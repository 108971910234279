"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const HttpClient_1 = require("./client/HttpClient");
const HttpRequest_1 = require("./client/HttpRequest");
const HttpCall_1 = require("./client/HttpCall");
const Monitor_1 = require("../monitor/Monitor");
/**
 * Class representing HTTP-based provider
 */
class HttpProvider {
    /**
     * Creates an instance of HttpProvider.
     * @param {string} url - The url of http provider.
     */
    constructor(url) {
        this.url = url;
    }
    request(request, converter) {
        const body = JSON.stringify(request, (_, value) => {
            if (value != null) {
                return value;
            }
            return undefined;
        });
        const httpRequest = new HttpRequest_1.default(this.url, body);
        return new HttpCall_1.default(HttpClient_1.default.newCall(httpRequest), converter);
    }
    monitor(request, ondata, onerror, onprogress) {
        const url = this.url.replace("http", "ws");
        return new Monitor_1.default(url, request, ondata, onerror, onprogress);
    }
}
exports.default = HttpProvider;
