import React, { useState } from "react";
function MapTypes({ selectedMapType, setselectedMapType }: any) {
  return (
    <div className='map_type_selection_container'>
      <div className='map_types_option'>
        <div
          className={`type_item ${selectedMapType == "GAME" ? "selected" : ""}`}
          onClick={() => {
            setselectedMapType("GAME");
          }}>
          Game
        </div>
        <div
          className={`type_item ${selectedMapType == "SATELLITE" ? "selected" : ""}`}
          onClick={() => {
            setselectedMapType("SATELLITE");
          }}>
          Satellite
        </div>
        <div
          className={`type_item ${selectedMapType == "HYBRID" ? "selected" : ""}`}
          onClick={() => {
            setselectedMapType("HYBRID");
          }}>
          Hybrid
        </div>
      </div>
    </div>
  );
}

export default MapTypes;
