import React from "react";
import { MINI_GAMES } from "../../../../assets/assets";
import { E_RPS_RPS_HANDS } from "../../../../enums/RPS.enums";
import { useRPSContext } from "../../../../services/contexts/rps.context";

function RPSHand({
  type,
  currentHandSelected,
  setcurrentHandSelected,
}: {
  className?: string;
  type: E_RPS_RPS_HANDS;
  currentHandSelected: E_RPS_RPS_HANDS | null;
  setcurrentHandSelected: any;
}) {
  const { nftRace } = useRPSContext();
  return (
    <div
      onClick={() => {
        console.log("choosing hand:", type);
        console.log(type);
        setcurrentHandSelected(type);
      }}
      className={`rps_hand ${currentHandSelected == type && "selected"} `}>
      <img
        src={nftRace ? MINI_GAMES.RPS.RACE_HAND[nftRace][type] : MINI_GAMES.RPS.HAND[type]}
        alt='paper_hand'
        className={`hand ${currentHandSelected == type && "selected"}`}
      />
    </div>
  );
}

export default RPSHand;
