"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.sign = exports.escapeString = exports.objTraverse = exports.arrTraverse = exports.generateHashKey = exports.serialize = exports.makeTxHash = exports.createPrivate = exports.hasProperties = exports.isGenesisBlock = exports.concatTypedArrays = exports.getCurrentTime = void 0;
/* eslint no-use-before-define: ["error", { "functions": false }] */
/* eslint-disable no-global-assign */
const js_sha3_1 = require("js-sha3");
const Type_1 = require("./Type");
const secp256k1 = require("secp256k1");
function getCurrentTime() {
    const date = new Date();
    return date.getTime();
}
exports.getCurrentTime = getCurrentTime;
function concatTypedArrays(a, b) {
    const c = new a.constructor(a.length + b.length);
    c.set(a, 0);
    c.set(b, a.length);
    return c;
}
exports.concatTypedArrays = concatTypedArrays;
function isGenesisBlock(height) {
    return height === 0;
}
exports.isGenesisBlock = isGenesisBlock;
function hasProperties(obj, propertySet) {
    if (typeof obj !== "object") {
        return false;
    }
    let result = true;
    propertySet.forEach((property) => {
        if ((0, Type_1.isArray)(property)) {
            result =
                result &&
                    property.some((item) => Object.prototype.hasOwnProperty.call(obj, item));
        }
        else if ((0, Type_1.isString)(property)) {
            result = result && Object.prototype.hasOwnProperty.call(obj, property);
        }
    });
    return result;
}
exports.hasProperties = hasProperties;
function createPrivate() {
    const weakMap = new WeakMap();
    return (key) => {
        if (!weakMap.has(key)) {
            weakMap.set(key, {});
        }
        return weakMap.get(key);
    };
}
exports.createPrivate = createPrivate;
function makeTxHash(rawTrasaction) {
    const phraseToSign = generateHashKey(rawTrasaction);
    return js_sha3_1.sha3_256.update(phraseToSign).hex();
}
exports.makeTxHash = makeTxHash;
function serialize(trasaction) {
    const phraseToSign = generateHashKey(trasaction);
    return js_sha3_1.sha3_256.update(phraseToSign).hex();
}
exports.serialize = serialize;
function generateHashKey(obj) {
    const resultStr = objTraverse(obj);
    const resultStrReplaced = resultStr.substring(1).slice(0, -1);
    return `icx_sendTransaction.${resultStrReplaced}`;
}
exports.generateHashKey = generateHashKey;
function arrTraverse(arr) {
    let result = "";
    result += "[";
    for (let j = 0; j < arr.length; j += 1) {
        const value = arr[j];
        if (value === undefined)
            continue;
        switch (true) {
            case value === null: {
                result += String.raw `\0`;
                break;
            }
            case typeof value === "string": {
                result += escapeString(value);
                break;
            }
            case Array.isArray(value): {
                result += arrTraverse(value);
                break;
            }
            case typeof value === "object": {
                result += objTraverse(value);
                break;
            }
            default:
                break;
        }
        result += ".";
    }
    if (result.endsWith(".")) {
        result = result.slice(0, -1);
    }
    result += "]";
    return result;
}
exports.arrTraverse = arrTraverse;
function objTraverse(obj) {
    let result = "";
    result += "{";
    const keys = Object.keys(obj);
    keys.sort();
    if (keys.length > 0) {
        for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            const value = obj[key];
            if (value === undefined)
                continue;
            switch (true) {
                case value === null: {
                    result += `${key}.`;
                    result += String.raw `\0`;
                    break;
                }
                case typeof value === "string": {
                    result += `${key}.`;
                    result += escapeString(value);
                    break;
                }
                case Array.isArray(value): {
                    result += `${key}.`;
                    result += arrTraverse(value);
                    break;
                }
                case typeof value === "object": {
                    result += `${key}.`;
                    result += objTraverse(value);
                    break;
                }
                default:
                    break;
            }
            result += ".";
        }
        result = result.slice(0, -1);
        result += "}";
    }
    else {
        result += "}";
    }
    return result;
}
exports.objTraverse = objTraverse;
function escapeString(value) {
    let newString = String.raw `${value}`;
    newString = newString.split("\\").join("\\\\");
    newString = newString.split(".").join("\\.");
    newString = newString.split("{").join("\\{");
    newString = newString.split("}").join("\\}");
    newString = newString.split("[").join("\\[");
    newString = newString.split("]").join("\\]");
    return newString;
}
exports.escapeString = escapeString;
function sign(data, privKey) {
    const signing = secp256k1.ecdsaSign(Buffer.from(data, "hex"), privKey);
    const recovery = new Uint8Array(1);
    recovery[0] = signing.recid;
    return concatTypedArrays(signing.signature, recovery);
}
exports.sign = sign;
