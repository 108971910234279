// all code related to chain config, contracts - call

import Icon from "icon-sdk-js";
import { E_ICONEX_RPC_EVENT_TYPE } from "../../hooks/useIconexHook";
import { REACT_APP_NETWORK_URL, REACT_APP_NETWORD_NID } from "../data/config";
const { IconConverter, IconBuilder, HttpProvider } = Icon;
const { CallBuilder, CallTransactionBuilder } = IconBuilder;

export class IconChainService {
  private provider;
  private static instance: IconChainService;
  public sdk;

  constructor() {
    console.log("Setting icon provider --");
    this.provider = new HttpProvider(REACT_APP_NETWORK_URL as string);
    this.sdk = new Icon(this.provider);
    // this.startListeningForEvent();
  }
  public static getInstance(): IconChainService {
    if (!IconChainService.instance) {
      IconChainService.instance = new IconChainService();
    }
    return IconChainService.instance;
  }
  getNativeTokenBalance = async (walletAddress: string): Promise<number> => {
    console.log(walletAddress);
    return Promise.resolve(Number(0));
  };

  public async getFromChain(score: string, method: string, params: any): Promise<unknown> {
    try {
      const call = new CallBuilder().to(score).method(method).params(params).build();
      const response = await this.sdk.call(call).execute();
      return response;
    } catch (error: any | unknown) {
      throw new Error(error);
    }
  }
  public initializeSignTxObj = (iconAddress, msgKey, msgVal) => {
    return new CallTransactionBuilder()
      .from(iconAddress)
      .to(iconAddress)
      .stepLimit(IconConverter.toHex(0))
      .nid(IconConverter.toBigNumber(REACT_APP_NETWORD_NID as string))
      .nonce(IconConverter.toBigNumber("1"))
      .version(IconConverter.toBigNumber("3"))
      .timestamp(new Date().getTime() * 1000)
      .method("transfer")
      .params({ [msgKey]: msgVal })
      .build();
  };
  // A private variable to hold the resolve function of the connect promise
  private connectPromiseResolver?: (value: string) => void;

  private resolveConnectPromise = (txResult: any) => {
    // Resolve the connect promise when the wallet address is received
    if (this.connectPromiseResolver) {
      this.connectPromiseResolver(txResult);
      this.connectPromiseResolver = undefined; // Clear the resolver to avoid multiple resolves
    }
  };

  public async setToChain(
    score: string,
    method: string,
    params: any,
    address: string,
    amount: number,
    rpcType: E_ICONEX_RPC_EVENT_TYPE
  ): Promise<any> {
    try {
      console.log("[action type]:", rpcType);
      let txObj: any = null;

      // const steplimit = await this.getStepLimit(caller, method, score, params);
      if (amount == 0 || amount == null || amount == undefined) {
        txObj = new CallTransactionBuilder()
          .method(method)
          .params(params)
          .from(address)
          .to(score)
          .stepLimit(IconConverter.toBigNumber("5000000"))
          .nid(IconConverter.toBigNumber(REACT_APP_NETWORD_NID as string))
          .nonce(IconConverter.toBigNumber("1"))
          .version(IconConverter.toBigNumber("3"))
          .timestamp(new Date().getTime() * 1000)
          .build();
      } else {
        txObj = new CallTransactionBuilder()
          .method(method)
          .params(params)
          .from(address)
          .to(score)
          .value(`${amount * Math.pow(10, 18)}`)
          .stepLimit(IconConverter.toBigNumber("5000000"))
          .nid(IconConverter.toBigNumber(REACT_APP_NETWORD_NID as string))
          .nonce(IconConverter.toBigNumber("1"))
          .version(IconConverter.toBigNumber("3"))
          .timestamp(new Date().getTime() * 1000)
          .build();
      }

      let scoreData: any = {};
      scoreData.value = JSON.stringify({
        jsonrpc: "2.0",
        method: "icx_sendTransaction",
        params: IconConverter.toRawTransaction(txObj),
        id: 50889,
      });
      const parsed = JSON.parse(scoreData.value);
      window.dispatchEvent(
        new CustomEvent("ICONEX_RELAY_REQUEST", {
          detail: {
            type: "REQUEST_JSON-RPC",
            payload: parsed,
          },
        })
      );

      // Wrap the connect logic in a promise and return the promise
      return new Promise<string>((resolve) => {
        console.log("resolve", resolve);
        this.connectPromiseResolver = resolve; // Save the resolver function to resolve the promise later
      });
    } catch (error) {
      throw new Error(`Tx failed. Params: Score: ${score} Method: ${method}`);
    }
  }

  getSdk = () => {
    return this.sdk;
  };

  handleWalletChanged = (address?: string) => {
    console.log("ICON WALLET CHANGED");
    console.log(address);
  };
  signMessage = async (message: any): Promise<string> => {
    return Promise.resolve(message);
  };
  private startListeningForEvent = () => {
    // Add the event listener for "ICONEX_RELAY_RESPONSE"
    window.addEventListener("ICONEX_RELAY_RESPONSE", this.eventHandler as EventListener, false);
  };
  eventHandler = async (event: any) => {};
}

export default IconChainService.getInstance();
