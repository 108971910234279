import { useEffect } from "react";
import { useWalletContext } from "../services/contexts/wallet.context";
import IconChainService from "../services/icon/icon.service";
import { useIconEventContext } from "../services/contexts/iconEvent.context";
import { getResult } from "../services/data/rpsAPIs.services";
import { signatureSubject } from "./useSignMessage";
export enum E_ICONEX_RPC_EVENT_TYPE {
    OPENING_GAME_PENDING = "OPENING_GAME_PENDING",
    OPENING_GAME_SUCCESS = "OPENING_GAME_SUCCESS",
    OPENING_GAME_ERROR = "OPENING_GAME_ERROR",
    PLAYING_GAME_PENDING = "PLAYING_GAME_PENDING",
    PLAYING_GAME_ERROR = "PLAYING_GAME_ERROR",
    PLAYING_GAME_RESULT_SUCCESS = "PLAYING_GAME_RESULT_SUCCESS",
    PLAYING_GAME_PROCESSING_RESULT = "PLAYING_GAME_PROCESSING_RESULT",
    MESSAGE_SIGNING_PENGING = "MESSAGE_SIGNING_PENGING",
    CANCELING_GAME_PENDING = "CANCELING_GAME_PENDING",
    CANCELING_GAME_SUCCESS = "CANCELING_GAME_SUCCESS",
    ADDING_FRIEND_PENDING = "ADDING_FRIEND_PENDING",
    ADDING_FRIEND_SUCCESS = "ADDING_FRIEND_SUCCESS",
    ADDING_FRIEND_FAILED = "ADDING_FRIEND_FAILED",
    REMOVING_FRIEND_PENDING = "REMOVING_FRIEND_PENDING",
    REMOVING_FRIEND_SUCCESS = "REMOVING_FRIEND_SUCCESS",
    CLAIMING_REWARD_PENDING = "CLAIMING_REWARD_PENDING",
    CLAIMING_REWARD_SUCCESS = "CLAIMING_REWARD_SUCCESS",
}
export const useIconexHook = async () => {
    const { setWalletAddress, setWalletConnectionStatus } = useWalletContext();
    const { setCurrentEventType, setCurrentTxResult, setIsTransactionLoading, setSignStoreHash } =
        useIconEventContext();
    const eventHandler = async (event: any) => {
        console.log("event found", event);
        const txResultCounter = 2;
        if (event.detail.type === "RESPONSE_JSON-RPC") {
            const txHash: string = event.detail.payload.result;
            if (!txHash) {
                setIsTransactionLoading(false);
                setCurrentTxResult(null);
                setCurrentEventType((e) => "");
            }
            let txResult: any = null;
            for (let index = txResultCounter; index >= 1; index--) {
                if (txResult?.status.valueOf() === 1) {
                    break;
                } else {
                    await new Promise((resolve) => setTimeout(resolve, 10000));
                    txResult = await (IconChainService as any).sdk
                        .getTransactionResult(txHash)
                        .execute();
                }
            }
            if (txResult.status.valueOf() === 0) {
                (IconChainService as any).resolveConnectPromise({ status: 0 });
                // throw new Error(`Tx failed. Params: `);
            }
            console.log("result ✅", txResult);
            let current_event_rpc_type: any = "";
            setCurrentEventType((prev) => {
                current_event_rpc_type = prev;
                return prev;
            });
            console.log(`current event action type]]]`, current_event_rpc_type);
            if (current_event_rpc_type == E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_PENDING) {
                console.log("publishing result");
                setCurrentEventType((prev) => {
                    return E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_PROCESSING_RESULT;
                });
                const resResult = await getResult(txHash);
                console.log(resResult);
                if (resResult) {
                    console.log(" result published 🟢");
                    setCurrentTxResult((prev) => resResult["data"]);
                    setIsTransactionLoading((prev) => false);
                    setCurrentEventType((prev) => {
                        return E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_RESULT_SUCCESS;
                    });
                } else {
                    console.log(" failed published 🔴");
                    setCurrentTxResult((prev) => null);
                    setIsTransactionLoading((prev) => {
                        return false;
                    });
                    setCurrentEventType((prev) => {
                        return E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_ERROR;
                    });
                }
            } else if (current_event_rpc_type == E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_PENDING) {
                setIsTransactionLoading(false);
                setCurrentTxResult(null);
                setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_SUCCESS);
                setTimeout(() => {
                    setCurrentEventType((e) => {
                        if (e == E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_SUCCESS) return "";
                        else return e;
                    });
                }, 20000);
            } else if (current_event_rpc_type == E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_PENDING) {
                setIsTransactionLoading(false);
                setCurrentTxResult(null);
                setCurrentEventType((e) => E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_SUCCESS);

                setTimeout(() => {
                    setCurrentEventType((e) => {
                        if (e == E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_SUCCESS) return "";
                        else return e;
                    });
                }, 10000);
            } else if (current_event_rpc_type == E_ICONEX_RPC_EVENT_TYPE.REMOVING_FRIEND_PENDING) {
                setIsTransactionLoading(false);
                setCurrentTxResult(null);
                setCurrentEventType((e) => E_ICONEX_RPC_EVENT_TYPE.REMOVING_FRIEND_SUCCESS);
                setTimeout(() => {
                    setCurrentEventType((e) => {
                        if (e == E_ICONEX_RPC_EVENT_TYPE.REMOVING_FRIEND_SUCCESS) return "";
                        else return e;
                    });
                }, 5000);
            } else if (current_event_rpc_type == E_ICONEX_RPC_EVENT_TYPE.CLAIMING_REWARD_PENDING) {
                setIsTransactionLoading(false);
                setCurrentTxResult(null);
                setCurrentEventType((e) => "");
            } else {
                setIsTransactionLoading(false);
                setCurrentTxResult(null);
                setCurrentEventType("");
            }
            (IconChainService as any).resolveConnectPromise(txResult);
        } else if (event.detail.type === "CANCEL_JSON-RPC") {
            console.log("wallet canceling event ... 🔴");
            let current_event_rpc_type: any = "";
            setCurrentEventType((prev) => {
                current_event_rpc_type = prev;
                return prev;
            });
            if (current_event_rpc_type == E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_PENDING) {
                setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_FAILED);
                setTimeout(() => {
                    setCurrentEventType((e) => {
                        if (e == E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_FAILED) return "";
                        else return e;
                    });
                }, 20000);
            } else {
                setIsTransactionLoading(false);
                setCurrentTxResult(null);
                setCurrentEventType("");
            }
            (IconChainService as any).resolveConnectPromise({ status: -1 });
        } else if (event.detail.type === "RESPONSE_ADDRESS") {
            if (event.detail.payload) {
                const address = event.detail.payload;
                localStorage.removeItem("address");
                localStorage.setItem("address", address);
                setWalletAddress(address);
                setWalletConnectionStatus(true);
            }
        } else if (event.detail.type === "RESPONSE_SIGNING") {
            console.log(event.detail.payload);
            signatureSubject.next(event.detail.payload);
            setIsTransactionLoading(false);
            setCurrentTxResult(null);
            setCurrentEventType("");
        } else if (event.detail.type === "CANCEL_SIGNING") {
            setIsTransactionLoading(false);
            setCurrentTxResult(null);
            setCurrentEventType("");
        } else {
            console.log("Error on wallet connection!");
            setWalletConnectionStatus(false);
        }
    };
    useEffect(() => {
        const add = localStorage.getItem("address");
        console.log(`Address: ${add}`);
        if (add) {
            setWalletAddress(add);
            setWalletConnectionStatus(true);
        }
        window.addEventListener("ICONEX_RELAY_RESPONSE", eventHandler, false);
        return () => {
            window.removeEventListener("ICONEX_RELAY_RESPONSE", eventHandler);
        };
    }, []);
};
