import React, { useEffect, useState } from "react";
import RPSContractServices from "../../../../services/contract/rps.service";
import {
  abbreviateNumber,
  findChanges,
  getGameIdsObj,
  removeGameIds,
} from "../../../../utils/helper";
import { useWalletContext } from "../../../../services/contexts/wallet.context";
import { useIconEventContext } from "../../../../services/contexts/iconEvent.context";
import { E_ICONEX_RPC_EVENT_TYPE, useIconexHook } from "../../../../hooks/useIconexHook";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { GiCandleSkull } from "react-icons/gi";
import { GiBullyMinion } from "react-icons/gi";

import Loading from "../../../../components/common/Loading.component";
import { getCreatedGame, getPlayedGame } from "../../../../services/data/rpsAPIs.services";
import {
  C_RPS_HAND,
  E_RPS_ACTION_CONTAINER,
  E_RPS_CHALLENGE_TYPE,
  E_RPS_RPS_HANDS,
  E_RPS_URL_QUERY_ID,
  ZERO_ADDRESS,
} from "../../../../enums/RPS.enums";
// import { useSearchParams } from "react-router-dom";
import { TwitterShareButton, TwitterIcon } from "react-share";
import { FaRegCopy } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import rpsService from "../../../../services/contract/rps.service";
import ConnectWallet from "../common/Connect.wallet";
import ConnectWalletComponent from "../common/Connect.wallet";
import { GiFrankensteinCreature } from "react-icons/gi";
import { useRPSContext } from "../../../../services/contexts/rps.context";
import { GiMailedFist } from "react-icons/gi";
import { useSearchParams } from "react-router-dom";
import RPSGameCard from "../common/RPSGameCard.component";
import FriendListTableRow from "./FriendListTableRow";
enum E_HISTORY_MENU {
  CHALLENGED = "Challenged",
  OPENED = "Hosted",
  CREATED = "Created",
  PLAYED = "Participated",
  MY_FRIENDS = "My Friends",
}

function RPSHistoryTable() {
  const {
    setCurrentActionContainerSelected,
    friendList,
    challengedGameNumber,
    setChallengingPlayerAddress,
    setIsOpeningGame,
  } = useRPSContext();

  const [currentHistoryMenuSelected, setcurrentHistoryMenuSelected] = useState<E_HISTORY_MENU>(
    challengedGameNumber >= 1 ? E_HISTORY_MENU.CHALLENGED : E_HISTORY_MENU.OPENED
  );
  const [listOfOpenGames, setlistOfOpenGames] = useState<Array<any>>([]);
  const [challengedOpenGames, setChallengedOpenGames] = useState<Array<any>>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDataLoading, setisDataLoading] = useState(true);
  const [historyCreatedGame, sethistoryCreatedGame] = useState([]);
  const [copyAddress, setcopyAddress] = useState("Copy Link");
  const [historyPlayerGame, sethistoryPlayerGame] = useState([]);
  const { currentEventType, setCurrentEventType, isTransactionLoading, setIsTransactionLoading } =
    useIconEventContext();
  const { walletAddress, isWalletConnected } = useWalletContext();
  const closeGame = async (gameId: string) => {
    try {
      setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.CANCELING_GAME_PENDING);
      setIsTransactionLoading(true);
      const response = await RPSContractServices?.closeGame(gameId, walletAddress);
      console.log(response);
    } catch (error) {}
  };
  const getGames = async () => {
    try {
      setisDataLoading(true);
      console.log("featching game");
      const response: Array<any> = await RPSContractServices?.getOpenGames();
      let openerFrnList = {};
      for (let i = 0; i < response.length; i++) {
        if (!Object.keys(openerFrnList).includes(response[i].creator)) {
          const resposeFriend = await rpsService.getFriendList(response[i].creator);
          openerFrnList[response[i].creator] = resposeFriend;
        }
      }
      let li: any = response;
      if (response) {
        const onlyAddress = response.filter(
          (data) =>
            data.creator == walletAddress &&
            RPSContractServices?.getRPSGameStatus(data.played, data.status) == "OPEN"
        );
        const challengeList = li.filter(
          (data: any) =>
            data.challengedAddress == walletAddress ||
            (data["wishList"] == "0x1" && openerFrnList[data.creator].includes(walletAddress))
        );
        // if (data["wishList"] == "0x1" && openerFrnList[data.creator].includes(walletAddress)) {
        //   response.push({
        //     ...data,
        //     type: E_RPS_OPEN_GAME_TYPE.TO_FRIENDS,
        //   });
        // }
        setChallengedOpenGames(challengeList);
        setlistOfOpenGames(onlyAddress);
        setisDataLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getHistoryCreatedGame = async () => {
    try {
      setisDataLoading(true);
      console.log("getting created history game ==>");
      const response = await getCreatedGame(walletAddress);
      if (response) {
        sethistoryCreatedGame(response.logs);
      }
      setisDataLoading(false);
      console.log(response);
    } catch (error) {}
  };
  const getHistoryPlayerGame = async () => {
    try {
      setisDataLoading(true);
      console.log("getting created history game ==>");
      const response = await getPlayedGame(walletAddress);
      if (response) {
        sethistoryPlayerGame(response.logs);
      }
      setisDataLoading(false);
      console.log(response);
    } catch (error) {}
  };
  useEffect(() => {
    getGames();
    return () => {};
  }, [, isTransactionLoading]);
  useEffect(() => {
    switch (currentHistoryMenuSelected) {
      case E_HISTORY_MENU.CREATED:
        getHistoryCreatedGame();
        break;
      case E_HISTORY_MENU.OPENED:
        getGames();
        break;
      case E_HISTORY_MENU.PLAYED:
        getHistoryPlayerGame();
        break;
      default:
        break;
    }

    return () => {};
  }, [currentHistoryMenuSelected]);

  return (
    <div className='rps_history_table'>
      <div className='rps_history_menu'>
        {Object.values(E_HISTORY_MENU).map((data) => {
          if (
            (E_HISTORY_MENU.CHALLENGED == data && challengedGameNumber >= 1) ||
            (E_HISTORY_MENU.CHALLENGED != data && Object.values(E_HISTORY_MENU).includes(data))
          )
            return (
              <div
                className={`${currentHistoryMenuSelected == data ? "selected" : ""}`}
                onClick={() => {
                  setcurrentHistoryMenuSelected(data);
                }}>
                {data}
                {data == E_HISTORY_MENU.OPENED &&
                  listOfOpenGames.length >= 1 &&
                  ` (${listOfOpenGames?.length})`}{" "}
                {data == E_HISTORY_MENU.CHALLENGED && challengedGameNumber >= 1 && (
                  <span className='icon-button__badge'>{challengedGameNumber}</span>
                )}
              </div>
            );
        })}
      </div>
      {isWalletConnected && (
        <div className='data_table'>
          {(currentHistoryMenuSelected == E_HISTORY_MENU.CREATED ||
            currentHistoryMenuSelected == E_HISTORY_MENU.PLAYED) && (
            <table>
              {currentEventType == E_ICONEX_RPC_EVENT_TYPE.CANCELING_GAME_PENDING && (
                <Loading message='loading..' />
              )}
              <thead>
                <tr>
                  <th>Time</th>
                  <th>GID</th>
                  <th>Wager</th>
                  <th>
                    {currentHistoryMenuSelected == E_HISTORY_MENU.CREATED ? "Opponent" : "Host"}
                  </th>
                  <th>Yours</th>
                  <th>Opponent's</th>
                  <th>Status</th>
                  <th>Transfer</th>
                </tr>
              </thead>
              <tbody>
                {isDataLoading ? (
                  <Loading message='loading data..' />
                ) : currentHistoryMenuSelected == E_HISTORY_MENU.CREATED ? (
                  historyCreatedGame?.map(
                    (
                      {
                        createdAt,
                        creator,
                        player,
                        winner,
                        wager,
                        gameId,
                        completedAt,
                        creatorChoice,
                        playerChoice,
                      },
                      index
                    ) => {
                      return (
                        <tr>
                          <td>{new Date(completedAt).toLocaleDateString("en-US")}</td>
                          <td>{gameId}</td>
                          <td>
                            {wager ? (
                              <>
                                {abbreviateNumber(wager["wagerAmount"])}{" "}
                                <span className='token_unit'>{wager["wagerType"]}</span>
                              </>
                            ) : (
                              0
                            )}
                          </td>
                          <td
                            className='clickable inlink'
                            onClick={() => {
                              setCurrentActionContainerSelected(
                                E_RPS_ACTION_CONTAINER.FIND_GANGSTER
                              );
                              setSearchParams((param) => {
                                param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, player);
                                return param;
                              });
                            }}>
                            {(player as string).slice(0, 6)}..{(player as string).slice(38)}
                          </td>
                          <td>{C_RPS_HAND[creatorChoice]}</td> <td>{C_RPS_HAND[playerChoice]}</td>{" "}
                          <td>
                            {winner == ZERO_ADDRESS ? "DRAW" : creator == winner ? "WON" : "LOST"}
                          </td>
                          <td
                            className={
                              winner == ZERO_ADDRESS ? "DRAW" : creator == winner ? "WON" : "LOST"
                            }>
                            {winner == ZERO_ADDRESS ? (
                              0
                            ) : creator == winner ? (
                              <>
                                + {abbreviateNumber(wager["wagerAmount"])}{" "}
                                <span className='token_unit'>{wager["wagerType"]}</span>
                              </>
                            ) : (
                              <>
                                - {abbreviateNumber(wager["wagerAmount"])}{" "}
                                <span className='token_unit'>{wager["wagerType"]}</span>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : currentHistoryMenuSelected == E_HISTORY_MENU.PLAYED ? (
                  historyPlayerGame?.map(
                    (
                      {
                        createdAt,
                        creator,
                        player,
                        winner,
                        wager,
                        gameId,
                        completedAt,
                        creatorChoice,
                        playerChoice,
                      },
                      index
                    ) => {
                      return (
                        <tr>
                          <td>{new Date(completedAt).toLocaleDateString("en-US")}</td>
                          <td>{gameId}</td>
                          <td>
                            {wager ? (
                              <>
                                {abbreviateNumber(wager["wagerAmount"])}{" "}
                                <span className='token_unit'>{wager["wagerType"]}</span>
                              </>
                            ) : (
                              0
                            )}
                          </td>
                          <td
                            className='clickable inlink'
                            onClick={() => {
                              setCurrentActionContainerSelected(
                                E_RPS_ACTION_CONTAINER.FIND_GANGSTER
                              );
                              setSearchParams((param) => {
                                param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, creator);
                                return param;
                              });
                            }}>
                            {(creator as string).slice(0, 4)}..{(creator as string).slice(38)}
                          </td>
                          <td>{C_RPS_HAND[playerChoice]}</td> <td>{C_RPS_HAND[creatorChoice]}</td>{" "}
                          <td>
                            {winner == ZERO_ADDRESS ? "DRAW" : player == winner ? "WON" : "LOST"}
                          </td>
                          <td
                            className={
                              winner == ZERO_ADDRESS ? "DRAW" : player == winner ? "WON" : "LOST"
                            }>
                            {winner == ZERO_ADDRESS ? (
                              0
                            ) : player == winner ? (
                              <>
                                + {abbreviateNumber(wager["wagerAmount"])}{" "}
                                <span className='token_unit'>{wager["wagerType"]}</span>
                              </>
                            ) : (
                              <>
                                - {abbreviateNumber(wager["wagerAmount"])}{" "}
                                <span className='token_unit'>{wager["wagerType"]}</span>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : null}
              </tbody>
            </table>
          )}
          {currentHistoryMenuSelected == E_HISTORY_MENU.OPENED && (
            <table>
              {currentEventType == E_ICONEX_RPC_EVENT_TYPE.CANCELING_GAME_PENDING && (
                <Loading message='loading..' />
              )}
              <thead>
                <tr>
                  <th>Time</th>
                  <th>GID</th>
                  <th>Wager</th>
                  <th>Action</th>
                  <th>Share</th>
                  <th>Playing with </th>
                </tr>
              </thead>
              <tbody>
                {isDataLoading ? (
                  <Loading message='loading..' />
                ) : listOfOpenGames.length >= 1 ? (
                  listOfOpenGames.map(
                    (
                      {
                        amount,
                        creationDate,
                        id,
                        played,
                        status,
                        tokenAddress,
                        wishList,
                        challengedAddress,
                      },
                      _
                    ) => {
                      return (
                        <tr key={parseInt(id, 16)}>
                          <td>
                            {new Date(parseInt(creationDate, 16) / 1000).toLocaleDateString(
                              "en-US"
                            )}
                          </td>
                          <td>{parseInt(id, 16)}</td>

                          <td>
                            {parseInt(amount, 16) / Math.pow(10, 18)}{" "}
                            <span className='currency'>
                              ${tokenAddress == ZERO_ADDRESS ? "ICX" : "CROWN"}
                            </span>
                          </td>
                          <td
                            className='close_button'
                            onClick={() => {
                              closeGame(id);
                            }}>
                            <button className='primary'>Close</button>
                          </td>
                          <td className='share_button' style={{ position: "relative" }}>
                            <TwitterShareButton
                              url={`I have opened a game of Rock, Paper, Scissor, wanna play against me? 
${window.location.href}&${E_RPS_URL_QUERY_ID.GAME_ID}=${id / 1}`}>
                              <FaXTwitter className='share_link' style={{ borderRadius: "100%" }} />
                            </TwitterShareButton>
                            <ReactTooltip
                              id={`copy_bar_tooltip_${id}`}
                              place={"top"}
                              className='streak_bar_tooltip'>
                              {copyAddress}
                            </ReactTooltip>
                            <FaRegCopy
                              className='copy_link'
                              data-tooltip-id={`copy_bar_tooltip_${id}`}
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              onClick={() => {
                                navigator.clipboard
                                  .writeText(
                                    `${window.location.origin}${window.location.pathname}?${
                                      E_RPS_URL_QUERY_ID.ROOM
                                    }=${E_RPS_ACTION_CONTAINER.PLAY}&${
                                      E_RPS_URL_QUERY_ID.GAME_ID
                                    }=${id / 1}`
                                  )
                                  .then(
                                    function () {
                                      setcopyAddress((r) => "Copied!");
                                      setTimeout(() => {
                                        setcopyAddress((r) => "Copy Link");
                                      }, 3000);
                                    },
                                    function (err) {
                                      console.error("Async: Could not copy text: ", err);
                                    }
                                  );
                              }}
                            />
                          </td>
                          <td>
                            {rpsService.getRPSOpeGameType(wishList, challengedAddress) ==
                            E_RPS_CHALLENGE_TYPE.SOMEONE ? (
                              <div
                                className='clickable inlink'
                                onClick={() => {
                                  setCurrentActionContainerSelected(
                                    E_RPS_ACTION_CONTAINER.FIND_GANGSTER
                                  );
                                  setSearchParams((param) => {
                                    param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, challengedAddress);
                                    return param;
                                  });
                                }}>
                                {challengedAddress.slice(0, 5)}...{challengedAddress.slice(38)}
                              </div>
                            ) : (
                              rpsService.getRPSOpeGameType(wishList, challengedAddress)
                            )}{" "}
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : null}{" "}
              </tbody>
            </table>
          )}
          {currentHistoryMenuSelected == E_HISTORY_MENU.CHALLENGED && (
            <div className='challenged_game_list'>
              {challengedOpenGames?.map((data, index) => {
                return data?.status == "0x1" && data?.played == "0x0" ? (
                  <>
                    <RPSGameCard
                      preSelectedId={null}
                      currentSelectedOpenGame={null}
                      setcurrentSelectedOpenGame={(e: any) => {
                        setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.PLAY);
                        setSearchParams((param) => {
                          param.set(E_RPS_URL_QUERY_ID.GAME_ID, `${(e.nftId as any) / 1}`);
                          return param;
                        });
                      }}
                      data={data}
                      key={index}
                    />
                  </>
                ) : null;
              })}
            </div>
          )}
          {currentHistoryMenuSelected == E_HISTORY_MENU.MY_FRIENDS && (
            <table>
              {currentEventType == E_ICONEX_RPC_EVENT_TYPE.CANCELING_GAME_PENDING && (
                <Loading message='loading..' />
              )}
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {isDataLoading ? (
                  <Loading message='loading..' />
                ) : friendList.length >= 1 ? (
                  friendList.map((data, index) => {
                    return (
                      <FriendListTableRow
                        setCurrentActionContainerSelected={setCurrentActionContainerSelected}
                        setSearchParams={setSearchParams}
                        index={index}
                        setisDataLoading={setisDataLoading}
                        setChallengingPlayerAddress={setChallengingPlayerAddress}
                        setIsOpeningGame={setIsOpeningGame}
                        address={data}
                      />
                    );
                  })
                ) : null}{" "}
              </tbody>
            </table>
          )}
          {!isDataLoading &&
          isWalletConnected &&
          currentHistoryMenuSelected == E_HISTORY_MENU.OPENED &&
          listOfOpenGames.length <= 0 ? (
            <div className='history_empty'>
              <GiCandleSkull className='icon_skull' />
              <h3> You haven't hosted any games.</h3>
              <p> You can open a game by going to Play Tab and host a game.</p>
            </div>
          ) : null}
          {!isDataLoading &&
          isWalletConnected &&
          currentHistoryMenuSelected == E_HISTORY_MENU.CREATED &&
          historyCreatedGame.length <= 0 ? (
            <div className='history_empty'>
              <GiFrankensteinCreature className='icon_skull' />
              <h3> Frankenstein's here.</h3>
              <p> Looks like nobody has played your game.</p>
            </div>
          ) : null}{" "}
          {!isDataLoading &&
          isWalletConnected &&
          currentHistoryMenuSelected == E_HISTORY_MENU.PLAYED &&
          historyPlayerGame.length <= 0 ? (
            <div className='history_empty'>
              <GiBullyMinion className='icon_skull' />
              <h3> Oh boy! Scared of playing a game.</h3>
              <p> Looks like you haven't played any.</p>
            </div>
          ) : null}{" "}
        </div>
      )}
      {!isWalletConnected && (
        <ConnectWalletComponent
          title={"Connect your Hana wallet"}
          desc={"Please connect your wallet, to view your history and games."}
        />
      )}
    </div>
  );
}

export default RPSHistoryTable;
