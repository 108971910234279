"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const Hexadecimal_1 = require("../Hexadecimal");
const Converter_1 = require("../Converter");
const Failure_1 = require("./Failure");
class TransactionResult {
    constructor(data) {
        this.status = (0, Converter_1.toNumber)(data.status);
        this.to = (0, Hexadecimal_1.addHxPrefix)(data.to);
        this.txHash = (0, Hexadecimal_1.add0xPrefix)(data.txHash);
        this.txIndex = (0, Converter_1.toNumber)(data.txIndex);
        this.blockHeight = (0, Converter_1.toNumber)(data.blockHeight);
        this.blockHash = (0, Hexadecimal_1.add0xPrefix)(data.blockHash);
        this.cumulativeStepUsed = (0, Converter_1.toBigNumber)(data.cumulativeStepUsed);
        this.stepUsed = (0, Converter_1.toBigNumber)(data.stepUsed);
        this.stepPrice = (0, Converter_1.toBigNumber)(data.stepPrice);
        if (data.scoreAddress) {
            this.scoreAddress = (0, Hexadecimal_1.addCxPrefix)(data.scoreAddress);
        }
        if (data.eventLogs) {
            this.eventLogs = data.eventLogs;
        }
        if (data.logsBloom) {
            this.logsBloom = data.logsBloom;
        }
        if (data.failure) {
            this.failure = new Failure_1.default(data.failure);
        }
    }
}
exports.default = TransactionResult;
