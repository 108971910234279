"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("../index");
class BlockNotification {
    constructor(data) {
        this.hash = data.hash;
        this.height = index_1.Converter.toBigNumber(data.height);
        if (data.indexes) {
            this.indexes = [];
            for (let i = 0; i < data.indexes.length; i++) {
                this.indexes[i] = [];
                for (let j = 0; j < data.indexes[i].length; j++)
                    this.indexes[i][j] = index_1.Converter.toBigNumber(data.indexes[i][j]);
            }
        }
        if (data.events) {
            this.events = [];
            for (let i = 0; i < data.events.length; i++) {
                this.events[i] = [];
                for (let j = 0; j < data.events[i].length; j++) {
                    this.events[i][j] = [];
                    for (let k = 0; k < data.events[i][j].length; k++)
                        this.events[i][j][k] = index_1.Converter.toBigNumber(data.events[i][j][k]);
                }
            }
        }
        if (data.logs) {
            this.logs = [];
            for (let i = 0; i < data.logs.length; i++) {
                this.logs[i] = [];
                for (let j = 0; j < data.logs[i].length; j++) {
                    this.logs[i][j] = [];
                    for (let k = 0; k < data.logs[i][j].length; k++) {
                        this.logs[i][j][k] = data.logs[i][j][k];
                    }
                }
            }
        }
    }
}
exports.default = BlockNotification;
