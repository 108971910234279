"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class EventFilter {
    constructor(event, addr, indexed, data) {
        this.event = event;
        this.addr = addr;
        this.indexed = indexed;
        this.data = data;
    }
    toObject() {
        const obj = {
            event: this.event,
            addr: this.addr,
            indexed: this.indexed,
            data: this.data,
        };
        Object.keys(obj).forEach((key) => {
            if (obj[key] === undefined || obj[key] === null)
                delete obj[key];
        });
        return obj;
    }
}
exports.default = EventFilter;
