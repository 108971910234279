import React from "react";
import ToggleSwitch from "../../common/ToggleSwitch.component";

interface IDistrictsToggle {
  showDistrict: boolean;
  setShowDistrict: any;
}
function DistrictsToggle({ setShowDistrict, showDistrict }: IDistrictsToggle) {
  return (
    <div className='toggle_component district_toggle'>
      <div className='infoBox'>
        <div className='title'>Show districts</div>
        <div className='subDesc'>View all 9 districts in the map</div>
      </div>
      <ToggleSwitch
        type={"large"}
        onChange={(e: any) => {
          setShowDistrict(e.target.checked);
        }}
        isDisabled={false}
      />
    </div>
  );
}

export default DistrictsToggle;
