import React, { useEffect, useState } from "react";
import { getHighestGangstaBet } from "../../services/data/data.service";
import { useWalletContext } from "../../services/contexts/wallet.context";
import { MINI_GAMES } from "../../assets/assets";
import { IoLogOutOutline } from "react-icons/io5";
import Button from "./Button.component";
import { handleWalletConnection } from "../../utils/wallet.utils";

function ProfilePicture() {
  const [gangstaBetDetail, setgangstaBetDetail] = useState<any>(null);
  const { walletAddress, isWalletConnected } = useWalletContext();
  const setGangstaBet = async () => {
    const res = await getHighestGangstaBet(walletAddress);
    console.log(res);
    setgangstaBetDetail(res);
  };
  useEffect(() => {
    setGangstaBet();
    // gangstaBetDetail?.image
    return () => {};
  }, [, walletAddress]);
  return (
    <div className={`profile_picture_container ${isWalletConnected ? "flex" : ""}`}>
      <div className='profile_desc'>
        {isWalletConnected ? (
          <>
            <h3>
              {gangstaBetDetail
                ? gangstaBetDetail.name
                : `${walletAddress.slice(0, 5)}...
              ${walletAddress.slice(36)}`}
            </h3>
            <p>
              <div>
                {walletAddress.slice(0, 5)}...{walletAddress.slice(36)}
              </div>
              {/* <IoLogOutOutline className='disconnect' /> */}
            </p>
          </>
        ) : null}
      </div>
      <div className='img_profile_c'>
        <img
          className={`${gangstaBetDetail ? "player" : "no_player"}`}
          src={
            gangstaBetDetail
              ? `https://d2l8dezwbqkb9.cloudfront.net/png/${gangstaBetDetail?.nftId}.png`
              : MINI_GAMES.RPS.UNKNOWL_PLAYER
          }
        />
      </div>
      {!isWalletConnected && (
        <Button
          className={"connect_button_profile"}
          onClick={() => {
            handleWalletConnection();
          }}
          name='Connect'
          size={"large"}
          isDisabled={false}
        />
      )}
    </div>
  );
}

export default ProfilePicture;
