"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("../../data/index");
const BTPNotification_1 = require("../../data/Formatter/BTPNotification");
class BTPMonitorSpec {
    constructor(height, networkID, proofFlag, progressInterval) {
        this.height = height;
        this.networkID = networkID;
        this.proofFlag = proofFlag;
        this.progressInterval = progressInterval;
    }
    getPath() {
        return "btp";
    }
    getParam() {
        const flag = this.proofFlag ? "0x1" : "0x0";
        let ret = {
            height: index_1.Converter.toHex(this.height),
            networkID: index_1.Converter.toHex(this.networkID),
            proofFlag: flag,
        };
        if (this.progressInterval > 0) {
            ret = Object.assign(ret, {
                progressInterval: index_1.Converter.toHex(this.progressInterval),
            });
        }
        return ret;
    }
    getConverter() {
        return (data) => new BTPNotification_1.default(data);
    }
}
exports.default = BTPMonitorSpec;
