import React from "react";
import ToggleSwitch from "../../common/ToggleSwitch.component";

interface IMintedLandsToggle {
  setShowMintedLands: any;
  showMintedLands: boolean;
}
function MintedLandsToggle({ setShowMintedLands, showMintedLands }: IMintedLandsToggle) {
  return (
    <div className='toggle_component district_toggle'>
      <div className='infoBox'>
        <div className='title'>Show all minted lands</div>
        <div className='subDesc'>View all minted lands in the map</div>
      </div>
      <ToggleSwitch
        checked={showMintedLands}
        type={"large"}
        onChange={(e: any) => {
          setShowMintedLands(e.target.checked);
        }}
        isDisabled={false}
      />
    </div>
  );
}

export default MintedLandsToggle;
