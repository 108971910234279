import { Tooltip as ReactTooltip } from "react-tooltip";
import ImageLoader from "../imageLoader/ImageLoader";
const GangstaBetTooltip = ({ image, place = "top", tooltipId }: any) => {
  return (
    <>
      <ReactTooltip
        id={tooltipId}
        place={place}
        className='tooltipSneakMoveLoadout'
        style={{ backgroundColor: "#aa9b46" }}>
        <div style={{ padding: "0.3rem", background: "#000000" }}>
          <ImageLoader src={image} alt='gbet' />
        </div>
      </ReactTooltip>
    </>
  );
};
export default GangstaBetTooltip;
