/* eslint-disable */
import { useEffect, useState } from "react";
import ImageLoader from "../imageLoader/ImageLoader";
// import { WHITE_LAND_VIEWER, MANSION } from '@constants/images';

const PropertyCarousel = ({
  images,
  previewImage,
  render3DObject,
  imageUrl,
  spotlight,
  handleOpen3DModal,
  setOpen3DModel,
}: any) => {
  const [showModal, setShowModal] = useState(false);
  const [showArtView, setShowArtView] = useState(false);
  const [selectedImg, setSelectedImg] = useState(previewImage);
  const [viewImagePath, setViewImagePath] = useState("");

  // const imageUri = process.env.REACT_APP_LAND_BUCKET_API;

  useEffect(() => {
    setSelectedImg(previewImage);
  }, [previewImage]);

  return (
    <div className='property-img-carousel-wrapper carousel-wrapper'>
      {/* {spotlight && (
        <div className='spotlight-img'>
          <img src={MANSION.SPOTLIGHT} alt='spotlight' />
        </div>
      )} */}
      <div className='property-img'>
        <ImageLoader
          className={"carousel-selected-img"}
          skeletonStyle={{ height: "200px", width: "100%" }}
          width={"200px"}
          style={{ borderRadius: "2px" }}
          src={imageUrl + selectedImg}
          alt='carousel-selected-image'
        />
      </div>
      <div className={`image-diff-view-wrapper ${!showModal && "set-image-top"}`}>
        {images &&
          images.map((imagePath: any, index: number) => {
            return (
              <div key={index} className='image-diff-view'>
                <ImageLoader
                  width={"100%"}
                  className={`diff-view ${imagePath === selectedImg && "selected-carousel"}`}
                  onClick={() => {
                    setSelectedImg(imagePath);
                  }}
                  src={imageUrl + imagePath}
                  alt={`carousel-${index}`}
                  skeletonStyle={{ width: "90px", height: "90px" }}
                  gifSkeleton
                />
              </div>
            );
          })}

        <div
          onClick={() => {
            setOpen3DModel(true);
          }}
          className='image-diff-view button_3d_view'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className=''
            fill=' '
            viewBox='0 0 24 24'
            stroke='currentColor'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='0.8'
              d='M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5'
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default PropertyCarousel;
