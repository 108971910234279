"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInteger = exports.isFunction = exports.isHex = exports.isBigNumber = exports.isArray = exports.isByte = exports.isString = void 0;
const bignumber_js_1 = require("bignumber.js");
function isString(value) {
    return typeof value === "string" || value instanceof String;
}
exports.isString = isString;
function isByte(value) {
    return Boolean(value) && value.byteLength !== undefined;
}
exports.isByte = isByte;
function isArray(obj) {
    return Array.isArray(obj);
}
exports.isArray = isArray;
function isBigNumber(value) {
    return bignumber_js_1.default.isBigNumber(value);
}
exports.isBigNumber = isBigNumber;
function isHex(value) {
    return /^(0x)[0-9a-f]+$/g.test(value);
}
exports.isHex = isHex;
function isFunction(value) {
    return typeof value === "function";
}
exports.isFunction = isFunction;
function isInteger(value) {
    return Number.isInteger(value);
}
exports.isInteger = isInteger;
