"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.toScoreApiList = exports.toTransactionResult = exports.toTransaction = exports.toBlock = void 0;
const Block_1 = require("./Block");
const Transaction_1 = require("./Transaction");
const TransactionResult_1 = require("./TransactionResult");
const ScoreApiList_1 = require("./ScoreApiList");
const Util_1 = require("../Util");
const Exception_1 = require("../../Exception");
const Validator_1 = require("../Validator");
/**
 * @description Convert block data into the right format.
 */
function toBlock(data) {
    if (!(0, Util_1.hasProperties)(data, [
        "height",
        "block_hash",
        "merkle_tree_root_hash",
        "prev_block_hash",
        "peer_id",
        "confirmed_transaction_list",
        "signature",
        "time_stamp",
        "version",
    ])) {
        const error = new Exception_1.FormatError("Block object is invalid.");
        throw error.toString();
    }
    return new Block_1.default(data);
}
exports.toBlock = toBlock;
/**
 * @description Convert transaction data into the right format.
 */
function toTransaction(data) {
    if (!(0, Util_1.hasProperties)(data, [
        ["txHash", "tx_hash"],
        "txIndex",
        "blockHeight",
        "blockHash",
    ]) ||
        !(0, Validator_1.checkDataInTransaction)(data)) {
        const error = new Exception_1.FormatError("Transaction object is invalid.");
        throw error.toString();
    }
    return new Transaction_1.default(data);
}
exports.toTransaction = toTransaction;
function checkStatusInTransaction(data) {
    if (data.status === "0x1" && !(0, Util_1.hasProperties)(data, ["eventLogs"])) {
        return false;
    }
    return !(data.status === "0x0" && !(0, Util_1.hasProperties)(data, ["failure"]));
}
/**
 * @description Convert transaction result data into the right format.
 */
function toTransactionResult(data) {
    if (!(0, Util_1.hasProperties)(data, [
        "status",
        "to",
        "txHash",
        "txIndex",
        "blockHeight",
        "blockHash",
        "cumulativeStepUsed",
        "stepUsed",
        "stepPrice",
    ]) ||
        !checkStatusInTransaction(data)) {
        const error = new Exception_1.FormatError("Transaction result object is invalid.");
        throw error.toString();
    }
    return new TransactionResult_1.default(data);
}
exports.toTransactionResult = toTransactionResult;
/**
 * @description Convert SCORE API list into the right format.
 */
function toScoreApiList(data) {
    if (!(0, Validator_1.isScoreApiList)(data)) {
        const error = new Exception_1.FormatError("SCORE API list is invalid.");
        throw error.toString();
    }
    return new ScoreApiList_1.default(data);
}
exports.toScoreApiList = toScoreApiList;
exports.default = {
    toBlock,
    toTransaction,
    toTransactionResult,
    toScoreApiList,
};
