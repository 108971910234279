"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("../index");
class EventNotification {
    constructor(data) {
        this.hash = data.hash;
        this.height = index_1.Converter.toBigNumber(data.height);
        this.index = index_1.Converter.toBigNumber(data.index);
        this.events = [];
        this.logs = [];
        if (data.events) {
            for (let i = 0; i < data.events.length; i++)
                this.events[i] = index_1.Converter.toBigNumber(data.events[i]);
        }
        if (data.logs) {
            for (let i = 0; i < data.logs.length; i++)
                this.logs[i] = data.logs[i];
        }
    }
}
exports.default = EventNotification;
