import React, { useState } from "react";
import ToggleSwitch from "../../common/ToggleSwitch.component";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
interface IDistrictsToggle {
  showZones: Record<number, boolean>;
  setShowZones: any;
  setToggleMarker: any;
  toggleMarker: boolean;
}

function ZoneSubCategoryToggle({ name, setShowZones, showZones, zoneId }: any) {
  return (
    <div className='zone_category_toggle'>
      <div>{name} zone</div>
      <ToggleSwitch
        checked={showZones[zoneId]}
        type={"medium"}
        onChange={(e: any) => {
          if (showZones[zoneId] != null && showZones[zoneId] != undefined)
            setShowZones((prev: any) => {
              return {
                ...prev,
                [zoneId]: e.target.checked,
              };
            });
        }}
        isDisabled={false}
      />
    </div>
  );
}
function ToggleMarker({ name, setToggleMarker, toggleMarker }: any) {
  return (
    <div className='zone_category_toggle'>
      <div>{name} zone</div>
      <ToggleSwitch
        checked={toggleMarker}
        type={"medium"}
        onChange={(e: any) => {
          setToggleMarker(!toggleMarker);
        }}
        isDisabled={false}
      />
    </div>
  );
}
function ZoneToggle({ setShowZones, showZones, setToggleMarker, toggleMarker }: IDistrictsToggle) {
  const [openAdvanceSettings, setopenAdvanceSettings] = useState<boolean>(false);
  return (
    <div className='toggle_component zone_toggle'>
      <div className='topBox'>
        <div className='infoBox'>
          <div className='title'>Show zones</div>
          <div className='subDesc'>View all 8 zones in the map</div>
        </div>
        <ToggleSwitch
          type={"large"}
          onChange={(e: any) => {
            setShowZones({
              1: e.target.checked,
              2: e.target.checked,
              3: e.target.checked,
              4: e.target.checked,
              5: e.target.checked,
              6: e.target.checked,
              7: e.target.checked,
              8: e.target.checked,
            });
          }}
          isDisabled={false}
        />
      </div>
      <div
        className='ads_cta'
        onClick={() => {
          setopenAdvanceSettings(!openAdvanceSettings);
        }}>
        Advance settings{" "}
        {openAdvanceSettings ? (
          <BiChevronUp className='down' />
        ) : (
          <BiChevronDown className='down' />
        )}
      </div>
      <div className={`advance_setting ${openAdvanceSettings ? "" : "close"}`}>
        <ZoneSubCategoryToggle
          name={"Recreational"}
          setShowZones={setShowZones}
          showZones={showZones}
          zoneId={1}
        />{" "}
        <ZoneSubCategoryToggle
          name={"Airport"}
          setShowZones={setShowZones}
          showZones={showZones}
          zoneId={2}
        />{" "}
        <ZoneSubCategoryToggle
          name={"Industrial"}
          setShowZones={setShowZones}
          showZones={showZones}
          zoneId={3}
        />{" "}
        <ZoneSubCategoryToggle
          name={"Governmental"}
          setShowZones={setShowZones}
          showZones={showZones}
          zoneId={4}
        />{" "}
        <ZoneSubCategoryToggle
          name={"Education"}
          setShowZones={setShowZones}
          showZones={showZones}
          zoneId={5}
        />{" "}
        <ZoneSubCategoryToggle
          name={"Health"}
          setShowZones={setShowZones}
          showZones={showZones}
          zoneId={6}
        />{" "}
        <ZoneSubCategoryToggle
          name={"Commercial"}
          setShowZones={setShowZones}
          showZones={showZones}
          zoneId={7}
        />{" "}
        <ZoneSubCategoryToggle
          name={"Residential"}
          setShowZones={setShowZones}
          showZones={showZones}
          zoneId={8}
        />
        <ToggleMarker
          name={"Toggle markers"}
          setToggleMarker={setToggleMarker}
          toggleMarker={toggleMarker}
        />{" "}
      </div>
    </div>
  );
}

export default ZoneToggle;
