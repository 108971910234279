import React, { useEffect, useState } from "react";
import { ICONS, MINI_GAMES } from "../../../../assets/assets";
import { abbreviateNumber, randomIntFromInterval } from "../../../../utils/helper";
import { getUser } from "../../../../services/data/rpsAPIs.services";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { E_RPS_URL_QUERY_ID, ZERO_ADDRESS } from "../../../../enums/RPS.enums";
import { useSearchParams } from "react-router-dom";
import { C_GBET_NFT_META_MAPPING } from "../../../../constants/gbet_nft_id";
import { useRPSContext } from "../../../../services/contexts/rps.context";
import { FaUserFriends } from "react-icons/fa";
import { RiMessage2Line } from "react-icons/ri";
import { GiTiedScroll } from "react-icons/gi";
import { GiPointySword } from "react-icons/gi";
import { GiScrollUnfurled } from "react-icons/gi";

function RPSGameCard({
  data,
  setcurrentSelectedOpenGame,
  currentSelectedOpenGame,
  preSelectedId,
  key,
}) {
  const { creator, tokenAddress, id, amount, message } = data;
  const { setIsOpeningGame, friendList } = useRPSContext();
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [nftId, setnftId] = useState<any>(null);
  const [name, setName] = useState<any>(null);
  const [race, setRace] = useState<any>(null);
  const [streak, setStreak] = useState<number>(0);
  const getGameType = (wishList: string, challengedAddress: string) => {
    if (challengedAddress != ZERO_ADDRESS) {
      return "challenge";
    } else if (wishList == "0x1") {
      return "friends";
    } else {
      return "public";
    }
  };
  const getUserDetails = async () => {
    console.log("getting user details rps");
    setnftId(null);
    setName(null);
    setRace(null);
    setStreak(0);
    const res = await getUser(creator);
    console.log(res);
    if (res) {
      if (res.data != false) {
        setnftId(res.data.nft_id);
        setName(res.data.name);
        setRace(res.data.nft_race);
        setStreak(res.data.stats.win_streak);
        console.log("preSelectedId == id", preSelectedId == id, preSelectedId, id);
        if (preSelectedId == id / 1) {
          setcurrentSelectedOpenGame({
            nftId: res.data.nft_id,
            creator,
            name: res.data.name,
            gameId: id,
            amount: parseInt(amount, 16) / Math.pow(10, 18),
            tokenAddress,
            race: res.data.nft_race,
            message,
          });
        }
      } else {
        setnftId(null);
      }
    }
  };
  useEffect(() => {
    getUserDetails();
    return () => {
      setnftId(null);
      setName(null);
      setRace(null);
      setStreak(0);
    };
  }, []);
  useEffect(() => {
    getUserDetails();
    return () => {
      setnftId(null);
      setName(null);
      setRace(null);
      setStreak(0);
    };
  }, [creator]);
  return (
    <div key={`key_${id}`}>
      <ReactTooltip id={`message_tt_${id}`} place={"bottom"} className='message_tt'>
        <div className='message_container'>
          <div className='paper'></div>
          <p className='msg_text'>{message}</p>
          <svg>
            <filter id='wavy2'>
              <feTurbulence x='0' y='0' baseFrequency='0.02' numOctaves='5' seed='1' />
              <feDisplacementMap in='SourceGraphic' scale='20' />
            </filter>
          </svg>
        </div>
      </ReactTooltip>
      <div
        className={`rps_open_game_item ${
          currentSelectedOpenGame?.gameId == id ? "selected" : "not_selected"
        } ${getGameType(data["wishList"], data["challengedAddress"])}`}
        onClick={() => {
          setIsOpeningGame(false);
          setcurrentSelectedOpenGame({
            nftId: nftId,
            creator,
            name,
            gameId: id,
            amount: parseInt(amount, 16) / Math.pow(10, 18),
            tokenAddress,
            race: race,
            message,
          });
          setSearchParams((params) => {
            params.set(E_RPS_URL_QUERY_ID.GAME_ID, `${id / 1}`);
            return params;
          });
        }}>
        <div className='open_game_pattern'>
          <img className='open_game_pattern' src={MINI_GAMES.RPS.PATTERN} />
        </div>
        <div className='rps_game_card_top'>
          <ReactTooltip id={"streak_bar_tooltip"} place={"top"} className='streak_bar_tooltip'>
            {streak} Win Streak
          </ReactTooltip>
          <div className='streak_bar' data-tooltip-id='streak_bar_tooltip'>
            {[...Array(streak)].map((e, i) => (
              <div
                className='streak_fire_log'
                style={{ marginRight: i == streak - 1 ? "0px" : "-10px" }}>
                <img src={ICONS.fire_log} />
              </div>
            ))}{" "}
          </div>

          <div className='game_details'>
            <img
              className='player_image'
              src={
                nftId
                  ? `https://d2l8dezwbqkb9.cloudfront.net/ipfs/img/${C_GBET_NFT_META_MAPPING[nftId]}.gif`
                  : MINI_GAMES.RPS.UNKNOWL_PLAYER
              }
            />
            <div className='player_details'>
              <p className='player_name'>
                <div
                  className={`value ${message.length >= 1 ? "has_message" : ""} ${
                    friendList.includes(creator) ? "is_friend" : ""
                  }`}>
                  {name ? (name as string)?.replace("GangstaBet", "GB") : creator}
                </div>
                {friendList.includes(creator) && <FaUserFriends className='friends_icon' />}
              </p>
              <p className='wager_amt'>
                <img
                  src={tokenAddress == ZERO_ADDRESS ? ICONS.iconex_logo : ICONS.crown_logo}
                  className='currency_type'
                />
                {abbreviateNumber(parseInt(amount, 16) / Math.pow(10, 18))}{" "}
                {tokenAddress == ZERO_ADDRESS ? "ICX" : "CROWN"}
                {getGameType(data["wishList"], data["challengedAddress"]) == "challenge" && (
                  <div className='challenge_icon'>
                    <GiPointySword className='sword' />
                  </div>
                )}
              </p>
            </div>
            {message.length >= 1 && (
              <div
                className='icons animate__animated animate__jackInTheBox'
                onMouseEnter={() => {
                  setMessageOpen((p) => true);
                }}
                onMouseLeave={() => {
                  setMessageOpen((p) => false);
                }}>
                {!messageOpen && (
                  <GiTiedScroll data-tooltip-id={`message_tt_${id}`} className='message_icon ' />
                )}
                {messageOpen && (
                  <GiScrollUnfurled
                    data-tooltip-id={`message_tt_${id}`}
                    className='message_icon animate__animated animate__flipInX'
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {/* <div className='message'>I choose scissor!</div> */}
      </div>
    </div>
  );
}

export default RPSGameCard;
