import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useRPSContext } from "./rps.context";

interface IWalletContext {
  isWalletConnected: boolean;
  walletAddress: string;
  setWalletConnectionStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setWalletAddress: React.Dispatch<React.SetStateAction<string>>;
  setHasPlayedDemo: React.Dispatch<React.SetStateAction<boolean>>;
  isWalletConnecting: boolean;
  hasPlayedDemo: boolean;
}

const WalletContext = createContext<IWalletContext>({
  isWalletConnected: false,
  isWalletConnecting: false,
  walletAddress: "",
  setWalletAddress: () => {},
  setWalletConnectionStatus: () => {},
  setHasPlayedDemo: () => {},
  hasPlayedDemo: false,
});

const WalletProvider = ({ children }: any) => {
  const [isWalletConnecting, setIsLoading] = useState<boolean>(true);
  const [isWalletConnected, setWalletConnectionStatus] = useState<boolean>(false);
  const [hasPlayedDemo, setHasPlayedDemo] = useState<boolean>(false);
  const [walletAddress, setWalletAddress] = useState<string>("");
  useEffect(() => {
    const add = localStorage.getItem("address");
    console.log(`Address: ${add}`);
    if (add) {
      setWalletAddress(add);
      setWalletConnectionStatus(true);
    }
  }, []);
  useEffect(() => {
    return () => {};
  }, [isWalletConnected, walletAddress]);

  return (
    <WalletContext.Provider
      value={{
        isWalletConnected,
        walletAddress,
        setWalletAddress,
        setWalletConnectionStatus,
        hasPlayedDemo,
        setHasPlayedDemo,
        isWalletConnecting,
      }}>
      {children}
    </WalletContext.Provider>
  );
};

const useWalletContext = () => {
  return useContext(WalletContext);
};

export { WalletProvider, useWalletContext };
