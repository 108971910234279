import { useEffect } from "react";
import { MINI_GAMES } from "../../../../assets/assets";
const don_davinci_dialogue_no_connection = [
  "NPC%v%(Apologetic tone) Hey there, Hate to break it to you, but the joint's closed for now. We're in the shadows, recharging for the next act. The night's young, though, so don't be a stranger. Come back when the neon lights flicker to life again.",
];
let timer_pls_connect: any = null;
let timer: any = null;

function CloseGameDialogue() {
  useEffect(() => {
    createDiag_pls_connect(don_davinci_dialogue_no_connection, 0);
    return () => {
      clearTimeout(timer as any);
      clearTimeout(timer_pls_connect as any);
    };
  }, []);
  function createDiag_pls_connect(dialogArray: any, indexCur: number) {
    const type = dialogArray[indexCur].split("%v%")[0];
    if (type == "NPC") {
      window.document.getElementById("npc_image")?.classList.add("isTalking");
      window.document.getElementById("player_image")?.classList.remove("isTalking");
    } else if (type == "PLAYER") {
      window.document.getElementById("player_image")?.classList.add("isTalking");
      window.document.getElementById("npc_image")?.classList.remove("isTalking");
    } else {
      window.document.getElementById("npc_image")?.classList.remove("isTalking");
      window.document.getElementById("player_image")?.classList.remove("isTalking");
    }
    const dialog = dialogArray[indexCur].split("%v%")[1].split("");
    let paragText = "";
    (document.getElementById("dialog") as HTMLDivElement).innerText = "";
    for (let i: number = 0; i < dialog.length; i++) {
      (function (i) {
        timer = setTimeout(function () {
          paragText += dialog[i];
          if (
            (document.getElementById("dialog") as HTMLDivElement)?.innerText != undefined &&
            (document.getElementById("dialog") as HTMLDivElement).innerText != null
          ) {
            (document.getElementById("dialog") as HTMLDivElement).innerText = paragText;

            if (i == dialog.length - 1) {
              var iDiv = document.createElement("div");
              iDiv.id = "arrow";
              iDiv.className = "arrow";
              iDiv.addEventListener("click", (e) => {
                (document.getElementById("arrow") as HTMLDivElement).remove();
                if (indexCur == dialogArray.length - 1) {
                  createDiag_pls_connect(dialogArray, indexCur);
                } else {
                  createDiag_pls_connect(dialogArray, indexCur + 1);
                }
              });
              (document.getElementById("decor") as HTMLDivElement).appendChild(iDiv);
            }
          }
        }, 50 * i);
      })(i);
    }
  }
  return (
    <div className='dia_container'>
      <div className='decor' id='decor'>
        <div className='image_1 animate__animated animate__fadeInUp' id='npc_image'>
          <img src='https://d2l8dezwbqkb9.cloudfront.net/png/2041.png' />
        </div>
        <div className='image_2' id='player_image'>
          <img src={MINI_GAMES.RPS.UNKNOWL_PLAYER} />
        </div>
        <div>
          <div className='p' id='dialog'></div>
        </div>
      </div>
    </div>
  );
}

export default CloseGameDialogue;
