"use strict";
/*
 * Copyright 2023 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const Converter_1 = require("../Converter");
class BTPNetworkInfo {
    constructor(data) {
        this.startHeight = (0, Converter_1.toBigNumber)(data.startHeight);
        this.networkTypeID = (0, Converter_1.toBigNumber)(data.networkTypeID);
        this.networkTypeName = data.networkTypeName;
        this.networkID = (0, Converter_1.toBigNumber)(data.networkID);
        this.networkName = data.networkName;
        this.open = (0, Converter_1.toBoolean)(data.open);
        this.owner = data.owner;
        this.nextMessageSN = (0, Converter_1.toBigNumber)(data.nextMessageSN);
        this.nextProofContextChanged = (0, Converter_1.toBoolean)(data.nextProofContextChanged);
        this.prevNSHash = data.prevNSHash;
        this.lastNSHash = data.lastNSHash;
    }
}
exports.default = BTPNetworkInfo;
