import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { getSchemaOrgJSONLD, og, twitterCard } from "../../utils/SEO";

const SiteMetaData = ({ siteMetadata, pageMetaData }: any) => {
  const { siteTitle, siteDescription, siteKeywords, siteUrl } = siteMetadata;

  const { pageTitle, pageDescription, pageKeywords, pageImage } = pageMetaData;

  const title = pageTitle || siteTitle;
  const description = pageDescription || siteDescription;
  const keywords = pageKeywords || siteKeywords;
  const coverImage =
    pageImage && pageImage.src
      ? pageImage.src
      : "https://gangstaverse.co/static/media/gangstaverse.a7bfd4b4ad859d93daa4e0e42c93dca3.svg";
  const imageUrl = coverImage;
  const schemaOrgConfig = getSchemaOrgJSONLD({ pageMetaData, siteMetadata });
  const canonicalUrl = `${siteUrl}${window.location.pathname}`;

  const helmetMeta = [
    {
      name: "description",
      content: description,
    },
    {
      name: "keywords",
      content: keywords,
    },
    { name: "author", content: "Gangstabet" },
    ...og({ title, description, image: imageUrl, siteUrl }),
    ...twitterCard({ title, description, image: imageUrl }),
  ];

  return (
    <Helmet title={title} meta={helmetMeta}>
      <html lang='en' />
      <link rel='canonical' href={canonicalUrl} />
      <script type='application/ld+json'>{JSON.stringify(schemaOrgConfig)}</script>
    </Helmet>
  );
};

SiteMetaData.defaultProps = {
  pageMetaData: {},
};

SiteMetaData.propTypes = {
  siteMetadata: PropTypes.object.isRequired,
  pageMetaData: PropTypes.object,
};

export default SiteMetaData;
