import React, { useEffect, useState } from "react";
import { getLatestLogs } from "../../../../services/data/rpsAPIs.services";
import { getTimeAgo } from "../../../../utils/helper";
import { useIconEventContext } from "../../../../services/contexts/iconEvent.context";
import { E_ICONEX_RPC_EVENT_TYPE } from "../../../../hooks/useIconexHook";
import { useWalletContext } from "../../../../services/contexts/wallet.context";
import {
  C_RPS_HAND,
  E_RPS_ACTION_CONTAINER,
  E_RPS_URL_QUERY_ID,
  T_RPS_GAME_TYPE,
} from "../../../../enums/RPS.enums";
import { useSearchParams } from "react-router-dom";
import { useRPSContext } from "../../../../services/contexts/rps.context";
import { REACT_APP_NETWORD_NID } from "../../../../services/data/config";
let logTimer: any = null;
function RPSLogsComponent() {
  const [currentLogs, setcurrentLogs] = useState([]);
  const { isTransactionLoading, currentEventType } = useIconEventContext();
  const { walletAddress } = useWalletContext();
  const getLogs = async () => {
    const res = await getLatestLogs();
    if (res?.logs == false) {
      setcurrentLogs([]);
    } else {
      setcurrentLogs(res?.logs);
    }
  };
  useEffect(() => {
    getLogs();
    logTimer = setInterval(() => {
      getLogs();
    }, 10000);
    return () => {
      clearInterval(logTimer);
    };
  }, []);
  const [searchParams, setSearchParams] = useSearchParams();
  const { setCurrentActionContainerSelected } = useRPSContext();

  useEffect(() => {
    console.log("update state found - refetching");
    getLogs();
    return () => {};
  }, [isTransactionLoading]);
  const getOpenedLogs = (
    data: {
      creatorName: string;
      creatorAddress: string;
      creatorChoice: number;
      playerChoice: number;
      playerName: string;
      playerAddress: string;
      wager: number;
      wagerType: string;
      challengedAddressName: string;
      challengedAddress: string;
      gameType: T_RPS_GAME_TYPE;
      result: string;
    },
    logs: string,
    gameId: string
  ) => {
    if (!data) {
      return <div className='message_box_log'>{logs}</div>;
    }
    const {
      creatorName,
      creatorAddress,
      creatorChoice,
      playerChoice,
      playerName,
      playerAddress,
      wager,
      wagerType,
      challengedAddress,
      challengedAddressName,
      gameType,
      result,
    } = data;
    if (gameId.includes("open")) {
      if (gameType == "FRIENDS") {
        return (
          <div className='message_box_log'>
            <span
              className='clickable inlink'
              onClick={() => {
                setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                setSearchParams((param) => {
                  param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, creatorAddress);
                  return param;
                });
              }}>
              {creatorName.length >= 40
                ? `${creatorName.slice(0, 5)}...${creatorName.slice(38)}`
                : creatorName}
            </span>{" "}
            opened a game with friends, for {wager} ${wagerType}.
          </div>
        );
      } else if (gameType == "ADDRESS") {
        return (
          <div className='message_box_log'>
            <span
              className='clickable inlink'
              onClick={() => {
                setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                setSearchParams((param) => {
                  param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, creatorAddress);
                  return param;
                });
              }}>
              {creatorName.length >= 40
                ? `${creatorName.slice(0, 5)}...${creatorName.slice(38)}`
                : creatorName}
            </span>{" "}
            has challenged{" "}
            <span
              className='clickable inlink'
              onClick={() => {
                setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                setSearchParams((param) => {
                  param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, challengedAddress);
                  return param;
                });
              }}>
              {challengedAddressName.length >= 40
                ? `${challengedAddressName.slice(0, 5)}...${challengedAddressName.slice(38)}`
                : challengedAddressName}
            </span>{" "}
            for a duel, for {wager} ${wagerType}.
          </div>
        );
      } else {
        return (
          <div className='message_box_log'>
            <span
              className='clickable inlink'
              onClick={() => {
                setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                setSearchParams((param) => {
                  param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, creatorAddress);
                  return param;
                });
              }}>
              {creatorName.length >= 40
                ? `${creatorName.slice(0, 5)}...${creatorName.slice(38)}`
                : creatorName}
            </span>{" "}
            opened a game for {wager} ${wagerType}.
          </div>
        );
      }
    } else if (gameId.includes("result")) {
      if (gameType == "ADDRESS") {
        if (result == "DRAW") {
          return (
            <div className='message_box_log'>
              <span
                className='clickable inlink'
                onClick={() => {
                  setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                  setSearchParams((param) => {
                    param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, playerAddress);
                    return param;
                  });
                }}>
                {playerName.length >= 40
                  ? `${playerName.slice(0, 5)}...${playerName.slice(38)}`
                  : playerName}
              </span>{" "}
              matched with {[C_RPS_HAND[playerChoice]]} against{" "}
              <span
                className='clickable inlink'
                onClick={() => {
                  setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                  setSearchParams((param) => {
                    param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, creatorAddress);
                    return param;
                  });
                }}>
                {creatorName.length >= 40
                  ? `${creatorName.slice(0, 5)}...${creatorName.slice(38)}`
                  : creatorName}
              </span>
              's {[C_RPS_HAND[creatorChoice]]}, for a wager of {wager} ${wagerType}.
            </div>
          );
        } else {
          return (
            <div className='message_box_log'>
              <span
                className='clickable inlink'
                onClick={() => {
                  setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                  setSearchParams((param) => {
                    param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, playerAddress);
                    return param;
                  });
                }}>
                {playerName.length >= 40
                  ? `${playerName.slice(0, 5)}...${playerName.slice(38)}`
                  : playerName}
              </span>{" "}
              have {result.toLowerCase()} the challenge with {[C_RPS_HAND[playerChoice]]} against{" "}
              <span
                className='clickable inlink'
                onClick={() => {
                  setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                  setSearchParams((param) => {
                    param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, creatorAddress);
                    return param;
                  });
                }}>
                {creatorName.length >= 40
                  ? `${creatorName.slice(0, 5)}...${creatorName.slice(38)}`
                  : creatorName}
              </span>
              's {[C_RPS_HAND[creatorChoice]]}, for a wager of {wager} ${wagerType}.
            </div>
          );
        }
      } else {
        if (result == "DRAW") {
          return (
            <div className='message_box_log'>
              <span
                className='clickable inlink'
                onClick={() => {
                  setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                  setSearchParams((param) => {
                    param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, playerAddress);
                    return param;
                  });
                }}>
                {playerName.length >= 40
                  ? `${playerName.slice(0, 5)}...${playerName.slice(38)}`
                  : playerName}
              </span>{" "}
              and{" "}
              <span
                className='clickable inlink'
                onClick={() => {
                  setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                  setSearchParams((param) => {
                    param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, creatorAddress);
                    return param;
                  });
                }}>
                {creatorName.length >= 40
                  ? `${creatorName.slice(0, 5)}...${creatorName.slice(38)}`
                  : creatorName}
              </span>{" "}
              drew with {[C_RPS_HAND[playerChoice]]} for a wager of {wager}${wagerType}.
            </div>
          );
        } else {
          return (
            <div className='message_box_log'>
              <span
                className='clickable inlink'
                onClick={() => {
                  setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                  setSearchParams((param) => {
                    param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, playerAddress);
                    return param;
                  });
                }}>
                {playerName.length >= 40
                  ? `${playerAddress.slice(0, 5)}...${playerAddress.slice(38)}`
                  : playerName}
              </span>{" "}
              have {result.toLowerCase()} with {[C_RPS_HAND[playerChoice]]} against{" "}
              <span
                className='clickable inlink'
                onClick={() => {
                  setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                  setSearchParams((param) => {
                    param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, creatorAddress);
                    return param;
                  });
                }}>
                {creatorName.length >= 40
                  ? `${creatorName.slice(0, 5)}...${creatorName.slice(38)}`
                  : creatorName}
              </span>
              's {[C_RPS_HAND[creatorChoice]]} for a wager of {wager} ${wagerType}.
            </div>
          );
        }
      }
    } else if (gameId.includes("cancel")) {
      if (gameType == "FRIENDS" || gameType == "PUBLIC") {
        return (
          <div className='message_box_log'>
            <span
              className='clickable inlink'
              onClick={() => {
                setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                setSearchParams((param) => {
                  param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, creatorAddress);
                  return param;
                });
              }}>
              {creatorName.length >= 40
                ? `${creatorName.slice(0, 5)}...${creatorName.slice(38)}`
                : creatorName}
            </span>{" "}
            closed a game for {wager} {wagerType}.
          </div>
        );
      } else {
        return (
          <div className='message_box_log'>
            <span
              className='clickable inlink'
              onClick={() => {
                setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                setSearchParams((param) => {
                  param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, creatorAddress);
                  return param;
                });
              }}>
              {creatorName.length >= 40
                ? `${creatorName.slice(0, 5)}...${creatorName.slice(38)}`
                : creatorName}
            </span>{" "}
            has closed a challenged game to {challengedAddressName}, for the wager of {wager} $
            {wagerType}.
          </div>
        );
      }
    }
  };
  return (
    <div className='rps_log'>
      <div className='menu_items'>
        <div>Activities</div>
      </div>
      <div
        className={`logs ${
          currentEventType == E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_RESULT_SUCCESS ? "blur" : ""
        }`}>
        {currentLogs?.length >= 1
          ? currentLogs.map((data, inde) => {
              return (
                <div className='log_message self lost' key={inde}>
                  <div className='message_details'>
                    <div className='time'>{getTimeAgo(data["createdDate"])}</div>|{" "}
                    <a
                      href={
                        REACT_APP_NETWORD_NID == "1"
                          ? `https://tracker.icon.community/transaction/${data["txHash"]}`
                          : `https://tracker.lisbon.icon.community/transaction/${data["txHash"]}`
                      }
                      target='_blank'
                      className='txHash'>
                      {(data["txHash"] as string)?.slice(0, 10)}...
                    </a>
                  </div>
                  <div className='message'>
                    {/* {data["logs"][0]} */}
                    {getOpenedLogs(data["meta"], data["logs"][0], data["gameId"])}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default RPSLogsComponent;
