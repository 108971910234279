import React, { useState } from "react";
import DistrictsToggle from "./sideMenuComponents/DistrictsToggle.component";
import ZoneToggle from "./sideMenuComponents/ZoneToggle.component";
import { ISideMenu } from "../../interfaces/sideMenu.interface";
import MintedLandsLayers from "../leaflet/layers/AllLands.layers";
import MintedLandsToggle from "./sideMenuComponents/MintedLandsToggle.component";
import ConnectWallet from "./sideMenuComponents/ConnectWallet.component";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import HeaderLogo from "../common/HeaderLogo.component";
import { useWalletContext } from "../../services/contexts/wallet.context";
import SocialComponent from "./sideMenuComponents/Social.component";
import FilterLandToggle from "./sideMenuComponents/FilterLands.component";
function SideMenu({
  setShowDistrict,
  showDistrict,
  setShowZones,
  showZones,
  setShowMintedLands,
  showMintedLands,
  setShowMyLands,
  showMyLands,
  setToggleMarker,
  toggleMarker,
  selectedFilterSubCategory,
  setselectedFilterSubCategory,
}: any) {
  const [isOpen, setisOpen] = useState<boolean>(false);
  return (
    <>
      <HeaderLogo isOpen={isOpen} setisOpen={setisOpen} />
      <div className={`side_menu_container ${isOpen ? "open" : "close"}`}>
        <div className='fill_container_side_menu'>
          <div className='side_menu_box'>
            <DistrictsToggle setShowDistrict={setShowDistrict} showDistrict={showDistrict} />
            <hr />
            <ZoneToggle
              setToggleMarker={setToggleMarker}
              toggleMarker={toggleMarker}
              setShowZones={setShowZones}
              showZones={showZones}
            />
            <hr />
            <MintedLandsToggle
              setShowMintedLands={setShowMintedLands}
              showMintedLands={showMintedLands}
            />
            <hr />
            <ConnectWallet showMyLands={showMyLands} setShowMyLands={setShowMyLands} />
            <hr />
            <FilterLandToggle
              selectedFilterSubCategory={selectedFilterSubCategory}
              setselectedFilterSubCategory={setselectedFilterSubCategory}
            />
          </div>{" "}
          <div>
            <SocialComponent />
          </div>
        </div>

        <div
          className='button'
          onClick={() => {
            setisOpen(!isOpen);
          }}>
          {" "}
          {isOpen ? (
            <AiOutlineCaretLeft className='icon' />
          ) : (
            <AiOutlineCaretRight className='icon' />
          )}
        </div>
      </div>
    </>
  );
}

export default SideMenu;
