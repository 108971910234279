import React, { useEffect, useState } from "react";
import { MINI_GAMES } from "../../assets/assets";
import RPSopenGame from "./component/common/RPS.openGame";
import { E_RPS_ACTION_CONTAINER, E_RPS_URL_QUERY_ID } from "../../enums/RPS.enums";
import PlayRPSGames from "./component/play/PlayRPSGames.component";
import RPSLobby from "./component/lobby/Lobby.component";
import { useRPSContext } from "../../services/contexts/rps.context";
import { getRoundInfo, getUser } from "../../services/data/rpsAPIs.services";
import { useWalletContext } from "../../services/contexts/wallet.context";
import { checkBackendHealth, getHighestGangstaBet } from "../../services/data/data.service";
import RPSLogsComponent from "./component/play/RPSLogs.component";
import { FaCirclePlus, FaFileSignature } from "react-icons/fa6";
import { useIconEventContext } from "../../services/contexts/iconEvent.context";
import { E_ICONEX_RPC_EVENT_TYPE } from "../../hooks/useIconexHook";
import RPSBattleFieldPlaying from "./component/play/battleField/RPSBattleFieldPlaying.component";
import FindRPSGangster from "./component/findGangster/FindRPSGangster.components";
import RPSHistoryTable from "./component/myHistories/HistoryTable.component";
import rpsService from "../../services/contract/rps.service";
import { useSearchParams } from "react-router-dom";
import CloseGameDialogue from "./component/dialogues/ClosedGame.dia";
import PopUpRPSModalForMessage from "./component/common/PopUpRPSModalForMessage";
import RPSLeaderBoard from "./component/leaderboard/LeaderBoard";
import { capitalize } from "../../utils/helper";
import MyProfile from "./component/findGangster/MyProfile";
import RoundOver from "./component/common/RoundOver";
import RewardsComponents from "./component/rewards/Rewards";

function RPSMain() {
    const {
        setIsNew,
        setNftId,
        setUserName,
        setNftRace,
        setUserStat,
        currentActionContainerSelected,
        setCurrentActionContainerSelected,
        setlastPlayed,
        setFriendList,
        challengedGameNumber,
        roundInfo,
        setRoundInfo,
    } = useRPSContext();
    const [isGameClosed, setisGameClosed] = useState<boolean>(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const [mobileOpenGameButton, setmobileOpenGameButton] = useState<boolean>(false);
    const [isRoundOver, setIsRoundOver] = useState<boolean>(false);
    const { isWalletConnected, walletAddress } = useWalletContext();
    const { currentEventType, isTransactionLoading } = useIconEventContext();
    const getFriends = async () => {
        const response = await rpsService.getFriendList(walletAddress);
        console.log("friends", response);
        setFriendList(response as string[]);
    };
    useEffect(() => {
        setSearchParams((params) => {
            params.set(E_RPS_URL_QUERY_ID.ROOM, currentActionContainerSelected);
            return params;
        });
        if (searchParams.get(E_RPS_URL_QUERY_ID.FIND_GANGSTER)) {
            setSearchParams((param) => {
                param.delete(E_RPS_URL_QUERY_ID.FIND_GANGSTER);
                return param;
            });
        }

        return () => {};
    }, [currentActionContainerSelected]);
    const checkIsGameClose = async () => {
        try {
            const status = await checkBackendHealth();
            setisGameClosed(!status);
        } catch (error) {
            setisGameClosed(true);
        }
    };
    const getUserDetails = async () => {
        if (!isWalletConnected) return;
        console.log("getting user details rps");
        getFriends();
        const res = await getUser(walletAddress);
        console.log(res);
        if (res?.data == false || !res) {
            console.log("new Player!");
            setIsNew(true);
            const gangstaBetDetail = await getHighestGangstaBet(walletAddress);
            console.log(gangstaBetDetail);
            if (gangstaBetDetail) {
                setNftRace(gangstaBetDetail?.nft_race);
                setNftId(gangstaBetDetail?.nftId);
                setUserName(gangstaBetDetail?.name);
            } else {
                setNftId(null);
                setNftRace(null);
                setUserName(null);
            }
        } else {
            console.log("old Player!");
            setIsNew(false);
            setNftId(res.data?.nft_id);
            setNftRace(res.data?.nft_race);
            setUserName(res.data?.name);
            setUserStat({ ...res.data?.stats, ...res.data?.leaderboard });
            setlastPlayed(res.data?.updatedAt);
        }
    };
    const urlDecryptor = () => {
        const room = searchParams.get(E_RPS_URL_QUERY_ID.ROOM);
        if (room && Object.values(E_RPS_ACTION_CONTAINER).includes(room as any)) {
            setCurrentActionContainerSelected(room as E_RPS_ACTION_CONTAINER);
        }
        console.log("searchParams", room);
    };

    const getCurrentRoundInfo = async () => {
        try {
            const res = await getRoundInfo();
            console.log(`round info`, res.data);
            setRoundInfo(res.data);
            const timeleft = new Date(res.data.roundEndTime).getTime() - new Date().getTime();
            console.log(`timeLeft: ${timeleft}`);
            if (timeleft <= 0) {
                setIsRoundOver(true);
            }
        } catch (error) {}
    };
    useEffect(() => {
        getCurrentRoundInfo();
        checkIsGameClose();
        console.log(`is wallet conected: ${isWalletConnected}`);

        urlDecryptor();
        if (isWalletConnected) getUserDetails();
        return () => {};
    }, [, isWalletConnected]);

    return (
        <div className="rps_main">
            {isRoundOver ? (
                <div className="dialogue_rps_main bg_battle_room">
                    <RoundOver />
                </div>
            ) : (
                <>
                    {isTransactionLoading &&
                    currentEventType == E_ICONEX_RPC_EVENT_TYPE.MESSAGE_SIGNING_PENGING ? (
                        <div className="dialogue_rps_main bg_battle_room">
                            <PopUpRPSModalForMessage
                                title="We need your signature"
                                subTitle="You need to sign to open a game."
                                icon={<FaFileSignature />}
                            />
                        </div>
                    ) : null}
                    {isTransactionLoading &&
                    currentEventType == E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_PENDING ? (
                        <div className="dialogue_rps_main bg_battle_room">
                            <PopUpRPSModalForMessage
                                title="Waiting for the transaction."
                                subTitle="Hosting a game..."
                                icon={<FaFileSignature />}
                            />
                        </div>
                    ) : null}{" "}
                    {isTransactionLoading &&
                    currentEventType == E_ICONEX_RPC_EVENT_TYPE.CLAIMING_REWARD_PENDING ? (
                        <div className="dialogue_rps_main bg_battle_room">
                            <PopUpRPSModalForMessage
                                title="Waiting for the transaction."
                                subTitle="Claiming reward..."
                                icon={<FaFileSignature />}
                            />
                        </div>
                    ) : null}{" "}
                    {isTransactionLoading &&
                    currentEventType == E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_PENDING ? (
                        <div className="dialogue_rps_main bg_battle_room">
                            <PopUpRPSModalForMessage
                                title="Waiting for the transaction."
                                subTitle="Adding a friend..."
                                icon={<FaFileSignature />}
                            />
                        </div>
                    ) : null}
                    {isTransactionLoading &&
                    currentEventType == E_ICONEX_RPC_EVENT_TYPE.CANCELING_GAME_PENDING ? (
                        <div className="dialogue_rps_main bg_battle_room">
                            <PopUpRPSModalForMessage
                                title="Waiting for the transaction."
                                subTitle="Closing a game..."
                                icon={<FaFileSignature />}
                            />
                        </div>
                    ) : null}{" "}
                    {isTransactionLoading &&
                    currentEventType == E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_PENDING ? (
                        <div className="dialogue_rps_main bg_battle_room">
                            <PopUpRPSModalForMessage
                                title="Waiting for the transaction."
                                subTitle="Playing a game..."
                                icon={<FaFileSignature />}
                            />
                        </div>
                    ) : null}
                    {isGameClosed && (
                        <div className="dialogue_rps_main bg_battle_room">
                            <CloseGameDialogue />
                        </div>
                    )}
                    {currentEventType == E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_RESULT_SUCCESS ||
                    currentEventType == E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_PROCESSING_RESULT ? (
                        <div className="battle_playing">
                            <div className="battle_middle">
                                <RPSBattleFieldPlaying />
                            </div>
                        </div>
                    ) : null}
                    <div
                        className={`mobile_open_game_container  ${
                            mobileOpenGameButton ? "opened" : ""
                        } `}
                    >
                        <RPSopenGame />
                    </div>
                    <div
                        className={`mobile_play_gameButton `}
                        onClick={() => {
                            setmobileOpenGameButton(!mobileOpenGameButton);
                        }}
                    >
                        <FaCirclePlus
                            className={`FaCirclePlus ${mobileOpenGameButton ? "selected" : ""}`}
                        />
                    </div>
                    <div className="rps_left_container">
                        <div className="intro_section">
                            <div className="intro_section_left">
                                <div className="intro_section_title">
                                    Rock, Paper, Scissor (RPS)
                                </div>
                                <p className="intro_section_description">
                                    Rediscover the thrill of a timeless childhood favorite – Rock,
                                    Paper, Scissors (RPS) – now brought to life in an exciting,
                                    modern twist. Challenge friends and rivals and immerse yourself
                                    in the nostalgia of a game loved by generations.
                                </p>
                            </div>
                            <img src={MINI_GAMES.RPS.TWO_PLAYERS} alt="two_players_battle" />
                        </div>
                        <div className="container_menu">
                            {Object.keys(E_RPS_ACTION_CONTAINER).map((data, index) => {
                                if (E_RPS_ACTION_CONTAINER[data] != E_RPS_ACTION_CONTAINER["LOGS"])
                                    return (
                                        <div
                                            key={index}
                                            className={`container_menu_name ${
                                                currentActionContainerSelected ==
                                                    E_RPS_ACTION_CONTAINER[data] && "selected"
                                            }`}
                                            onClick={() => {
                                                setCurrentActionContainerSelected(
                                                    E_RPS_ACTION_CONTAINER[data]
                                                );
                                            }}
                                        >
                                            {capitalize(E_RPS_ACTION_CONTAINER[data] as string)}
                                            {E_RPS_ACTION_CONTAINER[data] ==
                                                E_RPS_ACTION_CONTAINER.MY_GAMES &&
                                                challengedGameNumber >= 1 && (
                                                    <span className="icon-button__badge">
                                                        {challengedGameNumber}
                                                    </span>
                                                )}
                                        </div>
                                    );
                            })}
                            <div
                                className={`mobile_logs ${
                                    currentActionContainerSelected ==
                                        E_RPS_ACTION_CONTAINER["LOGS"] && "selected"
                                }`}
                                onClick={() => {
                                    setCurrentActionContainerSelected(
                                        E_RPS_ACTION_CONTAINER["LOGS"]
                                    );
                                }}
                            >
                                {E_RPS_ACTION_CONTAINER["LOGS"]}
                            </div>
                            <div
                                className={`container_menu_name`}
                                onClick={() => {
                                    window.open(
                                        `https://gangstaverse.medium.com/rock-paper-scissors-rps-mini-game-4252f842ef26`,
                                        "_blank"
                                    );
                                }}
                            >
                                Learn More
                            </div>
                        </div>

                        <div className="action_container">
                            {currentActionContainerSelected == E_RPS_ACTION_CONTAINER.PLAY && (
                                <PlayRPSGames />
                            )}
                            {/* {currentActionContainerSelected == E_RPS_ACTION_CONTAINER.LOCkER_ROOM && <RPSLobby />} */}
                            {currentActionContainerSelected ==
                                E_RPS_ACTION_CONTAINER.LEADER_BOARD && <RPSLeaderBoard />}{" "}
                            {currentActionContainerSelected == E_RPS_ACTION_CONTAINER.LOGS && (
                                <RPSLogsComponent />
                            )}
                            {currentActionContainerSelected ==
                                E_RPS_ACTION_CONTAINER.FIND_GANGSTER && (
                                <FindRPSGangster getUserDetails={getUserDetails} />
                            )}{" "}
                            {currentActionContainerSelected ==
                                E_RPS_ACTION_CONTAINER.MY_PROFILE && (
                                <MyProfile getUserDetails={getUserDetails} />
                            )}
                            {currentActionContainerSelected == E_RPS_ACTION_CONTAINER.MY_GAMES && (
                                <RPSHistoryTable />
                            )}{" "}
                            {currentActionContainerSelected == E_RPS_ACTION_CONTAINER.REWARD_ROOM &&
                                isWalletConnected && <RewardsComponents />}
                        </div>
                    </div>
                    <div className="rps_right_container">
                        {/* <RPSopenGame /> */}
                        <RPSLogsComponent />
                    </div>
                </>
            )}
        </div>
    );
}

export default RPSMain;
