"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeCxPrefix = exports.removeHxPrefix = exports.remove0xPrefix = exports.addCxPrefix = exports.addHxPrefix = exports.add0xPrefix = exports.isCxPrefix = exports.isHxPrefix = exports.is0xPrefix = void 0;
function addPrefix(checkers, prefix, str) {
    for (let i = 0; i < checkers.length; i += 1) {
        if (checkers[i](str)) {
            return str;
        }
    }
    return prefix + str;
}
function removePrefix(checkFunc, str) {
    if (checkFunc(str)) {
        return str.substr(2);
    }
    return str;
}
/**
 * Check whether string starts with '0x' prefix.
 * @param {string} str - the string.
 * @return {boolean} returns true if string starts with '0x' prefix.
 */
function is0xPrefix(str) {
    return /^(0x)/i.test(str);
}
exports.is0xPrefix = is0xPrefix;
/**
 * Check whether string starts with 'hx' prefix.
 * @param {string} str - the string.
 * @return {boolean} returns true if string starts with 'hx' prefix.
 */
function isHxPrefix(str) {
    return /^(hx)/i.test(str);
}
exports.isHxPrefix = isHxPrefix;
/**
 * Check whether string starts with 'cx' prefix.
 * @param {string} str - the string.
 * @return {boolean} returns true if string starts with 'cx' prefix.
 */
function isCxPrefix(str) {
    return /^(cx)/i.test(str);
}
exports.isCxPrefix = isCxPrefix;
/**
 * Add '0x' prefix to string.
 * @param {string} str - the string.
 * @return {string} the string with '0x' prefix.
 */
function add0xPrefix(str) {
    return addPrefix([is0xPrefix], "0x", str);
}
exports.add0xPrefix = add0xPrefix;
/**
 * Add 'hx' prefix to string.
 * @param {string} str - the string.
 * @return {string} the string with 'hx' prefix.
 */
function addHxPrefix(str) {
    return addPrefix([isHxPrefix, isCxPrefix], "hx", str);
}
exports.addHxPrefix = addHxPrefix;
/**
 * Add 'cx' prefix to string.
 * @param {string} str - the string.
 * @return {string} the string with 'cx' prefix.
 */
function addCxPrefix(str) {
    return addPrefix([isHxPrefix, isCxPrefix], "cx", str);
}
exports.addCxPrefix = addCxPrefix;
/**
 * remove '0x' prefix from string.
 * @param {string} str - the string.
 * @return {string} the string without '0x' prefix.
 */
function remove0xPrefix(str) {
    return removePrefix(is0xPrefix, str);
}
exports.remove0xPrefix = remove0xPrefix;
/**
 * remove 'hx' prefix from string.
 * @param {string} str - the string.
 * @return {string} the string without 'hx' prefix.
 */
function removeHxPrefix(str) {
    return removePrefix(isHxPrefix, str);
}
exports.removeHxPrefix = removeHxPrefix;
/**
 * remove 'cx' prefix from string.
 * @param {string} str - the string.
 * @return {string} the string without 'cx' prefix.
 */
function removeCxPrefix(str) {
    return removePrefix(isCxPrefix, str);
}
exports.removeCxPrefix = removeCxPrefix;
