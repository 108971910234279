"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTransactionResult = exports.isScoreApiList = exports.isScoreApi = exports.isBlock = exports.checkTxData = exports.isSignedTransaction = exports.checkDataInTransaction = exports.isCall = exports.isValidHash = exports.isPredefinedBlockValue = exports.isNonNegative = exports.hasBlank = exports.hasUppercase = exports.isAddress = exports.isScoreAddress = exports.isEoaAddress = exports.isPrivateKey = void 0;
const Type_1 = require("./Type");
const Util_1 = require("./Util");
const Converter_1 = require("./Converter");
const secp256k1 = require("secp256k1");
/**
 * Check if input value is a private key.
 * @param {any} privKey - the input value.
 * @return {boolean} returns true if the input value is a private key.
 */
function isPrivateKey(privKey) {
    if (!privKey) {
        return false;
    }
    if (typeof privKey === "string") {
        return /^[0-9a-f]{64}$/g.test(privKey) && /\S/g.test(privKey);
    }
    return secp256k1.privateKeyVerify(Buffer.from(privKey, "hex"));
}
exports.isPrivateKey = isPrivateKey;
/**
 * Check if input value is a EOA address.
 * @param {any} address - the input value.
 * @return {boolean} returns true if the input value is a EOA address.
 */
function isEoaAddress(address) {
    return /^(hx)[0-9a-f]{40}$/g.test(address) && /\S/g.test(address);
}
exports.isEoaAddress = isEoaAddress;
/**
 * Check if input value is a SCORE address.
 * @param {any} address - the input value.
 * @return {boolean} returns true if the input value is a SCORE address.
 */
function isScoreAddress(address) {
    return /^(cx)[0-9a-f]{40}$/g.test(address) && /\S/g.test(address);
}
exports.isScoreAddress = isScoreAddress;
/**
 * Check if input value is a EOA or SCORE address.
 * @param {any} address - the input value.
 * @return {boolean} returns true if the input value is a EOA or SCORE address.
 */
function isAddress(address) {
    return isEoaAddress(address) || isScoreAddress(address);
}
exports.isAddress = isAddress;
/**
 * Check if input value has upper case letter.
 * @param {any} value - the input value.
 * @return {boolean} returns true if the input value has upper case letter.
 */
function hasUppercase(value) {
    return /[A-Z]/g.test(value);
}
exports.hasUppercase = hasUppercase;
/**
 * Check if input value has blank.
 * @param {any} value - the input value.
 * @return {boolean} returns true if the input value has blank.
 */
function hasBlank(value) {
    return /\s/g.test(value);
}
exports.hasBlank = hasBlank;
/**
 * Check if input value is a block number.
 * @param {any} value - the input value.
 * @return {boolean} returns true if the input value is a block number.
 */
function isNonNegative(value) {
    try {
        if (hasUppercase(value.toString()) || hasBlank(value)) {
            return false;
        }
        const number = (0, Converter_1.toBigNumber)(value);
        const min = (0, Converter_1.toBigNumber)(0);
        const max = (0, Converter_1.toBigNumber)(Math.pow(2, 256));
        return (number.isInteger() &&
            number.isGreaterThanOrEqualTo(min) &&
            number.isLessThan(max));
    }
    catch (e) {
        return false;
    }
}
exports.isNonNegative = isNonNegative;
/**
 * Check if input value is 'latest'.
 * @param {any} value - the input value.
 * @return {boolean} returns true if the input value is 'latest'.
 */
function isPredefinedBlockValue(value) {
    return value === "latest";
}
exports.isPredefinedBlockValue = isPredefinedBlockValue;
/**
 * Check if input value is hash.
 * @param {any} hash - the input value.
 * @return {boolean} returns true if the input value is transaction hash.
 */
function isValidHash(hash) {
    return /^(0x)[0-9a-f]{64}$/g.test(hash) && /\S/g.test(hash);
}
exports.isValidHash = isValidHash;
/**
 * Check if input value is 'call' object.
 * @param {Call|object} call - the input value.
 * @return {boolean} returns true if the input value is 'call' object.
 */
function isCall(call) {
    return ((0, Util_1.hasProperties)(call, ["to", "data", "dataType"]) &&
        (0, Util_1.hasProperties)(call.data, ["method"]) &&
        isScoreAddress(call.to) &&
        call.dataType === "call");
}
exports.isCall = isCall;
/**
 * Check if an input transaction object has a data properties properly.
 * @param {IcxTransaction|CallTransaction|DeployTransaction|MessageTransaction|object} transaction
 * @return {boolean} returns true if the input transaction object has a data properties properly.
 */
function checkDataInTransaction(transaction) {
    switch (transaction.dataType) {
        case "call": {
            return (Object.prototype.hasOwnProperty.call(transaction, "data") &&
                (0, Util_1.hasProperties)(transaction.data, ["method"]));
        }
        case "deploy": {
            return (Object.prototype.hasOwnProperty.call(transaction, "data") &&
                (0, Util_1.hasProperties)(transaction.data, ["contentType", "content"]) &&
                (0, Type_1.isHex)(transaction.data.content));
        }
        case "message": {
            return (Object.prototype.hasOwnProperty.call(transaction, "data") &&
                (0, Type_1.isHex)(transaction.data));
        }
        case "base": {
            return Object.prototype.hasOwnProperty.call(transaction, "data");
        }
        case "deposit": {
            return (Object.prototype.hasOwnProperty.call(transaction, "data") &&
                (0, Util_1.hasProperties)(transaction.data, ["action"]));
        }
        default: {
            return true;
        }
    }
}
exports.checkDataInTransaction = checkDataInTransaction;
/**
 * Check if an input transaction object is a signed transaction object.
 * @param {SignedTransaction|object} signedTransaction - the transaction object.
 * @return {boolean} returns true if an input transaction object is a signed transaction object.
 */
function isSignedTransaction(signedTransaction) {
    const transaction = signedTransaction.getProperties();
    if (!(0, Util_1.hasProperties)(transaction, ["stepLimit", "signature"]) ||
        !(0, Type_1.isHex)(transaction.stepLimit)) {
        return false;
    }
    return checkTxData(transaction);
}
exports.isSignedTransaction = isSignedTransaction;
/**
 * Check if an input transaction object is valid.
 * @param {object} txData - the transaction object.
 * @return {boolean} returns true if an input transaction object is a signed transaction object.
 */
function checkTxData(txData) {
    if (!(0, Util_1.hasProperties)(txData, ["to", "from", "nid", "version", "timestamp"]) ||
        !isAddress(txData.to) ||
        !isAddress(txData.from) ||
        !(0, Type_1.isHex)(txData.nid) ||
        !(0, Type_1.isHex)(txData.version) ||
        !(0, Type_1.isHex)(txData.timestamp)) {
        return false;
    }
    if (Object.prototype.hasOwnProperty.call(txData, "value") &&
        !(0, Type_1.isHex)(txData.value)) {
        return false;
    }
    if (Object.prototype.hasOwnProperty.call(txData, "nonce") &&
        !(0, Type_1.isHex)(txData.nonce)) {
        return false;
    }
    return checkDataInTransaction(txData);
}
exports.checkTxData = checkTxData;
/**
 * Check if an input object is a block object.
 * @param {Block|object} block - the block object.
 * @return {boolean} returns true if an input object is a block object.
 */
function isBlock(block) {
    return (0, Util_1.hasProperties)(block, [
        "height",
        ["block_hash", "blockHash"],
        ["merkle_tree_root_hash", "merkleTreeRootHash"],
        ["prev_block_hash", "prevBlockHash"],
        ["peer_id", "peerId"],
        ["confirmed_transaction_list", "confirmedTransactionList"],
        "signature",
        ["time_stamp", "timestamp", "timeStamp"],
        "version",
    ]);
}
exports.isBlock = isBlock;
function isScoreApi(api) {
    return (0, Util_1.hasProperties)(api, ["type", "name"]);
}
exports.isScoreApi = isScoreApi;
/**
 * Check if an input object is a ScoreApiList object.
 * @param {object} scoreApiList - the input object.
 * @return {boolean} returns true if an input object is a ScoreApiList object.
 */
function isScoreApiList(scoreApiList) {
    if (!(0, Type_1.isArray)(scoreApiList)) {
        return false;
    }
    return scoreApiList.every((api) => isScoreApi(api));
}
exports.isScoreApiList = isScoreApiList;
/**
 * Check if an input object is a transaction result object.
 * @param {object} transaction - the input object.
 * @return {boolean} returns true if an input object is a transaction result object.
 */
function isTransactionResult(transaction) {
    return (0, Util_1.hasProperties)(transaction, [
        "status",
        "to",
        "txHash",
        "txIndex",
        "blockHeight",
        "blockHash",
        "cumulativeStepUsed",
        "stepUsed",
        "stepPrice",
    ]);
}
exports.isTransactionResult = isTransactionResult;
