"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Exception_1 = require("../../Exception");
const index_1 = require("../../data/index");
var State;
(function (State) {
    State[State["INIT"] = 0] = "INIT";
    State[State["CONNECT"] = 1] = "CONNECT";
    State[State["START"] = 2] = "START";
})(State || (State = {}));
class Monitor {
    constructor(url, spec, ondata, onerror, onprogress) {
        this.state = State.INIT;
        this.url = url;
        this.spec = spec;
        this.ws = new WebSocket(`${this.url}/${this.spec.getPath()}`);
        this.ws.onopen = () => {
            this.ws.send(JSON.stringify(this.spec.getParam()));
            this.state = State.CONNECT;
        };
        this.ws.onmessage = (event) => {
            try {
                if (this.state === State.CONNECT) {
                    const res = JSON.parse(event.data);
                    if (res.code != 0) {
                        throw new Exception_1.RpcError(res.message).toString();
                    }
                    else {
                        this.state = State.START;
                    }
                }
                else if (this.state === State.START) {
                    const obj = JSON.parse(event.data);
                    if (obj.progress !== undefined) {
                        if (onprogress !== undefined) {
                            onprogress(index_1.Converter.toBigNumber(obj.progress));
                        }
                    }
                    else {
                        const converter = spec.getConverter();
                        const data = converter(obj);
                        ondata(data);
                    }
                }
            }
            catch (e) {
                this.ws.onerror(e);
            }
        };
        this.ws.onerror = onerror;
    }
    close() {
        this.ws.close(1000);
    }
}
exports.default = Monitor;
