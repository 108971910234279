import React, { useEffect, useState } from "react";
import RPSGameCard from "../common/RPSGameCard.component";
import { MINI_GAMES } from "../../../../assets/assets";
import RPSContractServices from "../../../../services/contract/rps.service";
import { useRPSContext } from "../../../../services/contexts/rps.context";
import { findChanges, getGameIdsObj, removeGameIds } from "../../../../utils/helper";
import RPSHand from "../common/RPS.hand";
import {
  E_RPS_OPEN_GAME_TYPE,
  E_RPS_RPS_HANDS,
  E_RPS_URL_QUERY_ID,
  ZERO_ADDRESS,
} from "../../../../enums/RPS.enums";
import { WalletProvider, useWalletContext } from "../../../../services/contexts/wallet.context";
import { useIconEventContext } from "../../../../services/contexts/iconEvent.context";
import { E_ICONEX_RPC_EVENT_TYPE } from "../../../../hooks/useIconexHook";
import RPSBattleField from "./battleField/RPSBattleField.component";
import { getResult } from "../../../../services/data/rpsAPIs.services";
import { useSearchParams } from "react-router-dom";
import rpsService from "../../../../services/contract/rps.service";
import { C_GBET_NFT_META_MAPPING } from "../../../../constants/gbet_nft_id";

let timer: any = null;
function PlayRPSGames() {
  const [listOfOpenGames, setlistOfOpenGames] = useState<Array<any>>([]);
  const [isGameLoading, setIsGameLoading] = useState<boolean>(true);
  const [isGameWon, setIsGameWon] = useState<"won" | "lost" | "draw" | null>("draw");
  const [searchParams, setSearchParams] = useSearchParams();
  const [preSelectedId, setpreSelectedId] = useState<any>(null);
  const {
    nftId,
    isNew,
    userName,
    nftRace,
    currentSelectedOpenGame,
    setcurrentSelectedOpenGame,
    isOpeningGame,
    setIsOpeningGame,
    setChallengedGameNumber,
  } = useRPSContext();
  const {
    isTransactionLoading,
    setIsTransactionLoading,
    setCurrentEventType,
    setCurrentTxResult,
    currentEventType,
    currentTxResult,
  } = useIconEventContext();
  const { walletAddress, isWalletConnected } = useWalletContext();
  const getGames = async () => {
    try {
      let curChallengedGameNumber = 0;
      setIsGameLoading(true);
      const responseGames: Array<any> = await RPSContractServices?.getOpenGames();
      let openerFrnList = {};
      for (let i = 0; i < responseGames.length; i++) {
        if (!Object.keys(openerFrnList).includes(responseGames[i].creator)) {
          const resposeFriend = await rpsService.getFriendList(responseGames[i].creator);
          openerFrnList[responseGames[i].creator] = resposeFriend;
        }
      }
      setlistOfOpenGames((prev: any) => {
        const selectedGameId: string = searchParams.get(E_RPS_URL_QUERY_ID.GAME_ID)
          ? (searchParams.get(E_RPS_URL_QUERY_ID.GAME_ID) as string)
          : "";
        console.log(`Fetching open game details [init]`);
        console.log("selected game id", selectedGameId);
        let response1 = responseGames.filter((data) => data.creator != walletAddress);
        let response: any = [];
        response1.map((data, index) => {
          console.log("parsing...");
          if (data["wishList"] == "0x1" && openerFrnList[data.creator].includes(walletAddress)) {
            curChallengedGameNumber += 1;
            response.push({
              ...data,
              type: E_RPS_OPEN_GAME_TYPE.TO_FRIENDS,
            });
          }
          if (
            data["challengedAddress"] != ZERO_ADDRESS &&
            data["challengedAddress"] == walletAddress
          ) {
            curChallengedGameNumber += 1;
            console.log("to a friend and that includes you");
            response.push({
              ...data,
              type: E_RPS_OPEN_GAME_TYPE.A_CHALLENGE,
            });
          }

          if (data["wishList"] == "0x0" && data["challengedAddress"] == ZERO_ADDRESS) {
            console.log("to anyone");
            response.push({
              ...data,
              type: E_RPS_OPEN_GAME_TYPE.TO_ANYONE,
            });
          }
        });
        const changed = findChanges(prev, response);
        let oldArray = prev;
        let mostUpdatedArray: any = prev;
        if (changed.added.length >= 1) {
          let newArray = getGameIdsObj(response, changed.added);
          oldArray = oldArray.concat(newArray);
          console.log("List updated[new added]");
          mostUpdatedArray = oldArray;
        }
        if (changed.removed.length >= 1) {
          let newArray = removeGameIds(oldArray, changed.removed);
          console.log("List updated[removed added]");
          mostUpdatedArray = newArray;
        }
        console.log(
          `🎯checking selected game`,
          selectedGameId,
          typeof selectedGameId,
          selectedGameId?.length >= 1
        );

        if (selectedGameId?.length >= 1) {
          console.log(`SELECTED GAME ✅`, selectedGameId);
          const obj = mostUpdatedArray.filter(
            (data: any) => data.id / 1 == parseInt(selectedGameId)
          );
          console.log("Selected game", obj);
          if (obj.length >= 1) {
            console.log("setting up the preSelectedid");
            setpreSelectedId((prev: any) => {
              return obj[0].id / 1;
            });
          }
        }
        let finalFinalArray: any = [];
        for (let i = 0; i < mostUpdatedArray.length; i++) {
          if (mostUpdatedArray[i]?.status == "0x1" && mostUpdatedArray[i]?.played == "0x0") {
            finalFinalArray.push(mostUpdatedArray[i]);
          }
        }
        setIsGameLoading((e: any) => false);
        setChallengedGameNumber((e) => curChallengedGameNumber);
        return finalFinalArray;
      });
    } catch (error) {
      console.error(error);
    }
  };

  const syncGameResult = async (gameList: Array<any>) => {
    try {
      for (let data of gameList) {
        const { played, status, txHash } = data;
        if (played == "0x1" && status == "0x1") {
          await getResult(`0x${txHash}`);
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    getGames();
    timer = setInterval(() => {
      getGames();
    }, 10000);
    return () => {
      clearInterval(timer);
      setcurrentSelectedOpenGame(false);
      if (searchParams.get(E_RPS_URL_QUERY_ID.GAME_ID))
        setSearchParams((params) => {
          params.delete(E_RPS_URL_QUERY_ID.GAME_ID);
          return params;
        });
    };
  }, []);
  useEffect(() => {
    console.log("update state found - refetching");
    getGames();
    return () => {};
  }, [isTransactionLoading]);

  return (
    <div className='open_game_container'>
      {" "}
      <div className='title'>Battle Room</div>
      <p className='open_game_description'>This is where the action happens</p>
      <RPSBattleField
        isFrom={"play_rps_con"}
        currentSelectedOpenGame={currentSelectedOpenGame}
        setcurrentSelectedOpenGame={setcurrentSelectedOpenGame}
      />
      {/* <div className='title'>Available Games</div>
      <p className='open_game_description'>Click any of the games to begin playing</p> */}
      <div className='open_game_list'>
        <div
          className={`rps_open_game_item open_game_item host_a_game ${
            isOpeningGame ? "selected" : ""
          }`}
          onClick={() => {
            setcurrentSelectedOpenGame(false);
            setIsOpeningGame(!isOpeningGame);
          }}>
          Host a Game
        </div>
        {listOfOpenGames.length <= 0 && (
          <div className='no_game'> Nobody has hosted a game yet </div>
        )}
        {listOfOpenGames?.map((data, index) => {
          return data?.status == "0x1" && data?.played == "0x0" ? (
            <RPSGameCard
              preSelectedId={preSelectedId}
              currentSelectedOpenGame={currentSelectedOpenGame}
              setcurrentSelectedOpenGame={setcurrentSelectedOpenGame}
              data={data}
              key={index}
            />
          ) : null;
        })}
      </div>
    </div>
  );
}

export default PlayRPSGames;
