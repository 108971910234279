"use strict";
/*
 * Copyright 2022 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const Exception_1 = require("../../../Exception");
class HttpClient {
    static newCall(httpRequest) {
        return {
            execute() {
                return this.sendAsync();
            },
            sendAsync() {
                const { url, body } = httpRequest;
                // eslint-disable-next-line no-async-promise-executor
                return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    try {
                        const response = yield fetch(url, {
                            method: "POST",
                            body,
                        });
                        if (response.ok) {
                            const result = yield response.json();
                            resolve(result);
                        }
                        else {
                            const result = yield response.json();
                            reject(result);
                        }
                    }
                    catch (e) {
                        const error = new Exception_1.NetworkError(e.message);
                        reject(error.toString());
                    }
                }));
            },
        };
    }
}
exports.default = HttpClient;
