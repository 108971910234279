import React, { useCallback, useEffect, useRef, useState } from "react";
import { E_RPS_RPS_HANDS, ZERO_ADDRESS } from "../../../../../enums/RPS.enums";
import { useRPSContext } from "../../../../../services/contexts/rps.context";
import { useIconEventContext } from "../../../../../services/contexts/iconEvent.context";
import { useWalletContext } from "../../../../../services/contexts/wallet.context";
import { MINI_GAMES } from "../../../../../assets/assets";
import { E_ICONEX_RPC_EVENT_TYPE } from "../../../../../hooks/useIconexHook";
import RPSHand from "../../common/RPS.hand";
import RPSContractServices from "../../../../../services/contract/rps.service";
import Confetti from "../../common/Confeti.component";
import MakeItRain from "../../common/MakeItRain";
let timeoutCounter: any = null;
function RPSBattleFieldPlaying() {
  const [listOfOpenGames, setlistOfOpenGames] = useState<Array<any>>([]);
  const [currentHandSelected, setcurrentHandSelected] = useState<E_RPS_RPS_HANDS | null>(null);
  const [isGameWon, setIsGameWon] = useState<"won" | "lost" | "draw" | null>(null);
  const [vibrate, setvibrate] = useState<boolean>(false);
  const { nftId, isNew, userName, nftRace, currentSelectedOpenGame, setcurrentSelectedOpenGame } =
    useRPSContext();
  const {
    isTransactionLoading,
    setIsTransactionLoading,
    setCurrentEventType,
    setCurrentTxResult,
    currentEventType,
    currentTxResult,
  } = useIconEventContext();
  useEffect(() => {
    if (
      currentEventType == E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_RESULT_SUCCESS &&
      currentTxResult
    ) {
      if (currentTxResult.winnerAddress == ZERO_ADDRESS) {
        setIsGameWon("draw");
        setvibrate(true);
      } else if (currentTxResult.winnerAddress == walletAddress) {
        setTimeout(() => {
          fire();
        }, 200);
        setTimeout(() => {
          fire();
        }, 400);
        setTimeout(() => {
          fire();
        }, 2000);
        setvibrate(true);
        setIsGameWon("won");
        window.document
          .getElementById("you")
          ?.classList.remove("animate__animated", "animate__slideInLeft");
        window.document
          .getElementById("you")
          ?.classList.add("animate__animated", "animate__shakeY");
      } else {
        setvibrate(true);
        setIsGameWon("lost");
        window.document
          .getElementById("opponent")
          ?.classList.remove("animate__animated", "animate__slideInRight");

        window.document.getElementById("opponent")?.style.setProperty("animation-delay", "0s");
        window.document
          .getElementById("opponent")
          ?.classList.add("animate__animated", "animate__shakeY");
      }
      timeoutCounter = setTimeout(() => {
        setIsTransactionLoading((prev) => false);
        setCurrentEventType((prev) => "");
        setvibrate(false);
        setIsGameWon(null);
        setCurrentTxResult((prev) => null);
        setcurrentSelectedOpenGame((prev) => false);
      }, 30000);
    }
    return () => {};
  }, [isTransactionLoading]);
  const { walletAddress } = useWalletContext();
  const refAnimationInstance = useRef(null);
  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      (refAnimationInstance as any).current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 0,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);
  useEffect(() => {
    return () => {
      console.log("CLEARNING OUT!");
      clearTimeout(timeoutCounter);
      setIsTransactionLoading((prev) => false);
      setCurrentEventType((prev) => "");
      setvibrate(false);
      setIsGameWon(null);
      setCurrentTxResult((prev) => null);
      setcurrentSelectedOpenGame((prev) => false);
    };
  }, []);

  return (
    <>
      {" "}
      <div
        className={`open_game_battle_field ${isGameWon}  ${
          vibrate ? "vibrate" : ""
        } batle_playing_modal`}>
        <div className='you animate__animated animate__slideInLeft' id='you'>
          {(isGameWon == "won" || isGameWon == "draw") && currentSelectedOpenGame && (
            <MakeItRain
              type={RPSContractServices.getWagerType(currentSelectedOpenGame?.tokenAddress)}
            />
          )}
          <p>You</p>
          <img
            src={
              nftId
                ? `https://d2l8dezwbqkb9.cloudfront.net/png/${nftId}.png`
                : MINI_GAMES.RPS.UNKNOWL_PLAYER
            }
          />
        </div>
        <div className='field'>
          {isTransactionLoading &&
          currentEventType == E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_PROCESSING_RESULT &&
          currentSelectedOpenGame ? (
            <div className='battle_loading'>
              <div className='you_hand rps_hand animate__animated animate__fadeInDown'>
                <img
                  src={nftRace ? MINI_GAMES.RPS.RACE_HAND[nftRace][0] : MINI_GAMES.RPS.HAND[0]}
                  alt='hand'
                  className={`hand `}
                />
              </div>{" "}
              <img
                className='battle_vs animate__animated animate__zoomInDown'
                src={MINI_GAMES.RPS.VS_LOGO}
              />
              <div className='opponent_hand animate__animated rps_hand animate__fadeInDown'>
                <img
                  src={
                    currentSelectedOpenGame?.race
                      ? MINI_GAMES.RPS.RACE_HAND[currentSelectedOpenGame?.race][0]
                      : MINI_GAMES.RPS.HAND[0]
                  }
                  alt='hand'
                  className={`hand `}
                />
              </div>
            </div>
          ) : isTransactionLoading == false &&
            currentEventType == E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_RESULT_SUCCESS &&
            currentSelectedOpenGame ? (
            <div className='battle_result'>
              <div className={`you_hand rps_hand ${isGameWon}`}>
                <img
                  src={
                    nftRace
                      ? MINI_GAMES.RPS.RACE_HAND[nftRace][currentTxResult?.player1?.choice]
                      : MINI_GAMES.RPS.HAND[currentTxResult?.player1?.choice]
                  }
                  alt='your hand'
                  className={`hand `}
                />
              </div>{" "}
              <img className='battle_vs' src={MINI_GAMES.RPS.VS_LOGO} />
              <div
                className={`opponent_hand rps_hand ${
                  isGameWon == "won" ? "lost" : isGameWon == "lost" ? "won" : "draw"
                }`}>
                <img
                  src={
                    currentSelectedOpenGame?.race
                      ? MINI_GAMES.RPS.RACE_HAND[currentSelectedOpenGame?.race][
                          currentTxResult?.player2?.choice
                        ]
                      : MINI_GAMES.RPS.HAND[currentTxResult?.player2?.choice]
                  }
                  alt='hand'
                  className={`hand `}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {/*  */}
        </div>
        {currentSelectedOpenGame ? (
          <div className='opponent animate__animated animate__slideInRight' id='opponent'>
            {(isGameWon == "lost" || isGameWon == "draw") && currentSelectedOpenGame && (
              <MakeItRain
                type={RPSContractServices.getWagerType(currentSelectedOpenGame?.tokenAddress)}
              />
            )}
            <p>{currentSelectedOpenGame.name}</p>
            <img
              src={
                currentSelectedOpenGame.nftId
                  ? `https://d2l8dezwbqkb9.cloudfront.net/png/${currentSelectedOpenGame.nftId}.png`
                  : MINI_GAMES.RPS.UNKNOWL_PLAYER
              }
            />
          </div>
        ) : (
          <div className='empty_slot_player'>
            Click from below <br /> to begin playing
          </div>
        )}
        <Confetti refAnimationInstance={refAnimationInstance} />
      </div>
      {isTransactionLoading == false &&
      currentEventType == E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_RESULT_SUCCESS ? (
        <div className={`battle_notification ${isGameWon}`}>{currentTxResult?.log}</div>
      ) : null}
      {currentEventType == E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_RESULT_SUCCESS &&
      currentTxResult ? (
        <div
          className='battle_field_close_button'
          onClick={() => {
            setIsTransactionLoading((prev) => false);
            setCurrentEventType((prev) => "");
            setIsGameWon(null);
            setvibrate(false);
            setCurrentTxResult((prev) => null);
            setcurrentSelectedOpenGame((prev) => false);
          }}>
          Close
        </div>
      ) : null}
    </>
  );
}

export default RPSBattleFieldPlaying;
