import React, { useEffect, useState } from "react";
import { MINI_GAMES } from "../../../../assets/assets";
import {
  C_RPS_HAND,
  E_RPS_ACTION_CONTAINER,
  E_RPS_URL_QUERY_ID,
} from "../../../../enums/RPS.enums";
import { getUser } from "../../../../services/data/rpsAPIs.services";
import { C_GBET_NFT_META_MAPPING } from "../../../../constants/gbet_nft_id";
import { getTimeAgo } from "../../../../utils/helper";

export default function ChallengeHistoryCard({
  index,
  setCurrentActionContainerSelected,
  setSearchParams,
  againstAddress,
  status,
  choice,
  completedAt,
}: any) {
  const [nftId, setnftId] = useState<any>(null);
  const [name, setName] = useState<any>(null);

  const getUserDetails = async () => {
    console.log("getting user details rps");
    const res = await getUser(againstAddress);
    console.log(res);
    if (res) {
      if (res.data != false) {
        setnftId(res.data.nft_id);
        setName(res.data.name);
      } else {
        setnftId(null);
      }
    }
  };
  useEffect(() => {
    getUserDetails();

    return () => {
      setnftId(null);
      setName(null);
    };
  }, [, againstAddress]);
  return (
    <tr key={index}>
      <td className='time_value'>{getTimeAgo(completedAt)}</td>
      <td>
        <div className='friend_detail_card'>
          <img
            className='img_nft'
            src={
              nftId
                ? `https://d2l8dezwbqkb9.cloudfront.net/ipfs/img/${C_GBET_NFT_META_MAPPING[nftId]}.gif`
                : MINI_GAMES.RPS.UNKNOWL_PLAYER
            }
          />
          <div className='frn_info'>
            <h3
              onClick={() => {
                setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                setSearchParams((param) => {
                  param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, againstAddress);
                  return param;
                });
              }}
              className='inlink'>
              {name ? name : `${againstAddress.slice(0, 5)}...${againstAddress.slice(36)}`}
            </h3>
            <p>{`${againstAddress.slice(0, 5)}...${againstAddress.slice(36)}`}</p>
          </div>
        </div>
      </td>
      <td>
        <div className='history_result'>
          <div className={`status ${status}`}>{status}</div>
          <div className='choice'>with {C_RPS_HAND[choice]}</div>
        </div>
      </td>
    </tr>
  );
}
