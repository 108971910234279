"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageTransactionBuilder = exports.DepositTransactionBuilder = exports.DeployTransactionBuilder = exports.CallTransactionBuilder = exports.IcxTransactionBuilder = exports.CallBuilder = exports.Call = exports.MessageTransaction = exports.IcxTransaction = exports.DepositTransaction = exports.DeployTransaction = exports.CallTransaction = void 0;
const Call_1 = require("./call/Call");
Object.defineProperty(exports, "CallBuilder", { enumerable: true, get: function () { return Call_1.CallBuilder; } });
const IcxTransaction_1 = require("./transaction/IcxTransaction");
Object.defineProperty(exports, "IcxTransactionBuilder", { enumerable: true, get: function () { return IcxTransaction_1.IcxTransactionBuilder; } });
const CallTransaction_1 = require("./transaction/CallTransaction");
Object.defineProperty(exports, "CallTransactionBuilder", { enumerable: true, get: function () { return CallTransaction_1.CallTransactionBuilder; } });
const DeployTransaction_1 = require("./transaction/DeployTransaction");
exports.DeployTransactionBuilder = DeployTransaction_1.default;
const DepositTransaction_1 = require("./transaction/DepositTransaction");
exports.DepositTransactionBuilder = DepositTransaction_1.default;
const MessageTransaction_1 = require("./transaction/MessageTransaction");
exports.MessageTransactionBuilder = MessageTransaction_1.default;
const CallTransaction_2 = require("./transaction/CallTransaction");
Object.defineProperty(exports, "CallTransaction", { enumerable: true, get: function () { return CallTransaction_2.CallTransaction; } });
const DepositTransaction_2 = require("./transaction/DepositTransaction");
Object.defineProperty(exports, "DepositTransaction", { enumerable: true, get: function () { return DepositTransaction_2.DepositTransaction; } });
const DeployTransaction_2 = require("./transaction/DeployTransaction");
Object.defineProperty(exports, "DeployTransaction", { enumerable: true, get: function () { return DeployTransaction_2.DeployTransaction; } });
const IcxTransaction_2 = require("./transaction/IcxTransaction");
Object.defineProperty(exports, "IcxTransaction", { enumerable: true, get: function () { return IcxTransaction_2.IcxTransaction; } });
const MessageTransaction_2 = require("./transaction/MessageTransaction");
Object.defineProperty(exports, "MessageTransaction", { enumerable: true, get: function () { return MessageTransaction_2.MessageTransaction; } });
const Call_2 = require("./call/Call");
Object.defineProperty(exports, "Call", { enumerable: true, get: function () { return Call_2.Call; } });
const Builder = {
    CallBuilder: Call_1.CallBuilder,
    IcxTransactionBuilder: IcxTransaction_1.IcxTransactionBuilder,
    CallTransactionBuilder: CallTransaction_1.CallTransactionBuilder,
    DeployTransactionBuilder: DeployTransaction_1.default,
    MessageTransactionBuilder: MessageTransaction_1.default,
    DepositTransactionBuilder: DepositTransaction_1.default,
};
exports.default = Builder;
