export const EXTERNAL_URL = {
  EMERALD_CITY_MAP_TILE: "https://dzgwkcs2jijxf.cloudfront.net/mapTiles/",
  EMERALD_CITY_MAP_DISTRICTS: "https://dzgwkcs2jijxf.cloudfront.net/districts/",
  EMERALD_CITY_MAP_METADATA: "https://dzgwkcs2jijxf.cloudfront.net/metadata/",
};

export const MINI_GAME_URL = {
  RPS: {
    ENCRYPT: "rps/getEncryption/",
    REGISTER: "rps/register/",
    GET_USER: "rps/getUser/",
    GET_RESULT: "rps/playRPS",
    GET_LOGS: "rps/getLatestLogs",
    GET_CREATED_GAME: "rps/getCreatedGame",
    GET_LATEST_GAMES: "rps/getLatestGamePlayed",
    REFRESH_PROFILE: "rps/updatePlayerProfile",
    GET_PLAYED_GAME: "rps/getPlayedGames",
    GET_LEADERBOARD: "rps/getLeaderboard",
    GENERAL_STATS: "rps/generalStats",
    ROUND_INFO: "rps/getRoundInfo",
    HALL_OF_FAME: "rps/getHallOfFames",
  },
};
