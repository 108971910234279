import React, { useEffect, useState } from "react";
import { ICONS, MINI_GAMES } from "../../../../assets/assets";
import RPSHand from "./RPS.hand";
import {
  E_RPS_CHALLENGE_TYPE,
  E_RPS_RPS_HANDS,
  E_RPS_WAGER_TYPE,
} from "../../../../enums/RPS.enums";
import { useWalletContext } from "../../../../services/contexts/wallet.context";
import { useRPSContext } from "../../../../services/contexts/rps.context";
import RPSContractServices from "../../../../services/contract/rps.service";
import { useIconEventContext } from "../../../../services/contexts/iconEvent.context";
import { E_ICONEX_RPC_EVENT_TYPE } from "../../../../hooks/useIconexHook";
import { useSignMessage } from "../../../../hooks/useSignMessage";
import Dropdown from "../../../../components/common/Dropdown.component";
import rpsService from "../../../../services/contract/rps.service";
import { iconUSDRate } from "../../../../services/data/data.service";
import { fetchCrownPrice } from "../../../../services/data/rpsAPIs.services";
const MIN_WAGER_USD = 0.004;
function RPSopenGame() {
  const { isNew, setIsOpeningGame, challengingPlayerAddress, setChallengingPlayerAddress } =
    useRPSContext();
  const { isWalletConnected, walletAddress } = useWalletContext();
  const [challengeType, setchallengeTyoe] = useState<E_RPS_CHALLENGE_TYPE | null>(null);
  const [challengedAddress, setchallengedAddress] = useState<string>("");
  const [messageValue, setMessageValue] = useState<string>("");
  const [maxCapToken, setMaxCapToken] = useState<any>({
    [E_RPS_WAGER_TYPE.CROWN]: 10,
    [E_RPS_WAGER_TYPE.ICX]: 10,
  });
  const [currentWagerSelected, setcurrentWagerSelected] = useState<E_RPS_WAGER_TYPE>(
    E_RPS_WAGER_TYPE.ICX
  );
  const [wagerAmount, setWagerAmount] = useState<number>(0);
  const [minICXWager, setMinICXWager] = useState<number>(1);
  const [minCROWNWager, setMinCROWNWager] = useState<number>(25);
  const [curretStep, setcurretStep] = useState<"FIRST" | "SECOND" | "MESSAGE" | "MESSAGE_QUESTION">(
    "FIRST"
  );
  const { signClick } = useSignMessage();
  const handleSigning = async () => {
    const signature = await signClick();
    const payload = {
      address: walletAddress,
      signature: signature,
      payload: localStorage.getItem("dynamicHash") as string,
    };
    console.log("payload", payload);
  };
  const setMaxCao = async () => {
    const icxCap: any = await rpsService.getTokenCap(E_RPS_WAGER_TYPE.ICX);
    const crownCap: any = await rpsService.getTokenCap(E_RPS_WAGER_TYPE.CROWN);
    setMaxCapToken({
      [E_RPS_WAGER_TYPE.ICX]: icxCap / Math.pow(10, 18),
      [E_RPS_WAGER_TYPE.CROWN]: crownCap / Math.pow(10, 18),
    });
  };
  const [iconRate, setIconRate] = useState(0);
  const [MIN_WAGER, setMIN_WAGER] = useState(1);
  const [crownRate, setCrownRate] = useState<any>(0);
  const getICXRate = async () => {
    try {
      console.log(`getICXRate`);
      const res = await iconUSDRate();
      console.log(`icoxn rate`, res.icon.usd);
      const minICX = (1 / res.icon.usd) * MIN_WAGER_USD;
      console.log("minICX", minICX);
      setMinICXWager((p) => {
        if (minICX <= 1) {
          return 1;
        } else {
          return Math.ceil(minICX);
        }
      });
      setIconRate((p) => res.icon.usd);
      const crown = await fetchCrownPrice();
      console.log(`crown rate`, crown);
      const minCROWN = (1 / parseFloat(crown)) * MIN_WAGER_USD;
      console.log("minCROWN", minCROWN);
      setMinCROWNWager((p) => {
        if (minICX <= 1) {
          return 1;
        } else {
          return Math.ceil(minCROWN);
        }
      });
      setCrownRate(crown);
    } catch (error) {
      console.error(error);
    }
  };
  const { setIsTransactionLoading, isTransactionLoading, currentEventType, setCurrentEventType } =
    useIconEventContext();
  const [currentHandSelected, setcurrentHandSelected] = useState<E_RPS_RPS_HANDS | null>(null);
  useEffect(() => {
    if (currentEventType == E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_SUCCESS) {
      setcurrentHandSelected(null);
      setcurrentWagerSelected(E_RPS_WAGER_TYPE.ICX);
      setWagerAmount(0);
      setcurretStep("FIRST");
    }
    setMaxCao();
    return () => {};
  }, [currentEventType]);

  return (
    <div className='open_game_box_container'>
      <div className={`${isNew ? "" : ""} rps_opening_a_game`}>
        <div className='bg'></div>
        {curretStep == "SECOND" && (
          <div className={`overlay`}>
            <div className='choose_opponent'>
              <p className='choose_opponent_title'>Choose who you want to play with</p>
              <div className='choose_opponent_options'>
                {Object.keys(E_RPS_CHALLENGE_TYPE).map((data) => {
                  return (
                    <div
                      className={`${challengeType == E_RPS_CHALLENGE_TYPE[data] && "selected"}`}
                      onClick={() => {
                        setchallengeTyoe(E_RPS_CHALLENGE_TYPE[data]);
                      }}>
                      {E_RPS_CHALLENGE_TYPE[data]}
                    </div>
                  );
                })}
              </div>
              {E_RPS_CHALLENGE_TYPE.SOMEONE == challengeType && (
                <input
                  type='text'
                  placeholder='wallet address'
                  value={challengedAddress}
                  onChange={(e) => {
                    setchallengedAddress(e.target.value);
                  }}
                />
              )}

              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  className={`primary ${
                    currentHandSelected != null && currentWagerSelected && !isTransactionLoading
                      ? "enabled"
                      : "disabled"
                  }`}
                  disabled={
                    !isWalletConnected ||
                    (currentHandSelected != null &&
                      currentWagerSelected &&
                      !isTransactionLoading &&
                      challengeType != null) == false ||
                    (challengeType == E_RPS_CHALLENGE_TYPE.SOMEONE &&
                      challengedAddress.length <= 30) == true
                  }
                  onClick={async () => {
                    console.log({
                      walletAddress,
                      wagerAmount,
                      currentWagerSelected,
                      currentHandSelected,
                    });
                    if (
                      currentHandSelected != null &&
                      currentWagerSelected &&
                      !isTransactionLoading &&
                      challengeType != null &&
                      wagerAmount >= MIN_WAGER
                    ) {
                      setcurretStep("MESSAGE_QUESTION");
                      // setIsTransactionLoading(true);
                      // setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.MESSAGE_SIGNING_PENGING);
                      // const signature = await signClick();
                      // const payload = {
                      //   signature: signature,
                      //   payload: localStorage.getItem("dynamicHash") as string,
                      // };
                      // setIsTransactionLoading(true);
                      // setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_PENDING);
                      // console.log(
                      //   "setting current event type",
                      //   E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_PENDING
                      // );
                      // RPSContractServices?.openICXGame(
                      //   walletAddress,
                      //   wagerAmount,
                      //   currentWagerSelected,
                      //   currentHandSelected,
                      //   challengeType,
                      //   challengedAddress,
                      //   payload
                      // );
                    }
                  }}>
                  Next
                </button>

                <button
                  className='ghost'
                  onClick={() => {
                    setcurretStep("FIRST");
                  }}>
                  Back
                </button>
              </div>
            </div>
          </div>
        )}{" "}
        {curretStep == "MESSAGE_QUESTION" && (
          <div className={`overlay`}>
            <div className='choose_opponent'>
              <p className='choose_opponent_title'>Do you want to attach a message?</p>

              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  className={`primary ${
                    currentHandSelected != null && currentWagerSelected && !isTransactionLoading
                      ? "enabled"
                      : "disabled"
                  }`}
                  disabled={
                    !isWalletConnected ||
                    (currentHandSelected != null &&
                      currentWagerSelected &&
                      !isTransactionLoading &&
                      challengeType != null) == false ||
                    (challengeType == E_RPS_CHALLENGE_TYPE.SOMEONE &&
                      challengedAddress.length <= 30) == true
                  }
                  onClick={async () => {
                    console.log({
                      walletAddress,
                      wagerAmount,
                      currentWagerSelected,
                      currentHandSelected,
                    });
                    if (
                      currentHandSelected != null &&
                      currentWagerSelected &&
                      !isTransactionLoading &&
                      challengeType != null &&
                      wagerAmount >= MIN_WAGER
                    ) {
                      setIsTransactionLoading(true);
                      setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.MESSAGE_SIGNING_PENGING);
                      const signature = await signClick();
                      const payload = {
                        signature: signature,
                        payload: localStorage.getItem("dynamicHash") as string,
                      };
                      setIsTransactionLoading(true);
                      setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_PENDING);
                      console.log(
                        "setting current event type",
                        E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_PENDING
                      );
                      RPSContractServices?.openICXGame(
                        walletAddress,
                        wagerAmount,
                        currentWagerSelected,
                        currentHandSelected,
                        challengeType,
                        challengedAddress,
                        payload
                      );
                    }
                  }}>
                  Open Game (No)
                </button>
                <button
                  className='primary'
                  style={{ margin: "0px 5px" }}
                  onClick={() => {
                    setcurretStep("MESSAGE");
                  }}>
                  Yes
                </button>
                <button
                  className='ghost'
                  onClick={() => {
                    setcurretStep("SECOND");
                  }}>
                  Back
                </button>
              </div>
            </div>
          </div>
        )}{" "}
        {curretStep == "MESSAGE" && (
          <div className={`overlay open_game_message_page`}>
            <div className='paper'></div>

            <svg>
              <filter id='wavy2'>
                <feTurbulence x='0' y='0' baseFrequency='0.02' numOctaves='5' seed='1' />
                <feDisplacementMap in='SourceGraphic' scale='20' />
              </filter>
            </svg>
            <div className='choose_opponent'>
              <p className='choose_opponent_title'>Add a message</p>

              <textarea
                placeholder='Enter your message'
                value={messageValue}
                onChange={(e) => {
                  if (e.target.value.length <= 50) setMessageValue(e.target.value);
                }}
              />
              <div>{messageValue.length}/50</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  className={`primary ${
                    currentHandSelected != null && currentWagerSelected && !isTransactionLoading
                      ? "enabled"
                      : "disabled"
                  }`}
                  disabled={
                    !isWalletConnected ||
                    (currentHandSelected != null &&
                      currentWagerSelected &&
                      !isTransactionLoading &&
                      challengeType != null) == false ||
                    (challengeType == E_RPS_CHALLENGE_TYPE.SOMEONE &&
                      challengedAddress.length <= 30) == true ||
                    messageValue.length <= 0 ||
                    messageValue.length >= 51
                  }
                  onClick={async () => {
                    console.log({
                      walletAddress,
                      wagerAmount,
                      currentWagerSelected,
                      currentHandSelected,
                    });
                    if (
                      currentHandSelected != null &&
                      currentWagerSelected &&
                      !isTransactionLoading &&
                      challengeType != null &&
                      wagerAmount >= MIN_WAGER &&
                      messageValue.length >= 1
                    ) {
                      setIsTransactionLoading(true);
                      setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.MESSAGE_SIGNING_PENGING);
                      const signature = await signClick();
                      const payload = {
                        signature: signature,
                        payload: localStorage.getItem("dynamicHash") as string,
                      };
                      setIsTransactionLoading(true);
                      setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_PENDING);
                      console.log(
                        "setting current event type",
                        E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_PENDING
                      );
                      RPSContractServices?.openICXGame(
                        walletAddress,
                        wagerAmount,
                        currentWagerSelected,
                        currentHandSelected,
                        challengeType,
                        challengedAddress,
                        payload,
                        messageValue
                      );
                    }
                  }}>
                  Open game
                </button>
                <button
                  className='ghost'
                  onClick={() => {
                    setcurretStep("SECOND");
                  }}>
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
        <div
          className={`overlay ${
            currentEventType == E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_PENDING && isTransactionLoading
              ? ""
              : "none"
          }`}>
          <div className='loading_rps_open_game'>loading..</div>
        </div>
        <p className='choose_wager_title'>Choose a wager</p>
        <div className='wager_container'>
          {" "}
          <Dropdown
            data={[
              { label: E_RPS_WAGER_TYPE.CROWN, icon: <img src={ICONS.crown_logo} /> },
              { label: E_RPS_WAGER_TYPE.ICX, icon: <img src={ICONS.iconex_logo} /> },
            ]}
            initialValue={currentWagerSelected as string}
            onChange={(e: any) => {
              console.log(e);
              if (E_RPS_WAGER_TYPE.CROWN == e) {
                setMIN_WAGER(minCROWNWager);
              } else {
                setMIN_WAGER(minICXWager);
              }
              console.log({ minCROWNWager, minICXWager });
              setcurrentWagerSelected(e);
            }}
          />
          <input
            type='number'
            value={wagerAmount}
            onChange={(e) => {
              console.log(parseInt(e.target.value));
              setWagerAmount(parseInt(e.target.value));
            }}
            min={MIN_WAGER}
            max={maxCapToken[currentWagerSelected]}
            placeholder='Enter wager amount'
            className='wager_amount'
          />
          <div
            className={`max_cap ${
              wagerAmount <= MIN_WAGER - 1
                ? "red"
                : wagerAmount > maxCapToken[currentWagerSelected]
                ? "red"
                : "white"
            }`}>
            {wagerAmount <= MIN_WAGER - 1 && `Min: ${MIN_WAGER}`}
            {wagerAmount >= 1 && `Max: ${maxCapToken[currentWagerSelected]}`}
          </div>
        </div>
        <p className='choose_your_move'>Choose your move</p>
        <div className='moves_list'>
          <RPSHand
            setcurrentHandSelected={setcurrentHandSelected}
            currentHandSelected={currentHandSelected}
            type={E_RPS_RPS_HANDS.ROCK}
          />
          <RPSHand
            setcurrentHandSelected={setcurrentHandSelected}
            currentHandSelected={currentHandSelected}
            type={E_RPS_RPS_HANDS.PAPER}
          />
          <RPSHand
            setcurrentHandSelected={setcurrentHandSelected}
            currentHandSelected={currentHandSelected}
            type={E_RPS_RPS_HANDS.SCISSOR}
          />
        </div>
        <div className='first_open_rps_cta'>
          <button
            className={`primary ${
              currentHandSelected != null && currentWagerSelected && !isTransactionLoading
                ? "enabled"
                : "disabled"
            }`}
            disabled={
              !isWalletConnected ||
              (currentHandSelected != null && currentWagerSelected && !isTransactionLoading) ==
                false ||
              wagerAmount <= MIN_WAGER - 1 ||
              wagerAmount > maxCapToken[currentWagerSelected] ||
              Number.isNaN(wagerAmount)
            }
            onClick={async () => {
              console.log({
                walletAddress,
                wagerAmount,
                currentWagerSelected,
                currentHandSelected,
              });
              // handleSigning();
              if (challengingPlayerAddress) {
                setchallengeTyoe(E_RPS_CHALLENGE_TYPE.SOMEONE);
                setchallengedAddress(challengingPlayerAddress.address);
                setcurretStep("MESSAGE_QUESTION");
              } else if (
                currentHandSelected != null &&
                currentWagerSelected &&
                !isTransactionLoading &&
                wagerAmount >= MIN_WAGER
              ) {
                setcurretStep("SECOND");
              }
            }}>
            Next
          </button>
          <button
            className='ghost'
            onClick={() => {
              setIsOpeningGame(false);
              setChallengingPlayerAddress(false);
            }}>
            Close
          </button>
        </div>
      </div>
      {challengingPlayerAddress && (
        <div className='challenged_player'>
          <h4>Challenging</h4>
          <div className='img_container'>
            <img
              src={
                challengingPlayerAddress.nftId
                  ? `https://d2l8dezwbqkb9.cloudfront.net/png/${challengingPlayerAddress.nftId}.png`
                  : MINI_GAMES.RPS.UNKNOWL_PLAYER
              }
            />
          </div>
          <p>
            {challengingPlayerAddress.name
              ? challengingPlayerAddress.name
              : challengingPlayerAddress.address}
          </p>
        </div>
      )}
    </div>
  );
}

export default RPSopenGame;
