import {
  E_RPS_CHALLENGE_TYPE,
  E_RPS_RPS_HANDS,
  E_RPS_WAGER_TYPE,
  ZERO_ADDRESS,
} from "../../enums/RPS.enums";
import { IconChainService } from "../icon/icon.service";
import { MINI_GAME_URL } from "../../constants/externalURI";
import aesjs from "aes-js";
import axios from "axios";
import { E_ICONEX_RPC_EVENT_TYPE } from "../../hooks/useIconexHook";
import { abbreviateNumber, objectToHexWithPrefix } from "../../utils/helper";
import {
  REACT_APP_ENCRYPTION_KEY,
  REACT_APP_CROWN_SCORE,
  REACT_APP_RPS_MINI_GAME_SCORE,
  REACT_APP_EMERALD_CITY_NODE_API,
  REACT_APP_REWARD_DISTRIBUTION,
} from "../data/config";
class RPSRewardServices {
  private iconService: IconChainService;
  private static instance: RPSRewardServices;
  constructor() {
    this.iconService = new IconChainService();
  }
  public static getInstance(): RPSRewardServices {
    if (!RPSRewardServices.instance) {
      RPSRewardServices.instance = new RPSRewardServices();
    }
    return RPSRewardServices.instance;
  }

  public async getRewardFor(user: string, currencyType: "ICX" | "CROWN") {
    try {
      const response = await this.iconService.getFromChain(
        REACT_APP_REWARD_DISTRIBUTION as string,
        "getUsersClaimableAmount",
        {
          user,
          currency: currencyType == "ICX" ? ZERO_ADDRESS : REACT_APP_CROWN_SCORE,
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  public async getUsersAllocations(user: string) {
    try {
      const response = await this.iconService.getFromChain(
        REACT_APP_REWARD_DISTRIBUTION as string,
        "getUsersAllocations",
        {
          user,
          offset: `0x0`,
          limit: `0x5`,
          order: "",
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  public async claimReward(address: string, currencyType: "ICX" | "CROWN") {
    try {
      const response = await this.iconService.setToChain(
        REACT_APP_REWARD_DISTRIBUTION as string,
        "claim",
        {
          currency: currencyType == "ICX" ? ZERO_ADDRESS : REACT_APP_CROWN_SCORE,
        },
        address,
        0,
        E_ICONEX_RPC_EVENT_TYPE.CLAIMING_REWARD_PENDING
      );
      console.log(E_ICONEX_RPC_EVENT_TYPE.CLAIMING_REWARD_PENDING, response);
    } catch (error) {
      console.log(error);
    }
  }
}
export default RPSRewardServices.getInstance();
