import React, { useEffect, useState } from "react";
import { ICONS, MINI_GAMES } from "../../../../assets/assets";
import { MdPersonAddAlt1 } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import { FaHandshake, FaRegCopy } from "react-icons/fa6";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaMedal, FaUserFriends } from "react-icons/fa";
import { BsFire } from "react-icons/bs";
import rpsService from "../../../../services/contract/rps.service";
import { useWalletContext } from "../../../../services/contexts/wallet.context";
import { E_ICONEX_RPC_EVENT_TYPE } from "../../../../hooks/useIconexHook";
import RPSContractServices from "../../../../services/contract/rps.service";
import { useIconEventContext } from "../../../../services/contexts/iconEvent.context";
import { findChanges, getGameIdsObj, getTimeAgo, removeGameIds } from "../../../../utils/helper";
import { GiMedal, GiPointySword } from "react-icons/gi";
import { IoMdPaper } from "react-icons/io";
import { ImProfile } from "react-icons/im";
import { LuRefreshCw } from "react-icons/lu";

import {
  E_RPS_ACTION_CONTAINER,
  E_RPS_OPEN_GAME_TYPE,
  E_RPS_URL_QUERY_ID,
  ZERO_ADDRESS,
} from "../../../../enums/RPS.enums";
import { useSearchParams } from "react-router-dom";
import RPSGameCard from "../common/RPSGameCard.component";
import { useRPSContext } from "../../../../services/contexts/rps.context";
import ChallengeHistoryCard from "./ChallengeHistoryCard";
import axios from "axios";
import { REACT_APP_EMERALD_CITY_NODE_API } from "../../../../services/data/config";
import { MINI_GAME_URL } from "../../../../constants/externalURI";

function StatBox({ icon, title, desc }: any) {
  return (
    <div className='statBox'>
      <div className='icon_box_rps_stat'>{icon}</div>
      <div className='stat_value'>
        <div>{title}</div>
        <div className='stat_name'>{desc} </div>
      </div>
    </div>
  );
}
function RPSProfile({
  nftId,
  userName,
  address,
  userStat,
  addFriend,
  friendList,
  lastPlayed,
  listOfLatestGames,
}: any) {
  const { walletAddress, isWalletConnected } = useWalletContext();
  const [listOfOpenGames, setlistOfOpenGames] = useState<Array<any>>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [copyAddress, setcopyAddress] = useState("Copy Address");
  const [viewHistory, setViewHistory] = useState<boolean>(false);
  const { isTransactionLoading, currentEventType, setCurrentEventType, setIsTransactionLoading } =
    useIconEventContext();
  const { setCurrentActionContainerSelected, setChallengingPlayerAddress, setIsOpeningGame } =
    useRPSContext();

  const getGames = async (address: string) => {
    try {
      const responseGames: Array<any> = await RPSContractServices?.getOpenGames();
      let openerFrnList = {};
      for (let i = 0; i < responseGames.length; i++) {
        if (!Object.keys(openerFrnList).includes(responseGames[i].creator)) {
          const resposeFriend = await rpsService.getFriendList(responseGames[i].creator);
          openerFrnList[responseGames[i].creator] = resposeFriend;
        }
      }
      setlistOfOpenGames((prev: any) => {
        const selectedGameId: string = searchParams.get(E_RPS_URL_QUERY_ID.GAME_ID)
          ? (searchParams.get(E_RPS_URL_QUERY_ID.GAME_ID) as string)
          : "";
        console.log(`Fetching open game details [init]`);
        console.log("selected game id", selectedGameId);
        let response1 = responseGames;
        if (walletAddress != address)
          response1 = responseGames.filter((data) => data.creator != walletAddress);
        let response: any = [];
        response1.map((data, index) => {
          console.log("parsing...");
          if (data["wishList"] == "0x1" && openerFrnList[data.creator].includes(walletAddress)) {
            response.push({
              ...data,
              type: E_RPS_OPEN_GAME_TYPE.TO_FRIENDS,
            });
          }
          if (
            data["challengedAddress"] != ZERO_ADDRESS &&
            data["challengedAddress"] == walletAddress
          ) {
            console.log("to a friend and that includes you");
            response.push({
              ...data,
              type: E_RPS_OPEN_GAME_TYPE.A_CHALLENGE,
            });
          }
          if (data["wishList"] == "0x0" && data["challengedAddress"] == ZERO_ADDRESS) {
            console.log("to anyone");
            response.push({
              ...data,
              type: E_RPS_OPEN_GAME_TYPE.TO_ANYONE,
            });
          }
        });
        const changed = findChanges(prev, response);
        let oldArray = prev;
        let mostUpdatedArray: any = prev;
        if (changed.added.length >= 1) {
          let newArray = getGameIdsObj(response, changed.added);
          oldArray = oldArray.concat(newArray);
          console.log("List updated[new added]");
          mostUpdatedArray = oldArray;
        }
        if (changed.removed.length >= 1) {
          let newArray = removeGameIds(oldArray, changed.removed);
          console.log("List updated[removed added]");
          mostUpdatedArray = newArray;
        }
        let finalFinalArray: any = [];
        for (let i = 0; i < mostUpdatedArray.length; i++) {
          if (
            mostUpdatedArray[i]?.status == "0x1" &&
            mostUpdatedArray[i]?.played == "0x0" &&
            mostUpdatedArray[i]?.creator == address
          ) {
            finalFinalArray.push(mostUpdatedArray[i]);
          }
        }
        return finalFinalArray;
      });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getGames(address);

    return () => {};
  }, []);
  const [isProfileRefreshing, setisProfileRefreshing] = useState(false);
  return (
    <div className='rps_profile_container'>
      {walletAddress == address && (
        <div className='profile_button'>
          <button
            className='primary black'
            onClick={() => {
              window.open(
                `https://gangstabet.io/marketplace?sort_by=cheapest&for_sale=true`,
                "_blank"
              );
            }}>
            <MdPersonAddAlt1 className='icon' />
            Buy GangstaBet NFT
          </button>{" "}
          {
            <button
              disabled={isProfileRefreshing}
              className='primary black'
              onClick={async () => {
                setisProfileRefreshing(true);
                await axios.post(
                  `${REACT_APP_EMERALD_CITY_NODE_API}${MINI_GAME_URL.RPS.REFRESH_PROFILE}`,
                  {
                    address: address,
                  }
                );
                window.location.reload();
                setisProfileRefreshing(false);
              }}>
              <LuRefreshCw className='icon' />
              Refresh Profile
            </button>
          }
        </div>
      )}
      {isTransactionLoading == false &&
        currentEventType == E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_SUCCESS && (
          <div className='notification_frn_added'>{userName} added to your friend's list.</div>
        )}{" "}
      {isTransactionLoading == false &&
        currentEventType == E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_FAILED && (
          <div className='notification_frn_failed'>Failed to add {userName} as friend.</div>
        )}
      {walletAddress != address && isWalletConnected && (
        <div className='profile_button'>
          {!friendList?.includes(address) && (
            <button
              className='primary black'
              onClick={() => {
                setIsTransactionLoading(true);
                setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_PENDING);
                addFriend(walletAddress, address);
              }}>
              <MdPersonAddAlt1 className='icon' />
              Add Friend
            </button>
          )}
          {friendList?.includes(address) && (
            <button className='primary green' disabled>
              <FaUserFriends className='icon' />
              Friends
            </button>
          )}
          <button
            className='primary danger'
            onClick={() => {
              setChallengingPlayerAddress({
                name: userName,
                address,
                nftId,
              });
              setIsOpeningGame(true);
              setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.PLAY);
            }}>
            <GiPointySword className='icon' />
            Challenge
          </button>{" "}
          <button
            className='primary black'
            onClick={() => {
              setViewHistory(!viewHistory);
            }}>
            {!viewHistory ? (
              <>
                <IoMdPaper className='icon' />
                View History
              </>
            ) : (
              <>
                <ImProfile className='icon' />
                View Profile
              </>
            )}
          </button>
          <button disabled className='primary black'>
            Message
          </button>{" "}
        </div>
      )}
      <div className='rps_profile'>
        <>
          <img
            src={
              nftId
                ? `https://d2l8dezwbqkb9.cloudfront.net/png/${nftId}.png`
                : MINI_GAMES.RPS.UNKNOWL_PLAYER
            }
            className='gangstabet_img_rps'
          />
          {viewHistory ? (
            <div className='history_container'>
              <div className='title'>Previous Battles</div>
              <div className='history_battle_card'>
                <table>
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Against</th>
                      <th>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listOfLatestGames?.map(
                      (
                        {
                          createdAt,
                          creator,
                          player,
                          winner,
                          wager,
                          gameId,
                          completedAt,
                          creatorChoice,
                          playerChoice,
                        },
                        index
                      ) => {
                        return (
                          <ChallengeHistoryCard
                            index={index}
                            completedAt={completedAt}
                            setCurrentActionContainerSelected={setCurrentActionContainerSelected}
                            setSearchParams={setSearchParams}
                            againstAddress={address == creator ? player : creator}
                            status={
                              winner == ZERO_ADDRESS ? "DRAW" : address == winner ? "WON" : "LOST"
                            }
                            choice={address == creator ? creatorChoice : playerChoice}
                          />
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className='gangstabet_rps_stats'>
              <div className='username_addFriend'>
                <h1>{userName}</h1>
                <ReactTooltip
                  id={"friends_bar_tooltip"}
                  place={"top"}
                  className='streak_bar_tooltip'>
                  Friends
                </ReactTooltip>
                <ReactTooltip
                  id={"add_friends_bar_tooltip"}
                  place={"top"}
                  className='streak_bar_tooltip'>
                  Add Friend
                </ReactTooltip>
                {/* <div className='streak_bar' > */}
                {/* {walletAddress != address && !friendList?.includes(address) && (
                <MdPersonAddAlt1
                  data-tooltip-id='add_friends_bar_tooltip'
                  onClick={() => {
                    setIsTransactionLoading(true);
                    setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_PENDING);
                    addFriend(walletAddress, address);
                  }}
                  className='add_friend_icon'
                />
              )} */}
                {friendList?.includes(address) && (
                  <FaUserFriends data-tooltip-id='friends_bar_tooltip' className='friends_icon' />
                )}
              </div>
              <p>
                <span>
                  <IoWalletOutline />
                </span>
                <span>
                  {address.slice(0, 4)}...{address.slice(30)}
                  <ReactTooltip
                    id={"copy_bar_tooltip"}
                    place={"top"}
                    className='streak_bar_tooltip'>
                    {copyAddress}
                  </ReactTooltip>
                  <FaRegCopy
                    data-tooltip-id='copy_bar_tooltip'
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(address).then(
                        function () {
                          setcopyAddress((r) => "Copied!");
                          setTimeout(() => {
                            setcopyAddress((r) => "Copy Address");
                          }, 3000);
                        },
                        function (err) {
                          console.error("Async: Could not copy text: ", err);
                        }
                      );
                    }}
                  />
                </span>
              </p>
              <p className='last_played'>
                Last played: {getTimeAgo(new Date(lastPlayed).getTime())}{" "}
              </p>
              <StatBox
                icon={<FaMedal className='win_rate_icon' />}
                title={`${
                  userStat
                    ? userStat?.game_created + userStat?.game_participated == 0
                      ? 0
                      : Math.floor(
                          (userStat.game_won /
                            (userStat?.game_created +
                              userStat?.game_participated -
                              userStat?.game_draw)) *
                            100
                        )
                    : 0
                }% Win Rate`}
                desc={
                  <div className='win_rate_box'>
                    <div
                      className='win_rate_bar'
                      style={{
                        width: `${
                          userStat
                            ? userStat?.game_created + userStat?.game_participated == 0
                              ? 0
                              : Math.floor(
                                  (userStat.game_won /
                                    (userStat?.game_created +
                                      userStat?.game_participated -
                                      userStat?.game_draw)) *
                                    100
                                )
                            : 0
                        }%`,
                      }}></div>
                  </div>
                }
              />
              <div className='stat_list'>
                <StatBox
                  icon={<GiMedal className='streak_fire' />}
                  title={`${userStat?.rank ? `Ranked ${userStat?.rank}` : `UnRanked`}`}
                  desc={`${userStat?.score ? userStat?.score : "0"} score`}
                />
                <StatBox
                  icon={<img src={ICONS.iconex_logo} />}
                  title={`${
                    rpsService.getICXCashTitleName(
                      userStat?.waged_amount?.ICX
                        ? userStat?.earned.ICX - userStat?.waged_amount.ICX <= 0
                          ? 0
                          : userStat?.earned.ICX - userStat?.waged_amount.ICX
                        : 0
                    ).name
                  }`}
                  desc={`${
                    rpsService.getICXCashTitleName(
                      userStat?.waged_amount?.ICX
                        ? userStat?.earned.ICX - userStat?.waged_amount.ICX <= 0
                          ? 0
                          : userStat?.earned.ICX - userStat?.waged_amount.ICX
                        : 0
                    ).amount
                  } $ICX earned`}
                />{" "}
                <StatBox
                  icon={<FaHandshake className='r_icon' />}
                  title={`${userStat?.respect ? userStat?.respect : 0} Respects`}
                  desc={rpsService.getRespectName(userStat?.respect ? userStat?.respect : 0)}
                />
                <StatBox
                  icon={<img src={ICONS.crown_logo} className='streak_fire' />}
                  title={`${
                    rpsService.getCashTitleName(
                      userStat?.waged_amount?.CROWN
                        ? userStat?.earned.CROWN - userStat?.waged_amount.CROWN <= 0
                          ? 0
                          : userStat?.earned.CROWN - userStat?.waged_amount.CROWN
                        : 0
                    ).name
                  }`}
                  desc={`${
                    rpsService.getCashTitleName(
                      userStat?.waged_amount?.CROWN
                        ? userStat?.earned.CROWN - userStat?.waged_amount.CROWN <= 0
                          ? 0
                          : userStat?.earned.CROWN - userStat?.waged_amount.CROWN
                        : 0
                    ).amount
                  } $CROWN earned`}
                />{" "}
                <StatBox
                  icon={<BsFire className='streak_fire' />}
                  title={`${userStat?.max_win_streak ? userStat?.max_win_streak : 0} Win Streak`}
                  desc={rpsService.getStreakName(
                    userStat?.max_win_streak ? userStat?.max_win_streak : 0
                  )}
                />
              </div>

              <div className='rps_stat_cards_lisst'>
                <div className='rps_stat_card'>
                  <div className='rps_s_label'>Game Played</div>
                  <div className='rps_s_value'>
                    {userStat?.game_created ? userStat?.game_created : 0} Created
                  </div>
                  <div className='rps_s_value'>
                    {userStat?.game_participated ? userStat?.game_participated : 0} Participated
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
      <div className='title'>
        {walletAddress == address ? "My" : `${userName?.slice(0, 5)}..'s`} Opened Games
      </div>
      <p className='open_game_description'>Hosted games.</p>
      <div className='rps_profile_their_open_game'>
        {listOfOpenGames.length <= 0 && <div className='no_game'>No games hosted yet</div>}
        {listOfOpenGames?.map((data, index) => {
          return data?.status == "0x1" && data?.played == "0x0" ? (
            <>
              <RPSGameCard
                preSelectedId={null}
                currentSelectedOpenGame={null}
                setcurrentSelectedOpenGame={(e: any) => {
                  setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.PLAY);
                  setSearchParams((param) => {
                    param.set(E_RPS_URL_QUERY_ID.GAME_ID, `${(e.nftId as any) / 1}`);
                    return param;
                  });
                }}
                data={data}
                key={index}
              />
            </>
          ) : null;
        })}
      </div>
    </div>
  );
}

export default RPSProfile;
