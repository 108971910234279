import React, { useEffect, useState } from "react";
import Modal from "./Modal.component";
import PropertyCarousel from "../propertyCarousel/propertyCarousel.component";
import PlainSeperator from "../decorators/plainSeperator";
import "react-tooltip/dist/react-tooltip.css";
import GangstaBetTooltip from "../tooltip/GangstaBet.tooltip";
import { getHighestGangstaBet } from "../../services/data/data.service";
import ImageLoader from "../imageLoader/ImageLoader";
import { DISTRICT_DESCRIPTION, DISTRICT_NAME } from "../../constants/districts.ploygons";
import { EXTERNAL_URL } from "../../constants/externalURI";

function DistrictDetailsModal({
  isOpen,
  setOpen,
  selectedDistrict = "",
  setSelectedDistrict,
}: any) {
  //   const [gangstaBetDetail, setgangstaBetDetail] = useState<any>(null);
  //   const setGangstaBet = async () => {
  //     const res = await getHighestGangstaBet(details.owner);
  //     setgangstaBetDetail(res);
  //   };
  //   useEffect(() => {
  //     if (details) setGangstaBet();

  //     return () => {};
  //   }, [details]);

  return (
    <Modal
      width='380px'
      title={`${DISTRICT_NAME[selectedDistrict]}`}
      open={isOpen}
      setOpen={(e: any) => {
        setOpen(false);
        setSelectedDistrict(null);
      }}>
      {selectedDistrict && (
        <div className='land-details-wrapper'>
          <div className='property-profile-wrapper'>
            <div className='profile-left'>
              <ImageLoader
                skeletonStyle={{
                  width: "100%",
                  height: "300px",
                }}
                src={`${EXTERNAL_URL.EMERALD_CITY_MAP_DISTRICTS}thumbnails/${selectedDistrict[0]}${
                  selectedDistrict.split("_")[1]
                }.png`}
              />
              <PlainSeperator width='100%' />
              <div className='district_desc'>{DISTRICT_DESCRIPTION[selectedDistrict]}</div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default DistrictDetailsModal;
