import React, { useEffect, useState } from "react";
import { IoWalletOutline } from "react-icons/io5";
import { FaHandshake } from "react-icons/fa";
import { useRPSContext } from "../../../../services/contexts/rps.context";
import { useWalletContext } from "../../../../services/contexts/wallet.context";
import { MINI_GAMES } from "../../../../assets/assets";
import { getUser } from "../../../../services/data/rpsAPIs.services";
import Loading from "../../../../components/common/Loading.component";
import { FaSearch } from "react-icons/fa";
import { BsFire } from "react-icons/bs";
import { MdPersonAddAlt1 } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { ImProfile } from "react-icons/im";
import { IoPersonCircle } from "react-icons/io5";

import { E_RPS_URL_QUERY_ID } from "../../../../enums/RPS.enums";
import RPSProfile from "./RPS.profile";
import rpsService from "../../../../services/contract/rps.service";
import { useIconEventContext } from "../../../../services/contexts/iconEvent.context";

function MyProfile({ getUserDetails }) {
  const { userName, userStat, nftId, setFriendList, friendList, lastPlayed } = useRPSContext();
  const { walletAddress } = useWalletContext();
  const [searchPlayerDetails, setsearchPlayerDetails] = useState<any>(false);
  const { isTransactionLoading, currentEventType } = useIconEventContext();
  const [isSeaching, setIsSearching] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchWalletAddress, setsearchWalletAddress] = useState("");

  useEffect(() => {
    getUserDetails();
    console.log(searchParams.get(E_RPS_URL_QUERY_ID.FIND_GANGSTER));
    return () => {};
  }, []);
  return (
    <div className='rps_find_gangster' style={{ position: "relative" }}>
      {isSeaching && <Loading message='Loading ... ' />}
      {userName ? (
        <RPSProfile
          addFriend={() => {}}
          nftId={nftId}
          userName={userName}
          friendList={friendList}
          address={walletAddress}
          userStat={userStat}
          listOfLatestGames={[]}
          lastPlayed={lastPlayed}
        />
      ) : (
        <div className='history_empty'>
          <IoPersonCircle className='icon_skull' />
          <h3> Start playing to see your profile stats.</h3>
          <p> Looks like you haven't played any.</p>
        </div>
      )}

      {/* userName ? (
       
      ) : (
        <div className='history_empty'>
          <IoPersonCircle className='icon_skull' />
          <h3> Start playing to see your profile stats.</h3>
          <p> Looks like you haven't played any.</p>
        </div>
      ) */}
    </div>
  );
}

export default MyProfile;
