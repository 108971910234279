import React, { useState } from "react";
import EmeraldCityMap from "./components/leaflet/MapContainer.component";
import MapTypes from "./components/leaflet/mapType/MapTypes.component";
import { TSelectedMapType } from "./interfaces/maps.interfaces";
import SideMenu from "./components/sideMenu/SideMenu.component";
import HeaderLogo from "./components/common/HeaderLogo.component";
import { useWalletContext } from "./services/contexts/wallet.context";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import MapPage from "./pages/Map.component";
import Viewer3DPage from "./pages/Viewer3D.component";

function App() {
  const [selectedMapType, setselectedMapType] = useState<TSelectedMapType>("GAME");
  const [showDistrict, setShowDistrict] = useState<boolean>(false);
  const [showMyLands, setShowMyLands] = useState<boolean>(false);
  const [showMintedLands, setShowMintedLands] = useState<boolean>(true);
  const [showZones, setShowZones] = useState<Record<number, boolean>>({
    1: false,
    2: false,
    3: false,
    4: false,
    6: false,
    5: false,
    7: false,
    8: false,
  });
  return (
    <div className='main_container'>
      <BrowserRouter>
        <div
          className=' mx-auto'
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: "1",
          }}>
          <Routes>
            <Route path='/' element={<MapPage />} />
            <Route path='/:value' element={<MapPage />} />
            <Route path='/game/:gameId' element={<MapPage />} />
            <Route path='/3d' element={<Viewer3DPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
