import { useEffect, useState } from "react";
import { ICONS } from "../../assets/assets";
import { FaChevronDown, FaChevronRight } from "react-icons/fa6";

// const data = ;

const Dropdown = ({
  data,
  onChange,
  initialValue,
}: {
  data: Array<{ label: string; icon?: any }>;
  onChange: any;
  initialValue: string;
}) => {
  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState<Array<{ label: string; icon?: any }>>(data);
  const [selectedItem, setSelectedItem] = useState<string>(initialValue);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (id) => {
    selectedItem == id ? setSelectedItem(selectedItem) : setSelectedItem(id);
  };
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className='dropdown'>
      <div className='dropdown-header' onClick={toggleDropdown}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {items.find((item) => item.label == selectedItem)?.icon}
          {items.find((item) => item.label == selectedItem)?.label}
        </div>
        <FaChevronDown className={`icon ${isOpen && "open"}`} />
      </div>
      <div className={`dropdown-body ${isOpen && "open"}`}>
        {items.map((item) => (
          <div
            className='dropdown-item'
            onClick={(e) => {
              console.log(e.currentTarget.id);
              handleItemClick(e.currentTarget.id);
              onChange(e.currentTarget.id);
            }}
            id={`${item.label}`}>
            <span className={`dropdown-item-dot ${item.label == selectedItem && "selected"}`}>
              •{" "}
            </span>
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};
export default Dropdown;
