import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

const buildUrl = "v1.1.1/Build";
const loaderUrl = buildUrl + "/WebGL.loader.js";

function Viewer3DPage() {
  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    dataUrl: buildUrl + "/EmeraldCity_LandViewer.data.unityweb",
    frameworkUrl: buildUrl + "/EmeraldCity_LandViewer.framework.js.unityweb",
    codeUrl: buildUrl + "/EmeraldCity_LandViewer.wasm.unityweb",
    loaderUrl: buildUrl + "/EmeraldCity_LandViewer.loader.js",
    // streamingAssetsUrl: "StreamingAssets",
    // companyName: "DefaultCompany",
    // productName: "EmeraldCityViewer",
    // productVersion: "0.1",
  });
  const loadingPercentage = Math.round(loadingProgression * 100);
  return (
    <div>
      {" "}
      {/* {isLoaded === false && (
        <div className='loading-overlay'>
          <p className='loading-percentage'>Delivering Data... ({loadingPercentage}%)</p>
        </div>
      )} */}
      <Unity
        style={{
          width: "100vw",
          height: "100vh",
          borderRadius: "2px",
        }}
        unityProvider={unityProvider}
      />
    </div>
  );
}

export default Viewer3DPage;
