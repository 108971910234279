import { IconEventProvider } from "./iconEvent.context";
import { RPSProvider } from "./rps.context";
import { WalletProvider } from "./wallet.context";

const IndexContext = ({ children }: any) => {
  return (
    <WalletProvider>
      <RPSProvider>
        <IconEventProvider>{children}</IconEventProvider>
      </RPSProvider>
    </WalletProvider>
  );
};

export default IndexContext;
