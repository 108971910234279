import { CRS, Renderer } from "leaflet";
import { useEffect, useState } from "react";
import { MapContainer, TileLayer, Popup, ZoomControl, useMapEvents } from "react-leaflet";
import L from "leaflet";
import ListenerComponent from "./Listener.component";
import { convertPointsToLatLng } from "../../utils/cordinates.utils";
import Districtslayer from "./layers/Districts.layer";
import { TSelectedMapType } from "../../interfaces/maps.interfaces";
import MintedLandsLayers from "./layers/AllLands.layers";
import ZonesLayer from "./layers/Zones.layer";
import "leaflet-rotate";
import LandDetails from "../modal/LandDetails.modal";
import MyLandsLayer from "./layers/MyLands.layers";
import LandModel from "../modal/LandModel.model";
import { getLandMetaData } from "../../services/data/data.service";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { NFTID_COORD } from "../../constants/nftIdCoordsMapping";
import MapTypes from "./mapType/MapTypes.component";
import DistrictDetailsModal from "../modal/DistrictDetails.modal";
import { EXTERNAL_URL } from "../../constants/externalURI";
import SEO from "../SEO/SEO.helmet";
import FilterLandsLayers from "./layers/FilterLands.layers";
import BoxMarker from "./markers/Box.marker";
import RPSModal from "../../activities/rps/RPS.modal";
import { MARKERS } from "../../assets/assets";
import "../../styles/leaflet/leaflet.css";
import MiniGameModal from "../modal/MiniGames.modal";
import SnowAnimation from "../common/Snow.component";
const INITIAL_ZOOM_LEVEL = 3;

interface IEmeraldCityMap {
    selectedMapType: TSelectedMapType;
    showDistrict: boolean;
    showMintedLands: boolean;
    showZones: Record<number, boolean>;
    showMyLands: boolean;
    setselectedMapType: any;
    toggleMarkerZone: boolean;
    selectedFilterSubCategory: string;
    setselectedFilterSubCategory: any;
}
type TDistrictTypes =
    | "district_1"
    | "district_2"
    | "district_3"
    | "district_4"
    | "district_5"
    | "district_6"
    | "district_7"
    | "district_8"
    | "district_9";

function EmeraldCityMap({
    selectedMapType,
    showDistrict,
    showZones,
    showMintedLands,
    showMyLands,
    setselectedMapType,
    toggleMarkerZone,
    selectedFilterSubCategory,
    setselectedFilterSubCategory,
}: IEmeraldCityMap) {
    const { value, project, id, gameId }: any = useParams();
    const [selectAddress, setselectedAddress] = useState("");
    const [showInteractableLands, setShowInteractableLands] = useState(true);
    const [zoomLevel, setZoomLevel] = useState(INITIAL_ZOOM_LEVEL);
    const [openLandDetailModal, setOpenLandDetailsModal] = useState<boolean>(false);
    const [openRPS, setOpenRPS] = useState<boolean>(false);
    const [openDistrictDetailModal, setOpenDistrictDetailsModal] = useState<boolean>(false);
    const [selectlandDetails, setSelectedlandDetails] = useState<any>(null);
    const [selectedDistrictDetails, setSelectedDistrictDetails] = useState<any>(null);
    const [selectedLand, setSelectedLands] = useState<string | null>(null);
    const [selectedDistrict, setSelectedDistrict] = useState<TDistrictTypes | null>(null);

    const setOpen3DModel = (bool: boolean) => {
        if (bool && selectedLand) {
            const sx = selectedLand.split("_")[1];
            const sy = selectedLand.split("_")[2];
            window.open(`${window.location.origin}/3d?${sx}x${sy}`);
        }
    };

    const getFolderName = (type: TSelectedMapType) => {
        switch (type) {
            case "GAME":
                return "default";
            case "SATELLITE":
                return "satellite_v1.0";
            case "HYBRID":
                return "v1.3";
            default:
                return "null";
        }
    };
    const [searchParams, setSearchParams] = useSearchParams();

    const pr = {
        rotate: window.innerWidth <= 576,
        touchRotate: false,
        rotateControl: {
            closeOnZeroBearing: false,
        },
        bearing: 90,
    };
    console.log(pr);
    const [IsMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (window.innerWidth <= 571) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        console.log(`PPPP: ${value}`);
        if (Object.keys(NFTID_COORD)?.includes(value)) {
            setSelectedLands(value);
            const sx = value.split("_")[1];
            const sy = value.split("_")[2];
            selectThisLand(NFTID_COORD[value], sx, sy);
        }

        console.log("gameId", gameId);
        if (gameId == "rps") {
            setOpenRPS((p) => true);
            setMetaData({
                pageTitle: `Mini Game | Rock, Paper, Scissors`,
                pageDescription:
                    "Rediscover the thrill of a timeless childhood favorite – Rock, Paper, Scissors (RPS) – now brought to life in an exciting, modern twist. Challenge friends and rivals and immerse yourself in the nostalgia of a game loved by generations.",
                pageKeywords: null,
                pageImage: null,
            });
        }
        return () => {};
    }, []);
    const navigate = useNavigate();

    const selectThisLand = async (nftId: any, x: any, y: any) => {
        const res = await getLandMetaData(nftId);
        console.log(res);
        setSelectedlandDetails(res);
        setSelectedLands(`land_${x}_${y}`);
        setOpenLandDetailsModal(true);
        setOpenDistrictDetailsModal(false);
        navigate(`/land_${x}_${y}`);
        setMetaData({
            pageTitle: `Emerald City Map | Land #${nftId}`,
            pageDescription: null,
            pageKeywords: null,
            pageImage: { src: res?.image_url },
        });
    };
    const selectThisDistrict = async (type: TDistrictTypes) => {
        setSelectedDistrictDetails(null);
        setSelectedDistrict(type);
        setOpenDistrictDetailsModal(true);
        setOpenLandDetailsModal(false);
    };
    const [metaData, setMetaData] = useState<any>({
        pageTitle: null,
        pageDescription: null,
        pageKeywords: null,
        pageImage: null,
    });
    console.log("selectedFilterSubCategory", selectedFilterSubCategory);
    return (
        <SEO pageMetaData={metaData}>
            {(openLandDetailModal && window.innerWidth <= 576) || (
                <MapTypes
                    selectedMapType={selectedMapType}
                    setselectedMapType={setselectedMapType}
                />
            )}
            {IsMobile == true && openRPS == true ? null : (
                <div
                    className="emerald_city_map_container container min-w-[100%] mx-auto"
                    style={{
                        height: "100%",
                        position: "relative",
                        overflow: "hidden",
                        zIndex: "1",
                        margin: "0px 0px",
                    }}
                >
                    <MapContainer
                        {...pr}
                        center={[
                            convertPointsToLatLng(0, 0, zoomLevel).lat,
                            convertPointsToLatLng(0, 0, zoomLevel).lng,
                        ]}
                        // dragging={false}
                        // wheelPxPerZoomLevel={10000}
                        className="leaflet_emerald_map_container"
                        bounceAtZoomLimits={true}
                        zoomSnap={1}
                        zoomDelta={0.2}
                        // tap={false}\
                        minZoom={3}
                        zoom={INITIAL_ZOOM_LEVEL}
                        maxZoom={7}
                        zoomControl={false}
                        crs={CRS.Simple}
                        zoomAnimation={true}
                        fadeAnimation={true}
                        preferCanvas={false}
                        style={{
                            background: "#294b61",
                        }}
                        // maxBounds={maxBounds}
                        scrollWheelZoom={true}
                    >
                        <ListenerComponent
                            setZoomLevel={setZoomLevel}
                            setShowInteractableLands={setShowInteractableLands}
                            selectedAddress={selectAddress}
                        />
                        <TileLayer
                            url={`${EXTERNAL_URL.EMERALD_CITY_MAP_TILE}${getFolderName(
                                selectedMapType
                            )}/{z}/{x}/{y}.png`}
                        />
                        <ZoomControl position="bottomright" />
                        <BoxMarker
                            className={`minted_lands`}
                            marker={
                                new L.Icon({
                                    iconUrl: MARKERS.marker_rps,
                                    iconSize: [25, 30], // set the size of your custom marker
                                })
                            }
                            key={0}
                            onClick={async () => {
                                selectThisLand(35, 15, 12);
                            }}
                            pathOptions={{
                                weight: 1,
                                fill: true,
                                fillColor: selectedLand == `land_${15}_${12}` ? "red" : "#006900",
                                fillOpacity: 0.5,
                                color: selectedLand == `land_${15}_${12}` ? "red" : "#006900",
                            }}
                            rectangleBBox={[
                                [
                                    convertPointsToLatLng(15, 12, 0).lat,
                                    convertPointsToLatLng(15, 12, 0).lng,
                                ],
                                [
                                    convertPointsToLatLng(15 + 1, 12 - 1).lat,
                                    convertPointsToLatLng(15 + 1, 12 - 1).lng,
                                ],
                            ]}
                        />
                        {/* 48,18 */}
                        <BoxMarker
                            className={`minted_lands`}
                            marker={
                                new L.Icon({
                                    iconUrl: MARKERS.marker_beatdown,
                                    iconSize: [25, 30], // set the size of your custom marker
                                })
                            }
                            key={0}
                            onClick={async () => {
                                selectThisLand(72, -15, 5);
                            }}
                            pathOptions={{
                                weight: 1,
                                fill: true,
                                fillColor: selectedLand == `land_${-15}_${5}` ? "red" : "#006900",
                                fillOpacity: 0.5,
                                color: selectedLand == `land_${-15}_${5}` ? "red" : "#006900",
                            }}
                            rectangleBBox={[
                                [
                                    convertPointsToLatLng(-15, 5, 0).lat,
                                    convertPointsToLatLng(-15, 5, 0).lng,
                                ],
                                [
                                    convertPointsToLatLng(-15 + 1, 5 - 1).lat,
                                    convertPointsToLatLng(-15 + 1, 5 - 1).lng,
                                ],
                            ]}
                        />{" "}
                        <BoxMarker
                            className={`minted_lands`}
                            marker={
                                new L.Icon({
                                    iconUrl: MARKERS.marker_mobRun,
                                    iconSize: [25, 30], // set the size of your custom marker
                                })
                            }
                            key={0}
                            onClick={async () => {
                                selectThisLand(24, 48, 18);
                            }}
                            pathOptions={{
                                weight: 1,
                                fill: true,
                                fillColor: selectedLand == `land_${48}_${18}` ? "red" : "#006900",
                                fillOpacity: 0.5,
                                color: selectedLand == `land_${48}_${18}` ? "red" : "#006900",
                            }}
                            rectangleBBox={[
                                [
                                    convertPointsToLatLng(48, 18, 0).lat,
                                    convertPointsToLatLng(48, 18, 0).lng,
                                ],
                                [
                                    convertPointsToLatLng(48 + 1, 18 - 1).lat,
                                    convertPointsToLatLng(48 + 1, 18 - 1).lng,
                                ],
                            ]}
                        />
                        {showMyLands && (
                            <MyLandsLayer
                                selectedLand={selectedLand}
                                selectThisLand={selectThisLand}
                            />
                        )}
                        {showDistrict && (
                            <Districtslayer
                                selectThisDistrict={selectThisDistrict}
                                selectedDistrict={selectedDistrict}
                                zoomLevel={zoomLevel}
                            />
                        )}
                        {showMintedLands && (
                            <MintedLandsLayers
                                selectThisLand={selectThisLand}
                                selectedLand={selectedLand}
                            />
                        )}{" "}
                        {selectedFilterSubCategory.length >= 1 && (
                            <FilterLandsLayers
                                selectedFilterSubCategory={selectedFilterSubCategory}
                                selectThisLand={selectThisLand}
                                selectedLand={selectedLand}
                            />
                        )}
                        {(showZones[1] ||
                            showZones[2] ||
                            showZones[3] ||
                            showZones[4] ||
                            showZones[5] ||
                            showZones[6] ||
                            showZones[7] ||
                            showZones[8]) && (
                            <ZonesLayer
                                showZones={showZones}
                                selectedLand={selectedLand}
                                toggleMarkerZone={toggleMarkerZone}
                                selectThisLand={selectThisLand}
                            />
                        )}
                        <BoxMarker
                            className={`minted_lands`}
                            key={7856}
                            onClick={async () => {
                                setOpenRPS(true);
                            }}
                            pathOptions={{
                                weight: 0.1,
                                fill: true,
                                fillColor: "white",
                                fillOpacity: 0.1,
                                color: "white",
                            }}
                            rectangleBBox={[
                                [
                                    convertPointsToLatLng(-52, -24, 0).lat,
                                    convertPointsToLatLng(-52, -24, 0).lng,
                                ],
                                [
                                    convertPointsToLatLng(-52 + 1, -24 - 1).lat,
                                    convertPointsToLatLng(-52 + 1, -24 - 1).lng,
                                ],
                            ]}
                        />
                    </MapContainer>
                </div>
            )}
            <LandDetails
                isOpen={openLandDetailModal}
                setOpen={setOpenLandDetailsModal}
                setSelectedLands={setSelectedLands}
                setOpenRPS={setOpenRPS}
                setOpen3DModel={setOpen3DModel}
                details={selectlandDetails}
            />
            <DistrictDetailsModal
                setSelectedDistrict={setSelectedDistrict}
                isOpen={openDistrictDetailModal}
                selectedDistrict={selectedDistrict}
                setOpen={setOpenDistrictDetailsModal}
            />
            <MiniGameModal setOpenRPS={setOpenRPS} isOpen={true} setOpen={() => {}} />
            <RPSModal open={openRPS} setOpen={setOpenRPS} landID={value} />
            {/* <SnowAnimation /> */}
        </SEO>
    );
}

export default EmeraldCityMap;
