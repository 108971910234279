import React from "react";
import "./ts.scss";
function MakeItRain({ type }: { type: "ICX" | "CROWN" }) {
  return (
    <div className='makeItRain'>
      {[...Array(80)].map((e, i) => (
        <div key={i} className={`token-${i} ${type}`}></div>
      ))}
    </div>
  );
}

export default MakeItRain;
