import React from "react";
interface IToggleSwitch {
  onChange: any;
  isDisabled: boolean;
  checked?: boolean;
  id?: string;
  type: "large" | "medium" | "small";
}
function ToggleSwitch({ onChange, isDisabled, type, checked, id }: IToggleSwitch) {
  return (
    <label data-tooltip-id={id} className={`switch ${type} ${isDisabled && "disabled"}`}>
      <input checked={checked} onChange={onChange} disabled={isDisabled} type='checkbox' />
      <span className='slider round'></span>
    </label>
  );
}

export default ToggleSwitch;
