import React, { useState } from "react";
import { TSelectedMapType } from "../interfaces/maps.interfaces";
import EmeraldCityMap from "../components/leaflet/MapContainer.component";
import SideMenu from "../components/sideMenu/SideMenu.component";
import ProfilePicture from "../components/common/ProfilePicture.component";
import MiniGameModal from "../components/modal/MiniGames.modal";

function MapPage() {
  const [selectedMapType, setselectedMapType] = useState<TSelectedMapType>("HYBRID");
  const [showDistrict, setShowDistrict] = useState<boolean>(false);
  const [showMyLands, setShowMyLands] = useState<boolean>(false);
  const [showMintedLands, setShowMintedLands] = useState<boolean>(true);
  const [selectedFilterSubCategory, setselectedFilterSubCategory] = useState<string>("");
  const [toggleMarkerZone, setToggleMarker] = useState<boolean>(true);
  const [showZones, setShowZones] = useState<Record<number, boolean>>({
    1: false,
    2: false,
    3: false,
    4: false,
    6: false,
    5: false,
    7: false,
    8: false,
  });
  return (
    <>
      <ProfilePicture />
      <SideMenu
        setShowZones={setShowZones}
        showZones={showZones}
        setShowDistrict={setShowDistrict}
        showDistrict={showDistrict}
        setShowMyLands={setShowMyLands}
        showMyLands={showMyLands}
        showMintedLands={showMintedLands}
        setShowMintedLands={setShowMintedLands}
        setToggleMarker={setToggleMarker}
        toggleMarker={toggleMarkerZone}
        selectedFilterSubCategory={selectedFilterSubCategory}
        setselectedFilterSubCategory={setselectedFilterSubCategory}
      />
      <EmeraldCityMap
        showMintedLands={showMintedLands}
        showZones={showZones}
        showDistrict={showDistrict}
        selectedMapType={selectedMapType}
        showMyLands={showMyLands}
        setselectedMapType={setselectedMapType}
        toggleMarkerZone={toggleMarkerZone}
        selectedFilterSubCategory={selectedFilterSubCategory}
        setselectedFilterSubCategory={setselectedFilterSubCategory}
      />
    </>
  );
}

export default MapPage;
