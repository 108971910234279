import { useState } from "react";
import { useMapEvents } from "react-leaflet";

interface IListenerComponent {
  setShowInteractableLands?: any;
  selectedAddress?: string | undefined | null;
  setZoomLevel?: any;
}
function ListenerComponent({
  setZoomLevel,
  setShowInteractableLands,
  selectedAddress,
}: IListenerComponent) {
  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
      console.log(`currentZoom: ${mapEvents.getZoom()}`);
      // if (selectAddress.length >= 1) {
      //   setShowInteractableLands(true);
      //   return;
      // }
      // if (mapEvents.getZoom() >= 5) {
      //   setShowInteractableLands(true);
      // } else {
      //   setShowInteractableLands(false);
      // }
      setZoomLevel(mapEvents.getZoom());
    },
  });

  return null;
}
export default ListenerComponent;
