import React, { useEffect, useState } from "react";
import { FaFileSignature } from "react-icons/fa6";
import { useIconEventContext } from "../../../../services/contexts/iconEvent.context";

function PopUpRPSModalForMessage({
  title,
  subTitle,
  icon,
}: {
  title: string;
  subTitle: string;
  icon: any;
}) {
  const [showClose, setshowClose] = useState(false);
  const { setCurrentEventType, setIsTransactionLoading } = useIconEventContext();
  useEffect(() => {
    setTimeout(() => {
      setshowClose((e) => true);
    }, 10000);

    return () => {};
  }, []);

  return (
    <div className='rps_need_signature'>
      <div className='signature_icon'>{icon}</div>
      <div className='need_signature_title'>{title}</div>
      <div className='need_signature'>{subTitle}</div>
      {showClose && (
        <button
          className='ghost'
          onClick={(e) => {
            setCurrentEventType(null);
            setIsTransactionLoading(false);
          }}>
          Close
        </button>
      )}
    </div>
  );
}

export default PopUpRPSModalForMessage;
