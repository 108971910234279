import { DECORATOR } from "../../../assets/assets";
import "./stylishModalDecor.scss";

const StylishModalDecor = ({
  // width = "100%",
  // height = "100%",
  style,
  children,
  className,
}: any) => {
  return (
    <div className={`modal-main-decor ${className ? className : ""}`}>
      <img
        className='modal-decor-edge-tl'
        src={DECORATOR.MODAL_DECORATOR_EDGES}
        alt='decorator-div-content'
      />
      <img
        className='modal-decor-edge-tr'
        src={DECORATOR.MODAL_DECORATOR_EDGES}
        alt='decorator-div-content'
      />
      <img
        className='modal-decor-edge-bl'
        src={DECORATOR.MODAL_DECORATOR_EDGES}
        alt='decorator-div-content'
      />
      <img
        className='modal-decor-edge-br'
        src={DECORATOR.MODAL_DECORATOR_EDGES}
        alt='decorator-div-content'
      />
      <div style={style} className='modal-decor-content'>
        {children}
      </div>
    </div>
  );
};

export default StylishModalDecor;
