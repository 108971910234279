import axios from "axios";
import { EXTERNAL_URL } from "../../constants/externalURI";
import { REACT_APP_EMERALD_CITY_NODE_API, REACT_APP_GANGSTABET_NODE_API } from "./config";

export const iconUSDRate = async () => {
  try {
    const res = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=icon&vs_currencies=usd`
    );
    if (res.status == 200) {
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};
export const checkBackendHealth = async () => {
  try {
    const res = await axios.get(`${REACT_APP_EMERALD_CITY_NODE_API}`);
    if (res.status == 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};
export const getLandMetaData = async (nftId: number) => {
  try {
    const { owner, metaId } = await getLandDetailsFromSCORE(nftId);
    const res = await axios.get(`${EXTERNAL_URL.EMERALD_CITY_MAP_METADATA}${metaId}.json`);
    console.log({ ...res.data, nftId });
    if (res.status == 200) {
      return { ...res.data, nftId, owner };
    }
  } catch (error) {
    console.log(error);
  }
};
const getLandDetailsFromSCORE = async (nftId: number) => {
  try {
    const rpc_dict = {
      jsonrpc: "2.0",
      method: "icx_call",
      id: Math.floor(Math.random() * 1000),
      params: {
        to: `cx1125cae5b048ba57c9331e47e0220a3b91287ffb`,
        dataType: "call",
        data: {
          method: "getLandInfo",
          params: { nftId: `${nftId}` },
        },
      },
    };
    const res = await axios.post("https://ctz.solidwallet.io/api/v3", rpc_dict);
    if (res?.data?.result) {
      console.log(res.data);
      return res.data.result;
    } else return "";
  } catch (error) {
    console.log(error);
  }
};

export async function getMyLands(selectAddress: string): Promise<Array<number>> {
  let parsedNFTLIST: Array<number> = [];
  let nftIdList: Array<any> = [];
  try {
    const rpc_dict = {
      jsonrpc: "2.0",
      method: "icx_call",
      id: Math.floor(Math.random() * 1000),
      params: {
        to: `cx1125cae5b048ba57c9331e47e0220a3b91287ffb`,
        dataType: "call",
        data: {
          method: "getOwnersNfts",
          params: { address: selectAddress, page: "0x1" },
        },
      },
    };

    const res = await axios.post("https://ctz.solidwallet.io/api/v3", rpc_dict);
    if (res?.data?.result) {
      const { totalPage, nftOwned, nftList } = res.data.result;
      console.log({ totalPage: parseInt(totalPage, 16), nftOwned, nftList });
      if (nftList?.length >= 1) nftIdList = nftIdList.concat(nftList);
      for (let index = 2; index <= parseInt(totalPage, 16); index++) {
        console.log("page", index);
        const rpc_dict = {
          jsonrpc: "2.0",
          method: "icx_call",
          id: Math.floor(Math.random() * 1000),
          params: {
            to: `cx1125cae5b048ba57c9331e47e0220a3b91287ffb`,
            dataType: "call",
            data: {
              method: "getOwnersNfts",
              params: { address: selectAddress, page: `0x${index.toString(16)}` },
            },
          },
        };

        const res = await axios.post("https://ctz.solidwallet.io/api/v3", rpc_dict);
        if (res?.data?.result) {
          const { totalPage, nftOwned, nftList } = res.data.result;
          console.log({ totalPage: parseInt(totalPage, 16), nftOwned, nftList });
          if (nftList?.length >= 1) nftIdList = nftIdList.concat(nftList);
        }
      }
    }
    nftIdList?.map((data: any) => {
      parsedNFTLIST.push(parseInt(data, 16));
    });
  } catch (error) {
    console.log(error);
  }
  return parsedNFTLIST;
}

export async function getHighestGangstaBet(
  address: string
): Promise<{ nftId: number; name: string; image: string; nft_race: string } | null> {
  try {
    const response = await axios.get(
      `${REACT_APP_GANGSTABET_NODE_API}/one_random_heighest/${address}`
    );
    if (response.status == 200 && response.data.length >= 1) {
      const { token_uri, token_name, token_number } = response.data[0];
      const race = response.data[0]["attributes"].filter(
        (data: any) => data.key.toUpperCase() == "TYPE"
      );
      console.log(race[0], "RACEE");
      return {
        name: token_name,
        nftId: token_number,
        image: token_uri,
        nft_race: race[0].value.toUpperCase(),
      };
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
  return null;
}
