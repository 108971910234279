import { LatLngExpression } from "leaflet";

export const DISTRICT_NAME: Record<string, string> = {
  district_1: "Greenfield",
  district_2: "Heritage Quarters",
  district_3: "Winport",
  district_4: "Riverside",
  district_5: "Aeroville",
  district_6: "Kingston",
  district_7: "Pineview Crossings",
  district_8: "Crowndale",
  district_9: "Paradise Isle",
};

export const DISTRICT_DESCRIPTION: Record<keyof typeof DISTRICT_NAME, string> = {
  "district_1":
    "Greenfield is situated at the eastern side of the main island of The Emerald City. Greenfield is a more recent residential area compared to the west side. The area has seen much growth in part due to its neighboring commercial districts and being the way to the vacation island.",
  "district_2":
    "Heritage Quarters is situated at the west end of The Emerald City. This district is rich in its history with the top Gangsters of the city's history having built their home in this district.",
  "district_3":
    "Winport is situated right at the northmost point of The Emerald City. Always sought out by gangsters because of the  presence of the docks which is always a more preferred mode of smuggling anything into the city. ",
  "district_4":
    "Riverside is located south of the Emerald river that cruises through The Emerald City. Riverside is one of those districts which has a bit of both, the high energy commercial areas and the low lying residential and educational areas.",
  "district_5":
    "The aerial centre of The Emerald City, Aeroville is covered mostly by the airport that is located on the south side of the district. Aeroville is always under dispute by gangs because of the prospect it offers, who ever controls Aeroville, controls half of the ins and outs of the city.",
  "district_6":
    "Kingston is the busiest district in The Emerald City, the commercial heart of The Emerald City. Kingston is home to the tallest skyscrapers and the modern lifestyle the city radiates, but along with the tallest skyscrapers it also has the deepest underbellies.",
  "district_7":
    "Pineview Crossings is called so due to its lush trees that cover the vast area of the district. Just across the busy commercial centre, it has the best of both worlds, the commercial lands and the residential lands.",
  "district_8":
    "The heart of The Emerald City right in the middle of the city, Crowndale. Crowndale is full of the local treasures of The Emerald City, the monuments, the stadiums, the parks, all are there. Crowndale shows the glory of The Emerald City !",
  "district_9":
    "Paradise Isle is the island that people go to escape the main island's daily rush. An escape for the gangsters, detectives and the civillians alike. Paradise Isle is also the place where any celebrations/event relating to The Emerald City takes place, 'What happens in Paradise, stays in Paradise'",
};

export const DISTRICT_POLYGONS: Record<
  string,
  LatLngExpression[] | LatLngExpression[][] | LatLngExpression[][][]
> = {
  district_1: [
    [-111.2336305, 160.65123393500002],
    [-111.77783500000001, 160.657453415],
    [-111.77783500000001, 162.90579543500002],
    [-138.44859785350002, 162.910460045],
    [-138.6810509185, 162.90812774],
    [-138.6832277365, 167.80130363],
    [-138.61870063150002, 167.87904713],
    [-138.44463293500002, 167.87593739],
    [-138.1359134965, 168.34861787],
    [-138.127439455, 169.43080739],
    [-138.049540468, 169.51477037],
    [-137.905093045, 169.520212415],
    [-137.5867334125, 169.98434111],
    [-137.584945312, 171.070417805],
    [-137.52275051200002, 171.145829],
    [-136.269525292, 171.14116439],
    [-135.95691867850002, 171.62161922],
    [-135.955441552, 172.71002822],
    [-135.88492819750002, 172.777665065],
    [-135.7246211005, 172.7784425],
    [-135.420410785, 173.25656502500001],
    [-135.413258383, 174.33797711],
    [-135.33574811350002, 174.41649804500003],
    [-133.551457045, 174.40872369500002],
    [-133.2318535165, 174.873629825],
    [-133.243437298, 175.97136804500002],
    [-133.1632837495, 176.04833411],
    [-125.3829475, 176.042892065],
    [-124.3717377955, 177.05744474],
    [-124.3709603605, 177.981814955],
    [-124.369794208, 178.83932576],
    [-124.2807779005, 178.924066175],
    [-123.83235339250001, 179.23426274],
    [-123.826211656, 181.02625041500002],
    [-123.741315754, 181.11410057],
    [-123.2048078605, 181.48260476000002],
    [-122.6635576135, 181.4857145],
    [-122.34721931200001, 181.960727285],
    [-122.195308513, 182.03769335],
    [-122.194997539, 182.66275109000003],
    [-122.1149994775, 182.73582998],
    [-117.7640845, 182.72572332500002],
    [-117.685563565, 182.64564752],
    [-117.686962948, 182.48938308500001],
    [-117.2230674835, 182.19007061],
    [-115.58882137, 182.19551265500002],
    [-115.50820136050001, 182.12165633],
    [-115.513254688, 180.880092635],
    [-115.040885182, 180.40974446],
    [-115.040885182, 180.32733635000002],
    [-114.508808668, 179.93861885],
    [-114.4201033345, 179.77691237000002],
    [-114.417226825, 177.05278013],
    [-113.95908437950001, 176.590206305],
    [-113.40625035100001, 176.58631913],
    [-113.3340266395, 176.50546589],
    [-113.3208102445, 174.86585547500002],
    [-112.8680321005, 174.407168825],
    [-112.3186965295, 174.40639139],
    [-112.246006357, 174.3333125],
    [-112.2558797815, 173.25734246000002],
    [-111.7814889445, 172.782329675],
    [-111.2366624965, 172.78155224],
    [-111.15954094450001, 172.706141045],
    [-111.1509891595, 167.26176374],
    [-110.608728247, 166.732330505],
    [-110.608106299, 166.17335474],
    [-110.06405728600001, 165.64003433],
    [-110.0646014905, 161.27395937],
    [-110.68515010750001, 160.65123393500002],
    [-111.2336305, 160.65123393500002],
  ],
  district_2: [
    [-138.9881377435, 78.55300952600001],
    [-138.9887596915, 79.641574013],
    [-124.83874300000001, 79.6417295],
    [-124.83913171750001, 85.08478516550001],
    [-122.661925, 85.0837745],
    [-122.66200274350001, 90.5260527305],
    [-118.308289, 90.5265191915],
    [-118.308289, 93.79112424350001],
    [-116.055904318, 93.79112424350001],
    [-116.05372750000001, 89.82612800000001],
    [-116.594977747, 89.440831214],
    [-116.59637713000001, 84.1611923855],
    [-117.1663147285, 82.912554032],
    [-118.304868286, 82.350157553],
    [-118.3049460295, 82.2864856265],
    [-118.76472108850001, 81.8166039125],
    [-118.7684527765, 81.1229764055],
    [-119.3151450685, 80.72959429550001],
    [-119.316311221, 78.939705695],
    [-119.861448643, 78.551299169],
    [-120.9466701595, 77.4677880095],
    [-120.9513347695, 76.7702733275],
    [-121.4914966075, 76.376813474],
    [-121.4902527115, 74.5922114315],
    [-121.59559515400001, 74.489356781],
    [-122.0484510415, 74.17838278100001],
    [-123.053130292, 74.205126545],
    [-123.219345895, 74.10242738150001],
    [-123.6675371725, 73.6633320935],
    [-123.669169786, 72.950035481],
    [-124.2154733605, 72.56955879200001],
    [-124.211508442, 71.87919651200001],
    [-124.76690800600001, 71.4656788355],
    [-125.3006171335, 71.0899445],
    [-125.3799155035, 70.9416876455],
    [-125.84925301300001, 70.93220293850001],
    [-125.8381356925, 69.681698741],
    [-126.3845170105, 69.2922815495],
    [-126.38902613350001, 68.04434288750001],
    [-126.461716306, 67.9770170165],
    [-130.2729359065, 67.961857034],
    [-130.36700554150002, 68.05794800000001],
    [-130.365994876, 68.19788630000001],
    [-130.823593117, 68.5050508685],
    [-132.46164866200002, 68.52269864300001],
    [-132.544212259, 68.603396396],
    [-132.543279337, 69.8447268605],
    [-133.0021992175, 70.150958507],
    [-134.6334133345, 70.16681818100001],
    [-134.7246842035, 70.23919737950001],
    [-134.73688993300001, 71.4959988005],
    [-135.16929928000002, 71.775797657],
    [-136.7942161735, 71.7841162115],
    [-136.8910845745, 71.8738322105],
    [-136.891473292, 72.1778092955],
    [-137.358245266, 72.488550065],
    [-137.9050153015, 72.4824083285],
    [-138.44012381200002, 72.8740800815],
    [-138.978108832, 72.877889513],
    [-139.06945744450002, 72.9631741325],
    [-139.0721007235, 73.6476279065],
    [-139.6335642805, 74.213522843],
    [-140.07024952, 74.5104252695],
    [-141.176384038, 74.510813987],
    [-141.240444682, 74.59291112300001],
    [-141.250862311, 76.37502537350001],
    [-141.804085057, 76.9294920155],
    [-142.252820539, 77.22483957200001],
    [-142.79648083450002, 77.227482851],
    [-142.879588636, 77.304915377],
    [-142.8816877105, 77.86762283],
    [-142.7992796005, 78.017823272],
    [-142.79982380500002, 78.31355954600001],
    [-142.8793554055, 78.3910698155],
    [-142.87989961, 78.556119266],
    [-142.82928859150002, 78.5544866525],
    [-138.9881377435, 78.55300952600001],
  ],
  district_3: [
    [-107.891437435, 134.06917641500002],
    [-108.4386739315, 134.613380915],
    [-108.5125302565, 134.606384],
    [-118.308289, 134.60560656500002],
    [-118.3088332045, 130.252748],
    [-122.6630911525, 130.25430287],
    [-122.661614026, 101.9545027175],
    [-116.67567550000001, 101.954114],
    [-115.60919016700001, 101.9507710295],
    [-115.417319209, 102.101515676],
    [-115.0453165615, 102.4915548155],
    [-114.475378963, 103.039568747],
    [-113.796211747, 103.349609825],
    [-113.3904684205, 103.55687399600001],
    [-113.0536835785, 104.1852747065],
    [-112.8555931405, 104.67031640300002],
    [-112.859558059, 105.217008695],
    [-112.3185410425, 105.76105770800001],
    [-111.774103312, 105.745353521],
    [-111.2201808745, 106.1425450625],
    [-111.144847423, 106.31311430150001],
    [-111.1477239325, 107.240361026],
    [-111.686408644, 108.515509913],
    [-111.6595871365, 115.01292292549999],
    [-111.111573205, 115.567467311],
    [-111.102710446, 119.9591198825],
    [-110.61665808400001, 121.10295999800002],
    [-110.1253969075, 122.106006635],
    [-109.45796896, 122.72834335249999],
    [-109.054635682, 122.9855188505],
    [-108.518205532, 123.00713154350001],
    [-108.4253020495, 123.14232749000001],
    [-107.8635275185, 123.714830624],
    [-107.851166302, 127.92293079200002],
    [-107.668158103, 127.9296944765],
    [-107.4110603485, 127.9606363895],
    [-107.416580137, 128.4210333965],
    [-107.4140146015, 130.707547475],
    [-107.40554056, 134.06684411],
    [-107.891437435, 134.06917641500002],
  ],
  district_4: [
    [-138.6810509185, 162.90812774],
    [-137.56224421000002, 162.90968261],
    [-129.192534487, 162.908905175],
    [-129.2079277, 156.3123692],
    [-129.1921457695, 144.402842435],
    [-133.546170487, 144.401287565],
    [-133.5460927435, 132.9737705],
    [-134.6346572305, 132.9737705],
    [-134.6343462565, 127.53188098700002],
    [-136.2675817045, 127.53203647400002],
    [-136.266882013, 114.47089524350001],
    [-145.51960240900002, 114.4710507305],
    [-145.5277654765, 114.5018371565],
    [-145.531263934, 114.769508027],
    [-145.602166006, 114.7792259645],
    [-145.6053534895, 115.02621706400001],
    [-144.5217645865, 116.11804677800001],
    [-144.52269750850002, 127.52877124700001],
    [-145.0648806775, 127.8271508],
    [-145.06884559600002, 136.27164977],
    [-144.533970316, 136.80652505],
    [-144.534436777, 142.278890015],
    [-143.96473240900002, 142.64894907500002],
    [-143.4493707475, 142.63728755],
    [-143.350869733, 142.807545815],
    [-143.1870641785, 142.877514965],
    [-142.813351174, 142.88140214],
    [-142.2583403275, 143.44426508],
    [-141.723542791, 143.421719465],
    [-141.16091308149998, 143.96981114],
    [-139.53980561950002, 143.9511527],
    [-139.0797195865, 144.256684655],
    [-138.547098868, 144.97581203],
    [-138.54227877099999, 150.616102955],
    [-138.99047004850001, 150.83689449500002],
    [-139.076221129, 150.92785439],
    [-139.0800305605, 151.31968163],
    [-139.0071071575, 151.480610675],
    [-139.0100614105, 151.78303289000002],
    [-139.07280041500002, 151.92919067000003],
    [-139.5251898415, 151.92841323500002],
    [-139.62532346950002, 152.020928],
    [-139.6242350605, 152.242496975],
    [-140.1894303055, 152.55269354],
    [-140.1906742015, 156.41265831500002],
    [-139.630687771, 156.71430309500002],
    [-139.6261009045, 156.93898181],
    [-139.541438233, 157.015947875],
    [-139.0806525085, 157.012838135],
    [-139.00687392700002, 157.17532205],
    [-139.004852596, 157.462973],
    [-139.088815576, 157.63556357000002],
    [-139.0874939365, 158.0382749],
    [-138.5213657695, 158.56226609],
    [-138.525952636, 160.49030489],
    [-138.60089737, 160.57737761],
    [-138.671488468, 160.725867695],
    [-138.6810509185, 162.90812774],
  ],
  district_5: [
    [-146.15756557, 113.3927483855],
    [-146.6914301845, 112.8561627485],
    [-146.705812732, 112.30915948250001],
    [-147.24581908300001, 111.771407693],
    [-147.2566254295, 110.1315640475],
    [-148.340758537, 109.042610843],
    [-148.336560388, 104.6560893425],
    [-147.79849762450002, 104.26737184250001],
    [-146.7082227805, 104.267605073],
    [-146.705657245, 104.1185707835],
    [-146.1657286375, 103.5849393995],
    [-146.16145274500002, 101.97557120600001],
    [-146.707678576, 101.423592356],
    [-146.69555059, 101.272925453],
    [-147.222496033, 101.28163272500001],
    [-147.7880799955, 100.8911271245],
    [-147.84242270200002, 98.1615528395],
    [-147.25685866, 97.6999119365],
    [-147.25615896850002, 97.036526651],
    [-146.1655731505, 95.97027454850002],
    [-146.185086769, 89.9778055685],
    [-145.516337182, 89.339142716],
    [-143.373570835, 89.334633593],
    [-142.896536719, 88.8817777055],
    [-142.92102592150002, 86.176226162],
    [-143.345583175, 86.20864520149999],
    [-143.367351355, 84.2296066655],
    [-143.3574779305, 81.332806112],
    [-143.35296880750002, 79.0896729065],
    [-142.8880626775, 79.0881180365],
    [-142.88767396, 78.556119266],
    [-138.9959120935, 78.55300952600001],
    [-138.9965340415, 79.641574013],
    [-133.548502792, 79.64165175650001],
    [-133.54422689950002, 104.67560296100001],
    [-137.89910679550002, 104.6756807045],
    [-137.9042378665, 114.47089524350001],
    [-145.51960240900002, 114.4710507305],
    [-145.59415842549998, 114.47089524350001],
    [-146.1560107, 113.93322119750002],
    [-146.15756557, 113.3927483855],
  ],
  district_6: [
    [-109.0133538835, 135.678466865],
    [-108.7309894915, 135.173134115],
    [-108.441394954, 134.613380915],
    [-108.5125302565, 134.606384],
    [-118.308289, 134.60560656500002],
    [-118.307667052, 143.314433435],
    [-120.486195409, 143.31521087000002],
    [-120.48666187, 144.402842435],
    [-129.1921457695, 144.402842435],
    [-129.192534487, 162.908905175],
    [-111.77783500000001, 162.90579543500002],
    [-111.77783500000001, 160.657453415],
    [-111.2336305, 160.65123393500002],
    [-111.680189164, 160.1684468],
    [-111.67676845, 155.840466155],
    [-111.109551874, 155.293151915],
    [-111.12331247350001, 149.13353441],
    [-111.6648736945, 148.72926821000001],
    [-111.654222835, 143.32220778500002],
    [-111.1163155585, 142.79121968],
    [-111.13994958250001, 138.41892524],
    [-110.92094614300001, 137.978119595],
    [-110.9062526215, 137.878607915],
    [-110.694246097, 137.39426591],
    [-109.0133538835, 135.678466865],
  ],
  district_7: [
    [-116.0419882315, 94.348156421],
    [-116.06367866800001, 93.79112424350001],
    [-118.31606335000001, 93.79112424350001],
    [-118.31606335000001, 90.5265191915],
    [-122.6697770935, 90.5260527305],
    [-122.66969935, 85.0837745],
    [-124.8469060675, 85.08478516550001],
    [-124.84651735, 79.6417295],
    [-133.54422689950002, 79.64165175650001],
    [-133.54422689950002, 104.67560296100001],
    [-137.89910679550002, 104.6756807045],
    [-137.90276074, 111.75088340900001],
    [-122.66215823050001, 111.7506501785],
    [-122.661614026, 101.9545027175],
    [-116.67567550000001, 101.954114],
    [-115.60919016700001, 101.9507710295],
    [-116.236424725, 101.57939033],
    [-116.6544515245, 101.3949050045],
    [-116.6673569455, 99.6941881985],
    [-117.1286868745, 99.22523940650001],
    [-117.124410982, 94.8946154825],
    [-116.6701557115, 94.4306422745],
    [-116.12649541600001, 94.4224014635],
    [-116.0419882315, 94.348156421],
  ],
  district_8: [
    [-122.66215823050001, 111.75088340900001],
    [-122.6630911525, 130.25430287],
    [-118.3088332045, 130.252748],
    [-118.308289, 134.60560656500002],
    [-118.307667052, 143.314433435],
    [-120.486195409, 143.31521087000002],
    [-120.48666187, 144.402842435],
    [-129.192534487, 144.402842435],
    [-133.54422689950002, 144.401287565],
    [-133.5460927435, 132.9737705],
    [-134.6346572305, 132.9737705],
    [-134.6343462565, 127.53188098700002],
    [-136.2675817045, 127.53203647400002],
    [-136.266882013, 114.47089524350001],
    [-137.90276074, 114.47089524350001],
    [-137.90276074, 111.75088340900001],
    [-122.66215823050001, 111.75088340900001],
  ],
  district_9: [
    [-143.1976372945, 182.26003976],
    [-141.717789772, 182.25382028],
    [-141.240600169, 181.94595602],
    [-140.85553661350002, 181.94828832500002],
    [-140.7801254185, 181.789691585],
    [-140.237553532, 181.79280132500003],
    [-140.2421403985, 180.82256244500002],
    [-140.2191283225, 180.768141995],
    [-140.1410738485, 180.709056935],
    [-137.45379202750001, 180.70594719500002],
    [-137.38942040950002, 180.741709205],
    [-137.358245266, 180.79224248],
    [-137.339742313, 180.85288241],
    [-137.3354664205, 181.410303305],
    [-136.791650638, 181.799020805],
    [-136.7174055955, 181.94362371500003],
    [-136.258641202, 182.25459771500002],
    [-135.720500695, 182.25848489000003],
    [-135.63233956599998, 182.32689917000002],
    [-134.0162854315, 182.335450955],
    [-133.982156035, 183.04213937000003],
    [-133.4479804465, 183.429302],
    [-133.4493798295, 183.56846286500001],
    [-132.979420372, 183.91675374500002],
    [-132.98532887800002, 186.18764138],
    [-132.9141935755, 186.29959202],
    [-132.9165258805, 186.84846113],
    [-132.4523971855, 187.163322305],
    [-132.443767657, 189.125568245],
    [-132.915826189, 189.57725798],
    [-132.915670702, 190.58559117500002],
    [-133.121380003, 190.76362379000003],
    [-133.535752858, 190.74418791500003],
    [-134.0133311785, 191.21920070000002],
    [-133.98860874550002, 192.14434835],
    [-134.0845442245, 192.386130635],
    [-135.078028411, 193.381247435],
    [-135.092022241, 193.78007159000003],
    [-135.171787072, 193.937890895],
    [-135.3345042175, 194.02107644000003],
    [-136.258330228, 194.03351540000003],
    [-136.719193696, 194.47043387000002],
    [-136.715462008, 195.42823379000004],
    [-136.8483256495, 195.6972263],
    [-136.99028528050002, 195.73609805],
    [-137.1883757185, 195.7283237],
    [-137.801072242, 196.11548633],
    [-137.82035263, 197.04840833],
    [-137.89879582150002, 197.192233805],
    [-137.912556421, 197.27464191500002],
    [-137.943342847, 197.32206545000003],
    [-138.0118348705, 197.34849824000003],
    [-138.293655058, 197.353940285],
    [-138.9088393735, 197.74188035],
    [-138.979508215, 197.898144785],
    [-139.5796102915, 197.90280939500002],
    [-139.657587022, 197.859273035],
    [-139.6881402175, 197.804852585],
    [-139.6934267755, 197.358604895],
    [-140.126846788, 197.35938233000002],
    [-140.233355383, 197.28863574500002],
    [-140.23506574, 197.19456611],
    [-140.63015820700002, 196.66046826500002],
    [-140.6282146195, 196.35260400500002],
    [-140.7725065555, 196.2725282],
    [-141.225828904, 196.259311805],
    [-141.30295045600002, 196.22199492500002],
    [-141.319121104, 196.16679704000003],
    [-141.33739082649998, 195.72443652500002],
    [-141.707994091, 195.72754626500003],
    [-141.709548961, 196.26786359000002],
    [-142.353576115, 196.26786359000002],
    [-142.4259553135, 196.13803194500002],
    [-142.415770915, 195.743094965],
    [-143.3885753305, 195.72754626500003],
    [-143.4703614925, 195.682455035],
    [-143.50573478500002, 195.578278745],
    [-143.501536636, 193.93011654500003],
    [-143.875327384, 193.944110375],
    [-143.8741612315, 194.478985655],
    [-144.529772167, 194.47587591500002],
    [-144.586213948, 194.393467805],
    [-144.59391055449998, 193.941000635],
    [-145.030129333, 193.93555859000003],
    [-145.098077152, 193.89979658000001],
    [-145.1384260285, 193.84304382500002],
    [-145.14083607700002, 192.13657400000002],
    [-144.983949694, 192.04950128000002],
    [-144.99001368700002, 190.753517135],
    [-145.131506857, 190.6749962],
    [-145.210338766, 190.52728355000002],
    [-145.2121268665, 190.1307917],
    [-145.5179697955, 189.66977274500002],
    [-145.6827860155, 189.587364635],
    [-146.066761162, 189.04160526500002],
    [-146.178089854, 189.03149861],
    [-146.230333486, 188.93198693000002],
    [-146.2196826265, 187.34601953],
    [-146.1871081, 187.28382473000002],
    [-146.1201709465, 187.24573041500003],
    [-145.6779659185, 187.233291455],
    [-145.6751671525, 185.715738335],
    [-145.66661536750001, 185.68308606500003],
    [-145.636839607, 185.64499175],
    [-145.608385486, 185.622446135],
    [-145.5695914795, 185.60845230500001],
    [-145.1345388535, 185.60767487],
    [-145.1336059315, 185.18164049000004],
    [-145.124976403, 185.15443026500003],
    [-145.0896031105, 185.096900075],
    [-145.0544630485, 185.07357702500002],
    [-144.986281999, 185.04170219000002],
    [-144.9841829245, 184.59545450000002],
    [-144.669088519, 184.13676785],
    [-144.61357966, 183.986722895],
    [-144.44705308300001, 183.896540435],
    [-144.115243825, 183.89576300000002],
    [-144.04916185000002, 183.742608305],
    [-144.043253344, 183.51870702500003],
    [-143.99995021450002, 183.468951185],
    [-143.963644, 183.43629891500004],
    [-143.91544303, 183.430079435],
    [-143.49967079200002, 183.43163430500002],
    [-143.503868941, 182.45906312000002],
    [-143.477902612, 182.39531345],
    [-143.427524824, 182.35255452500002],
    [-143.325836326, 182.329231475],
    [-143.1976372945, 182.26003976],
  ],
};
