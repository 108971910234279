import React from "react";
import SiteMetaData from "./SiteMetaData";

const SEO = ({ children, pageMetaData }: any) => {
  const siteMetadata = {
    siteUrl: "https://map.emeraldcity.xyz/",
    title: "Emerald City Map",
    description:
      "The map of Emerald City - Step into the virtual realm of The Emerald City, a captivating metropolis where Gangsters, Detectives, and Civilians live out their days. Explore the old-world charm of Heritage Quarters, the modern housings of Greenfield or experience the bustling energy of Winport. Witness the heart of the city in Crowndale or indulge in the enchanting allure of Paradise Isle. From the vibrant Aeroville to the commercial areas of Kingston and Pineview Crossings, explore the city with a life of its own.",
    keywords:
      "blockchain, virtual city, emerald city map, maps, google maps, leaflet, gangstabets, gangstaverse",
  };
  return (
    <>
      <SiteMetaData siteMetadata={siteMetadata} pageMetaData={pageMetaData} />
      {children}
    </>
  );
};

export default SEO;
