export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
export function appendUniqueStringsToList(list1: Array<string>, list2: Array<string>) {
  // Create a Set to store unique strings
  const uniqueStrings = new Set(list1);

  // Iterate over each string in list2
  for (const newString of list2) {
    // Check if the newString is not already in the Set
    if (!uniqueStrings.has(newString)) {
      uniqueStrings.add(newString);
    }
  }

  // Convert the Set back to an array
  const combinedList = Array.from(uniqueStrings);

  // Return the combined list with unique strings
  return combinedList;
}
export function sortStringsAlphabetically(arr: Array<string>) {
  arr.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));
  return arr;
}
export function randomIntFromInterval(min: number, max: number): number {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function findChanges(oldArray: Array<any>, newArray: Array<any>) {
  const addedGameIds: any = [];
  const removedGameIds: any = [];
  const updatedGameIds: any = [];
  try {
    // Create a map of gameIds to their corresponding objects in the oldArray
    const oldArrayMap = new Map();
    oldArray.forEach((item) => oldArrayMap.set(item.id, item));

    // Iterate through the newArray and check for changes
    newArray.forEach((newItem: any) => {
      const oldItem = oldArrayMap.get(newItem.id);

      // Compare oldItem and newItem to check for changes
      if (!oldItem) {
        // Item is added
        addedGameIds.push(newItem.id);
      }
    });

    // Iterate through the oldArray to find removed items
    oldArray.forEach((oldItem) => {
      const newItem = newArray.find((item) => item.id === oldItem.id && item.played === "0x0");
      if (!newItem) {
        // Item is removed
        removedGameIds.push(oldItem.id);
      }
    });

    return { added: addedGameIds, removed: removedGameIds };
  } catch (error) {
    return { added: [], removed: [] };
  }
}
export function removeGameIds(array: any, gameIdsToRemove: any) {
  return array.filter((item: any) => !gameIdsToRemove.includes(item.id));
}
export function getGameIdsObj(array: any, gameIdsToRemove: any) {
  return array.filter((item: any) => gameIdsToRemove.includes(item.id));
}
export function getTimeAgo(timestamp: number) {
  const currentTime = new Date().getTime();
  const inputTime = timestamp;

  const timeDifference = currentTime - inputTime;
  const minutesAgo = Math.floor(timeDifference / (1000 * 60));
  const hoursAgo = Math.floor(minutesAgo / 60);
  const daysAgo = Math.floor(hoursAgo / 24);

  if (minutesAgo < 60) {
    return minutesAgo == 0 ? "Just now" : `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
  } else if (hoursAgo < 24) {
    return `${hoursAgo} hour${hoursAgo !== 1 ? "s" : ""} ago`;
  } else {
    return `${daysAgo} day${daysAgo !== 1 ? "s" : ""} ago`;
  }
}
export const objectToHexWithPrefix = (params: any) => {
  const enc = new TextEncoder();
  const arrayBuffer = enc.encode(JSON.stringify(params));

  if (
    typeof arrayBuffer !== "object" ||
    arrayBuffer === null ||
    typeof arrayBuffer.byteLength !== "number"
  ) {
    throw new TypeError("Expected input to be an ArrayBuffer");
  }

  const view = new Uint8Array(arrayBuffer);
  let result = "";
  let value;

  for (let i = 0; i < view.length; i += 1) {
    value = view[i].toString(16);
    result += value.length === 1 ? `0${value}` : value;
  }

  return `0x${result}`;
};

// export function
export function abbreviateNumber(value) {
  var newValue: any = value;
  if (value >= 1000) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue: any = "";
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision)
      );
      var dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}
export function capitalize(str: string, lower: boolean = false) {
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );
}
