"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const EventFilter_1 = require("./EventFilter");
const index_1 = require("../../data/index");
const EventNotification_1 = require("../../data/Formatter/EventNotification");
class EventMonitorSpec {
    constructor(height, eventFilter, logs, progressInterval) {
        this.height = height;
        if (eventFilter instanceof EventFilter_1.default) {
            this.eventFilters = [eventFilter];
        }
        else {
            this.eventFilters = eventFilter;
        }
        this.logs = logs;
        this.progressInterval = progressInterval;
    }
    getPath() {
        return "event";
    }
    getParam() {
        let ret = {};
        if (this.eventFilters.length == 1) {
            ret = Object.assign(ret, this.eventFilters[0].toObject());
        }
        else {
            ret["eventFilters"] = this.eventFilters.map((v) => v.toObject());
        }
        if (this.logs)
            ret["logs"] = "0x1";
        ret["height"] = index_1.Converter.toHex(this.height);
        if (this.progressInterval > 0)
            ret["progressInterval"] = index_1.Converter.toHex(this.progressInterval);
        return ret;
    }
    getConverter() {
        return (data) => new EventNotification_1.default(data);
    }
}
exports.default = EventMonitorSpec;
