import React, { useEffect, useState } from "react";
import { GiOpenTreasureChest } from "react-icons/gi";
import { GiLockedChest } from "react-icons/gi";
import RPSRewardServices from "../../../../services/contract/rps.reward";
import { useWalletContext } from "../../../../services/contexts/wallet.context";
import { ZERO_ADDRESS } from "../../../../enums/RPS.enums";
import { useIconEventContext } from "../../../../services/contexts/iconEvent.context";
import { E_ICONEX_RPC_EVENT_TYPE } from "../../../../hooks/useIconexHook";
function RewardsComponents() {
    const [rewards, setrewards] = useState<{ ICX: number; CROWN: number }>({
        ICX: 0,
        CROWN: 0,
    });
    const [rewardsDis, setrewardsDis] = useState<Array<any>>([]);
    const [round2, setRound2] = useState<number>(0);
    const { walletAddress, isWalletConnected } = useWalletContext();
    const { setIsTransactionLoading, isTransactionLoading, currentEventType, setCurrentEventType } =
        useIconEventContext();
    const getReward = async () => {
        try {
            const resICX: any = await RPSRewardServices.getRewardFor(walletAddress, "ICX");
            const resCROWN: any = await RPSRewardServices.getRewardFor(walletAddress, "CROWN");
            const userAllocation: any = await RPSRewardServices.getUsersAllocations(walletAddress);
            console.log(`userAllocation`, userAllocation);
            setrewardsDis(userAllocation);
            console.log({
                ICX: Math.floor(resICX / Math.pow(10, 18)),
                CROWN: Math.floor(resCROWN / Math.pow(10, 18)),
            });
            setrewards({
                ICX: Math.floor(resICX / Math.pow(10, 18)),
                CROWN: Math.floor(resCROWN / Math.pow(10, 18)),
            });
            categoryCurrency(userAllocation);
        } catch (error) {
            console.error(error);
        }
    };
    const getRewardType = (metric: any) => {
        try {
            console.log(metric);
            if (metric.rank) {
                return `Rank ${metric.rank}`;
            }
            let type = metric.type.split("_");
            console.log(type);
            if (type.includes("hallOfFame")) {
                if (type.includes("game") && type.includes("created")) {
                    return "HOF: Max Game Created";
                } else if (type.includes("game") && type.includes("participated")) {
                    return "HOF: Max Game Participated";
                } else if (type.includes("respect")) {
                    return "HOF: Max Respect";
                } else if (type.includes("max win streak")) {
                    return "HOF: Max Win Streak";
                } else if (type.includes("won")) {
                    return "HOF: Max Game Wins";
                } else {
                    return "HOF";
                }
            }
            return metric.type;
        } catch (error) {
            console.error(error);
        }
    };

    const claimReward = async (moneyType: "ICX" | "CROWN") => {
        try {
            setIsTransactionLoading(true);
            setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.CLAIMING_REWARD_PENDING);
            const resICX: any = await RPSRewardServices.claimReward(walletAddress, moneyType);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        getReward();

        return () => {};
    }, [, currentEventType]);

    const categoryCurrency = async (userAllocation) => {
        try {
            let rewardCategory: any = {
                CROWN: [],
                ICX: [],
            };
            for (let rewardObject of userAllocation) {
                if (rewardObject.currency == ZERO_ADDRESS) {
                    rewardCategory.ICX.push(rewardObject);
                } else {
                    rewardCategory.CROWN.push(rewardObject);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="rps_reward_distribution">
            {/* <div className='leader_board_season general_stats'>Hall of fame of Round 1</div>
      <div className='round_table'>
        <table cellPadding={"0"} cellSpacing={"0"} border={0}>
          <thead>
            <tr>
              <th>Player</th>
              <th>Ranked</th>
              <th>Title</th>
              <th>Rewarded</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='clickable inlink'>DaVinci</td>
              <td>1</td>
              <td>Dominator</td>
              <td>5,000 CROWN</td>
            </tr>{" "}
          </tbody>
        </table>
      </div> */}
            <div className="leader_board_season general_stats">
                <div>
                    <GiLockedChest className="icon" />
                    Allocated Rewards
                </div>
            </div>
            <div className="round_table">
                <table cellPadding={"0"} cellSpacing={"0"} border={0}>
                    <thead>
                        <tr>
                            <th>Round</th>
                            <th>Metric</th>
                            <th>Allocated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rewardsDis.map(({ currency, amount, roundNumber, metric }, index) => {
                            return (
                                <tr>
                                    <td>Round {roundNumber / 1}</td>
                                    <td>{getRewardType(JSON.parse(metric))}</td>
                                    <td>
                                        {(amount / Math.pow(10, 18)).toFixed(1)}{" "}
                                        {currency == ZERO_ADDRESS ? "ICX" : "CROWN"}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="leader_board_season general_stats">
                <div>
                    <GiLockedChest className="icon" />
                    CLaimable Rewards
                </div>
            </div>
            <div className="round_table">
                <table cellPadding={"0"} cellSpacing={"0"} border={0}>
                    <thead>
                        <tr>
                            <th>Token</th>
                            <th>Claimable</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(rewards).map((data, index) => {
                            return (
                                <tr>
                                    <td> {data}</td>
                                    <td>
                                        {parseFloat(rewards[data].toFixed(2)).toLocaleString()}{" "}
                                        {data}
                                    </td>
                                    <td className={rewards[data] > 0 ? "unclaimed" : "claimed"}>
                                        {rewards[data] > 0 ? "unclaimed" : "claimed"}
                                    </td>
                                    <td>
                                        <button
                                            className="primary"
                                            disabled={rewards[data] > 0 ? false : true}
                                            onClick={() => {
                                                claimReward(data as any);
                                            }}
                                        >
                                            <GiOpenTreasureChest className="icon" />
                                            Claim
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default RewardsComponents;
