import React from "react";

function Loading({ message }: { message: string }) {
  return (
    <div className='loading'>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div className='lds-circle'>
          <div></div>
        </div>
        <div className='message_loading'>{message}</div>
      </div>
    </div>
  );
}

export default Loading;
