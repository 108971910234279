import React, { useEffect, useState } from "react";
import { IoWalletOutline } from "react-icons/io5";
import { FaHandshake } from "react-icons/fa";
import { useRPSContext } from "../../../../services/contexts/rps.context";
import { useWalletContext } from "../../../../services/contexts/wallet.context";
import { MINI_GAMES } from "../../../../assets/assets";
import { getLatestGames, getUser } from "../../../../services/data/rpsAPIs.services";
import Loading from "../../../../components/common/Loading.component";
import { FaSearch } from "react-icons/fa";
import { BsFire } from "react-icons/bs";
import { MdPersonAddAlt1 } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { ImProfile } from "react-icons/im";
import { IoPersonCircle } from "react-icons/io5";

import {
  E_RPS_OPEN_GAME_TYPE,
  E_RPS_URL_QUERY_ID,
  ZERO_ADDRESS,
} from "../../../../enums/RPS.enums";
import RPSProfile from "./RPS.profile";
import rpsService from "../../../../services/contract/rps.service";
import { useIconEventContext } from "../../../../services/contexts/iconEvent.context";
import { findChanges, getGameIdsObj, removeGameIds } from "../../../../utils/helper";
import RPSGameCard from "../common/RPSGameCard.component";
import { E_ICONEX_RPC_EVENT_TYPE } from "../../../../hooks/useIconexHook";

function FindRPSGangster({ getUserDetails }) {
  const { userName, userStat, nftId, setFriendList, friendList, lastPlayed } = useRPSContext();
  const { walletAddress } = useWalletContext();
  const [searchPlayerDetails, setsearchPlayerDetails] = useState<any>(false);
  const [listOfLatestGames, setListOfLatestGames] = useState<Array<any>>([]);
  const { isTransactionLoading, currentEventType } = useIconEventContext();
  const [isSeaching, setIsSearching] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchWalletAddress, setsearchWalletAddress] = useState("");
  const searchUserDetails = async (address: string) => {
    console.log(address);
    if (address.length <= 0) {
      setsearchPlayerDetails(false);
      setSearchParams((param) => {
        param.delete(E_RPS_URL_QUERY_ID.FIND_GANGSTER);
        return param;
      });
    }
    console.log("getting user details rps");
    setIsSearching(true);
    const res = await getUser(address);
    const response = await getLatestGames(address);
    if (response) {
      setListOfLatestGames(response.logs);
    }
    console.log(res);
    if (res?.data == false || !res) {
      console.log("no data");
      setSearchParams((param) => {
        param.delete(E_RPS_URL_QUERY_ID.FIND_GANGSTER);
        return param;
      });
    } else {
      setsearchPlayerDetails(res.data);
      setSearchParams((param) => {
        param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, address);
        return param;
      });
    }
    setIsSearching(false);
  };
  const addFriend = async (walletAddress: string, address: string) => {
    const response = await rpsService.addToFriendList(walletAddress, address);
    console.log(response);
  };

  useEffect(() => {
    getUserDetails();
    console.log(searchParams.get(E_RPS_URL_QUERY_ID.FIND_GANGSTER));
    if (searchParams.get(E_RPS_URL_QUERY_ID.FIND_GANGSTER)) {
      searchUserDetails(searchParams.get(E_RPS_URL_QUERY_ID.FIND_GANGSTER) as string);
    }
    return () => {};
  }, [searchParams]);
  useEffect(() => {
    if (currentEventType == E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_SUCCESS) {
      setTimeout(() => {
        if (searchParams.get(E_RPS_URL_QUERY_ID.FIND_GANGSTER)) {
          searchUserDetails(searchParams.get(E_RPS_URL_QUERY_ID.FIND_GANGSTER) as string);
        }
      }, 3000);
    }
    return () => {};
  }, [currentEventType]);
  return (
    <div className='rps_find_gangster' style={{ position: "relative" }}>
      {isSeaching && <Loading message='Loading ... ' />}
      <div className='search_rps_gangster'>
        <div>
          <h4>Find a gangster</h4>
          <p>This is where you background check people</p>
          <input
            placeholder='Enter wallet address'
            type='text'
            onChange={(e) => {
              setsearchWalletAddress(e.target.value);
            }}
          />
          <button
            className='search_button'
            onClick={() => {
              searchUserDetails(searchWalletAddress);
            }}>
            <FaSearch />
          </button>
        </div>
      </div>
      {searchPlayerDetails ? (
        <>
          <RPSProfile
            nftId={searchPlayerDetails.nft_id}
            userName={searchPlayerDetails.name}
            listOfLatestGames={listOfLatestGames}
            address={searchPlayerDetails.icon_address}
            userStat={{ ...searchPlayerDetails.stats, ...searchPlayerDetails.leaderboard }}
            friendList={friendList}
            addFriend={addFriend}
            lastPlayed={searchPlayerDetails.updatedAt}
          />
        </>
      ) : (
        <div className='history_empty'>
          <IoPersonCircle className='icon_skull' />
          <h3> Find a gangster</h3>
          <p> Search your friends and foes</p>
        </div>
      )}

      {/* userName ? (
        <RPSProfile
          addFriend={addFriend}
          nftId={nftId}
          userName={userName}
          friendList={friendList}
          address={walletAddress}
          userStat={userStat}
          lastPlayed={lastPlayed}
        />
      ) : (
        <div className='history_empty'>
          <IoPersonCircle className='icon_skull' />
          <h3> Start playing to see your profile stats.</h3>
          <p> Looks like you haven't played any.</p>
        </div>
      ) */}
    </div>
  );
}

export default FindRPSGangster;
