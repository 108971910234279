import React, { useEffect, useState } from "react";
import Modal from "./Modal.component";
import PropertyCarousel from "../propertyCarousel/propertyCarousel.component";
import PlainSeperator from "../decorators/plainSeperator";
import "react-tooltip/dist/react-tooltip.css";
import GangstaBetTooltip from "../tooltip/GangstaBet.tooltip";
import { getHighestGangstaBet } from "../../services/data/data.service";
import { MINI_GAMES } from "../../assets/assets";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../common/Button.component";

function MiniGameModal({
    isOpen,
    setOpen,
    details,
    setSelectedLands,
    setOpen3DModel,
    setOpenRPS,
}: any) {
    const navigate = useNavigate();
    const [close, setclose] = useState(true);
    return (
        <Modal
            width="380px"
            title={`Mini Games`}
            open={isOpen}
            className={"mini_game_modal"}
            setOpen={(e: any) => {
                setclose(!close);
            }}
        >
            {close && (
                <div className="land-details-wrapper">
                    <div className="property_project">
                        <div className="property_project_list">
                            <div
                                className="property_item"
                                onClick={() => {
                                    // setSearchParams((param) => {
                                    //   param.set("project", "rps");
                                    //   return param;
                                    // });
                                }}
                            >
                                <img src={MINI_GAMES.RPS.TWO_PLAYERS} />
                                <div className="property_info">
                                    <div className="property_title">Rock, Paper, Scissor(RPS)</div>
                                    <div className="property_desc">
                                        Fight, Duel and Earn with your own hands
                                    </div>
                                </div>
                                <Button
                                    onClick={() => {
                                        console.log(details);
                                        setOpenRPS(true);
                                        navigate("/game/rps");
                                    }}
                                    isDisabled={false}
                                    name={"Play"}
                                    size={"medium"}
                                />
                            </div>
                        </div>{" "}
                        <div className="property_project_list">
                            <div
                                className="property_item"
                                onClick={() => {
                                    // setSearchParams((param) => {
                                    //   param.set("project", "rps");
                                    //   return param;
                                    // });
                                }}
                            >
                                <img src={MINI_GAMES.gw_logo} />
                                <div className="property_info">
                                    <div className="property_title">GangWars: Mob Run</div>
                                    <div className="property_desc">
                                        Quick and easy way to play GangWars, with no effort
                                        whatsoever!
                                    </div>
                                </div>
                                <Button
                                    onClick={() => {
                                        console.log(details);
                                        window.open(`https://war-base.gangstaverse.co/`, "_blank");
                                    }}
                                    isDisabled={false}
                                    name={"Play"}
                                    size={"medium"}
                                />
                            </div>
                        </div>{" "}
                        <div className="property_project_list">
                            <div
                                className="property_item"
                                onClick={() => {
                                    // setSearchParams((param) => {
                                    //   param.set("project", "rps");
                                    //   return param;
                                    // });
                                }}
                            >
                                <img src={MINI_GAMES.beatdown_logo} />
                                <div className="property_info">
                                    <div className="property_title">Beatdown Inc.</div>
                                    <div className="property_desc">
                                        Choose your character and build your champion and play
                                        against everyone.
                                    </div>
                                </div>
                                <Button
                                    onClick={() => {}}
                                    isDisabled={true}
                                    name={"Play"}
                                    size={"medium"}
                                />
                            </div>
                        </div>{" "}
                        <div className="property_project_list">
                            <div
                                className="property_item"
                                onClick={() => {
                                    // setSearchParams((param) => {
                                    //   param.set("project", "rps");
                                    //   return param;
                                    // });
                                }}
                            >
                                <img src={MINI_GAMES.SKI_MASK} />
                                <div className="property_info">
                                    <div className="property_title">Join a heist</div>
                                    <div className="property_desc">
                                        Form a team and join a heist to loot some $CROWN.
                                    </div>
                                </div>
                                <Button
                                    onClick={() => {}}
                                    isDisabled={true}
                                    name={"Join"}
                                    size={"medium"}
                                />
                            </div>
                        </div>{" "}
                        <div className="property_project_list">
                            <div
                                className="property_item"
                                onClick={() => {
                                    // setSearchParams((param) => {
                                    //   param.set("project", "rps");
                                    //   return param;
                                    // });
                                }}
                            >
                                <img src={MINI_GAMES.BLOOD_SPLATTER} />
                                <div className="property_info">
                                    <div className="property_title">Shoot a gangster</div>
                                    <div className="property_desc">
                                        Feeling like causing mayhem? Shooting and looting them
                                    </div>
                                </div>
                                <Button
                                    onClick={() => {}}
                                    isDisabled={true}
                                    name={"Search"}
                                    size={"medium"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
}

export default MiniGameModal;
