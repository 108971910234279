"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const ConfirmedTransaction_1 = require("./ConfirmedTransaction");
const Hexadecimal_1 = require("../Hexadecimal");
const Converter_1 = require("../Converter");
const Util_1 = require("../Util");
const Exception_1 = require("../../Exception");
const Validator_1 = require("../Validator");
/**
 * @description Convert confirmed transaction list in block data into the right format.
 */
function toConfirmedTransaction(data) {
    if (!(0, Util_1.hasProperties)(data, [["txHash", "tx_hash"]]) ||
        !(0, Validator_1.checkDataInTransaction)(data)) {
        const error = new Exception_1.FormatError("Confirmed transaction object is invalid.");
        throw error.toString();
    }
    return new ConfirmedTransaction_1.default(data);
}
class Block {
    constructor(data) {
        this.height = (0, Converter_1.toNumber)(data.height);
        this.blockHash = (0, Hexadecimal_1.add0xPrefix)(data.block_hash);
        this.merkleTreeRootHash = (0, Hexadecimal_1.add0xPrefix)(data.merkle_tree_root_hash);
        this.prevBlockHash = (0, Hexadecimal_1.add0xPrefix)(data.prev_block_hash);
        this.peerId = (0, Hexadecimal_1.addHxPrefix)(data.peer_id);
        this.confirmedTransactionList = (data.confirmed_transaction_list || []).map((transaction) => (0, Util_1.isGenesisBlock)(data.height)
            ? transaction
            : toConfirmedTransaction(transaction));
        this.signature = data.signature;
        this.timeStamp = (0, Converter_1.toNumber)(data.time_stamp);
        this.version = data.version;
    }
    getTransactions() {
        return this.confirmedTransactionList;
    }
}
exports.default = Block;
