"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const Request_1 = require("./transport/jsonrpc/Request");
const Wallet_1 = require("./Wallet");
const builder_1 = require("./builder");
const SignedTransaction_1 = require("./SignedTransaction");
const Validator = require("./data/Validator");
const Formatter = require("./data/Formatter");
const Exception_1 = require("./Exception");
const Util = require("./data/Util");
const Amount_1 = require("./data/Amount");
const Converter = require("./data/Converter");
const HttpProvider_1 = require("./transport/http/HttpProvider");
const Hexadecimal = require("./data/Hexadecimal");
const BTPNetworkInfo_1 = require("./data/Formatter/BTPNetworkInfo");
const BTPNetworkTypeInfo_1 = require("./data/Formatter/BTPNetworkTypeInfo");
const BTPSourceInformation_1 = require("./data/Formatter/BTPSourceInformation");
const ScoreStatus_1 = require("./data/Formatter/ScoreStatus");
const NetworkInfo_1 = require("./data/Formatter/NetworkInfo");
/**
 * Class which provides APIs of ICON network.
 */
class IconService {
    /**
     * Creates an instance of IconService.
     * @param {HttpProvider} provider - The HttpProvider instance.
     */
    constructor(provider) {
        this.provider = provider;
    }
    /**
     * Get the total number of issued coins.
     * @param {Hash} [height] - block Height.
     * @return {HttpCall} The HttpCall instance for icx_getTotalSupply JSON-RPC API request.
     */
    getTotalSupply(height) {
        let params;
        if (height === undefined) {
            params = null;
        }
        else {
            params = { height };
        }
        const requestId = Util.getCurrentTime();
        const request = new Request_1.default(requestId, "icx_getTotalSupply", params);
        return this.provider.request(request, Converter.toBigNumber);
    }
    /**
     * Get the balance of the address.
     * @param {string} address - The EOA or SCORE address.
     * @param {Hash} [height] - block Height.
     * @return {HttpCall} The HttpCall instance for icx_getBalance JSON-RPC API request.
     */
    getBalance(address, height) {
        let params;
        if (height === undefined) {
            params = { address };
        }
        else {
            params = { address, height: Converter.toHex(height) };
        }
        if (!Validator.isAddress(address)) {
            const error = new Exception_1.DataError(`[${address}] is not valid address.`);
            throw error.toString();
        }
        else {
            const requestId = Util.getCurrentTime();
            const request = new Request_1.default(requestId, "icx_getBalance", params);
            return this.provider.request(request, Converter.toBigNumber);
        }
    }
    /**
     * @description Get the block information.
     * @param {BigNumber} value The value of block number
     * @return {object} The HttpCall instance for icx_getBlockByHeight JSON-RPC API request.
     */
    getBlockByHeight(value) {
        if (Validator.isNonNegative(value)) {
            const requestId = Util.getCurrentTime();
            const params = { height: Converter.toHex(value) };
            const request = new Request_1.default(requestId, "icx_getBlockByHeight", params);
            return this.provider.request(request, Formatter.toBlock);
        }
        const error = new Exception_1.DataError(`[${value}] is an unrecognized block height.`);
        throw error.toString();
    }
    /**
     * @description Get the block information.
     * @param {string} value The value of block hash
     * @return {object} The HttpCall instance for icx_getBlockByHash JSON-RPC API request.
     */
    getBlockByHash(value) {
        if (Validator.isValidHash(value)) {
            const requestId = Util.getCurrentTime();
            const params = { hash: value };
            const request = new Request_1.default(requestId, "icx_getBlockByHash", params);
            return this.provider.request(request, Formatter.toBlock);
        }
        const error = new Exception_1.DataError(`[${value}] is an unrecognized block hash.`);
        throw error.toString();
    }
    /**
     * @description Get the last block information.
     * @return {object} The HttpCall instance for icx_getLastBlock JSON-RPC API request.
     */
    getLastBlock() {
        const requestId = Util.getCurrentTime();
        const request = new Request_1.default(requestId, "icx_getLastBlock", null);
        return this.provider.request(request, Formatter.toBlock);
    }
    /**
     * @description Get the SCORE API list.
     * @param {string} address SCORE address
     * @param {Hash} [height] block Height
     * @return {array} The HttpCall instance for icx_getScoreApi JSON-RPC API request.
     */
    getScoreApi(address, height) {
        let params;
        if (height === undefined) {
            params = { address };
        }
        else {
            params = { address, height: Converter.toHex(height) };
        }
        if (!Validator.isScoreAddress(address)) {
            const error = new Exception_1.DataError(`[${address}] is not a valid SCORE address.`);
            throw error.toString();
        }
        else {
            const requestId = Util.getCurrentTime();
            const request = new Request_1.default(requestId, "icx_getScoreApi", params);
            return this.provider.request(request, Formatter.toScoreApiList);
        }
    }
    /**
     * @description Get the SCORE status
     * @param {string} address SCORE address
     * @param {Hash} [height] block Height
     * @return {object} The HttpCall instance for icx_getScoreStatus JSON-RPC API request.
     */
    getScoreStatus(address, height) {
        let params;
        if (height === undefined) {
            params = { address };
        }
        else {
            params = { address, height: Converter.toHex(height) };
        }
        if (!Validator.isScoreAddress(address)) {
            const error = new Exception_1.DataError(`[${address}] is not a valid SCORE address.`);
            throw error.toString();
        }
        else {
            const requestId = Util.getCurrentTime();
            const request = new Request_1.default(requestId, "icx_getScoreStatus", params);
            return this.provider.request(request, (data) => new ScoreStatus_1.default(data));
        }
    }
    /**
     * Get the transaction information.
     * @param {string} hash - The transaction hash.
     * @return {HttpCall} The HttpCall instance for icx_getTransactionByHash JSON-RPC API request.
     */
    getTransaction(hash) {
        if (!Validator.isValidHash(hash)) {
            const error = new Exception_1.DataError(`[${hash}] is an unrecognized hash value.`);
            throw error.toString();
        }
        else {
            const requestId = Util.getCurrentTime();
            const params = { txHash: hash };
            const request = new Request_1.default(requestId, "icx_getTransactionByHash", params);
            return this.provider.request(request, Formatter.toTransaction);
        }
    }
    /**
     * Get the result of transaction by transaction hash.
     * @param {string} hash - The transaction hash.
     * @return {HttpCall} The HttpCall instance for icx_getTransactionResult JSON-RPC API request.
     */
    getTransactionResult(hash) {
        if (!Validator.isValidHash(hash)) {
            const error = new Exception_1.DataError(`[${hash}] is an unrecognized hash value.`);
            throw error.toString();
        }
        else {
            const requestId = Util.getCurrentTime();
            const params = { txHash: hash };
            const request = new Request_1.default(requestId, "icx_getTransactionResult", params);
            return this.provider.request(request, Formatter.toTransactionResult);
        }
    }
    /**
     * Send a transaction that changes the states of address.
     * @param {SignedTransaction} signedTransaction - Parameters including signature.
     * @return {HttpCall} The HttpCall instance for icx_sendTransaction JSON-RPC API request.
     */
    sendTransaction(signedTransaction) {
        if (!Validator.isSignedTransaction(signedTransaction)) {
            const error = new Exception_1.DataError("Transaction object is invalid.");
            throw error.toString();
        }
        else {
            const requestId = Util.getCurrentTime();
            const params = signedTransaction.getProperties();
            const request = new Request_1.default(requestId, "icx_sendTransaction", params);
            return this.provider.request(request);
        }
    }
    /**
     * Returns an estimated step of how much step is necessary to allow the transaction to complete.
     * @param {SignedTransaction} transaction - Parameters
     * @return {HttpCall} The HttpCall instance for debug_estimateStep JSON-RPC API request.
     */
    estimateStep(transaction) {
        const rawTx = Converter.toRawTransaction(transaction);
        if (!Validator.checkTxData(rawTx)) {
            const error = new Exception_1.DataError("Transaction object is invalid.");
            throw error.toString();
        }
        else {
            const requestId = Util.getCurrentTime();
            const request = new Request_1.default(requestId, "debug_estimateStep", rawTx);
            return this.provider.request(request, Converter.toBigNumber);
        }
    }
    /**
     * Calls a SCORE API just for reading.
     * @param {Call} call - The call instance exported by CallBuilder
     * @return {HttpCall} The HttpCall instance for icx_call JSON-RPC API request.
     */
    // eslint-disable-next-line
    call(call) {
        if (!Validator.isCall(call)) {
            const error = new Exception_1.DataError("Call object is invalid.");
            throw error.toString();
        }
        else {
            const requestId = Util.getCurrentTime();
            const request = new Request_1.default(requestId, "icx_call", call);
            return this.provider.request(request);
        }
    }
    /**
     * sends a transaction like `icx_sendTransaction`, then it will wait for the
     result of it for specified time.  If the timeout isn't set by user, it uses
     `defaultWaitTimeout` of icon node.
     * @param {SignedTransaction} signedTransaction - Parameters including signature.
     * @return {HttpCall} The HttpCall instance for icx_sendTransactionAndWait JSON-RPC API request.
     */
    sendTransactionAndWait(signedTransaction) {
        if (!Validator.isSignedTransaction(signedTransaction)) {
            const error = new Exception_1.DataError("Transaction object is invalid.");
            throw error.toString();
        }
        const requestId = Util.getCurrentTime();
        const params = signedTransaction.getProperties();
        const request = new Request_1.default(requestId, "icx_sendTransactionAndWait", params);
        return this.provider.request(request);
    }
    /**
     * It will wait for the result of the transaction for specified time.
     * If the timeout isn't set by user, it uses `defaultWaitTimeout` of icon node.
     * @param {string} hash - The transaction hash.
     * @return {HttpCall} The HttpCall instance for icx_waitTransactionResult JSON-RPC API request.
     */
    waitTransactionResult(hash) {
        if (!Validator.isValidHash(hash)) {
            const error = new Exception_1.DataError(`[${hash}] is an unrecognized hash value.`);
            throw error.toString();
        }
        const requestId = Util.getCurrentTime();
        const params = { txHash: hash };
        const request = new Request_1.default(requestId, "icx_waitTransactionResult", params);
        return this.provider.request(request, Formatter.toTransactionResult);
    }
    /**
     * Get data by hash.
     It can be used to retrieve data based on the hash algorithm (SHA3-256).
     Following data can be retrieved by a hash.
  
     * BlockHeader with the hash of the block
     * Validators with BlockHeader.NextValidatorsHash
     * Votes with BlockHeader.VotesHash
     * etc…
     * @param {string} hash - The hash value of the data to retrieve
     * @return {HttpCall} The HttpCall instance for icx_getDataByHash JSON-RPC API request.
     */
    getDataByHash(hash) {
        if (!Validator.isValidHash(hash)) {
            const error = new Exception_1.DataError(`[${hash}] is an unrecognized hash value.`);
            throw error.toString();
        }
        const requestId = Util.getCurrentTime();
        const params = { hash };
        const request = new Request_1.default(requestId, "icx_getDataByHash", params);
        return this.provider.request(request);
    }
    /**
     * Get block header for specified height.
     * @param {BigNumber | string} height - The height of the block.
     * @return {HttpCall} The HttpCall instance for icx_getBlockHeaderByHeight JSON-RPC API request.
     */
    getBlockHeaderByHeight(height) {
        if (!Validator.isNonNegative(height)) {
            const error = new Exception_1.DataError(`[${height}] is an unrecognized block height`);
            throw error.toString();
        }
        const requestId = Util.getCurrentTime();
        const params = { height: Converter.toHex(height) };
        const request = new Request_1.default(requestId, "icx_getBlockHeaderByHeight", params);
        return this.provider.request(request);
    }
    /**
     * Get votes for the block specified by height.
     * @param {BigNumber} height - The height of the block.
     * @return {HttpCall} The HttpCall instance for icx_getVotesByHeight JSON-RPC API request.
     */
    getVotesByHeight(height) {
        if (!Validator.isNonNegative(height)) {
            const error = new Exception_1.DataError(`[${height}] is an unrecognized block height`);
            throw error.toString();
        }
        const requestId = Util.getCurrentTime();
        const params = { height: Converter.toHex(height) };
        const request = new Request_1.default(requestId, "icx_getVotesByHeight", params);
        return this.provider.request(request);
    }
    /**
     * Get proof for the receipt
     * @param {string} hash - The hash value of the block including the result
     * @param {string} index - Index of the receipt in the block
     * @return {HttpCall} The HttpCall instance for icx_getProofForResult JSON-RPC API request.
     */
    getProofForResult(hash, index) {
        if (!Validator.isValidHash(hash)) {
            const error = new Exception_1.DataError(`[${hash}] is an unrecognized hash value.`);
            throw error.toString();
        }
        if (!Validator.isNonNegative(index)) {
            const error = new Exception_1.DataError(`index must be non-negative number`);
            throw error.toString();
        }
        const requestId = Util.getCurrentTime();
        const params = { hash, index: Converter.toHex(index) };
        const request = new Request_1.default(requestId, "icx_getProofForResult", params);
        return this.provider.request(request);
    }
    /**
     * Get proof for the receipt and the events in it
     * @param {string} hash - The hash value of the block including the result
     * @param index - Index of the receipt in the block
     * @param events - List of indexes of the events in the receipt
     * @return {HttpCall} The HttpCall instance for icx_getProofForEvents JSON-RPC API request.
     */
    getProofForEvents(hash, index, events) {
        if (!Validator.isValidHash(hash)) {
            const error = new Exception_1.DataError(`[${hash}] is an unrecognized hash value.`);
            throw error.toString();
        }
        if (!Validator.isNonNegative(index)) {
            const error = new Exception_1.DataError(`index must be none-negative number`);
            throw error.toString();
        }
        if (!events.every(Validator.isNonNegative)) {
            const error = new Exception_1.DataError(`All event index must be none-negative number`);
            throw error.toString();
        }
        const requestId = Util.getCurrentTime();
        const params = {
            hash,
            index: Converter.toHex(index),
            events: events.map(Converter.toHex),
        };
        const request = new Request_1.default(requestId, "icx_getProofForEvents", params);
        return this.provider.request(request);
    }
    /**
     * Get BTP network information.
     * @param id - network id
     * @param height - Main block height
     * @return {HttpCall} The HttpCall instance for btp_getNetworkInfo JSON-RPC API request.
     */
    getBTPNetworkInfo(id, height) {
        let params;
        if (height === undefined)
            params = { id };
        else
            params = { id, height };
        const requestId = Util.getCurrentTime();
        const request = new Request_1.default(requestId, "btp_getNetworkInfo", params);
        return this.provider.request(request, (data) => new BTPNetworkInfo_1.default(data));
    }
    /**
     * Get BTP network type information.
     * @param id - Network type id
     * @param height - Main block height
     * @return {HttpCall} The HttpCall instance for btp_getNetworkTypeInfo JSON-RPC API request.
     */
    getBTPNetworkTypeInfo(id, height) {
        let params;
        if (height === undefined)
            params = { id };
        else
            params = { id, height };
        const requestId = Util.getCurrentTime();
        const request = new Request_1.default(requestId, "btp_getNetworkTypeInfo", params);
        return this.provider.request(request, (data) => new BTPNetworkTypeInfo_1.default(data));
    }
    /**
     * Get BTP messages
     * @param networkID - BTP network ID
     * @param height - Main block height
     * @return {HttpCall} The HttpCall instance for btp_getMessages JSON-RPC API request.
     */
    getBTPMessages(networkID, height) {
        const params = { networkID, height };
        const requestId = Util.getCurrentTime();
        const request = new Request_1.default(requestId, "btp_getMessages", params);
        return this.provider.request(request);
    }
    /**
     * Get BTP block header
     * @param networkID - Network id
     * @param height - Main block height
     * @return {HttpCall} The HttpCall instance for btp_getHeader JSON-RPC API request.
     */
    getBTPHeader(networkID, height) {
        const params = { networkID, height };
        const requestId = Util.getCurrentTime();
        const request = new Request_1.default(requestId, "btp_getHeader", params);
        return this.provider.request(request);
    }
    /**
     * Get BTP block proof
     * @param networkID - Network id
     * @param height - Main block height
     * @return {HttpCall} The HttpCall instance for btp_getProof JSON-RPC API request.
     */
    getBTPProof(networkID, height) {
        const params = { networkID, height };
        const requestId = Util.getCurrentTime();
        const request = new Request_1.default(requestId, "btp_getProof", params);
        return this.provider.request(request);
    }
    /**
     * Get source network information
     * @return {HttpCall} The HttpCall instance for btp_getSourceInformation JSON-RPC API request.
     */
    getBTPSourceInformation() {
        const requestId = Util.getCurrentTime();
        const request = new Request_1.default(requestId, "btp_getSourceInformation", null);
        return this.provider.request(request, (data) => new BTPSourceInformation_1.default(data));
    }
    /***
     * Get basic network Information.
     */
    getNetworkInfo() {
        const requestId = Util.getCurrentTime();
        const request = new Request_1.default(requestId, "icx_getNetworkInfo", null);
        return this.provider.request(request, (data) => new NetworkInfo_1.default(data));
    }
    /**
     * Get the transaction trace.
     * @param {string} hash - The transaction hash.
     * @return {HttpCall} The HttpCall instance for debug_getTrace JSON-RPC API request.
     */
    getTrace(hash) {
        if (!Validator.isValidHash(hash)) {
            const error = new Exception_1.DataError(`[${hash}] is an unrecognized hash value.`);
            throw error.toString();
        }
        else {
            const requestId = Util.getCurrentTime();
            const params = { txHash: hash };
            const request = new Request_1.default(requestId, "debug_getTrace", params);
            return this.provider.request(request);
        }
    }
    /***
     * Get monitor for block.
     * @param monitorSpec
     * @param ondata - Callback to be called when Monitor successfully got messages
     * @param onerror - Callback to be called when a network error or os error occurs
     * @return {Monitor<BlockNotification>} The Monitor instance for `block` websocket API
     */
    monitorBlock(monitorSpec, ondata, onerror) {
        return this.provider.monitor(monitorSpec, ondata, onerror);
    }
    /***
     * Get monitor for event
     * @param monitorSpec
     * @param ondata - Callback to be called when Monitor successfully got messages
     * @param onerror - Callback to be called when a network error or os error occurs
     * @param onprogress - Callback to be called when the block is processed
     * @return {Monitor<EventNotification>} The Monitor instance for `event` websocket API
     */
    monitorEvent(monitorSpec, ondata, onerror, onprogress) {
        return this.provider.monitor(monitorSpec, ondata, onerror, onprogress);
    }
    /***
     * Get monitor for btp
     * @param monitorSpec
     * @param ondata - Callback to be called when Monitor successfully got messages
     * @param onerror - Callback to be called when a network error or os error occurs
     * @param onprogress - Callback to be called when the block is processed
     * @return {Monitor<BTPNotification>} The Monitor instance for `btp` websocket API
     */
    monitorBTP(monitorSpec, ondata, onerror, onprogress) {
        return this.provider.monitor(monitorSpec, ondata, onerror, onprogress);
    }
}
exports.default = IconService;
IconService.IconAmount = Amount_1.default;
IconService.IconBuilder = builder_1.default;
IconService.IconConverter = Converter;
IconService.IconWallet = Wallet_1.default;
IconService.IconUtil = Util;
IconService.SignedTransaction = SignedTransaction_1.default;
IconService.HttpProvider = HttpProvider_1.default;
IconService.IconHexadecimal = Hexadecimal;
IconService.IconValidator = Validator;
