import React from "react";
import { ICONS } from "../../assets/assets";

function HeaderLogo({ isOpen, setisOpen }: any) {
  return (
    <div className='header_logo'>
      <div className='header_flex_box'>
        <div className='logos' style={{ opacity: isOpen ? "1" : 0.5 }}>
          <img src='https://gangstaverse.co/static/media/gangstaverse.a7bfd4b4ad859d93daa4e0e42c93dca3.svg' />
          <div> | The Emerald City</div>
        </div>

        <div
          className='mobile_header'
          onClick={() => {
            setisOpen(!isOpen);
          }}>
          {isOpen ? <img src={ICONS.close} /> : <img src={ICONS.menu_3_fill} />}
        </div>
      </div>
    </div>
  );
}

export default HeaderLogo;
