import React from "react";
import { SOCIALS } from "../../../assets/assets";

function SocialComponent() {
  return (
    <div className='social_connection'>
      <img
        onClick={() => {
          window.open(`https://gangstaverse.co/`, "_blank");
        }}
        src={SOCIALS.gb_logo}
        className='gb_logo socials'
      />
      <img
        onClick={() => {
          window.open(`https://discord.com/invite/9jkyefW9kU`, "_blank");
        }}
        src={SOCIALS.discord}
        className='discord socials'
      />
      <img
        onClick={() => {
          window.open(`https://twitter.com/GangstaVerse`, "_blank");
        }}
        src={SOCIALS.twitter}
        className='twitter socials'
      />
      <img
        onClick={() => {
          window.open(`https://gangstaverse.medium.com/`, "_blank");
        }}
        src={SOCIALS.medium}
        className='medium socials'
      />
    </div>
  );
}

export default SocialComponent;
