"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BlockNotification_1 = require("../../data/Formatter/BlockNotification");
const data_1 = require("../../data");
class BlockMonitorSpec {
    constructor(height, eventFilters, logs) {
        this.height = height;
        this.eventFilters = eventFilters;
        this.logs = logs;
    }
    getPath() {
        return "block";
    }
    getParam() {
        const height = data_1.Converter.toHex(this.height);
        const params = { height };
        if (this.logs)
            params["logs"] = "0x1";
        if (!this.eventFilters || this.eventFilters.length === 0) {
            return params;
        }
        return Object.assign(Object.assign({}, params), { eventFilters: this.eventFilters.map((v) => v.toObject()) });
    }
    getConverter() {
        return (data) => new BlockNotification_1.default(data);
    }
}
exports.default = BlockMonitorSpec;
