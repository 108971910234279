import React from "react";
interface IButton {
  onClick: any;
  isDisabled: boolean;
  name: string;
  size: "large" | "medium";
  className?: string;
}
function Button({ onClick, isDisabled, name, size, className }: IButton) {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={`emerald_button ${size} ${className}`}>
      {name}
    </button>
  );
}

export default Button;
