import React from "react";
import axios from "axios";
import L from "leaflet";
import { useEffect, useState } from "react";
import { MINTED_LANDS } from "../../../constants/mintedLands";
import BoxMarker from "../markers/Box.marker";
import { convertPointsToLatLng } from "../../../utils/cordinates.utils";
import { ZONE_COLOR, ZONE_ID_NAME } from "../../../constants/zones.lands";
import { MARKERS } from "../../../assets/assets";
import { getLandMetaData } from "../../../services/data/data.service";
const zone_color = {
  1: "#FF5733", // Orange
  2: "#33FFB2", // Turquoise
  3: "#FFC133", // Yellow
  4: "#3366FF", // Blue
  5: "#FF3397", // Pink
  6: "#42FF8A", // Green
  7: "#E033FF", // Purple
  8: "#33C4FF", // Blue
  9: "#FF5733", // Orange
};
interface IZonesLayer {
  showZones: Record<number, boolean>;
}
const zoneIdMarker: Record<number, any> = {
  1: new L.Icon({
    iconUrl: MARKERS[ZONE_ID_NAME[1]],
    iconSize: [25, 27],
  }),
  8: new L.Icon({
    iconUrl: MARKERS[ZONE_ID_NAME[8]],
    iconSize: [25, 27],
  }),
  2: new L.Icon({
    iconUrl: MARKERS[ZONE_ID_NAME[2]],
    iconSize: [25, 27],
  }),
  3: new L.Icon({
    iconUrl: MARKERS[ZONE_ID_NAME[3]],
    iconSize: [25, 27],
  }),
  4: new L.Icon({
    iconUrl: MARKERS[ZONE_ID_NAME[4]],
    iconSize: [25, 27],
  }),
  5: new L.Icon({
    iconUrl: MARKERS[ZONE_ID_NAME[5]],
    iconSize: [25, 27],
  }),
  6: new L.Icon({
    iconUrl: MARKERS[ZONE_ID_NAME[6]],
    iconSize: [25, 27],
  }),
  7: new L.Icon({
    iconUrl: MARKERS[ZONE_ID_NAME[7]],
    iconSize: [25, 27],
  }),
};
function ZonesLayer({ showZones, selectedLand, selectThisLand, toggleMarkerZone }: any) {
  // const [openLandDetailModal, setOpenLandDetailsModal] = useState(false);
  // const [selectlandDetails, setSelectedlandDetails] = useState(null);
  // const [selectedLand, setSelectedLands] = useState(null);
  return (
    <div>
      {MINTED_LANDS?.length >= 1
        ? MINTED_LANDS.map(({ x, y, owner, zoneId, name, metaId, nftId }, index) => {
            const xx = parseInt(x);
            const yy = parseInt(y);

            // console.log(data['Land Coord']);
            // console.log(typeof x, typeof y);
            if (showZones[parseInt(zoneId)]) {
              return (
                <BoxMarker
                  className={`minted_lands`}
                  key={index}
                  marker={toggleMarkerZone ? zoneIdMarker[parseInt(zoneId) as number] : null}
                  onClick={async () => {
                    selectThisLand(nftId, x, y);
                  }}
                  pathOptions={{
                    weight: 0.5,
                    fill: true,
                    fillColor:
                      selectedLand == `land_${x}_${y}`
                        ? "red"
                        : ZONE_COLOR[parseInt(zoneId) as number],
                    fillOpacity: 0.65,
                    color: selectedLand == `land_${x}_${y}` ? "red" : "#ceb25a",
                  }}
                  rectangleBBox={[
                    [convertPointsToLatLng(xx, yy, 0).lat, convertPointsToLatLng(xx, yy, 0).lng],
                    [
                      convertPointsToLatLng(xx + 1, yy - 1).lat,
                      convertPointsToLatLng(xx + 1, yy - 1).lng,
                    ],
                  ]}
                />
              );
            }
          })
        : null}
    </div>
  );
}

export default ZonesLayer;
