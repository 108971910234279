import React, { useEffect, useState } from "react";
import {
    fetchCrownPrice,
    getGeneralStatsRPS,
    getLeaderBoardRPS,
    getTotalTxFromMiniGame,
    getHallOfFame,
} from "../../../../services/data/rpsAPIs.services";
import { ICONS, MINI_GAMES } from "../../../../assets/assets";
import rpsService from "../../../../services/contract/rps.service";
import { E_RPS_ACTION_CONTAINER, E_RPS_URL_QUERY_ID } from "../../../../enums/RPS.enums";
import { useSearchParams } from "react-router-dom";
import { GiSwordsPower } from "react-icons/gi";
import { GrTransaction } from "react-icons/gr";
import { BsFillLightningChargeFill } from "react-icons/bs";

import { Tooltip as ReactTooltip } from "react-tooltip";
import { useRPSContext } from "../../../../services/contexts/rps.context";
import CountUp from "react-countup";
import { iconUSDRate } from "../../../../services/data/data.service";
import { useWalletContext } from "../../../../services/contexts/wallet.context";
let countdownTimer: any = null;
const REWARDs = {
    respect: 5555,
    game_won: 3555,
    max_win_streak: 1555,
    game_participated: 1555,
    game_created: 3555,
};
const MIN = {
    respect: 555,
    game_won: 555,
    max_win_streak: 5,
    game_participated: 55,
    game_created: 555,
};
function RPSLeaderBoard() {
    const { setCurrentActionContainerSelected, roundInfo } = useRPSContext();
    const { walletAddress } = useWalletContext();
    const [LeadeBoardList, setLeadeBoardList] = useState([]);
    const [hofList, setHOF] = useState<any>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialGenStat, setInitialGenStat] = useState(
        window.localStorage.getItem("rpsGenStat")
            ? JSON.parse(window.localStorage.getItem("rpsGenStat") as any)
            : {
                  total_player: 0,
                  game_played: 0,
                  current_reward_pool: {
                      ICX: 0,
                      CROWN: 0,
                  },
                  current_platform_fee: 0,
                  total_tx: 0,
              }
    );
    const [generalStats, setGeneralStats] = useState(
        window.localStorage.getItem("rpsGenStat")
            ? JSON.parse(window.localStorage.getItem("rpsGenStat") as any)
            : {
                  total_player: 0,
                  game_played: 0,
                  current_reward_pool: {
                      ICX: 0,
                      CROWN: 0,
                  },
                  current_platform_fee: 0,
                  total_tx: 0,
              }
    );
    const [iconRate, setIconRate] = useState(0);
    const [crownRate, setCrownRate] = useState<any>(0);
    const getICXRate = async () => {
        try {
            console.log(`getICXRate`);
            const res = await iconUSDRate();
            console.log(`icoxn rate`, res.icon.usd);
            setIconRate((p) => res.icon.usd);
            const crown = await fetchCrownPrice();
            console.log(`crown rate`, crown);
            setCrownRate(crown);
        } catch (error) {
            console.error(error);
        }
    };
    const getList = async () => {
        try {
            const res = await getLeaderBoardRPS();
            const reshof = await getHallOfFame();
            if (reshof) {
                setHOF(reshof.data);
            }
            if (res) {
                console.log(res);
                setLeadeBoardList(res.data);
            }
            const txTotal = await getTotalTxFromMiniGame();
            const generalStat = await getGeneralStatsRPS();
            if (generalStat) {
                console.log("general stats");
                console.log(generalStat.data);
                setGeneralStats({
                    ...generalStat.data,
                    total_tx: txTotal < 0 ? 0 : txTotal,
                });
                if (window.localStorage.getItem("rpsGenStat")) {
                    window.localStorage.removeItem("rpsGenStat");
                }
                window.localStorage.setItem(
                    `rpsGenStat`,
                    JSON.stringify({
                        ...generalStat.data,
                        total_tx: txTotal < 0 ? 0 : txTotal,
                    })
                );
            }
        } catch (error) {
            console.error(error);
        }
    };
    const pad = (n: any) => {
        return n < 10 ? "0" + n : n;
    };
    const [endInTimer, setEndInTimer] = useState<any>(null);
    const [isEnded, setIsEnded] = useState<boolean>(false);
    const timer = () => {
        if (roundInfo == false) {
        } else {
            const timeleft = new Date(roundInfo.roundEndTime).getTime() - new Date().getTime();
            const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
            let timerRemaining = {
                days: pad(days),
                hours: pad(hours),
                minute: pad(minutes),
                second: pad(seconds),
            };
            setEndInTimer((p: any) => timerRemaining);
            if (timeleft < 0) {
                clearInterval(countdownTimer);
                setEndInTimer(null);
                setIsEnded(true);
            }
        }
    };
    const [rewardType, setrewardType] = useState(null);

    useEffect(() => {
        getICXRate();
        getList();

        countdownTimer = setInterval(timer, 1000);
        return () => {
            setEndInTimer(null);
            clearInterval(countdownTimer);
        };
    }, []);

    return (
        <div className="rps_leader_board">
            <div className="leader_board_table">
                <div className="leader_board_season general_stats">General Stats</div>
                <div className="gp_list">
                    <div className="rps_general_stats">
                        <img
                            className="rps_general_stats_icon"
                            src={MINI_GAMES.RPS.UNKNOWL_PLAYER}
                        />
                        <div className="rps_g_s_desc">
                            <div className="rps_g_s_t">Total Players</div>
                            <div className="rps_g_s_val">
                                <CountUp
                                    start={initialGenStat.total_player}
                                    end={generalStats.total_player}
                                />
                            </div>
                        </div>
                    </div>{" "}
                    <div className="rps_general_stats">
                        <GiSwordsPower className="rps_general_stats_icon crown" />
                        <div className="rps_g_s_desc">
                            <div className="rps_g_s_t">Total Games Played</div>
                            <div className="rps_g_s_val">
                                <CountUp
                                    start={initialGenStat.game_played}
                                    end={generalStats.game_played}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="rps_general_stats">
                        <GrTransaction className="rps_general_stats_icon crown" />
                        <div className="rps_g_s_desc">
                            <div className="rps_g_s_t"># of Transactions</div>
                            <div className="rps_g_s_val">
                                {" "}
                                <CountUp
                                    start={initialGenStat.total_tx}
                                    end={generalStats.total_tx}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="rps_general_stats">
                        <img className="rps_general_stats_icon crown" src={ICONS.crown_logo} />
                        <div className="rps_g_s_desc">
                            <div className="rps_g_s_t">CROWN Reward Pool</div>
                            <div className="rps_g_s_val">
                                {" "}
                                <CountUp
                                    start={initialGenStat.current_reward_pool.CROWN}
                                    end={generalStats.current_reward_pool.CROWN}
                                />
                                <span className="usd_dollar">
                                    ~${""}
                                    <CountUp
                                        start={0}
                                        end={generalStats.current_reward_pool.CROWN * crownRate}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>{" "}
                    <div className="rps_general_stats">
                        <img className="rps_general_stats_icon crown" src={ICONS.iconex_logo} />
                        <div className="rps_g_s_desc">
                            <div className="rps_g_s_t">ICX Reward Pool</div>
                            <div className="rps_g_s_val">
                                <CountUp
                                    start={initialGenStat.current_reward_pool.ICX}
                                    end={generalStats.current_reward_pool.ICX}
                                />
                                <span className="usd_dollar">
                                    ~${""}
                                    <CountUp
                                        start={0}
                                        end={generalStats.current_reward_pool.ICX * iconRate}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>{" "}
                    <div className="rps_general_stats">
                        <BsFillLightningChargeFill className="rps_general_stats_icon crown" />
                        <div className="rps_g_s_desc">
                            <div className="rps_g_s_t">Platform Fee</div>
                            <div className="rps_g_s_val">
                                {" "}
                                <CountUp
                                    start={initialGenStat.current_platform_fee}
                                    end={generalStats.current_platform_fee}
                                />
                                %
                            </div>
                        </div>
                    </div>{" "}
                </div>
                <div className="leader_board_season general_stats">
                    <h2 className="round_title"> Hall Of Fame</h2>
                    {/* <p>Reward type: </p> */}
                    <p className="round_desc">
                        The Fame-keeper keeps on scouting for the best player who deserves the title
                        until the round ends.
                    </p>
                </div>
                {hofList == false ? null : (
                    <div className="leader_board_hof">
                        {hofList.respect && hofList.respect.stats.respect >= MIN.respect ? (
                            <div className="poster">
                                <h1>The Godfather</h1>
                                <div
                                    className="mugshot"
                                    role="img"
                                    aria-label="mugshot-img"
                                    style={{
                                        backgroundImage:
                                            hofList.respect.nft_id == null
                                                ? `url(${MINI_GAMES.RPS.UNKNOWL_PLAYER})`
                                                : `url(https://d2l8dezwbqkb9.cloudfront.net/png/${hofList.respect.nft_id}.png)`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                                <p
                                    className="name"
                                    onClick={() => {
                                        setCurrentActionContainerSelected(
                                            E_RPS_ACTION_CONTAINER.FIND_GANGSTER
                                        );
                                        setSearchParams((param) => {
                                            param.set(
                                                E_RPS_URL_QUERY_ID.FIND_GANGSTER,
                                                hofList.respect.icon_address
                                            );
                                            return param;
                                        });
                                    }}
                                >
                                    {hofList.respect.name.length >= 20
                                        ? `${hofList.respect.name.slice(
                                              0,
                                              6
                                          )}...${hofList.respect.name.slice(37)}`
                                        : hofList.respect.name}
                                </p>
                                {/* <em>Armed & very dangerous</em> */}
                                <h2>Reward</h2>
                                <u>{REWARDs.respect} $CROWN</u>
                                <hr />
                                <div className="require"> Highest Respect (min: {MIN.respect})</div>
                            </div>
                        ) : (
                            <div className="poster">
                                <h1>The Godfather</h1>
                                <div
                                    className="mugshot"
                                    role="img"
                                    aria-label="mugshot-img"
                                    style={{
                                        backgroundImage: `url(${MINI_GAMES.RPS.UNKNOWL_PLAYER})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                                <p className="name">UnKnown</p>
                                {/* <em>Armed & very dangerous</em> */}
                                <h2>Reward</h2>
                                <u>{REWARDs.respect} $CROWN</u>
                                <hr />
                                <div className="require"> Highest Respect (min: {MIN.respect})</div>
                            </div>
                        )}{" "}
                        {hofList.max_win_streak &&
                        hofList.max_win_streak.stats.max_win_streak >= MIN.max_win_streak ? (
                            <div className="poster">
                                <h1>Unyielding Gladiator</h1>
                                <div
                                    className="mugshot"
                                    role="img"
                                    aria-label="mugshot-img"
                                    style={{
                                        backgroundImage:
                                            hofList.max_win_streak.nft_id == null
                                                ? `url(${MINI_GAMES.RPS.UNKNOWL_PLAYER})`
                                                : `url(https://d2l8dezwbqkb9.cloudfront.net/png/${hofList.max_win_streak.nft_id}.png)`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                                <p
                                    className="name "
                                    onClick={() => {
                                        setCurrentActionContainerSelected(
                                            E_RPS_ACTION_CONTAINER.FIND_GANGSTER
                                        );
                                        setSearchParams((param) => {
                                            param.set(
                                                E_RPS_URL_QUERY_ID.FIND_GANGSTER,
                                                hofList.max_win_streak.icon_address
                                            );
                                            return param;
                                        });
                                    }}
                                >
                                    {hofList.max_win_streak.name.length >= 20
                                        ? `${hofList.max_win_streak.name.slice(
                                              0,
                                              6
                                          )}...${hofList.max_win_streak.name.slice(37)}`
                                        : hofList.max_win_streak.name}
                                </p>
                                {/* <em>Armed & very dangerous</em> */}
                                <h2>Reward</h2>
                                <u>{REWARDs.max_win_streak} $CROWN</u>
                                <hr />
                                <div className="require">
                                    {" "}
                                    Highest win streak (min: {MIN.max_win_streak})
                                </div>
                            </div>
                        ) : (
                            <div className="poster">
                                <h1>Unyielding Gladiator</h1>
                                <div
                                    className="mugshot"
                                    role="img"
                                    aria-label="mugshot-img"
                                    style={{
                                        backgroundImage: `url(${MINI_GAMES.RPS.UNKNOWL_PLAYER})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                                <p className="name">UnKnown</p>
                                {/* <em>Armed & very dangerous</em> */}
                                <h2>Reward</h2>
                                <u>{REWARDs.max_win_streak} $CROWN</u>
                                <hr />
                                <div className="require">
                                    {" "}
                                    Highest win streak (min: {MIN.max_win_streak})
                                </div>
                            </div>
                        )}{" "}
                        {hofList.game_won && hofList.game_won.stats.game_won >= MIN.game_won ? (
                            <div className="poster">
                                <h1>Ruthless Victor</h1>
                                <div
                                    className="mugshot"
                                    role="img"
                                    aria-label="mugshot-img"
                                    style={{
                                        backgroundImage:
                                            hofList.game_won.nft_id == null
                                                ? `url(${MINI_GAMES.RPS.UNKNOWL_PLAYER})`
                                                : `url(https://d2l8dezwbqkb9.cloudfront.net/png/${hofList.game_won.nft_id}.png)`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                                <p
                                    className="name"
                                    onClick={() => {
                                        setCurrentActionContainerSelected(
                                            E_RPS_ACTION_CONTAINER.FIND_GANGSTER
                                        );
                                        setSearchParams((param) => {
                                            param.set(
                                                E_RPS_URL_QUERY_ID.FIND_GANGSTER,
                                                hofList.game_won.icon_address
                                            );
                                            return param;
                                        });
                                    }}
                                >
                                    {hofList.game_won.name.length >= 20
                                        ? `${hofList.game_won.name.slice(
                                              0,
                                              6
                                          )}...${hofList.game_won.name.slice(37)}`
                                        : hofList.game_won.name}
                                </p>
                                {/* <em>Armed & very dangerous</em> */}
                                <h2>Reward</h2>
                                <u>{REWARDs.game_won} $CROWN</u>
                                <hr />
                                <div className="require"> Top wins (min: {MIN.game_won})</div>
                            </div>
                        ) : (
                            <div className="poster">
                                <h1>Ruthless Victor</h1>
                                <div
                                    className="mugshot"
                                    role="img"
                                    aria-label="mugshot-img"
                                    style={{
                                        backgroundImage: `url(${MINI_GAMES.RPS.UNKNOWL_PLAYER})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                                <p className="name">UnKnown</p>
                                {/* <em>Armed & very dangerous</em> */}
                                <h2>Reward</h2>
                                <u>{REWARDs.game_won} $CROWN</u>
                                <hr />
                                <div className="require"> Top wins (min: {MIN.game_won})</div>
                            </div>
                        )}{" "}
                        {hofList.game_participated &&
                        hofList.game_participated.stats.game_participated >=
                            MIN.game_participated ? (
                            <div className="poster">
                                <h1>Adventurous Nomad</h1>
                                <div
                                    className="mugshot"
                                    role="img"
                                    aria-label="mugshot-img"
                                    style={{
                                        backgroundImage:
                                            hofList.game_participated.nft_id == null
                                                ? `url(${MINI_GAMES.RPS.UNKNOWL_PLAYER})`
                                                : `url(https://d2l8dezwbqkb9.cloudfront.net/png/${hofList.game_participated.nft_id}.png)`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                                <p
                                    className="name"
                                    onClick={() => {
                                        setCurrentActionContainerSelected(
                                            E_RPS_ACTION_CONTAINER.FIND_GANGSTER
                                        );
                                        setSearchParams((param) => {
                                            param.set(
                                                E_RPS_URL_QUERY_ID.FIND_GANGSTER,
                                                hofList.game_participated.icon_address
                                            );
                                            return param;
                                        });
                                    }}
                                >
                                    {hofList.game_participated.name.length >= 20
                                        ? `${hofList.game_participated.name.slice(
                                              0,
                                              6
                                          )}...${hofList.game_participated.name.slice(37)}`
                                        : hofList.game_participated.name}
                                </p>
                                {/* <em>Armed & very dangerous</em> */}
                                <h2>Reward</h2>
                                <u>{REWARDs.game_participated} $CROWN</u>
                                <hr />
                                <div className="require">
                                    {" "}
                                    Top game participations (min: {MIN.game_participated})
                                </div>
                            </div>
                        ) : (
                            <div className="poster">
                                <h1>Adventurous Nomad</h1>
                                <div
                                    className="mugshot"
                                    role="img"
                                    aria-label="mugshot-img"
                                    style={{
                                        backgroundImage: `url(${MINI_GAMES.RPS.UNKNOWL_PLAYER})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                                <p className="name">UnKnown</p>
                                {/* <em>Armed & very dangerous</em> */}
                                <h2>Reward</h2>
                                <u>{REWARDs.game_participated} $CROWN</u>
                                <hr />
                                <div className="require">
                                    {" "}
                                    Top game participations (min: {MIN.game_participated})
                                </div>
                            </div>
                        )}{" "}
                        {hofList.game_created &&
                        hofList.game_created.stats.game_created >= MIN.game_created ? (
                            <div className="poster">
                                <h1>Epic GameShaper</h1>
                                <div
                                    className="mugshot"
                                    role="img"
                                    aria-label="mugshot-img"
                                    style={{
                                        backgroundImage:
                                            hofList.game_created.nft_id == null
                                                ? `url(${MINI_GAMES.RPS.UNKNOWL_PLAYER})`
                                                : `url(https://d2l8dezwbqkb9.cloudfront.net/png/${hofList.game_created.nft_id}.png)`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                                <p
                                    className="name"
                                    onClick={() => {
                                        setCurrentActionContainerSelected(
                                            E_RPS_ACTION_CONTAINER.FIND_GANGSTER
                                        );
                                        setSearchParams((param) => {
                                            param.set(
                                                E_RPS_URL_QUERY_ID.FIND_GANGSTER,
                                                hofList.game_created.icon_address
                                            );
                                            return param;
                                        });
                                    }}
                                >
                                    {hofList.game_created.name.length >= 20
                                        ? `${hofList.game_created.name.slice(
                                              0,
                                              6
                                          )}...${hofList.game_created.name.slice(37)}`
                                        : hofList.game_created.name}
                                </p>
                                {/* <em>Armed & very dangerous</em> */}
                                <h2>Reward</h2>
                                <u>{REWARDs.game_created} $CROWN</u>
                                <hr />
                                <div className="require">
                                    {" "}
                                    Most game hosted. (min: {MIN.game_created})
                                </div>
                            </div>
                        ) : (
                            <div className="poster">
                                <h1>Epic GameShaper</h1>
                                <div
                                    className="mugshot"
                                    role="img"
                                    aria-label="mugshot-img"
                                    style={{
                                        backgroundImage: `url(${MINI_GAMES.RPS.UNKNOWL_PLAYER})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                                <p className="name">UnKnown</p>
                                {/* <em>Armed & very dangerous</em> */}
                                <h2>Reward</h2>
                                <u>{REWARDs.game_created} $CROWN</u>
                                <hr />
                                <div className="require">
                                    Most game hosted. (min: {MIN.game_created})
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {/* <div className='round_table'>
          <table cellPadding={"0"} cellSpacing={"0"} border={0}>
            <thead>
              <tr>
                <th>Player</th>
                <th>Title</th>
                <th>Rewarded</th>
                <th>Requires</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='clickable inlink'>DaVinci</td>
                <td>Godfather</td>
                <td>5,000 CROWN</td>
                <td className='text_left'>Highest respects (min: 100)</td>
              </tr>{" "}
              <tr>
                <td className='clickable inlink'>DaVinci</td>
                <td>Unyielding Gladiator</td>
                <td>4,000 CROWN</td>
                <td className='text_left'>Max win streak (min: 3)</td>
              </tr>{" "}
              <tr>
                <td className='clickable inlink'>DaVinci</td>
                <td>Ruthless Victor</td>
                <td>3,000 CROWN</td>
                <td className='text_left'>Top wins (min: 20)</td>
              </tr>{" "}
              <tr>
                <td className='clickable inlink'>DaVinci</td>
                <td>Epic Gameshaper</td>
                <td>1,500 CROWN</td>
                <td className='text_left'>Top game hosted (min: 10)</td>
              </tr>{" "}
              <tr>
                <td className='clickable inlink'>DaVinci</td>
                <td>Adventurous Nomad</td>
                <td>1,000 CROWN</td>
                <td className='text_left'>Top participations (min: 10)</td>
              </tr>{" "}
            </tbody>
          </table>
        </div> */}
                <div className="leader_board_season">
                    <h2 className="round_title">
                        {" "}
                        Round {roundInfo == false ? "" : roundInfo.roundNumber}
                    </h2>
                    {/* <p>Reward type: </p> */}
                    <p className="round_timer">
                        {" "}
                        {isEnded ? (
                            <div> Round Over</div>
                        ) : (
                            <div>
                                ends in{" "}
                                {endInTimer
                                    ? `${endInTimer.days ? `${endInTimer.days} days` : ""} ${
                                          endInTimer.hours ? `${endInTimer.hours} hours` : ""
                                      } ${endInTimer.minute ? `${endInTimer.minute} minute` : ""} ${
                                          endInTimer.second ? `${endInTimer.second} second` : ""
                                      } `
                                    : ""}
                            </div>
                        )}
                    </p>
                    <p className="round_desc">
                        {" "}
                        The {rewardType} rewards will auto adjusted after every game played{" "}
                        <a
                            className="clickable outlink"
                            href="https://gangstaverse.medium.com/rock-paper-scissors-rps-mini-game-4252f842ef26"
                            target="_blank"
                        >
                            Learn more
                        </a>
                    </p>
                </div>
                {LeadeBoardList.length >= 1 && (
                    <table>
                        <thead>
                            <tr>
                                <td>Rank</td>
                                <td>Name</td>
                                <td>Rewards ({rewardType})</td>
                                <td>Score</td>
                                <td className="table_stats " data-tooltip-id="streak_bar_tooltip">
                                    <ReactTooltip
                                        id={"streak_bar_tooltip"}
                                        place={"top"}
                                        className="streak_bar-tooltip"
                                    >
                                        Max Streak
                                    </ReactTooltip>
                                    MS
                                </td>
                                <td className="table_stats" data-tooltip-id="respect-tooltip">
                                    <ReactTooltip
                                        id={"respect-tooltip"}
                                        place={"top"}
                                        className="respect-tooltip"
                                    >
                                        Respect
                                    </ReactTooltip>
                                    R
                                </td>
                                <td className="table_stats" data-tooltip-id="profit-tooltip">
                                    <ReactTooltip
                                        id={"profit-tooltip"}
                                        place={"top"}
                                        className="profit-tooltip"
                                    >
                                        Profit %
                                    </ReactTooltip>
                                    P(%)
                                </td>
                                <td className="table_stats" data-tooltip-id="game_won-tooltip">
                                    <ReactTooltip
                                        id={"game_won-tooltip"}
                                        place={"top"}
                                        className="game_won-tooltip"
                                    >
                                        # of Game Won
                                    </ReactTooltip>
                                    GW
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {LeadeBoardList?.map(
                                (
                                    { rank, icon_address, reward, token, name, score, metric },
                                    index
                                ) => {
                                    if (rewardType == null) {
                                        setrewardType((p) => token);
                                    }
                                    return (
                                        <tr
                                            className={`pos_${index} ${
                                                walletAddress == icon_address && "user"
                                            }`}
                                            key={index}
                                        >
                                            <td className="rank_value">{rank}</td>
                                            <td
                                                className="name_value clickable inlink"
                                                onClick={() => {
                                                    setCurrentActionContainerSelected(
                                                        E_RPS_ACTION_CONTAINER.FIND_GANGSTER
                                                    );
                                                    setSearchParams((param) => {
                                                        param.set(
                                                            E_RPS_URL_QUERY_ID.FIND_GANGSTER,
                                                            icon_address
                                                        );
                                                        return param;
                                                    });
                                                }}
                                            >
                                                {name}
                                            </td>
                                            <td className="reward_value">
                                                <img
                                                    src={
                                                        token == "ICX"
                                                            ? ICONS.iconex_logo
                                                            : ICONS.crown_logo
                                                    }
                                                />
                                                <CountUp start={0} end={reward} />{" "}
                                                {/* {(reward as number).toLocaleString()} */}
                                                <span className="unit">{token} </span>
                                                {reward >= 1 && (
                                                    <span className="usd_dollar">
                                                        ~${""}
                                                        <CountUp
                                                            start={0}
                                                            end={reward * crownRate}
                                                        />
                                                    </span>
                                                )}
                                            </td>
                                            <td className="score_value">{Math.floor(score)}</td>

                                            <td
                                                className="score_value table_stats"
                                                data-tooltip-id="streak_bar_tooltip"
                                            >
                                                <ReactTooltip
                                                    id={"streak_bar_tooltip"}
                                                    place={"top"}
                                                    className="streak_bar-tooltip"
                                                >
                                                    Max Streak
                                                </ReactTooltip>
                                                {metric["win_streak"]}
                                            </td>
                                            <td
                                                className="score_value table_stats"
                                                data-tooltip-id="respect-tooltip"
                                            >
                                                <ReactTooltip
                                                    id={"respect-tooltip"}
                                                    place={"top"}
                                                    className="respect-tooltip"
                                                >
                                                    Respect
                                                </ReactTooltip>
                                                {metric["respects"]}
                                            </td>
                                            <td
                                                className="score_value table_stats"
                                                data-tooltip-id="profit-tooltip"
                                            >
                                                <ReactTooltip
                                                    id={"profit-tooltip"}
                                                    place={"top"}
                                                    className="profit-tooltip"
                                                >
                                                    Profit %
                                                </ReactTooltip>
                                                {Math.floor(metric["profit_rate"])}
                                            </td>
                                            <td
                                                className="score_value table_stats"
                                                data-tooltip-id="game_won-tooltip"
                                            >
                                                <ReactTooltip
                                                    id={"game_won-tooltip"}
                                                    place={"top"}
                                                    className="game_won-tooltip"
                                                >
                                                    # of Game Won
                                                </ReactTooltip>
                                                {metric["win_rate"]}
                                            </td>
                                        </tr>
                                    );
                                }
                            )}{" "}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

export default RPSLeaderBoard;
