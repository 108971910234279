export enum E_RPS_WAGER_TYPE {
  ICX = "ICX",
  CROWN = "CROWN",
}
export enum E_RPS_CHALLENGE_TYPE {
  ANYONE = "anyone",
  ALL_FRIENDS = "friends",
  SOMEONE = "someone",
}
export enum E_RPS_RPS_HANDS {
  ROCK = 0,
  PAPER = 1,
  SCISSOR = 2,
}

export enum E_RPS_ACTION_CONTAINER {
  PLAY = "play",
  MY_GAMES = "my games",
  LEADER_BOARD = "leaderboard",
  MY_PROFILE = "my profile",
  FIND_GANGSTER = "find gangster",
  // LOCkER_ROOM = "lobby",
  REWARD_ROOM = "rewards",
  LOGS = "logs",
}

export const C_RPS_HAND = {
  [E_RPS_RPS_HANDS.PAPER]: "Paper",
  [E_RPS_RPS_HANDS.SCISSOR]: "Scissor",
  [E_RPS_RPS_HANDS.ROCK]: "Rock",
};

export const ZERO_ADDRESS = "hx0000000000000000000000000000000000000000";

export enum E_RPS_URL_QUERY_ID {
  ROOM = "r",
  GAME_ID = "gid",
  FIND_GANGSTER = "fg",
}

export enum E_RPS_OPEN_GAME_TYPE {
  TO_FRIENDS = "to friends",
  TO_ANYONE = "to anyone",
  A_CHALLENGE = "a challenge",
}
export type T_RPS_GAME_TYPE = "PUBLIC" | "FRIENDS" | "ADDRESS";
