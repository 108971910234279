"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const Response_1 = require("../../jsonrpc/Response");
const Exception_1 = require("../../../Exception");
class HttpCall {
    constructor(httpCall, converter) {
        this.httpCall = httpCall;
        this.converter = converter;
    }
    execute() {
        return this.callAsync();
    }
    callAsync() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.httpCall.execute();
                return new Response_1.default(response, this.converter).result;
            }
            catch (e) {
                if (typeof e.error === "object") {
                    const rpcError = new Exception_1.RpcError(e.error.message);
                    throw rpcError.toString();
                }
                else {
                    throw e;
                }
            }
        });
    }
}
exports.default = HttpCall;
