import L from "leaflet";
function translateCoordinates(
  x: number,
  y: number
): {
  translatedX: number;
  translatedY: number;
} {
  // Original coordinate system
  const maxXOld = 280 / 2;
  const minXOld = -280 / 2;
  // const maxYOld = 58.8;
  // const minYOld = -60.8;
  const maxYOld = 58.99;
  const minYOld = -60.75;
  const { maxXNew, minXNew, maxYNew, minYNew } = {
    maxXNew: 204.551,
    minXNew: 51.44,
    maxYNew: 95.2,
    minYNew: 160.72,
  };

  // Calculate scaling factors
  const scaleX = (maxXNew - minXNew) / (maxXOld - minXOld);
  const scaleY = (maxYNew - minYNew) / (maxYOld - minYOld);

  // Perform translation
  const translatedX = parseFloat((scaleX * (x - minXOld) + minXNew).toFixed(16));
  const translatedY = parseFloat((scaleY * (y - minYOld) + minYNew).toFixed(16));

  return { translatedX, translatedY };
}
export function convertPointsToLatLng(x: number, y: number, zoom: number = 0) {
  const { translatedX, translatedY } = translateCoordinates(x, y);
  // console.log(`input:(${x},${y}) transformed:(${translatedX},${translatedY})`);
  let po = L.point(translatedX, translatedY);
  // console.log(po);
  // console.log(L.CRS.Simple.pointToLatLng(po));
  return L.CRS.Simple.pointToLatLng(po, 0); // When doing xy(x, y);
}
