import { useEffect, useState } from "react";

const ImageLoader = ({
  src,
  className,
  alt,
  style,
  onClick,
  skeletonStyle,
  width = "100%",
  height,
  skeletonClassName,
}: any) => {
  const [isImageLoaded, setImageLoader] = useState(true);
  useEffect(() => {
    setImageLoader(true);
    if (src) {
      const image = new Image();
      image.src = src;
      image.onload = () => {
        setImageLoader(false);
      };
    } else {
      setImageLoader(true);
    }
  }, [src]);
  if (isImageLoaded)
    // eslint-disable-next-line react/self-closing-comp
    return (
      <div
        style={skeletonStyle}
        className={`skeleton skeleton-default-width ${skeletonClassName}`}
      />
    );
  else
    return (
      // eslint-disable-next-line
      <img
        onClick={onClick}
        width={width}
        height={height}
        src={src}
        style={style}
        className={className}
        alt={alt}
      />
    );
};

export default ImageLoader;
