import React from "react";
import { IoWalletOutline } from "react-icons/io5";
import { handleWalletConnection } from "../../../../utils/wallet.utils";

export default function ConnectWalletComponent({
  size,
  title,
  desc,
}: {
  title: string;
  size?: "small" | "medium";
  desc: string;
}) {
  return (
    <div className={`connect_wallet_rps ${size}`}>
      <IoWalletOutline className='connect_wallet_rps_icon' />
      <h2>{title}</h2>
      <p>{desc}</p>
      <button
        onClick={() => {
          handleWalletConnection();
        }}>
        Connect
      </button>
    </div>
  );
}
