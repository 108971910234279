import { createContext, useContext, useEffect, useRef, useState } from "react";
import { E_RPS_ACTION_CONTAINER, E_RPS_URL_QUERY_ID } from "../../enums/RPS.enums";
import { useSearchParams } from "react-router-dom";
type I_CURRENT_RPS_SELECTED_GAME =
  | {
      nftId: number;
      creator: string;
      gameId: string;
      race: string;
      name: string;
      amount: number;
      tokenAddress: string;
      message: string;
    }
  | false;
type T_USER_STATS = {
  game_created: number;
  game_participated: number;
  game_won: number;
  win_streak: number;
  respect: number;
};
interface IRPSContext {
  lastPlayed: any;
  setlastPlayed: React.Dispatch<React.SetStateAction<any>>;
  challengingPlayerAddress:
    | {
        address: string;
        nftId: number;
        name: string;
      }
    | false;
  setChallengingPlayerAddress: React.Dispatch<
    React.SetStateAction<
      | {
          address: string;
          nftId: number;
          name: string;
        }
      | false
    >
  >;
  currentSelectedOpenGame: I_CURRENT_RPS_SELECTED_GAME;
  setcurrentSelectedOpenGame: React.Dispatch<React.SetStateAction<I_CURRENT_RPS_SELECTED_GAME>>;
  isNew: boolean;
  setIsNew: React.Dispatch<React.SetStateAction<boolean>>;
  isOpeningGame: boolean;
  setIsOpeningGame: React.Dispatch<React.SetStateAction<boolean>>;
  showIntroNPCDialogue: boolean;
  setShowIntroNPCDialogue: React.Dispatch<React.SetStateAction<boolean>>;
  showNormalDADialogue: boolean;
  setShowNormalDADialogue: React.Dispatch<React.SetStateAction<boolean>>;
  showRegistrationDialogue: boolean;
  setshowRegistrationDialogue: React.Dispatch<React.SetStateAction<boolean>>;
  nftId: number | null;
  setNftId: React.Dispatch<React.SetStateAction<number | null>>;
  userName: string | null;
  setUserName: React.Dispatch<React.SetStateAction<string | null>>;
  setNftRace: React.Dispatch<React.SetStateAction<string | null>>;
  nftRace: string | null;
  userStat: T_USER_STATS | null;
  setUserStat: React.Dispatch<React.SetStateAction<T_USER_STATS | null>>;
  friendList: Array<string>;
  setFriendList: React.Dispatch<React.SetStateAction<Array<string>>>;
  currentActionContainerSelected: E_RPS_ACTION_CONTAINER;
  setCurrentActionContainerSelected: React.Dispatch<React.SetStateAction<E_RPS_ACTION_CONTAINER>>;
  challengedGameNumber: number;
  setChallengedGameNumber: React.Dispatch<React.SetStateAction<number>>;
  roundInfo: any;
  setRoundInfo: React.Dispatch<React.SetStateAction<any>>;
}

const RPSContext = createContext<IRPSContext>({
  challengedGameNumber: 0,
  setChallengedGameNumber: () => {},
  isNew: true,
  setIsNew: () => {},
  nftId: null,
  setNftId: () => {},
  userStat: null,
  setUserStat: () => {},
  userName: null,
  setUserName: () => {},
  showIntroNPCDialogue: false,
  setShowIntroNPCDialogue: () => {},
  showNormalDADialogue: false,
  setShowNormalDADialogue: () => {},
  setNftRace: () => {},
  nftRace: null,
  currentSelectedOpenGame: false,
  setcurrentSelectedOpenGame: () => {},
  friendList: [],
  setFriendList: () => {},
  showRegistrationDialogue: false,
  setshowRegistrationDialogue: () => {},
  currentActionContainerSelected: E_RPS_ACTION_CONTAINER.PLAY,
  setCurrentActionContainerSelected: () => {},
  isOpeningGame: false,
  setIsOpeningGame: () => {},
  lastPlayed: null,
  setlastPlayed: () => {},
  challengingPlayerAddress: false,
  setChallengingPlayerAddress: () => {},
  roundInfo: false,
  setRoundInfo: () => {},
});

const RPSProvider = ({ children }: any) => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const [challengingPlayerAddress, setChallengingPlayerAddress] = useState<
    | {
        address: string;
        nftId: number;
        name: string;
      }
    | false
  >(false);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [roundInfo, setRoundInfo] = useState<any>(false);
  const [challengedGameNumber, setChallengedGameNumber] = useState<number>(0);
  const [showIntroNPCDialogue, setShowIntroNPCDialogue] = useState<boolean>(false);
  const [showNormalDADialogue, setShowNormalDADialogue] = useState<boolean>(false);
  const [showRegistrationDialogue, setshowRegistrationDialogue] = useState<boolean>(false);
  const [nftId, setNftId] = useState<number | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [nftRace, setNftRace] = useState<string | null>(null);
  const [userStat, setUserStat] = useState<T_USER_STATS | null>(null);
  const [friendList, setFriendList] = useState<Array<string>>([]);
  const [currentActionContainerSelected, setCurrentActionContainerSelected] =
    useState<E_RPS_ACTION_CONTAINER>(E_RPS_ACTION_CONTAINER.PLAY);
  const [currentSelectedOpenGame, setcurrentSelectedOpenGame] =
    useState<I_CURRENT_RPS_SELECTED_GAME>(false);
  const [isOpeningGame, setIsOpeningGame] = useState<boolean>(false);
  const [lastPlayed, setlastPlayed] = useState<any>(null);
  return (
    <RPSContext.Provider
      value={{
        isNew,
        setIsNew,
        setNftId,
        showIntroNPCDialogue,
        nftId,
        setShowIntroNPCDialogue,
        showNormalDADialogue,
        setShowNormalDADialogue,
        userName,
        setUserName,
        nftRace,
        setNftRace,
        currentSelectedOpenGame,
        setcurrentSelectedOpenGame,
        userStat,
        setUserStat,
        friendList,
        setFriendList,
        showRegistrationDialogue,
        setshowRegistrationDialogue,
        currentActionContainerSelected,
        setCurrentActionContainerSelected,
        isOpeningGame,
        setIsOpeningGame,
        lastPlayed,
        setlastPlayed,
        challengedGameNumber,
        setChallengedGameNumber,
        challengingPlayerAddress,
        setChallengingPlayerAddress,
        roundInfo,
        setRoundInfo,
      }}>
      {children}
    </RPSContext.Provider>
  );
};

const useRPSContext = () => {
  return useContext(RPSContext);
};

export { RPSProvider, useRPSContext };
