import { AiOutlineClose } from "react-icons/ai";
import StylishModalDecor from "../../components/decorators/StylishModalDecor";
import RPSMain from "./RPS.main";
import { useNavigate, useSearchParams } from "react-router-dom";
import { E_RPS_URL_QUERY_ID } from "../../enums/RPS.enums";
const ModelElement = ({ setOpen, landID }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <div className='modal-elements'>
      <div className='modal-upper-elements'>
        <AiOutlineClose
          color='#aa9b46'
          onClick={() => {
            Object.values(E_RPS_URL_QUERY_ID).forEach((data) => {
              setSearchParams((param) => {
                param.delete(data);
                return param;
              });
            });
            navigate(`/land_15_12`);
            setOpen(false);
          }}
          size={40}
          className='close-modal-btn'
        />
      </div>
      <div className='modal-children'>
        <RPSMain />
      </div>
    </div>
  );
};

const RPSModal = ({ open, children, setOpen, title, landID }: any) => {
  if (!open) return null;
  return (
    <div className={`blur overlay rps_container`}>
      <div className='blur_for_bg'></div>
      <div className={`modal-wrapper`}>
        <div className='modal'>
          <StylishModalDecor>
            <ModelElement title={title} children={children} setOpen={setOpen} landID={landID} />
          </StylishModalDecor>
        </div>
      </div>
    </div>
  );
};

export default RPSModal;
