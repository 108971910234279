"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallTransactionBuilder = exports.CallTransaction = void 0;
const IcxTransaction_1 = require("./IcxTransaction");
const Util_1 = require("../../data/Util");
/**
 * SubClass for making the transaction object for calling a method in SCORE.
 * @extends {IcxTransaction}
 */
class CallTransaction extends IcxTransaction_1.IcxTransaction {
    constructor(to, from, value, stepLimit, nid, nonce, version, timestamp, method, params) {
        super(to, from, value, stepLimit, nid, nonce, version, timestamp);
        this.dataType = "call";
        this.data = { method };
        if (params) {
            this.data.params = params;
        }
    }
}
exports.CallTransaction = CallTransaction;
/**
 * Builder for 'CallTransaction' object.
 * @extends {IcxTransactionBuilder}
 */
class CallTransactionBuilder extends IcxTransaction_1.IcxTransactionBuilder {
    /**
     * Creates an instance of CallTransactionBuilder.
     */
    constructor() {
        super();
        this.private = (0, Util_1.createPrivate)();
        this.private(this).method = undefined;
        this.private(this).params = undefined;
    }
    /**
     * Set 'method' property
     * @param {string} method - The method name of SCORE API
     * @return {CallTransactionBuilder} this.
     */
    method(method) {
        this.private(this).method = method;
        return this;
    }
    /**
     * Set 'params' property
     * @param {object} params - The input params for method
     * @return {CallTransactionBuilder} this.
     */
    params(params) {
        this.private(this).params = params;
        return this;
    }
    /**
     * Build 'CallTransaction' object
     * @return {CallTransaction} 'CallTransaction' instance exported by 'CallTransactionBuilder'.
     */
    build() {
        return new CallTransaction(this.private(this).to, this.private(this).from, this.private(this).value, this.private(this).stepLimit, this.private(this).nid, this.private(this).nonce, this.private(this).version, this.private(this).timestamp, this.private(this).method, this.private(this).params);
    }
}
exports.CallTransactionBuilder = CallTransactionBuilder;
