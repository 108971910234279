import React, { useEffect, useState } from "react";
import { E_RPS_ACTION_CONTAINER, E_RPS_URL_QUERY_ID } from "../../../../enums/RPS.enums";
import { GiMailedFist, GiPointySword } from "react-icons/gi";
import { getUser } from "../../../../services/data/rpsAPIs.services";
import { MINI_GAMES } from "../../../../assets/assets";
import { C_GBET_NFT_META_MAPPING } from "../../../../constants/gbet_nft_id";
import { ImProfile } from "react-icons/im";
import { IoPersonRemove } from "react-icons/io5";
import { useIconEventContext } from "../../../../services/contexts/iconEvent.context";
import { E_ICONEX_RPC_EVENT_TYPE } from "../../../../hooks/useIconexHook";
import rpsService from "../../../../services/contract/rps.service";
import { useWalletContext } from "../../../../services/contexts/wallet.context";
import { useRPSContext } from "../../../../services/contexts/rps.context";

function FriendListTableRow({
  setCurrentActionContainerSelected,
  setSearchParams,
  index,
  setChallengingPlayerAddress,
  setIsOpeningGame,
  address,
  setisDataLoading,
}: any) {
  const [nftId, setnftId] = useState<any>(null);
  const [name, setName] = useState<any>(null);
  const [race, setRace] = useState<any>(null);
  const [streak, setStreak] = useState<number>(0);
  const { setIsTransactionLoading, setCurrentEventType, currentEventType } = useIconEventContext();
  const { walletAddress } = useWalletContext();
  const { setFriendList } = useRPSContext();
  const getUserDetails = async () => {
    console.log("getting user details rps");
    const res = await getUser(address);
    console.log(res);
    if (res) {
      if (res.data != false) {
        setnftId(res.data.nft_id);
        setName(res.data.name);
        setRace(res.data.nft_race);
        setStreak(res.data.stats.win_streak);
      } else {
        setnftId(null);
      }
    }
  };
  useEffect(() => {
    getUserDetails();

    return () => {};
  }, []);
  const getFriends = async () => {
    const response = await rpsService.getFriendList(walletAddress);
    console.log("friends", response);
    setFriendList(response as string[]);
  };
  useEffect(() => {
    if (currentEventType == E_ICONEX_RPC_EVENT_TYPE.REMOVING_FRIEND_SUCCESS) {
      setTimeout(() => {
        getFriends();
      }, 4000);
    }
    return () => {};
  }, [currentEventType]);
  const removeFriend = async () => {
    setisDataLoading(true);
    const response = await rpsService.RemoveFromFriendList(walletAddress, address);
    console.log(response);
  };
  return (
    <tr key={index}>
      <td className=''>
        <div className='friend_detail_card'>
          <img
            className='img_nft'
            src={
              nftId
                ? `https://d2l8dezwbqkb9.cloudfront.net/ipfs/img/${C_GBET_NFT_META_MAPPING[nftId]}.gif`
                : MINI_GAMES.RPS.UNKNOWL_PLAYER
            }
          />
          <div className='frn_info'>
            <h3
              className='inlink'
              onClick={() => {
                setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
                setSearchParams((param) => {
                  param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, address);
                  return param;
                });
              }}>
              {name}
            </h3>
            <p>{address}</p>
          </div>
        </div>
      </td>
      <td>
        <div className='friendList_cta'>
          <button
            className='primary danger'
            onClick={() => {
              setChallengingPlayerAddress({
                name,
                address,
                nftId,
              });
              setIsOpeningGame(true);
              setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.PLAY);
            }}>
            <GiPointySword className='icon' />
            Challenge
          </button>{" "}
          <button
            className='primary '
            onClick={() => {
              setCurrentActionContainerSelected(E_RPS_ACTION_CONTAINER.FIND_GANGSTER);
              setSearchParams((param) => {
                param.set(E_RPS_URL_QUERY_ID.FIND_GANGSTER, address);
                return param;
              });
            }}>
            <ImProfile className='icon' />
            View Profile
          </button>{" "}
          <button
            className='primary '
            onClick={() => {
              setIsTransactionLoading(true);
              setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.REMOVING_FRIEND_PENDING);
              removeFriend();
            }}>
            <IoPersonRemove className='icon' />
            Remove
          </button>
        </div>
      </td>
    </tr>
  );
}

export default FriendListTableRow;
