"use strict";
/*
 * Copyright 2021 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.toRawTransaction = exports.toBoolean = exports.toHex = exports.toHexNumber = exports.toBigNumber = exports.toNumber = exports.toUtf8 = exports.fromUtf8 = void 0;
const bignumber_js_1 = require("bignumber.js");
const utf8 = require("utf8");
const Hexadecimal_1 = require("./Hexadecimal");
const Type_1 = require("./Type");
const Exception_1 = require("../Exception");
/**
 * Convert UTF-8 text to hex string.
 * @param {string} value - the UTF-8 string only.
 * @return {string} the hex string.
 */
function fromUtf8(value) {
    if (!(0, Type_1.isString)(value)) {
        const error = new Exception_1.DataError("Invalid string");
        throw error.toString();
    }
    const bytes = [];
    let convertedText = "";
    for (let i = 0; i < value.length; i += 1) {
        const originBytes = unescape(encodeURIComponent(value[i]));
        for (let j = 0; j < originBytes.length; j += 1) {
            bytes.push(originBytes[j].charCodeAt(0));
        }
    }
    const textToHexFormat = "%x";
    for (let i = 0; i < bytes.length; i += 1) {
        const byte = bytes[i];
        let hexByte = byte.toString(16);
        if (hexByte.length === 1) {
            hexByte = `0${hexByte}`;
        }
        let char = textToHexFormat;
        char = char.replace(/%x/g, hexByte);
        convertedText += char;
    }
    return (0, Hexadecimal_1.add0xPrefix)(convertedText);
}
exports.fromUtf8 = fromUtf8;
/**
 * Convert hex string to UTF-8 text.
 * @param {String} value the hex string only.
 * @returns {String} the UTF-8 string.
 */
function toUtf8(value) {
    if (!(0, Type_1.isHex)(value)) {
        const error = new Exception_1.DataError("Invalid hex string");
        throw error.toString();
    }
    let str = "";
    let i = 0;
    const l = value.length;
    if (value.substring(0, 2) === "0x") {
        i = 2;
    }
    for (; i < l; i += 2) {
        const code = parseInt(value.substr(i, 2), 16);
        if (code === 0) {
            break;
        }
        str += String.fromCharCode(code);
    }
    return utf8.decode(str);
}
exports.toUtf8 = toUtf8;
/**
 * Convert string or BigNumber value to number.
 * @param {string|BigNumber} value - the value.
 * @return {number} the value converted to number.
 */
function toNumber(value) {
    if ((0, Type_1.isBigNumber)(value)) {
        return value.toNumber();
    }
    return new bignumber_js_1.default(value).toNumber();
}
exports.toNumber = toNumber;
/**
 * Convert string or number value to BigNumber.
 * @param {string|number} value - the value.
 * @return {BigNumber} the value converted to BigNumber.
 */
function toBigNumber(value) {
    if ((0, Type_1.isBigNumber)(value)) {
        return value;
    }
    return new bignumber_js_1.default(value);
}
exports.toBigNumber = toBigNumber;
/**
 * Convert string, number or BigNumber value to hex string strictly.
 * @param {string|number|BigNumber} value - the value that represents the number.
 * @return {string} the value converted to hex string.
 */
function toHexNumber(value) {
    return (0, Hexadecimal_1.add0xPrefix)(toBigNumber(value).toString(16));
}
exports.toHexNumber = toHexNumber;
/**
 * Convert string, number or BigNumber value to hex string.
 * @param {string|number|BigNumber} value - the value.
 * @return {string} the value converted to hex string.
 */
function toHex(value) {
    if (!value) {
        return "0x0";
    }
    if ((0, Type_1.isHex)(value.toString())) {
        return value.toString();
    }
    if ((0, Type_1.isString)(value)) {
        return fromUtf8(value.toString());
    }
    if ((0, Type_1.isInteger)(value)) {
        return toHexNumber(value);
    }
    if ((0, Type_1.isBigNumber)(value) && value.isInteger()) {
        return toHexNumber(value);
    }
    const error = new Exception_1.DataError("Invalid value");
    throw error.toString();
}
exports.toHex = toHex;
function toBoolean(value) {
    const n = toBigNumber(value).toNumber();
    if (n === 1)
        return true;
    if (n === 0)
        return false;
    const error = new Exception_1.DataError("Invalid value");
    throw error.toString();
}
exports.toBoolean = toBoolean;
/**
 * Convert transaction object to raw transaction object.
 * @param {object} transaction - the transaction object.
 * @return {object} the raw transaction object.
 */
function toRawTransaction(transaction) {
    const { to, from, stepLimit, nid, version, timestamp, dataType, data, value, nonce, } = transaction;
    const rawTransaction = {
        to,
        from,
        nid: toHexNumber(nid),
        version: toHexNumber(version),
        timestamp: toHexNumber(timestamp),
    };
    if (stepLimit) {
        rawTransaction["stepLimit"] = toHexNumber(stepLimit);
    }
    if (value) {
        rawTransaction["value"] = toHexNumber(value);
    }
    if (nonce) {
        rawTransaction["nonce"] = toHexNumber(nonce);
    }
    if (dataType) {
        rawTransaction["dataType"] = dataType;
    }
    if (data) {
        rawTransaction["data"] = data;
    }
    return rawTransaction;
}
exports.toRawTransaction = toRawTransaction;
