import React from "react";
import L, { LatLngExpression } from "leaflet";
import { Polygon, Polyline, Tooltip, FeatureGroup, Marker, Popup } from "react-leaflet";
import { DISTRICT_NAME, DISTRICT_POLYGONS } from "../../../constants/districts.ploygons";
import { convertPointsToLatLng } from "../../../utils/cordinates.utils";

const DISTRICT_TEXT_POSITION: Record<string, Array<number>> = {
  district_1: [73, 0], // GreenField
  district_2: [-104, 0], //Heritage Quarters
  district_3: [-25, 25], //Winport
  district_4: [36, -9], //Riverside
  district_5: [-59, -15], //Aeroville
  district_6: [40, 23], //Kingston
  district_7: [-70, 5], //Pineview Crossings
  district_8: [-6, 0], //Crowndale
  district_9: [110, -15], //Paradise Isle
};

function Districtslayer({ zoomLevel, selectThisDistrict, selectedDistrict }: any) {
  return (
    <div>
      {Object.keys(DISTRICT_POLYGONS).map((name, index) => {
        const text_html = L.divIcon({
          html: `<p class='district_name_label' style="background:transparent;color:white;width:0px;font-size:${
            18 + (zoomLevel - 1) * 4 + "px !important"
          }">${DISTRICT_NAME[name]}</p>`,
        });
        return (
          <FeatureGroup
            pathOptions={{
              color: "#0E5A8A",
              weight: 4.5,
              fill: true,
              fillColor: "#0E5A8A",
              fillOpacity: 0.4,
            }}
            eventHandlers={{
              click: (e) => {
                selectThisDistrict(name);
              },
            }}>
            <Polygon
              eventHandlers={{
                mouseover: (e) => {
                  console.log(e.target);

                  e.target.setStyle({
                    fillOpacity: 0.6,
                  });
                },
                mouseout: (e) => {
                  console.log(e.target.options.fillOpacity);
                  e.target.setStyle({
                    fillOpacity: 0.4,
                  });
                },
              }}
              positions={DISTRICT_POLYGONS[name]}>
              <Marker
                draggable={false}
                position={convertPointsToLatLng(
                  window.innerWidth <= 576
                    ? DISTRICT_TEXT_POSITION[name][0] - 5
                    : DISTRICT_TEXT_POSITION[name][0],
                  window.innerWidth <= 576
                    ? DISTRICT_TEXT_POSITION[name][1] - 10
                    : DISTRICT_TEXT_POSITION[name][1],
                  zoomLevel
                )}
                eventHandlers={{
                  click: (e) => {
                    selectThisDistrict(name);
                  },
                }}
                icon={text_html}></Marker>
            </Polygon>
          </FeatureGroup>
        );
      })}
    </div>
  );
}

export default Districtslayer;
