import React from "react";
import ToggleSwitch from "../../common/ToggleSwitch.component";
import { useWalletContext } from "../../../services/contexts/wallet.context";
import Button from "../../common/Button.component";
import { useIconexHook } from "../../../hooks/useIconexHook";
import { handleWalletConnection } from "../../../utils/wallet.utils";

interface IDistrictsToggle {
  showDistrict: boolean;
  setShowDistrict: any;
}

function ConnectWallet({ setShowMyLands, showMyLands }: any) {
  const { isWalletConnected, walletAddress, setWalletAddress, setWalletConnectionStatus } =
    useWalletContext();
  useIconexHook();
  return (
    <div className='toggle_component wallet_toggle'>
      <div className='infoBox'>
        <div className='title'>Show your lands</div>
        <div className='subDesc'>Please connect your wallet to view your lands.</div>
        <a
          target='_blank'
          href={`https://emeraldcity.gangstaverse.co/inventory/${walletAddress}`}
          className='address'>
          {walletAddress.length >= 1 && `${walletAddress.slice(0, 4)}...${walletAddress.slice(-4)}`}{" "}
        </a>{" "}
        {isWalletConnected && (
          <span
            className='disconnect'
            onClick={() => {
              setWalletAddress("");
              setWalletConnectionStatus(false);
              localStorage.removeItem("address");
            }}>
            (Disconnect)
          </span>
        )}
      </div>
      {isWalletConnected ? (
        <div>
          <ToggleSwitch
            type={"large"}
            checked={showMyLands}
            onChange={(e: any) => {
              setShowMyLands(e.target.checked);
            }}
            isDisabled={false}
          />
        </div>
      ) : (
        <Button
          size='medium'
          onClick={() => {
            handleWalletConnection();
          }}
          isDisabled={false}
          name={"Connect Wallet"}
        />
      )}
    </div>
  );
}

export default ConnectWallet;
