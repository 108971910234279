import axios from "axios";
import { MINI_GAME_URL } from "../../constants/externalURI";
import {
  REACT_APP_EMERALD_CITY_NODE_API,
  REACT_APP_NETWORK_API,
  REACT_APP_RPS_MINI_GAME_SCORE,
} from "./config";
import iconService from "../icon/icon.service";
export const getHallOfFame = async () => {
  try {
    const res = await axios.get(
      `${REACT_APP_EMERALD_CITY_NODE_API}${MINI_GAME_URL.RPS.HALL_OF_FAME}`
    );
    if (res.status == 200) {
      return { ...res.data };
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};
export const fetchCrownPrice = async () => {
  const response = await iconService.getFromChain(
    "cxa0af3165c08318e988cb30993b3048335b94af6c",
    "getPrice",
    {
      _id: "0x32",
    }
  );
  const price = Number(response) / 10 ** 18;
  console.log("Crown Price ", price);
  return price.toFixed(8);
};

export const getUser = async (address: string) => {
  try {
    const res = await axios.post(
      `${REACT_APP_EMERALD_CITY_NODE_API}${MINI_GAME_URL.RPS.GET_USER}`,
      {
        address,
      }
    );
    if (res.status == 200) {
      return { ...res.data };
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};
export const getLatestLogs = async () => {
  try {
    const res = await axios.get(`${REACT_APP_EMERALD_CITY_NODE_API}${MINI_GAME_URL.RPS.GET_LOGS}`);
    if (res.status == 200) {
      return { ...res.data };
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getRoundInfo = async () => {
  try {
    const res = await axios.get(
      `${REACT_APP_EMERALD_CITY_NODE_API}${MINI_GAME_URL.RPS.ROUND_INFO}`
    );
    if (res.status == 200) {
      return { ...res.data };
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getResult = async (txHash: string) => {
  try {
    console.log("📚 [API] Publishing result...");
    const res = await axios.post(
      `${REACT_APP_EMERALD_CITY_NODE_API}${MINI_GAME_URL.RPS.GET_RESULT}`,
      {
        txHash,
      }
    );
    if (res.status == 200) {
      return { ...res.data };
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getCreatedGame = async (address: string) => {
  try {
    const res = await axios.post(
      `${REACT_APP_EMERALD_CITY_NODE_API}${MINI_GAME_URL.RPS.GET_CREATED_GAME}`,
      {
        address,
      }
    );
    if (res.status == 200) {
      return { ...res.data };
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getLatestGames = async (address: string) => {
  try {
    const res = await axios.post(
      `${REACT_APP_EMERALD_CITY_NODE_API}${MINI_GAME_URL.RPS.GET_LATEST_GAMES}`,
      {
        address,
      }
    );
    if (res.status == 200) {
      return { ...res.data };
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getPlayedGame = async (address: string) => {
  try {
    const res = await axios.post(
      `${REACT_APP_EMERALD_CITY_NODE_API}${MINI_GAME_URL.RPS.GET_PLAYED_GAME}`,
      {
        address,
      }
    );
    if (res.status == 200) {
      return { ...res.data };
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getLeaderBoardRPS = async () => {
  try {
    const res = await axios.get(
      `${REACT_APP_EMERALD_CITY_NODE_API}${MINI_GAME_URL.RPS.GET_LEADERBOARD}`
    );
    if (res.status == 200) {
      return { ...res.data };
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};
export const rpsRegisterUser = async (
  address: string,
  nft_id: number | null,
  name: string | null,
  nft_race: string | null,
  headers: { signature: string; payload: string }
) => {
  try {
    // export const headerConfig =;
    const res = await axios.post(
      `${REACT_APP_EMERALD_CITY_NODE_API}${MINI_GAME_URL.RPS.REGISTER}`,
      {
        address,
        nft_id,
        name: name == null ? address : name,
        race: nft_race,
      },
      {
        headers,
      }
    );
    if (res.status == 200) {
      return { ...res.data };
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};
export const getTotalTxFromMiniGame = async () => {
  try {
    const res = await axios.get(
      `${REACT_APP_NETWORK_API}/transactions/address/${REACT_APP_RPS_MINI_GAME_SCORE}`
    );
    if (res.status == 200) {
      console.log(res.headers);
      console.log(res.headers["x-total-count"]);
      return res.headers["x-total-count"] ? res.headers["x-total-count"] : 0;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getGeneralStatsRPS = async () => {
  try {
    const res = await axios.get(
      `${REACT_APP_EMERALD_CITY_NODE_API}${MINI_GAME_URL.RPS.GENERAL_STATS}`
    );
    if (res.status == 200) {
      return { ...res.data };
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};
