import menu_3_fill from "./icons/menu-3-fill.png";
import arrow_left from "./icons/arrow-left.png";
import close from "./icons/close-line.png";
import Airport from "./icons/markers/airport.png";
import Governmental from "./icons/markers/government.png";
import Commercial from "./icons/markers/Commercial.png";
import Health from "./icons/markers/Hospital.png";
import Industrial from "./icons/markers/Industrial.png";
import Recreational from "./icons/markers/Recreational.png";
import marker_rps from "./icons/markers/rps.png";
import marker_beatdown from "./icons/markers/beatdown_market.png";
import marker_mobRun from "./icons/markers/mobRunMarker.png";
import Residential from "./icons/markers/Residential.png";
import myLand from "./icons/markers/myLand.png";
import Education from "./icons/markers/Education.png";
import Decorator from "./icons/decors/cornerDecor.svg";
import stylishSep from "./icons/decors/line.svg";
import ModalDecorator from "./icons/decors/boxEdge.svg";
import discord from "./icons/social/discord.png";
import gb_logo from "./icons/social/gb_logo.png";
import medium from "./icons/social/medium.png";
import twitter from "./icons/social/twitter.png";
import rps_bg from "./mini_games/rps/bg.png";
import rps_two_players from "./mini_games/rps/two_players.png";
import vs_logo from "./mini_games/rps/vs_logo.png";
import lobby from "./mini_games/rps/lobby.png";
import rps_paper from "./mini_games/rps/paper.png";
import rps_rock from "./mini_games/rps/rock.png";
import zombie_scissor from "./mini_games/rps/zombie_scissor.png";
import zombie_rock from "./mini_games/rps/zombie_rock.png";
import zombie_paper from "./mini_games/rps/zombie_paper.png";
import cyborg_scissor from "./mini_games/rps/cyborg_scissor.png";
import cyborg_rock from "./mini_games/rps/cyborg_rock.png";
import cyborg_paper from "./mini_games/rps/cyborg_paper.png";
import alien_scissor from "./mini_games/rps/alien_scissor.png";
import alien_rock from "./mini_games/rps/alien_rock.png";
import alien_paper from "./mini_games/rps/alien_paper.png";
import human_scissor from "./mini_games/rps/human_scissor.png";
import human_rock from "./mini_games/rps/human_rock.png";
import human_paper from "./mini_games/rps/human_paper.png";
import pattern from "./mini_games/rps/pattern.png";
import ghost_scissor from "./mini_games/rps/ghost_scissor.png";
import dino_rock from "./mini_games/rps/dino_rock.png";
import dino_paper from "./mini_games/rps/dino_paper.png";
import dino_scissor from "./mini_games/rps/dino_scissor.png";
import ghost_rock from "./mini_games/rps/ghost_rock.png";
import ghost_paper from "./mini_games/rps/ghost_paper.png";
import blood from "./mini_games/blood.png";
import ski from "./mini_games/ski.jpg";
import fire from "./mini_games/rps/fire.png";
import unknown_player from "./mini_games/rps/unknown_player.png";
import rps_scissor from "./mini_games/rps/scissor.png";
import pill from "./icons/crown-logo.svg";
import crown_logo from "./icons/crown-logo.svg";
import iconex_logo from "./icons/iconex.svg";
import beatdown_logo from "./beatdown.png";
import gw_logo from "./logo.png";
import { E_RPS_RPS_HANDS } from "../enums/RPS.enums";
import fire_log from "./icons/fire_logo.png";
const SOCIALS = { discord, gb_logo, medium, twitter };

const MINI_GAMES = {
    RPS: {
        BACKGROUND: rps_bg,
        BLAZE: fire,
        TWO_PLAYERS: rps_two_players,
        UNKNOWL_PLAYER: unknown_player,
        LOBBY: lobby,
        VS_LOGO: vs_logo,
        PATTERN: pattern,
        RACE_HAND: {
            ZOMBIE: {
                [E_RPS_RPS_HANDS.ROCK]: zombie_rock,
                [E_RPS_RPS_HANDS.PAPER]: zombie_paper,
                [E_RPS_RPS_HANDS.SCISSOR]: zombie_scissor,
            },
            HUMAN: {
                [E_RPS_RPS_HANDS.ROCK]: human_rock,
                [E_RPS_RPS_HANDS.PAPER]: human_paper,
                [E_RPS_RPS_HANDS.SCISSOR]: human_scissor,
            },
            ALIEN: {
                [E_RPS_RPS_HANDS.ROCK]: alien_rock,
                [E_RPS_RPS_HANDS.PAPER]: alien_paper,
                [E_RPS_RPS_HANDS.SCISSOR]: alien_scissor,
            },
            GHOST: {
                [E_RPS_RPS_HANDS.ROCK]: ghost_rock,
                [E_RPS_RPS_HANDS.PAPER]: ghost_paper,
                [E_RPS_RPS_HANDS.SCISSOR]: ghost_scissor,
            },
            DINOSAUR: {
                [E_RPS_RPS_HANDS.ROCK]: dino_rock,
                [E_RPS_RPS_HANDS.PAPER]: dino_paper,
                [E_RPS_RPS_HANDS.SCISSOR]: dino_scissor,
            },
            CYBORG: {
                [E_RPS_RPS_HANDS.ROCK]: cyborg_rock,
                [E_RPS_RPS_HANDS.PAPER]: cyborg_paper,
                [E_RPS_RPS_HANDS.SCISSOR]: cyborg_scissor,
            },
        },
        HAND: {
            [E_RPS_RPS_HANDS.ROCK]: rps_rock,
            [E_RPS_RPS_HANDS.PAPER]: rps_paper,
            [E_RPS_RPS_HANDS.SCISSOR]: rps_scissor,
        },
    },
    BLOOD_SPLATTER: blood,
    SKI_MASK: ski,
    beatdown_logo,
    gw_logo,
};

const ICONS = {
    arrow_left,
    menu_3_fill,
    close,
    crown_logo,
    iconex_logo,
    fire_log,
};
const DECORATOR = {
    DECORATOR_EDGES: Decorator,
    STYLISH_SEPERATOR: stylishSep,
    MODAL_DECORATOR_EDGES: ModalDecorator,
};
const MARKERS: Record<string, string> = {
    Airport,
    Governmental,
    Residential,
    Commercial,
    Health,
    Industrial,
    Recreational,
    Education,
    myLand,
    marker_rps,
    marker_beatdown,
    marker_mobRun,
};

export { ICONS, MARKERS, DECORATOR, SOCIALS, MINI_GAMES };
