import React from "react";
import { useEffect, useState } from "react";
import { MINTED_LANDS } from "../../../constants/mintedLands";
import BoxMarker from "../markers/Box.marker";
import { convertPointsToLatLng } from "../../../utils/cordinates.utils";
import L from "leaflet";
import { ZONE_COLOR, ZONE_ID_NAME } from "../../../constants/zones.lands";
import LandDetails from "../../modal/LandDetails.modal";
import { getLandMetaData, getMyLands } from "../../../services/data/data.service";
import { useWalletContext } from "../../../services/contexts/wallet.context";
import { MARKERS } from "../../../assets/assets";
const zone_color = {
  1: "#FF5733", // Orange
  2: "#33FFB2", // Turquoise
  3: "#FFC133", // Yellow
  4: "#3366FF", // Blue
  5: "#FF3397", // Pink
  6: "#42FF8A", // Green
  7: "#E033FF", // Purple
  8: "#33C4FF", // Blue
  9: "#FF5733", // Orange
};
interface IMintedLandLayer {
  selectedNFTIDLIST?: any;
  showZones: boolean;
}
function MyLandsLayer({ selectedLand, selectThisLand }: any) {
  const { isWalletConnected, walletAddress } = useWalletContext();
  const [selectedNFTIDLIST, setSelectedNFTIDList] = useState<Array<number>>([]);

  const setNFTID = async () => {
    if (isWalletConnected) {
      const res: Array<number> = await getMyLands(walletAddress);
      setSelectedNFTIDList(res);
      console.log(res);
    }
  };
  useEffect(() => {
    setNFTID();

    return () => {};
  }, []);

  return (
    <div>
      {MINTED_LANDS?.length >= 1
        ? MINTED_LANDS.map(({ x, y, owner, zoneId, name, metaId, nftId }: any, index) => {
            const xx = parseInt(x);
            const yy = parseInt(y);
            if (selectedNFTIDLIST.includes(nftId)) {
              const customMarker = new L.Icon({
                iconUrl: MARKERS.myLand,
                iconSize: [25, 27], // set the size of your custom marker
              });
              return (
                <BoxMarker
                  className={`minted_lands`}
                  marker={customMarker}
                  key={index}
                  onClick={async () => {
                    selectThisLand(nftId, x, y);
                  }}
                  pathOptions={{
                    weight: 1,
                    fill: true,
                    fillColor: selectedLand == `land_${x}_${y}` ? "red" : "#006900",
                    fillOpacity: 0.5,
                    color: selectedLand == `land_${x}_${y}` ? "red" : "#006900",
                  }}
                  rectangleBBox={[
                    [convertPointsToLatLng(xx, yy, 0).lat, convertPointsToLatLng(xx, yy, 0).lng],
                    [
                      convertPointsToLatLng(xx + 1, yy - 1).lat,
                      convertPointsToLatLng(xx + 1, yy - 1).lng,
                    ],
                  ]}
                />
              );
            }
          })
        : null}
    </div>
  );
}

export default MyLandsLayer;
