import { AiOutlineClose } from "react-icons/ai";
import PlainSeperator from "../decorators/plainSeperator";
import StylishModalDecor from "../decorators/StylishModalDecor";

const ModelElement = ({ children, setOpen, title, isViewer }: any) => {
  return (
    <div className='modal-elements'>
      <div className='modal-upper-elements'>
        <AiOutlineClose
          color='#aa9b46'
          onClick={() => {
            setOpen(false);
          }}
          size={25}
          className='close-modal-btn'
        />
        {!isViewer && (
          <div className='modal-title-div'>
            <h2 className='modal-heading-name'>{title}</h2>
            <PlainSeperator width={"90%"} />
          </div>
        )}
      </div>
      <div className='modal-children'>{children}</div>
    </div>
  );
};

const Modal = ({
  open,
  children,
  setOpen,
  className,
  title,
  isStylish = true,
  width,
  bgBlur = false,
  isViewer = false,
  isPacBet = false,
  isMiniGameModal = true,
}: any) => {
  if (!open) return null;
  return (
    <div
      className={`${
        (bgBlur || window.innerWidth <= 576 || isViewer) && !isMiniGameModal ? "blur overlay" : ""
      } `}>
      <div
        className={`${className} modal-wrapper ${isPacBet ? "isPacBet" : ""} ${
          (bgBlur || window.innerWidth <= 576 || isViewer) && !isMiniGameModal
            ? ""
            : "normal overlay"
        } ${isViewer && "viewer3d"}`}
        style={isPacBet ? { width: width, maxWidth: "742px" } : { width: width }}>
        <div className='modal'>
          {isStylish ? (
            <StylishModalDecor isViewer={isViewer} className={`${isViewer && "viewer3d"}`}>
              <ModelElement
                isViewer={isViewer}
                title={title}
                children={children}
                setOpen={setOpen}
              />
            </StylishModalDecor>
          ) : (
            <div className='normal-modal'>
              <ModelElement children={children} title={title} setOpen={setOpen} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
