import React, { useCallback, useRef } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};

export default function Confetti({ refAnimationInstance }: any) {
  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  return (
    <>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles as any} />
    </>
  );
}
