"use strict";
/*
 * Copyright 2023 ICON Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
const Converter_1 = require("../Converter");
class BTPNetworkTypeInfo {
    constructor(data) {
        this.networkTypeID = (0, Converter_1.toBigNumber)(data.networkTypeID);
        this.networkTypeName = data.networkTypeName;
        this.openNetworkIDs = (data.openNetworkIDs || []).map((openNetworkID) => (0, Converter_1.toBigNumber)(openNetworkID));
        this.nextProofContext = data.nextProofContext;
    }
}
exports.default = BTPNetworkTypeInfo;
