import React, { useEffect, useState } from "react";
import Modal from "./Modal.component";
import PropertyCarousel from "../propertyCarousel/propertyCarousel.component";
import PlainSeperator from "../decorators/plainSeperator";
import "react-tooltip/dist/react-tooltip.css";
import GangstaBetTooltip from "../tooltip/GangstaBet.tooltip";
import { getHighestGangstaBet } from "../../services/data/data.service";
import { MINI_GAMES } from "../../assets/assets";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../common/Button.component";
function sortByValueLength(arr: any): any {
    // Use the Array.sort() method to sort the array based on the length of the "value" key
    return arr.sort(
        (a: any, b: any) =>
            Math.max(a.key.length, a.value.length) - Math.max(b.key.length, b.value.length)
    );
}

function LandDetails({
    isOpen,
    setOpen,
    details,
    setSelectedLands,
    setOpen3DModel,
    setOpenRPS,
}: any) {
    const [gangstaBetDetail, setgangstaBetDetail] = useState<any>(null);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const setGangstaBet = async () => {
        const res = await getHighestGangstaBet(details.owner);
        setgangstaBetDetail(res);
    };
    useEffect(() => {
        if (details) setGangstaBet();

        return () => {};
    }, [details]);

    return (
        <Modal
            width="380px"
            title={`Emerald City Land #${details?.nftId}`}
            open={isOpen}
            setOpen={(e: any) => {
                setOpen(false);
                setSelectedLands(null);
                navigate("/");
            }}
        >
            <div className="land-details-wrapper">
                {details && (
                    <div className="property-profile-wrapper">
                        <div className="profile-left">
                            <PropertyCarousel
                                images={details.images}
                                previewImage={details.preview}
                                setOpen3DModel={setOpen3DModel}
                                // imageUrl={details.metadata.image_uri}
                                imageUrl={details.image_uri}
                            />
                            <PlainSeperator width="100%" />
                            <div className="owner_address">
                                Owned by:
                                <a
                                    target="_blank"
                                    href={`https://emeraldcity.gangstaverse.co/inventory/${details?.owner}`}
                                >
                                    {" "}
                                    {details?.owner.slice(0, 4)}...{details?.owner.slice(-4)}
                                </a>
                            </div>{" "}
                            {gangstaBetDetail && (
                                <div className=" owner_address gangstabet">
                                    Also owns GangstaBet:
                                    <a
                                        target="_blank"
                                        data-tooltip-id="my-tooltip-1"
                                        href={`https://gangstabet.io/profile/${gangstaBetDetail?.nftId}`}
                                    >
                                        {" "}
                                        {gangstaBetDetail?.name}
                                    </a>
                                </div>
                            )}
                            <GangstaBetTooltip
                                image={gangstaBetDetail?.image}
                                tooltipId={"my-tooltip-1"}
                            />
                            {details?.nftId == 35 && (
                                <div className="property_project">
                                    {/* <div className='property_project_title'> Hosted:</div> */}
                                    <div className="property_project_list">
                                        <div
                                            className="property_item"
                                            onClick={() => {
                                                // setSearchParams((param) => {
                                                //   param.set("project", "rps");
                                                //   return param;
                                                // });
                                            }}
                                        >
                                            <img src={MINI_GAMES.RPS.TWO_PLAYERS} />
                                            <div className="property_info">
                                                <div className="property_title">
                                                    Rock, Paper, Scissor(RPS)
                                                </div>
                                                <div className="property_desc">
                                                    By {details?.owner.slice(0, 4)}...
                                                    {details?.owner.slice(-4)}
                                                </div>
                                            </div>
                                            <Button
                                                onClick={() => {
                                                    console.log(details);
                                                    setOpenRPS(true);
                                                    navigate("/game/rps");
                                                }}
                                                isDisabled={false}
                                                name={"Play"}
                                                size={"medium"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}{" "}
                            {details?.nftId == 24 && (
                                <div className="property_project">
                                    {/* <div className='property_project_title'> Hosted:</div> */}
                                    <div className="property_project_list">
                                        <div
                                            className="property_item"
                                            onClick={() => {
                                                // setSearchParams((param) => {
                                                //   param.set("project", "rps");
                                                //   return param;
                                                // });
                                            }}
                                        >
                                            <img src={MINI_GAMES.gw_logo} />
                                            <div className="property_info">
                                                <div className="property_title">
                                                    GangWars: Mob Run{" "}
                                                </div>
                                                <div className="property_desc">
                                                    By {details?.owner.slice(0, 4)}...
                                                    {details?.owner.slice(-4)}
                                                </div>
                                            </div>
                                            <Button
                                                onClick={() => {
                                                    console.log(details);
                                                    // setOpenRPS(true);
                                                    window.open(
                                                        `https://war-base.gangstaverse.co/`,
                                                        "_blank"
                                                    );
                                                }}
                                                isDisabled={false}
                                                name={"Play"}
                                                size={"medium"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}{" "}
                            <div className="attribute_container">
                                <div className="attribute_container_inside">
                                    {" "}
                                    {details?.attributes
                                        ? sortByValueLength(details?.attributes).map(
                                              ({ key, value }: any, index: number) => {
                                                  return (
                                                      <div
                                                          key={index + 1}
                                                          className="attribute_item"
                                                          style={{}}
                                                      >
                                                          <div className="key">{key}</div>
                                                          <div className="value">{value}</div>
                                                      </div>
                                                  );
                                              }
                                          )
                                        : null}
                                </div>
                            </div>
                            <div className="view_on_cft">
                                <a
                                    target="_blank"
                                    href={`https://craft.network/nft/emerald-city-land:${details?.nftId}`}
                                >
                                    View on Craft
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        stroke-width="2"
                                        viewBox="0 0 24 24"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        height="1em"
                                        width="1em"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <desc></desc>
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <line x1="17" y1="7" x2="7" y2="17"></line>
                                        <polyline points="8 7 17 7 17 16"></polyline>
                                    </svg>
                                </a>
                            </div>{" "}
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default LandDetails;
