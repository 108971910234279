import React from "react";
import { useEffect, useState } from "react";
import { MINTED_LANDS } from "../../../constants/mintedLands";
import BoxMarker from "../markers/Box.marker";
import { convertPointsToLatLng } from "../../../utils/cordinates.utils";
import { ZONE_COLOR } from "../../../constants/zones.lands";
import LandDetails from "../../modal/LandDetails.modal";
import { getLandMetaData } from "../../../services/data/data.service";
const zone_color = {
  1: "#FF5733", // Orange
  2: "#33FFB2", // Turquoise
  3: "#FFC133", // Yellow
  4: "#3366FF", // Blue
  5: "#FF3397", // Pink
  6: "#42FF8A", // Green
  7: "#E033FF", // Purple
  8: "#33C4FF", // Blue
  9: "#FF5733", // Orange
};
interface IMintedLandLayer {
  selectedNFTIDLIST?: any;
  showZones: boolean;
}
function MintedLandsLayers({ selectedLand, selectThisLand }: any) {
  return (
    <div>
      {MINTED_LANDS?.length >= 1
        ? MINTED_LANDS.map(({ x, y, owner, zoneId, name, metaId, nftId }, index) => {
            const xx = parseInt(x);
            const yy = parseInt(y);

            return (
              <BoxMarker
                className={`minted_lands`}
                key={index}
                onClick={async () => {
                  selectThisLand(nftId, x, y);
                }}
                pathOptions={{
                  weight: 1,
                  fill: true,
                  fillColor: selectedLand == `land_${x}_${y}` ? "red" : "#DDBF62",
                  fillOpacity: 0.3,
                  color: selectedLand == `land_${x}_${y}` ? "red" : "black",
                }}
                rectangleBBox={[
                  [convertPointsToLatLng(xx, yy, 0).lat, convertPointsToLatLng(xx, yy, 0).lng],
                  [
                    convertPointsToLatLng(xx + 1, yy - 1).lat,
                    convertPointsToLatLng(xx + 1, yy - 1).lng,
                  ],
                ]}
              />
            );
          })
        : null}
    </div>
  );
}

export default MintedLandsLayers;
