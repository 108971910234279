import { createContext, useContext, useEffect, useRef, useState } from "react";

interface IRPSContext {
  isTransactionLoading: boolean;
  setIsTransactionLoading: React.Dispatch<React.SetStateAction<boolean>>;
  currentTxResult: any;
  setCurrentTxResult: React.Dispatch<React.SetStateAction<any>>;
  currentEventType: string | null;
  setCurrentEventType: React.Dispatch<React.SetStateAction<string | null>>;
  signStoreHash: any;
  setSignStoreHash: React.Dispatch<React.SetStateAction<any>>;
}

const RPSContext = createContext<IRPSContext>({
  isTransactionLoading: false,
  setIsTransactionLoading: () => {},
  currentTxResult: null,
  setCurrentTxResult: () => {},
  currentEventType: "",
  setCurrentEventType: () => {},
  signStoreHash: null,
  setSignStoreHash: () => {},
});

const IconEventProvider = ({ children }: any) => {
  const [isTransactionLoading, setIsTransactionLoading] = useState<boolean>(false);
  const [currentTxResult, setCurrentTxResult] = useState<number | null>(null);
  const [currentEventType, setCurrentEventType] = useState<string | null>(null);
  const [signStoreHash, setSignStoreHash] = useState<any | null>(null);
  useEffect(() => {
    console.log(`[Current Event Type updated]: ${currentEventType}`);
    return () => {};
  }, [currentEventType]);

  return (
    <RPSContext.Provider
      value={{
        isTransactionLoading,
        setIsTransactionLoading,
        currentTxResult,
        setCurrentTxResult,
        currentEventType,
        setCurrentEventType,
        signStoreHash,
        setSignStoreHash,
      }}>
      {children}
    </RPSContext.Provider>
  );
};

const useIconEventContext = () => {
  return useContext(RPSContext);
};

export { IconEventProvider, useIconEventContext };
