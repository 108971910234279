import React, { useState } from "react";
import ToggleSwitch from "../../common/ToggleSwitch.component";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { LANDS_CATEGORIES } from "../../../constants/filterLands";
import { sortStringsAlphabetically } from "../../../utils/helper";

const main_category = Object.keys(LANDS_CATEGORIES);
interface IDistrictsToggle {
  showZones: Record<number, boolean>;
  setShowZones: any;
  setToggleMarker: any;
  toggleMarker: boolean;
}

function FilterLandToggle({
  setShowZones,
  showZones,
  setToggleMarker,
  toggleMarker,
  selectedFilterSubCategory,
  setselectedFilterSubCategory,
}: any) {
  const [openAdvanceSettings, setopenAdvanceSettings] = useState<boolean>(false);
  const items = (SCIdenx: any, subCategoryName: any, categoryName: any): any => {
    return (
      <div
        key={SCIdenx}
        className={`category_item`}
        onClick={(e) => {
          let curr = selectedFilterSubCategory.split("#");
          let term = `${categoryName}&${subCategoryName}`;
          console.log(`Clicked on ${term}`);
          if (!curr.includes(term)) {
            curr.push(term);
            e.currentTarget.classList.add("selected");
          } else {
            curr = curr.filter((e: string) => e !== term);
            e.currentTarget.classList.remove("selected");
          }
          curr = curr.join("#");
          setselectedFilterSubCategory((prec: any) => {
            return curr;
          });
        }}>
        <div className='name'>{subCategoryName}</div>
        <div className='count'>
          {LANDS_CATEGORIES[categoryName as any][subCategoryName]?.length}
        </div>
      </div>
    );
  };
  return (
    <div className='toggle_component filter_land_toggle'>
      <div className='topBox'>
        <div className='infoBox'>
          <div className='title'>Filter Lands</div>
          <div className='subDesc'>Find specific things you're looking for</div>
        </div>
        <ToggleSwitch
          type={"large"}
          onChange={(e: any) => {
            if (!openAdvanceSettings == false) {
              setselectedFilterSubCategory("");
            }
            setopenAdvanceSettings(!openAdvanceSettings);
          }}
          isDisabled={false}
        />
      </div>

      <div className={`advance_setting ${openAdvanceSettings ? "" : "close"}`}>
        {Object.keys(LANDS_CATEGORIES).map((categoryName, CInder) => {
          return (
            <>
              <div key={`c_${CInder}`} className='category_box'>
                <div className='category_title'>{categoryName}</div>
                <div className='category_list'>
                  {sortStringsAlphabetically(
                    Object.keys(LANDS_CATEGORIES[categoryName as any])
                  ).map((subCategoryName, SCIdenx) => {
                    return items(SCIdenx, subCategoryName, categoryName);
                  })}{" "}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default FilterLandToggle;
