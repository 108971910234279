import React, { useState } from "react";
import L from "leaflet";
import {
  Polygon,
  Polyline,
  Tooltip,
  Marker,
  Popup,
  Pane,
  Rectangle,
  FeatureGroup,
  Circle,
} from "react-leaflet";
import MARKER from "../Marker.png";
import { MARKERS } from "../../../assets/assets";
interface IBoxMarket {
  pathOptions: any;
  rectangleBBox: any;
  onClick: any;
  className: string;
  marker?: any;
}
const BoxMarker = ({
  pathOptions,
  rectangleBBox,
  onClick,
  className,
  marker = false,
}: IBoxMarket) => {
  const center = L.polygon(rectangleBBox).getBounds().getCenter();

  return (
    <>
      <FeatureGroup pathOptions={pathOptions}>
        {marker && (
          <Marker
            key={`marker-`}
            position={{
              lat: center.lat,
              lng: center.lng,
            }}
            icon={marker}
            eventHandlers={{
              click: onClick,
            }}></Marker>
        )}
        <Rectangle
          className={`box_marker ${className}`}
          bounds={rectangleBBox}
          eventHandlers={{
            click: onClick,
          }}
        />
      </FeatureGroup>
    </>
  );
};

export default BoxMarker;
