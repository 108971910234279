import React, { useState } from "react";
import { E_RPS_RPS_HANDS, E_RPS_URL_QUERY_ID, ZERO_ADDRESS } from "../../../../../enums/RPS.enums";
import { useRPSContext } from "../../../../../services/contexts/rps.context";
import { useIconEventContext } from "../../../../../services/contexts/iconEvent.context";
import { useWalletContext } from "../../../../../services/contexts/wallet.context";
import { ICONS, MINI_GAMES } from "../../../../../assets/assets";
import { E_ICONEX_RPC_EVENT_TYPE } from "../../../../../hooks/useIconexHook";
import RPSHand from "../../common/RPS.hand";
import RPSContractServices from "../../../../../services/contract/rps.service";
import { useSearchParams } from "react-router-dom";
import ConnectWalletComponent from "../../common/Connect.wallet";
import RPSopenGame from "../../common/RPS.openGame";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { GiTiedScroll } from "react-icons/gi";

function RPSBattleField({ currentSelectedOpenGame, setcurrentSelectedOpenGame, isFrom }: any) {
  const [currentHandSelected, setcurrentHandSelected] = useState<E_RPS_RPS_HANDS | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { nftId, isOpeningGame, setIsOpeningGame } = useRPSContext();
  const {
    isTransactionLoading,
    setIsTransactionLoading,
    setCurrentEventType,
    setCurrentTxResult,
    currentEventType,
  } = useIconEventContext();
  const { walletAddress, isWalletConnected } = useWalletContext();

  return (
    <div>
      {" "}
      <div className={`open_game_battle_field ${currentSelectedOpenGame ? "game_selected" : ""}`}>
        {isOpeningGame && (
          <div className='open_rps_modal'>
            {isWalletConnected && (
              <>
                <RPSopenGame />
                {currentEventType == E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_SUCCESS && (
                  <div className='sucess_open_game'>
                    Game sucessfully opened, you can find it on My Games Tab.
                  </div>
                )}
              </>
            )}
            {!isWalletConnected && (
              <ConnectWalletComponent
                size={"small"}
                title='Wait, Before you host.'
                desc='Please connect your wallet.'
              />
            )}
          </div>
        )}

        <div className='you'>
          <p>You</p>
          <img
            src={
              nftId
                ? `https://d2l8dezwbqkb9.cloudfront.net/png/${nftId}.png`
                : MINI_GAMES.RPS.UNKNOWL_PLAYER
            }
          />
        </div>
        <div className='field'>
          {currentSelectedOpenGame ? (
            isTransactionLoading &&
            currentEventType == E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_PENDING ? (
              <div className='battle_loading'>
                <div className='you_hand rps_hand'>
                  <img src={MINI_GAMES.RPS.HAND[0]} alt='hand' className={`hand `} />
                </div>{" "}
                <img className='battle_vs' src={MINI_GAMES.RPS.VS_LOGO} />
                <div className='opponent_hand rps_hand'>
                  <img src={MINI_GAMES.RPS.HAND[0]} alt='hand' className={`hand `} />
                </div>
              </div>
            ) : (
              <div className='choose_your_moves'>
                {isWalletConnected ? (
                  <div className='mid'>
                    <div className='selected_wager'>
                      <img
                        src={
                          currentSelectedOpenGame.tokenAddress == ZERO_ADDRESS
                            ? ICONS.iconex_logo
                            : ICONS.crown_logo
                        }
                        className='currency_type'
                      />
                      <div>{currentSelectedOpenGame.amount}</div>
                    </div>
                    <div className='cym_title'>Choose your move</div>
                    <div className='hand_list_cym'>
                      <RPSHand
                        className='cym_hand'
                        currentHandSelected={currentHandSelected}
                        setcurrentHandSelected={setcurrentHandSelected}
                        type={E_RPS_RPS_HANDS.PAPER}
                      />{" "}
                      <RPSHand
                        className='cym_hand'
                        currentHandSelected={currentHandSelected}
                        setcurrentHandSelected={setcurrentHandSelected}
                        type={E_RPS_RPS_HANDS.ROCK}
                      />{" "}
                      <RPSHand
                        className='cym_hand'
                        currentHandSelected={currentHandSelected}
                        setcurrentHandSelected={setcurrentHandSelected}
                        type={E_RPS_RPS_HANDS.SCISSOR}
                      />
                    </div>
                    <div className='cym_buttom_cta'>
                      <div
                        className='cym_play'
                        onClick={async () => {
                          console.log(currentSelectedOpenGame);
                          console.log(currentHandSelected);
                          if (currentSelectedOpenGame && currentHandSelected != null) {
                            console.log(currentSelectedOpenGame);
                            setIsTransactionLoading(true);
                            setCurrentTxResult(null);
                            setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_PENDING);
                            const res = await RPSContractServices?.playRPS(
                              currentSelectedOpenGame.gameId,
                              walletAddress,
                              currentSelectedOpenGame.amount,
                              currentHandSelected,
                              currentSelectedOpenGame.tokenAddress
                            );
                          }
                        }}>
                        Play
                      </div>
                      <div
                        className='cym_chicken_out'
                        onClick={() => {
                          setcurrentSelectedOpenGame(false);
                          setSearchParams((params) => {
                            params.delete(E_RPS_URL_QUERY_ID.GAME_ID);
                            return params;
                          });
                        }}>
                        Chicken out
                      </div>
                    </div>
                  </div>
                ) : (
                  <ConnectWalletComponent
                    size={"small"}
                    title='Wait, Before you play.'
                    desc='Please connect your wallet.'
                  />
                )}
              </div>
            )
          ) : (
            <img className='cym_vs' src={MINI_GAMES.RPS.VS_LOGO} />
          )}
          {/*  */}
        </div>
        {currentSelectedOpenGame ? (
          <div className='opponent'>
            <p className='name_opponent'>
              {currentSelectedOpenGame
                ? (currentSelectedOpenGame?.name as string)?.replace("GangstaBet", "GB")
                : currentSelectedOpenGame?.name}
            </p>
            <img
              src={
                currentSelectedOpenGame.nftId
                  ? `https://d2l8dezwbqkb9.cloudfront.net/png/${currentSelectedOpenGame.nftId}.png`
                  : MINI_GAMES.RPS.UNKNOWL_PLAYER
              }
            />
            {currentSelectedOpenGame?.message.length >= 1 && (
              <div className='icons_play_section'>
                <div className='message_icon'>
                  <ReactTooltip
                    id={"message_tt_battle_field"}
                    place={"bottom"}
                    className='message_tt'>
                    <div className='message_container'>
                      <div className='paper'></div>
                      <p className='msg_text'>{currentSelectedOpenGame?.message}</p>
                      <svg>
                        <filter id='wavy2'>
                          <feTurbulence x='0' y='0' baseFrequency='0.02' numOctaves='5' seed='1' />
                          <feDisplacementMap in='SourceGraphic' scale='20' />
                        </filter>
                      </svg>
                    </div>
                  </ReactTooltip>
                  <GiTiedScroll
                    data-tooltip-id='message_tt_battle_field'
                    className='message_icon'
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className='empty_slot_player'>
            Click from below <br /> to begin playing
          </div>
        )}
      </div>
    </div>
  );
}

export default RPSBattleField;
