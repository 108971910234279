import React from "react";
import RPSLeaderBoard from "../leaderboard/LeaderBoard";

export default function RoundOver() {
    return (
        <div className="round_over">
            <div className="mid">
                <img src="https://d2l8dezwbqkb9.cloudfront.net/png/2041.png" />
                <div className="info">
                    <h2>Round is Over</h2>
                    <p>
                        Visit again in next 24 hours until DaVinci's Palace is ready for next round.
                        Your rewards will be claimable in next round.
                    </p>
                </div>
            </div>
            <RPSLeaderBoard />
        </div>
    );
}
