export const {
  REACT_APP_GANGSTABET_NODE_API,
  REACT_APP_ENCRYPTION_KEY,
  REACT_APP_RPS_MINI_GAME_SCORE,
  REACT_APP_CROWN_SCORE,
  REACT_APP_EMERALD_CITY_NODE_API,
  REACT_APP_NETWORK_URL,
  REACT_APP_NETWORD_NID,
  REACT_APP_NETWORK_API,
  REACT_APP_REWARD_DISTRIBUTION,
} = process.env;
