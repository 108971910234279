export const ZONE_COLOR: Record<number, string> = {
  1: "#18173a", // Orange
  2: "#3a3017", // Turquoise
  3: "#3e6211", // Yellow
  4: "#680808", // Blue
  5: "#33173a", // Pink
  6: "#173a31", // Green
  7: "#172b3a", // Purple
  8: "#3a1f17", // Blue
};
export const ZONE_ID_NAME: Record<number, string> = {
  1: "Recreational",
  2: "Airport",
  3: "Industrial",
  4: "Governmental",
  5: "Education",
  6: "Health",
  7: "Commercial",
  8: "Residential",
};
export const LAND_ZONE: Record<string, number> = {
  land_15_16: 1,
  land_48_21: 1,
  "land_80_-13": 1,
  "land_-3_35": 3,
  "land_-3_36": 3,
  "land_-4_30": 3,
  "land_-4_31": 3,
  "land_-4_33": 3,
  "land_-4_35": 3,
  "land_-4_36": 3,
  "land_-5_30": 3,
  "land_-5_31": 3,
  "land_-5_32": 3,
  "land_81_-13": 1,
  "land_-5_35": 3,
  "land_-6_30": 3,
  "land_-6_31": 3,
  "land_-6_32": 3,
  "land_-6_33": 3,
  "land_-7_30": 3,
  "land_-7_31": 3,
  "land_-7_32": 3,
  "land_-7_33": 3,
  "land_-7_34": 3,
  "land_81_-9": 1,
  "land_-7_35": 3,
  "land_-8_32": 3,
  "land_-8_33": 3,
  "land_-8_34": 3,
  "land_-8_35": 3,
  "land_-8_36": 3,
  "land_-9_30": 3,
  "land_-9_31": 3,
  "land_-9_33": 3,
  "land_-9_35": 3,
  "land_82_-13": 1,
  land_0_30: 3,
  land_0_31: 3,
  land_0_32: 3,
  land_0_33: 3,
  land_0_35: 3,
  land_1_30: 3,
  land_1_31: 3,
  land_1_32: 3,
  land_1_33: 3,
  land_2_30: 3,
  "land_83_-12": 1,
  land_2_31: 3,
  land_2_32: 3,
  land_2_33: 3,
  land_2_34: 3,
  land_2_35: 3,
  land_2_36: 3,
  land_3_30: 3,
  land_3_31: 3,
  land_3_32: 3,
  land_3_33: 3,
  "land_83_-2": 1,
  land_3_34: 3,
  land_3_35: 3,
  land_3_36: 3,
  land_4_30: 3,
  land_4_31: 3,
  land_4_32: 3,
  land_4_33: 3,
  land_4_35: 3,
  land_4_36: 3,
  "land_-32_-2": 3,
  "land_84_-12": 1,
  "land_-32_16": 3,
  "land_-32_17": 3,
  "land_-33_-2": 3,
  "land_-39_-1": 3,
  "land_-39_27": 3,
  "land_-39_28": 3,
  "land_-40_26": 3,
  "land_-40_30": 3,
  "land_-41_26": 3,
  "land_-43_22": 3,
  "land_-100_-6": 1,
  "land_-43_26": 3,
  "land_-44_22": 3,
  "land_-44_26": 3,
  "land_-45_12": 3,
  "land_-45_22": 3,
  "land_-45_23": 3,
  "land_-46_19": 3,
  "land_-46_22": 3,
  "land_-46_23": 3,
  "land_-47_18": 3,
  "land_-100_-7": 1,
  "land_-47_19": 3,
  "land_-47_20": 3,
  "land_-47_21": 3,
  "land_-47_22": 3,
  "land_-48_18": 3,
  "land_-48_19": 3,
  "land_-48_20": 3,
  "land_-49_19": 3,
  "land_-50_18": 3,
  "land_-50_19": 3,
  "land_-100_-9": 1,
  "land_-51_18": 3,
  "land_-51_19": 3,
  "land_-52_18": 3,
  "land_-52_19": 3,
  "land_-53_18": 3,
  "land_-53_19": 3,
  "land_-54_18": 3,
  "land_-54_19": 3,
  "land_-55_18": 3,
  "land_-55_19": 3,
  land_15_15: 1,
  "land_-101_-17": 1,
  "land_-56_18": 3,
  "land_-56_19": 3,
  "land_-56_5": 3,
  "land_-57_19": 3,
  "land_-58_18": 3,
  "land_-59_18": 3,
  "land_-59_19": 3,
  "land_-60_-1": 3,
  "land_-60_18": 3,
  "land_-60_19": 3,
  "land_-101_-18": 1,
  land_5_32: 3,
  land_6_32: 3,
  land_6_36: 3,
  land_7_27: 3,
  land_7_31: 3,
  land_7_32: 3,
  land_7_33: 3,
  land_8_30: 3,
  land_8_31: 3,
  land_8_32: 3,
  "land_-101_-19": 1,
  land_8_33: 3,
  land_8_34: 3,
  land_8_35: 3,
  land_8_36: 3,
  land_9_31: 3,
  land_9_32: 3,
  land_9_33: 3,
  land_9_34: 3,
  land_9_35: 3,
  land_9_36: 3,
  "land_-101_-20": 1,
  "land_-25_-11": 3,
  "land_-25_-14": 3,
  "land_-31_-13": 3,
  "land_-32_-16": 3,
  "land_-40_-13": 3,
  land_15_14: 4,
  land_18_21: 4,
  land_28_21: 4,
  land_36_17: 4,
  land_12_21: 4,
  "land_-101_7": 1,
  land_16_19: 4,
  land_17_10: 4,
  land_27_22: 4,
  land_33_11: 4,
  land_35_13: 4,
  land_39_13: 4,
  land_48_14: 4,
  land_48_16: 4,
  land_37_11: 4,
  land_44_12: 4,
  "land_-104_3": 1,
  land_48_27: 4,
  land_49_13: 4,
  land_51_14: 4,
  land_56_29: 4,
  land_14_15: 4,
  land_20_18: 4,
  land_22_13: 4,
  land_26_23: 4,
  land_28_19: 4,
  land_28_23: 4,
  "land_-105_-5": 1,
  land_33_24: 4,
  land_14_14: 4,
  land_18_20: 4,
  land_27_23: 4,
  land_28_18: 4,
  land_13_18: 4,
  land_37_10: 4,
  land_22_27: 4,
  land_48_13: 4,
  land_50_20: 4,
  "land_-105_3": 1,
  land_54_21: 4,
  land_32_29: 4,
  land_55_16: 4,
  land_56_14: 4,
  land_56_20: 4,
  land_36_15: 4,
  land_13_11: 4,
  land_14_17: 4,
  land_18_28: 4,
  land_23_22: 4,
  "land_-108_-7": 1,
  land_35_16: 4,
  land_36_22: 4,
  land_39_10: 4,
  land_50_18: 4,
  land_52_12: 4,
  land_54_11: 4,
  land_53_11: 4,
  land_13_13: 4,
  land_14_13: 4,
  land_15_20: 4,
  "land_-109_2": 1,
  land_15_21: 4,
  land_17_19: 4,
  land_24_21: 4,
  land_26_10: 4,
  land_30_20: 4,
  land_13_12: 4,
  land_19_10: 4,
  land_26_11: 4,
  land_26_24: 4,
  land_28_20: 4,
  land_31_23: 1,
  "land_-64_19": 1,
  land_29_19: 4,
  land_29_20: 4,
  land_30_19: 4,
  land_43_25: 4,
  land_48_19: 4,
  land_51_26: 4,
  land_55_17: 4,
  land_37_24: 4,
  land_39_12: 4,
  land_48_20: 4,
  "land_-64_20": 1,
  land_48_29: 4,
  land_49_14: 4,
  land_50_19: 4,
  land_51_18: 4,
  land_55_15: 4,
  land_36_14: 4,
  land_37_16: 4,
  land_48_28: 4,
  land_50_26: 4,
  land_52_13: 4,
  "land_-64_21": 1,
  land_53_10: 4,
  land_52_18: 4,
  land_53_22: 4,
  land_33_16: 4,
  land_45_29: 4,
  land_46_27: 4,
  land_49_19: 4,
  land_50_21: 4,
  land_50_27: 4,
  land_51_13: 4,
  "land_-70_12": 1,
  land_56_19: 4,
  land_56_17: 4,
  land_57_20: 4,
  land_57_21: 4,
  land_57_24: 4,
  "land_60_-11": 4,
  "land_60_-17": 4,
  "land_60_-9": 4,
  land_60_23: 4,
  "land_61_-1": 4,
  "land_-70_13": 1,
  land_61_0: 4,
  land_61_4: 4,
  "land_67_-1": 4,
  "land_69_-1": 4,
  "land_69_-2": 4,
  land_70_14: 4,
  land_72_26: 4,
  "land_73_-1": 4,
  "land_78_-10": 4,
  "land_81_-2": 4,
  "land_-70_14": 1,
  "land_83_-11": 4,
  "land_84_-2": 4,
  land_84_13: 4,
  "land_-100_-8": 4,
  "land_-102_-10": 4,
  "land_-104_2": 4,
  "land_-105_2": 4,
  "land_-108_-8": 4,
  "land_-82_14": 4,
  "land_-94_1": 4,
  "land_-70_15": 1,
  "land_-96_-12": 4,
  "land_-97_-13": 4,
  "land_-97_-17": 4,
  "land_-99_-14": 4,
  "land_-99_-18": 4,
  "land_30_-1": 4,
  "land_30_-11": 4,
  "land_30_-15": 4,
  "land_30_-16": 4,
  "land_30_-3": 4,
  "land_-70_16": 1,
  land_30_0: 4,
  land_30_3: 4,
  land_30_5: 4,
  land_30_8: 4,
  "land_31_-11": 4,
  "land_31_-2": 4,
  land_31_5: 4,
  "land_32_-17": 4,
  land_32_1: 4,
  land_32_6: 4,
  "land_-70_17": 1,
  "land_33_-13": 4,
  "land_33_-16": 4,
  land_33_0: 4,
  land_33_3: 4,
  land_33_4: 4,
  "land_34_-10": 4,
  "land_34_-6": 4,
  land_34_8: 4,
  "land_35_-19": 4,
  "land_35_-3": 4,
  "land_-70_18": 1,
  "land_35_-4": 4,
  "land_35_-5": 4,
  "land_35_-7": 4,
  land_35_8: 4,
  "land_36_-16": 4,
  land_36_6: 4,
  land_36_7: 4,
  "land_37_-14": 4,
  "land_38_-10": 4,
  land_38_7: 4,
  land_46_19: 1,
  "land_-74_10": 1,
  "land_39_-10": 4,
  "land_40_-5": 4,
  land_40_1: 4,
  land_40_3: 4,
  land_40_5: 4,
  "land_41_-1": 4,
  "land_41_-4": 4,
  "land_41_-6": 4,
  "land_41_-9": 4,
  land_41_1: 4,
  "land_-75_10": 1,
  "land_42_-6": 4,
  land_42_1: 4,
  land_42_2: 4,
  land_42_4: 4,
  "land_44_-10": 4,
  "land_44_-4": 4,
  "land_44_-9": 4,
  land_44_3: 4,
  "land_45_-3": 4,
  "land_45_-4": 4,
  "land_-76_10": 1,
  "land_45_-5": 4,
  "land_45_-6": 4,
  "land_45_-8": 4,
  "land_46_-5": 4,
  land_46_2: 4,
  land_46_3: 4,
  land_46_5: 4,
  "land_47_-1": 4,
  "land_47_-3": 4,
  land_47_3: 4,
  "land_-76_14": 1,
  "land_48_-1": 4,
  land_48_0: 4,
  land_49_2: 4,
  land_49_3: 4,
  "land_50_-5": 4,
  land_50_3: 4,
  land_50_7: 4,
  "land_51_-6": 4,
  land_51_2: 4,
  "land_52_-1": 4,
  "land_-77_10": 1,
  "land_53_-1": 4,
  land_53_5: 4,
  "land_54_-10": 4,
  "land_54_-3": 4,
  "land_54_-4": 4,
  "land_54_-6": 4,
  land_54_0: 4,
  land_54_2: 4,
  land_54_3: 4,
  "land_55_-1": 4,
  "land_-78_10": 1,
  "land_55_-9": 4,
  land_55_1: 4,
  land_55_2: 4,
  land_55_9: 4,
  "land_56_-3": 4,
  land_56_4: 4,
  "land_57_-2": 4,
  "land_57_-7": 4,
  land_57_0: 4,
  land_57_4: 4,
  "land_-80_10": 1,
  "land_58_-5": 4,
  "land_58_-6": 4,
  land_58_1: 4,
  land_58_3: 4,
  land_58_7: 4,
  "land_59_-1": 4,
  "land_59_-2": 4,
  "land_59_-9": 4,
  land_59_9: 4,
  "land_-14_5": 4,
  "land_-80_8": 1,
  "land_-17_-10": 4,
  "land_-17_-3": 4,
  "land_-19_3": 4,
  "land_-1_13": 4,
  "land_-20_4": 4,
  "land_-23_-6": 4,
  "land_-23_2": 4,
  "land_-25_-2": 4,
  "land_-25_-8": 4,
  "land_-25_1": 4,
  "land_-80_9": 1,
  "land_-25_6": 4,
  "land_-26_-2": 4,
  "land_-26_1": 4,
  "land_-27_-3": 4,
  "land_-28_-6": 4,
  "land_-2_10": 4,
  "land_-30_-5": 4,
  "land_-30_-9": 4,
  "land_-30_2": 4,
  "land_-30_6": 4,
  "land_-81_19": 1,
  "land_-5_13": 4,
  "land_-5_5": 4,
  "land_-6_8": 4,
  "land_-7_9": 4,
  "land_-8_11": 4,
  "land_-8_12": 4,
  "land_-8_3": 4,
  "land_-8_9": 4,
  land_0_10: 4,
  land_11_0: 4,
  land_47_20: 1,
  "land_-81_6": 1,
  land_12_9: 4,
  land_13_4: 4,
  land_15_3: 4,
  land_15_8: 4,
  land_16_1: 4,
  land_16_9: 4,
  land_17_1: 4,
  land_18_7: 4,
  "land_19_-1": 4,
  land_19_6: 4,
  "land_-82_6": 1,
  land_1_10: 4,
  land_1_11: 4,
  land_22_2: 4,
  "land_23_-9": 4,
  land_24_6: 4,
  "land_26_-2": 4,
  land_26_1: 4,
  land_26_6: 4,
  land_27_1: 4,
  land_6_2: 4,
  "land_-83_6": 1,
  "land_-12_22": 4,
  "land_-13_11": 4,
  "land_-13_19": 4,
  "land_-13_21": 4,
  "land_-13_23": 4,
  "land_-13_26": 4,
  "land_-13_27": 4,
  "land_-14_10": 4,
  "land_-14_12": 4,
  "land_-14_22": 4,
  "land_-85_6": 1,
  "land_-15_23": 4,
  "land_-15_28": 4,
  "land_-15_30": 4,
  "land_-16_30": 4,
  "land_-17_19": 4,
  "land_-17_20": 4,
  "land_-17_22": 4,
  "land_-17_26": 4,
  "land_-17_27": 4,
  "land_-17_28": 4,
  "land_-86_6": 1,
  "land_-17_6": 4,
  "land_-17_7": 4,
  "land_-18_15": 4,
  "land_-18_22": 4,
  "land_-18_25": 4,
  "land_-18_28": 4,
  "land_-18_29": 4,
  "land_-18_30": 4,
  "land_-19_13": 4,
  "land_-19_25": 4,
  "land_-87_15": 1,
  "land_-20_23": 4,
  "land_-20_24": 4,
  "land_-21_10": 4,
  "land_-21_11": 4,
  "land_-21_19": 4,
  "land_-21_23": 4,
  "land_-21_29": 4,
  "land_-21_30": 4,
  "land_-22_13": 4,
  "land_-22_19": 4,
  "land_-87_6": 1,
  "land_-22_21": 4,
  "land_-22_22": 4,
  "land_-22_24": 4,
  "land_-22_30": 4,
  "land_-23_21": 4,
  "land_-24_21": 4,
  "land_-24_28": 4,
  "land_-25_20": 4,
  "land_-25_28": 4,
  "land_-25_29": 4,
  "land_-88_15": 1,
  "land_-26_12": 4,
  "land_-27_13": 4,
  "land_-27_16": 4,
  "land_-27_20": 4,
  "land_-27_27": 4,
  "land_-28_10": 4,
  "land_-28_13": 4,
  "land_-28_16": 4,
  "land_-29_10": 4,
  "land_-29_11": 4,
  "land_-88_6": 1,
  "land_-29_12": 4,
  "land_-30_10": 4,
  "land_-7_17": 4,
  "land_-7_36": 4,
  "land_-8_18": 4,
  land_2_17: 4,
  land_3_28: 4,
  land_4_18: 4,
  land_4_19: 4,
  land_4_21: 4,
  "land_-89_15": 1,
  land_4_27: 4,
  land_4_29: 4,
  land_5_14: 4,
  land_5_20: 4,
  "land_-31_6": 4,
  "land_-33_-1": 4,
  "land_-33_20": 4,
  "land_-34_13": 4,
  "land_-34_23": 4,
  "land_-34_24": 4,
  land_13_17: 1,
  "land_-89_6": 1,
  "land_-35_15": 4,
  "land_-37_16": 4,
  "land_-37_24": 4,
  "land_-38_23": 4,
  "land_-40_-2": 4,
  "land_-40_1": 4,
  "land_-41_17": 4,
  "land_-42_-1": 4,
  "land_-42_1": 4,
  "land_-42_10": 4,
  "land_-90_-1": 1,
  "land_-43_12": 4,
  "land_-44_4": 4,
  "land_-46_15": 4,
  "land_-49_5": 4,
  "land_-53_14": 4,
  "land_-59_6": 4,
  "land_-60_12": 4,
  land_6_20: 4,
  land_6_24: 4,
  land_6_25: 4,
  "land_-90_-10": 1,
  land_6_29: 4,
  land_7_18: 4,
  land_7_25: 4,
  land_8_18: 4,
  land_8_20: 4,
  "land_-28_-13": 4,
  "land_-31_-3": 4,
  "land_-32_-11": 4,
  "land_-32_-12": 4,
  "land_-32_-13": 4,
  "land_-90_-11": 1,
  "land_-33_-6": 4,
  "land_-35_-5": 4,
  "land_-36_-18": 4,
  "land_-37_-7": 4,
  "land_-38_-13": 4,
  "land_-39_-6": 4,
  "land_-40_-10": 4,
  "land_-49_-6": 4,
  land_33_15: 5,
  land_13_24: 5,
  "land_-90_-13": 1,
  land_44_19: 5,
  land_45_21: 5,
  land_42_11: 5,
  land_44_24: 5,
  land_44_17: 5,
  land_50_14: 5,
  land_49_18: 5,
  land_53_13: 5,
  land_11_17: 5,
  land_13_23: 5,
  "land_-90_-14": 1,
  land_44_18: 5,
  land_45_23: 5,
  land_30_18: 5,
  land_55_29: 5,
  land_12_24: 5,
  land_33_14: 5,
  land_56_11: 5,
  land_13_25: 5,
  land_39_21: 5,
  land_45_18: 5,
  "land_-90_-15": 1,
  land_49_21: 5,
  land_51_22: 5,
  land_45_20: 5,
  land_56_13: 5,
  land_45_25: 5,
  land_45_19: 5,
  land_45_22: 5,
  land_46_28: 5,
  "land_60_-1": 5,
  land_61_3: 5,
  "land_-90_-16": 1,
  "land_63_-15": 5,
  "land_70_-1": 5,
  "land_-104_-2": 5,
  "land_-92_-9": 5,
  "land_-93_-17": 5,
  "land_-96_-9": 5,
  land_30_7: 5,
  "land_31_-19": 5,
  land_31_6: 5,
  "land_32_-11": 5,
  "land_-90_-17": 1,
  "land_32_-18": 5,
  "land_32_-19": 5,
  "land_36_-15": 5,
  "land_36_-8": 5,
  "land_36_-9": 5,
  "land_37_-16": 5,
  "land_37_-17": 5,
  "land_37_-2": 5,
  "land_37_-7": 5,
  "land_38_-4": 5,
  "land_-90_-18": 1,
  "land_38_-5": 5,
  "land_38_-6": 5,
  "land_38_-9": 5,
  "land_39_-4": 5,
  "land_39_-5": 5,
  "land_39_-6": 5,
  land_42_8: 5,
  land_43_3: 5,
  land_43_4: 5,
  land_44_4: 5,
  land_23_24: 1,
  "land_-90_-2": 1,
  land_44_5: 5,
  "land_47_-10": 5,
  "land_47_-7": 5,
  "land_47_-8": 5,
  "land_47_-9": 5,
  land_47_6: 5,
  land_47_7: 5,
  land_47_8: 5,
  land_49_6: 5,
  "land_58_-1": 5,
  "land_-90_-20": 1,
  "land_-10_6": 5,
  "land_-18_5": 5,
  "land_-2_11": 5,
  "land_-8_7": 5,
  "land_-8_8": 5,
  land_12_2: 5,
  "land_14_-1": 5,
  land_14_0: 5,
  "land_16_-2": 5,
  "land_-10_18": 5,
  "land_-90_-3": 1,
  "land_-11_19": 5,
  "land_-12_25": 5,
  "land_-15_24": 5,
  "land_-19_15": 5,
  "land_-21_26": 5,
  "land_-21_27": 5,
  "land_-23_20": 5,
  "land_-23_28": 5,
  "land_-24_20": 5,
  "land_-27_17": 5,
  "land_-90_-4": 1,
  "land_-27_23": 5,
  "land_-29_28": 5,
  "land_-30_28": 5,
  "land_-31_15": 5,
  "land_-33_12": 5,
  "land_-33_27": 5,
  "land_-33_5": 5,
  "land_-38_-2": 5,
  "land_-38_28": 5,
  "land_-38_6": 5,
  "land_-90_-5": 1,
  "land_-41_0": 5,
  "land_-41_10": 5,
  "land_-43_13": 5,
  "land_-43_6": 5,
  "land_-43_7": 5,
  "land_-44_17": 5,
  "land_-48_-2": 5,
  "land_-50_12": 5,
  "land_-50_3": 5,
  "land_-58_14": 5,
  "land_-90_-6": 1,
  "land_-59_10": 5,
  "land_-59_11": 5,
  "land_-59_2": 5,
  "land_-59_5": 5,
  "land_-60_-4": 5,
  "land_-60_10": 5,
  "land_-60_14": 5,
  "land_-60_3": 5,
  "land_-60_4": 5,
  "land_-60_5": 5,
  "land_-90_-7": 1,
  "land_-28_-16": 5,
  "land_-31_-12": 5,
  "land_-32_-4": 5,
  "land_-34_-16": 5,
  "land_-37_-5": 5,
  "land_-40_-4": 5,
  land_38_13: 6,
  land_16_10: 6,
  land_31_15: 6,
  land_46_26: 6,
  "land_-90_-8": 1,
  land_47_11: 6,
  land_48_12: 6,
  land_55_12: 6,
  land_53_12: 6,
  land_53_16: 6,
  land_47_26: 6,
  land_47_13: 6,
  land_11_23: 6,
  land_16_11: 6,
  land_37_25: 6,
  "land_-90_-9": 1,
  land_42_13: 6,
  land_43_24: 6,
  land_47_12: 6,
  land_28_22: 6,
  land_26_29: 6,
  land_28_28: 6,
  land_42_12: 6,
  land_50_17: 6,
  land_12_15: 6,
  land_15_18: 6,
  "land_-90_0": 1,
  land_40_12: 6,
  land_43_23: 6,
  land_47_28: 6,
  land_41_12: 6,
  land_41_13: 6,
  land_45_28: 6,
  land_50_12: 6,
  land_47_10: 6,
  land_57_22: 6,
  land_58_26: 6,
  land_24_24: 1,
  "land_-90_1": 1,
  land_57_23: 6,
  "land_60_-6": 6,
  "land_65_-18": 6,
  "land_65_-19": 6,
  "land_66_-11": 6,
  "land_66_-12": 6,
  "land_66_-18": 6,
  "land_66_-19": 6,
  "land_67_-19": 6,
  "land_68_-19": 6,
  "land_-90_15": 1,
  "land_72_-12": 6,
  "land_84_-1": 6,
  "land_-103_-15": 6,
  "land_-108_2": 6,
  "land_-65_18": 6,
  "land_-86_16": 6,
  "land_-95_-19": 6,
  "land_-98_6": 6,
  "land_30_-19": 6,
  land_31_9: 6,
  "land_-90_2": 1,
  land_33_6: 6,
  land_33_7: 6,
  land_34_2: 6,
  land_34_3: 6,
  land_34_4: 6,
  land_34_5: 6,
  "land_37_-3": 6,
  land_37_6: 6,
  land_37_7: 6,
  "land_40_-6": 6,
  "land_-90_3": 1,
  "land_41_-10": 6,
  land_42_9: 6,
  land_43_9: 6,
  "land_48_-7": 6,
  "land_49_-7": 6,
  land_50_4: 6,
  "land_51_-3": 6,
  "land_51_-4": 6,
  "land_52_-6": 6,
  "land_53_-2": 6,
  "land_-90_4": 1,
  land_53_1: 6,
  land_53_6: 6,
  land_53_7: 6,
  land_53_8: 6,
  land_53_9: 6,
  land_54_7: 6,
  land_57_1: 6,
  land_57_5: 6,
  "land_58_-4": 6,
  land_58_5: 6,
  "land_-90_5": 1,
  land_58_9: 6,
  "land_59_-5": 6,
  land_59_5: 6,
  "land_-10_13": 6,
  "land_-15_2": 6,
  "land_-16_5": 6,
  "land_-5_10": 6,
  "land_-6_13": 6,
  "land_-6_5": 6,
  "land_-9_13": 6,
  "land_-90_6": 1,
  land_11_7: 6,
  land_11_8: 6,
  land_11_9: 6,
  land_13_8: 6,
  land_14_8: 6,
  land_19_2: 6,
  land_6_13: 6,
  "land_-11_8": 6,
  "land_-12_6": 6,
  "land_-13_25": 6,
  "land_-91_-19": 1,
  "land_-13_7": 6,
  "land_-14_20": 6,
  "land_-14_23": 6,
  "land_-14_26": 6,
  "land_-15_16": 6,
  "land_-15_25": 6,
  "land_-16_18": 6,
  "land_-16_6": 6,
  "land_-17_29": 6,
  "land_-17_8": 6,
  "land_-91_12": 1,
  "land_-19_10": 6,
  "land_-20_10": 6,
  "land_-25_12": 6,
  "land_-26_19": 6,
  "land_-26_28": 6,
  "land_-27_12": 6,
  "land_-28_11": 6,
  "land_-28_12": 6,
  "land_-3_14": 6,
  "land_-3_29": 6,
  "land_-92_-6": 1,
  land_2_26: 6,
  land_3_19: 6,
  land_4_14: 6,
  land_4_16: 6,
  land_5_16: 6,
  "land_-31_23": 6,
  "land_-31_25": 6,
  "land_-32_25": 6,
  "land_-33_19": 6,
  "land_-33_21": 6,
  land_46_21: 1,
  "land_-93_-23": 1,
  "land_-33_26": 6,
  "land_-33_4": 6,
  "land_-36_-1": 6,
  "land_-36_10": 6,
  "land_-37_15": 6,
  "land_-38_16": 6,
  "land_-38_29": 6,
  "land_-42_9": 6,
  "land_-43_25": 6,
  "land_-44_13": 6,
  "land_-93_-24": 1,
  "land_-49_-1": 6,
  "land_-49_11": 6,
  "land_-56_14": 6,
  "land_-59_13": 6,
  "land_-59_15": 6,
  land_5_22: 6,
  land_5_23: 6,
  land_5_24: 6,
  "land_-36_-9": 6,
  "land_-37_-13": 6,
  "land_-93_-26": 1,
  "land_-39_-5": 6,
  "land_-39_-9": 6,
  "land_-43_-4": 6,
  "land_-49_-9": 6,
  land_10_19: 7,
  land_11_13: 7,
  land_11_16: 7,
  land_11_18: 7,
  land_12_13: 7,
  land_14_19: 7,
  "land_-93_-27": 1,
  land_14_12: 7,
  land_18_10: 7,
  land_22_20: 7,
  land_23_20: 7,
  land_27_25: 7,
  land_29_22: 7,
  land_29_29: 7,
  land_30_27: 7,
  land_31_17: 7,
  land_32_10: 7,
  "land_-93_12": 1,
  land_33_12: 7,
  land_29_27: 7,
  land_34_21: 7,
  land_36_16: 7,
  land_37_20: 7,
  land_36_20: 7,
  land_11_29: 7,
  land_12_16: 7,
  land_12_17: 7,
  land_14_11: 7,
  "land_-94_-16": 1,
  land_18_19: 7,
  land_23_21: 7,
  land_25_27: 7,
  land_26_25: 7,
  land_26_30: 7,
  land_29_17: 7,
  land_29_26: 7,
  land_31_11: 7,
  land_32_20: 7,
  land_32_26: 7,
  "land_-94_-23": 1,
  land_33_21: 7,
  land_35_14: 7,
  land_37_14: 7,
  land_38_12: 7,
  land_41_28: 7,
  land_43_15: 7,
  land_44_13: 7,
  land_44_11: 7,
  land_44_28: 7,
  land_39_25: 7,
  "land_-94_-24": 1,
  land_51_23: 7,
  land_52_28: 7,
  land_52_29: 7,
  land_54_18: 7,
  land_54_17: 7,
  land_55_24: 7,
  land_55_28: 7,
  land_56_24: 7,
  land_56_18: 7,
  land_36_25: 7,
  "land_-94_12": 1,
  land_36_13: 7,
  land_39_15: 7,
  land_40_15: 7,
  land_40_27: 7,
  land_43_12: 7,
  land_44_20: 7,
  land_47_19: 7,
  land_49_20: 7,
  land_49_29: 7,
  land_50_15: 7,
  "land_-94_6": 1,
  land_50_22: 7,
  land_51_17: 7,
  land_52_27: 7,
  land_55_10: 7,
  land_55_23: 7,
  land_11_21: 7,
  land_12_25: 7,
  land_13_19: 7,
  land_14_18: 7,
  land_14_10: 7,
  land_47_21: 1,
  "land_-95_-23": 1,
  land_17_13: 7,
  land_18_18: 7,
  land_20_21: 7,
  land_22_21: 7,
  land_23_17: 7,
  land_22_30: 7,
  land_25_18: 7,
  land_26_18: 7,
  land_28_26: 7,
  land_28_27: 7,
  "land_-95_-24": 1,
  land_29_21: 7,
  land_31_19: 7,
  land_31_28: 7,
  land_34_15: 7,
  land_12_18: 7,
  land_12_19: 7,
  land_12_34: 7,
  land_14_21: 7,
  land_16_18: 7,
  land_16_25: 7,
  "land_-95_11": 1,
  land_17_20: 7,
  land_17_25: 7,
  land_18_12: 7,
  land_18_26: 7,
  land_22_19: 7,
  land_23_18: 7,
  land_25_19: 7,
  land_27_24: 7,
  land_28_14: 7,
  land_10_20: 7,
  "land_-96_-16": 1,
  land_33_20: 7,
  land_12_20: 7,
  land_13_10: 7,
  land_34_20: 7,
  land_35_10: 7,
  land_12_26: 7,
  land_36_24: 7,
  land_37_18: 7,
  land_15_17: 7,
  land_15_19: 7,
  "land_-96_11": 1,
  land_13_34: 7,
  land_16_21: 7,
  land_40_13: 7,
  land_33_22: 7,
  land_16_20: 7,
  land_17_18: 7,
  land_41_26: 7,
  land_43_17: 7,
  land_22_18: 7,
  land_22_29: 7,
  "land_-97_-24": 1,
  land_44_14: 7,
  land_25_20: 7,
  land_48_23: 7,
  land_50_24: 7,
  land_28_25: 7,
  land_50_16: 7,
  land_50_13: 7,
  land_28_17: 7,
  land_28_16: 7,
  land_31_18: 7,
  "land_-97_0": 1,
  land_31_29: 7,
  land_52_23: 7,
  land_55_19: 7,
  land_32_11: 7,
  land_55_18: 7,
  land_32_25: 7,
  land_33_18: 7,
  land_34_13: 7,
  land_34_18: 7,
  land_56_21: 7,
  "land_-97_11": 1,
  land_36_11: 7,
  land_57_18: 7,
  land_10_18: 7,
  land_10_21: 7,
  land_11_12: 7,
  land_12_10: 7,
  land_11_20: 7,
  land_11_31: 7,
  land_12_30: 7,
  land_13_20: 7,
  "land_-98_-23": 1,
  land_13_21: 7,
  land_14_20: 7,
  land_13_32: 7,
  land_19_13: 7,
  land_15_28: 7,
  land_20_15: 7,
  land_21_30: 7,
  land_21_29: 7,
  land_24_19: 7,
  land_25_22: 7,
  "land_-98_-24": 1,
  land_24_22: 7,
  land_25_25: 7,
  land_30_21: 7,
  land_30_23: 7,
  land_31_20: 7,
  land_30_17: 7,
  land_30_14: 7,
  land_31_26: 7,
  land_32_18: 7,
  land_35_15: 7,
  land_13_14: 1,
  land_48_24: 1,
  "land_-98_11": 1,
  land_36_23: 7,
  land_37_17: 7,
  land_39_11: 7,
  land_37_21: 7,
  land_39_22: 7,
  land_40_29: 7,
  land_42_19: 7,
  land_41_14: 7,
  land_42_10: 7,
  land_43_18: 7,
  "land_-99_-19": 1,
  land_44_16: 7,
  land_44_15: 7,
  land_48_10: 7,
  land_48_25: 7,
  land_48_17: 7,
  land_49_12: 7,
  land_51_11: 7,
  land_52_14: 7,
  land_54_16: 7,
  land_54_15: 7,
  "land_-99_-20": 1,
  land_56_16: 7,
  land_56_26: 7,
  land_11_19: 7,
  land_11_14: 7,
  land_13_31: 7,
  land_16_22: 7,
  land_17_21: 7,
  land_18_27: 7,
  land_19_21: 7,
  land_21_14: 7,
  "land_-99_-7": 1,
  land_21_21: 7,
  land_25_21: 7,
  land_31_10: 7,
  land_30_29: 7,
  land_31_25: 7,
  land_31_14: 7,
  land_32_13: 7,
  land_32_21: 7,
  land_33_19: 7,
  land_33_13: 7,
  "land_-99_-8": 1,
  land_10_29: 7,
  land_11_15: 7,
  land_11_10: 7,
  land_11_11: 7,
  land_15_10: 7,
  land_20_19: 7,
  land_20_14: 7,
  land_23_29: 7,
  land_24_18: 7,
  land_26_13: 7,
  "land_-99_-9": 1,
  land_26_22: 7,
  land_29_24: 7,
  land_31_21: 7,
  land_32_12: 7,
  land_32_16: 7,
  land_33_17: 7,
  land_33_23: 7,
  land_34_19: 7,
  land_34_24: 7,
  land_35_20: 7,
  "land_-99_7": 1,
  land_37_13: 7,
  land_37_12: 7,
  land_36_12: 7,
  land_38_11: 7,
  land_38_14: 7,
  land_42_30: 7,
  land_42_22: 7,
  land_42_29: 7,
  land_44_27: 7,
  land_46_29: 7,
  "land_32_-1": 1,
  land_47_29: 7,
  land_49_28: 7,
  land_50_25: 7,
  land_51_10: 7,
  land_51_12: 7,
  land_51_19: 7,
  land_51_21: 7,
  land_55_13: 7,
  land_53_17: 7,
  land_56_23: 7,
  land_32_0: 1,
  land_56_28: 7,
  land_36_10: 7,
  land_38_29: 7,
  land_39_20: 7,
  land_40_22: 7,
  land_41_10: 7,
  land_41_29: 7,
  land_42_18: 7,
  land_44_21: 7,
  land_44_29: 7,
  land_35_5: 1,
  land_48_18: 7,
  land_49_16: 7,
  land_49_11: 7,
  land_50_11: 7,
  land_54_12: 7,
  land_52_26: 7,
  land_56_10: 7,
  land_56_12: 7,
  land_55_20: 7,
  land_56_27: 7,
  land_38_27: 1,
  "land_39_-19": 1,
  land_35_17: 7,
  land_35_21: 7,
  land_37_23: 7,
  land_38_10: 7,
  land_38_16: 7,
  land_40_14: 7,
  land_43_13: 7,
  land_43_14: 7,
  land_48_22: 7,
  land_49_25: 7,
  "land_45_-10": 1,
  land_49_27: 7,
  land_50_28: 7,
  land_49_15: 7,
  land_50_23: 7,
  land_51_20: 7,
  land_51_16: 7,
  land_54_14: 7,
  land_55_21: 7,
  land_55_25: 7,
  land_56_15: 7,
  "land_-11_5": 1,
  land_29_18: 7,
  land_30_26: 7,
  land_31_16: 7,
  land_32_14: 7,
  land_29_28: 7,
  land_35_12: 7,
  land_37_19: 7,
  land_34_17: 7,
  land_39_26: 7,
  land_41_11: 7,
  "land_-21_-1": 1,
  land_42_25: 7,
  land_41_15: 7,
  land_43_22: 7,
  land_47_27: 7,
  land_50_29: 7,
  land_52_16: 7,
  land_55_14: 7,
  land_55_22: 7,
  land_57_19: 7,
  land_58_24: 7,
  "land_-21_-10": 1,
  land_57_26: 7,
  land_58_23: 7,
  land_51_27: 7,
  land_58_22: 7,
  land_57_27: 7,
  land_58_21: 7,
  land_54_10: 7,
  land_58_25: 7,
  land_58_18: 7,
  land_58_19: 7,
  "land_-21_-2": 1,
  "land_60_-10": 7,
  "land_60_-12": 7,
  "land_60_-16": 7,
  "land_60_-18": 7,
  "land_60_-19": 7,
  "land_60_-2": 7,
  "land_60_-3": 7,
  "land_60_-4": 7,
  "land_60_-5": 7,
  "land_60_-7": 7,
  "land_-21_-3": 1,
  "land_60_-8": 7,
  land_60_1: 7,
  land_60_18: 7,
  land_60_19: 7,
  land_60_20: 7,
  land_60_21: 7,
  land_60_22: 7,
  land_60_24: 7,
  land_60_25: 7,
  land_60_26: 7,
  "land_-21_-4": 1,
  land_60_27: 7,
  land_60_29: 7,
  land_60_5: 7,
  land_60_7: 7,
  "land_61_-12": 7,
  "land_61_-14": 7,
  "land_61_-2": 7,
  "land_61_-3": 7,
  "land_61_-4": 7,
  "land_61_-5": 7,
  "land_-21_-5": 1,
  "land_61_-6": 7,
  "land_61_-8": 7,
  "land_61_-9": 7,
  land_61_1: 7,
  land_61_14: 7,
  land_61_16: 7,
  land_61_22: 7,
  land_61_23: 7,
  land_61_24: 7,
  land_61_25: 7,
  "land_-21_-6": 1,
  land_61_26: 7,
  land_61_27: 7,
  land_61_28: 7,
  land_61_29: 7,
  land_61_5: 7,
  "land_62_-1": 7,
  "land_62_-11": 7,
  "land_62_-12": 7,
  "land_62_-14": 7,
  "land_62_-15": 7,
  land_46_20: 1,
  "land_-21_-7": 1,
  "land_62_-2": 7,
  "land_62_-3": 7,
  "land_62_-4": 7,
  "land_62_-5": 7,
  "land_62_-6": 7,
  land_62_0: 7,
  land_62_1: 7,
  land_62_22: 7,
  land_62_23: 7,
  land_62_24: 7,
  "land_-21_-8": 1,
  "land_63_-1": 7,
  "land_63_-11": 7,
  "land_63_-2": 7,
  "land_63_-3": 7,
  "land_63_-4": 7,
  "land_63_-5": 7,
  "land_63_-6": 7,
  land_63_0: 7,
  land_63_1: 7,
  land_63_2: 7,
  "land_-21_-9": 1,
  land_63_21: 7,
  land_63_3: 7,
  land_63_4: 7,
  land_63_5: 7,
  "land_64_-15": 7,
  "land_65_-11": 7,
  "land_65_-12": 7,
  "land_65_-15": 7,
  "land_66_-15": 7,
  "land_67_-11": 7,
  "land_-21_0": 1,
  "land_67_-12": 7,
  "land_67_-2": 7,
  "land_68_-1": 7,
  "land_68_-11": 7,
  "land_68_-12": 7,
  "land_69_-15": 7,
  "land_70_-11": 7,
  "land_70_-12": 7,
  land_70_13: 7,
  land_70_15: 7,
  "land_-21_1": 1,
  "land_71_-1": 7,
  "land_71_-11": 7,
  "land_71_-12": 7,
  "land_71_-2": 7,
  land_71_25: 7,
  "land_72_-1": 7,
  "land_72_-11": 7,
  "land_72_-2": 7,
  land_73_15: 7,
  "land_74_-1": 7,
  "land_-21_2": 1,
  "land_74_-2": 7,
  "land_77_-13": 7,
  "land_79_-14": 7,
  "land_79_-2": 7,
  "land_80_-1": 7,
  "land_80_-12": 7,
  "land_80_-2": 7,
  "land_81_-1": 7,
  "land_81_-11": 7,
  "land_81_-12": 7,
  "land_-21_3": 1,
  "land_81_-14": 7,
  "land_82_-1": 7,
  "land_82_-12": 7,
  "land_82_-2": 7,
  land_82_12: 7,
  land_82_26: 7,
  land_82_27: 7,
  "land_83_-1": 7,
  land_83_27: 7,
  land_83_28: 7,
  "land_-21_4": 1,
  land_84_26: 7,
  land_84_27: 7,
  "land_85_-1": 7,
  "land_85_-9": 7,
  "land_86_-1": 7,
  "land_86_-8": 7,
  land_87_26: 7,
  land_88_7: 7,
  "land_-100_-1": 7,
  "land_-100_-12": 7,
  "land_-21_6": 1,
  "land_-100_-13": 7,
  "land_-100_-19": 7,
  "land_-100_-2": 7,
  "land_-100_-20": 7,
  "land_-100_2": 7,
  "land_-100_7": 7,
  "land_-101_-12": 7,
  "land_-101_-13": 7,
  "land_-101_-15": 7,
  "land_-101_-16": 7,
  "land_-21_7": 1,
  "land_-101_-6": 7,
  "land_-101_-7": 7,
  "land_-102_-13": 7,
  "land_-102_-15": 7,
  "land_-102_-16": 7,
  "land_-103_-10": 7,
  "land_-103_-12": 7,
  "land_-103_-16": 7,
  "land_-104_-11": 7,
  "land_-104_-12": 7,
  land_57_25: 1,
  "land_-21_8": 1,
  "land_-105_-11": 7,
  "land_-105_-12": 7,
  "land_-106_-11": 7,
  "land_-106_2": 7,
  "land_-106_3": 7,
  "land_-107_-2": 7,
  "land_-107_2": 7,
  "land_-107_3": 7,
  "land_-109_-6": 7,
  "land_-109_-7": 7,
  "land_-21_9": 1,
  "land_-109_-8": 7,
  "land_-64_18": 7,
  "land_-66_18": 7,
  "land_-67_18": 7,
  "land_-68_18": 7,
  "land_-69_18": 7,
  "land_-70_10": 7,
  "land_-70_11": 7,
  "land_-71_10": 7,
  "land_-72_10": 7,
  "land_-22_-10": 1,
  "land_-73_10": 7,
  "land_-74_14": 7,
  "land_-78_11": 7,
  "land_-79_15": 7,
  "land_-82_19": 7,
  "land_-83_16": 7,
  "land_-84_16": 7,
  "land_-85_16": 7,
  "land_-86_11": 7,
  "land_-90_-12": 7,
  "land_-22_-2": 1,
  "land_-90_11": 7,
  "land_-91_-11": 7,
  "land_-91_-12": 7,
  "land_-91_-13": 7,
  "land_-91_-14": 7,
  "land_-91_-7": 7,
  "land_-92_-10": 7,
  "land_-92_-12": 7,
  "land_-92_-13": 7,
  "land_-92_-14": 7,
  "land_-22_-3": 1,
  "land_-92_-15": 7,
  "land_-92_-18": 7,
  "land_-92_-3": 7,
  "land_-92_-7": 7,
  "land_-92_-8": 7,
  "land_-92_12": 7,
  "land_-92_6": 7,
  "land_-93_-10": 7,
  "land_-93_-11": 7,
  "land_-93_-12": 7,
  "land_-22_-4": 1,
  "land_-93_-13": 7,
  "land_-93_-14": 7,
  "land_-93_-15": 7,
  "land_-93_-19": 7,
  "land_-93_-20": 7,
  "land_-93_-21": 7,
  "land_-93_-22": 7,
  "land_-93_-25": 7,
  "land_-93_-7": 7,
  "land_-93_-8": 7,
  "land_-22_-6": 1,
  "land_-93_-9": 7,
  "land_-93_2": 7,
  "land_-94_-10": 7,
  "land_-94_-11": 7,
  "land_-94_-12": 7,
  "land_-94_-14": 7,
  "land_-94_-15": 7,
  "land_-94_-17": 7,
  "land_-94_-18": 7,
  "land_-94_-7": 7,
  "land_-22_-8": 1,
  "land_-94_-9": 7,
  "land_-95_-10": 7,
  "land_-95_-11": 7,
  "land_-95_-13": 7,
  "land_-95_-14": 7,
  "land_-95_-15": 7,
  "land_-95_-16": 7,
  "land_-95_-17": 7,
  "land_-95_-7": 7,
  "land_-95_-8": 7,
  "land_-22_-9": 1,
  "land_-95_-9": 7,
  "land_-96_-10": 7,
  "land_-96_-11": 7,
  "land_-96_-13": 7,
  "land_-96_-14": 7,
  "land_-96_-15": 7,
  "land_-96_-17": 7,
  "land_-96_-7": 7,
  "land_-96_-8": 7,
  "land_-97_-10": 7,
  "land_-22_0": 1,
  "land_-97_-12": 7,
  "land_-97_-14": 7,
  "land_-97_-23": 7,
  "land_-97_-7": 7,
  "land_-97_-8": 7,
  "land_-97_-9": 7,
  "land_-97_2": 7,
  "land_-98_-10": 7,
  "land_-98_-11": 7,
  "land_-98_-12": 7,
  land_47_18: 1,
  "land_-22_1": 1,
  "land_-98_-13": 7,
  "land_-98_-14": 7,
  "land_-98_-22": 7,
  "land_-98_-7": 7,
  "land_-98_-8": 7,
  "land_-98_-9": 7,
  "land_-99_-11": 7,
  "land_-99_-12": 7,
  "land_-99_-13": 7,
  "land_-99_-5": 7,
  "land_-22_2": 1,
  "land_-99_1": 7,
  "land_30_-10": 7,
  "land_30_-12": 7,
  "land_30_-13": 7,
  "land_30_-14": 7,
  "land_30_-17": 7,
  "land_30_-18": 7,
  "land_30_-2": 7,
  "land_30_-4": 7,
  "land_30_-5": 7,
  "land_-22_3": 1,
  "land_30_-6": 7,
  "land_30_-8": 7,
  "land_30_-9": 7,
  land_30_1: 7,
  land_30_2: 7,
  land_30_4: 7,
  land_30_6: 7,
  land_30_9: 7,
  "land_31_-1": 7,
  "land_31_-10": 7,
  "land_-22_5": 1,
  "land_31_-12": 7,
  "land_31_-13": 7,
  "land_31_-14": 7,
  "land_31_-16": 7,
  "land_31_-17": 7,
  "land_31_-18": 7,
  "land_31_-4": 7,
  "land_31_-5": 7,
  "land_31_-6": 7,
  "land_31_-7": 7,
  "land_-22_7": 1,
  "land_31_-8": 7,
  "land_31_-9": 7,
  land_31_0: 7,
  land_31_2: 7,
  land_31_3: 7,
  land_31_4: 7,
  land_31_7: 7,
  land_31_8: 7,
  "land_32_-10": 7,
  "land_32_-12": 7,
  "land_-22_8": 1,
  "land_32_-13": 7,
  "land_32_-15": 7,
  "land_32_-16": 7,
  "land_32_-2": 7,
  "land_32_-3": 7,
  "land_32_-4": 7,
  "land_32_-6": 7,
  "land_32_-7": 7,
  "land_32_-8": 7,
  "land_32_-9": 7,
  "land_-22_9": 1,
  land_32_2: 7,
  land_32_3: 7,
  land_32_4: 7,
  land_32_5: 7,
  land_32_7: 7,
  land_32_8: 7,
  land_32_9: 7,
  "land_33_-1": 7,
  "land_33_-10": 7,
  "land_33_-11": 7,
  "land_-23_-10": 1,
  "land_33_-12": 7,
  "land_33_-14": 7,
  "land_33_-15": 7,
  "land_33_-17": 7,
  "land_33_-18": 7,
  "land_33_-19": 7,
  "land_33_-2": 7,
  "land_33_-3": 7,
  "land_33_-4": 7,
  "land_33_-5": 7,
  "land_-23_-2": 1,
  "land_33_-6": 7,
  "land_33_-7": 7,
  "land_33_-8": 7,
  "land_33_-9": 7,
  land_33_1: 7,
  land_33_2: 7,
  land_33_5: 7,
  land_33_9: 7,
  "land_34_-13": 7,
  "land_34_-14": 7,
  "land_-23_-3": 1,
  "land_34_-15": 7,
  "land_34_-16": 7,
  "land_34_-17": 7,
  "land_34_-18": 7,
  "land_34_-2": 7,
  "land_34_-3": 7,
  "land_34_-4": 7,
  "land_34_-5": 7,
  "land_34_-7": 7,
  "land_34_-8": 7,
  land_52_25: 1,
  "land_-23_-4": 1,
  "land_34_-9": 7,
  land_34_0: 7,
  land_34_1: 7,
  land_34_6: 7,
  land_34_7: 7,
  land_34_9: 7,
  "land_35_-1": 7,
  "land_35_-10": 7,
  "land_35_-11": 7,
  "land_35_-13": 7,
  "land_-23_-5": 1,
  "land_35_-14": 7,
  "land_35_-15": 7,
  "land_35_-16": 7,
  "land_35_-17": 7,
  "land_35_-18": 7,
  "land_35_-8": 7,
  land_35_0: 7,
  land_35_1: 7,
  land_35_2: 7,
  land_35_3: 7,
  "land_-23_-7": 1,
  land_35_4: 7,
  land_35_6: 7,
  land_35_7: 7,
  land_35_9: 7,
  "land_36_-10": 7,
  "land_36_-11": 7,
  "land_36_-12": 7,
  "land_36_-13": 7,
  "land_36_-14": 7,
  "land_36_-17": 7,
  "land_-23_-8": 1,
  "land_36_-18": 7,
  "land_36_-19": 7,
  "land_36_-2": 7,
  "land_36_-3": 7,
  "land_36_-5": 7,
  "land_36_-6": 7,
  land_36_0: 7,
  land_36_1: 7,
  land_36_3: 7,
  land_36_4: 7,
  "land_-23_-9": 1,
  land_36_5: 7,
  land_36_8: 7,
  land_36_9: 7,
  "land_37_-1": 7,
  "land_37_-10": 7,
  "land_37_-11": 7,
  "land_37_-12": 7,
  "land_37_-13": 7,
  "land_37_-15": 7,
  "land_37_-18": 7,
  "land_-23_0": 1,
  "land_37_-19": 7,
  "land_37_-4": 7,
  "land_37_-5": 7,
  "land_37_-6": 7,
  "land_37_-8": 7,
  "land_37_-9": 7,
  land_37_0: 7,
  land_37_1: 7,
  land_37_2: 7,
  land_37_3: 7,
  "land_-23_1": 1,
  land_37_4: 7,
  land_37_5: 7,
  land_37_8: 7,
  land_37_9: 7,
  "land_38_-1": 7,
  "land_38_-13": 7,
  "land_38_-14": 7,
  "land_38_-15": 7,
  "land_38_-16": 7,
  "land_38_-17": 7,
  "land_-23_3": 1,
  "land_38_-18": 7,
  "land_38_-19": 7,
  "land_38_-2": 7,
  "land_38_-7": 7,
  "land_38_-8": 7,
  land_38_0: 7,
  land_38_1: 7,
  land_38_2: 7,
  land_38_4: 7,
  land_38_5: 7,
  "land_-23_4": 1,
  land_38_8: 7,
  land_38_9: 7,
  "land_39_-1": 7,
  "land_39_-11": 7,
  "land_39_-12": 7,
  "land_39_-13": 7,
  "land_39_-14": 7,
  "land_39_-15": 7,
  "land_39_-16": 7,
  "land_39_-17": 7,
  "land_-23_5": 1,
  "land_39_-18": 7,
  "land_39_-7": 7,
  "land_39_-8": 7,
  "land_39_-9": 7,
  land_39_0: 7,
  land_39_2: 7,
  land_39_3: 7,
  land_39_5: 7,
  land_39_6: 7,
  land_39_7: 7,
  "land_61_-7": 1,
  "land_-23_6": 1,
  land_39_8: 7,
  "land_40_-1": 7,
  "land_40_-10": 7,
  "land_40_-2": 7,
  "land_40_-3": 7,
  "land_40_-4": 7,
  "land_40_-7": 7,
  "land_40_-8": 7,
  "land_40_-9": 7,
  land_40_0: 7,
  "land_-23_7": 1,
  land_40_2: 7,
  land_40_6: 7,
  land_40_7: 7,
  land_40_8: 7,
  land_40_9: 7,
  "land_41_-2": 7,
  "land_41_-3": 7,
  "land_41_-5": 7,
  "land_41_-7": 7,
  "land_41_-8": 7,
  "land_-23_9": 1,
  land_41_0: 7,
  land_41_2: 7,
  land_41_3: 7,
  land_41_4: 7,
  land_41_5: 7,
  land_41_6: 7,
  land_41_7: 7,
  land_41_8: 7,
  land_41_9: 7,
  "land_42_-1": 7,
  "land_-24_-1": 1,
  "land_42_-10": 7,
  "land_42_-2": 7,
  "land_42_-3": 7,
  "land_42_-5": 7,
  "land_42_-7": 7,
  land_42_0: 7,
  land_42_5: 7,
  land_42_6: 7,
  "land_43_-1": 7,
  "land_43_-10": 7,
  "land_-24_-10": 1,
  "land_43_-2": 7,
  "land_43_-3": 7,
  "land_43_-4": 7,
  "land_43_-5": 7,
  "land_43_-6": 7,
  "land_43_-7": 7,
  "land_43_-8": 7,
  "land_43_-9": 7,
  land_43_0: 7,
  land_43_1: 7,
  "land_-24_-2": 1,
  land_43_6: 7,
  land_43_7: 7,
  "land_44_-2": 7,
  "land_44_-5": 7,
  "land_44_-6": 7,
  "land_44_-7": 7,
  "land_44_-8": 7,
  land_44_0: 7,
  land_44_1: 7,
  land_44_2: 7,
  "land_-24_-3": 1,
  land_44_6: 7,
  land_44_7: 7,
  land_44_8: 7,
  land_44_9: 7,
  "land_45_-1": 7,
  "land_45_-2": 7,
  "land_45_-9": 7,
  land_45_1: 7,
  land_45_2: 7,
  land_45_3: 7,
  "land_-24_-4": 1,
  land_45_4: 7,
  land_45_5: 7,
  land_45_6: 7,
  land_45_7: 7,
  land_45_8: 7,
  "land_46_-1": 7,
  "land_46_-10": 7,
  "land_46_-2": 7,
  "land_46_-3": 7,
  "land_46_-4": 7,
  "land_-24_-5": 1,
  "land_46_-6": 7,
  "land_46_-7": 7,
  "land_46_-8": 7,
  "land_46_-9": 7,
  land_46_0: 7,
  land_46_1: 7,
  land_46_4: 7,
  land_46_6: 7,
  land_46_7: 7,
  land_46_9: 7,
  "land_-24_-6": 1,
  "land_47_-2": 7,
  "land_47_-4": 7,
  "land_47_-5": 7,
  "land_47_-6": 7,
  land_47_0: 7,
  land_47_1: 7,
  land_47_2: 7,
  land_47_4: 7,
  land_47_5: 7,
  "land_48_-10": 7,
  land_63_22: 1,
  "land_-24_-7": 1,
  "land_48_-2": 7,
  "land_48_-3": 7,
  "land_48_-4": 7,
  "land_48_-5": 7,
  "land_48_-6": 7,
  "land_48_-8": 7,
  land_48_1: 7,
  land_48_2: 7,
  land_48_4: 7,
  land_48_5: 7,
  "land_-24_-8": 1,
  land_48_6: 7,
  land_48_7: 7,
  land_48_9: 7,
  "land_49_-1": 7,
  "land_49_-2": 7,
  "land_49_-3": 7,
  "land_49_-4": 7,
  "land_49_-5": 7,
  "land_49_-6": 7,
  "land_49_-8": 7,
  "land_-24_-9": 1,
  "land_49_-9": 7,
  land_49_1: 7,
  land_49_4: 7,
  land_49_5: 7,
  land_49_7: 7,
  land_49_8: 7,
  land_49_9: 7,
  "land_50_-1": 7,
  "land_50_-10": 7,
  "land_50_-2": 7,
  "land_-24_0": 1,
  "land_50_-6": 7,
  "land_50_-7": 7,
  "land_50_-8": 7,
  "land_50_-9": 7,
  land_50_5: 7,
  land_50_8: 7,
  land_50_9: 7,
  "land_51_-1": 7,
  "land_51_-10": 7,
  "land_51_-5": 7,
  "land_-24_1": 1,
  "land_51_-8": 7,
  "land_51_-9": 7,
  land_51_0: 7,
  land_51_1: 7,
  land_51_3: 7,
  land_51_4: 7,
  land_51_5: 7,
  land_51_7: 7,
  land_51_8: 7,
  land_51_9: 7,
  "land_-24_2": 1,
  "land_52_-10": 7,
  "land_52_-2": 7,
  "land_52_-4": 7,
  "land_52_-7": 7,
  "land_52_-8": 7,
  "land_52_-9": 7,
  land_52_0: 7,
  land_52_1: 7,
  land_52_3: 7,
  land_52_4: 7,
  "land_-24_3": 1,
  land_52_5: 7,
  land_52_6: 7,
  land_52_8: 7,
  land_52_9: 7,
  "land_53_-10": 7,
  "land_53_-3": 7,
  "land_53_-4": 7,
  "land_53_-5": 7,
  "land_53_-6": 7,
  "land_53_-7": 7,
  "land_-24_4": 1,
  "land_53_-9": 7,
  land_53_2: 7,
  land_53_3: 7,
  land_53_4: 7,
  "land_54_-1": 7,
  "land_54_-2": 7,
  "land_54_-8": 7,
  "land_54_-9": 7,
  land_54_4: 7,
  land_54_5: 7,
  "land_-24_5": 1,
  land_54_6: 7,
  land_54_8: 7,
  land_54_9: 7,
  "land_55_-10": 7,
  "land_55_-3": 7,
  "land_55_-4": 7,
  "land_55_-5": 7,
  "land_55_-6": 7,
  "land_55_-7": 7,
  "land_55_-8": 7,
  "land_-24_6": 1,
  land_55_0: 7,
  land_55_3: 7,
  land_55_5: 7,
  land_55_6: 7,
  land_55_8: 7,
  "land_56_-1": 7,
  "land_56_-10": 7,
  "land_56_-4": 7,
  "land_56_-5": 7,
  "land_56_-7": 7,
  "land_64_-11": 1,
  "land_-24_7": 1,
  "land_56_-8": 7,
  land_56_0: 7,
  land_56_1: 7,
  land_56_3: 7,
  land_56_5: 7,
  land_56_6: 7,
  land_56_7: 7,
  land_56_8: 7,
  "land_57_-10": 7,
  "land_57_-3": 7,
  "land_-24_8": 1,
  "land_57_-4": 7,
  "land_57_-5": 7,
  "land_57_-6": 7,
  "land_57_-8": 7,
  "land_57_-9": 7,
  land_57_2: 7,
  land_57_3: 7,
  land_57_6: 7,
  land_57_7: 7,
  land_57_8: 7,
  "land_-24_9": 1,
  "land_58_-3": 7,
  "land_58_-7": 7,
  "land_58_-9": 7,
  land_58_0: 7,
  land_58_2: 7,
  land_58_4: 7,
  land_58_6: 7,
  land_58_8: 7,
  "land_59_-10": 7,
  "land_59_-3": 7,
  "land_-25_-1": 1,
  "land_59_-4": 7,
  "land_59_-6": 7,
  "land_59_-8": 7,
  land_59_0: 7,
  land_59_1: 7,
  land_59_2: 7,
  land_59_3: 7,
  land_59_4: 7,
  land_59_6: 7,
  land_59_7: 7,
  "land_-25_-10": 1,
  land_59_8: 7,
  "land_-10_-1": 7,
  "land_-10_-10": 7,
  "land_-10_-2": 7,
  "land_-10_-3": 7,
  "land_-10_-4": 7,
  "land_-10_-5": 7,
  "land_-10_-6": 7,
  "land_-10_-7": 7,
  "land_-10_-8": 7,
  "land_-25_-3": 1,
  "land_-10_-9": 7,
  "land_-10_0": 7,
  "land_-10_1": 7,
  "land_-10_10": 7,
  "land_-10_2": 7,
  "land_-10_3": 7,
  "land_-10_4": 7,
  "land_-10_5": 7,
  "land_-10_7": 7,
  "land_-10_8": 7,
  "land_-25_-4": 1,
  "land_-10_9": 7,
  "land_-11_-1": 7,
  "land_-11_-10": 7,
  "land_-11_-2": 7,
  "land_-11_-3": 7,
  "land_-11_-4": 7,
  "land_-11_-5": 7,
  "land_-11_-6": 7,
  "land_-11_-7": 7,
  "land_-11_-8": 7,
  "land_-25_-5": 1,
  "land_-11_-9": 7,
  "land_-11_0": 7,
  "land_-11_1": 7,
  "land_-11_2": 7,
  "land_-11_3": 7,
  "land_-12_-1": 7,
  "land_-12_-10": 7,
  "land_-12_-2": 7,
  "land_-12_-3": 7,
  "land_-12_-4": 7,
  "land_-25_-6": 1,
  "land_-12_-5": 7,
  "land_-12_-6": 7,
  "land_-12_-7": 7,
  "land_-12_-8": 7,
  "land_-12_-9": 7,
  "land_-12_0": 7,
  "land_-12_1": 7,
  "land_-12_2": 7,
  "land_-12_3": 7,
  "land_-12_4": 7,
  "land_-25_-7": 1,
  "land_-12_5": 7,
  "land_-13_-1": 7,
  "land_-13_-10": 7,
  "land_-13_-2": 7,
  "land_-13_-4": 7,
  "land_-13_-5": 7,
  "land_-13_-6": 7,
  "land_-13_-7": 7,
  "land_-13_-8": 7,
  "land_-13_-9": 7,
  "land_64_-12": 1,
  "land_-25_-9": 1,
  "land_-13_0": 7,
  "land_-13_1": 7,
  "land_-13_4": 7,
  "land_-13_5": 7,
  "land_-14_-1": 7,
  "land_-14_-10": 7,
  "land_-14_-2": 7,
  "land_-14_-3": 7,
  "land_-14_-4": 7,
  "land_-14_-5": 7,
  "land_-25_0": 1,
  "land_-14_-6": 7,
  "land_-14_-7": 7,
  "land_-14_-8": 7,
  "land_-14_-9": 7,
  "land_-14_0": 7,
  "land_-14_1": 7,
  "land_-14_3": 7,
  "land_-14_4": 7,
  "land_-15_-1": 7,
  "land_-15_-10": 7,
  "land_-25_2": 1,
  "land_-15_-2": 7,
  "land_-15_-3": 7,
  "land_-15_-4": 7,
  "land_-15_-6": 7,
  "land_-15_-7": 7,
  "land_-15_-8": 7,
  "land_-15_-9": 7,
  "land_-15_0": 7,
  "land_-15_1": 7,
  "land_-15_4": 7,
  "land_-25_3": 1,
  "land_-15_5": 7,
  "land_-16_-10": 7,
  "land_-16_-2": 7,
  "land_-16_-3": 7,
  "land_-16_-4": 7,
  "land_-16_-5": 7,
  "land_-16_-6": 7,
  "land_-16_-7": 7,
  "land_-16_-8": 7,
  "land_-16_-9": 7,
  "land_-25_4": 1,
  "land_-16_0": 7,
  "land_-16_1": 7,
  "land_-16_2": 7,
  "land_-16_3": 7,
  "land_-16_4": 7,
  "land_-17_-1": 7,
  "land_-17_-2": 7,
  "land_-17_-4": 7,
  "land_-17_-5": 7,
  "land_-17_-6": 7,
  "land_-25_5": 1,
  "land_-17_-7": 7,
  "land_-17_-8": 7,
  "land_-17_-9": 7,
  "land_-17_0": 7,
  "land_-17_1": 7,
  "land_-17_2": 7,
  "land_-17_3": 7,
  "land_-17_5": 7,
  "land_-18_-1": 7,
  "land_-18_-10": 7,
  "land_-25_7": 1,
  "land_-18_-2": 7,
  "land_-18_-3": 7,
  "land_-18_-4": 7,
  "land_-18_-6": 7,
  "land_-18_-7": 7,
  "land_-18_-8": 7,
  "land_-18_-9": 7,
  "land_-18_0": 7,
  "land_-18_1": 7,
  "land_-18_2": 7,
  "land_-25_8": 1,
  "land_-18_3": 7,
  "land_-19_-1": 7,
  "land_-19_-10": 7,
  "land_-19_-2": 7,
  "land_-19_-3": 7,
  "land_-19_-4": 7,
  "land_-19_-5": 7,
  "land_-19_-6": 7,
  "land_-19_-7": 7,
  "land_-19_-8": 7,
  "land_-25_9": 1,
  "land_-19_-9": 7,
  "land_-19_0": 7,
  "land_-19_1": 7,
  "land_-19_4": 7,
  "land_-19_5": 7,
  "land_-1_-10": 7,
  "land_-1_-3": 7,
  "land_-1_-4": 7,
  "land_-1_-5": 7,
  "land_-1_-6": 7,
  "land_-26_-1": 1,
  "land_-1_-7": 7,
  "land_-1_-8": 7,
  "land_-1_-9": 7,
  "land_-1_10": 7,
  "land_-1_11": 7,
  "land_-1_12": 7,
  "land_-1_6": 7,
  "land_-1_7": 7,
  "land_-1_8": 7,
  "land_-1_9": 7,
  land_23_25: 1,
  "land_64_-13": 1,
  "land_-26_-10": 1,
  "land_-20_-1": 7,
  "land_-20_-10": 7,
  "land_-20_-2": 7,
  "land_-20_-3": 7,
  "land_-20_-4": 7,
  "land_-20_-5": 7,
  "land_-20_-6": 7,
  "land_-20_-7": 7,
  "land_-20_-8": 7,
  "land_-20_0": 7,
  "land_-26_-3": 1,
  "land_-20_1": 7,
  "land_-20_2": 7,
  "land_-20_5": 7,
  "land_-2_-3": 7,
  "land_-2_-4": 7,
  "land_-2_-5": 7,
  "land_-2_-6": 7,
  "land_-2_-7": 7,
  "land_-2_-8": 7,
  "land_-2_12": 7,
  "land_-26_-4": 1,
  "land_-2_6": 7,
  "land_-2_7": 7,
  "land_-2_8": 7,
  "land_-2_9": 7,
  "land_-3_-10": 7,
  "land_-3_-3": 7,
  "land_-3_-4": 7,
  "land_-3_-5": 7,
  "land_-3_-6": 7,
  "land_-3_-7": 7,
  "land_-26_-6": 1,
  "land_-3_-8": 7,
  "land_-3_-9": 7,
  "land_-3_10": 7,
  "land_-3_11": 7,
  "land_-3_12": 7,
  "land_-3_13": 7,
  "land_-3_6": 7,
  "land_-3_7": 7,
  "land_-3_8": 7,
  "land_-3_9": 7,
  "land_-26_-7": 1,
  "land_-4_-10": 7,
  "land_-4_-3": 7,
  "land_-4_-4": 7,
  "land_-4_-5": 7,
  "land_-4_-6": 7,
  "land_-4_-7": 7,
  "land_-4_-9": 7,
  "land_-4_12": 7,
  "land_-4_13": 7,
  "land_-4_6": 7,
  "land_-26_-8": 1,
  "land_-4_7": 7,
  "land_-4_8": 7,
  "land_-4_9": 7,
  "land_-5_-1": 7,
  "land_-5_-10": 7,
  "land_-5_-2": 7,
  "land_-5_-4": 7,
  "land_-5_-5": 7,
  "land_-5_-6": 7,
  "land_-5_-7": 7,
  "land_-26_-9": 1,
  "land_-5_-8": 7,
  "land_-5_-9": 7,
  "land_-5_0": 7,
  "land_-5_1": 7,
  "land_-5_2": 7,
  "land_-5_3": 7,
  "land_-5_4": 7,
  "land_-5_6": 7,
  "land_-5_7": 7,
  "land_-5_8": 7,
  "land_-26_0": 1,
  "land_-5_9": 7,
  "land_-6_-1": 7,
  "land_-6_-10": 7,
  "land_-6_-2": 7,
  "land_-6_-3": 7,
  "land_-6_-4": 7,
  "land_-6_-5": 7,
  "land_-6_-6": 7,
  "land_-6_-7": 7,
  "land_-6_-8": 7,
  "land_-26_2": 1,
  "land_-6_-9": 7,
  "land_-6_0": 7,
  "land_-6_1": 7,
  "land_-6_10": 7,
  "land_-6_2": 7,
  "land_-6_3": 7,
  "land_-6_4": 7,
  "land_-6_6": 7,
  "land_-6_7": 7,
  "land_-6_9": 7,
  "land_-26_3": 1,
  "land_-7_-1": 7,
  "land_-7_-10": 7,
  "land_-7_-2": 7,
  "land_-7_-3": 7,
  "land_-7_-4": 7,
  "land_-7_-5": 7,
  "land_-7_-7": 7,
  "land_-7_-8": 7,
  "land_-7_-9": 7,
  "land_-7_0": 7,
  "land_64_-18": 1,
  "land_-26_4": 1,
  "land_-7_1": 7,
  "land_-7_11": 7,
  "land_-7_12": 7,
  "land_-7_13": 7,
  "land_-7_2": 7,
  "land_-7_3": 7,
  "land_-7_4": 7,
  "land_-7_5": 7,
  "land_-7_6": 7,
  "land_-7_8": 7,
  "land_-26_5": 1,
  "land_-8_-1": 7,
  "land_-8_-10": 7,
  "land_-8_-2": 7,
  "land_-8_-3": 7,
  "land_-8_-4": 7,
  "land_-8_-5": 7,
  "land_-8_-6": 7,
  "land_-8_-8": 7,
  "land_-8_-9": 7,
  "land_-8_0": 7,
  "land_-26_6": 1,
  "land_-8_1": 7,
  "land_-8_10": 7,
  "land_-8_13": 7,
  "land_-8_2": 7,
  "land_-8_4": 7,
  "land_-8_5": 7,
  "land_-9_-1": 7,
  "land_-9_-10": 7,
  "land_-9_-2": 7,
  "land_-9_-3": 7,
  "land_-26_7": 1,
  "land_-9_-4": 7,
  "land_-9_-5": 7,
  "land_-9_-6": 7,
  "land_-9_-7": 7,
  "land_-9_-8": 7,
  "land_-9_-9": 7,
  "land_-9_0": 7,
  "land_-9_1": 7,
  "land_-9_10": 7,
  "land_-9_2": 7,
  "land_-26_8": 1,
  "land_-9_3": 7,
  "land_-9_4": 7,
  "land_-9_5": 7,
  "land_-9_6": 7,
  "land_-9_7": 7,
  "land_-9_8": 7,
  "land_-9_9": 7,
  "land_0_-10": 7,
  "land_0_-3": 7,
  "land_0_-4": 7,
  "land_-26_9": 1,
  "land_0_-5": 7,
  "land_0_-6": 7,
  "land_0_-7": 7,
  "land_0_-8": 7,
  "land_0_-9": 7,
  land_0_13: 7,
  land_0_6: 7,
  land_0_7: 7,
  land_0_8: 7,
  "land_10_-1": 7,
  "land_-27_-1": 1,
  "land_10_-2": 7,
  land_10_0: 7,
  land_10_1: 7,
  land_10_2: 7,
  land_10_3: 7,
  land_10_5: 7,
  land_10_6: 7,
  land_10_7: 7,
  land_10_8: 7,
  "land_11_-1": 7,
  "land_-27_-10": 1,
  "land_11_-2": 7,
  land_11_2: 7,
  land_11_3: 7,
  land_11_4: 7,
  land_11_5: 7,
  land_11_6: 7,
  "land_12_-1": 7,
  "land_12_-2": 7,
  land_12_0: 7,
  land_12_1: 7,
  "land_-27_-2": 1,
  land_12_3: 7,
  land_12_4: 7,
  land_12_5: 7,
  land_12_6: 7,
  land_12_7: 7,
  land_12_8: 7,
  "land_13_-1": 7,
  "land_13_-2": 7,
  land_13_0: 7,
  land_13_1: 7,
  "land_-27_-4": 1,
  land_13_2: 7,
  land_13_3: 7,
  land_13_5: 7,
  land_13_6: 7,
  land_13_7: 7,
  land_13_9: 7,
  "land_14_-2": 7,
  land_14_1: 7,
  land_14_2: 7,
  land_14_3: 7,
  "land_64_-5": 1,
  "land_-27_-5": 1,
  land_14_4: 7,
  land_14_5: 7,
  land_14_6: 7,
  land_14_7: 7,
  land_14_9: 7,
  "land_15_-1": 7,
  "land_15_-2": 7,
  land_15_0: 7,
  land_15_1: 7,
  land_15_2: 7,
  "land_-27_-6": 1,
  land_15_4: 7,
  land_15_5: 7,
  land_15_6: 7,
  land_15_7: 7,
  "land_16_-1": 7,
  land_16_0: 7,
  land_16_2: 7,
  land_16_3: 7,
  land_16_4: 7,
  land_16_5: 7,
  "land_-27_-7": 1,
  land_16_6: 7,
  land_16_7: 7,
  land_16_8: 7,
  "land_17_-1": 7,
  "land_17_-2": 7,
  land_17_0: 7,
  land_17_2: 7,
  land_17_3: 7,
  land_17_5: 7,
  land_17_7: 7,
  "land_-27_-8": 1,
  land_17_8: 7,
  land_17_9: 7,
  "land_18_-2": 7,
  land_18_0: 7,
  land_18_1: 7,
  land_18_2: 7,
  land_18_3: 7,
  land_18_4: 7,
  land_18_5: 7,
  land_18_6: 7,
  "land_-27_-9": 1,
  land_18_9: 7,
  "land_19_-2": 7,
  land_19_0: 7,
  land_19_1: 7,
  land_19_4: 7,
  land_19_5: 7,
  land_19_7: 7,
  land_19_8: 7,
  land_19_9: 7,
  land_1_12: 7,
  "land_-27_0": 1,
  land_1_13: 7,
  land_1_6: 7,
  land_1_8: 7,
  land_1_9: 7,
  "land_22_-8": 7,
  "land_23_-4": 7,
  "land_23_-5": 7,
  "land_23_-6": 7,
  "land_2_-2": 7,
  land_2_0: 7,
  "land_-27_1": 1,
  land_2_1: 7,
  land_2_10: 7,
  land_2_11: 7,
  land_2_12: 7,
  land_2_2: 7,
  land_2_7: 7,
  land_2_8: 7,
  land_2_9: 7,
  "land_3_-1": 7,
  "land_3_-2": 7,
  "land_-27_2": 1,
  land_3_0: 7,
  land_3_1: 7,
  land_3_10: 7,
  land_3_11: 7,
  land_3_12: 7,
  land_3_13: 7,
  land_3_2: 7,
  land_3_3: 7,
  land_3_4: 7,
  land_3_5: 7,
  "land_-27_3": 1,
  land_3_6: 7,
  land_3_7: 7,
  land_3_8: 7,
  land_3_9: 7,
  "land_4_-1": 7,
  "land_4_-2": 7,
  land_4_0: 7,
  land_4_1: 7,
  land_4_10: 7,
  land_4_11: 7,
  "land_-27_4": 1,
  land_4_12: 7,
  land_4_2: 7,
  land_4_3: 7,
  land_4_4: 7,
  land_4_5: 7,
  land_4_6: 7,
  land_4_7: 7,
  land_4_8: 7,
  land_4_9: 7,
  "land_5_-1": 7,
  land_64_21: 1,
  "land_-27_5": 1,
  "land_5_-2": 7,
  land_5_0: 7,
  land_5_1: 7,
  land_5_10: 7,
  land_5_11: 7,
  land_5_12: 7,
  land_5_13: 7,
  land_5_2: 7,
  land_5_3: 7,
  land_5_4: 7,
  "land_-27_6": 1,
  land_5_5: 7,
  land_5_6: 7,
  land_5_7: 7,
  land_5_8: 7,
  land_5_9: 7,
  "land_6_-1": 7,
  "land_6_-2": 7,
  land_6_0: 7,
  land_6_1: 7,
  land_6_10: 7,
  "land_-27_7": 1,
  land_6_11: 7,
  land_6_12: 7,
  land_6_3: 7,
  land_6_4: 7,
  land_6_5: 7,
  land_6_6: 7,
  land_6_7: 7,
  land_6_8: 7,
  land_6_9: 7,
  "land_7_-1": 7,
  "land_-27_8": 1,
  "land_7_-2": 7,
  land_7_0: 7,
  land_7_1: 7,
  land_7_10: 7,
  land_7_11: 7,
  land_7_12: 7,
  land_7_13: 7,
  land_7_2: 7,
  land_7_3: 7,
  land_7_4: 7,
  "land_-27_9": 1,
  land_7_5: 7,
  land_7_6: 7,
  land_7_7: 7,
  land_7_8: 7,
  land_7_9: 7,
  "land_8_-1": 7,
  "land_8_-2": 7,
  land_8_0: 7,
  land_8_1: 7,
  land_8_10: 7,
  "land_-28_-1": 1,
  land_8_11: 7,
  land_8_12: 7,
  land_8_2: 7,
  land_8_3: 7,
  land_8_4: 7,
  land_8_5: 7,
  land_8_8: 7,
  land_8_9: 7,
  "land_9_-1": 7,
  "land_9_-2": 7,
  "land_-28_-10": 1,
  land_9_0: 7,
  land_9_1: 7,
  land_9_10: 7,
  land_9_11: 7,
  land_9_12: 7,
  land_9_13: 7,
  land_9_2: 7,
  land_9_3: 7,
  land_9_4: 7,
  land_9_5: 7,
  "land_-28_-2": 1,
  land_9_6: 7,
  land_9_7: 7,
  land_9_8: 7,
  land_9_9: 7,
  "land_-10_14": 7,
  "land_-10_15": 7,
  "land_-10_16": 7,
  "land_-10_17": 7,
  "land_-10_19": 7,
  "land_-10_20": 7,
  "land_-28_-3": 1,
  "land_-10_21": 7,
  "land_-10_22": 7,
  "land_-10_23": 7,
  "land_-10_24": 7,
  "land_-10_25": 7,
  "land_-10_26": 7,
  "land_-10_27": 7,
  "land_-10_28": 7,
  "land_-10_29": 7,
  "land_-10_30": 7,
  "land_-28_-4": 1,
  "land_-11_10": 7,
  "land_-11_11": 7,
  "land_-11_12": 7,
  "land_-11_13": 7,
  "land_-11_14": 7,
  "land_-11_15": 7,
  "land_-11_17": 7,
  "land_-11_20": 7,
  "land_-11_21": 7,
  "land_-11_22": 7,
  "land_65_-13": 1,
  "land_-28_-5": 1,
  "land_-11_23": 7,
  "land_-11_24": 7,
  "land_-11_25": 7,
  "land_-11_26": 7,
  "land_-11_27": 7,
  "land_-11_28": 7,
  "land_-11_29": 7,
  "land_-11_30": 7,
  "land_-11_6": 7,
  "land_-11_7": 7,
  "land_-28_-7": 1,
  "land_-11_9": 7,
  "land_-12_10": 7,
  "land_-12_11": 7,
  "land_-12_12": 7,
  "land_-12_13": 7,
  "land_-12_14": 7,
  "land_-12_18": 7,
  "land_-12_19": 7,
  "land_-12_20": 7,
  "land_-12_21": 7,
  "land_-28_-9": 1,
  "land_-12_23": 7,
  "land_-12_24": 7,
  "land_-12_26": 7,
  "land_-12_27": 7,
  "land_-12_29": 7,
  "land_-12_30": 7,
  "land_-12_7": 7,
  "land_-13_10": 7,
  "land_-13_12": 7,
  "land_-13_13": 7,
  "land_-28_0": 1,
  "land_-13_14": 7,
  "land_-13_15": 7,
  "land_-13_16": 7,
  "land_-13_17": 7,
  "land_-13_18": 7,
  "land_-13_20": 7,
  "land_-13_22": 7,
  "land_-13_24": 7,
  "land_-13_28": 7,
  "land_-13_29": 7,
  "land_-28_1": 1,
  "land_-13_30": 7,
  "land_-14_11": 7,
  "land_-14_13": 7,
  "land_-14_14": 7,
  "land_-14_15": 7,
  "land_-14_16": 7,
  "land_-14_17": 7,
  "land_-14_18": 7,
  "land_-14_19": 7,
  "land_-14_21": 7,
  "land_-28_3": 1,
  "land_-14_24": 7,
  "land_-14_25": 7,
  "land_-14_27": 7,
  "land_-14_28": 7,
  "land_-14_29": 7,
  "land_-14_30": 7,
  "land_-14_6": 7,
  "land_-14_7": 7,
  "land_-14_8": 7,
  "land_-14_9": 7,
  "land_-28_4": 1,
  "land_-15_10": 7,
  "land_-15_11": 7,
  "land_-15_12": 7,
  "land_-15_13": 7,
  "land_-15_14": 7,
  "land_-15_18": 7,
  "land_-15_19": 7,
  "land_-15_20": 7,
  "land_-15_21": 7,
  "land_-15_22": 7,
  "land_-28_5": 1,
  "land_-15_26": 7,
  "land_-15_27": 7,
  "land_-15_7": 7,
  "land_-15_8": 7,
  "land_-15_9": 7,
  "land_-16_10": 7,
  "land_-16_11": 7,
  "land_-16_12": 7,
  "land_-16_14": 7,
  "land_-16_15": 7,
  "land_-28_7": 1,
  "land_-16_16": 7,
  "land_-16_17": 7,
  "land_-16_19": 7,
  "land_-16_20": 7,
  "land_-16_21": 7,
  "land_-16_22": 7,
  "land_-16_23": 7,
  "land_-16_24": 7,
  "land_-16_25": 7,
  "land_-16_26": 7,
  "land_-28_9": 1,
  "land_-16_27": 7,
  "land_-16_28": 7,
  "land_-16_29": 7,
  "land_-16_7": 7,
  "land_-16_8": 7,
  "land_-16_9": 7,
  "land_-17_10": 7,
  "land_-17_11": 7,
  "land_-17_12": 7,
  "land_-17_13": 7,
  land_65_21: 1,
  "land_-29_-1": 1,
  "land_-17_14": 7,
  "land_-17_15": 7,
  "land_-17_16": 7,
  "land_-17_17": 7,
  "land_-17_18": 7,
  "land_-17_23": 7,
  "land_-17_24": 7,
  "land_-17_25": 7,
  "land_-17_9": 7,
  "land_-18_10": 7,
  "land_-29_-10": 1,
  "land_-18_11": 7,
  "land_-18_12": 7,
  "land_-18_13": 7,
  "land_-18_14": 7,
  "land_-18_16": 7,
  "land_-18_17": 7,
  "land_-18_18": 7,
  "land_-18_19": 7,
  "land_-18_20": 7,
  "land_-18_21": 7,
  "land_-29_-3": 1,
  "land_-18_23": 7,
  "land_-18_24": 7,
  "land_-18_26": 7,
  "land_-18_27": 7,
  "land_-18_6": 7,
  "land_-18_7": 7,
  "land_-18_8": 7,
  "land_-18_9": 7,
  "land_-19_12": 7,
  "land_-19_14": 7,
  "land_-29_-4": 1,
  "land_-19_16": 7,
  "land_-19_17": 7,
  "land_-19_18": 7,
  "land_-19_19": 7,
  "land_-19_20": 7,
  "land_-19_21": 7,
  "land_-19_22": 7,
  "land_-19_23": 7,
  "land_-19_24": 7,
  "land_-19_26": 7,
  "land_-29_-5": 1,
  "land_-19_27": 7,
  "land_-19_28": 7,
  "land_-19_29": 7,
  "land_-19_6": 7,
  "land_-19_7": 7,
  "land_-19_8": 7,
  "land_-19_9": 7,
  "land_-1_14": 7,
  "land_-1_15": 7,
  "land_-1_16": 7,
  "land_-29_-6": 1,
  "land_-1_17": 7,
  "land_-1_18": 7,
  "land_-1_19": 7,
  "land_-1_20": 7,
  "land_-1_21": 7,
  "land_-1_22": 7,
  "land_-1_23": 7,
  "land_-1_24": 7,
  "land_-1_25": 7,
  "land_-1_26": 7,
  "land_-29_-7": 1,
  "land_-1_27": 7,
  "land_-1_28": 7,
  "land_-1_29": 7,
  "land_-20_11": 7,
  "land_-20_12": 7,
  "land_-20_13": 7,
  "land_-20_14": 7,
  "land_-20_15": 7,
  "land_-20_16": 7,
  "land_-20_17": 7,
  "land_-29_-8": 1,
  "land_-20_18": 7,
  "land_-20_19": 7,
  "land_-20_20": 7,
  "land_-20_21": 7,
  "land_-20_22": 7,
  "land_-20_25": 7,
  "land_-20_26": 7,
  "land_-20_27": 7,
  "land_-20_28": 7,
  "land_-20_29": 7,
  "land_-29_-9": 1,
  "land_-20_6": 7,
  "land_-20_7": 7,
  "land_-20_8": 7,
  "land_-20_9": 7,
  "land_-21_12": 7,
  "land_-21_13": 7,
  "land_-21_14": 7,
  "land_-21_15": 7,
  "land_-21_16": 7,
  "land_-21_17": 7,
  "land_-29_0": 1,
  "land_-21_18": 7,
  "land_-21_20": 7,
  "land_-21_21": 7,
  "land_-21_22": 7,
  "land_-21_24": 7,
  "land_-21_28": 7,
  "land_-22_10": 7,
  "land_-22_11": 7,
  "land_-22_12": 7,
  "land_-22_14": 7,
  "land_66_-13": 1,
  "land_-29_1": 1,
  "land_-22_15": 7,
  "land_-22_16": 7,
  "land_-22_17": 7,
  "land_-22_18": 7,
  "land_-22_20": 7,
  "land_-22_23": 7,
  "land_-22_25": 7,
  "land_-22_26": 7,
  "land_-22_27": 7,
  "land_-22_28": 7,
  "land_-29_2": 1,
  "land_-22_29": 7,
  "land_-23_10": 7,
  "land_-23_11": 7,
  "land_-23_12": 7,
  "land_-23_13": 7,
  "land_-23_14": 7,
  "land_-23_15": 7,
  "land_-23_16": 7,
  "land_-23_17": 7,
  "land_-23_18": 7,
  "land_-29_3": 1,
  "land_-23_19": 7,
  "land_-23_22": 7,
  "land_-23_23": 7,
  "land_-23_24": 7,
  "land_-23_25": 7,
  "land_-23_26": 7,
  "land_-23_27": 7,
  "land_-23_29": 7,
  "land_-24_10": 7,
  "land_-24_11": 7,
  "land_-29_5": 1,
  "land_-24_12": 7,
  "land_-24_13": 7,
  "land_-24_14": 7,
  "land_-24_15": 7,
  "land_-24_16": 7,
  "land_-24_17": 7,
  "land_-24_18": 7,
  "land_-24_19": 7,
  "land_-24_22": 7,
  "land_-24_23": 7,
  "land_-29_6": 1,
  "land_-24_24": 7,
  "land_-24_25": 7,
  "land_-24_26": 7,
  "land_-24_27": 7,
  "land_-24_29": 7,
  "land_-25_10": 7,
  "land_-25_11": 7,
  "land_-25_13": 7,
  "land_-25_15": 7,
  "land_-25_16": 7,
  "land_-29_7": 1,
  "land_-25_17": 7,
  "land_-25_18": 7,
  "land_-25_19": 7,
  "land_-25_21": 7,
  "land_-25_22": 7,
  "land_-25_23": 7,
  "land_-25_24": 7,
  "land_-25_25": 7,
  "land_-25_26": 7,
  "land_-25_27": 7,
  "land_-29_8": 1,
  "land_-26_13": 7,
  "land_-26_15": 7,
  "land_-26_18": 7,
  "land_-26_20": 7,
  "land_-26_21": 7,
  "land_-26_22": 7,
  "land_-26_23": 7,
  "land_-26_24": 7,
  "land_-26_25": 7,
  "land_-26_26": 7,
  "land_-29_9": 1,
  "land_-26_27": 7,
  "land_-26_29": 7,
  "land_-27_10": 7,
  "land_-27_11": 7,
  "land_-27_14": 7,
  "land_-27_15": 7,
  "land_-27_18": 7,
  "land_-27_19": 7,
  "land_-27_21": 7,
  "land_-27_22": 7,
  "land_-30_-10": 1,
  "land_-27_24": 7,
  "land_-27_25": 7,
  "land_-27_26": 7,
  "land_-27_28": 7,
  "land_-27_29": 7,
  "land_-28_14": 7,
  "land_-28_15": 7,
  "land_-28_17": 7,
  "land_-28_18": 7,
  "land_-28_19": 7,
  "land_-30_-2": 1,
  "land_-28_20": 7,
  "land_-28_21": 7,
  "land_-28_22": 7,
  "land_-28_23": 7,
  "land_-28_24": 7,
  "land_-28_25": 7,
  "land_-28_26": 7,
  "land_-28_27": 7,
  "land_-28_28": 7,
  "land_-28_29": 7,
  "land_67_-13": 1,
  "land_-30_-3": 1,
  "land_-29_13": 7,
  "land_-29_14": 7,
  "land_-29_15": 7,
  "land_-29_16": 7,
  "land_-29_17": 7,
  "land_-29_18": 7,
  "land_-29_19": 7,
  "land_-29_20": 7,
  "land_-29_21": 7,
  "land_-29_22": 7,
  "land_-30_-4": 1,
  "land_-29_23": 7,
  "land_-29_24": 7,
  "land_-29_25": 7,
  "land_-29_26": 7,
  "land_-29_27": 7,
  "land_-29_29": 7,
  "land_-2_14": 7,
  "land_-2_15": 7,
  "land_-2_16": 7,
  "land_-2_17": 7,
  "land_-30_-6": 1,
  "land_-2_18": 7,
  "land_-2_19": 7,
  "land_-2_20": 7,
  "land_-2_21": 7,
  "land_-2_22": 7,
  "land_-2_23": 7,
  "land_-2_24": 7,
  "land_-2_25": 7,
  "land_-2_26": 7,
  "land_-2_27": 7,
  "land_-30_-7": 1,
  "land_-2_28": 7,
  "land_-2_29": 7,
  "land_-30_11": 7,
  "land_-30_12": 7,
  "land_-30_13": 7,
  "land_-30_14": 7,
  "land_-30_15": 7,
  "land_-30_16": 7,
  "land_-30_17": 7,
  "land_-30_18": 7,
  "land_-30_-8": 1,
  "land_-30_19": 7,
  "land_-30_20": 7,
  "land_-30_21": 7,
  "land_-30_22": 7,
  "land_-30_23": 7,
  "land_-30_24": 7,
  "land_-30_25": 7,
  "land_-30_26": 7,
  "land_-30_27": 7,
  "land_-30_29": 7,
  "land_-30_0": 1,
  "land_-3_15": 7,
  "land_-3_16": 7,
  "land_-3_17": 7,
  "land_-3_18": 7,
  "land_-3_19": 7,
  "land_-3_20": 7,
  "land_-3_21": 7,
  "land_-3_22": 7,
  "land_-3_23": 7,
  "land_-3_24": 7,
  "land_-30_1": 1,
  "land_-3_25": 7,
  "land_-3_26": 7,
  "land_-3_27": 7,
  "land_-3_28": 7,
  "land_-4_14": 7,
  "land_-4_15": 7,
  "land_-4_16": 7,
  "land_-4_17": 7,
  "land_-4_18": 7,
  "land_-4_19": 7,
  "land_-30_3": 1,
  "land_-4_20": 7,
  "land_-4_21": 7,
  "land_-4_22": 7,
  "land_-4_23": 7,
  "land_-4_24": 7,
  "land_-4_25": 7,
  "land_-4_26": 7,
  "land_-4_27": 7,
  "land_-4_28": 7,
  "land_-4_29": 7,
  "land_-30_4": 1,
  "land_-4_34": 7,
  "land_-5_14": 7,
  "land_-5_15": 7,
  "land_-5_16": 7,
  "land_-5_17": 7,
  "land_-5_18": 7,
  "land_-5_19": 7,
  "land_-5_26": 7,
  "land_-5_27": 7,
  "land_-5_28": 7,
  "land_-30_5": 1,
  "land_-5_29": 7,
  "land_-5_33": 7,
  "land_-5_34": 7,
  "land_-5_36": 7,
  "land_-6_14": 7,
  "land_-6_15": 7,
  "land_-6_18": 7,
  "land_-6_19": 7,
  "land_-6_20": 7,
  "land_-6_21": 7,
  "land_67_-18": 1,
  "land_-30_7": 1,
  "land_-6_26": 7,
  "land_-6_27": 7,
  "land_-6_28": 7,
  "land_-6_29": 7,
  "land_-6_34": 7,
  "land_-6_35": 7,
  "land_-7_14": 7,
  "land_-7_15": 7,
  "land_-7_16": 7,
  "land_-7_18": 7,
  "land_-30_8": 1,
  "land_-7_19": 7,
  "land_-7_20": 7,
  "land_-7_21": 7,
  "land_-7_23": 7,
  "land_-7_24": 7,
  "land_-7_25": 7,
  "land_-7_26": 7,
  "land_-7_27": 7,
  "land_-7_28": 7,
  "land_-7_29": 7,
  "land_-30_9": 1,
  "land_-8_14": 7,
  "land_-8_15": 7,
  "land_-8_16": 7,
  "land_-8_17": 7,
  "land_-8_19": 7,
  "land_-8_20": 7,
  "land_-8_21": 7,
  "land_-8_22": 7,
  "land_-8_23": 7,
  "land_-8_24": 7,
  "land_-7_-6": 1,
  "land_-8_25": 7,
  "land_-8_26": 7,
  "land_-8_27": 7,
  "land_-8_28": 7,
  "land_-8_29": 7,
  "land_-8_30": 7,
  "land_-8_31": 7,
  "land_-9_14": 7,
  "land_-9_15": 7,
  "land_-9_16": 7,
  "land_20_-1": 1,
  "land_-9_17": 7,
  "land_-9_18": 7,
  "land_-9_19": 7,
  "land_-9_20": 7,
  "land_-9_21": 7,
  "land_-9_22": 7,
  "land_-9_23": 7,
  "land_-9_24": 7,
  "land_-9_25": 7,
  "land_-9_26": 7,
  "land_20_-10": 1,
  "land_-9_27": 7,
  "land_-9_28": 7,
  "land_-9_29": 7,
  "land_-9_32": 7,
  land_0_14: 7,
  land_0_15: 7,
  land_0_16: 7,
  land_0_17: 7,
  land_0_18: 7,
  land_0_19: 7,
  "land_20_-2": 1,
  land_0_20: 7,
  land_0_21: 7,
  land_0_22: 7,
  land_0_23: 7,
  land_0_24: 7,
  land_0_25: 7,
  land_0_26: 7,
  land_0_27: 7,
  land_0_28: 7,
  land_0_29: 7,
  "land_20_-3": 1,
  land_0_34: 7,
  land_0_36: 7,
  land_1_14: 7,
  land_1_15: 7,
  land_1_16: 7,
  land_1_17: 7,
  land_1_18: 7,
  land_1_19: 7,
  land_1_20: 7,
  land_1_21: 7,
  "land_20_-4": 1,
  land_1_22: 7,
  land_1_23: 7,
  land_1_24: 7,
  land_1_25: 7,
  land_1_26: 7,
  land_1_27: 7,
  land_1_28: 7,
  land_1_29: 7,
  land_1_34: 7,
  land_1_35: 7,
  "land_20_-5": 1,
  land_1_36: 7,
  land_2_14: 7,
  land_2_15: 7,
  land_2_16: 7,
  land_2_18: 7,
  land_2_19: 7,
  land_2_20: 7,
  land_2_21: 7,
  land_2_22: 7,
  land_2_23: 7,
  "land_68_-13": 1,
  "land_20_-6": 1,
  land_2_24: 7,
  land_2_25: 7,
  land_2_27: 7,
  land_2_28: 7,
  land_2_29: 7,
  land_3_14: 7,
  land_3_15: 7,
  land_3_16: 7,
  land_3_17: 7,
  land_3_18: 7,
  "land_20_-7": 1,
  land_3_20: 7,
  land_3_21: 7,
  land_3_22: 7,
  land_3_23: 7,
  land_3_24: 7,
  land_3_25: 7,
  land_3_26: 7,
  land_3_27: 7,
  land_3_29: 7,
  land_4_15: 7,
  "land_20_-8": 1,
  land_4_17: 7,
  land_4_20: 7,
  land_4_22: 7,
  land_4_23: 7,
  land_4_24: 7,
  land_4_25: 7,
  land_4_26: 7,
  land_4_28: 7,
  land_5_15: 7,
  land_5_17: 7,
  land_20_0: 1,
  land_5_18: 7,
  land_5_19: 7,
  "land_-31_10": 7,
  "land_-31_11": 7,
  "land_-31_12": 7,
  "land_-31_13": 7,
  "land_-31_14": 7,
  "land_-31_16": 7,
  "land_-31_17": 7,
  "land_-31_18": 7,
  land_20_1: 1,
  "land_-31_19": 7,
  "land_-31_2": 7,
  "land_-31_20": 7,
  "land_-31_21": 7,
  "land_-31_22": 7,
  "land_-31_24": 7,
  "land_-31_26": 7,
  "land_-31_27": 7,
  "land_-31_28": 7,
  "land_-31_29": 7,
  land_20_2: 1,
  "land_-31_3": 7,
  "land_-31_4": 7,
  "land_-31_5": 7,
  "land_-32_-1": 7,
  "land_-32_0": 7,
  "land_-32_1": 7,
  "land_-32_11": 7,
  "land_-32_12": 7,
  "land_-32_13": 7,
  "land_-32_14": 7,
  land_20_3: 1,
  "land_-32_15": 7,
  "land_-32_18": 7,
  "land_-32_19": 7,
  "land_-32_2": 7,
  "land_-32_20": 7,
  "land_-32_22": 7,
  "land_-32_23": 7,
  "land_-32_24": 7,
  "land_-32_26": 7,
  "land_-32_27": 7,
  land_20_4: 1,
  "land_-32_28": 7,
  "land_-32_29": 7,
  "land_-32_3": 7,
  "land_-32_4": 7,
  "land_-32_5": 7,
  "land_-32_7": 7,
  "land_-32_9": 7,
  "land_-33_0": 7,
  "land_-33_1": 7,
  "land_-33_10": 7,
  land_20_5: 1,
  "land_-33_11": 7,
  "land_-33_13": 7,
  "land_-33_14": 7,
  "land_-33_15": 7,
  "land_-33_16": 7,
  "land_-33_17": 7,
  "land_-33_18": 7,
  "land_-33_2": 7,
  "land_-33_22": 7,
  "land_-33_23": 7,
  land_20_6: 1,
  "land_-33_24": 7,
  "land_-33_25": 7,
  "land_-33_28": 7,
  "land_-33_29": 7,
  "land_-33_3": 7,
  "land_-33_6": 7,
  "land_-33_7": 7,
  "land_-33_8": 7,
  "land_-33_9": 7,
  "land_-34_-1": 7,
  land_24_23: 1,
  "land_68_-18": 1,
  land_20_8: 1,
  "land_-34_-2": 7,
  "land_-34_0": 7,
  "land_-34_1": 7,
  "land_-34_10": 7,
  "land_-34_11": 7,
  "land_-34_14": 7,
  "land_-34_15": 7,
  "land_-34_16": 7,
  "land_-34_17": 7,
  "land_-34_18": 7,
  "land_21_-1": 1,
  "land_-34_19": 7,
  "land_-34_2": 7,
  "land_-34_20": 7,
  "land_-34_21": 7,
  "land_-34_22": 7,
  "land_-34_25": 7,
  "land_-34_26": 7,
  "land_-34_27": 7,
  "land_-34_28": 7,
  "land_-34_29": 7,
  "land_21_-10": 1,
  "land_-34_3": 7,
  "land_-34_4": 7,
  "land_-34_5": 7,
  "land_-34_6": 7,
  "land_-34_7": 7,
  "land_-34_8": 7,
  "land_-34_9": 7,
  "land_-35_-1": 7,
  "land_-35_-2": 7,
  "land_-35_0": 7,
  "land_21_-2": 1,
  "land_-35_1": 7,
  "land_-35_10": 7,
  "land_-35_11": 7,
  "land_-35_13": 7,
  "land_-35_14": 7,
  "land_-35_16": 7,
  "land_-35_17": 7,
  "land_-35_18": 7,
  "land_-35_19": 7,
  "land_-35_2": 7,
  "land_21_-3": 1,
  "land_-35_20": 7,
  "land_-35_21": 7,
  "land_-35_22": 7,
  "land_-35_23": 7,
  "land_-35_24": 7,
  "land_-35_25": 7,
  "land_-35_26": 7,
  "land_-35_27": 7,
  "land_-35_28": 7,
  "land_-35_29": 7,
  "land_21_-4": 1,
  "land_-35_3": 7,
  "land_-35_4": 7,
  "land_-35_5": 7,
  "land_-35_6": 7,
  "land_-35_7": 7,
  "land_-35_8": 7,
  "land_-35_9": 7,
  "land_-36_-2": 7,
  "land_-36_0": 7,
  "land_-36_1": 7,
  "land_21_-5": 1,
  "land_-36_11": 7,
  "land_-36_12": 7,
  "land_-36_13": 7,
  "land_-36_14": 7,
  "land_-36_15": 7,
  "land_-36_16": 7,
  "land_-36_17": 7,
  "land_-36_18": 7,
  "land_-36_19": 7,
  "land_-36_21": 7,
  "land_21_-6": 1,
  "land_-36_22": 7,
  "land_-36_23": 7,
  "land_-36_24": 7,
  "land_-36_25": 7,
  "land_-36_26": 7,
  "land_-36_27": 7,
  "land_-36_28": 7,
  "land_-36_29": 7,
  "land_-36_4": 7,
  "land_-36_5": 7,
  "land_21_-7": 1,
  "land_-36_6": 7,
  "land_-36_7": 7,
  "land_-36_8": 7,
  "land_-36_9": 7,
  "land_-37_-1": 7,
  "land_-37_-2": 7,
  "land_-37_0": 7,
  "land_-37_1": 7,
  "land_-37_10": 7,
  "land_-37_11": 7,
  "land_21_-8": 1,
  "land_-37_12": 7,
  "land_-37_13": 7,
  "land_-37_14": 7,
  "land_-37_17": 7,
  "land_-37_18": 7,
  "land_-37_19": 7,
  "land_-37_2": 7,
  "land_-37_20": 7,
  "land_-37_21": 7,
  "land_-37_23": 7,
  "land_68_-2": 1,
  "land_21_-9": 1,
  "land_-37_25": 7,
  "land_-37_26": 7,
  "land_-37_27": 7,
  "land_-37_28": 7,
  "land_-37_29": 7,
  "land_-37_5": 7,
  "land_-38_-1": 7,
  "land_-38_0": 7,
  "land_-38_1": 7,
  "land_-38_10": 7,
  land_21_0: 1,
  "land_-38_11": 7,
  "land_-38_12": 7,
  "land_-38_13": 7,
  "land_-38_14": 7,
  "land_-38_15": 7,
  "land_-38_17": 7,
  "land_-38_18": 7,
  "land_-38_19": 7,
  "land_-38_2": 7,
  "land_-38_20": 7,
  land_21_1: 1,
  "land_-38_21": 7,
  "land_-38_22": 7,
  "land_-38_24": 7,
  "land_-38_25": 7,
  "land_-38_26": 7,
  "land_-38_27": 7,
  "land_-38_3": 7,
  "land_-38_4": 7,
  "land_-38_5": 7,
  "land_-38_7": 7,
  land_21_3: 1,
  "land_-38_8": 7,
  "land_-38_9": 7,
  "land_-39_-2": 7,
  "land_-39_1": 7,
  "land_-39_10": 7,
  "land_-39_11": 7,
  "land_-39_12": 7,
  "land_-39_13": 7,
  "land_-39_14": 7,
  "land_-39_16": 7,
  land_21_5: 1,
  "land_-39_17": 7,
  "land_-39_18": 7,
  "land_-39_19": 7,
  "land_-39_2": 7,
  "land_-39_20": 7,
  "land_-39_21": 7,
  "land_-39_22": 7,
  "land_-39_23": 7,
  "land_-39_24": 7,
  "land_-39_25": 7,
  land_21_6: 1,
  "land_-39_26": 7,
  "land_-39_3": 7,
  "land_-39_30": 7,
  "land_-39_4": 7,
  "land_-39_5": 7,
  "land_-39_8": 7,
  "land_-39_9": 7,
  "land_-40_-1": 7,
  "land_-40_0": 7,
  "land_-40_10": 7,
  land_21_8: 1,
  "land_-40_11": 7,
  "land_-40_12": 7,
  "land_-40_13": 7,
  "land_-40_14": 7,
  "land_-40_15": 7,
  "land_-40_16": 7,
  "land_-40_17": 7,
  "land_-40_18": 7,
  "land_-40_19": 7,
  "land_-40_2": 7,
  land_21_9: 1,
  "land_-40_20": 7,
  "land_-40_21": 7,
  "land_-40_22": 7,
  "land_-40_23": 7,
  "land_-40_24": 7,
  "land_-40_25": 7,
  "land_-40_27": 7,
  "land_-40_29": 7,
  "land_-40_3": 7,
  "land_-40_4": 7,
  "land_22_-1": 1,
  "land_-40_5": 7,
  "land_-41_-1": 7,
  "land_-41_-2": 7,
  "land_-41_1": 7,
  "land_-41_11": 7,
  "land_-41_12": 7,
  "land_-41_13": 7,
  "land_-41_14": 7,
  "land_-41_15": 7,
  "land_-41_16": 7,
  "land_22_-10": 1,
  "land_-41_18": 7,
  "land_-41_19": 7,
  "land_-41_2": 7,
  "land_-41_21": 7,
  "land_-41_22": 7,
  "land_-41_23": 7,
  "land_-41_24": 7,
  "land_-41_25": 7,
  "land_-41_3": 7,
  "land_-41_4": 7,
  "land_69_-12": 1,
  "land_22_-2": 1,
  "land_-41_5": 7,
  "land_-41_6": 7,
  "land_-41_7": 7,
  "land_-41_9": 7,
  "land_-42_-2": 7,
  "land_-42_0": 7,
  "land_-42_11": 7,
  "land_-42_12": 7,
  "land_-42_13": 7,
  "land_-42_14": 7,
  "land_22_-3": 1,
  "land_-42_16": 7,
  "land_-42_17": 7,
  "land_-42_18": 7,
  "land_-42_19": 7,
  "land_-42_2": 7,
  "land_-42_20": 7,
  "land_-42_23": 7,
  "land_-42_24": 7,
  "land_-42_25": 7,
  "land_-42_26": 7,
  "land_22_-4": 1,
  "land_-42_3": 7,
  "land_-42_4": 7,
  "land_-42_5": 7,
  "land_-42_7": 7,
  "land_-42_8": 7,
  "land_-43_-1": 7,
  "land_-43_-2": 7,
  "land_-43_0": 7,
  "land_-43_1": 7,
  "land_-43_10": 7,
  "land_22_-5": 1,
  "land_-43_11": 7,
  "land_-43_14": 7,
  "land_-43_15": 7,
  "land_-43_16": 7,
  "land_-43_17": 7,
  "land_-43_18": 7,
  "land_-43_19": 7,
  "land_-43_2": 7,
  "land_-43_20": 7,
  "land_-43_23": 7,
  "land_22_-6": 1,
  "land_-43_24": 7,
  "land_-43_3": 7,
  "land_-43_4": 7,
  "land_-43_5": 7,
  "land_-43_9": 7,
  "land_-44_-1": 7,
  "land_-44_0": 7,
  "land_-44_1": 7,
  "land_-44_10": 7,
  "land_-44_11": 7,
  "land_22_-7": 1,
  "land_-44_12": 7,
  "land_-44_14": 7,
  "land_-44_15": 7,
  "land_-44_16": 7,
  "land_-44_18": 7,
  "land_-44_19": 7,
  "land_-44_2": 7,
  "land_-44_20": 7,
  "land_-44_21": 7,
  "land_-44_23": 7,
  "land_22_-9": 1,
  "land_-44_24": 7,
  "land_-44_25": 7,
  "land_-44_3": 7,
  "land_-44_5": 7,
  "land_-44_6": 7,
  "land_-44_7": 7,
  "land_-44_8": 7,
  "land_-44_9": 7,
  "land_-45_-1": 7,
  "land_-45_0": 7,
  land_22_0: 1,
  "land_-45_1": 7,
  "land_-45_10": 7,
  "land_-45_11": 7,
  "land_-45_13": 7,
  "land_-45_14": 7,
  "land_-45_15": 7,
  "land_-45_16": 7,
  "land_-45_17": 7,
  "land_-45_18": 7,
  "land_-45_19": 7,
  land_22_1: 1,
  "land_-45_2": 7,
  "land_-45_20": 7,
  "land_-45_21": 7,
  "land_-45_3": 7,
  "land_-45_4": 7,
  "land_-45_5": 7,
  "land_-45_6": 7,
  "land_-45_7": 7,
  "land_-45_8": 7,
  "land_-45_9": 7,
  land_22_4: 1,
  "land_-46_-1": 7,
  "land_-46_-2": 7,
  "land_-46_0": 7,
  "land_-46_1": 7,
  "land_-46_10": 7,
  "land_-46_11": 7,
  "land_-46_12": 7,
  "land_-46_13": 7,
  "land_-46_16": 7,
  "land_-46_17": 7,
  "land_69_-13": 1,
  land_22_5: 1,
  "land_-46_18": 7,
  "land_-46_2": 7,
  "land_-46_20": 7,
  "land_-46_7": 7,
  "land_-46_8": 7,
  "land_-46_9": 7,
  "land_-47_-1": 7,
  "land_-47_-2": 7,
  "land_-47_0": 7,
  "land_-47_1": 7,
  land_22_8: 1,
  "land_-47_10": 7,
  "land_-47_11": 7,
  "land_-47_12": 7,
  "land_-47_13": 7,
  "land_-47_14": 7,
  "land_-47_15": 7,
  "land_-47_16": 7,
  "land_-47_17": 7,
  "land_-47_2": 7,
  "land_-47_3": 7,
  land_22_9: 1,
  "land_-47_4": 7,
  "land_-47_6": 7,
  "land_-47_7": 7,
  "land_-47_8": 7,
  "land_-47_9": 7,
  "land_-48_-1": 7,
  "land_-48_1": 7,
  "land_-48_10": 7,
  "land_-48_11": 7,
  "land_-48_12": 7,
  "land_23_-1": 1,
  "land_-48_13": 7,
  "land_-48_14": 7,
  "land_-48_15": 7,
  "land_-48_16": 7,
  "land_-48_17": 7,
  "land_-48_2": 7,
  "land_-48_3": 7,
  "land_-48_4": 7,
  "land_-48_6": 7,
  "land_-48_7": 7,
  "land_23_-10": 1,
  "land_-48_8": 7,
  "land_-48_9": 7,
  "land_-49_-2": 7,
  "land_-49_0": 7,
  "land_-49_1": 7,
  "land_-49_10": 7,
  "land_-49_12": 7,
  "land_-49_13": 7,
  "land_-49_14": 7,
  "land_-49_15": 7,
  "land_23_-2": 1,
  "land_-49_16": 7,
  "land_-49_17": 7,
  "land_-49_18": 7,
  "land_-49_2": 7,
  "land_-49_3": 7,
  "land_-49_4": 7,
  "land_-49_6": 7,
  "land_-49_7": 7,
  "land_-49_8": 7,
  "land_-49_9": 7,
  "land_23_-3": 1,
  "land_-50_-1": 7,
  "land_-50_-2": 7,
  "land_-50_0": 7,
  "land_-50_1": 7,
  "land_-50_10": 7,
  "land_-50_11": 7,
  "land_-50_14": 7,
  "land_-50_15": 7,
  "land_-50_16": 7,
  "land_-50_17": 7,
  "land_23_-7": 1,
  "land_-50_2": 7,
  "land_-50_4": 7,
  "land_-50_5": 7,
  "land_-50_6": 7,
  "land_-50_7": 7,
  "land_-50_8": 7,
  "land_-50_9": 7,
  "land_-51_10": 7,
  "land_-51_11": 7,
  "land_-51_12": 7,
  "land_23_-8": 1,
  "land_-51_13": 7,
  "land_-51_14": 7,
  "land_-51_15": 7,
  "land_-51_16": 7,
  "land_-51_17": 7,
  "land_-51_2": 7,
  "land_-51_3": 7,
  "land_-51_4": 7,
  "land_-51_5": 7,
  "land_-52_11": 7,
  land_23_0: 1,
  "land_-52_12": 7,
  "land_-52_14": 7,
  "land_-52_15": 7,
  "land_-52_16": 7,
  "land_-52_17": 7,
  "land_-52_2": 7,
  "land_-52_3": 7,
  "land_-52_4": 7,
  "land_-52_5": 7,
  "land_-53_15": 7,
  "land_69_-18": 1,
  land_23_1: 1,
  "land_-53_16": 7,
  "land_-53_17": 7,
  "land_-53_9": 7,
  "land_-54_14": 7,
  "land_-54_15": 7,
  "land_-54_16": 7,
  "land_-54_17": 7,
  "land_-54_5": 7,
  "land_-55_11": 7,
  "land_-55_14": 7,
  land_23_2: 1,
  "land_-55_15": 7,
  "land_-55_16": 7,
  "land_-55_17": 7,
  "land_-56_16": 7,
  "land_-56_17": 7,
  "land_-57_14": 7,
  "land_-57_15": 7,
  "land_-57_16": 7,
  "land_-57_17": 7,
  "land_-57_18": 7,
  land_23_4: 1,
  "land_-58_10": 7,
  "land_-58_11": 7,
  "land_-58_15": 7,
  "land_-58_16": 7,
  "land_-58_17": 7,
  "land_-58_19": 7,
  "land_-59_-1": 7,
  "land_-59_-2": 7,
  "land_-59_-3": 7,
  "land_-59_-4": 7,
  land_23_5: 1,
  "land_-59_-5": 7,
  "land_-59_0": 7,
  "land_-59_1": 7,
  "land_-59_12": 7,
  "land_-59_14": 7,
  "land_-59_16": 7,
  "land_-59_17": 7,
  "land_-59_4": 7,
  "land_-59_7": 7,
  "land_-59_8": 7,
  land_23_6: 1,
  "land_-59_9": 7,
  "land_-60_-2": 7,
  "land_-60_-5": 7,
  "land_-60_-6": 7,
  "land_-60_0": 7,
  "land_-60_1": 7,
  "land_-60_11": 7,
  "land_-60_13": 7,
  "land_-60_15": 7,
  "land_-60_16": 7,
  land_23_7: 1,
  "land_-60_17": 7,
  "land_-60_2": 7,
  "land_-60_6": 7,
  "land_-60_7": 7,
  "land_-60_8": 7,
  "land_-60_9": 7,
  land_5_21: 7,
  land_5_25: 7,
  land_5_26: 7,
  land_5_27: 7,
  land_23_8: 1,
  land_5_28: 7,
  land_5_29: 7,
  land_5_33: 7,
  land_5_34: 7,
  land_5_35: 7,
  land_5_36: 7,
  land_6_14: 7,
  land_6_15: 7,
  land_6_16: 7,
  land_6_17: 7,
  land_23_9: 1,
  land_6_18: 7,
  land_6_19: 7,
  land_6_21: 7,
  land_6_22: 7,
  land_6_23: 7,
  land_6_26: 7,
  land_6_27: 7,
  land_6_28: 7,
  land_6_30: 7,
  land_6_31: 7,
  "land_24_-1": 1,
  land_6_33: 7,
  land_6_34: 7,
  land_6_35: 7,
  land_7_14: 7,
  land_7_15: 7,
  land_7_16: 7,
  land_7_17: 7,
  land_7_19: 7,
  land_7_20: 7,
  land_7_21: 7,
  "land_24_-10": 1,
  land_7_22: 7,
  land_7_23: 7,
  land_7_24: 7,
  land_7_26: 7,
  land_7_28: 7,
  land_7_29: 7,
  land_7_30: 7,
  land_7_34: 7,
  land_7_35: 7,
  land_7_36: 7,
  land_69_6: 1,
  "land_24_-2": 1,
  land_8_14: 7,
  land_8_15: 7,
  land_8_16: 7,
  land_8_17: 7,
  land_8_19: 7,
  land_8_21: 7,
  land_8_22: 7,
  land_8_23: 7,
  land_8_24: 7,
  land_8_25: 7,
  "land_24_-3": 1,
  land_8_26: 7,
  land_8_27: 7,
  land_8_28: 7,
  land_8_29: 7,
  land_9_14: 7,
  land_9_15: 7,
  land_9_16: 7,
  land_9_17: 7,
  land_9_18: 7,
  land_9_19: 7,
  "land_24_-4": 1,
  land_9_20: 7,
  land_9_21: 7,
  land_9_22: 7,
  land_9_23: 7,
  land_9_24: 7,
  land_9_25: 7,
  land_9_26: 7,
  land_9_27: 7,
  land_9_28: 7,
  land_9_29: 7,
  "land_24_-5": 1,
  land_9_30: 7,
  "land_-25_-13": 7,
  "land_-25_-15": 7,
  "land_-25_-21": 7,
  "land_-25_-25": 7,
  "land_-25_-26": 7,
  "land_-26_-16": 7,
  "land_-26_-17": 7,
  "land_-26_-18": 7,
  "land_-26_-31": 7,
  "land_24_-6": 1,
  "land_-28_-17": 7,
  "land_-28_-18": 7,
  "land_-28_-31": 7,
  "land_-29_-16": 7,
  "land_-29_-17": 7,
  "land_-29_-18": 7,
  "land_-29_-32": 7,
  "land_-30_-17": 7,
  "land_-30_-18": 7,
  "land_-30_-32": 7,
  "land_24_-7": 1,
  "land_-30_-33": 7,
  "land_-30_-34": 7,
  "land_-31_-10": 7,
  "land_-31_-15": 7,
  "land_-31_-16": 7,
  "land_-31_-17": 7,
  "land_-31_-18": 7,
  "land_-31_-31": 7,
  "land_-31_-33": 7,
  "land_-31_-34": 7,
  "land_24_-8": 1,
  "land_-31_-4": 7,
  "land_-31_-7": 7,
  "land_-31_-9": 7,
  "land_-32_-17": 7,
  "land_-32_-5": 7,
  "land_-32_-6": 7,
  "land_-33_-12": 7,
  "land_-33_-14": 7,
  "land_-33_-17": 7,
  "land_-33_-18": 7,
  "land_24_-9": 1,
  "land_-33_-3": 7,
  "land_-33_-5": 7,
  "land_-34_-12": 7,
  "land_-34_-13": 7,
  "land_-34_-17": 7,
  "land_-34_-18": 7,
  "land_-35_-10": 7,
  "land_-35_-12": 7,
  "land_-35_-13": 7,
  "land_-35_-14": 7,
  land_24_1: 1,
  "land_-35_-15": 7,
  "land_-35_-16": 7,
  "land_-35_-17": 7,
  "land_-35_-3": 7,
  "land_-35_-35": 7,
  "land_-35_-7": 7,
  "land_-36_-10": 7,
  "land_-36_-11": 7,
  "land_-36_-12": 7,
  "land_-36_-14": 7,
  land_24_2: 1,
  "land_-36_-15": 7,
  "land_-36_-16": 7,
  "land_-36_-17": 7,
  "land_-36_-3": 7,
  "land_-36_-4": 7,
  "land_-36_-5": 7,
  "land_-37_-10": 7,
  "land_-37_-11": 7,
  "land_-37_-14": 7,
  "land_-37_-15": 7,
  "land_70_-13": 1,
  land_24_3: 1,
  "land_-37_-16": 7,
  "land_-37_-17": 7,
  "land_-37_-18": 7,
  "land_-37_-21": 7,
  "land_-37_-37": 7,
  "land_-37_-4": 7,
  "land_-37_-8": 7,
  "land_-38_-10": 7,
  "land_-38_-11": 7,
  "land_-38_-14": 7,
  land_24_4: 1,
  "land_-38_-15": 7,
  "land_-38_-16": 7,
  "land_-38_-17": 7,
  "land_-38_-18": 7,
  "land_-38_-3": 7,
  "land_-38_-37": 7,
  "land_-39_-10": 7,
  "land_-39_-12": 7,
  "land_-39_-13": 7,
  "land_-39_-15": 7,
  land_24_5: 1,
  "land_-39_-18": 7,
  "land_-39_-21": 7,
  "land_-39_-37": 7,
  "land_-39_-7": 7,
  "land_-40_-11": 7,
  "land_-40_-12": 7,
  "land_-40_-14": 7,
  "land_-40_-3": 7,
  "land_-40_-5": 7,
  "land_-40_-6": 7,
  land_24_7: 1,
  "land_-42_-16": 7,
  "land_-42_-37": 7,
  "land_-43_-3": 7,
  "land_-43_-6": 7,
  "land_-44_-32": 7,
  "land_-44_-33": 7,
  "land_-45_-32": 7,
  "land_-45_-33": 7,
  "land_-46_-12": 7,
  "land_-46_-32": 7,
  land_24_9: 1,
  "land_-46_-33": 7,
  "land_-48_-9": 7,
  "land_-49_-4": 7,
  "land_-50_-3": 7,
  "land_-54_-22": 7,
  "land_-54_-23": 7,
  "land_-55_-22": 7,
  "land_-55_-23": 7,
  "land_-56_-12": 7,
  "land_-56_-22": 7,
  "land_25_-1": 1,
  "land_-56_-23": 7,
  "land_-57_-22": 7,
  "land_-58_-22": 7,
  "land_-58_-23": 7,
  "land_-59_-22": 7,
  "land_-59_-7": 7,
  "land_-60_-10": 7,
  "land_-60_-7": 7,
  "land_-60_-8": 7,
  "land_-60_-9": 7,
  "land_25_-10": 1,
  "land_-63_-26": 7,
  "land_-63_-27": 7,
  "land_-64_-26": 7,
  "land_-65_-26": 7,
  "land_-68_-26": 7,
  "land_-70_-30": 7,
  land_10_12: 8,
  land_10_11: 8,
  land_10_15: 8,
  land_13_27: 8,
  "land_25_-2": 1,
  land_13_28: 8,
  land_14_16: 8,
  land_13_29: 8,
  land_14_29: 8,
  land_15_24: 8,
  land_16_17: 8,
  land_18_17: 8,
  land_20_17: 8,
  land_20_29: 8,
  land_21_23: 8,
  "land_25_-3": 1,
  land_21_16: 8,
  land_22_10: 8,
  land_22_12: 8,
  land_23_15: 8,
  land_23_26: 8,
  land_23_27: 8,
  land_18_14: 8,
  land_25_17: 8,
  land_24_26: 8,
  land_26_16: 8,
  "land_25_-4": 1,
  land_27_19: 8,
  land_29_10: 8,
  land_28_13: 8,
  land_29_11: 8,
  land_30_22: 8,
  land_25_15: 8,
  land_32_27: 8,
  land_33_10: 8,
  land_35_18: 8,
  land_10_13: 8,
  "land_70_-2": 1,
  "land_25_-5": 1,
  land_14_26: 8,
  land_15_26: 8,
  land_19_19: 8,
  land_18_23: 8,
  land_21_12: 8,
  land_21_27: 8,
  land_23_19: 8,
  land_24_15: 8,
  land_25_29: 8,
  land_24_14: 8,
  "land_25_-7": 1,
  land_18_16: 8,
  land_24_28: 8,
  land_26_21: 8,
  land_27_26: 8,
  land_30_11: 8,
  land_32_24: 8,
  land_33_28: 8,
  land_33_27: 8,
  land_34_10: 8,
  land_34_27: 8,
  "land_25_-8": 1,
  land_38_26: 8,
  land_39_28: 8,
  land_42_14: 8,
  land_45_16: 8,
  land_45_13: 8,
  land_46_10: 8,
  land_46_12: 8,
  land_47_15: 8,
  land_47_24: 8,
  land_40_19: 8,
  "land_25_-9": 1,
  land_53_28: 8,
  land_54_19: 8,
  land_10_14: 8,
  land_39_16: 8,
  land_40_21: 8,
  land_42_23: 8,
  land_43_19: 8,
  land_43_20: 8,
  land_45_24: 8,
  land_46_14: 8,
  land_25_0: 1,
  land_46_16: 8,
  land_42_26: 8,
  land_46_22: 8,
  land_47_22: 8,
  land_49_23: 8,
  land_53_26: 8,
  land_47_16: 8,
  land_53_21: 8,
  land_54_24: 8,
  land_54_29: 8,
  land_25_2: 1,
  land_57_12: 8,
  land_57_11: 8,
  land_10_26: 8,
  land_12_14: 8,
  land_12_27: 8,
  land_12_28: 8,
  land_12_11: 8,
  land_14_23: 8,
  land_14_25: 8,
  land_16_14: 8,
  land_25_3: 1,
  land_16_24: 8,
  land_18_13: 8,
  land_19_24: 8,
  land_19_20: 8,
  land_20_13: 8,
  land_21_26: 8,
  land_22_22: 8,
  land_23_13: 8,
  land_23_11: 8,
  land_23_28: 8,
  land_25_4: 1,
  land_25_12: 8,
  land_26_12: 8,
  land_27_28: 8,
  land_24_16: 8,
  land_30_25: 8,
  land_10_22: 8,
  land_10_23: 8,
  land_11_24: 8,
  land_11_28: 8,
  land_14_28: 8,
  land_25_5: 1,
  land_16_16: 8,
  land_17_17: 8,
  land_17_22: 8,
  land_17_27: 8,
  land_17_29: 8,
  land_18_11: 8,
  land_19_11: 8,
  land_20_23: 8,
  land_19_16: 8,
  land_19_29: 8,
  land_25_6: 1,
  land_21_17: 8,
  land_21_22: 8,
  land_23_12: 8,
  land_24_17: 8,
  land_25_13: 8,
  land_27_16: 8,
  land_27_18: 8,
  land_26_28: 8,
  land_27_17: 8,
  land_29_12: 8,
  "land_71_-13": 1,
  land_25_7: 1,
  land_24_27: 8,
  land_29_25: 8,
  land_10_10: 8,
  land_10_16: 8,
  land_11_22: 8,
  land_11_26: 8,
  land_33_29: 8,
  land_35_23: 8,
  land_14_22: 8,
  land_38_18: 8,
  land_25_8: 1,
  land_16_15: 8,
  land_38_19: 8,
  land_37_29: 8,
  land_16_13: 8,
  land_38_28: 8,
  land_41_18: 8,
  land_16_26: 8,
  land_41_20: 8,
  land_17_15: 8,
  land_42_16: 8,
  land_25_9: 1,
  land_17_23: 8,
  land_41_21: 8,
  land_41_17: 8,
  land_21_10: 8,
  land_22_11: 8,
  land_22_16: 8,
  land_45_12: 8,
  land_23_14: 8,
  land_46_17: 8,
  land_24_10: 8,
  "land_26_-1": 1,
  land_47_17: 8,
  land_25_14: 8,
  land_24_29: 8,
  land_25_23: 8,
  land_49_24: 8,
  land_25_16: 8,
  land_25_24: 8,
  land_26_14: 8,
  land_46_25: 8,
  land_53_27: 8,
  "land_26_-10": 1,
  land_30_13: 8,
  land_53_29: 8,
  land_53_18: 8,
  land_30_10: 8,
  land_54_23: 8,
  land_32_28: 8,
  land_54_27: 8,
  land_35_11: 8,
  land_36_27: 8,
  land_57_10: 8,
  "land_26_-3": 1,
  land_35_26: 8,
  land_57_17: 8,
  land_57_15: 8,
  land_10_24: 8,
  land_10_27: 8,
  land_10_25: 8,
  land_12_12: 8,
  land_12_29: 8,
  land_15_12: 8,
  land_15_11: 8,
  "land_26_-4": 1,
  land_14_24: 8,
  land_15_23: 8,
  land_16_29: 8,
  land_17_11: 8,
  land_17_14: 8,
  land_17_24: 8,
  land_20_16: 8,
  land_20_10: 8,
  land_19_27: 8,
  land_19_12: 8,
  "land_26_-6": 1,
  land_19_22: 8,
  land_20_12: 8,
  land_18_22: 8,
  land_21_18: 8,
  land_21_15: 8,
  land_21_11: 8,
  land_24_11: 8,
  land_24_13: 8,
  land_20_27: 8,
  land_25_11: 8,
  "land_26_-7": 1,
  land_26_20: 8,
  land_27_14: 8,
  land_30_12: 8,
  land_33_26: 8,
  land_35_28: 8,
  land_35_19: 8,
  land_36_21: 8,
  land_35_27: 8,
  land_38_21: 8,
  land_42_27: 8,
  "land_26_-8": 1,
  land_44_25: 8,
  land_45_11: 8,
  land_45_14: 8,
  land_47_23: 8,
  land_53_25: 8,
  land_53_23: 8,
  land_54_20: 8,
  land_10_17: 8,
  land_11_27: 8,
  land_15_13: 8,
  land_71_11: 1,
  "land_26_-9": 1,
  land_15_29: 8,
  land_13_26: 8,
  land_16_23: 8,
  land_16_28: 8,
  land_17_16: 8,
  land_18_15: 8,
  land_19_14: 8,
  land_19_17: 8,
  land_19_25: 8,
  land_19_23: 8,
  land_26_0: 1,
  land_20_26: 8,
  land_21_19: 8,
  land_22_15: 8,
  land_23_16: 8,
  land_22_25: 8,
  land_24_12: 8,
  land_20_28: 8,
  land_26_19: 8,
  land_27_13: 8,
  land_27_12: 8,
  land_26_2: 1,
  land_27_27: 8,
  land_31_24: 8,
  land_10_28: 8,
  land_16_12: 8,
  land_16_27: 8,
  land_17_12: 8,
  land_18_24: 8,
  land_18_29: 8,
  land_19_15: 8,
  land_19_28: 8,
  land_26_3: 1,
  land_19_26: 8,
  land_20_11: 8,
  land_19_18: 8,
  land_21_20: 8,
  land_21_28: 8,
  land_21_24: 8,
  land_22_14: 8,
  land_22_17: 8,
  land_22_23: 8,
  land_22_24: 8,
  land_26_4: 1,
  land_23_10: 8,
  land_25_10: 8,
  land_25_26: 8,
  land_26_15: 8,
  land_26_26: 8,
  land_28_12: 8,
  land_27_29: 8,
  land_32_22: 8,
  land_33_25: 8,
  land_34_26: 8,
  land_26_5: 1,
  land_36_26: 8,
  land_35_24: 8,
  land_35_29: 8,
  land_39_24: 8,
  land_39_18: 8,
  land_38_15: 8,
  land_39_27: 8,
  land_42_24: 8,
  land_41_22: 8,
  land_43_29: 8,
  land_26_7: 1,
  land_46_24: 8,
  land_46_23: 8,
  land_49_22: 8,
  land_53_20: 8,
  land_53_24: 8,
  land_54_26: 8,
  land_38_22: 8,
  land_38_24: 8,
  land_39_19: 8,
  land_38_17: 8,
  land_26_8: 1,
  land_38_20: 8,
  land_40_20: 8,
  land_40_26: 8,
  land_40_28: 8,
  land_42_17: 8,
  land_35_22: 8,
  land_53_15: 8,
  land_52_20: 8,
  land_57_16: 8,
  land_57_14: 8,
  land_26_9: 1,
  land_29_13: 8,
  land_34_28: 8,
  land_36_28: 8,
  land_38_23: 8,
  land_39_17: 8,
  land_39_23: 8,
  land_38_25: 8,
  land_42_15: 8,
  land_42_28: 8,
  land_44_26: 8,
  "land_27_-10": 1,
  land_46_11: 8,
  land_52_21: 8,
  land_53_14: 8,
  land_54_22: 8,
  land_54_28: 8,
  land_56_22: 8,
  land_57_13: 8,
  land_32_23: 8,
  land_34_25: 8,
  land_34_29: 8,
  land_24_25: 1,
  land_71_12: 1,
  "land_27_-3": 1,
  land_35_25: 8,
  land_36_29: 8,
  land_36_18: 8,
  land_36_19: 8,
  land_37_28: 8,
  land_37_26: 8,
  land_37_27: 8,
  land_40_16: 8,
  land_40_17: 8,
  land_40_23: 8,
  "land_27_-6": 1,
  land_42_20: 8,
  land_42_21: 8,
  land_41_27: 8,
  land_43_28: 8,
  land_45_17: 8,
  land_45_15: 8,
  land_46_13: 8,
  land_46_15: 8,
  land_47_14: 8,
  land_51_25: 8,
  "land_27_-7": 1,
  land_51_24: 8,
  land_53_19: 8,
  land_54_25: 8,
  land_55_26: 8,
  land_59_11: 8,
  land_58_13: 8,
  land_59_12: 8,
  land_59_23: 8,
  land_59_28: 8,
  land_59_19: 8,
  "land_27_-8": 1,
  land_59_29: 8,
  land_57_29: 8,
  land_59_26: 8,
  land_59_24: 8,
  land_58_20: 8,
  land_59_14: 8,
  land_58_14: 8,
  land_58_29: 8,
  land_59_20: 8,
  land_58_15: 8,
  "land_27_-9": 1,
  land_59_15: 8,
  land_59_21: 8,
  land_59_17: 8,
  land_59_27: 8,
  land_59_22: 8,
  land_58_28: 8,
  land_59_18: 8,
  land_59_16: 8,
  land_58_17: 8,
  land_59_10: 8,
  land_27_2: 1,
  land_59_13: 8,
  land_58_27: 8,
  land_57_28: 8,
  land_58_11: 8,
  land_58_16: 8,
  "land_60_-13": 8,
  "land_60_-14": 8,
  "land_60_-15": 8,
  land_60_0: 8,
  land_60_10: 8,
  land_27_3: 1,
  land_60_11: 8,
  land_60_12: 8,
  land_60_13: 8,
  land_60_14: 8,
  land_60_15: 8,
  land_60_16: 8,
  land_60_17: 8,
  land_60_2: 8,
  land_60_28: 8,
  land_60_3: 8,
  land_27_4: 1,
  land_60_4: 8,
  land_60_6: 8,
  land_60_8: 8,
  land_60_9: 8,
  "land_61_-11": 8,
  "land_61_-15": 8,
  "land_61_-16": 8,
  "land_61_-17": 8,
  "land_61_-18": 8,
  "land_61_-19": 8,
  land_27_5: 1,
  land_61_10: 8,
  land_61_11: 8,
  land_61_12: 8,
  land_61_17: 8,
  land_61_18: 8,
  land_61_19: 8,
  land_61_20: 8,
  land_61_6: 8,
  land_61_7: 8,
  land_61_9: 8,
  land_27_6: 1,
  "land_62_-10": 8,
  "land_62_-13": 8,
  "land_62_-16": 8,
  "land_62_-18": 8,
  "land_62_-19": 8,
  "land_62_-7": 8,
  "land_62_-8": 8,
  "land_62_-9": 8,
  land_62_10: 8,
  land_62_11: 8,
  land_71_13: 1,
  land_27_7: 1,
  land_62_12: 8,
  land_62_13: 8,
  land_62_14: 8,
  land_62_16: 8,
  land_62_17: 8,
  land_62_18: 8,
  land_62_19: 8,
  land_62_2: 8,
  land_62_20: 8,
  land_62_21: 8,
  land_27_8: 1,
  land_62_25: 8,
  land_62_26: 8,
  land_62_27: 8,
  land_62_28: 8,
  land_62_29: 8,
  land_62_3: 8,
  land_62_4: 8,
  land_62_5: 8,
  land_62_6: 8,
  land_62_7: 8,
  land_27_9: 1,
  land_62_8: 8,
  land_62_9: 8,
  "land_63_-10": 8,
  "land_63_-12": 8,
  "land_63_-13": 8,
  "land_63_-14": 8,
  "land_63_-16": 8,
  "land_63_-17": 8,
  "land_63_-18": 8,
  "land_63_-7": 8,
  "land_28_-1": 1,
  "land_63_-8": 8,
  "land_63_-9": 8,
  land_63_10: 8,
  land_63_11: 8,
  land_63_12: 8,
  land_63_13: 8,
  land_63_14: 8,
  land_63_15: 8,
  land_63_16: 8,
  land_63_17: 8,
  "land_28_-10": 1,
  land_63_18: 8,
  land_63_19: 8,
  land_63_20: 8,
  land_63_23: 8,
  land_63_24: 8,
  land_63_25: 8,
  land_63_26: 8,
  land_63_27: 8,
  land_63_28: 8,
  land_63_29: 8,
  "land_28_-2": 1,
  land_63_6: 8,
  land_63_7: 8,
  land_63_8: 8,
  land_63_9: 8,
  "land_64_-1": 8,
  "land_64_-10": 8,
  "land_64_-14": 8,
  "land_64_-16": 8,
  "land_64_-2": 8,
  "land_64_-3": 8,
  "land_28_-3": 1,
  "land_64_-6": 8,
  "land_64_-7": 8,
  "land_64_-8": 8,
  "land_64_-9": 8,
  land_64_0: 8,
  land_64_1: 8,
  land_64_10: 8,
  land_64_11: 8,
  land_64_12: 8,
  land_64_13: 8,
  "land_28_-4": 1,
  land_64_14: 8,
  land_64_15: 8,
  land_64_16: 8,
  land_64_17: 8,
  land_64_18: 8,
  land_64_19: 8,
  land_64_2: 8,
  land_64_20: 8,
  land_64_22: 8,
  land_64_23: 8,
  "land_28_-5": 1,
  land_64_24: 8,
  land_64_25: 8,
  land_64_26: 8,
  land_64_27: 8,
  land_64_28: 8,
  land_64_29: 8,
  land_64_3: 8,
  land_64_4: 8,
  land_64_5: 8,
  land_64_6: 8,
  "land_28_-6": 1,
  land_64_7: 8,
  land_64_8: 8,
  land_64_9: 8,
  "land_65_-1": 8,
  "land_65_-14": 8,
  "land_65_-16": 8,
  "land_65_-17": 8,
  "land_65_-2": 8,
  "land_65_-3": 8,
  "land_65_-4": 8,
  land_71_14: 1,
  "land_28_-7": 1,
  "land_65_-5": 8,
  "land_65_-6": 8,
  "land_65_-7": 8,
  "land_65_-8": 8,
  "land_65_-9": 8,
  land_65_0: 8,
  land_65_1: 8,
  land_65_10: 8,
  land_65_11: 8,
  land_65_12: 8,
  "land_28_-8": 1,
  land_65_13: 8,
  land_65_14: 8,
  land_65_15: 8,
  land_65_16: 8,
  land_65_17: 8,
  land_65_18: 8,
  land_65_19: 8,
  land_65_2: 8,
  land_65_20: 8,
  land_65_22: 8,
  "land_28_-9": 1,
  land_65_23: 8,
  land_65_24: 8,
  land_65_25: 8,
  land_65_26: 8,
  land_65_27: 8,
  land_65_28: 8,
  land_65_29: 8,
  land_65_3: 8,
  land_65_4: 8,
  land_65_5: 8,
  land_28_0: 1,
  land_65_6: 8,
  land_65_7: 8,
  land_65_8: 8,
  land_65_9: 8,
  "land_66_-1": 8,
  "land_66_-10": 8,
  "land_66_-14": 8,
  "land_66_-16": 8,
  "land_66_-17": 8,
  "land_66_-2": 8,
  land_28_2: 1,
  "land_66_-3": 8,
  "land_66_-4": 8,
  "land_66_-5": 8,
  "land_66_-6": 8,
  "land_66_-7": 8,
  "land_66_-8": 8,
  "land_66_-9": 8,
  land_66_0: 8,
  land_66_1: 8,
  land_66_10: 8,
  land_28_5: 1,
  land_66_12: 8,
  land_66_13: 8,
  land_66_14: 8,
  land_66_15: 8,
  land_66_16: 8,
  land_66_17: 8,
  land_66_18: 8,
  land_66_19: 8,
  land_66_2: 8,
  land_66_20: 8,
  land_28_7: 1,
  land_66_21: 8,
  land_66_22: 8,
  land_66_23: 8,
  land_66_24: 8,
  land_66_25: 8,
  land_66_26: 8,
  land_66_27: 8,
  land_66_28: 8,
  land_66_29: 8,
  land_66_3: 8,
  land_28_8: 1,
  land_66_4: 8,
  land_66_5: 8,
  land_66_6: 8,
  land_66_7: 8,
  land_66_8: 8,
  "land_67_-10": 8,
  "land_67_-14": 8,
  "land_67_-15": 8,
  "land_67_-16": 8,
  "land_67_-17": 8,
  land_28_9: 1,
  "land_67_-3": 8,
  "land_67_-4": 8,
  "land_67_-5": 8,
  "land_67_-6": 8,
  "land_67_-7": 8,
  "land_67_-8": 8,
  "land_67_-9": 8,
  land_67_0: 8,
  land_67_1: 8,
  land_67_10: 8,
  "land_29_-1": 1,
  land_67_11: 8,
  land_67_12: 8,
  land_67_13: 8,
  land_67_14: 8,
  land_67_15: 8,
  land_67_16: 8,
  land_67_17: 8,
  land_67_18: 8,
  land_67_19: 8,
  land_67_2: 8,
  land_71_15: 1,
  "land_29_-10": 1,
  land_67_20: 8,
  land_67_21: 8,
  land_67_22: 8,
  land_67_24: 8,
  land_67_25: 8,
  land_67_26: 8,
  land_67_27: 8,
  land_67_28: 8,
  land_67_29: 8,
  land_67_3: 8,
  "land_29_-2": 1,
  land_67_4: 8,
  land_67_5: 8,
  land_67_6: 8,
  land_67_7: 8,
  land_67_8: 8,
  "land_68_-10": 8,
  "land_68_-14": 8,
  "land_68_-15": 8,
  "land_68_-16": 8,
  "land_68_-17": 8,
  "land_29_-3": 1,
  "land_68_-3": 8,
  "land_68_-4": 8,
  "land_68_-5": 8,
  "land_68_-6": 8,
  "land_68_-7": 8,
  "land_68_-8": 8,
  "land_68_-9": 8,
  land_68_0: 8,
  land_68_1: 8,
  land_68_10: 8,
  "land_29_-4": 1,
  land_68_11: 8,
  land_68_12: 8,
  land_68_13: 8,
  land_68_14: 8,
  land_68_15: 8,
  land_68_16: 8,
  land_68_17: 8,
  land_68_18: 8,
  land_68_19: 8,
  land_68_2: 8,
  "land_29_-5": 1,
  land_68_20: 8,
  land_68_21: 8,
  land_68_22: 8,
  land_68_23: 8,
  land_68_24: 8,
  land_68_25: 8,
  land_68_26: 8,
  land_68_27: 8,
  land_68_28: 8,
  land_68_29: 8,
  "land_29_-6": 1,
  land_68_3: 8,
  land_68_4: 8,
  land_68_5: 8,
  land_68_7: 8,
  land_68_8: 8,
  land_68_9: 8,
  "land_69_-10": 8,
  "land_69_-14": 8,
  "land_69_-16": 8,
  "land_69_-17": 8,
  "land_29_-7": 1,
  "land_69_-3": 8,
  "land_69_-4": 8,
  "land_69_-5": 8,
  "land_69_-6": 8,
  "land_69_-7": 8,
  "land_69_-8": 8,
  "land_69_-9": 8,
  land_69_0: 8,
  land_69_1: 8,
  land_69_10: 8,
  "land_29_-8": 1,
  land_69_11: 8,
  land_69_13: 8,
  land_69_14: 8,
  land_69_15: 8,
  land_69_16: 8,
  land_69_17: 8,
  land_69_18: 8,
  land_69_19: 8,
  land_69_2: 8,
  land_69_20: 8,
  "land_29_-9": 1,
  land_69_21: 8,
  land_69_22: 8,
  land_69_23: 8,
  land_69_24: 8,
  land_69_25: 8,
  land_69_26: 8,
  land_69_27: 8,
  land_69_28: 8,
  land_69_29: 8,
  land_69_3: 8,
  land_29_0: 1,
  land_69_4: 8,
  land_69_5: 8,
  land_69_7: 8,
  land_69_8: 8,
  land_69_9: 8,
  "land_70_-10": 8,
  "land_70_-14": 8,
  "land_70_-15": 8,
  "land_70_-16": 8,
  "land_70_-17": 8,
  land_71_2: 1,
  land_29_1: 1,
  "land_70_-18": 8,
  "land_70_-19": 8,
  "land_70_-3": 8,
  "land_70_-4": 8,
  "land_70_-5": 8,
  "land_70_-6": 8,
  "land_70_-7": 8,
  "land_70_-8": 8,
  land_70_0: 8,
  land_70_1: 8,
  land_29_2: 1,
  land_70_10: 8,
  land_70_11: 8,
  land_70_12: 8,
  land_70_17: 8,
  land_70_18: 8,
  land_70_19: 8,
  land_70_2: 8,
  land_70_20: 8,
  land_70_21: 8,
  land_70_22: 8,
  land_29_3: 1,
  land_70_23: 8,
  land_70_24: 8,
  land_70_25: 8,
  land_70_26: 8,
  land_70_27: 8,
  land_70_28: 8,
  land_70_29: 8,
  land_70_3: 8,
  land_70_4: 8,
  land_70_5: 8,
  land_29_4: 1,
  land_70_6: 8,
  land_70_8: 8,
  land_70_9: 8,
  "land_71_-10": 8,
  "land_71_-14": 8,
  "land_71_-15": 8,
  "land_71_-16": 8,
  "land_71_-17": 8,
  "land_71_-18": 8,
  "land_71_-19": 8,
  land_29_5: 1,
  "land_71_-3": 8,
  "land_71_-4": 8,
  "land_71_-5": 8,
  "land_71_-6": 8,
  "land_71_-7": 8,
  "land_71_-8": 8,
  land_71_0: 8,
  land_71_1: 8,
  land_71_10: 8,
  land_71_16: 8,
  land_29_6: 1,
  land_71_17: 8,
  land_71_18: 8,
  land_71_19: 8,
  land_71_20: 8,
  land_71_21: 8,
  land_71_22: 8,
  land_71_23: 8,
  land_71_24: 8,
  land_71_26: 8,
  land_71_27: 8,
  land_29_7: 1,
  land_71_28: 8,
  land_71_29: 8,
  land_71_3: 8,
  land_71_30: 8,
  land_71_4: 8,
  land_71_5: 8,
  land_71_6: 8,
  land_71_7: 8,
  land_71_8: 8,
  land_71_9: 8,
  land_29_8: 1,
  "land_72_-10": 8,
  "land_72_-14": 8,
  "land_72_-15": 8,
  "land_72_-16": 8,
  "land_72_-17": 8,
  "land_72_-18": 8,
  "land_72_-19": 8,
  "land_72_-3": 8,
  "land_72_-4": 8,
  "land_72_-5": 8,
  land_29_9: 1,
  "land_72_-6": 8,
  "land_72_-7": 8,
  "land_72_-8": 8,
  "land_72_-9": 8,
  land_72_0: 8,
  land_72_1: 8,
  land_72_10: 8,
  land_72_16: 8,
  land_72_17: 8,
  land_72_18: 8,
  land_8_6: 1,
  land_72_19: 8,
  land_72_2: 8,
  land_72_20: 8,
  land_72_21: 8,
  land_72_22: 8,
  land_72_23: 8,
  land_72_24: 8,
  land_72_27: 8,
  land_72_28: 8,
  land_72_29: 8,
  "land_72_-13": 1,
  "land_-10_33": 1,
  land_72_3: 8,
  land_72_30: 8,
  land_72_4: 8,
  land_72_5: 8,
  land_72_6: 8,
  land_72_7: 8,
  land_72_8: 8,
  land_72_9: 8,
  "land_73_-10": 8,
  "land_73_-11": 8,
  "land_-11_32": 1,
  "land_73_-12": 8,
  "land_73_-14": 8,
  "land_73_-15": 8,
  "land_73_-16": 8,
  "land_73_-17": 8,
  "land_73_-18": 8,
  "land_73_-3": 8,
  "land_73_-4": 8,
  "land_73_-5": 8,
  "land_73_-6": 8,
  "land_-13_31": 1,
  "land_73_-7": 8,
  "land_73_-8": 8,
  "land_73_-9": 8,
  land_73_0: 8,
  land_73_1: 8,
  land_73_10: 8,
  land_73_16: 8,
  land_73_17: 8,
  land_73_18: 8,
  land_73_19: 8,
  "land_-15_29": 1,
  land_73_2: 8,
  land_73_20: 8,
  land_73_21: 8,
  land_73_22: 8,
  land_73_23: 8,
  land_73_24: 8,
  land_73_25: 8,
  land_73_26: 8,
  land_73_27: 8,
  land_73_28: 8,
  "land_-17_30": 1,
  land_73_29: 8,
  land_73_3: 8,
  land_73_30: 8,
  land_73_5: 8,
  land_73_6: 8,
  land_73_7: 8,
  land_73_8: 8,
  land_73_9: 8,
  "land_74_-10": 8,
  "land_74_-11": 8,
  "land_-19_30": 1,
  "land_74_-12": 8,
  "land_74_-14": 8,
  "land_74_-15": 8,
  "land_74_-16": 8,
  "land_74_-17": 8,
  "land_74_-18": 8,
  "land_74_-3": 8,
  "land_74_-4": 8,
  "land_74_-5": 8,
  "land_74_-6": 8,
  "land_-20_30": 1,
  "land_74_-7": 8,
  "land_74_-8": 8,
  "land_74_-9": 8,
  land_74_0: 8,
  land_74_1: 8,
  land_74_10: 8,
  land_74_16: 8,
  land_74_17: 8,
  land_74_18: 8,
  land_74_19: 8,
  "land_-21_25": 1,
  land_74_2: 8,
  land_74_20: 8,
  land_74_21: 8,
  land_74_22: 8,
  land_74_23: 8,
  land_74_24: 8,
  land_74_25: 8,
  land_74_26: 8,
  land_74_27: 8,
  land_74_28: 8,
  "land_-40_28": 1,
  land_74_29: 8,
  land_74_3: 8,
  land_74_30: 8,
  land_74_4: 8,
  land_74_5: 8,
  land_74_6: 8,
  land_74_7: 8,
  land_74_8: 8,
  land_74_9: 8,
  "land_75_-10": 8,
  "land_-41_27": 1,
  "land_75_-11": 8,
  "land_75_-12": 8,
  "land_75_-14": 8,
  "land_75_-15": 8,
  "land_75_-16": 8,
  "land_75_-17": 8,
  "land_75_-18": 8,
  "land_75_-3": 8,
  "land_75_-4": 8,
  "land_75_-5": 8,
  land_72_11: 1,
  "land_-42_22": 1,
  "land_75_-6": 8,
  "land_75_-7": 8,
  "land_75_-8": 8,
  "land_75_-9": 8,
  land_75_0: 8,
  land_75_1: 8,
  land_75_10: 8,
  land_75_16: 8,
  land_75_17: 8,
  land_75_18: 8,
  "land_-44_-2": 1,
  land_75_19: 8,
  land_75_2: 8,
  land_75_20: 8,
  land_75_21: 8,
  land_75_22: 8,
  land_75_23: 8,
  land_75_24: 8,
  land_75_25: 8,
  land_75_26: 8,
  land_75_27: 8,
  "land_-45_25": 1,
  land_75_28: 8,
  land_75_29: 8,
  land_75_3: 8,
  land_75_30: 8,
  land_75_4: 8,
  land_75_5: 8,
  land_75_6: 8,
  land_75_7: 8,
  land_75_8: 8,
  land_75_9: 8,
  "land_-45_26": 1,
  "land_76_-10": 8,
  "land_76_-11": 8,
  "land_76_-12": 8,
  "land_76_-14": 8,
  "land_76_-15": 8,
  "land_76_-16": 8,
  "land_76_-17": 8,
  "land_76_-3": 8,
  "land_76_-4": 8,
  "land_76_-5": 8,
  "land_-47_23": 1,
  "land_76_-6": 8,
  "land_76_-7": 8,
  "land_76_-8": 8,
  "land_76_-9": 8,
  land_76_0: 8,
  land_76_1: 8,
  land_76_10: 8,
  land_76_16: 8,
  land_76_17: 8,
  land_76_18: 8,
  "land_-47_5": 1,
  land_76_19: 8,
  land_76_21: 8,
  land_76_22: 8,
  land_76_23: 8,
  land_76_24: 8,
  land_76_25: 8,
  land_76_26: 8,
  land_76_27: 8,
  land_76_28: 8,
  land_76_29: 8,
  "land_-48_0": 1,
  land_76_3: 8,
  land_76_30: 8,
  land_76_4: 8,
  land_76_5: 8,
  land_76_7: 8,
  land_76_9: 8,
  "land_77_-11": 8,
  "land_77_-12": 8,
  "land_77_-14": 8,
  "land_77_-15": 8,
  "land_-48_21": 1,
  "land_77_-16": 8,
  "land_77_-17": 8,
  "land_77_-3": 8,
  "land_77_-4": 8,
  "land_77_-5": 8,
  "land_77_-6": 8,
  "land_77_-7": 8,
  "land_77_-8": 8,
  "land_77_-9": 8,
  land_77_0: 8,
  "land_-48_5": 1,
  land_77_1: 8,
  land_77_10: 8,
  land_77_16: 8,
  land_77_17: 8,
  land_77_18: 8,
  land_77_19: 8,
  land_77_2: 8,
  land_77_20: 8,
  land_77_21: 8,
  land_77_22: 8,
  "land_-49_20": 1,
  land_77_23: 8,
  land_77_24: 8,
  land_77_25: 8,
  land_77_26: 8,
  land_77_27: 8,
  land_77_28: 8,
  land_77_29: 8,
  land_77_3: 8,
  land_77_30: 8,
  land_77_4: 8,
  land_72_12: 1,
  "land_-53_10": 1,
  land_77_5: 8,
  land_77_6: 8,
  land_77_7: 8,
  land_77_8: 8,
  land_77_9: 8,
  "land_78_-11": 8,
  "land_78_-12": 8,
  "land_78_-14": 8,
  "land_78_-15": 8,
  "land_78_-16": 8,
  "land_-53_11": 1,
  "land_78_-17": 8,
  "land_78_-3": 8,
  "land_78_-4": 8,
  "land_78_-5": 8,
  "land_78_-6": 8,
  "land_78_-7": 8,
  "land_78_-8": 8,
  "land_78_-9": 8,
  land_78_0: 8,
  land_78_1: 8,
  "land_-53_12": 1,
  land_78_10: 8,
  land_78_16: 8,
  land_78_17: 8,
  land_78_18: 8,
  land_78_19: 8,
  land_78_2: 8,
  land_78_20: 8,
  land_78_21: 8,
  land_78_22: 8,
  land_78_23: 8,
  "land_-53_13": 1,
  land_78_24: 8,
  land_78_25: 8,
  land_78_26: 8,
  land_78_27: 8,
  land_78_28: 8,
  land_78_29: 8,
  land_78_3: 8,
  land_78_30: 8,
  land_78_4: 8,
  land_78_5: 8,
  "land_-54_10": 1,
  land_78_6: 8,
  land_78_7: 8,
  land_78_8: 8,
  land_78_9: 8,
  "land_79_-1": 8,
  "land_79_-10": 8,
  "land_79_-11": 8,
  "land_79_-12": 8,
  "land_79_-3": 8,
  "land_79_-4": 8,
  "land_-54_12": 1,
  "land_79_-5": 8,
  "land_79_-6": 8,
  "land_79_-7": 8,
  "land_79_-8": 8,
  land_79_0: 8,
  land_79_1: 8,
  land_79_10: 8,
  land_79_11: 8,
  land_79_12: 8,
  land_79_13: 8,
  "land_-54_13": 1,
  land_79_14: 8,
  land_79_15: 8,
  land_79_16: 8,
  land_79_17: 8,
  land_79_18: 8,
  land_79_19: 8,
  land_79_2: 8,
  land_79_20: 8,
  land_79_21: 8,
  land_79_22: 8,
  "land_-55_10": 1,
  land_79_23: 8,
  land_79_24: 8,
  land_79_25: 8,
  land_79_26: 8,
  land_79_27: 8,
  land_79_28: 8,
  land_79_29: 8,
  land_79_3: 8,
  land_79_30: 8,
  land_79_4: 8,
  "land_-55_12": 1,
  land_79_5: 8,
  land_79_7: 8,
  land_79_8: 8,
  land_79_9: 8,
  "land_80_-10": 8,
  "land_80_-3": 8,
  "land_80_-4": 8,
  "land_80_-5": 8,
  "land_80_-6": 8,
  "land_80_-7": 8,
  "land_-55_13": 1,
  "land_80_-9": 8,
  land_80_0: 8,
  land_80_1: 8,
  land_80_10: 8,
  land_80_11: 8,
  land_80_12: 8,
  land_80_13: 8,
  land_80_14: 8,
  land_80_15: 8,
  land_80_16: 8,
  land_72_13: 1,
  "land_-56_10": 1,
  land_80_17: 8,
  land_80_18: 8,
  land_80_19: 8,
  land_80_2: 8,
  land_80_20: 8,
  land_80_21: 8,
  land_80_22: 8,
  land_80_23: 8,
  land_80_24: 8,
  land_80_25: 8,
  "land_-56_11": 1,
  land_80_26: 8,
  land_80_27: 8,
  land_80_28: 8,
  land_80_29: 8,
  land_80_3: 8,
  land_80_5: 8,
  land_80_6: 8,
  land_80_7: 8,
  land_80_8: 8,
  land_80_9: 8,
  "land_-56_13": 1,
  "land_81_-3": 8,
  "land_81_-4": 8,
  "land_81_-5": 8,
  "land_81_-6": 8,
  "land_81_-7": 8,
  "land_81_-8": 8,
  land_81_0: 8,
  land_81_1: 8,
  land_81_10: 8,
  land_81_11: 8,
  "land_-57_10": 1,
  land_81_12: 8,
  land_81_13: 8,
  land_81_15: 8,
  land_81_16: 8,
  land_81_17: 8,
  land_81_18: 8,
  land_81_19: 8,
  land_81_2: 8,
  land_81_20: 8,
  land_81_21: 8,
  "land_-57_11": 1,
  land_81_22: 8,
  land_81_23: 8,
  land_81_24: 8,
  land_81_25: 8,
  land_81_26: 8,
  land_81_27: 8,
  land_81_28: 8,
  land_81_29: 8,
  land_81_3: 8,
  land_81_30: 8,
  "land_-57_12": 1,
  land_81_4: 8,
  land_81_5: 8,
  land_81_6: 8,
  land_81_7: 8,
  land_81_8: 8,
  land_81_9: 8,
  "land_82_-10": 8,
  "land_82_-3": 8,
  "land_82_-4": 8,
  "land_82_-5": 8,
  "land_-57_13": 1,
  "land_82_-6": 8,
  "land_82_-7": 8,
  "land_82_-8": 8,
  "land_82_-9": 8,
  land_82_1: 8,
  land_82_10: 8,
  land_82_11: 8,
  land_82_14: 8,
  land_82_15: 8,
  land_82_16: 8,
  "land_-59_3": 1,
  land_82_17: 8,
  land_82_18: 8,
  land_82_19: 8,
  land_82_2: 8,
  land_82_20: 8,
  land_82_21: 8,
  land_82_22: 8,
  land_82_23: 8,
  land_82_24: 8,
  land_82_25: 8,
  "land_-60_-3": 1,
  land_82_3: 8,
  land_82_4: 8,
  land_82_5: 8,
  land_82_6: 8,
  land_82_7: 8,
  land_82_8: 8,
  land_82_9: 8,
  "land_83_-10": 8,
  "land_83_-3": 8,
  "land_83_-4": 8,
  "land_-25_-28": 1,
  "land_83_-5": 8,
  "land_83_-6": 8,
  "land_83_-7": 8,
  "land_83_-8": 8,
  "land_83_-9": 8,
  land_83_0: 8,
  land_83_1: 8,
  land_83_10: 8,
  land_83_11: 8,
  land_83_12: 8,
  land_72_14: 1,
  "land_-26_-11": 1,
  land_83_13: 8,
  land_83_14: 8,
  land_83_15: 8,
  land_83_16: 8,
  land_83_17: 8,
  land_83_18: 8,
  land_83_19: 8,
  land_83_2: 8,
  land_83_20: 8,
  land_83_21: 8,
  "land_-26_-12": 1,
  land_83_22: 8,
  land_83_23: 8,
  land_83_24: 8,
  land_83_25: 8,
  land_83_3: 8,
  land_83_4: 8,
  land_83_5: 8,
  land_83_6: 8,
  land_83_7: 8,
  land_83_8: 8,
  "land_-26_-14": 1,
  land_83_9: 8,
  "land_84_-10": 8,
  "land_84_-3": 8,
  "land_84_-4": 8,
  "land_84_-5": 8,
  "land_84_-6": 8,
  "land_84_-7": 8,
  "land_84_-8": 8,
  "land_84_-9": 8,
  land_84_0: 8,
  "land_-26_-19": 1,
  land_84_1: 8,
  land_84_10: 8,
  land_84_12: 8,
  land_84_14: 8,
  land_84_15: 8,
  land_84_16: 8,
  land_84_17: 8,
  land_84_18: 8,
  land_84_19: 8,
  land_84_2: 8,
  "land_-26_-28": 1,
  land_84_20: 8,
  land_84_21: 8,
  land_84_22: 8,
  land_84_23: 8,
  land_84_24: 8,
  land_84_25: 8,
  land_84_3: 8,
  land_84_4: 8,
  land_84_5: 8,
  land_84_6: 8,
  "land_-26_-32": 1,
  land_84_7: 8,
  land_84_8: 8,
  land_84_9: 8,
  "land_85_-2": 8,
  "land_85_-3": 8,
  "land_85_-4": 8,
  "land_85_-5": 8,
  "land_85_-6": 8,
  "land_85_-7": 8,
  land_85_0: 8,
  "land_-27_-19": 1,
  land_85_1: 8,
  land_85_10: 8,
  land_85_11: 8,
  land_85_12: 8,
  land_85_13: 8,
  land_85_14: 8,
  land_85_15: 8,
  land_85_16: 8,
  land_85_17: 8,
  land_85_18: 8,
  "land_-27_-32": 1,
  land_85_19: 8,
  land_85_2: 8,
  land_85_20: 8,
  land_85_21: 8,
  land_85_22: 8,
  land_85_23: 8,
  land_85_24: 8,
  land_85_25: 8,
  land_85_26: 8,
  land_85_3: 8,
  "land_-27_-33": 1,
  land_85_4: 8,
  land_85_5: 8,
  land_85_6: 8,
  land_85_7: 8,
  land_85_8: 8,
  land_85_9: 8,
  "land_86_-3": 8,
  "land_86_-4": 8,
  "land_86_-5": 8,
  "land_86_-7": 8,
  "land_-28_-11": 1,
  land_86_0: 8,
  land_86_1: 8,
  land_86_10: 8,
  land_86_11: 8,
  land_86_12: 8,
  land_86_13: 8,
  land_86_14: 8,
  land_86_15: 8,
  land_86_16: 8,
  land_86_17: 8,
  land_40_25: 1,
  land_72_15: 1,
  "land_-28_-15": 1,
  land_86_18: 8,
  land_86_19: 8,
  land_86_2: 8,
  land_86_20: 8,
  land_86_21: 8,
  land_86_22: 8,
  land_86_23: 8,
  land_86_24: 8,
  land_86_25: 8,
  land_86_26: 8,
  "land_-28_-19": 1,
  land_86_3: 8,
  land_86_4: 8,
  land_86_5: 8,
  land_86_6: 8,
  land_86_7: 8,
  land_86_8: 8,
  land_86_9: 8,
  "land_87_-3": 8,
  "land_87_-4": 8,
  "land_87_-5": 8,
  "land_-28_-32": 1,
  "land_87_-6": 8,
  "land_87_-7": 8,
  land_87_0: 8,
  land_87_1: 8,
  land_87_10: 8,
  land_87_11: 8,
  land_87_12: 8,
  land_87_13: 8,
  land_87_14: 8,
  land_87_15: 8,
  "land_-28_-33": 1,
  land_87_16: 8,
  land_87_17: 8,
  land_87_18: 8,
  land_87_19: 8,
  land_87_2: 8,
  land_87_20: 8,
  land_87_21: 8,
  land_87_22: 8,
  land_87_23: 8,
  land_87_24: 8,
  "land_-29_-15": 1,
  land_87_25: 8,
  land_87_3: 8,
  land_87_4: 8,
  land_87_5: 8,
  land_87_6: 8,
  land_87_7: 8,
  land_87_8: 8,
  land_87_9: 8,
  land_88_10: 8,
  land_88_11: 8,
  "land_-29_-33": 1,
  land_88_12: 8,
  land_88_13: 8,
  land_88_14: 8,
  land_88_15: 8,
  land_88_16: 8,
  land_88_17: 8,
  land_88_18: 8,
  land_88_8: 8,
  land_88_9: 8,
  land_89_10: 8,
  "land_-30_-11": 1,
  land_89_11: 8,
  land_89_12: 8,
  land_89_13: 8,
  land_89_14: 8,
  land_89_15: 8,
  land_89_16: 8,
  land_89_17: 8,
  land_89_18: 8,
  land_89_7: 8,
  land_89_8: 8,
  "land_-30_-14": 1,
  land_89_9: 8,
  land_90_10: 8,
  land_90_11: 8,
  land_90_12: 8,
  land_90_13: 8,
  land_90_14: 8,
  land_90_15: 8,
  land_90_16: 8,
  land_90_17: 8,
  land_90_18: 8,
  "land_-30_-15": 1,
  land_90_7: 8,
  land_90_8: 8,
  land_90_9: 8,
  "land_-100_-10": 8,
  "land_-100_-11": 8,
  "land_-100_-15": 8,
  "land_-100_-16": 8,
  "land_-100_-17": 8,
  "land_-100_-18": 8,
  "land_-100_-3": 8,
  "land_-30_-19": 1,
  "land_-100_-5": 8,
  "land_-100_0": 8,
  "land_-100_1": 8,
  "land_-100_3": 8,
  "land_-100_4": 8,
  "land_-100_5": 8,
  "land_-100_6": 8,
  "land_-101_-1": 8,
  "land_-101_-10": 8,
  "land_-101_-11": 8,
  land_72_25: 1,
  "land_-30_-20": 1,
  "land_-101_-14": 8,
  "land_-101_-2": 8,
  "land_-101_-3": 8,
  "land_-101_-4": 8,
  "land_-101_-5": 8,
  "land_-101_-8": 8,
  "land_-101_-9": 8,
  "land_-101_0": 8,
  "land_-101_1": 8,
  "land_-101_2": 8,
  "land_-31_-19": 1,
  "land_-101_3": 8,
  "land_-101_4": 8,
  "land_-101_5": 8,
  "land_-101_6": 8,
  "land_-102_-1": 8,
  "land_-102_-11": 8,
  "land_-102_-12": 8,
  "land_-102_-14": 8,
  "land_-102_-2": 8,
  "land_-102_-3": 8,
  "land_-31_-20": 1,
  "land_-102_-4": 8,
  "land_-102_-5": 8,
  "land_-102_-6": 8,
  "land_-102_-7": 8,
  "land_-102_-8": 8,
  "land_-102_-9": 8,
  "land_-102_0": 8,
  "land_-102_1": 8,
  "land_-102_2": 8,
  "land_-102_3": 8,
  "land_-31_-35": 1,
  "land_-102_4": 8,
  "land_-102_5": 8,
  "land_-102_6": 8,
  "land_-103_-1": 8,
  "land_-103_-11": 8,
  "land_-103_-13": 8,
  "land_-103_-14": 8,
  "land_-103_-2": 8,
  "land_-103_-3": 8,
  "land_-103_-4": 8,
  "land_-32_-20": 1,
  "land_-103_-5": 8,
  "land_-103_-6": 8,
  "land_-103_-7": 8,
  "land_-103_-8": 8,
  "land_-103_-9": 8,
  "land_-103_0": 8,
  "land_-103_1": 8,
  "land_-103_2": 8,
  "land_-103_3": 8,
  "land_-103_4": 8,
  "land_-32_-9": 1,
  "land_-103_5": 8,
  "land_-103_6": 8,
  "land_-104_-1": 8,
  "land_-104_-10": 8,
  "land_-104_-3": 8,
  "land_-104_-4": 8,
  "land_-104_-5": 8,
  "land_-104_-6": 8,
  "land_-104_-7": 8,
  "land_-104_-8": 8,
  "land_-33_-19": 1,
  "land_-104_-9": 8,
  "land_-104_0": 8,
  "land_-104_1": 8,
  "land_-105_-1": 8,
  "land_-105_-10": 8,
  "land_-105_-2": 8,
  "land_-105_-3": 8,
  "land_-105_-4": 8,
  "land_-105_-6": 8,
  "land_-105_-7": 8,
  "land_-33_-20": 1,
  "land_-105_-8": 8,
  "land_-105_-9": 8,
  "land_-105_0": 8,
  "land_-105_1": 8,
  "land_-106_-1": 8,
  "land_-106_-10": 8,
  "land_-106_-12": 8,
  "land_-106_-2": 8,
  "land_-106_-3": 8,
  "land_-106_-5": 8,
  "land_-33_-7": 1,
  "land_-106_-6": 8,
  "land_-106_-7": 8,
  "land_-106_-8": 8,
  "land_-106_-9": 8,
  "land_-106_0": 8,
  "land_-106_1": 8,
  "land_-107_-1": 8,
  "land_-107_-3": 8,
  "land_-107_-4": 8,
  "land_-107_-5": 8,
  "land_-34_-19": 1,
  "land_-107_-6": 8,
  "land_-107_0": 8,
  "land_-107_1": 8,
  "land_-108_-1": 8,
  "land_-108_-2": 8,
  "land_-108_-3": 8,
  "land_-108_-4": 8,
  "land_-108_-5": 8,
  "land_-108_-6": 8,
  "land_-108_0": 8,
  "land_73_-13": 1,
  "land_-34_-20": 1,
  "land_-108_1": 8,
  "land_-109_-1": 8,
  "land_-109_-2": 8,
  "land_-109_-3": 8,
  "land_-109_-4": 8,
  "land_-109_-5": 8,
  "land_-109_0": 8,
  "land_-109_1": 8,
  "land_-110_-1": 8,
  "land_-110_-2": 8,
  "land_-34_-35": 1,
  "land_-110_-3": 8,
  "land_-110_0": 8,
  "land_-110_1": 8,
  "land_-65_19": 8,
  "land_-65_20": 8,
  "land_-65_21": 8,
  "land_-66_19": 8,
  "land_-66_20": 8,
  "land_-66_21": 8,
  "land_-67_19": 8,
  "land_-35_-19": 1,
  "land_-67_20": 8,
  "land_-67_21": 8,
  "land_-68_20": 8,
  "land_-68_21": 8,
  "land_-69_19": 8,
  "land_-69_20": 8,
  "land_-70_19": 8,
  "land_-70_20": 8,
  "land_-70_21": 8,
  "land_-71_11": 8,
  "land_-35_-20": 1,
  "land_-71_12": 8,
  "land_-71_13": 8,
  "land_-71_14": 8,
  "land_-71_16": 8,
  "land_-71_17": 8,
  "land_-71_18": 8,
  "land_-71_19": 8,
  "land_-71_20": 8,
  "land_-72_11": 8,
  "land_-72_12": 8,
  "land_-37_-19": 1,
  "land_-72_13": 8,
  "land_-72_14": 8,
  "land_-72_15": 8,
  "land_-72_16": 8,
  "land_-72_17": 8,
  "land_-72_18": 8,
  "land_-72_19": 8,
  "land_-72_20": 8,
  "land_-73_11": 8,
  "land_-73_12": 8,
  "land_-37_-20": 1,
  "land_-73_13": 8,
  "land_-73_14": 8,
  "land_-73_15": 8,
  "land_-73_16": 8,
  "land_-73_17": 8,
  "land_-73_18": 8,
  "land_-73_19": 8,
  "land_-73_20": 8,
  "land_-74_11": 8,
  "land_-74_12": 8,
  "land_-38_-19": 1,
  "land_-74_13": 8,
  "land_-74_15": 8,
  "land_-74_16": 8,
  "land_-74_17": 8,
  "land_-74_18": 8,
  "land_-74_19": 8,
  "land_-74_20": 8,
  "land_-75_11": 8,
  "land_-75_12": 8,
  "land_-75_13": 8,
  "land_-39_-19": 1,
  "land_-75_14": 8,
  "land_-75_16": 8,
  "land_-75_17": 8,
  "land_-75_18": 8,
  "land_-75_19": 8,
  "land_-75_20": 8,
  "land_-76_11": 8,
  "land_-76_12": 8,
  "land_-76_13": 8,
  "land_-76_15": 8,
  "land_-39_-20": 1,
  "land_-76_16": 8,
  "land_-76_17": 8,
  "land_-76_18": 8,
  "land_-76_19": 8,
  "land_-76_20": 8,
  "land_-77_11": 8,
  "land_-77_12": 8,
  "land_-77_13": 8,
  "land_-77_14": 8,
  "land_-77_15": 8,
  "land_-40_-19": 1,
  "land_-77_16": 8,
  "land_-77_17": 8,
  "land_-77_18": 8,
  "land_-77_19": 8,
  "land_-77_20": 8,
  "land_-78_12": 8,
  "land_-78_13": 8,
  "land_-78_14": 8,
  "land_-78_15": 8,
  "land_-78_16": 8,
  land_73_11: 1,
  "land_-41_-19": 1,
  "land_-78_17": 8,
  "land_-78_18": 8,
  "land_-78_19": 8,
  "land_-78_20": 8,
  "land_-79_11": 8,
  "land_-79_12": 8,
  "land_-79_13": 8,
  "land_-79_14": 8,
  "land_-79_16": 8,
  "land_-79_17": 8,
  "land_-41_-20": 1,
  "land_-79_18": 8,
  "land_-79_19": 8,
  "land_-79_20": 8,
  "land_-80_11": 8,
  "land_-80_12": 8,
  "land_-80_13": 8,
  "land_-80_14": 8,
  "land_-80_15": 8,
  "land_-80_16": 8,
  "land_-80_17": 8,
  "land_-42_-20": 1,
  "land_-80_18": 8,
  "land_-80_19": 8,
  "land_-80_20": 8,
  "land_-81_10": 8,
  "land_-81_11": 8,
  "land_-81_12": 8,
  "land_-81_13": 8,
  "land_-81_14": 8,
  "land_-81_15": 8,
  "land_-81_16": 8,
  "land_-43_-19": 1,
  "land_-81_17": 8,
  "land_-81_18": 8,
  "land_-81_7": 8,
  "land_-81_8": 8,
  "land_-81_9": 8,
  "land_-82_10": 8,
  "land_-82_11": 8,
  "land_-82_12": 8,
  "land_-82_13": 8,
  "land_-82_15": 8,
  "land_-43_-20": 1,
  "land_-82_16": 8,
  "land_-82_17": 8,
  "land_-82_7": 8,
  "land_-82_8": 8,
  "land_-82_9": 8,
  "land_-83_10": 8,
  "land_-83_11": 8,
  "land_-83_12": 8,
  "land_-83_13": 8,
  "land_-83_14": 8,
  "land_-44_-20": 1,
  "land_-83_15": 8,
  "land_-83_7": 8,
  "land_-83_8": 8,
  "land_-83_9": 8,
  "land_-84_10": 8,
  "land_-84_11": 8,
  "land_-84_12": 8,
  "land_-84_13": 8,
  "land_-84_14": 8,
  "land_-84_15": 8,
  "land_-45_-19": 1,
  "land_-84_7": 8,
  "land_-84_8": 8,
  "land_-84_9": 8,
  "land_-85_10": 8,
  "land_-85_11": 8,
  "land_-85_12": 8,
  "land_-85_13": 8,
  "land_-85_14": 8,
  "land_-85_15": 8,
  "land_-85_7": 8,
  "land_-45_-20": 1,
  "land_-85_8": 8,
  "land_-85_9": 8,
  "land_-86_10": 8,
  "land_-86_12": 8,
  "land_-86_13": 8,
  "land_-86_14": 8,
  "land_-86_15": 8,
  "land_-86_7": 8,
  "land_-86_8": 8,
  "land_-86_9": 8,
  "land_-47_-20": 1,
  "land_-87_10": 8,
  "land_-87_11": 8,
  "land_-87_13": 8,
  "land_-87_14": 8,
  "land_-87_7": 8,
  "land_-87_8": 8,
  "land_-88_10": 8,
  "land_-88_11": 8,
  "land_-88_12": 8,
  "land_-88_13": 8,
  "land_-48_-19": 1,
  "land_-88_14": 8,
  "land_-88_7": 8,
  "land_-88_8": 8,
  "land_-88_9": 8,
  "land_-89_10": 8,
  "land_-89_11": 8,
  "land_-89_12": 8,
  "land_-89_13": 8,
  "land_-89_14": 8,
  "land_-89_7": 8,
  land_73_12: 1,
  "land_-49_-20": 1,
  "land_-89_8": 8,
  "land_-89_9": 8,
  "land_-90_-19": 8,
  "land_-90_10": 8,
  "land_-90_12": 8,
  "land_-90_13": 8,
  "land_-90_14": 8,
  "land_-90_7": 8,
  "land_-90_8": 8,
  "land_-90_9": 8,
  "land_-50_-20": 1,
  "land_-91_-1": 8,
  "land_-91_-10": 8,
  "land_-91_-15": 8,
  "land_-91_-16": 8,
  "land_-91_-17": 8,
  "land_-91_-18": 8,
  "land_-91_-2": 8,
  "land_-91_-3": 8,
  "land_-91_-4": 8,
  "land_-91_-5": 8,
  "land_-51_-11": 1,
  "land_-91_-6": 8,
  "land_-91_-8": 8,
  "land_-91_-9": 8,
  "land_-91_0": 8,
  "land_-91_1": 8,
  "land_-91_11": 8,
  "land_-91_2": 8,
  "land_-91_3": 8,
  "land_-91_4": 8,
  "land_-91_5": 8,
  "land_-51_-13": 1,
  "land_-91_6": 8,
  "land_-91_7": 8,
  "land_-91_8": 8,
  "land_-91_9": 8,
  "land_-92_-1": 8,
  "land_-92_-11": 8,
  "land_-92_-16": 8,
  "land_-92_-17": 8,
  "land_-92_-2": 8,
  "land_-92_0": 8,
  "land_-51_-14": 1,
  "land_-92_1": 8,
  "land_-92_10": 8,
  "land_-92_11": 8,
  "land_-92_2": 8,
  "land_-92_3": 8,
  "land_-92_4": 8,
  "land_-92_5": 8,
  "land_-92_7": 8,
  "land_-92_8": 8,
  "land_-92_9": 8,
  "land_-51_-15": 1,
  "land_-93_-1": 8,
  "land_-93_-16": 8,
  "land_-93_-18": 8,
  "land_-93_-2": 8,
  "land_-93_-3": 8,
  "land_-93_-4": 8,
  "land_-93_-5": 8,
  "land_-93_-6": 8,
  "land_-93_0": 8,
  "land_-93_1": 8,
  "land_-51_-16": 1,
  "land_-93_10": 8,
  "land_-93_11": 8,
  "land_-93_3": 8,
  "land_-93_4": 8,
  "land_-93_5": 8,
  "land_-93_6": 8,
  "land_-93_7": 8,
  "land_-93_8": 8,
  "land_-93_9": 8,
  "land_-94_-1": 8,
  "land_-51_-17": 1,
  "land_-94_-13": 8,
  "land_-94_-19": 8,
  "land_-94_-2": 8,
  "land_-94_-20": 8,
  "land_-94_-21": 8,
  "land_-94_-22": 8,
  "land_-94_-4": 8,
  "land_-94_-5": 8,
  "land_-94_-6": 8,
  "land_-94_-8": 8,
  "land_-51_-19": 1,
  "land_-94_0": 8,
  "land_-94_10": 8,
  "land_-94_11": 8,
  "land_-94_2": 8,
  "land_-94_3": 8,
  "land_-94_4": 8,
  "land_-94_5": 8,
  "land_-94_7": 8,
  "land_-94_8": 8,
  "land_-94_9": 8,
  "land_-51_-20": 1,
  "land_-95_-2": 8,
  "land_-95_-20": 8,
  "land_-95_-21": 8,
  "land_-95_-22": 8,
  "land_-95_-3": 8,
  "land_-95_-4": 8,
  "land_-95_-5": 8,
  "land_-95_-6": 8,
  "land_-95_0": 8,
  "land_-95_1": 8,
  land_73_13: 1,
  "land_-51_-24": 1,
  "land_-95_10": 8,
  "land_-95_2": 8,
  "land_-95_3": 8,
  "land_-95_4": 8,
  "land_-95_5": 8,
  "land_-95_6": 8,
  "land_-95_7": 8,
  "land_-95_8": 8,
  "land_-95_9": 8,
  "land_-96_-1": 8,
  "land_-52_-11": 1,
  "land_-96_-18": 8,
  "land_-96_-19": 8,
  "land_-96_-2": 8,
  "land_-96_-20": 8,
  "land_-96_-21": 8,
  "land_-96_-22": 8,
  "land_-96_-3": 8,
  "land_-96_-5": 8,
  "land_-96_0": 8,
  "land_-96_1": 8,
  "land_-52_-13": 1,
  "land_-96_10": 8,
  "land_-96_2": 8,
  "land_-96_3": 8,
  "land_-96_4": 8,
  "land_-96_5": 8,
  "land_-96_6": 8,
  "land_-96_7": 8,
  "land_-96_8": 8,
  "land_-96_9": 8,
  "land_-97_-1": 8,
  "land_-52_-15": 1,
  "land_-97_-15": 8,
  "land_-97_-16": 8,
  "land_-97_-18": 8,
  "land_-97_-19": 8,
  "land_-97_-2": 8,
  "land_-97_-20": 8,
  "land_-97_-21": 8,
  "land_-97_-22": 8,
  "land_-97_-3": 8,
  "land_-97_-4": 8,
  "land_-52_-17": 1,
  "land_-97_-5": 8,
  "land_-97_-6": 8,
  "land_-97_1": 8,
  "land_-97_10": 8,
  "land_-97_3": 8,
  "land_-97_4": 8,
  "land_-97_5": 8,
  "land_-97_6": 8,
  "land_-97_7": 8,
  "land_-97_8": 8,
  "land_-52_-18": 1,
  "land_-97_9": 8,
  "land_-98_-1": 8,
  "land_-98_-15": 8,
  "land_-98_-16": 8,
  "land_-98_-18": 8,
  "land_-98_-19": 8,
  "land_-98_-2": 8,
  "land_-98_-20": 8,
  "land_-98_-3": 8,
  "land_-98_-5": 8,
  "land_-52_-20": 1,
  "land_-98_-6": 8,
  "land_-98_1": 8,
  "land_-98_10": 8,
  "land_-98_2": 8,
  "land_-98_3": 8,
  "land_-98_4": 8,
  "land_-98_5": 8,
  "land_-98_7": 8,
  "land_-98_8": 8,
  "land_-98_9": 8,
  "land_-52_-24": 1,
  "land_-99_-1": 8,
  "land_-99_-10": 8,
  "land_-99_-15": 8,
  "land_-99_-16": 8,
  "land_-99_-17": 8,
  "land_-99_-2": 8,
  "land_-99_-3": 8,
  "land_-99_-4": 8,
  "land_-99_-6": 8,
  "land_-99_0": 8,
  "land_-52_-25": 1,
  "land_-99_2": 8,
  "land_-99_3": 8,
  "land_-99_4": 8,
  "land_-99_5": 8,
  "land_-99_6": 8,
  "land_34_-11": 8,
  "land_34_-19": 8,
  "land_35_-6": 8,
  land_38_6: 8,
  "land_50_-3": 8,
  "land_-53_-11": 1,
  "land_50_-4": 8,
  land_50_2: 8,
  land_50_6: 8,
  "land_51_-2": 8,
  "land_52_-5": 8,
  "land_56_-2": 8,
  "land_58_-10": 8,
  "land_59_-7": 8,
  "land_-10_11": 8,
  "land_-10_12": 8,
  land_73_14: 1,
  "land_-53_-12": 1,
  "land_-13_3": 8,
  "land_-15_3": 8,
  "land_-20_3": 8,
  "land_-5_11": 8,
  "land_-5_12": 8,
  "land_-6_11": 8,
  "land_-6_12": 8,
  "land_-7_7": 8,
  "land_-9_11": 8,
  "land_-9_12": 8,
  "land_-53_-14": 1,
  land_0_11: 8,
  land_0_12: 8,
  land_10_4: 8,
  land_2_13: 8,
  "land_-11_16": 8,
  "land_-11_18": 8,
  "land_-12_15": 8,
  "land_-12_16": 8,
  "land_-12_17": 8,
  "land_-12_8": 8,
  "land_-53_-16": 1,
  "land_-12_9": 8,
  "land_-13_6": 8,
  "land_-13_8": 8,
  "land_-13_9": 8,
  "land_-15_15": 8,
  "land_-15_17": 8,
  "land_-15_6": 8,
  "land_-16_13": 8,
  "land_-17_21": 8,
  "land_-1_30": 8,
  "land_-53_-17": 1,
  "land_-1_33": 8,
  "land_-25_14": 8,
  "land_-26_10": 8,
  "land_-26_11": 8,
  "land_-26_14": 8,
  "land_-26_16": 8,
  "land_-26_17": 8,
  "land_-2_30": 8,
  "land_-3_30": 8,
  "land_-3_33": 8,
  "land_-53_-19": 1,
  "land_-4_32": 8,
  "land_-5_20": 8,
  "land_-5_21": 8,
  "land_-5_22": 8,
  "land_-5_23": 8,
  "land_-5_24": 8,
  "land_-5_25": 8,
  "land_-6_16": 8,
  "land_-6_17": 8,
  "land_-6_22": 8,
  "land_-53_-24": 1,
  "land_-6_23": 8,
  "land_-6_24": 8,
  "land_-6_25": 8,
  "land_-9_34": 8,
  "land_-31_-1": 8,
  "land_-31_-2": 8,
  "land_-31_0": 8,
  "land_-31_1": 8,
  "land_-31_7": 8,
  "land_-31_8": 8,
  "land_-53_-25": 1,
  "land_-31_9": 8,
  "land_-32_10": 8,
  "land_-32_21": 8,
  "land_-32_6": 8,
  "land_-32_8": 8,
  "land_-34_12": 8,
  "land_-35_12": 8,
  "land_-36_2": 8,
  "land_-36_20": 8,
  "land_-36_3": 8,
  "land_-54_-12": 1,
  "land_-37_22": 8,
  "land_-37_3": 8,
  "land_-37_4": 8,
  "land_-37_6": 8,
  "land_-37_7": 8,
  "land_-37_8": 8,
  "land_-37_9": 8,
  "land_-39_0": 8,
  "land_-39_15": 8,
  "land_-39_29": 8,
  "land_-54_-14": 1,
  "land_-39_6": 8,
  "land_-39_7": 8,
  "land_-40_6": 8,
  "land_-40_7": 8,
  "land_-40_8": 8,
  "land_-40_9": 8,
  "land_-41_20": 8,
  "land_-41_8": 8,
  "land_-42_15": 8,
  "land_-42_21": 8,
  "land_-54_-15": 1,
  "land_-42_6": 8,
  "land_-43_21": 8,
  "land_-43_8": 8,
  "land_-45_-2": 8,
  "land_-46_14": 8,
  "land_-46_3": 8,
  "land_-46_4": 8,
  "land_-46_5": 8,
  "land_-46_6": 8,
  "land_-50_13": 8,
  "land_74_-13": 1,
  "land_-54_-16": 1,
  "land_-51_-1": 8,
  "land_-51_-2": 8,
  "land_-51_-3": 8,
  "land_-51_-4": 8,
  "land_-51_-5": 8,
  "land_-51_-6": 8,
  "land_-51_0": 8,
  "land_-51_1": 8,
  "land_-51_6": 8,
  "land_-51_7": 8,
  "land_-54_-17": 1,
  "land_-51_8": 8,
  "land_-51_9": 8,
  "land_-52_-1": 8,
  "land_-52_-2": 8,
  "land_-52_-3": 8,
  "land_-52_-4": 8,
  "land_-52_-5": 8,
  "land_-52_-6": 8,
  "land_-52_0": 8,
  "land_-52_1": 8,
  "land_-54_-18": 1,
  "land_-52_10": 8,
  "land_-52_13": 8,
  "land_-52_6": 8,
  "land_-52_7": 8,
  "land_-52_8": 8,
  "land_-52_9": 8,
  "land_-53_-1": 8,
  "land_-53_-2": 8,
  "land_-53_-3": 8,
  "land_-53_-4": 8,
  "land_-54_-19": 1,
  "land_-53_-5": 8,
  "land_-53_-6": 8,
  "land_-53_0": 8,
  "land_-53_1": 8,
  "land_-53_2": 8,
  "land_-53_3": 8,
  "land_-53_4": 8,
  "land_-53_5": 8,
  "land_-53_6": 8,
  "land_-53_7": 8,
  "land_-54_-24": 1,
  "land_-53_8": 8,
  "land_-54_-1": 8,
  "land_-54_-2": 8,
  "land_-54_-3": 8,
  "land_-54_-4": 8,
  "land_-54_-5": 8,
  "land_-54_-6": 8,
  "land_-54_0": 8,
  "land_-54_1": 8,
  "land_-54_2": 8,
  "land_-54_-25": 1,
  "land_-54_3": 8,
  "land_-54_4": 8,
  "land_-54_6": 8,
  "land_-54_7": 8,
  "land_-54_8": 8,
  "land_-54_9": 8,
  "land_-55_-1": 8,
  "land_-55_-2": 8,
  "land_-55_-3": 8,
  "land_-55_-4": 8,
  "land_-55_-12": 1,
  "land_-55_-5": 8,
  "land_-55_-6": 8,
  "land_-55_0": 8,
  "land_-55_1": 8,
  "land_-55_2": 8,
  "land_-55_3": 8,
  "land_-55_4": 8,
  "land_-55_5": 8,
  "land_-55_6": 8,
  "land_-55_7": 8,
  "land_-55_-13": 1,
  "land_-55_8": 8,
  "land_-55_9": 8,
  "land_-56_-1": 8,
  "land_-56_-2": 8,
  "land_-56_-3": 8,
  "land_-56_-4": 8,
  "land_-56_-5": 8,
  "land_-56_-6": 8,
  "land_-56_0": 8,
  "land_-56_1": 8,
  "land_-55_-15": 1,
  "land_-56_15": 8,
  "land_-56_2": 8,
  "land_-56_3": 8,
  "land_-56_4": 8,
  "land_-56_6": 8,
  "land_-56_7": 8,
  "land_-56_8": 8,
  "land_-56_9": 8,
  "land_-57_-1": 8,
  "land_-57_-2": 8,
  "land_-55_-16": 1,
  "land_-57_-3": 8,
  "land_-57_-4": 8,
  "land_-57_-5": 8,
  "land_-57_-6": 8,
  "land_-57_0": 8,
  "land_-57_1": 8,
  "land_-57_2": 8,
  "land_-57_3": 8,
  "land_-57_4": 8,
  "land_-57_5": 8,
  land_74_11: 1,
  "land_-55_-17": 1,
  "land_-57_6": 8,
  "land_-57_7": 8,
  "land_-57_8": 8,
  "land_-57_9": 8,
  "land_-58_-1": 8,
  "land_-58_-2": 8,
  "land_-58_-3": 8,
  "land_-58_-4": 8,
  "land_-58_-5": 8,
  "land_-58_-6": 8,
  "land_-55_-18": 1,
  "land_-58_0": 8,
  "land_-58_1": 8,
  "land_-58_12": 8,
  "land_-58_13": 8,
  "land_-58_2": 8,
  "land_-58_3": 8,
  "land_-58_4": 8,
  "land_-58_5": 8,
  "land_-58_6": 8,
  "land_-58_7": 8,
  "land_-55_-19": 1,
  "land_-58_8": 8,
  "land_-58_9": 8,
  "land_-25_-17": 8,
  "land_-25_-19": 8,
  "land_-25_-20": 8,
  "land_-25_-23": 8,
  "land_-25_-24": 8,
  "land_-25_-27": 8,
  "land_-25_-29": 8,
  "land_-31_-14": 8,
  "land_-55_-25": 1,
  "land_-31_-6": 8,
  "land_-33_-15": 8,
  "land_-34_-4": 8,
  "land_-38_-6": 8,
  "land_-40_-15": 8,
  "land_-40_-17": 8,
  "land_-40_-8": 8,
  "land_-40_-9": 8,
  "land_-41_-10": 8,
  "land_-41_-11": 8,
  "land_-56_-13": 1,
  "land_-41_-12": 8,
  "land_-41_-14": 8,
  "land_-41_-16": 8,
  "land_-41_-17": 8,
  "land_-41_-18": 8,
  "land_-41_-6": 8,
  "land_-41_-7": 8,
  "land_-42_-11": 8,
  "land_-42_-12": 8,
  "land_-42_-13": 8,
  "land_-56_-14": 1,
  "land_-42_-18": 8,
  "land_-42_-4": 8,
  "land_-42_-7": 8,
  "land_-42_-9": 8,
  "land_-43_-10": 8,
  "land_-43_-11": 8,
  "land_-43_-12": 8,
  "land_-43_-14": 8,
  "land_-43_-16": 8,
  "land_-43_-9": 8,
  "land_-56_-16": 1,
  "land_-44_-10": 8,
  "land_-44_-11": 8,
  "land_-44_-12": 8,
  "land_-44_-13": 8,
  "land_-44_-14": 8,
  "land_-44_-15": 8,
  "land_-44_-17": 8,
  "land_-44_-18": 8,
  "land_-44_-3": 8,
  "land_-44_-4": 8,
  "land_-56_-18": 1,
  "land_-44_-5": 8,
  "land_-44_-6": 8,
  "land_-44_-7": 8,
  "land_-44_-9": 8,
  "land_-45_-10": 8,
  "land_-45_-14": 8,
  "land_-45_-16": 8,
  "land_-45_-18": 8,
  "land_-45_-3": 8,
  "land_-45_-5": 8,
  "land_-56_-24": 1,
  "land_-45_-6": 8,
  "land_-45_-7": 8,
  "land_-45_-8": 8,
  "land_-45_-9": 8,
  "land_-46_-15": 8,
  "land_-46_-16": 8,
  "land_-46_-18": 8,
  "land_-46_-3": 8,
  "land_-46_-5": 8,
  "land_-46_-6": 8,
  "land_-56_-25": 1,
  "land_-46_-8": 8,
  "land_-46_-9": 8,
  "land_-47_-10": 8,
  "land_-47_-11": 8,
  "land_-47_-12": 8,
  "land_-47_-13": 8,
  "land_-47_-15": 8,
  "land_-47_-18": 8,
  "land_-47_-4": 8,
  "land_-47_-5": 8,
  land_74_12: 1,
  "land_-57_-11": 1,
  "land_-47_-6": 8,
  "land_-47_-9": 8,
  "land_-48_-14": 8,
  "land_-48_-15": 8,
  "land_-48_-17": 8,
  "land_-48_-18": 8,
  "land_-48_-3": 8,
  "land_-48_-5": 8,
  "land_-48_-6": 8,
  "land_-49_-11": 8,
  "land_-57_-13": 1,
  "land_-49_-12": 8,
  "land_-49_-14": 8,
  "land_-49_-15": 8,
  "land_-49_-16": 8,
  "land_-49_-17": 8,
  "land_-49_-5": 8,
  "land_-49_-7": 8,
  "land_-49_-8": 8,
  "land_-50_-10": 8,
  "land_-50_-11": 8,
  "land_-57_-14": 1,
  "land_-50_-12": 8,
  "land_-50_-14": 8,
  "land_-50_-16": 8,
  "land_-50_-18": 8,
  "land_-50_-6": 8,
  "land_-50_-7": 8,
  "land_-50_-9": 8,
  "land_-51_-10": 8,
  "land_-51_-7": 8,
  "land_-51_-9": 8,
  "land_-57_-16": 1,
  "land_-52_-7": 8,
  "land_-52_-9": 8,
  "land_-53_-10": 8,
  "land_-53_-8": 8,
  "land_-54_-10": 8,
  "land_-55_-10": 8,
  "land_-55_-7": 8,
  "land_-56_-7": 8,
  "land_-56_-9": 8,
  "land_-57_-7": 8,
  "land_-57_-17": 1,
  "land_-57_-8": 8,
  "land_-58_-7": 8,
  "land_-58_-9": 8,
  "land_-57_-24": 1,
  "land_-57_-25": 1,
  "land_-58_-11": 1,
  "land_-58_-12": 1,
  "land_-58_-16": 1,
  land_41_25: 1,
  land_74_13: 1,
  "land_-58_-18": 1,
  "land_-58_-19": 1,
  "land_-58_-24": 1,
  "land_-58_-25": 1,
  "land_-59_-11": 1,
  "land_-59_-13": 1,
  "land_-59_-14": 1,
  "land_-59_-15": 1,
  "land_-59_-17": 1,
  "land_-59_-18": 1,
  land_74_14: 1,
  "land_-59_-20": 1,
  "land_-59_-24": 1,
  "land_-59_-25": 1,
  "land_-60_-11": 1,
  "land_-60_-12": 1,
  "land_-60_-13": 1,
  "land_-60_-14": 1,
  "land_-60_-15": 1,
  "land_-60_-18": 1,
  "land_-60_-19": 1,
  land_74_15: 1,
  "land_-60_-20": 1,
  "land_-60_-24": 1,
  "land_-61_-33": 1,
  "land_-62_-33": 1,
  "land_-63_-33": 1,
  "land_-65_-33": 1,
  "land_-66_-33": 1,
  "land_-67_-33": 1,
  "land_-68_-33": 1,
  "land_-69_-31": 1,
  "land_75_-1": 1,
  "land_-69_-32": 1,
  "land_-70_-28": 1,
  "land_-70_-31": 1,
  "land_70_-9": 2,
  "land_71_-9": 2,
  "land_-26_-21": 2,
  "land_-26_-22": 2,
  "land_-26_-23": 2,
  "land_-26_-26": 2,
  "land_-26_-27": 2,
  "land_75_-13": 1,
  "land_-26_-29": 2,
  "land_-26_-30": 2,
  "land_-27_-20": 2,
  "land_-27_-21": 2,
  "land_-27_-22": 2,
  "land_-27_-23": 2,
  "land_-27_-26": 2,
  "land_-27_-27": 2,
  "land_-27_-28": 2,
  "land_-27_-30": 2,
  land_75_11: 1,
  "land_-28_-20": 2,
  "land_-28_-21": 2,
  "land_-28_-23": 2,
  "land_-28_-26": 2,
  "land_-28_-29": 2,
  "land_-29_-21": 2,
  "land_-29_-22": 2,
  "land_-29_-23": 2,
  "land_-29_-24": 2,
  "land_-29_-31": 2,
  land_75_12: 1,
  "land_-30_-21": 2,
  "land_-30_-24": 2,
  "land_-31_-21": 2,
  "land_-31_-23": 2,
  "land_-31_-24": 2,
  "land_-32_-21": 2,
  "land_-32_-22": 2,
  "land_-32_-23": 2,
  "land_-32_-24": 2,
  "land_-32_-34": 2,
  land_75_13: 1,
  "land_-33_-21": 2,
  "land_-33_-23": 2,
  "land_-33_-24": 2,
  "land_-33_-33": 2,
  "land_-34_-21": 2,
  "land_-34_-33": 2,
  "land_-35_-21": 2,
  "land_-35_-28": 2,
  "land_-35_-29": 2,
  "land_-36_-21": 2,
  land_75_14: 1,
  "land_-36_-24": 2,
  "land_-36_-36": 2,
  "land_-37_-23": 2,
  "land_-37_-25": 2,
  "land_-38_-22": 2,
  "land_-38_-24": 2,
  "land_-38_-25": 2,
  "land_-38_-33": 2,
  "land_-38_-34": 2,
  "land_-38_-35": 2,
  land_75_15: 1,
  "land_-38_-36": 2,
  "land_-39_-25": 2,
  "land_-40_-23": 2,
  "land_-40_-25": 2,
  "land_-41_-22": 2,
  "land_-42_-22": 2,
  "land_-42_-23": 2,
  "land_-42_-24": 2,
  "land_-42_-33": 2,
  "land_-42_-34": 2,
  land_46_18: 1,
  "land_76_-1": 1,
  "land_-42_-35": 2,
  "land_-42_-36": 2,
  "land_-43_-22": 2,
  "land_-43_-32": 2,
  "land_-43_-33": 2,
  "land_-43_-34": 2,
  "land_-43_-35": 2,
  "land_-43_-36": 2,
  "land_-44_-22": 2,
  "land_-45_-21": 2,
  "land_76_-13": 1,
  "land_-45_-22": 2,
  "land_-46_-21": 2,
  "land_-46_-22": 2,
  "land_-46_-30": 2,
  "land_-47_-21": 2,
  "land_-47_-22": 2,
  "land_-47_-32": 2,
  "land_-48_-21": 2,
  "land_-48_-22": 2,
  "land_-48_-23": 2,
  land_76_12: 1,
  "land_-48_-32": 2,
  "land_-48_-33": 2,
  "land_-49_-21": 2,
  "land_-49_-22": 2,
  "land_-49_-23": 2,
  "land_-49_-25": 2,
  "land_-49_-33": 2,
  "land_-50_-21": 2,
  "land_-50_-22": 2,
  "land_-50_-23": 2,
  land_76_13: 1,
  "land_-50_-33": 2,
  "land_-51_-22": 2,
  "land_-51_-23": 2,
  "land_-51_-32": 2,
  "land_-51_-34": 2,
  "land_-51_-35": 2,
  "land_-52_-21": 2,
  "land_-52_-22": 2,
  "land_-52_-23": 2,
  "land_-52_-27": 2,
  land_76_14: 1,
  "land_-52_-32": 2,
  "land_-52_-34": 2,
  "land_-52_-35": 2,
  "land_-52_-36": 2,
  "land_-53_-21": 2,
  "land_-53_-29": 2,
  "land_-53_-31": 2,
  "land_-53_-32": 2,
  "land_-53_-33": 2,
  "land_-53_-34": 2,
  "land_77_-2": 1,
  "land_-53_-35": 2,
  "land_-53_-36": 2,
  "land_-54_-21": 2,
  "land_-54_-32": 2,
  "land_-54_-33": 2,
  "land_-54_-34": 2,
  "land_-54_-35": 2,
  "land_-54_-36": 2,
  "land_-55_-21": 2,
  "land_-55_-29": 2,
  land_77_11: 1,
  "land_-55_-30": 2,
  "land_-55_-31": 2,
  "land_-55_-32": 2,
  "land_-55_-33": 2,
  "land_-56_-21": 2,
  "land_-56_-34": 2,
  "land_-57_-21": 2,
  "land_-57_-32": 2,
  "land_-57_-33": 2,
  "land_-57_-34": 2,
  land_77_12: 1,
  "land_-57_-35": 2,
  "land_-58_-21": 2,
  "land_-58_-30": 2,
  "land_-58_-33": 2,
  "land_-58_-34": 2,
  "land_-59_-21": 2,
  "land_-59_-33": 2,
  "land_-60_-22": 2,
  "land_-60_-30": 2,
  "land_-60_-32": 2,
  land_77_13: 1,
  "land_-60_-33": 2,
  "land_-61_-27": 2,
  "land_-61_-30": 2,
  "land_-61_-31": 2,
  "land_-61_-32": 2,
  "land_-62_-26": 2,
  "land_-62_-28": 2,
  "land_-62_-32": 2,
  "land_-63_-32": 2,
  "land_-64_-27": 2,
  land_77_14: 1,
  "land_-64_-28": 2,
  "land_-64_-32": 2,
  "land_-65_-27": 2,
  "land_-65_-28": 2,
  "land_-65_-32": 2,
  "land_-66_-27": 2,
  "land_-66_-28": 2,
  "land_-66_-29": 2,
  "land_-66_-32": 2,
  "land_-67_-27": 2,
  land_40_24: 1,
  land_77_15: 1,
  "land_-67_-29": 2,
  "land_-67_-30": 2,
  "land_-67_-32": 2,
  "land_-68_-27": 2,
  "land_-68_-28": 2,
  "land_-68_-29": 2,
  "land_-68_-30": 2,
  "land_-69_-27": 2,
  "land_-69_-28": 2,
  "land_-69_-29": 2,
  "land_78_-13": 1,
  "land_-69_-30": 2,
  "land_-70_-27": 2,
  "land_-70_-29": 2,
  land_25_30: 3,
  land_10_34: 3,
  land_10_33: 3,
  land_12_32: 3,
  land_24_30: 3,
  land_31_27: 3,
  land_43_26: 3,
  "land_78_-2": 1,
  land_45_30: 3,
  land_46_30: 3,
  land_11_30: 3,
  land_10_36: 3,
  land_15_30: 3,
  land_22_28: 3,
  land_27_20: 3,
  land_10_31: 3,
  land_10_35: 3,
  land_11_35: 3,
  land_78_11: 1,
  land_11_33: 3,
  land_11_34: 3,
  land_14_30: 3,
  land_13_33: 3,
  land_19_30: 3,
  land_10_30: 3,
  land_13_30: 3,
  land_17_30: 3,
  land_23_30: 3,
  land_48_15: 3,
  land_78_12: 1,
  land_12_33: 3,
  land_20_20: 3,
  land_27_21: 3,
  land_10_32: 3,
  land_39_30: 3,
  land_40_30: 3,
  land_49_30: 3,
  land_11_32: 3,
  land_12_31: 3,
  land_20_30: 3,
  land_78_13: 1,
  land_31_12: 3,
  land_16_30: 3,
  land_48_30: 3,
  land_41_30: 3,
  land_43_30: 3,
  land_44_30: 3,
  land_28_29: 3,
  land_47_30: 3,
  land_49_17: 3,
  "land_63_-19": 3,
  land_78_14: 1,
  "land_73_-2": 3,
  land_82_28: 3,
  "land_84_-11": 3,
  "land_-107_-7": 3,
  "land_-107_-8": 3,
  "land_-91_-20": 3,
  "land_-92_-20": 3,
  "land_-92_-21": 3,
  "land_-92_-22": 3,
  "land_-92_-23": 3,
  land_78_15: 1,
  "land_-92_-24": 3,
  "land_-92_-25": 3,
  "land_-92_-26": 3,
  "land_-92_-27": 3,
  "land_36_-4": 3,
  "land_36_-7": 3,
  land_36_2: 3,
  land_48_3: 3,
  "land_49_-10": 3,
  "land_51_-7": 3,
  "land_79_-13": 1,
  "land_52_-3": 3,
  land_52_7: 3,
  land_53_0: 3,
  land_54_1: 3,
  land_55_4: 3,
  "land_-12_28": 3,
  "land_-19_11": 3,
  "land_-1_31": 3,
  "land_-1_32": 3,
  "land_-1_34": 3,
  "land_80_-11": 1,
  "land_-1_35": 3,
  "land_-1_36": 3,
  "land_-2_31": 3,
  "land_-2_32": 3,
  "land_-2_34": 3,
  "land_-2_35": 3,
  "land_-2_36": 3,
  "land_-3_31": 3,
  "land_-3_32": 3,
  "land_-3_34": 3,
};

// 1: "#18173a", // Orange
// 2: "#3a3017", // Turquoise
// 3: "#3e6211", // Yellow
// 4: "#680808", // Blue
// 5: "#33173a", // Pink
// 6: "#173a31", // Green
// 7: "#172b3a", // Purple
// 8: "#3a1f17", // Blue
