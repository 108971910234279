import "./plainSeperator.css";

const PlainSeperator = ({ width = "100%", style }: any) => {
  return (
    <div className='plain__separator' style={style}>
      <hr style={{ width, margin: "auto", marginTop: "2px" }} />
    </div>
  );
};

export default PlainSeperator;
