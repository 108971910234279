import IconService from "icon-sdk-js";
import { sha3_256 } from "js-sha3";
import { useWalletContext } from "../services/contexts/wallet.context";
import iconService from "../services/icon/icon.service";
import { useIconEventContext } from "../services/contexts/iconEvent.context";
import { E_ICONEX_RPC_EVENT_TYPE } from "./useIconexHook";
import { Subject } from "rxjs";

const MSG_KEY = "message";
export const signatureSubject = new Subject<string>();

export const useSignMessage = () => {
  const message = Array.from({ length: 8 }, () => {
    return Math.random();
  })
    .join("_")
    .toString();
  const { walletAddress } = useWalletContext();
  const { setCurrentEventType, setCurrentTxResult, setIsTransactionLoading, setSignStoreHash } =
    useIconEventContext();
  const getRanHex = (size: number) => {
    return [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
  };

  // const getDynamicHashN = (count: number) => {
  //     return getRanHex(count + (51 - count)) + new Date().getTime();
  // };

  // only handles one signature at a time
  const signClick = async () => {
    if (walletAddress) {
      const txObj = iconService.initializeSignTxObj(walletAddress, MSG_KEY, message);
      const txStrRep = IconService.IconUtil.generateHashKey(txObj);
      const txSha3Rep = sha3_256.update(txStrRep).hex();
      console.log("dynamicHash", txSha3Rep);
      try {
        localStorage.removeItem("dynamicHash");
      } catch (error) {}
      localStorage.setItem("dynamicHash", txSha3Rep);
      setIsTransactionLoading(true);
      setCurrentEventType(E_ICONEX_RPC_EVENT_TYPE.MESSAGE_SIGNING_PENGING);
      setSignStoreHash(txSha3Rep);

      if (message) {
        window.parent.dispatchEvent(
          new CustomEvent("ICONEX_RELAY_REQUEST", {
            detail: {
              type: "REQUEST_SIGNING",
              payload: {
                from: walletAddress,
                hash: txSha3Rep, //64C
              },
            },
          })
        );
        return new Promise((resolve) => {
          signatureSubject.subscribe((signature) => {
            resolve(signature);
          });
        });
      }
    } else {
      setCurrentEventType("");
      setIsTransactionLoading(false);
    }
  };

  return { signClick };
};
