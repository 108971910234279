import {
  E_RPS_CHALLENGE_TYPE,
  E_RPS_RPS_HANDS,
  E_RPS_WAGER_TYPE,
  ZERO_ADDRESS,
} from "../../enums/RPS.enums";
import { IconChainService } from "../icon/icon.service";
import { MINI_GAME_URL } from "../../constants/externalURI";
import aesjs from "aes-js";
import axios from "axios";
import { E_ICONEX_RPC_EVENT_TYPE } from "../../hooks/useIconexHook";
import { abbreviateNumber, objectToHexWithPrefix } from "../../utils/helper";
import {
  REACT_APP_ENCRYPTION_KEY,
  REACT_APP_CROWN_SCORE,
  REACT_APP_RPS_MINI_GAME_SCORE,
  REACT_APP_EMERALD_CITY_NODE_API,
} from "../data/config";
class RPSContractServices {
  private iconService: IconChainService;
  private static instance: RPSContractServices;
  constructor() {
    this.iconService = new IconChainService();
  }
  public static getInstance(): RPSContractServices {
    if (!RPSContractServices.instance) {
      RPSContractServices.instance = new RPSContractServices();
    }
    return RPSContractServices.instance;
  }
  private _encrypt(string: string) {
    const key = aesjs.utils.hex.toBytes(REACT_APP_ENCRYPTION_KEY);
    var textBytes = aesjs.utils.utf8.toBytes(string);
    var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
    var encryptedBytes = aesCtr.encrypt(textBytes);
    var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    return encryptedHex;
  }

  public getWagerType(scoreAddress: string): "ICX" | "CROWN" {
    switch (scoreAddress) {
      case ZERO_ADDRESS:
        return "ICX";
      case REACT_APP_CROWN_SCORE:
        return "CROWN";
      default:
        console.error("Invalid score address for the wager type.");
        return "CROWN";
    }
  }
  public async closeGame(gameId: string, address: string) {
    try {
      const response = await this.iconService.setToChain(
        REACT_APP_RPS_MINI_GAME_SCORE as string,
        "cancel",
        {
          gameId: gameId,
        },
        address,
        0,
        E_ICONEX_RPC_EVENT_TYPE.CANCELING_GAME_PENDING
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  public getRPSGameStatus(played: string, status: string): "OPEN" | "PLAYED" | "RESULT" {
    if (played == "0x1" && status == "0x1") {
      return "PLAYED";
    } else if (played == "0x0" && status == "0x1") {
      return "OPEN";
    } else {
      return "RESULT";
    }
  }

  public getRPSOpeGameType(whiteList: string, challengedAddress: string): E_RPS_CHALLENGE_TYPE {
    if (whiteList == "0x1") {
      return E_RPS_CHALLENGE_TYPE.ALL_FRIENDS;
    } else if (whiteList == "0x0" && challengedAddress != ZERO_ADDRESS) {
      return E_RPS_CHALLENGE_TYPE.SOMEONE;
    } else {
      return E_RPS_CHALLENGE_TYPE.ANYONE;
    }
  }

  public async openICXGame(
    address: string,
    wagerAmount: number,
    wagerType: string,
    currentHandSelected: number,
    currentChallengeType: E_RPS_CHALLENGE_TYPE,
    challengedAddress: string,
    payload: any,
    messageString: string = ""
  ) {
    try {
      console.log(`Encrypting...`);
      const en = this._encrypt(
        JSON.stringify({
          type: wagerType,
          choice: currentHandSelected,
          address: address,
          amount: wagerAmount,
        })
      );
      const encrypt = await axios.post(
        `${REACT_APP_EMERALD_CITY_NODE_API}${MINI_GAME_URL.RPS.ENCRYPT}`,
        {
          address: address,
          amount: wagerAmount,
          type: wagerType,
        },
        {
          headers: {
            Accept: "application/json",
            "Session-Key": en,
            ...payload,
          },
        }
      );
      if (encrypt.status == 200) {
        const payload = this.createOpenGamePayload(
          currentChallengeType,
          challengedAddress,
          encrypt.data.data,
          wagerType as E_RPS_WAGER_TYPE,
          messageString
        );
        if (wagerType == "ICX") {
          console.log("opening game");
          const response = await this.iconService.setToChain(
            REACT_APP_RPS_MINI_GAME_SCORE as string,
            "OpenGameWithICX",
            payload,
            address,
            wagerAmount,
            E_ICONEX_RPC_EVENT_TYPE.OPENING_GAME_PENDING
          );
          console.log("open game result", response);
          console.log(response);
        } else {
          await this.createGameWithCROWN(address, wagerAmount, payload);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  private createOpenGamePayload(
    currentChallengeType: E_RPS_CHALLENGE_TYPE,
    challengedAddress: string,
    randomString: string,
    creationType: E_RPS_WAGER_TYPE,
    message: string = ""
  ) {
    switch (currentChallengeType) {
      case E_RPS_CHALLENGE_TYPE.ALL_FRIENDS:
        return {
          randomString,
          wishList: creationType == E_RPS_WAGER_TYPE.ICX ? "0x1" : true,
          challengedAddress: ZERO_ADDRESS,
          message,
        };
      case E_RPS_CHALLENGE_TYPE.ANYONE:
        return {
          randomString,
          wishList: creationType == E_RPS_WAGER_TYPE.ICX ? "0x0" : false,
          challengedAddress: ZERO_ADDRESS,
          message,
        };
      case E_RPS_CHALLENGE_TYPE.SOMEONE:
        return {
          randomString,
          wishList: creationType == E_RPS_WAGER_TYPE.ICX ? "0x0" : false,
          challengedAddress: challengedAddress,
          message,
        };
    }
  }

  public async createGameWithCROWN(address: string, wagerAmount: number, payload: any) {
    try {
      console.log(`play rps game with $CROWN ----->`);
      const fixedWagerAmount = (wagerAmount * Math.pow(10, 18)).toLocaleString("fullwide", {
        useGrouping: false,
      });
      console.log({
        ...payload,
        type: "create",
      });
      console.log("crown", {
        _to: REACT_APP_RPS_MINI_GAME_SCORE as string,
        __value: fixedWagerAmount,
        _data: objectToHexWithPrefix({
          ...payload,
          type: "create",
        }),
      });
      const response = await this.iconService.setToChain(
        REACT_APP_CROWN_SCORE as string,
        "transfer",
        {
          _to: REACT_APP_RPS_MINI_GAME_SCORE as string,
          _value: fixedWagerAmount,
          _data: objectToHexWithPrefix({
            ...payload,
            type: "create",
          }),
        },
        address,
        0,
        E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_PENDING
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }
  public async getFriendList(address: string) {
    try {
      const response = await this.iconService.getFromChain(
        REACT_APP_RPS_MINI_GAME_SCORE as string,
        "getWishList",
        {
          address,
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  public async getTokenCap(tokeName: "ICX" | "CROWN") {
    try {
      const response = await this.iconService.getFromChain(
        REACT_APP_RPS_MINI_GAME_SCORE as string,
        "getTokenCap",
        {
          token: tokeName == "ICX" ? ZERO_ADDRESS : REACT_APP_CROWN_SCORE,
        }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  public async addToFriendList(address: string, friendAddress: string) {
    try {
      const response = await this.iconService.setToChain(
        REACT_APP_RPS_MINI_GAME_SCORE as string,
        "addToMyWhiteList",
        {
          addressList: [friendAddress],
        },
        address,
        0,
        E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_PENDING
      );
      console.log(E_ICONEX_RPC_EVENT_TYPE.ADDING_FRIEND_PENDING, response);
    } catch (error) {
      console.log(error);
    }
  }
  public async RemoveFromFriendList(address: string, friendAddress: string) {
    try {
      const response = await this.iconService.setToChain(
        REACT_APP_RPS_MINI_GAME_SCORE as string,
        "removeFromMyWhiteList",
        {
          address: friendAddress,
        },
        address,
        0,
        E_ICONEX_RPC_EVENT_TYPE.REMOVING_FRIEND_PENDING
      );
      console.log(E_ICONEX_RPC_EVENT_TYPE.REMOVING_FRIEND_PENDING, response);
    } catch (error) {
      console.log(error);
    }
  }
  public async playRPS(
    gameId: string,
    address: string,
    wagerAmount: number,
    currentHandSelected: number,
    tokenAddress: string
  ) {
    try {
      if (tokenAddress == ZERO_ADDRESS) {
        const response = await this.iconService.setToChain(
          REACT_APP_RPS_MINI_GAME_SCORE as string,
          "playRPS",
          {
            gameId: gameId,
            option: `0x${currentHandSelected}`,
          },
          address,
          wagerAmount,
          E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_PENDING
        );
        console.log(response);
      } else {
        const fixedWagerAmount = (wagerAmount * Math.pow(10, 18)).toLocaleString("fullwide", {
          useGrouping: false,
        });
        console.log({
          type: "play",
          gameId: gameId,
          option: `0x${currentHandSelected}`,
        });
        const response = await this.iconService.setToChain(
          REACT_APP_CROWN_SCORE as string,
          "transfer",
          {
            _to: REACT_APP_RPS_MINI_GAME_SCORE as string,
            _value: fixedWagerAmount,
            _data: objectToHexWithPrefix({
              type: "play",
              gameId: parseInt(gameId, 16),
              option: currentHandSelected,
            }),
          },
          address,
          0,
          E_ICONEX_RPC_EVENT_TYPE.PLAYING_GAME_PENDING
        );
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  }
  public async getOpenGames(): Promise<any> {
    try {
      const response = await this.iconService.getFromChain(
        REACT_APP_RPS_MINI_GAME_SCORE as string,
        "getAvailableGames",
        {}
      );
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      console.error(error);
    }
  }

  public getRespectName(respectNumber: number) {
    const levels = [
      { name: "Novice", minRespect: 0, maxRespect: 100 },
      { name: "Apprentice", minRespect: 100, maxRespect: 250 },
      { name: "Rookie", minRespect: 250, maxRespect: 400 },
      { name: "Striver", minRespect: 400, maxRespect: 600 },
      { name: "Challenger", minRespect: 600, maxRespect: 850 },
      { name: "Protégé", minRespect: 850, maxRespect: 1150 },
      { name: "Seeker", minRespect: 1150, maxRespect: 1500 },
      { name: "Journeyer", minRespect: 1500, maxRespect: 1900 },
      { name: "Trailblazer", minRespect: 1900, maxRespect: 2350 },
      { name: "Pioneer", minRespect: 2350, maxRespect: 2850 },
      { name: "Elite", minRespect: 2850, maxRespect: 3400 },
      { name: "Master", minRespect: 3400, maxRespect: 4000 },
      { name: "Grandmaster", minRespect: 4000, maxRespect: 4650 },
      { name: "Prodigy", minRespect: 4650, maxRespect: 5350 },
      { name: "Virtuoso", minRespect: 5350, maxRespect: 6100 },
      { name: "Maestro", minRespect: 6100, maxRespect: 6900 },
      { name: "Ace", minRespect: 6900, maxRespect: 7750 },
      { name: "Legend", minRespect: 7750, maxRespect: 8650 },
      { name: "Myth", minRespect: 8650, maxRespect: 9600 },
      { name: "Hero", minRespect: 9600, maxRespect: 10600 },
      { name: "Champion", minRespect: 10600, maxRespect: 11750 },
      { name: "Conqueror", minRespect: 11750, maxRespect: 12950 },
      { name: "Overlord", minRespect: 12950, maxRespect: 14200 },
      { name: "Warlord", minRespect: 14200, maxRespect: 15500 },
      { name: "Majesty", minRespect: 15500, maxRespect: 16850 },
      { name: "Sovereign", minRespect: 16850, maxRespect: 18250 },
      { name: "Imperator", minRespect: 18250, maxRespect: 19700 },
      { name: "Tyrant", minRespect: 19700, maxRespect: 21200 },
      { name: "Despot", minRespect: 21200, maxRespect: 22750 },
      { name: "Ascendant", minRespect: 22750, maxRespect: 24350 },
    ];
    const level = levels.find(
      (entry) => respectNumber >= entry.minRespect && respectNumber <= entry.maxRespect
    );

    return level ? level.name : "Unknown Level";
  }
  public getStreakName(streakNumber: number) {
    const streaks = [
      { name: "Streak Starter", minStreak: 0, maxStreak: 1 },
      { name: "Winning Run", minStreak: 2, maxStreak: 5 },
      { name: "Victory Surge", minStreak: 6, maxStreak: 10 },
      { name: "Champion Streak", minStreak: 11, maxStreak: 15 },
      { name: "Winning Spree", minStreak: 16, maxStreak: 20 },
      { name: "Dominance Drive", minStreak: 21, maxStreak: 25 },
      { name: "Triumph Trail", minStreak: 26, maxStreak: 30 },
      { name: "Streak Master", minStreak: 31, maxStreak: 35 },
      { name: "Victory Rampage", minStreak: 36, maxStreak: 40 },
      { name: "Supreme Streak", minStreak: 41, maxStreak: 45 },
      { name: "Unbeaten Dynasty", minStreak: 46, maxStreak: 50 },
    ];

    const streak = streaks.find(
      (entry) => streakNumber >= entry.minStreak && streakNumber <= entry.maxStreak
    );

    return streak ? streak.name : "Unknown Streak";
  }

  public getCashTitleName(cash: number) {
    console.log("CASH ", cash);
    const streaks = [
      { name: "Bankrupt Banter", minCash: -99999999, maxCash: 2 },
      { name: "Tramp", minCash: 2, maxCash: 500 },
      { name: "Hustler", minCash: 501, maxCash: 1500 },
      { name: "Highroller", minCash: 1501, maxCash: 5000 },
      { name: "Big Shot", minCash: 5001, maxCash: 10000 },
      { name: "Mogul", minCash: 10001, maxCash: 20000 },
      { name: "Tycoon", minCash: 20001, maxCash: 999999999 },
    ];
    let streak: any = {
      name: "Tramp",
      minCash: 0,
      maxCash: 100,
      amount: 0,
    };
    streak = streaks.find((entry) => cash >= entry.minCash && cash <= entry.maxCash);
    console.log(`${cash.toFixed(2)}`);
    return {
      amount: abbreviateNumber(Math.ceil(cash)),
      name: streak.name,
      range: {
        min: abbreviateNumber(streak.minCash),
        max: abbreviateNumber(streak.maxCash),
      },
    };
  }

  public getICXCashTitleName(cash: number) {
    console.log("CASH ", cash);
    const streaks = [
      { name: "Bankrupt Banter", minCash: -99999999, maxCash: 2 },
      { name: "Hustler", minCash: 2, maxCash: 100 },
      { name: "Highroller", minCash: 101, maxCash: 500 },
      { name: "Big Shot", minCash: 501, maxCash: 1000 },
      { name: "Mogul", minCash: 1001, maxCash: 1500 },
      { name: "Tycoon", minCash: 1501, maxCash: 999999999 },
    ];
    let streak: any = {
      name: "Tramp",
      minCash: 0,
      maxCash: 100,
      amount: 0,
    };
    streak = streaks.find((entry) => cash >= entry.minCash && cash <= entry.maxCash);
    console.log(`${cash.toFixed(2)}`);
    return {
      amount: abbreviateNumber(Math.ceil(cash)),
      name: streak.name,
      range: {
        min: abbreviateNumber(streak.minCash),
        max: abbreviateNumber(streak.maxCash),
      },
    };
  }
}
export default RPSContractServices.getInstance();
