export const MINTED_LANDS = [
  {
    "x": "81",
    "y": "15",
    "owner": "hxc910beff64f520c3ef1e078477b13187ad0c620d",
    "name": "Emerald City Land #1",
    "zoneId": "8",
    "metaId": "5832",
    "nftId": 1,
    "creationDate": "1691113629170164",
    "id": "1",
    "mintedVia": "CROWN",
  },
  {
    "x": "23",
    "y": "9",
    "owner": "hx596540c70cdbaf2ad097fcf28958f7db8f03d894",
    "name": "Emerald City Land #2",
    "zoneId": "1",
    "metaId": "447",
    "nftId": 2,
    "creationDate": "1691114499642907",
    "id": "2",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "7",
    "owner": "hx8c5c49759e78ecd4c32cad296900dbe68956bed6",
    "name": "Emerald City Land #3",
    "zoneId": "6",
    "metaId": "1742",
    "nftId": 3,
    "creationDate": "1691114895544402",
    "id": "3",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "16",
    "owner": "hxc910beff64f520c3ef1e078477b13187ad0c620d",
    "name": "Emerald City Land #4",
    "zoneId": "4",
    "metaId": "1501",
    "nftId": 4,
    "creationDate": "1691115437723992",
    "id": "4",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "-16",
    "owner": "hxc910beff64f520c3ef1e078477b13187ad0c620d",
    "name": "Emerald City Land #5",
    "zoneId": "8",
    "metaId": "5318",
    "nftId": 5,
    "creationDate": "1691117982546027",
    "id": "5",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "6",
    "owner": "hx5b0699827e3731ae468ce3f1bc8ab10ba03a1a22",
    "name": "Emerald City Land #6",
    "zoneId": "6",
    "metaId": "1720",
    "nftId": 6,
    "creationDate": "1691118026570027",
    "id": "6",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "-10",
    "owner": "hxc910beff64f520c3ef1e078477b13187ad0c620d",
    "name": "Emerald City Land #7",
    "zoneId": "7",
    "metaId": "2630",
    "nftId": 7,
    "creationDate": "1691118032569711",
    "id": "7",
    "mintedVia": "CROWN",
  },
  {
    "x": "-4",
    "y": "16",
    "owner": "hxc910beff64f520c3ef1e078477b13187ad0c620d",
    "name": "Emerald City Land #8",
    "zoneId": "7",
    "metaId": "3766",
    "nftId": 8,
    "creationDate": "1691118032569711",
    "id": "8",
    "mintedVia": "CROWN",
  },
  {
    "x": "3",
    "y": "-2",
    "owner": "hxc910beff64f520c3ef1e078477b13187ad0c620d",
    "name": "Emerald City Land #9",
    "zoneId": "7",
    "metaId": "3269",
    "nftId": 9,
    "creationDate": "1691118032569711",
    "id": "9",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "21",
    "owner": "hx925446099120182af547b1551265f77d123ae060",
    "name": "Emerald City Land #10",
    "zoneId": "8",
    "metaId": "5768",
    "nftId": 10,
    "creationDate": "1691118044549826",
    "id": "10",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "28",
    "owner": "hx925446099120182af547b1551265f77d123ae060",
    "name": "Emerald City Land #11",
    "zoneId": "8",
    "metaId": "5068",
    "nftId": 11,
    "creationDate": "1691118044549826",
    "id": "11",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "20",
    "owner": "hx925446099120182af547b1551265f77d123ae060",
    "name": "Emerald City Land #12",
    "zoneId": "8",
    "metaId": "5874",
    "nftId": 12,
    "creationDate": "1691118044549826",
    "id": "12",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "-8",
    "owner": "hx925446099120182af547b1551265f77d123ae060",
    "name": "Emerald City Land #13",
    "zoneId": "8",
    "metaId": "5130",
    "nftId": 13,
    "creationDate": "1691118044549826",
    "id": "13",
    "mintedVia": "CROWN",
  },
  {
    "x": "84",
    "y": "18",
    "owner": "hx925446099120182af547b1551265f77d123ae060",
    "name": "Emerald City Land #14",
    "zoneId": "8",
    "metaId": "5937",
    "nftId": 14,
    "creationDate": "1691118044549826",
    "id": "14",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "0",
    "owner": "hx77abefe4a33e31c208afee301150fb61ad3f0be2",
    "name": "Emerald City Land #15",
    "zoneId": "8",
    "metaId": "6794",
    "nftId": 15,
    "creationDate": "1691118054757650",
    "id": "15",
    "mintedVia": "CROWN",
  },
  {
    "x": "-96",
    "y": "1",
    "owner": "hx77abefe4a33e31c208afee301150fb61ad3f0be2",
    "name": "Emerald City Land #16",
    "zoneId": "8",
    "metaId": "6519",
    "nftId": 16,
    "creationDate": "1691118054757650",
    "id": "16",
    "mintedVia": "CROWN",
  },
  {
    "x": "-77",
    "y": "18",
    "owner": "hx77abefe4a33e31c208afee301150fb61ad3f0be2",
    "name": "Emerald City Land #17",
    "zoneId": "8",
    "metaId": "6292",
    "nftId": 17,
    "creationDate": "1691118054757650",
    "id": "17",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "14",
    "owner": "hx77abefe4a33e31c208afee301150fb61ad3f0be2",
    "name": "Emerald City Land #18",
    "zoneId": "8",
    "metaId": "4906",
    "nftId": 18,
    "creationDate": "1691118054757650",
    "id": "18",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "-5",
    "owner": "hx77abefe4a33e31c208afee301150fb61ad3f0be2",
    "name": "Emerald City Land #19",
    "zoneId": "8",
    "metaId": "6879",
    "nftId": 19,
    "creationDate": "1691118054757650",
    "id": "19",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "-1",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #20",
    "zoneId": "4",
    "metaId": "1239",
    "nftId": 20,
    "creationDate": "1691118054757650",
    "id": "20",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "22",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #21",
    "zoneId": "7",
    "metaId": "3469",
    "nftId": 21,
    "creationDate": "1691118058642574",
    "id": "21",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "27",
    "owner": "hx596540c70cdbaf2ad097fcf28958f7db8f03d894",
    "name": "Emerald City Land #22",
    "zoneId": "7",
    "metaId": "3902",
    "nftId": 22,
    "creationDate": "1691118060709839",
    "id": "22",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "-21",
    "owner": "hx132f340c20777fef65cb9fd41f463772c2f3c7e1",
    "name": "Emerald City Land #23",
    "zoneId": "2",
    "metaId": "822",
    "nftId": 23,
    "creationDate": "1691118062913538",
    "id": "23",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "18",
    "owner": "hx8c5c49759e78ecd4c32cad296900dbe68956bed6",
    "name": "Emerald City Land #24",
    "zoneId": "7",
    "metaId": "2090",
    "nftId": 24,
    "creationDate": "1691118068733067",
    "id": "24",
    "mintedVia": "CROWN",
  },
  {
    "x": "6",
    "y": "15",
    "owner": "hx5b0699827e3731ae468ce3f1bc8ab10ba03a1a22",
    "name": "Emerald City Land #25",
    "zoneId": "7",
    "metaId": "4467",
    "nftId": 25,
    "creationDate": "1691118074533670",
    "id": "25",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-16",
    "owner": "hxba63e3071174621aa182f396d5ce239c37674857",
    "name": "Emerald City Land #26",
    "zoneId": "1",
    "metaId": "670",
    "nftId": 26,
    "creationDate": "1691118074533670",
    "id": "26",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "-25",
    "owner": "hxc910beff64f520c3ef1e078477b13187ad0c620d",
    "name": "Emerald City Land #27",
    "zoneId": "1",
    "metaId": "696",
    "nftId": 27,
    "creationDate": "1691118080595050",
    "id": "27",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "-14",
    "owner": "hx4b00ab353364cfadd4ffc402ac2895fc1e4e8e6d",
    "name": "Emerald City Land #28",
    "zoneId": "1",
    "metaId": "685",
    "nftId": 28,
    "creationDate": "1691118096582636",
    "id": "28",
    "mintedVia": "CROWN",
  },
  {
    "x": "-84",
    "y": "8",
    "owner": "hx596540c70cdbaf2ad097fcf28958f7db8f03d894",
    "name": "Emerald City Land #29",
    "zoneId": "8",
    "metaId": "6361",
    "nftId": 29,
    "creationDate": "1691118106741172",
    "id": "29",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "-9",
    "owner": "hx596540c70cdbaf2ad097fcf28958f7db8f03d894",
    "name": "Emerald City Land #30",
    "zoneId": "8",
    "metaId": "5863",
    "nftId": 30,
    "creationDate": "1691118106741172",
    "id": "30",
    "mintedVia": "CROWN",
  },
  {
    "x": "-105",
    "y": "-4",
    "owner": "hxc910beff64f520c3ef1e078477b13187ad0c620d",
    "name": "Emerald City Land #31",
    "zoneId": "8",
    "metaId": "6167",
    "nftId": 31,
    "creationDate": "1691118128593992",
    "id": "31",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "31",
    "owner": "hxba63e3071174621aa182f396d5ce239c37674857",
    "name": "Emerald City Land #32",
    "zoneId": "3",
    "metaId": "1027",
    "nftId": 32,
    "creationDate": "1691118132646263",
    "id": "32",
    "mintedVia": "CROWN",
  },
  {
    "x": "-101",
    "y": "-4",
    "owner": "hxc8a20b8169d223630de99f6199c99c8c8c1712ea",
    "name": "Emerald City Land #33",
    "zoneId": "8",
    "metaId": "6103",
    "nftId": 33,
    "creationDate": "1691118134756192",
    "id": "33",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "-9",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #34",
    "zoneId": "2",
    "metaId": "733",
    "nftId": 34,
    "creationDate": "1691118136779736",
    "id": "34",
    "mintedVia": "CROWN",
  },
  {
    "x": "15",
    "y": "12",
    "owner": "hx5b0699827e3731ae468ce3f1bc8ab10ba03a1a22",
    "name": "Emerald City Land #35",
    "zoneId": "8",
    "metaId": "4858",
    "nftId": 35,
    "creationDate": "1691118136779736",
    "id": "35",
    "mintedVia": "CROWN",
  },
  {
    "x": "-46",
    "y": "-6",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #36",
    "zoneId": "8",
    "metaId": "6889",
    "nftId": 36,
    "creationDate": "1691118138575205",
    "id": "36",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "-10",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #37",
    "zoneId": "8",
    "metaId": "5470",
    "nftId": 37,
    "creationDate": "1691118138575205",
    "id": "37",
    "mintedVia": "CROWN",
  },
  {
    "x": "-71",
    "y": "20",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #38",
    "zoneId": "8",
    "metaId": "6237",
    "nftId": 38,
    "creationDate": "1691118138575205",
    "id": "38",
    "mintedVia": "CROWN",
  },
  {
    "x": "-102",
    "y": "1",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #39",
    "zoneId": "8",
    "metaId": "6127",
    "nftId": 39,
    "creationDate": "1691118138575205",
    "id": "39",
    "mintedVia": "CROWN",
  },
  {
    "x": "85",
    "y": "14",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #40",
    "zoneId": "8",
    "metaId": "5965",
    "nftId": 40,
    "creationDate": "1691118138575205",
    "id": "40",
    "mintedVia": "CROWN",
  },
  {
    "x": "27",
    "y": "26",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #41",
    "zoneId": "8",
    "metaId": "4713",
    "nftId": 41,
    "creationDate": "1691118142634825",
    "id": "41",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "13",
    "owner": "hx8c5c49759e78ecd4c32cad296900dbe68956bed6",
    "name": "Emerald City Land #42",
    "zoneId": "5",
    "metaId": "1642",
    "nftId": 42,
    "creationDate": "1691118154762797",
    "id": "42",
    "mintedVia": "CROWN",
  },
  {
    "x": "20",
    "y": "30",
    "owner": "hxba63e3071174621aa182f396d5ce239c37674857",
    "name": "Emerald City Land #43",
    "zoneId": "3",
    "metaId": "949",
    "nftId": 43,
    "creationDate": "1691118166632934",
    "id": "43",
    "mintedVia": "CROWN",
  },
  {
    "x": "58",
    "y": "20",
    "owner": "hxc910beff64f520c3ef1e078477b13187ad0c620d",
    "name": "Emerald City Land #44",
    "zoneId": "8",
    "metaId": "5034",
    "nftId": 44,
    "creationDate": "1691118174562217",
    "id": "44",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "25",
    "owner": "hx39769786f467e354b797c72313c1890858f7827c",
    "name": "Emerald City Land #45",
    "zoneId": "7",
    "metaId": "3657",
    "nftId": 45,
    "creationDate": "1691118182619303",
    "id": "45",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-13",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #46",
    "zoneId": "1",
    "metaId": "652",
    "nftId": 46,
    "creationDate": "1691118188589001",
    "id": "46",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-20",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #47",
    "zoneId": "1",
    "metaId": "632",
    "nftId": 47,
    "creationDate": "1691118188589001",
    "id": "47",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "4",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #48",
    "zoneId": "1",
    "metaId": "310",
    "nftId": 48,
    "creationDate": "1691118188589001",
    "id": "48",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "-26",
    "owner": "hx77abefe4a33e31c208afee301150fb61ad3f0be2",
    "name": "Emerald City Land #49",
    "zoneId": "7",
    "metaId": "4535",
    "nftId": 49,
    "creationDate": "1691118194604569",
    "id": "49",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "-17",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #50",
    "zoneId": "8",
    "metaId": "5196",
    "nftId": 50,
    "creationDate": "1691118204787709",
    "id": "50",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "22",
    "owner": "hxba63e3071174621aa182f396d5ce239c37674857",
    "name": "Emerald City Land #51",
    "zoneId": "8",
    "metaId": "5769",
    "nftId": 51,
    "creationDate": "1691118204787709",
    "id": "51",
    "mintedVia": "CROWN",
  },
  {
    "x": "-106",
    "y": "-5",
    "owner": "hx5b0699827e3731ae468ce3f1bc8ab10ba03a1a22",
    "name": "Emerald City Land #52",
    "zoneId": "8",
    "metaId": "6179",
    "nftId": 52,
    "creationDate": "1691118216646793",
    "id": "52",
    "mintedVia": "CROWN",
  },
  {
    "x": "-79",
    "y": "17",
    "owner": "hxc910beff64f520c3ef1e078477b13187ad0c620d",
    "name": "Emerald City Land #53",
    "zoneId": "8",
    "metaId": "6309",
    "nftId": 53,
    "creationDate": "1691118228625955",
    "id": "53",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "-6",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #54",
    "zoneId": "8",
    "metaId": "5363",
    "nftId": 54,
    "creationDate": "1691118246602723",
    "id": "54",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "-12",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #55",
    "zoneId": "8",
    "metaId": "5123",
    "nftId": 55,
    "creationDate": "1691118246602723",
    "id": "55",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "1",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #56",
    "zoneId": "8",
    "metaId": "5288",
    "nftId": 56,
    "creationDate": "1691118246602723",
    "id": "56",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "-13",
    "owner": "hx78db693f26e0780fe0b21fcdce78b5d627fbc8c5",
    "name": "Emerald City Land #57",
    "zoneId": "8",
    "metaId": "5091",
    "nftId": 57,
    "creationDate": "1691118246602723",
    "id": "57",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "15",
    "owner": "hx78db693f26e0780fe0b21fcdce78b5d627fbc8c5",
    "name": "Emerald City Land #58",
    "zoneId": "8",
    "metaId": "5798",
    "nftId": 58,
    "creationDate": "1691118246602723",
    "id": "58",
    "mintedVia": "CROWN",
  },
  {
    "x": "-69",
    "y": "-28",
    "owner": "hxf39ec72347977b0d0b313eb067353ba32927f51d",
    "name": "Emerald City Land #59",
    "zoneId": "2",
    "metaId": "908",
    "nftId": 59,
    "creationDate": "1691118248630240",
    "id": "59",
    "mintedVia": "CROWN",
  },
  {
    "x": "-64",
    "y": "-32",
    "owner": "hxf39ec72347977b0d0b313eb067353ba32927f51d",
    "name": "Emerald City Land #60",
    "zoneId": "2",
    "metaId": "891",
    "nftId": 60,
    "creationDate": "1691118248630240",
    "id": "60",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "29",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #61",
    "zoneId": "8",
    "metaId": "4862",
    "nftId": 61,
    "creationDate": "1691118264790056",
    "id": "61",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "-6",
    "owner": "hxf335c3753f6e38c9797f65881bf052293da718c2",
    "name": "Emerald City Land #62",
    "zoneId": "7",
    "metaId": "4629",
    "nftId": 62,
    "creationDate": "1691118278639148",
    "id": "62",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "-14",
    "owner": "hx77abefe4a33e31c208afee301150fb61ad3f0be2",
    "name": "Emerald City Land #63",
    "zoneId": "8",
    "metaId": "6829",
    "nftId": 63,
    "creationDate": "1691118282649990",
    "id": "63",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "-9",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #64",
    "zoneId": "7",
    "metaId": "2555",
    "nftId": 64,
    "creationDate": "1691118286784029",
    "id": "64",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "-11",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #65",
    "zoneId": "7",
    "metaId": "2225",
    "nftId": 65,
    "creationDate": "1691118286784029",
    "id": "65",
    "mintedVia": "CROWN",
  },
  {
    "x": "8",
    "y": "26",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #66",
    "zoneId": "7",
    "metaId": "4510",
    "nftId": 66,
    "creationDate": "1691118286784029",
    "id": "66",
    "mintedVia": "CROWN",
  },
  {
    "x": "-19",
    "y": "5",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #67",
    "zoneId": "7",
    "metaId": "2984",
    "nftId": 67,
    "creationDate": "1691118286784029",
    "id": "67",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "-15",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #68",
    "zoneId": "7",
    "metaId": "4619",
    "nftId": 68,
    "creationDate": "1691118286784029",
    "id": "68",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "8",
    "owner": "hxc8a20b8169d223630de99f6199c99c8c8c1712ea",
    "name": "Emerald City Land #69",
    "zoneId": "8",
    "metaId": "5120",
    "nftId": 69,
    "creationDate": "1691118306791263",
    "id": "69",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "-24",
    "owner": "hx39769786f467e354b797c72313c1890858f7827c",
    "name": "Emerald City Land #70",
    "zoneId": "1",
    "metaId": "195",
    "nftId": 70,
    "creationDate": "1691118308679803",
    "id": "70",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "-2",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #71",
    "zoneId": "1",
    "metaId": "337",
    "nftId": 71,
    "creationDate": "1691118312792871",
    "id": "71",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "5",
    "owner": "hxc8a20b8169d223630de99f6199c99c8c8c1712ea",
    "name": "Emerald City Land #72",
    "zoneId": "7",
    "metaId": "2930",
    "nftId": 72,
    "creationDate": "1691118344649034",
    "id": "72",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "-13",
    "owner": "hxcbf5b67955ea2ee5806d63436e787b5d63d2ab58",
    "name": "Emerald City Land #73",
    "zoneId": "3",
    "metaId": "1134",
    "nftId": 73,
    "creationDate": "1691118344649034",
    "id": "73",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "-5",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #74",
    "zoneId": "4",
    "metaId": "1334",
    "nftId": 74,
    "creationDate": "1691118366695503",
    "id": "74",
    "mintedVia": "CROWN",
  },
  {
    "x": "0",
    "y": "-6",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #75",
    "zoneId": "7",
    "metaId": "3151",
    "nftId": 75,
    "creationDate": "1691118368824000",
    "id": "75",
    "mintedVia": "CROWN",
  },
  {
    "x": "-10",
    "y": "16",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #76",
    "zoneId": "7",
    "metaId": "3376",
    "nftId": 76,
    "creationDate": "1691118368824000",
    "id": "76",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "-8",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #77",
    "zoneId": "7",
    "metaId": "2440",
    "nftId": 77,
    "creationDate": "1691118368824000",
    "id": "77",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "26",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #78",
    "zoneId": "7",
    "metaId": "3559",
    "nftId": 78,
    "creationDate": "1691118368824000",
    "id": "78",
    "mintedVia": "CROWN",
  },
  {
    "x": "1",
    "y": "28",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #79",
    "zoneId": "7",
    "metaId": "3886",
    "nftId": 79,
    "creationDate": "1691118368824000",
    "id": "79",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "27",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #80",
    "zoneId": "8",
    "metaId": "5733",
    "nftId": 80,
    "creationDate": "1691118384821573",
    "id": "80",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "19",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #81",
    "zoneId": "8",
    "metaId": "6033",
    "nftId": 81,
    "creationDate": "1691118404856767",
    "id": "81",
    "mintedVia": "CROWN",
  },
  {
    "x": "38",
    "y": "20",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #82",
    "zoneId": "8",
    "metaId": "4970",
    "nftId": 82,
    "creationDate": "1691118404856767",
    "id": "82",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "-15",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #83",
    "zoneId": "8",
    "metaId": "5593",
    "nftId": 83,
    "creationDate": "1691118404856767",
    "id": "83",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "-5",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #84",
    "zoneId": "8",
    "metaId": "5282",
    "nftId": 84,
    "creationDate": "1691118404856767",
    "id": "84",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "29",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #85",
    "zoneId": "8",
    "metaId": "5813",
    "nftId": 85,
    "creationDate": "1691118404856767",
    "id": "85",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "10",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #86",
    "zoneId": "7",
    "metaId": "4088",
    "nftId": 86,
    "creationDate": "1691118416719154",
    "id": "86",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "2",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #87",
    "zoneId": "1",
    "metaId": "308",
    "nftId": 87,
    "creationDate": "1691118420733616",
    "id": "87",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "2",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #88",
    "zoneId": "1",
    "metaId": "241",
    "nftId": 88,
    "creationDate": "1691118420733616",
    "id": "88",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "25",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #89",
    "zoneId": "1",
    "metaId": "23",
    "nftId": 89,
    "creationDate": "1691118420733616",
    "id": "89",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "5",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #90",
    "zoneId": "7",
    "metaId": "4052",
    "nftId": 90,
    "creationDate": "1691118462833299",
    "id": "90",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "16",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #91",
    "zoneId": "8",
    "metaId": "5682",
    "nftId": 91,
    "creationDate": "1691118462833299",
    "id": "91",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "-15",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #92",
    "zoneId": "8",
    "metaId": "5708",
    "nftId": 92,
    "creationDate": "1691118462833299",
    "id": "92",
    "mintedVia": "CROWN",
  },
  {
    "x": "-108",
    "y": "-1",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #93",
    "zoneId": "8",
    "metaId": "6193",
    "nftId": 93,
    "creationDate": "1691118462833299",
    "id": "93",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "-8",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #94",
    "zoneId": "7",
    "metaId": "2181",
    "nftId": 94,
    "creationDate": "1691118464851369",
    "id": "94",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "9",
    "owner": "hxcfc147627b87c10cbe48c02f8bbb94e34b7b6cb8",
    "name": "Emerald City Land #95",
    "zoneId": "1",
    "metaId": "334",
    "nftId": 95,
    "creationDate": "1691118468859931",
    "id": "95",
    "mintedVia": "CROWN",
  },
  {
    "x": "-92",
    "y": "2",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #96",
    "zoneId": "8",
    "metaId": "6443",
    "nftId": 96,
    "creationDate": "1691118478700114",
    "id": "96",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "18",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #97",
    "zoneId": "8",
    "metaId": "6032",
    "nftId": 97,
    "creationDate": "1691118478700114",
    "id": "97",
    "mintedVia": "CROWN",
  },
  {
    "x": "84",
    "y": "-5",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #98",
    "zoneId": "8",
    "metaId": "5924",
    "nftId": 98,
    "creationDate": "1691118478700114",
    "id": "98",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "6",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #99",
    "zoneId": "8",
    "metaId": "5917",
    "nftId": 99,
    "creationDate": "1691118478700114",
    "id": "99",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "5",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #100",
    "zoneId": "8",
    "metaId": "5543",
    "nftId": 100,
    "creationDate": "1691118478700114",
    "id": "100",
    "mintedVia": "CROWN",
  },
  {
    "x": "46",
    "y": "19",
    "owner": "hxb66e8c52f3f583ca21604af2ea30e1ae62d87f96",
    "name": "Emerald City Land #101",
    "zoneId": "1",
    "metaId": "13",
    "nftId": 101,
    "creationDate": "1691118500673970",
    "id": "101",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "12",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #102",
    "zoneId": "7",
    "metaId": "4136",
    "nftId": 102,
    "creationDate": "1691118506859048",
    "id": "102",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "12",
    "owner": "hxdb2d5b5dfbb4a40903982cb03c8587fabcdd9ad6",
    "name": "Emerald City Land #103",
    "zoneId": "1",
    "metaId": "578",
    "nftId": 103,
    "creationDate": "1691118522737443",
    "id": "103",
    "mintedVia": "CROWN",
  },
  {
    "x": "0",
    "y": "-10",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #104",
    "zoneId": "7",
    "metaId": "3147",
    "nftId": 104,
    "creationDate": "1691118522737443",
    "id": "104",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "-8",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #105",
    "zoneId": "7",
    "metaId": "2754",
    "nftId": 105,
    "creationDate": "1691118522737443",
    "id": "105",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "21",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #106",
    "zoneId": "7",
    "metaId": "2031",
    "nftId": 106,
    "creationDate": "1691118522737443",
    "id": "106",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "-1",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #107",
    "zoneId": "7",
    "metaId": "3053",
    "nftId": 107,
    "creationDate": "1691118522737443",
    "id": "107",
    "mintedVia": "CROWN",
  },
  {
    "x": "11",
    "y": "10",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #108",
    "zoneId": "7",
    "metaId": "2042",
    "nftId": 108,
    "creationDate": "1691118522737443",
    "id": "108",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "9",
    "owner": "hxcfc147627b87c10cbe48c02f8bbb94e34b7b6cb8",
    "name": "Emerald City Land #109",
    "zoneId": "8",
    "metaId": "6711",
    "nftId": 109,
    "creationDate": "1691118536875742",
    "id": "109",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "22",
    "owner": "hxba63e3071174621aa182f396d5ce239c37674857",
    "name": "Emerald City Land #110",
    "zoneId": "8",
    "metaId": "4962",
    "nftId": 110,
    "creationDate": "1691118548729027",
    "id": "110",
    "mintedVia": "CROWN",
  },
  {
    "x": "-18",
    "y": "3",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #111",
    "zoneId": "7",
    "metaId": "2970",
    "nftId": 111,
    "creationDate": "1691118550692808",
    "id": "111",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "21",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #112",
    "zoneId": "7",
    "metaId": "1980",
    "nftId": 112,
    "creationDate": "1691118558764861",
    "id": "112",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "7",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #113",
    "zoneId": "7",
    "metaId": "4202",
    "nftId": 113,
    "creationDate": "1691118558764861",
    "id": "113",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "10",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #114",
    "zoneId": "7",
    "metaId": "3628",
    "nftId": 114,
    "creationDate": "1691118558764861",
    "id": "114",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "17",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #115",
    "zoneId": "7",
    "metaId": "4093",
    "nftId": 115,
    "creationDate": "1691118558764861",
    "id": "115",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "-1",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #116",
    "zoneId": "7",
    "metaId": "2918",
    "nftId": 116,
    "creationDate": "1691118558764861",
    "id": "116",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "-23",
    "owner": "hx2aa24e20c2b14a8cbefaca13b1b8b2b31b7923b7",
    "name": "Emerald City Land #117",
    "zoneId": "2",
    "metaId": "767",
    "nftId": 117,
    "creationDate": "1691118558764861",
    "id": "117",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "0",
    "owner": "hx8cd9b1967a8646fad7ada9ce09462a5ea3f08804",
    "name": "Emerald City Land #118",
    "zoneId": "1",
    "metaId": "474",
    "nftId": 118,
    "creationDate": "1691118578727536",
    "id": "118",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "-20",
    "owner": "hx8cd9b1967a8646fad7ada9ce09462a5ea3f08804",
    "name": "Emerald City Land #119",
    "zoneId": "1",
    "metaId": "634",
    "nftId": 119,
    "creationDate": "1691118578727536",
    "id": "119",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "21",
    "owner": "hx2aa24e20c2b14a8cbefaca13b1b8b2b31b7923b7",
    "name": "Emerald City Land #120",
    "zoneId": "4",
    "metaId": "1227",
    "nftId": 120,
    "creationDate": "1691118598745645",
    "id": "120",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "-29",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #121",
    "zoneId": "2",
    "metaId": "778",
    "nftId": 121,
    "creationDate": "1691118602722213",
    "id": "121",
    "mintedVia": "CROWN",
  },
  {
    "x": "-10",
    "y": "20",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #122",
    "zoneId": "7",
    "metaId": "3379",
    "nftId": 122,
    "creationDate": "1691118608775606",
    "id": "122",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "-8",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #123",
    "zoneId": "7",
    "metaId": "2991",
    "nftId": 123,
    "creationDate": "1691118608775606",
    "id": "123",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "25",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #124",
    "zoneId": "7",
    "metaId": "3558",
    "nftId": 124,
    "creationDate": "1691118608775606",
    "id": "124",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "9",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #125",
    "zoneId": "7",
    "metaId": "2542",
    "nftId": 125,
    "creationDate": "1691118608775606",
    "id": "125",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "-3",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #126",
    "zoneId": "7",
    "metaId": "4583",
    "nftId": 126,
    "creationDate": "1691118608775606",
    "id": "126",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "2",
    "owner": "hx95ea1371b98d4265d6331ba09f1e7290b7376915",
    "name": "Emerald City Land #127",
    "zoneId": "8",
    "metaId": "5570",
    "nftId": 127,
    "creationDate": "1691118614893748",
    "id": "127",
    "mintedVia": "CROWN",
  },
  {
    "x": "-67",
    "y": "-32",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #128",
    "zoneId": "2",
    "metaId": "902",
    "nftId": 128,
    "creationDate": "1691118618908119",
    "id": "128",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "-31",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #129",
    "zoneId": "2",
    "metaId": "861",
    "nftId": 129,
    "creationDate": "1691118618908119",
    "id": "129",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "-21",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #130",
    "zoneId": "2",
    "metaId": "814",
    "nftId": 130,
    "creationDate": "1691118618908119",
    "id": "130",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "14",
    "owner": "hxba63e3071174621aa182f396d5ce239c37674857",
    "name": "Emerald City Land #131",
    "zoneId": "7",
    "metaId": "3891",
    "nftId": 131,
    "creationDate": "1691118620806526",
    "id": "131",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "20",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #132",
    "zoneId": "8",
    "metaId": "5218",
    "nftId": 132,
    "creationDate": "1691118628746767",
    "id": "132",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "4",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #133",
    "zoneId": "8",
    "metaId": "6545",
    "nftId": 133,
    "creationDate": "1691118628746767",
    "id": "133",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "17",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #134",
    "zoneId": "8",
    "metaId": "6031",
    "nftId": 134,
    "creationDate": "1691118628746767",
    "id": "134",
    "mintedVia": "CROWN",
  },
  {
    "x": "-102",
    "y": "0",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #135",
    "zoneId": "8",
    "metaId": "6126",
    "nftId": 135,
    "creationDate": "1691118628746767",
    "id": "135",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "12",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #136",
    "zoneId": "8",
    "metaId": "5758",
    "nftId": 136,
    "creationDate": "1691118628746767",
    "id": "136",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "29",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #137",
    "zoneId": "8",
    "metaId": "5226",
    "nftId": 137,
    "creationDate": "1691118628746767",
    "id": "137",
    "mintedVia": "CROWN",
  },
  {
    "x": "18",
    "y": "23",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #138",
    "zoneId": "8",
    "metaId": "4703",
    "nftId": 138,
    "creationDate": "1691118628746767",
    "id": "138",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "8",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #139",
    "zoneId": "8",
    "metaId": "6802",
    "nftId": 139,
    "creationDate": "1691118628746767",
    "id": "139",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "19",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #140",
    "zoneId": "8",
    "metaId": "4702",
    "nftId": 140,
    "creationDate": "1691118628746767",
    "id": "140",
    "mintedVia": "CROWN",
  },
  {
    "x": "84",
    "y": "14",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #141",
    "zoneId": "8",
    "metaId": "5933",
    "nftId": 141,
    "creationDate": "1691118628746767",
    "id": "141",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-22",
    "owner": "hxb66e8c52f3f583ca21604af2ea30e1ae62d87f96",
    "name": "Emerald City Land #142",
    "zoneId": "7",
    "metaId": "4644",
    "nftId": 142,
    "creationDate": "1691118636901639",
    "id": "142",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "7",
    "owner": "hx2aa24e20c2b14a8cbefaca13b1b8b2b31b7923b7",
    "name": "Emerald City Land #143",
    "zoneId": "8",
    "metaId": "6548",
    "nftId": 143,
    "creationDate": "1691118640780687",
    "id": "143",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "2",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #144",
    "zoneId": "8",
    "metaId": "6781",
    "nftId": 144,
    "creationDate": "1691118644750188",
    "id": "144",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "8",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #145",
    "zoneId": "7",
    "metaId": "2998",
    "nftId": 145,
    "creationDate": "1691118650723173",
    "id": "145",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "16",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #146",
    "zoneId": "7",
    "metaId": "4210",
    "nftId": 146,
    "creationDate": "1691118650723173",
    "id": "146",
    "mintedVia": "CROWN",
  },
  {
    "x": "6",
    "y": "6",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #147",
    "zoneId": "7",
    "metaId": "3325",
    "nftId": 147,
    "creationDate": "1691118650723173",
    "id": "147",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "-2",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #148",
    "zoneId": "7",
    "metaId": "4441",
    "nftId": 148,
    "creationDate": "1691118650723173",
    "id": "148",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "21",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #149",
    "zoneId": "7",
    "metaId": "4145",
    "nftId": 149,
    "creationDate": "1691118650723173",
    "id": "149",
    "mintedVia": "CROWN",
  },
  {
    "x": "3",
    "y": "13",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #150",
    "zoneId": "7",
    "metaId": "3275",
    "nftId": 150,
    "creationDate": "1691118690796932",
    "id": "150",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "22",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #151",
    "zoneId": "7",
    "metaId": "4121",
    "nftId": 151,
    "creationDate": "1691118690796932",
    "id": "151",
    "mintedVia": "CROWN",
  },
  {
    "x": "8",
    "y": "23",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #152",
    "zoneId": "7",
    "metaId": "4507",
    "nftId": 152,
    "creationDate": "1691118690796932",
    "id": "152",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "13",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #153",
    "zoneId": "7",
    "metaId": "4313",
    "nftId": 153,
    "creationDate": "1691118690796932",
    "id": "153",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "-2",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #154",
    "zoneId": "7",
    "metaId": "4028",
    "nftId": 154,
    "creationDate": "1691118690796932",
    "id": "154",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "19",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #155",
    "zoneId": "3",
    "metaId": "1087",
    "nftId": 155,
    "creationDate": "1691118708807592",
    "id": "155",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "22",
    "owner": "hx6a7c99794e15a4ee03557887f11a98b0d4adbedc",
    "name": "Emerald City Land #156",
    "zoneId": "8",
    "metaId": "5652",
    "nftId": 156,
    "creationDate": "1691118712918654",
    "id": "156",
    "mintedVia": "CROWN",
  },
  {
    "x": "11",
    "y": "5",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #157",
    "zoneId": "7",
    "metaId": "3174",
    "nftId": 157,
    "creationDate": "1691118716794165",
    "id": "157",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "4",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #158",
    "zoneId": "7",
    "metaId": "4284",
    "nftId": 158,
    "creationDate": "1691118716794165",
    "id": "158",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "-10",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #159",
    "zoneId": "7",
    "metaId": "2362",
    "nftId": 159,
    "creationDate": "1691118716794165",
    "id": "159",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "26",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #160",
    "zoneId": "7",
    "metaId": "3926",
    "nftId": 160,
    "creationDate": "1691118716794165",
    "id": "160",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "3",
    "owner": "hxb66e8c52f3f583ca21604af2ea30e1ae62d87f96",
    "name": "Emerald City Land #161",
    "zoneId": "1",
    "metaId": "242",
    "nftId": 161,
    "creationDate": "1691118720827530",
    "id": "161",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "-21",
    "owner": "hx9a8b1bd320fb56bccd5e70865bfe5410e24548bb",
    "name": "Emerald City Land #162",
    "zoneId": "2",
    "metaId": "755",
    "nftId": 162,
    "creationDate": "1691118726779930",
    "id": "162",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "19",
    "owner": "hx8cd9b1967a8646fad7ada9ce09462a5ea3f08804",
    "name": "Emerald City Land #163",
    "zoneId": "7",
    "metaId": "1886",
    "nftId": 163,
    "creationDate": "1691118736935420",
    "id": "163",
    "mintedVia": "CROWN",
  },
  {
    "x": "-23",
    "y": "10",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #164",
    "zoneId": "7",
    "metaId": "3611",
    "nftId": 164,
    "creationDate": "1691118744781944",
    "id": "164",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "29",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #165",
    "zoneId": "7",
    "metaId": "4077",
    "nftId": 165,
    "creationDate": "1691118744781944",
    "id": "165",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "28",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #166",
    "zoneId": "7",
    "metaId": "3992",
    "nftId": 166,
    "creationDate": "1691118744781944",
    "id": "166",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "30",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #167",
    "zoneId": "7",
    "metaId": "3455",
    "nftId": 167,
    "creationDate": "1691118744781944",
    "id": "167",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "6",
    "owner": "hxe347132e97bda05c93e519a3fe5ab35f7d461858",
    "name": "Emerald City Land #168",
    "zoneId": "7",
    "metaId": "3311",
    "nftId": 168,
    "creationDate": "1691118744781944",
    "id": "168",
    "mintedVia": "CROWN",
  },
  {
    "x": "55",
    "y": "14",
    "owner": "hxb66e8c52f3f583ca21604af2ea30e1ae62d87f96",
    "name": "Emerald City Land #169",
    "zoneId": "7",
    "metaId": "2136",
    "nftId": 169,
    "creationDate": "1691118752758192",
    "id": "169",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "9",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #170",
    "zoneId": "6",
    "metaId": "1719",
    "nftId": 170,
    "creationDate": "1691118756952584",
    "id": "170",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "8",
    "owner": "hxa20345ae286358bbef89abf1857927912f0d1fad",
    "name": "Emerald City Land #171",
    "zoneId": "8",
    "metaId": "5072",
    "nftId": 171,
    "creationDate": "1691118800764544",
    "id": "171",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "-13",
    "owner": "hxa20345ae286358bbef89abf1857927912f0d1fad",
    "name": "Emerald City Land #172",
    "zoneId": "8",
    "metaId": "5124",
    "nftId": 172,
    "creationDate": "1691118800764544",
    "id": "172",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "1",
    "owner": "hxbbc3677c77519578162c70a558484b5f629b4539",
    "name": "Emerald City Land #173",
    "zoneId": "4",
    "metaId": "1259",
    "nftId": 173,
    "creationDate": "1691118800764544",
    "id": "173",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "11",
    "owner": "hx9dbf6f87d52fc21ef1501bd94939248374803817",
    "name": "Emerald City Land #174",
    "zoneId": "7",
    "metaId": "1970",
    "nftId": 174,
    "creationDate": "1691118826805636",
    "id": "174",
    "mintedVia": "CROWN",
  },
  {
    "x": "0",
    "y": "33",
    "owner": "hxb66e8c52f3f583ca21604af2ea30e1ae62d87f96",
    "name": "Emerald City Land #175",
    "zoneId": "3",
    "metaId": "1033",
    "nftId": 175,
    "creationDate": "1691118828814595",
    "id": "175",
    "mintedVia": "CROWN",
  },
  {
    "x": "-63",
    "y": "-32",
    "owner": "hxaf4ee010dd42b50ef3ed79cdb37e491bac53bbc5",
    "name": "Emerald City Land #176",
    "zoneId": "2",
    "metaId": "888",
    "nftId": 176,
    "creationDate": "1691118852798783",
    "id": "176",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "-21",
    "owner": "hxaf4ee010dd42b50ef3ed79cdb37e491bac53bbc5",
    "name": "Emerald City Land #177",
    "zoneId": "2",
    "metaId": "762",
    "nftId": 177,
    "creationDate": "1691118852798783",
    "id": "177",
    "mintedVia": "CROWN",
  },
  {
    "x": "32",
    "y": "0",
    "owner": "hxb66e8c52f3f583ca21604af2ea30e1ae62d87f96",
    "name": "Emerald City Land #178",
    "zoneId": "1",
    "metaId": "208",
    "nftId": 178,
    "creationDate": "1691118860822473",
    "id": "178",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "11",
    "owner": "hx8cd9b1967a8646fad7ada9ce09462a5ea3f08804",
    "name": "Emerald City Land #179",
    "zoneId": "5",
    "metaId": "1651",
    "nftId": 179,
    "creationDate": "1691118880838557",
    "id": "179",
    "mintedVia": "CROWN",
  },
  {
    "x": "-96",
    "y": "-17",
    "owner": "hxaf4ee010dd42b50ef3ed79cdb37e491bac53bbc5",
    "name": "Emerald City Land #180",
    "zoneId": "7",
    "metaId": "2386",
    "nftId": 180,
    "creationDate": "1691118924810133",
    "id": "180",
    "mintedVia": "CROWN",
  },
  {
    "x": "-100",
    "y": "-19",
    "owner": "hxaf4ee010dd42b50ef3ed79cdb37e491bac53bbc5",
    "name": "Emerald City Land #181",
    "zoneId": "7",
    "metaId": "2281",
    "nftId": 181,
    "creationDate": "1691118924810133",
    "id": "181",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "-12",
    "owner": "hxaf4ee010dd42b50ef3ed79cdb37e491bac53bbc5",
    "name": "Emerald City Land #182",
    "zoneId": "7",
    "metaId": "2526",
    "nftId": 182,
    "creationDate": "1691118924810133",
    "id": "182",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "-23",
    "owner": "hx9dbf6f87d52fc21ef1501bd94939248374803817",
    "name": "Emerald City Land #183",
    "zoneId": "2",
    "metaId": "829",
    "nftId": 183,
    "creationDate": "1691118940875578",
    "id": "183",
    "mintedVia": "CROWN",
  },
  {
    "x": "-23",
    "y": "18",
    "owner": "hx16c5df937a778038f4da3f9956bc29b8eb26d096",
    "name": "Emerald City Land #184",
    "zoneId": "7",
    "metaId": "3619",
    "nftId": 184,
    "creationDate": "1691118955039971",
    "id": "184",
    "mintedVia": "CROWN",
  },
  {
    "x": "90",
    "y": "15",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #185",
    "zoneId": "8",
    "metaId": "6076",
    "nftId": 185,
    "creationDate": "1691118963003113",
    "id": "185",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "11",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #186",
    "zoneId": "8",
    "metaId": "4786",
    "nftId": 186,
    "creationDate": "1691118963003113",
    "id": "186",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "4",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #187",
    "zoneId": "8",
    "metaId": "5464",
    "nftId": 187,
    "creationDate": "1691118963003113",
    "id": "187",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "14",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #188",
    "zoneId": "8",
    "metaId": "4892",
    "nftId": 188,
    "creationDate": "1691118963003113",
    "id": "188",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "27",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #189",
    "zoneId": "8",
    "metaId": "4848",
    "nftId": 189,
    "creationDate": "1691118963003113",
    "id": "189",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "28",
    "owner": "hxd2ab70a9d09d60aabec1b6c3dadc35e4bf33b6f1",
    "name": "Emerald City Land #190",
    "zoneId": "8",
    "metaId": "5387",
    "nftId": 190,
    "creationDate": "1691118970909752",
    "id": "190",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "29",
    "owner": "hx9dbf6f87d52fc21ef1501bd94939248374803817",
    "name": "Emerald City Land #191",
    "zoneId": "7",
    "metaId": "2067",
    "nftId": 191,
    "creationDate": "1691118987031326",
    "id": "191",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "15",
    "owner": "hx16533f91c766ce4222e6bd4eceb270e08c9a29c8",
    "name": "Emerald City Land #192",
    "zoneId": "8",
    "metaId": "4976",
    "nftId": 192,
    "creationDate": "1691118992912307",
    "id": "192",
    "mintedVia": "CROWN",
  },
  {
    "x": "8",
    "y": "12",
    "owner": "hx9dbf6f87d52fc21ef1501bd94939248374803817",
    "name": "Emerald City Land #193",
    "zoneId": "7",
    "metaId": "3351",
    "nftId": 193,
    "creationDate": "1691119008900836",
    "id": "193",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "29",
    "owner": "hxaf4ee010dd42b50ef3ed79cdb37e491bac53bbc5",
    "name": "Emerald City Land #194",
    "zoneId": "4",
    "metaId": "1210",
    "nftId": 194,
    "creationDate": "1691119046872886",
    "id": "194",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "-9",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #195",
    "zoneId": "8",
    "metaId": "6859",
    "nftId": 195,
    "creationDate": "1691119052861539",
    "id": "195",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "23",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #196",
    "zoneId": "8",
    "metaId": "5534",
    "nftId": 196,
    "creationDate": "1691119052861539",
    "id": "196",
    "mintedVia": "CROWN",
  },
  {
    "x": "9",
    "y": "36",
    "owner": "hxaf4ee010dd42b50ef3ed79cdb37e491bac53bbc5",
    "name": "Emerald City Land #197",
    "zoneId": "3",
    "metaId": "1129",
    "nftId": 197,
    "creationDate": "1691119087039378",
    "id": "197",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "-18",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #198",
    "zoneId": "7",
    "metaId": "2153",
    "nftId": 198,
    "creationDate": "1691119119061595",
    "id": "198",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "3",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #199",
    "zoneId": "7",
    "metaId": "2956",
    "nftId": 199,
    "creationDate": "1691119119061595",
    "id": "199",
    "mintedVia": "CROWN",
  },
  {
    "x": "14",
    "y": "-2",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #200",
    "zoneId": "7",
    "metaId": "3196",
    "nftId": 200,
    "creationDate": "1691119119061595",
    "id": "200",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "9",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #201",
    "zoneId": "7",
    "metaId": "4244",
    "nftId": 201,
    "creationDate": "1691119119061595",
    "id": "201",
    "mintedVia": "CROWN",
  },
  {
    "x": "54",
    "y": "16",
    "owner": "hx0423317d3a7b75fd2a6cac16df3623755e7938f0",
    "name": "Emerald City Land #202",
    "zoneId": "7",
    "metaId": "2018",
    "nftId": 202,
    "creationDate": "1691119126900907",
    "id": "202",
    "mintedVia": "CROWN",
  },
  {
    "x": "-81",
    "y": "14",
    "owner": "hxaf4ee010dd42b50ef3ed79cdb37e491bac53bbc5",
    "name": "Emerald City Land #203",
    "zoneId": "8",
    "metaId": "6327",
    "nftId": 203,
    "creationDate": "1691119130925009",
    "id": "203",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "25",
    "owner": "hxaf4ee010dd42b50ef3ed79cdb37e491bac53bbc5",
    "name": "Emerald City Land #204",
    "zoneId": "8",
    "metaId": "6008",
    "nftId": 204,
    "creationDate": "1691119130925009",
    "id": "204",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "-5",
    "owner": "hxaf4ee010dd42b50ef3ed79cdb37e491bac53bbc5",
    "name": "Emerald City Land #205",
    "zoneId": "8",
    "metaId": "6760",
    "nftId": 205,
    "creationDate": "1691119130925009",
    "id": "205",
    "mintedVia": "CROWN",
  },
  {
    "x": "40",
    "y": "17",
    "owner": "hxaf4ee010dd42b50ef3ed79cdb37e491bac53bbc5",
    "name": "Emerald City Land #206",
    "zoneId": "8",
    "metaId": "5008",
    "nftId": 206,
    "creationDate": "1691119130925009",
    "id": "206",
    "mintedVia": "CROWN",
  },
  {
    "x": "-92",
    "y": "11",
    "owner": "hxaf4ee010dd42b50ef3ed79cdb37e491bac53bbc5",
    "name": "Emerald City Land #207",
    "zoneId": "8",
    "metaId": "6442",
    "nftId": 207,
    "creationDate": "1691119130925009",
    "id": "207",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "-2",
    "owner": "hx9a8b1bd320fb56bccd5e70865bfe5410e24548bb",
    "name": "Emerald City Land #208",
    "zoneId": "7",
    "metaId": "3112",
    "nftId": 208,
    "creationDate": "1691119152887697",
    "id": "208",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "17",
    "owner": "hx9a8b1bd320fb56bccd5e70865bfe5410e24548bb",
    "name": "Emerald City Land #209",
    "zoneId": "7",
    "metaId": "3929",
    "nftId": 209,
    "creationDate": "1691119152887697",
    "id": "209",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "24",
    "owner": "hxaa96bc248c1fd538057eb795c25c0e06dc22b9c7",
    "name": "Emerald City Land #210",
    "zoneId": "8",
    "metaId": "5842",
    "nftId": 210,
    "creationDate": "1691119165066271",
    "id": "210",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "-3",
    "owner": "hxaa96bc248c1fd538057eb795c25c0e06dc22b9c7",
    "name": "Emerald City Land #211",
    "zoneId": "8",
    "metaId": "6806",
    "nftId": 211,
    "creationDate": "1691119165066271",
    "id": "211",
    "mintedVia": "CROWN",
  },
  {
    "x": "-91",
    "y": "-3",
    "owner": "hxaf4ee010dd42b50ef3ed79cdb37e491bac53bbc5",
    "name": "Emerald City Land #212",
    "zoneId": "8",
    "metaId": "6417",
    "nftId": 212,
    "creationDate": "1691119196915080",
    "id": "212",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "-22",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #213",
    "zoneId": "2",
    "metaId": "766",
    "nftId": 213,
    "creationDate": "1691119232935315",
    "id": "213",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-35",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #214",
    "zoneId": "2",
    "metaId": "856",
    "nftId": 214,
    "creationDate": "1691119232935315",
    "id": "214",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "-23",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #215",
    "zoneId": "2",
    "metaId": "824",
    "nftId": 215,
    "creationDate": "1691119232935315",
    "id": "215",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "-33",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #216",
    "zoneId": "2",
    "metaId": "826",
    "nftId": 216,
    "creationDate": "1691119298977599",
    "id": "216",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "-22",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #217",
    "zoneId": "2",
    "metaId": "794",
    "nftId": 217,
    "creationDate": "1691119298977599",
    "id": "217",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "9",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #218",
    "zoneId": "1",
    "metaId": "246",
    "nftId": 218,
    "creationDate": "1691119307112665",
    "id": "218",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "-24",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #219",
    "zoneId": "1",
    "metaId": "181",
    "nftId": 219,
    "creationDate": "1691119307112665",
    "id": "219",
    "mintedVia": "CROWN",
  },
  {
    "x": "20",
    "y": "5",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #220",
    "zoneId": "1",
    "metaId": "398",
    "nftId": 220,
    "creationDate": "1691119307112665",
    "id": "220",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "-3",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #221",
    "zoneId": "1",
    "metaId": "234",
    "nftId": 221,
    "creationDate": "1691119307112665",
    "id": "221",
    "mintedVia": "CROWN",
  },
  {
    "x": "-82",
    "y": "13",
    "owner": "hx78db693f26e0780fe0b21fcdce78b5d627fbc8c5",
    "name": "Emerald City Land #222",
    "zoneId": "8",
    "metaId": "6338",
    "nftId": 222,
    "creationDate": "1691119326958697",
    "id": "222",
    "mintedVia": "CROWN",
  },
  {
    "x": "-72",
    "y": "17",
    "owner": "hx78db693f26e0780fe0b21fcdce78b5d627fbc8c5",
    "name": "Emerald City Land #223",
    "zoneId": "8",
    "metaId": "6244",
    "nftId": 223,
    "creationDate": "1691119326958697",
    "id": "223",
    "mintedVia": "CROWN",
  },
  {
    "x": "-104",
    "y": "-11",
    "owner": "hxff2eadfeba8316166328d55f9248f1a728d76967",
    "name": "Emerald City Land #224",
    "zoneId": "7",
    "metaId": "2298",
    "nftId": 224,
    "creationDate": "1691119328971179",
    "id": "224",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "2",
    "owner": "hx2a1699a1c2babf5522023830d5bd7a99dfa3f7ad",
    "name": "Emerald City Land #225",
    "zoneId": "7",
    "metaId": "4395",
    "nftId": 225,
    "creationDate": "1691119328971179",
    "id": "225",
    "mintedVia": "CROWN",
  },
  {
    "x": "9",
    "y": "28",
    "owner": "hx2a1699a1c2babf5522023830d5bd7a99dfa3f7ad",
    "name": "Emerald City Land #226",
    "zoneId": "7",
    "metaId": "4528",
    "nftId": 226,
    "creationDate": "1691119328971179",
    "id": "226",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "23",
    "owner": "hx2a1699a1c2babf5522023830d5bd7a99dfa3f7ad",
    "name": "Emerald City Land #227",
    "zoneId": "7",
    "metaId": "3989",
    "nftId": 227,
    "creationDate": "1691119328971179",
    "id": "227",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "-23",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #228",
    "zoneId": "2",
    "metaId": "763",
    "nftId": 228,
    "creationDate": "1691119365123750",
    "id": "228",
    "mintedVia": "CROWN",
  },
  {
    "x": "-104",
    "y": "3",
    "owner": "hx2a1699a1c2babf5522023830d5bd7a99dfa3f7ad",
    "name": "Emerald City Land #229",
    "zoneId": "1",
    "metaId": "115",
    "nftId": 229,
    "creationDate": "1691119380998832",
    "id": "229",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-7",
    "owner": "hx2a1699a1c2babf5522023830d5bd7a99dfa3f7ad",
    "name": "Emerald City Land #230",
    "zoneId": "1",
    "metaId": "322",
    "nftId": 230,
    "creationDate": "1691119380998832",
    "id": "230",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "12",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #231",
    "zoneId": "5",
    "metaId": "1647",
    "nftId": 231,
    "creationDate": "1691119385137683",
    "id": "231",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "12",
    "owner": "hxc8ce3b36c494b6979c9379d34338ec49461c3a94",
    "name": "Emerald City Land #232",
    "zoneId": "7",
    "metaId": "4250",
    "nftId": 232,
    "creationDate": "1691119387133594",
    "id": "232",
    "mintedVia": "CROWN",
  },
  {
    "x": "0",
    "y": "-8",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #233",
    "zoneId": "7",
    "metaId": "3153",
    "nftId": 233,
    "creationDate": "1691119417012539",
    "id": "233",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "12",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #234",
    "zoneId": "7",
    "metaId": "4090",
    "nftId": 234,
    "creationDate": "1691119417012539",
    "id": "234",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "8",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #235",
    "zoneId": "7",
    "metaId": "3247",
    "nftId": 235,
    "creationDate": "1691119417012539",
    "id": "235",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "27",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #236",
    "zoneId": "7",
    "metaId": "3490",
    "nftId": 236,
    "creationDate": "1691119417012539",
    "id": "236",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "5",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #237",
    "zoneId": "7",
    "metaId": "2223",
    "nftId": 237,
    "creationDate": "1691119417012539",
    "id": "237",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "1",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #238",
    "zoneId": "7",
    "metaId": "2743",
    "nftId": 238,
    "creationDate": "1691119421065565",
    "id": "238",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "11",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #239",
    "zoneId": "7",
    "metaId": "3597",
    "nftId": 239,
    "creationDate": "1691119421065565",
    "id": "239",
    "mintedVia": "CROWN",
  },
  {
    "x": "17",
    "y": "2",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #240",
    "zoneId": "7",
    "metaId": "3226",
    "nftId": 240,
    "creationDate": "1691119421065565",
    "id": "240",
    "mintedVia": "CROWN",
  },
  {
    "x": "18",
    "y": "12",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #241",
    "zoneId": "7",
    "metaId": "1922",
    "nftId": 241,
    "creationDate": "1691119421065565",
    "id": "241",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "34",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #242",
    "zoneId": "7",
    "metaId": "4497",
    "nftId": 242,
    "creationDate": "1691119421065565",
    "id": "242",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "-19",
    "owner": "hx43cbd35f79d8083b171478a7d262964c82f62c35",
    "name": "Emerald City Land #243",
    "zoneId": "1",
    "metaId": "701",
    "nftId": 243,
    "creationDate": "1691119443049778",
    "id": "243",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "17",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #244",
    "zoneId": "4",
    "metaId": "1506",
    "nftId": 244,
    "creationDate": "1691119463158961",
    "id": "244",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "-16",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #245",
    "zoneId": "4",
    "metaId": "1294",
    "nftId": 245,
    "creationDate": "1691119463158961",
    "id": "245",
    "mintedVia": "CROWN",
  },
  {
    "x": "-19",
    "y": "25",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #246",
    "zoneId": "4",
    "metaId": "1449",
    "nftId": 246,
    "creationDate": "1691119463158961",
    "id": "246",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "22",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #247",
    "zoneId": "7",
    "metaId": "3709",
    "nftId": 247,
    "creationDate": "1691119471088564",
    "id": "247",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "11",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #248",
    "zoneId": "7",
    "metaId": "4408",
    "nftId": 248,
    "creationDate": "1691119499039682",
    "id": "248",
    "mintedVia": "CROWN",
  },
  {
    "x": "14",
    "y": "19",
    "owner": "hxba63e3071174621aa182f396d5ce239c37674857",
    "name": "Emerald City Land #249",
    "zoneId": "7",
    "metaId": "1829",
    "nftId": 249,
    "creationDate": "1691119509050476",
    "id": "249",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "-12",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #250",
    "zoneId": "7",
    "metaId": "4625",
    "nftId": 250,
    "creationDate": "1691119509050476",
    "id": "250",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "21",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #251",
    "zoneId": "7",
    "metaId": "3449",
    "nftId": 251,
    "creationDate": "1691119509050476",
    "id": "251",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "1",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #252",
    "zoneId": "7",
    "metaId": "2218",
    "nftId": 252,
    "creationDate": "1691119509050476",
    "id": "252",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "7",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #253",
    "zoneId": "7",
    "metaId": "4054",
    "nftId": 253,
    "creationDate": "1691119509050476",
    "id": "253",
    "mintedVia": "CROWN",
  },
  {
    "x": "15",
    "y": "19",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #254",
    "zoneId": "7",
    "metaId": "1939",
    "nftId": 254,
    "creationDate": "1691119509050476",
    "id": "254",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "17",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #255",
    "zoneId": "7",
    "metaId": "4394",
    "nftId": 255,
    "creationDate": "1691119524999849",
    "id": "255",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "28",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #256",
    "zoneId": "8",
    "metaId": "4981",
    "nftId": 256,
    "creationDate": "1691119533036988",
    "id": "256",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "5",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #257",
    "zoneId": "4",
    "metaId": "1513",
    "nftId": 257,
    "creationDate": "1691119551001262",
    "id": "257",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "10",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #258",
    "zoneId": "7",
    "metaId": "4310",
    "nftId": 258,
    "creationDate": "1691119561040282",
    "id": "258",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "22",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #259",
    "zoneId": "7",
    "metaId": "4014",
    "nftId": 259,
    "creationDate": "1691119561040282",
    "id": "259",
    "mintedVia": "CROWN",
  },
  {
    "x": "30",
    "y": "23",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #260",
    "zoneId": "7",
    "metaId": "1993",
    "nftId": 260,
    "creationDate": "1691119561040282",
    "id": "260",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "28",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #261",
    "zoneId": "7",
    "metaId": "4494",
    "nftId": 261,
    "creationDate": "1691119561040282",
    "id": "261",
    "mintedVia": "CROWN",
  },
  {
    "x": "-96",
    "y": "-11",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #262",
    "zoneId": "7",
    "metaId": "2382",
    "nftId": 262,
    "creationDate": "1691119561040282",
    "id": "262",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "29",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #263",
    "zoneId": "6",
    "metaId": "1684",
    "nftId": 263,
    "creationDate": "1691119563193824",
    "id": "263",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "3",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #264",
    "zoneId": "8",
    "metaId": "6462",
    "nftId": 264,
    "creationDate": "1691119571120450",
    "id": "264",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "1",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #265",
    "zoneId": "4",
    "metaId": "1505",
    "nftId": 265,
    "creationDate": "1691119571120450",
    "id": "265",
    "mintedVia": "CROWN",
  },
  {
    "x": "11",
    "y": "8",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #266",
    "zoneId": "6",
    "metaId": "1761",
    "nftId": 266,
    "creationDate": "1691119601020881",
    "id": "266",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "-7",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #267",
    "zoneId": "8",
    "metaId": "5641",
    "nftId": 267,
    "creationDate": "1691119617082660",
    "id": "267",
    "mintedVia": "CROWN",
  },
  {
    "x": "-88",
    "y": "7",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #268",
    "zoneId": "8",
    "metaId": "6391",
    "nftId": 268,
    "creationDate": "1691119617082660",
    "id": "268",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "6",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #269",
    "zoneId": "8",
    "metaId": "6708",
    "nftId": 269,
    "creationDate": "1691119617082660",
    "id": "269",
    "mintedVia": "CROWN",
  },
  {
    "x": "39",
    "y": "-10",
    "owner": "hx9a8b1bd320fb56bccd5e70865bfe5410e24548bb",
    "name": "Emerald City Land #270",
    "zoneId": "4",
    "metaId": "1300",
    "nftId": 270,
    "creationDate": "1691119621126394",
    "id": "270",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "-3",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #271",
    "zoneId": "7",
    "metaId": "3113",
    "nftId": 271,
    "creationDate": "1691119621126394",
    "id": "271",
    "mintedVia": "CROWN",
  },
  {
    "x": "41",
    "y": "-2",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #272",
    "zoneId": "7",
    "metaId": "2615",
    "nftId": 272,
    "creationDate": "1691119621126394",
    "id": "272",
    "mintedVia": "CROWN",
  },
  {
    "x": "-2",
    "y": "-5",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #273",
    "zoneId": "7",
    "metaId": "3015",
    "nftId": 273,
    "creationDate": "1691119621126394",
    "id": "273",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "-10",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #274",
    "zoneId": "7",
    "metaId": "2524",
    "nftId": 274,
    "creationDate": "1691119621126394",
    "id": "274",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "15",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #275",
    "zoneId": "7",
    "metaId": "4484",
    "nftId": 275,
    "creationDate": "1691119621126394",
    "id": "275",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "-33",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #276",
    "zoneId": "2",
    "metaId": "787",
    "nftId": 276,
    "creationDate": "1691119661069089",
    "id": "276",
    "mintedVia": "CROWN",
  },
  {
    "x": "-46",
    "y": "-22",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #277",
    "zoneId": "2",
    "metaId": "812",
    "nftId": 277,
    "creationDate": "1691119661069089",
    "id": "277",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "-22",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #278",
    "zoneId": "2",
    "metaId": "815",
    "nftId": 278,
    "creationDate": "1691119661069089",
    "id": "278",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "-22",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #279",
    "zoneId": "2",
    "metaId": "823",
    "nftId": 279,
    "creationDate": "1691119661069089",
    "id": "279",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "28",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #280",
    "zoneId": "7",
    "metaId": "3609",
    "nftId": 280,
    "creationDate": "1691119699088679",
    "id": "280",
    "mintedVia": "CROWN",
  },
  {
    "x": "-10",
    "y": "-3",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #281",
    "zoneId": "7",
    "metaId": "2844",
    "nftId": 281,
    "creationDate": "1691119699088679",
    "id": "281",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "20",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #282",
    "zoneId": "7",
    "metaId": "2114",
    "nftId": 282,
    "creationDate": "1691119699088679",
    "id": "282",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "27",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #283",
    "zoneId": "7",
    "metaId": "3762",
    "nftId": 283,
    "creationDate": "1691119703056362",
    "id": "283",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "15",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #284",
    "zoneId": "7",
    "metaId": "4392",
    "nftId": 284,
    "creationDate": "1691119703056362",
    "id": "284",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "12",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #285",
    "zoneId": "6",
    "metaId": "1671",
    "nftId": 285,
    "creationDate": "1691119705273343",
    "id": "285",
    "mintedVia": "CROWN",
  },
  {
    "x": "23",
    "y": "8",
    "owner": "hx5bb64f02aaa2ed52ade1e37cce844d9d2724da61",
    "name": "Emerald City Land #286",
    "zoneId": "1",
    "metaId": "446",
    "nftId": 286,
    "creationDate": "1691119721151258",
    "id": "286",
    "mintedVia": "CROWN",
  },
  {
    "x": "84",
    "y": "13",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #287",
    "zoneId": "4",
    "metaId": "1252",
    "nftId": 287,
    "creationDate": "1691119737242674",
    "id": "287",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "13",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #288",
    "zoneId": "4",
    "metaId": "1373",
    "nftId": 288,
    "creationDate": "1691119737242674",
    "id": "288",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "-18",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #289",
    "zoneId": "7",
    "metaId": "4602",
    "nftId": 289,
    "creationDate": "1691119759133945",
    "id": "289",
    "mintedVia": "CROWN",
  },
  {
    "x": "27",
    "y": "25",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #290",
    "zoneId": "7",
    "metaId": "1834",
    "nftId": 290,
    "creationDate": "1691119759133945",
    "id": "290",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "1",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #291",
    "zoneId": "7",
    "metaId": "2757",
    "nftId": 291,
    "creationDate": "1691119759133945",
    "id": "291",
    "mintedVia": "CROWN",
  },
  {
    "x": "12",
    "y": "17",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #292",
    "zoneId": "7",
    "metaId": "1848",
    "nftId": 292,
    "creationDate": "1691119763250902",
    "id": "292",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "-5",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #293",
    "zoneId": "7",
    "metaId": "2215",
    "nftId": 293,
    "creationDate": "1691119763250902",
    "id": "293",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "18",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #294",
    "zoneId": "7",
    "metaId": "1906",
    "nftId": 294,
    "creationDate": "1691119763250902",
    "id": "294",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "-5",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #295",
    "zoneId": "7",
    "metaId": "2179",
    "nftId": 295,
    "creationDate": "1691119763250902",
    "id": "295",
    "mintedVia": "CROWN",
  },
  {
    "x": "-11",
    "y": "30",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #296",
    "zoneId": "7",
    "metaId": "3407",
    "nftId": 296,
    "creationDate": "1691119763250902",
    "id": "296",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "31",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #297",
    "zoneId": "3",
    "metaId": "1054",
    "nftId": 297,
    "creationDate": "1691119779109132",
    "id": "297",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "25",
    "owner": "hx9a8b1bd320fb56bccd5e70865bfe5410e24548bb",
    "name": "Emerald City Land #298",
    "zoneId": "8",
    "metaId": "5345",
    "nftId": 298,
    "creationDate": "1691119801088541",
    "id": "298",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "7",
    "owner": "hx9a8b1bd320fb56bccd5e70865bfe5410e24548bb",
    "name": "Emerald City Land #299",
    "zoneId": "8",
    "metaId": "6723",
    "nftId": 299,
    "creationDate": "1691119801088541",
    "id": "299",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "26",
    "owner": "hx9a8b1bd320fb56bccd5e70865bfe5410e24548bb",
    "name": "Emerald City Land #300",
    "zoneId": "8",
    "metaId": "5385",
    "nftId": 300,
    "creationDate": "1691119801088541",
    "id": "300",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "5",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #301",
    "zoneId": "3",
    "metaId": "1102",
    "nftId": 301,
    "creationDate": "1691119807271585",
    "id": "301",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "23",
    "owner": "hx252425752a05fda66cd8dc52309b635d8e3e169f",
    "name": "Emerald City Land #302",
    "zoneId": "8",
    "metaId": "4807",
    "nftId": 302,
    "creationDate": "1691119811118021",
    "id": "302",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "3",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #303",
    "zoneId": "1",
    "metaId": "362",
    "nftId": 303,
    "creationDate": "1691119813270919",
    "id": "303",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "-13",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #304",
    "zoneId": "1",
    "metaId": "46",
    "nftId": 304,
    "creationDate": "1691119813270919",
    "id": "304",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "-13",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #305",
    "zoneId": "1",
    "metaId": "34",
    "nftId": 305,
    "creationDate": "1691119813270919",
    "id": "305",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-8",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #306",
    "zoneId": "1",
    "metaId": "323",
    "nftId": 306,
    "creationDate": "1691119813270919",
    "id": "306",
    "mintedVia": "CROWN",
  },
  {
    "x": "-98",
    "y": "-23",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #307",
    "zoneId": "1",
    "metaId": "198",
    "nftId": 307,
    "creationDate": "1691119813270919",
    "id": "307",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-23",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #308",
    "zoneId": "2",
    "metaId": "737",
    "nftId": 308,
    "creationDate": "1691119853102083",
    "id": "308",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "6",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #309",
    "zoneId": "5",
    "metaId": "1608",
    "nftId": 309,
    "creationDate": "1691119855326384",
    "id": "309",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "-15",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #310",
    "zoneId": "5",
    "metaId": "1570",
    "nftId": 310,
    "creationDate": "1691119855326384",
    "id": "310",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "-8",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #311",
    "zoneId": "5",
    "metaId": "1583",
    "nftId": 311,
    "creationDate": "1691119855326384",
    "id": "311",
    "mintedVia": "CROWN",
  },
  {
    "x": "-10",
    "y": "18",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #312",
    "zoneId": "5",
    "metaId": "1619",
    "nftId": 312,
    "creationDate": "1691119855326384",
    "id": "312",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "-9",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #313",
    "zoneId": "5",
    "metaId": "1604",
    "nftId": 313,
    "creationDate": "1691119855326384",
    "id": "313",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "-10",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #314",
    "zoneId": "1",
    "metaId": "351",
    "nftId": 314,
    "creationDate": "1691119861134595",
    "id": "314",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "0",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #315",
    "zoneId": "1",
    "metaId": "411",
    "nftId": 315,
    "creationDate": "1691119861134595",
    "id": "315",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "1",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #316",
    "zoneId": "1",
    "metaId": "240",
    "nftId": 316,
    "creationDate": "1691119861134595",
    "id": "316",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-5",
    "owner": "hx883414436da0b60d18373d19768db9b811b0439a",
    "name": "Emerald City Land #317",
    "zoneId": "1",
    "metaId": "320",
    "nftId": 317,
    "creationDate": "1691119861134595",
    "id": "317",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "3",
    "owner": "hx252425752a05fda66cd8dc52309b635d8e3e169f",
    "name": "Emerald City Land #318",
    "zoneId": "7",
    "metaId": "2669",
    "nftId": 318,
    "creationDate": "1691119875112063",
    "id": "318",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "12",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #319",
    "zoneId": "8",
    "metaId": "6404",
    "nftId": 319,
    "creationDate": "1691119891175043",
    "id": "319",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "34",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #320",
    "zoneId": "8",
    "metaId": "6653",
    "nftId": 320,
    "creationDate": "1691119891175043",
    "id": "320",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "-17",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #321",
    "zoneId": "8",
    "metaId": "5671",
    "nftId": 321,
    "creationDate": "1691119891175043",
    "id": "321",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "24",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #322",
    "zoneId": "8",
    "metaId": "4762",
    "nftId": 322,
    "creationDate": "1691119891175043",
    "id": "322",
    "mintedVia": "CROWN",
  },
  {
    "x": "12",
    "y": "29",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #323",
    "zoneId": "8",
    "metaId": "4857",
    "nftId": 323,
    "creationDate": "1691119891175043",
    "id": "323",
    "mintedVia": "CROWN",
  },
  {
    "x": "-104",
    "y": "-2",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #324",
    "zoneId": "5",
    "metaId": "1572",
    "nftId": 324,
    "creationDate": "1691119891175043",
    "id": "324",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "-4",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #325",
    "zoneId": "4",
    "metaId": "1315",
    "nftId": 325,
    "creationDate": "1691119923183426",
    "id": "325",
    "mintedVia": "CROWN",
  },
  {
    "x": "-18",
    "y": "30",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #326",
    "zoneId": "4",
    "metaId": "1447",
    "nftId": 326,
    "creationDate": "1691119923183426",
    "id": "326",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "-5",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #327",
    "zoneId": "4",
    "metaId": "1320",
    "nftId": 327,
    "creationDate": "1691119923183426",
    "id": "327",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "1",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #328",
    "zoneId": "8",
    "metaId": "5864",
    "nftId": 328,
    "creationDate": "1691119937304055",
    "id": "328",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "18",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #329",
    "zoneId": "8",
    "metaId": "5528",
    "nftId": 329,
    "creationDate": "1691119937304055",
    "id": "329",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "-5",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #330",
    "zoneId": "8",
    "metaId": "6477",
    "nftId": 330,
    "creationDate": "1691119937304055",
    "id": "330",
    "mintedVia": "CROWN",
  },
  {
    "x": "-100",
    "y": "0",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #331",
    "zoneId": "8",
    "metaId": "6091",
    "nftId": 331,
    "creationDate": "1691119937304055",
    "id": "331",
    "mintedVia": "CROWN",
  },
  {
    "x": "-102",
    "y": "6",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #332",
    "zoneId": "8",
    "metaId": "6132",
    "nftId": 332,
    "creationDate": "1691119937304055",
    "id": "332",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "13",
    "owner": "hx252425752a05fda66cd8dc52309b635d8e3e169f",
    "name": "Emerald City Land #333",
    "zoneId": "1",
    "metaId": "582",
    "nftId": 333,
    "creationDate": "1691119941192415",
    "id": "333",
    "mintedVia": "CROWN",
  },
  {
    "x": "41",
    "y": "1",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #334",
    "zoneId": "4",
    "metaId": "1309",
    "nftId": 334,
    "creationDate": "1691119947145568",
    "id": "334",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "-2",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #335",
    "zoneId": "4",
    "metaId": "1504",
    "nftId": 335,
    "creationDate": "1691119947145568",
    "id": "335",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "27",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #336",
    "zoneId": "4",
    "metaId": "1438",
    "nftId": 336,
    "creationDate": "1691119947145568",
    "id": "336",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "-4",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #337",
    "zoneId": "6",
    "metaId": "1737",
    "nftId": 337,
    "creationDate": "1691119987325787",
    "id": "337",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "26",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #338",
    "zoneId": "6",
    "metaId": "1773",
    "nftId": 338,
    "creationDate": "1691119987325787",
    "id": "338",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "22",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #339",
    "zoneId": "8",
    "metaId": "5910",
    "nftId": 339,
    "creationDate": "1691119991209631",
    "id": "339",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "18",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #340",
    "zoneId": "8",
    "metaId": "5105",
    "nftId": 340,
    "creationDate": "1691119991209631",
    "id": "340",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "-19",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #341",
    "zoneId": "8",
    "metaId": "5401",
    "nftId": 341,
    "creationDate": "1691119991209631",
    "id": "341",
    "mintedVia": "CROWN",
  },
  {
    "x": "-102",
    "y": "-5",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #342",
    "zoneId": "8",
    "metaId": "6121",
    "nftId": 342,
    "creationDate": "1691119991209631",
    "id": "342",
    "mintedVia": "CROWN",
  },
  {
    "x": "-73",
    "y": "14",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #343",
    "zoneId": "8",
    "metaId": "6251",
    "nftId": 343,
    "creationDate": "1691119991209631",
    "id": "343",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "29",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #344",
    "zoneId": "6",
    "metaId": "1806",
    "nftId": 344,
    "creationDate": "1691120001161979",
    "id": "344",
    "mintedVia": "CROWN",
  },
  {
    "x": "84",
    "y": "-1",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #345",
    "zoneId": "6",
    "metaId": "1711",
    "nftId": 345,
    "creationDate": "1691120001161979",
    "id": "345",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "1",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #346",
    "zoneId": "6",
    "metaId": "1746",
    "nftId": 346,
    "creationDate": "1691120001161979",
    "id": "346",
    "mintedVia": "CROWN",
  },
  {
    "x": "-83",
    "y": "10",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #347",
    "zoneId": "8",
    "metaId": "6345",
    "nftId": 347,
    "creationDate": "1691120037325011",
    "id": "347",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "5",
    "owner": "hx2998570cefa5fca24241f7f7e3906f63923fef8f",
    "name": "Emerald City Land #348",
    "zoneId": "8",
    "metaId": "5700",
    "nftId": 348,
    "creationDate": "1691120037325011",
    "id": "348",
    "mintedVia": "CROWN",
  },
  {
    "x": "-19",
    "y": "11",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #349",
    "zoneId": "3",
    "metaId": "986",
    "nftId": 349,
    "creationDate": "1691120051185746",
    "id": "349",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "18",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #350",
    "zoneId": "3",
    "metaId": "1094",
    "nftId": 350,
    "creationDate": "1691120051185746",
    "id": "350",
    "mintedVia": "CROWN",
  },
  {
    "x": "30",
    "y": "2",
    "owner": "hx5bb64f02aaa2ed52ade1e37cce844d9d2724da61",
    "name": "Emerald City Land #351",
    "zoneId": "7",
    "metaId": "2424",
    "nftId": 351,
    "creationDate": "1691120059226333",
    "id": "351",
    "mintedVia": "CROWN",
  },
  {
    "x": "1",
    "y": "31",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #352",
    "zoneId": "3",
    "metaId": "1036",
    "nftId": 352,
    "creationDate": "1691120067219797",
    "id": "352",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "19",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #353",
    "zoneId": "3",
    "metaId": "1101",
    "nftId": 353,
    "creationDate": "1691120067219797",
    "id": "353",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "28",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #354",
    "zoneId": "3",
    "metaId": "961",
    "nftId": 354,
    "creationDate": "1691120067219797",
    "id": "354",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "18",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #355",
    "zoneId": "3",
    "metaId": "1104",
    "nftId": 355,
    "creationDate": "1691120067219797",
    "id": "355",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "26",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #356",
    "zoneId": "3",
    "metaId": "1072",
    "nftId": 356,
    "creationDate": "1691120067219797",
    "id": "356",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "7",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #357",
    "zoneId": "7",
    "metaId": "4438",
    "nftId": 357,
    "creationDate": "1691120093249989",
    "id": "357",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "1",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #358",
    "zoneId": "1",
    "metaId": "376",
    "nftId": 358,
    "creationDate": "1691120105403854",
    "id": "358",
    "mintedVia": "CROWN",
  },
  {
    "x": "-101",
    "y": "-18",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #359",
    "zoneId": "1",
    "metaId": "111",
    "nftId": 359,
    "creationDate": "1691120105403854",
    "id": "359",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "-10",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #360",
    "zoneId": "1",
    "metaId": "264",
    "nftId": 360,
    "creationDate": "1691120105403854",
    "id": "360",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "-20",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #361",
    "zoneId": "1",
    "metaId": "649",
    "nftId": 361,
    "creationDate": "1691120105403854",
    "id": "361",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "23",
    "owner": "hx5bb64f02aaa2ed52ade1e37cce844d9d2724da61",
    "name": "Emerald City Land #362",
    "zoneId": "8",
    "metaId": "5456",
    "nftId": 362,
    "creationDate": "1691120117235998",
    "id": "362",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "-4",
    "owner": "hx62800b755fb716c58d3d73cda7b25580f30bb704",
    "name": "Emerald City Land #363",
    "zoneId": "8",
    "metaId": "5712",
    "nftId": 363,
    "creationDate": "1691120125197404",
    "id": "363",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-10",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #364",
    "zoneId": "1",
    "metaId": "300",
    "nftId": 364,
    "creationDate": "1691120155420127",
    "id": "364",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "15",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #365",
    "zoneId": "1",
    "metaId": "60",
    "nftId": 365,
    "creationDate": "1691120155420127",
    "id": "365",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "11",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #366",
    "zoneId": "1",
    "metaId": "93",
    "nftId": 366,
    "creationDate": "1691120155420127",
    "id": "366",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "-21",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #367",
    "zoneId": "2",
    "metaId": "858",
    "nftId": 367,
    "creationDate": "1691120167252066",
    "id": "367",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "-29",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #368",
    "zoneId": "2",
    "metaId": "859",
    "nftId": 368,
    "creationDate": "1691120167252066",
    "id": "368",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "1",
    "owner": "hx5bb64f02aaa2ed52ade1e37cce844d9d2724da61",
    "name": "Emerald City Land #369",
    "zoneId": "4",
    "metaId": "1382",
    "nftId": 369,
    "creationDate": "1691120167252066",
    "id": "369",
    "mintedVia": "CROWN",
  },
  {
    "x": "-20",
    "y": "29",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #370",
    "zoneId": "7",
    "metaId": "3579",
    "nftId": 370,
    "creationDate": "1691120207386590",
    "id": "370",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "-21",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #371",
    "zoneId": "2",
    "metaId": "751",
    "nftId": 371,
    "creationDate": "1691120229245150",
    "id": "371",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "-6",
    "owner": "hx5bb64f02aaa2ed52ade1e37cce844d9d2724da61",
    "name": "Emerald City Land #372",
    "zoneId": "8",
    "metaId": "6833",
    "nftId": 372,
    "creationDate": "1691120233247707",
    "id": "372",
    "mintedVia": "CROWN",
  },
  {
    "x": "-23",
    "y": "28",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #373",
    "zoneId": "5",
    "metaId": "1627",
    "nftId": 373,
    "creationDate": "1691120243302491",
    "id": "373",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "18",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #374",
    "zoneId": "3",
    "metaId": "1098",
    "nftId": 374,
    "creationDate": "1691120295267373",
    "id": "374",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "16",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #375",
    "zoneId": "3",
    "metaId": "1060",
    "nftId": 375,
    "creationDate": "1691120295267373",
    "id": "375",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "-7",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #376",
    "zoneId": "3",
    "metaId": "975",
    "nftId": 376,
    "creationDate": "1691120295267373",
    "id": "376",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-30",
    "owner": "hxc09bc9c068cb31adadb69c071ffce2e8d9930200",
    "name": "Emerald City Land #377",
    "zoneId": "2",
    "metaId": "749",
    "nftId": 377,
    "creationDate": "1691120303241760",
    "id": "377",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "14",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #378",
    "zoneId": "5",
    "metaId": "1545",
    "nftId": 378,
    "creationDate": "1691120305466550",
    "id": "378",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "9",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #379",
    "zoneId": "8",
    "metaId": "5629",
    "nftId": 379,
    "creationDate": "1691120341314293",
    "id": "379",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "-34",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #380",
    "zoneId": "2",
    "metaId": "865",
    "nftId": 380,
    "creationDate": "1691120389280984",
    "id": "380",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-20",
    "owner": "hxa10445db0a8bf2fbd80111248f42d82fe4e39319",
    "name": "Emerald City Land #381",
    "zoneId": "2",
    "metaId": "742",
    "nftId": 381,
    "creationDate": "1691120397282014",
    "id": "381",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "-33",
    "owner": "hxa10445db0a8bf2fbd80111248f42d82fe4e39319",
    "name": "Emerald City Land #382",
    "zoneId": "2",
    "metaId": "873",
    "nftId": 382,
    "creationDate": "1691120397282014",
    "id": "382",
    "mintedVia": "CROWN",
  },
  {
    "x": "10",
    "y": "12",
    "owner": "hxa959ed84fd6aae362256eaef8b5d1662473f7022",
    "name": "Emerald City Land #383",
    "zoneId": "8",
    "metaId": "4666",
    "nftId": 383,
    "creationDate": "1691120403276293",
    "id": "383",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "-11",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #384",
    "zoneId": "4",
    "metaId": "1235",
    "nftId": 384,
    "creationDate": "1691120423339456",
    "id": "384",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "11",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #385",
    "zoneId": "6",
    "metaId": "1678",
    "nftId": 385,
    "creationDate": "1691120443365353",
    "id": "385",
    "mintedVia": "CROWN",
  },
  {
    "x": "-91",
    "y": "-7",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #386",
    "zoneId": "7",
    "metaId": "2335",
    "nftId": 386,
    "creationDate": "1691120451354478",
    "id": "386",
    "mintedVia": "CROWN",
  },
  {
    "x": "-4",
    "y": "-3",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #387",
    "zoneId": "7",
    "metaId": "3041",
    "nftId": 387,
    "creationDate": "1691120451354478",
    "id": "387",
    "mintedVia": "CROWN",
  },
  {
    "x": "-98",
    "y": "-8",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #388",
    "zoneId": "7",
    "metaId": "2404",
    "nftId": 388,
    "creationDate": "1691120451354478",
    "id": "388",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "14",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #389",
    "zoneId": "7",
    "metaId": "4366",
    "nftId": 389,
    "creationDate": "1691120451354478",
    "id": "389",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "5",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #390",
    "zoneId": "7",
    "metaId": "3219",
    "nftId": 390,
    "creationDate": "1691120451354478",
    "id": "390",
    "mintedVia": "CROWN",
  },
  {
    "x": "11",
    "y": "-1",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #391",
    "zoneId": "7",
    "metaId": "3169",
    "nftId": 391,
    "creationDate": "1691120463500855",
    "id": "391",
    "mintedVia": "CROWN",
  },
  {
    "x": "41",
    "y": "8",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #392",
    "zoneId": "7",
    "metaId": "2627",
    "nftId": 392,
    "creationDate": "1691120463500855",
    "id": "392",
    "mintedVia": "CROWN",
  },
  {
    "x": "54",
    "y": "9",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #393",
    "zoneId": "7",
    "metaId": "2782",
    "nftId": 393,
    "creationDate": "1691120463500855",
    "id": "393",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "-21",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #394",
    "zoneId": "7",
    "metaId": "4533",
    "nftId": 394,
    "creationDate": "1691120463500855",
    "id": "394",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "-18",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #395",
    "zoneId": "7",
    "metaId": "2514",
    "nftId": 395,
    "creationDate": "1691120463500855",
    "id": "395",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "13",
    "owner": "hxa10445db0a8bf2fbd80111248f42d82fe4e39319",
    "name": "Emerald City Land #396",
    "zoneId": "4",
    "metaId": "1476",
    "nftId": 396,
    "creationDate": "1691120465483845",
    "id": "396",
    "mintedVia": "CROWN",
  },
  {
    "x": "-2",
    "y": "19",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #397",
    "zoneId": "7",
    "metaId": "3721",
    "nftId": 397,
    "creationDate": "1691120511337570",
    "id": "397",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "-3",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #398",
    "zoneId": "7",
    "metaId": "3025",
    "nftId": 398,
    "creationDate": "1691120511337570",
    "id": "398",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "29",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #399",
    "zoneId": "7",
    "metaId": "1836",
    "nftId": 399,
    "creationDate": "1691120511337570",
    "id": "399",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "-2",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #400",
    "zoneId": "7",
    "metaId": "2231",
    "nftId": 400,
    "creationDate": "1691120511337570",
    "id": "400",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "22",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #401",
    "zoneId": "7",
    "metaId": "3555",
    "nftId": 401,
    "creationDate": "1691120511337570",
    "id": "401",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "-23",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #402",
    "zoneId": "2",
    "metaId": "819",
    "nftId": 402,
    "creationDate": "1691120527337278",
    "id": "402",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "-23",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #403",
    "zoneId": "2",
    "metaId": "792",
    "nftId": 403,
    "creationDate": "1691120527337278",
    "id": "403",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "-36",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #404",
    "zoneId": "2",
    "metaId": "851",
    "nftId": 404,
    "creationDate": "1691120527337278",
    "id": "404",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "2",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #405",
    "zoneId": "1",
    "metaId": "327",
    "nftId": 405,
    "creationDate": "1691120579351434",
    "id": "405",
    "mintedVia": "CROWN",
  },
  {
    "x": "23",
    "y": "0",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #406",
    "zoneId": "1",
    "metaId": "439",
    "nftId": 406,
    "creationDate": "1691120579351434",
    "id": "406",
    "mintedVia": "CROWN",
  },
  {
    "x": "-21",
    "y": "25",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #407",
    "zoneId": "1",
    "metaId": "557",
    "nftId": 407,
    "creationDate": "1691120579351434",
    "id": "407",
    "mintedVia": "CROWN",
  },
  {
    "x": "27",
    "y": "-8",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #408",
    "zoneId": "1",
    "metaId": "503",
    "nftId": 408,
    "creationDate": "1691120579351434",
    "id": "408",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "14",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #409",
    "zoneId": "5",
    "metaId": "1656",
    "nftId": 409,
    "creationDate": "1691120597351776",
    "id": "409",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "-11",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #410",
    "zoneId": "4",
    "metaId": "1274",
    "nftId": 410,
    "creationDate": "1691120601412708",
    "id": "410",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "0",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #411",
    "zoneId": "7",
    "metaId": "2742",
    "nftId": 411,
    "creationDate": "1691120635530855",
    "id": "411",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "-1",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #412",
    "zoneId": "7",
    "metaId": "2629",
    "nftId": 412,
    "creationDate": "1691120635530855",
    "id": "412",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "4",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #413",
    "zoneId": "7",
    "metaId": "2539",
    "nftId": 413,
    "creationDate": "1691120635530855",
    "id": "413",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "7",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #414",
    "zoneId": "7",
    "metaId": "3457",
    "nftId": 414,
    "creationDate": "1691120635530855",
    "id": "414",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "-4",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #415",
    "zoneId": "7",
    "metaId": "2716",
    "nftId": 415,
    "creationDate": "1691120635530855",
    "id": "415",
    "mintedVia": "CROWN",
  },
  {
    "x": "-18",
    "y": "17",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #416",
    "zoneId": "7",
    "metaId": "3515",
    "nftId": 416,
    "creationDate": "1691120637526245",
    "id": "416",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "11",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #417",
    "zoneId": "7",
    "metaId": "4272",
    "nftId": 417,
    "creationDate": "1691120637526245",
    "id": "417",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "-2",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #418",
    "zoneId": "7",
    "metaId": "2920",
    "nftId": 418,
    "creationDate": "1691120637526245",
    "id": "418",
    "mintedVia": "CROWN",
  },
  {
    "x": "40",
    "y": "8",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #419",
    "zoneId": "7",
    "metaId": "2613",
    "nftId": 419,
    "creationDate": "1691120637526245",
    "id": "419",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "-23",
    "owner": "hxa10445db0a8bf2fbd80111248f42d82fe4e39319",
    "name": "Emerald City Land #420",
    "zoneId": "2",
    "metaId": "771",
    "nftId": 420,
    "creationDate": "1691120643420198",
    "id": "420",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "17",
    "owner": "hx2fcbfa16d02624ce4f10b5fc14ebd6e0118825da",
    "name": "Emerald City Land #421",
    "zoneId": "7",
    "metaId": "4115",
    "nftId": 421,
    "creationDate": "1691120647365829",
    "id": "421",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "7",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #422",
    "zoneId": "7",
    "metaId": "4374",
    "nftId": 422,
    "creationDate": "1691120683394826",
    "id": "422",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "2",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #423",
    "zoneId": "8",
    "metaId": "6796",
    "nftId": 423,
    "creationDate": "1691120685547028",
    "id": "423",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "-14",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #424",
    "zoneId": "8",
    "metaId": "5276",
    "nftId": 424,
    "creationDate": "1691120685547028",
    "id": "424",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "-12",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #425",
    "zoneId": "8",
    "metaId": "6894",
    "nftId": 425,
    "creationDate": "1691120685547028",
    "id": "425",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "-14",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #426",
    "zoneId": "8",
    "metaId": "6841",
    "nftId": 426,
    "creationDate": "1691120685547028",
    "id": "426",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "18",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #427",
    "zoneId": "4",
    "metaId": "1164",
    "nftId": 427,
    "creationDate": "1691120687541556",
    "id": "427",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "-1",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #428",
    "zoneId": "4",
    "metaId": "1327",
    "nftId": 428,
    "creationDate": "1691120687541556",
    "id": "428",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "-10",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #429",
    "zoneId": "4",
    "metaId": "1248",
    "nftId": 429,
    "creationDate": "1691120695388203",
    "id": "429",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "25",
    "owner": "hxa10445db0a8bf2fbd80111248f42d82fe4e39319",
    "name": "Emerald City Land #430",
    "zoneId": "7",
    "metaId": "4045",
    "nftId": 430,
    "creationDate": "1691120713557465",
    "id": "430",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "-7",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #431",
    "zoneId": "8",
    "metaId": "6021",
    "nftId": 431,
    "creationDate": "1691120717425542",
    "id": "431",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "5",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #432",
    "zoneId": "6",
    "metaId": "1747",
    "nftId": 432,
    "creationDate": "1691120719565963",
    "id": "432",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "24",
    "owner": "hxa10445db0a8bf2fbd80111248f42d82fe4e39319",
    "name": "Emerald City Land #433",
    "zoneId": "5",
    "metaId": "1539",
    "nftId": 433,
    "creationDate": "1691120745398941",
    "id": "433",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "21",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #434",
    "zoneId": "4",
    "metaId": "1233",
    "nftId": 434,
    "creationDate": "1691120745398941",
    "id": "434",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "-13",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #435",
    "zoneId": "4",
    "metaId": "1525",
    "nftId": 435,
    "creationDate": "1691120745398941",
    "id": "435",
    "mintedVia": "CROWN",
  },
  {
    "x": "27",
    "y": "5",
    "owner": "hxd66d85bab702719a0192686960c6bb1bcb25e8ca",
    "name": "Emerald City Land #436",
    "zoneId": "1",
    "metaId": "508",
    "nftId": 436,
    "creationDate": "1691120757549834",
    "id": "436",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "4",
    "owner": "hx6cdf7757e937903dc01af9603ad664ddc3fa6a89",
    "name": "Emerald City Land #437",
    "zoneId": "7",
    "metaId": "4127",
    "nftId": 437,
    "creationDate": "1691120759441555",
    "id": "437",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "19",
    "owner": "hxa10445db0a8bf2fbd80111248f42d82fe4e39319",
    "name": "Emerald City Land #438",
    "zoneId": "4",
    "metaId": "1212",
    "nftId": 438,
    "creationDate": "1691120777411767",
    "id": "438",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "18",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #439",
    "zoneId": "3",
    "metaId": "1108",
    "nftId": 439,
    "creationDate": "1691120777411767",
    "id": "439",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "34",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #440",
    "zoneId": "3",
    "metaId": "999",
    "nftId": 440,
    "creationDate": "1691120777411767",
    "id": "440",
    "mintedVia": "CROWN",
  },
  {
    "x": "-107",
    "y": "-8",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #441",
    "zoneId": "3",
    "metaId": "964",
    "nftId": 441,
    "creationDate": "1691120777411767",
    "id": "441",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "5",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #442",
    "zoneId": "1",
    "metaId": "525",
    "nftId": 442,
    "creationDate": "1691120777411767",
    "id": "442",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "-10",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #443",
    "zoneId": "1",
    "metaId": "211",
    "nftId": 443,
    "creationDate": "1691120777411767",
    "id": "443",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "-4",
    "owner": "hx8c2b02e698f093f4f2064f03ee87ca28c20ba1f0",
    "name": "Emerald City Land #444",
    "zoneId": "1",
    "metaId": "235",
    "nftId": 444,
    "creationDate": "1691120777411767",
    "id": "444",
    "mintedVia": "CROWN",
  },
  {
    "x": "-102",
    "y": "5",
    "owner": "hx157fa170289c22021e5a463e0f37ab9097859cc6",
    "name": "Emerald City Land #445",
    "zoneId": "8",
    "metaId": "6131",
    "nftId": 445,
    "creationDate": "1691120783429202",
    "id": "445",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "-1",
    "owner": "hxa10445db0a8bf2fbd80111248f42d82fe4e39319",
    "name": "Emerald City Land #446",
    "zoneId": "6",
    "metaId": "1810",
    "nftId": 446,
    "creationDate": "1691120829435633",
    "id": "446",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "22",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #447",
    "zoneId": "1",
    "metaId": "27",
    "nftId": 447,
    "creationDate": "1691120833448462",
    "id": "447",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "7",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #448",
    "zoneId": "1",
    "metaId": "496",
    "nftId": 448,
    "creationDate": "1691120833448462",
    "id": "448",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "-20",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #449",
    "zoneId": "1",
    "metaId": "625",
    "nftId": 449,
    "creationDate": "1691120833448462",
    "id": "449",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "34",
    "owner": "hxa10445db0a8bf2fbd80111248f42d82fe4e39319",
    "name": "Emerald City Land #450",
    "zoneId": "3",
    "metaId": "989",
    "nftId": 450,
    "creationDate": "1691120879453422",
    "id": "450",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "-22",
    "owner": "hx157fa170289c22021e5a463e0f37ab9097859cc6",
    "name": "Emerald City Land #451",
    "zoneId": "2",
    "metaId": "810",
    "nftId": 451,
    "creationDate": "1691120889438900",
    "id": "451",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "-5",
    "owner": "hxa10445db0a8bf2fbd80111248f42d82fe4e39319",
    "name": "Emerald City Land #452",
    "zoneId": "8",
    "metaId": "5442",
    "nftId": 452,
    "creationDate": "1691120903442792",
    "id": "452",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "7",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #453",
    "zoneId": "8",
    "metaId": "5353",
    "nftId": 453,
    "creationDate": "1691120915630190",
    "id": "453",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "-6",
    "owner": "hx651dd46807d3df37399c4ae2812aef1d8fe7db93",
    "name": "Emerald City Land #454",
    "zoneId": "8",
    "metaId": "6908",
    "nftId": 454,
    "creationDate": "1691120915630190",
    "id": "454",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "-9",
    "owner": "hx6cdf7757e937903dc01af9603ad664ddc3fa6a89",
    "name": "Emerald City Land #455",
    "zoneId": "8",
    "metaId": "6929",
    "nftId": 455,
    "creationDate": "1691120933480885",
    "id": "455",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "19",
    "owner": "hx6cdf7757e937903dc01af9603ad664ddc3fa6a89",
    "name": "Emerald City Land #456",
    "zoneId": "8",
    "metaId": "5529",
    "nftId": 456,
    "creationDate": "1691120933480885",
    "id": "456",
    "mintedVia": "CROWN",
  },
  {
    "x": "-101",
    "y": "-14",
    "owner": "hx6cdf7757e937903dc01af9603ad664ddc3fa6a89",
    "name": "Emerald City Land #457",
    "zoneId": "8",
    "metaId": "6100",
    "nftId": 457,
    "creationDate": "1691120933480885",
    "id": "457",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "0",
    "owner": "hx6cdf7757e937903dc01af9603ad664ddc3fa6a89",
    "name": "Emerald City Land #458",
    "zoneId": "8",
    "metaId": "6718",
    "nftId": 458,
    "creationDate": "1691120933480885",
    "id": "458",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-26",
    "owner": "hx157fa170289c22021e5a463e0f37ab9097859cc6",
    "name": "Emerald City Land #459",
    "zoneId": "2",
    "metaId": "746",
    "nftId": 459,
    "creationDate": "1691120969656736",
    "id": "459",
    "mintedVia": "CROWN",
  },
  {
    "x": "12",
    "y": "32",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #460",
    "zoneId": "3",
    "metaId": "916",
    "nftId": 460,
    "creationDate": "1691120971558170",
    "id": "460",
    "mintedVia": "CROWN",
  },
  {
    "x": "15",
    "y": "30",
    "owner": "hxbf37eac3b738a15e5dfbeb20ec6be4a7f769f5e0",
    "name": "Emerald City Land #461",
    "zoneId": "3",
    "metaId": "924",
    "nftId": 461,
    "creationDate": "1691120997477288",
    "id": "461",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "5",
    "owner": "hx157fa170289c22021e5a463e0f37ab9097859cc6",
    "name": "Emerald City Land #462",
    "zoneId": "7",
    "metaId": "4388",
    "nftId": 462,
    "creationDate": "1691121019667926",
    "id": "462",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "11",
    "owner": "hx157fa170289c22021e5a463e0f37ab9097859cc6",
    "name": "Emerald City Land #463",
    "zoneId": "7",
    "metaId": "2994",
    "nftId": 463,
    "creationDate": "1691121019667926",
    "id": "463",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "29",
    "owner": "hx157fa170289c22021e5a463e0f37ab9097859cc6",
    "name": "Emerald City Land #464",
    "zoneId": "7",
    "metaId": "2134",
    "nftId": 464,
    "creationDate": "1691121019667926",
    "id": "464",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "5",
    "owner": "hx157fa170289c22021e5a463e0f37ab9097859cc6",
    "name": "Emerald City Land #465",
    "zoneId": "7",
    "metaId": "2746",
    "nftId": 465,
    "creationDate": "1691121019667926",
    "id": "465",
    "mintedVia": "CROWN",
  },
  {
    "x": "15",
    "y": "13",
    "owner": "hx03a6688ab037e35b5b0f510593da9738e6199e43",
    "name": "Emerald City Land #466",
    "zoneId": "8",
    "metaId": "4899",
    "nftId": 466,
    "creationDate": "1691121023525720",
    "id": "466",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "-5",
    "owner": "hxc910beff64f520c3ef1e078477b13187ad0c620d",
    "name": "Emerald City Land #467",
    "zoneId": "8",
    "metaId": "5479",
    "nftId": 467,
    "creationDate": "1691121851946449",
    "id": "467",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "9",
    "owner": "hxa71249cc33d414e1474951bf6f7f4f81b6681bd5",
    "name": "Emerald City Land #468",
    "zoneId": "1",
    "metaId": "498",
    "nftId": 468,
    "creationDate": "1691122189872412",
    "id": "468",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "-9",
    "owner": "hxa71249cc33d414e1474951bf6f7f4f81b6681bd5",
    "name": "Emerald City Land #469",
    "zoneId": "7",
    "metaId": "4562",
    "nftId": 469,
    "creationDate": "1691122223902067",
    "id": "469",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "23",
    "owner": "hxa71249cc33d414e1474951bf6f7f4f81b6681bd5",
    "name": "Emerald City Land #470",
    "zoneId": "7",
    "metaId": "3505",
    "nftId": 470,
    "creationDate": "1691122223902067",
    "id": "470",
    "mintedVia": "CROWN",
  },
  {
    "x": "55",
    "y": "-1",
    "owner": "hxa71249cc33d414e1474951bf6f7f4f81b6681bd5",
    "name": "Emerald City Land #471",
    "zoneId": "4",
    "metaId": "1349",
    "nftId": 471,
    "creationDate": "1691122267922473",
    "id": "471",
    "mintedVia": "CROWN",
  },
  {
    "x": "85",
    "y": "-7",
    "owner": "hxa71249cc33d414e1474951bf6f7f4f81b6681bd5",
    "name": "Emerald City Land #472",
    "zoneId": "8",
    "metaId": "5958",
    "nftId": 472,
    "creationDate": "1691122289896978",
    "id": "472",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "1",
    "owner": "hxf662476d85bc58ee7aa203810238e0b791afc1c8",
    "name": "Emerald City Land #473",
    "zoneId": "7",
    "metaId": "4030",
    "nftId": 473,
    "creationDate": "1691122908212917",
    "id": "473",
    "mintedVia": "CROWN",
  },
  {
    "x": "-19",
    "y": "15",
    "owner": "hx9b154d5d192a2816b0818f3c36d9b27e56226300",
    "name": "Emerald City Land #474",
    "zoneId": "5",
    "metaId": "1623",
    "nftId": 474,
    "creationDate": "1691123104115545",
    "id": "474",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "-16",
    "owner": "hx3f133cdf56a93b43bca05939d12d4996a98996ab",
    "name": "Emerald City Land #475",
    "zoneId": "8",
    "metaId": "5436",
    "nftId": 475,
    "creationDate": "1691123156313872",
    "id": "475",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "20",
    "owner": "hx3f133cdf56a93b43bca05939d12d4996a98996ab",
    "name": "Emerald City Land #476",
    "zoneId": "8",
    "metaId": "5453",
    "nftId": 476,
    "creationDate": "1691123156313872",
    "id": "476",
    "mintedVia": "CROWN",
  },
  {
    "x": "-72",
    "y": "13",
    "owner": "hx3f133cdf56a93b43bca05939d12d4996a98996ab",
    "name": "Emerald City Land #477",
    "zoneId": "8",
    "metaId": "6240",
    "nftId": 477,
    "creationDate": "1691123156313872",
    "id": "477",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "27",
    "owner": "hx3f133cdf56a93b43bca05939d12d4996a98996ab",
    "name": "Emerald City Land #478",
    "zoneId": "7",
    "metaId": "1909",
    "nftId": 478,
    "creationDate": "1691123398193905",
    "id": "478",
    "mintedVia": "CROWN",
  },
  {
    "x": "43",
    "y": "0",
    "owner": "hx3f133cdf56a93b43bca05939d12d4996a98996ab",
    "name": "Emerald City Land #479",
    "zoneId": "7",
    "metaId": "2648",
    "nftId": 479,
    "creationDate": "1691123398193905",
    "id": "479",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "10",
    "owner": "hxfad322a18a5e9ad6627ea4b66f86707ed71a21f5",
    "name": "Emerald City Land #480",
    "zoneId": "8",
    "metaId": "6024",
    "nftId": 480,
    "creationDate": "1691123976363700",
    "id": "480",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "5",
    "owner": "hx65699b5c0268646be7b53f32cfe0f6aab89a3ab5",
    "name": "Emerald City Land #481",
    "zoneId": "1",
    "metaId": "379",
    "nftId": 481,
    "creationDate": "1691124110470099",
    "id": "481",
    "mintedVia": "CROWN",
  },
  {
    "x": "-99",
    "y": "4",
    "owner": "hx4ecf40ccbc51e1f2a3d21a8bda45414add51fd73",
    "name": "Emerald City Land #482",
    "zoneId": "8",
    "metaId": "6582",
    "nftId": 482,
    "creationDate": "1691124150474214",
    "id": "482",
    "mintedVia": "CROWN",
  },
  {
    "x": "-101",
    "y": "-5",
    "owner": "hx4ecf40ccbc51e1f2a3d21a8bda45414add51fd73",
    "name": "Emerald City Land #483",
    "zoneId": "8",
    "metaId": "6104",
    "nftId": 483,
    "creationDate": "1691124150474214",
    "id": "483",
    "mintedVia": "CROWN",
  },
  {
    "x": "-70",
    "y": "14",
    "owner": "hx4ecf40ccbc51e1f2a3d21a8bda45414add51fd73",
    "name": "Emerald City Land #484",
    "zoneId": "1",
    "metaId": "125",
    "nftId": 484,
    "creationDate": "1691124226444393",
    "id": "484",
    "mintedVia": "CROWN",
  },
  {
    "x": "17",
    "y": "27",
    "owner": "hx9adc1c1e078a028e74fa1d9c7ae54bbb097be804",
    "name": "Emerald City Land #485",
    "zoneId": "8",
    "metaId": "4783",
    "nftId": 485,
    "creationDate": "1691124388643841",
    "id": "485",
    "mintedVia": "CROWN",
  },
  {
    "x": "24",
    "y": "27",
    "owner": "hx9adc1c1e078a028e74fa1d9c7ae54bbb097be804",
    "name": "Emerald City Land #486",
    "zoneId": "8",
    "metaId": "4800",
    "nftId": 486,
    "creationDate": "1691124388643841",
    "id": "486",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "8",
    "owner": "hx4ecf40ccbc51e1f2a3d21a8bda45414add51fd73",
    "name": "Emerald City Land #487",
    "zoneId": "5",
    "metaId": "1607",
    "nftId": 487,
    "creationDate": "1691124428525823",
    "id": "487",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "11",
    "owner": "hx4b0deedd552bab1c9123e8523e056adca5658fd5",
    "name": "Emerald City Land #488",
    "zoneId": "7",
    "metaId": "3101",
    "nftId": 488,
    "creationDate": "1691124526532992",
    "id": "488",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "25",
    "owner": "hxd736e9979db0c60a5b74ef40f635557aa4770a3f",
    "name": "Emerald City Land #489",
    "zoneId": "7",
    "metaId": "3991",
    "nftId": 489,
    "creationDate": "1691124552511939",
    "id": "489",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "10",
    "owner": "hxd736e9979db0c60a5b74ef40f635557aa4770a3f",
    "name": "Emerald City Land #490",
    "zoneId": "7",
    "metaId": "2993",
    "nftId": 490,
    "creationDate": "1691124552511939",
    "id": "490",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "21",
    "owner": "hxd736e9979db0c60a5b74ef40f635557aa4770a3f",
    "name": "Emerald City Land #491",
    "zoneId": "7",
    "metaId": "3799",
    "nftId": 491,
    "creationDate": "1691124552511939",
    "id": "491",
    "mintedVia": "CROWN",
  },
  {
    "x": "-104",
    "y": "2",
    "owner": "hx6a67e6f1a32722d56e5f23773b37441dee2fefef",
    "name": "Emerald City Land #492",
    "zoneId": "4",
    "metaId": "1255",
    "nftId": 492,
    "creationDate": "1691124670546843",
    "id": "492",
    "mintedVia": "CROWN",
  },
  {
    "x": "-95",
    "y": "-8",
    "owner": "hx6a67e6f1a32722d56e5f23773b37441dee2fefef",
    "name": "Emerald City Land #493",
    "zoneId": "7",
    "metaId": "2379",
    "nftId": 493,
    "creationDate": "1691124690592723",
    "id": "493",
    "mintedVia": "CROWN",
  },
  {
    "x": "-46",
    "y": "8",
    "owner": "hx6a67e6f1a32722d56e5f23773b37441dee2fefef",
    "name": "Emerald City Land #494",
    "zoneId": "7",
    "metaId": "4304",
    "nftId": 494,
    "creationDate": "1691124706799618",
    "id": "494",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "19",
    "owner": "hx6a67e6f1a32722d56e5f23773b37441dee2fefef",
    "name": "Emerald City Land #495",
    "zoneId": "7",
    "metaId": "3677",
    "nftId": 495,
    "creationDate": "1691124728613763",
    "id": "495",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "34",
    "owner": "hx6a67e6f1a32722d56e5f23773b37441dee2fefef",
    "name": "Emerald City Land #496",
    "zoneId": "7",
    "metaId": "4463",
    "nftId": 496,
    "creationDate": "1691124748601670",
    "id": "496",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "25",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #497",
    "zoneId": "8",
    "metaId": "5110",
    "nftId": 497,
    "creationDate": "1691124870613924",
    "id": "497",
    "mintedVia": "CROWN",
  },
  {
    "x": "-71",
    "y": "12",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #498",
    "zoneId": "8",
    "metaId": "6230",
    "nftId": 498,
    "creationDate": "1691125006905356",
    "id": "498",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "-8",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #499",
    "zoneId": "8",
    "metaId": "5642",
    "nftId": 499,
    "creationDate": "1691125094786569",
    "id": "499",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-29",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #500",
    "zoneId": "2",
    "metaId": "740",
    "nftId": 500,
    "creationDate": "1691125210798560",
    "id": "500",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "12",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #501",
    "zoneId": "7",
    "metaId": "3477",
    "nftId": 501,
    "creationDate": "1691125332787399",
    "id": "501",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "8",
    "owner": "hx18b73a4d38ace9c7803bef586e7b6f63096ec639",
    "name": "Emerald City Land #502",
    "zoneId": "7",
    "metaId": "2840",
    "nftId": 502,
    "creationDate": "1691125382803475",
    "id": "502",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "12",
    "owner": "hx18b73a4d38ace9c7803bef586e7b6f63096ec639",
    "name": "Emerald City Land #503",
    "zoneId": "7",
    "metaId": "2062",
    "nftId": 503,
    "creationDate": "1691125382803475",
    "id": "503",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "-27",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #504",
    "zoneId": "1",
    "metaId": "183",
    "nftId": 504,
    "creationDate": "1691125426801402",
    "id": "504",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "8",
    "owner": "hx18b73a4d38ace9c7803bef586e7b6f63096ec639",
    "name": "Emerald City Land #505",
    "zoneId": "8",
    "metaId": "6549",
    "nftId": 505,
    "creationDate": "1691125436962724",
    "id": "505",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "23",
    "owner": "hxd736e9979db0c60a5b74ef40f635557aa4770a3f",
    "name": "Emerald City Land #506",
    "zoneId": "8",
    "metaId": "5770",
    "nftId": 506,
    "creationDate": "1691125446835900",
    "id": "506",
    "mintedVia": "CROWN",
  },
  {
    "x": "54",
    "y": "22",
    "owner": "hxd736e9979db0c60a5b74ef40f635557aa4770a3f",
    "name": "Emerald City Land #507",
    "zoneId": "8",
    "metaId": "4993",
    "nftId": 507,
    "creationDate": "1691125446835900",
    "id": "507",
    "mintedVia": "CROWN",
  },
  {
    "x": "-19",
    "y": "22",
    "owner": "hxb3ec843e3cdd68fb793bfb22c2d044163416ca10",
    "name": "Emerald City Land #508",
    "zoneId": "7",
    "metaId": "3536",
    "nftId": 508,
    "creationDate": "1691125452796072",
    "id": "508",
    "mintedVia": "CROWN",
  },
  {
    "x": "3",
    "y": "26",
    "owner": "hxb3ec843e3cdd68fb793bfb22c2d044163416ca10",
    "name": "Emerald City Land #509",
    "zoneId": "7",
    "metaId": "3916",
    "nftId": 509,
    "creationDate": "1691125452796072",
    "id": "509",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "-33",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #510",
    "zoneId": "2",
    "metaId": "773",
    "nftId": 510,
    "creationDate": "1691125530864685",
    "id": "510",
    "mintedVia": "CROWN",
  },
  {
    "x": "40",
    "y": "15",
    "owner": "hxfdb96bf0305e875f97f1c57aef4e3b4a2c32363c",
    "name": "Emerald City Land #511",
    "zoneId": "7",
    "metaId": "1882",
    "nftId": 511,
    "creationDate": "1691125558991899",
    "id": "511",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "4",
    "owner": "hxfdb96bf0305e875f97f1c57aef4e3b4a2c32363c",
    "name": "Emerald City Land #512",
    "zoneId": "7",
    "metaId": "4371",
    "nftId": 512,
    "creationDate": "1691125558991899",
    "id": "512",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "21",
    "owner": "hxfdb96bf0305e875f97f1c57aef4e3b4a2c32363c",
    "name": "Emerald City Land #513",
    "zoneId": "7",
    "metaId": "4282",
    "nftId": 513,
    "creationDate": "1691125558991899",
    "id": "513",
    "mintedVia": "CROWN",
  },
  {
    "x": "-64",
    "y": "-28",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #514",
    "zoneId": "2",
    "metaId": "890",
    "nftId": 514,
    "creationDate": "1691125607085230",
    "id": "514",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "-33",
    "owner": "hxafeee1cebb7c43ee668a6a98d5c0e302eafdbe52",
    "name": "Emerald City Land #515",
    "zoneId": "2",
    "metaId": "880",
    "nftId": 515,
    "creationDate": "1691125639016492",
    "id": "515",
    "mintedVia": "CROWN",
  },
  {
    "x": "1",
    "y": "20",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #516",
    "zoneId": "7",
    "metaId": "3878",
    "nftId": 516,
    "creationDate": "1691125665043994",
    "id": "516",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "21",
    "owner": "hxafeee1cebb7c43ee668a6a98d5c0e302eafdbe52",
    "name": "Emerald City Land #517",
    "zoneId": "7",
    "metaId": "2076",
    "nftId": 517,
    "creationDate": "1691125740913456",
    "id": "517",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "-6",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #518",
    "zoneId": "4",
    "metaId": "1537",
    "nftId": 518,
    "creationDate": "1691125765071979",
    "id": "518",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "1",
    "owner": "hxafeee1cebb7c43ee668a6a98d5c0e302eafdbe52",
    "name": "Emerald City Land #519",
    "zoneId": "8",
    "metaId": "5792",
    "nftId": 519,
    "creationDate": "1691125809074934",
    "id": "519",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "14",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #520",
    "zoneId": "5",
    "metaId": "1649",
    "nftId": 520,
    "creationDate": "1691125840938224",
    "id": "520",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "21",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #521",
    "zoneId": "5",
    "metaId": "1541",
    "nftId": 521,
    "creationDate": "1691125840938224",
    "id": "521",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "24",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #522",
    "zoneId": "5",
    "metaId": "1543",
    "nftId": 522,
    "creationDate": "1691125840938224",
    "id": "522",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "4",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #523",
    "zoneId": "5",
    "metaId": "1599",
    "nftId": 523,
    "creationDate": "1691125840938224",
    "id": "523",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "-9",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #524",
    "zoneId": "5",
    "metaId": "1584",
    "nftId": 524,
    "creationDate": "1691125840938224",
    "id": "524",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "21",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #525",
    "zoneId": "1",
    "metaId": "33",
    "nftId": 525,
    "creationDate": "1691125857181313",
    "id": "525",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "-16",
    "owner": "hxc3faa688d250d9c0c10edfa37639ec7e2ee92329",
    "name": "Emerald City Land #526",
    "zoneId": "7",
    "metaId": "4591",
    "nftId": 526,
    "creationDate": "1691125876941787",
    "id": "526",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "-3",
    "owner": "hxd736e9979db0c60a5b74ef40f635557aa4770a3f",
    "name": "Emerald City Land #527",
    "zoneId": "4",
    "metaId": "1526",
    "nftId": 527,
    "creationDate": "1691125920936964",
    "id": "527",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "22",
    "owner": "hxd736e9979db0c60a5b74ef40f635557aa4770a3f",
    "name": "Emerald City Land #528",
    "zoneId": "4",
    "metaId": "1429",
    "nftId": 528,
    "creationDate": "1691125920936964",
    "id": "528",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "15",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #529",
    "zoneId": "8",
    "metaId": "4852",
    "nftId": 529,
    "creationDate": "1691125957196739",
    "id": "529",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "-4",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #530",
    "zoneId": "5",
    "metaId": "1662",
    "nftId": 530,
    "creationDate": "1691125969033784",
    "id": "530",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "17",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #531",
    "zoneId": "5",
    "metaId": "1629",
    "nftId": 531,
    "creationDate": "1691125969033784",
    "id": "531",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "-20",
    "owner": "hxc3faa688d250d9c0c10edfa37639ec7e2ee92329",
    "name": "Emerald City Land #532",
    "zoneId": "1",
    "metaId": "617",
    "nftId": 532,
    "creationDate": "1691126011050554",
    "id": "532",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "5",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #533",
    "zoneId": "6",
    "metaId": "1725",
    "nftId": 533,
    "creationDate": "1691126015147701",
    "id": "533",
    "mintedVia": "CROWN",
  },
  {
    "x": "54",
    "y": "-3",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #534",
    "zoneId": "4",
    "metaId": "1343",
    "nftId": 534,
    "creationDate": "1691126048995715",
    "id": "534",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "-12",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #535",
    "zoneId": "4",
    "metaId": "1528",
    "nftId": 535,
    "creationDate": "1691126048995715",
    "id": "535",
    "mintedVia": "CROWN",
  },
  {
    "x": "-18",
    "y": "28",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #536",
    "zoneId": "4",
    "metaId": "1445",
    "nftId": 536,
    "creationDate": "1691126048995715",
    "id": "536",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "19",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #537",
    "zoneId": "4",
    "metaId": "1140",
    "nftId": 537,
    "creationDate": "1691126048995715",
    "id": "537",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "19",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #538",
    "zoneId": "4",
    "metaId": "1158",
    "nftId": 538,
    "creationDate": "1691126048995715",
    "id": "538",
    "mintedVia": "CROWN",
  },
  {
    "x": "-91",
    "y": "8",
    "owner": "hxc3faa688d250d9c0c10edfa37639ec7e2ee92329",
    "name": "Emerald City Land #539",
    "zoneId": "8",
    "metaId": "6432",
    "nftId": 539,
    "creationDate": "1691126051034724",
    "id": "539",
    "mintedVia": "CROWN",
  },
  {
    "x": "-69",
    "y": "19",
    "owner": "hxc3faa688d250d9c0c10edfa37639ec7e2ee92329",
    "name": "Emerald City Land #540",
    "zoneId": "8",
    "metaId": "6224",
    "nftId": 540,
    "creationDate": "1691126051034724",
    "id": "540",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "13",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #541",
    "zoneId": "6",
    "metaId": "1680",
    "nftId": 541,
    "creationDate": "1691126157250732",
    "id": "541",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "16",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #542",
    "zoneId": "6",
    "metaId": "1774",
    "nftId": 542,
    "creationDate": "1691126157250732",
    "id": "542",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "-7",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #543",
    "zoneId": "6",
    "metaId": "1733",
    "nftId": 543,
    "creationDate": "1691126157250732",
    "id": "543",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "-3",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #544",
    "zoneId": "1",
    "metaId": "485",
    "nftId": 544,
    "creationDate": "1691126247083460",
    "id": "544",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "8",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #545",
    "zoneId": "1",
    "metaId": "281",
    "nftId": 545,
    "creationDate": "1691126247083460",
    "id": "545",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "30",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #546",
    "zoneId": "8",
    "metaId": "5698",
    "nftId": 546,
    "creationDate": "1691126321055064",
    "id": "546",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-21",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #547",
    "zoneId": "2",
    "metaId": "743",
    "nftId": 547,
    "creationDate": "1691126367251864",
    "id": "547",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "23",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #548",
    "zoneId": "7",
    "metaId": "3420",
    "nftId": 548,
    "creationDate": "1691126415276282",
    "id": "548",
    "mintedVia": "CROWN",
  },
  {
    "x": "-104",
    "y": "-1",
    "owner": "hx17ed41d951bbfb57e8f55574be5893182cb7e8e0",
    "name": "Emerald City Land #549",
    "zoneId": "8",
    "metaId": "6152",
    "nftId": 549,
    "creationDate": "1691126437266971",
    "id": "549",
    "mintedVia": "CROWN",
  },
  {
    "x": "38",
    "y": "-4",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #550",
    "zoneId": "5",
    "metaId": "1589",
    "nftId": 550,
    "creationDate": "1691126457338374",
    "id": "550",
    "mintedVia": "CROWN",
  },
  {
    "x": "-18",
    "y": "15",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #551",
    "zoneId": "4",
    "metaId": "1442",
    "nftId": 551,
    "creationDate": "1691126497157187",
    "id": "551",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "12",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #552",
    "zoneId": "6",
    "metaId": "1785",
    "nftId": 552,
    "creationDate": "1691126541153196",
    "id": "552",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "-11",
    "owner": "hx6f6f1336216fa3f76e6e2999eb07179225f7293b",
    "name": "Emerald City Land #553",
    "zoneId": "1",
    "metaId": "659",
    "nftId": 553,
    "creationDate": "1691126587310792",
    "id": "553",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-2",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #554",
    "zoneId": "7",
    "metaId": "4204",
    "nftId": 554,
    "creationDate": "1691126753204176",
    "id": "554",
    "mintedVia": "CROWN",
  },
  {
    "x": "56",
    "y": "0",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #555",
    "zoneId": "7",
    "metaId": "2801",
    "nftId": 555,
    "creationDate": "1691126753204176",
    "id": "555",
    "mintedVia": "CROWN",
  },
  {
    "x": "-20",
    "y": "6",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #556",
    "zoneId": "7",
    "metaId": "3580",
    "nftId": 556,
    "creationDate": "1691126753204176",
    "id": "556",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "10",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #557",
    "zoneId": "7",
    "metaId": "3411",
    "nftId": 557,
    "creationDate": "1691126753204176",
    "id": "557",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "16",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #558",
    "zoneId": "7",
    "metaId": "4411",
    "nftId": 558,
    "creationDate": "1691126753204176",
    "id": "558",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "24",
    "owner": "hx25ee33bbaf76616b432dfb4b785beb1f92db07c9",
    "name": "Emerald City Land #559",
    "zoneId": "7",
    "metaId": "3450",
    "nftId": 559,
    "creationDate": "1691126787366446",
    "id": "559",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "13",
    "owner": "hx25ee33bbaf76616b432dfb4b785beb1f92db07c9",
    "name": "Emerald City Land #560",
    "zoneId": "7",
    "metaId": "3414",
    "nftId": 560,
    "creationDate": "1691126787366446",
    "id": "560",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "14",
    "owner": "hx25ee33bbaf76616b432dfb4b785beb1f92db07c9",
    "name": "Emerald City Land #561",
    "zoneId": "7",
    "metaId": "3781",
    "nftId": 561,
    "creationDate": "1691126787366446",
    "id": "561",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "12",
    "owner": "hx25ee33bbaf76616b432dfb4b785beb1f92db07c9",
    "name": "Emerald City Land #562",
    "zoneId": "7",
    "metaId": "3305",
    "nftId": 562,
    "creationDate": "1691126787366446",
    "id": "562",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "26",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #563",
    "zoneId": "7",
    "metaId": "4101",
    "nftId": 563,
    "creationDate": "1691126855243273",
    "id": "563",
    "mintedVia": "CROWN",
  },
  {
    "x": "0",
    "y": "-7",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #564",
    "zoneId": "7",
    "metaId": "3152",
    "nftId": 564,
    "creationDate": "1691126855243273",
    "id": "564",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "-9",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #565",
    "zoneId": "7",
    "metaId": "2939",
    "nftId": 565,
    "creationDate": "1691126855243273",
    "id": "565",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "7",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #566",
    "zoneId": "7",
    "metaId": "2173",
    "nftId": 566,
    "creationDate": "1691126947294025",
    "id": "566",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "24",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #567",
    "zoneId": "7",
    "metaId": "3900",
    "nftId": 567,
    "creationDate": "1691126947294025",
    "id": "567",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "-8",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #568",
    "zoneId": "7",
    "metaId": "2705",
    "nftId": 568,
    "creationDate": "1691126947294025",
    "id": "568",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "1",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #569",
    "zoneId": "7",
    "metaId": "2928",
    "nftId": 569,
    "creationDate": "1691126947294025",
    "id": "569",
    "mintedVia": "CROWN",
  },
  {
    "x": "30",
    "y": "-6",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #570",
    "zoneId": "7",
    "metaId": "2420",
    "nftId": 570,
    "creationDate": "1691126947294025",
    "id": "570",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "-13",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #571",
    "zoneId": "1",
    "metaId": "43",
    "nftId": 571,
    "creationDate": "1691126991292100",
    "id": "571",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "-2",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #572",
    "zoneId": "1",
    "metaId": "515",
    "nftId": 572,
    "creationDate": "1691126991292100",
    "id": "572",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "36",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #573",
    "zoneId": "7",
    "metaId": "3793",
    "nftId": 573,
    "creationDate": "1691126997308795",
    "id": "573",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "16",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #574",
    "zoneId": "7",
    "metaId": "3984",
    "nftId": 574,
    "creationDate": "1691126997308795",
    "id": "574",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "30",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #575",
    "zoneId": "7",
    "metaId": "1905",
    "nftId": 575,
    "creationDate": "1691126997308795",
    "id": "575",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "-4",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #576",
    "zoneId": "7",
    "metaId": "2908",
    "nftId": 576,
    "creationDate": "1691126997308795",
    "id": "576",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "25",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #577",
    "zoneId": "7",
    "metaId": "4149",
    "nftId": 577,
    "creationDate": "1691126997308795",
    "id": "577",
    "mintedVia": "CROWN",
  },
  {
    "x": "9",
    "y": "24",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #578",
    "zoneId": "7",
    "metaId": "4524",
    "nftId": 578,
    "creationDate": "1691127061363270",
    "id": "578",
    "mintedVia": "CROWN",
  },
  {
    "x": "-102",
    "y": "-11",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #579",
    "zoneId": "8",
    "metaId": "6115",
    "nftId": 579,
    "creationDate": "1691127069361425",
    "id": "579",
    "mintedVia": "CROWN",
  },
  {
    "x": "-100",
    "y": "5",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #580",
    "zoneId": "8",
    "metaId": "6095",
    "nftId": 580,
    "creationDate": "1691127069361425",
    "id": "580",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "20",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #581",
    "zoneId": "8",
    "metaId": "5038",
    "nftId": 581,
    "creationDate": "1691127069361425",
    "id": "581",
    "mintedVia": "CROWN",
  },
  {
    "x": "-101",
    "y": "-11",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #582",
    "zoneId": "8",
    "metaId": "6099",
    "nftId": 582,
    "creationDate": "1691127069361425",
    "id": "582",
    "mintedVia": "CROWN",
  },
  {
    "x": "-109",
    "y": "2",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #583",
    "zoneId": "1",
    "metaId": "119",
    "nftId": 583,
    "creationDate": "1691127153373400",
    "id": "583",
    "mintedVia": "CROWN",
  },
  {
    "x": "14",
    "y": "25",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #584",
    "zoneId": "8",
    "metaId": "4758",
    "nftId": 584,
    "creationDate": "1691127189483631",
    "id": "584",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "0",
    "owner": "hxab5df692abc89645d517619ec2324e659f810f46",
    "name": "Emerald City Land #585",
    "zoneId": "8",
    "metaId": "5990",
    "nftId": 585,
    "creationDate": "1691127189483631",
    "id": "585",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "10",
    "owner": "hx9b451345a9f5c7afeaf2ff4bb9d1fb0f57a62e60",
    "name": "Emerald City Land #586",
    "zoneId": "7",
    "metaId": "3138",
    "nftId": 586,
    "creationDate": "1691127217503763",
    "id": "586",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "35",
    "owner": "hx9b451345a9f5c7afeaf2ff4bb9d1fb0f57a62e60",
    "name": "Emerald City Land #587",
    "zoneId": "7",
    "metaId": "3805",
    "nftId": 587,
    "creationDate": "1691127217503763",
    "id": "587",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "-11",
    "owner": "hx9b451345a9f5c7afeaf2ff4bb9d1fb0f57a62e60",
    "name": "Emerald City Land #588",
    "zoneId": "1",
    "metaId": "28",
    "nftId": 588,
    "creationDate": "1691127281404253",
    "id": "588",
    "mintedVia": "CROWN",
  },
  {
    "x": "24",
    "y": "5",
    "owner": "hx9b451345a9f5c7afeaf2ff4bb9d1fb0f57a62e60",
    "name": "Emerald City Land #589",
    "zoneId": "1",
    "metaId": "462",
    "nftId": 589,
    "creationDate": "1691127281404253",
    "id": "589",
    "mintedVia": "CROWN",
  },
  {
    "x": "-105",
    "y": "-2",
    "owner": "hx9b451345a9f5c7afeaf2ff4bb9d1fb0f57a62e60",
    "name": "Emerald City Land #590",
    "zoneId": "8",
    "metaId": "6165",
    "nftId": 590,
    "creationDate": "1691127333386072",
    "id": "590",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "13",
    "owner": "hx9b451345a9f5c7afeaf2ff4bb9d1fb0f57a62e60",
    "name": "Emerald City Land #591",
    "zoneId": "8",
    "metaId": "5995",
    "nftId": 591,
    "creationDate": "1691127333386072",
    "id": "591",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "-32",
    "owner": "hxb1dea3123611629a70e529103557526cdfbc5bf4",
    "name": "Emerald City Land #592",
    "zoneId": "2",
    "metaId": "833",
    "nftId": 592,
    "creationDate": "1691127335426958",
    "id": "592",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "-10",
    "owner": "hxb1dea3123611629a70e529103557526cdfbc5bf4",
    "name": "Emerald City Land #593",
    "zoneId": "1",
    "metaId": "336",
    "nftId": 593,
    "creationDate": "1691127381426479",
    "id": "593",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "21",
    "owner": "hxb1dea3123611629a70e529103557526cdfbc5bf4",
    "name": "Emerald City Land #594",
    "zoneId": "1",
    "metaId": "35",
    "nftId": 594,
    "creationDate": "1691127381426479",
    "id": "594",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "-5",
    "owner": "hxb1dea3123611629a70e529103557526cdfbc5bf4",
    "name": "Emerald City Land #595",
    "zoneId": "7",
    "metaId": "2935",
    "nftId": 595,
    "creationDate": "1691127421393965",
    "id": "595",
    "mintedVia": "CROWN",
  },
  {
    "x": "89",
    "y": "8",
    "owner": "hxb1dea3123611629a70e529103557526cdfbc5bf4",
    "name": "Emerald City Land #596",
    "zoneId": "8",
    "metaId": "6069",
    "nftId": 596,
    "creationDate": "1691127453491249",
    "id": "596",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "-21",
    "owner": "hx29eb841f8c659af8e4788eac63e343fb22006aaa",
    "name": "Emerald City Land #597",
    "zoneId": "2",
    "metaId": "864",
    "nftId": 597,
    "creationDate": "1691127459579079",
    "id": "597",
    "mintedVia": "CROWN",
  },
  {
    "x": "30",
    "y": "9",
    "owner": "hx29eb841f8c659af8e4788eac63e343fb22006aaa",
    "name": "Emerald City Land #598",
    "zoneId": "7",
    "metaId": "2427",
    "nftId": 598,
    "creationDate": "1691127511506719",
    "id": "598",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-20",
    "owner": "hx29eb841f8c659af8e4788eac63e343fb22006aaa",
    "name": "Emerald City Land #599",
    "zoneId": "1",
    "metaId": "656",
    "nftId": 599,
    "creationDate": "1691127551490738",
    "id": "599",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "7",
    "owner": "hx29eb841f8c659af8e4788eac63e343fb22006aaa",
    "name": "Emerald City Land #600",
    "zoneId": "1",
    "metaId": "280",
    "nftId": 600,
    "creationDate": "1691127595567969",
    "id": "600",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "-7",
    "owner": "hx29eb841f8c659af8e4788eac63e343fb22006aaa",
    "name": "Emerald City Land #601",
    "zoneId": "1",
    "metaId": "373",
    "nftId": 601,
    "creationDate": "1691127595567969",
    "id": "601",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "-2",
    "owner": "hx1e3a479a803686b1f17d04f3330a52686c9b2000",
    "name": "Emerald City Land #602",
    "zoneId": "7",
    "metaId": "2494",
    "nftId": 602,
    "creationDate": "1691127649491968",
    "id": "602",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "-13",
    "owner": "hx1e3a479a803686b1f17d04f3330a52686c9b2000",
    "name": "Emerald City Land #603",
    "zoneId": "8",
    "metaId": "6895",
    "nftId": 603,
    "creationDate": "1691127719567530",
    "id": "603",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "-15",
    "owner": "hx1e3a479a803686b1f17d04f3330a52686c9b2000",
    "name": "Emerald City Land #604",
    "zoneId": "8",
    "metaId": "6903",
    "nftId": 604,
    "creationDate": "1691127719567530",
    "id": "604",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "4",
    "owner": "hx484d3d5b5d2234535a5a9a40950f1f222d484e5d",
    "name": "Emerald City Land #605",
    "zoneId": "8",
    "metaId": "5779",
    "nftId": 605,
    "creationDate": "1691127965747536",
    "id": "605",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "14",
    "owner": "hx3967ae023120655a3f43990f5a2bdc4e1a7ec70b",
    "name": "Emerald City Land #606",
    "zoneId": "7",
    "metaId": "3500",
    "nftId": 606,
    "creationDate": "1691128043664240",
    "id": "606",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "-16",
    "owner": "hx3967ae023120655a3f43990f5a2bdc4e1a7ec70b",
    "name": "Emerald City Land #607",
    "zoneId": "8",
    "metaId": "5553",
    "nftId": 607,
    "creationDate": "1691128101657803",
    "id": "607",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "-4",
    "owner": "hx3967ae023120655a3f43990f5a2bdc4e1a7ec70b",
    "name": "Emerald City Land #608",
    "zoneId": "8",
    "metaId": "6791",
    "nftId": 608,
    "creationDate": "1691128101657803",
    "id": "608",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "21",
    "owner": "hx3967ae023120655a3f43990f5a2bdc4e1a7ec70b",
    "name": "Emerald City Land #609",
    "zoneId": "8",
    "metaId": "6662",
    "nftId": 609,
    "creationDate": "1691128101657803",
    "id": "609",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "19",
    "owner": "hx5107725aa74d7c06b270ffc232786e69308ce3b0",
    "name": "Emerald City Land #610",
    "zoneId": "3",
    "metaId": "1093",
    "nftId": 610,
    "creationDate": "1691128119689207",
    "id": "610",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "26",
    "owner": "hx5107725aa74d7c06b270ffc232786e69308ce3b0",
    "name": "Emerald City Land #611",
    "zoneId": "8",
    "metaId": "4944",
    "nftId": 611,
    "creationDate": "1691128219723326",
    "id": "611",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "2",
    "owner": "hx5107725aa74d7c06b270ffc232786e69308ce3b0",
    "name": "Emerald City Land #612",
    "zoneId": "4",
    "metaId": "1312",
    "nftId": 612,
    "creationDate": "1691128277678269",
    "id": "612",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "15",
    "owner": "hx421f624fd856517b4478b169c8849d087110ce93",
    "name": "Emerald City Land #613",
    "zoneId": "4",
    "metaId": "1500",
    "nftId": 613,
    "creationDate": "1691128561833027",
    "id": "613",
    "mintedVia": "CROWN",
  },
  {
    "x": "40",
    "y": "1",
    "owner": "hx421f624fd856517b4478b169c8849d087110ce93",
    "name": "Emerald City Land #614",
    "zoneId": "4",
    "metaId": "1302",
    "nftId": 614,
    "creationDate": "1691128561833027",
    "id": "614",
    "mintedVia": "CROWN",
  },
  {
    "x": "-75",
    "y": "17",
    "owner": "hx1e3a479a803686b1f17d04f3330a52686c9b2000",
    "name": "Emerald City Land #615",
    "zoneId": "8",
    "metaId": "6272",
    "nftId": 615,
    "creationDate": "1691128663835871",
    "id": "615",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "35",
    "owner": "hx421f624fd856517b4478b169c8849d087110ce93",
    "name": "Emerald City Land #616",
    "zoneId": "3",
    "metaId": "1057",
    "nftId": 616,
    "creationDate": "1691128677805488",
    "id": "616",
    "mintedVia": "CROWN",
  },
  {
    "x": "-2",
    "y": "32",
    "owner": "hx421f624fd856517b4478b169c8849d087110ce93",
    "name": "Emerald City Land #617",
    "zoneId": "3",
    "metaId": "993",
    "nftId": 617,
    "creationDate": "1691128677805488",
    "id": "617",
    "mintedVia": "CROWN",
  },
  {
    "x": "46",
    "y": "18",
    "owner": "hxaead0bd48f1d8b8f346f2ae5227d1d9fa580e9db",
    "name": "Emerald City Land #618",
    "zoneId": "1",
    "metaId": "8",
    "nftId": 618,
    "creationDate": "1691128691843379",
    "id": "618",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "15",
    "owner": "hx421f624fd856517b4478b169c8849d087110ce93",
    "name": "Emerald City Land #619",
    "zoneId": "7",
    "metaId": "3648",
    "nftId": 619,
    "creationDate": "1691128727829617",
    "id": "619",
    "mintedVia": "CROWN",
  },
  {
    "x": "41",
    "y": "-3",
    "owner": "hx421f624fd856517b4478b169c8849d087110ce93",
    "name": "Emerald City Land #620",
    "zoneId": "7",
    "metaId": "2616",
    "nftId": 620,
    "creationDate": "1691128727829617",
    "id": "620",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "31",
    "owner": "hx421f624fd856517b4478b169c8849d087110ce93",
    "name": "Emerald City Land #621",
    "zoneId": "7",
    "metaId": "2024",
    "nftId": 621,
    "creationDate": "1691128727829617",
    "id": "621",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "20",
    "owner": "hx421f624fd856517b4478b169c8849d087110ce93",
    "name": "Emerald City Land #622",
    "zoneId": "7",
    "metaId": "3811",
    "nftId": 622,
    "creationDate": "1691128727829617",
    "id": "622",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "5",
    "owner": "hxaead0bd48f1d8b8f346f2ae5227d1d9fa580e9db",
    "name": "Emerald City Land #623",
    "zoneId": "8",
    "metaId": "6799",
    "nftId": 623,
    "creationDate": "1691128761898069",
    "id": "623",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "8",
    "owner": "hx421f624fd856517b4478b169c8849d087110ce93",
    "name": "Emerald City Land #624",
    "zoneId": "1",
    "metaId": "416",
    "nftId": 624,
    "creationDate": "1691128795933377",
    "id": "624",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "-12",
    "owner": "hx9a9c8860b96863a4f17112ea840e0f83232d1e01",
    "name": "Emerald City Land #625",
    "zoneId": "7",
    "metaId": "2197",
    "nftId": 625,
    "creationDate": "1691128860058083",
    "id": "625",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "27",
    "owner": "hxf099d7843a7dc1ded3faf3900edfe09568d61a2b",
    "name": "Emerald City Land #626",
    "zoneId": "7",
    "metaId": "3969",
    "nftId": 626,
    "creationDate": "1691129160166411",
    "id": "626",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-21",
    "owner": "hx1a4c9e9c8c866a961e5c88c18c0f019aa97f33db",
    "name": "Emerald City Land #627",
    "zoneId": "2",
    "metaId": "852",
    "nftId": 627,
    "creationDate": "1691129190202293",
    "id": "627",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "-35",
    "owner": "hx1a4c9e9c8c866a961e5c88c18c0f019aa97f33db",
    "name": "Emerald City Land #628",
    "zoneId": "2",
    "metaId": "789",
    "nftId": 628,
    "creationDate": "1691129190202293",
    "id": "628",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "-4",
    "owner": "hxf099d7843a7dc1ded3faf3900edfe09568d61a2b",
    "name": "Emerald City Land #629",
    "zoneId": "1",
    "metaId": "422",
    "nftId": 629,
    "creationDate": "1691129254203643",
    "id": "629",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-27",
    "owner": "hxc0a2c4b422657ff499640f07606b1516e9935b9b",
    "name": "Emerald City Land #630",
    "zoneId": "2",
    "metaId": "739",
    "nftId": 630,
    "creationDate": "1691129270078637",
    "id": "630",
    "mintedVia": "CROWN",
  },
  {
    "x": "30",
    "y": "21",
    "owner": "hx1a4c9e9c8c866a961e5c88c18c0f019aa97f33db",
    "name": "Emerald City Land #631",
    "zoneId": "7",
    "metaId": "1992",
    "nftId": 631,
    "creationDate": "1691129306082229",
    "id": "631",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "19",
    "owner": "hx1a4c9e9c8c866a961e5c88c18c0f019aa97f33db",
    "name": "Emerald City Land #632",
    "zoneId": "7",
    "metaId": "4095",
    "nftId": 632,
    "creationDate": "1691129306082229",
    "id": "632",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "4",
    "owner": "hxf099d7843a7dc1ded3faf3900edfe09568d61a2b",
    "name": "Emerald City Land #633",
    "zoneId": "8",
    "metaId": "6798",
    "nftId": 633,
    "creationDate": "1691129306082229",
    "id": "633",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "3",
    "owner": "hx1a4c9e9c8c866a961e5c88c18c0f019aa97f33db",
    "name": "Emerald City Land #634",
    "zoneId": "8",
    "metaId": "5309",
    "nftId": 634,
    "creationDate": "1691129408395136",
    "id": "634",
    "mintedVia": "CROWN",
  },
  {
    "x": "-104",
    "y": "-6",
    "owner": "hx1a4c9e9c8c866a961e5c88c18c0f019aa97f33db",
    "name": "Emerald City Land #635",
    "zoneId": "8",
    "metaId": "6157",
    "nftId": 635,
    "creationDate": "1691129408395136",
    "id": "635",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "13",
    "owner": "hx1a4c9e9c8c866a961e5c88c18c0f019aa97f33db",
    "name": "Emerald City Land #636",
    "zoneId": "8",
    "metaId": "5900",
    "nftId": 636,
    "creationDate": "1691129408395136",
    "id": "636",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "14",
    "owner": "hx1a4c9e9c8c866a961e5c88c18c0f019aa97f33db",
    "name": "Emerald City Land #637",
    "zoneId": "8",
    "metaId": "5035",
    "nftId": 637,
    "creationDate": "1691129516312947",
    "id": "637",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "12",
    "owner": "hx1a4c9e9c8c866a961e5c88c18c0f019aa97f33db",
    "name": "Emerald City Land #638",
    "zoneId": "8",
    "metaId": "5331",
    "nftId": 638,
    "creationDate": "1691129516312947",
    "id": "638",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "20",
    "owner": "hx1a4c9e9c8c866a961e5c88c18c0f019aa97f33db",
    "name": "Emerald City Land #639",
    "zoneId": "8",
    "metaId": "6035",
    "nftId": 639,
    "creationDate": "1691129516312947",
    "id": "639",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "-5",
    "owner": "hx9213dfd0eba46fefff91b5ddc0ccb105a10c0f70",
    "name": "Emerald City Land #640",
    "zoneId": "7",
    "metaId": "2534",
    "nftId": 640,
    "creationDate": "1691129888413067",
    "id": "640",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "-22",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #641",
    "zoneId": "2",
    "metaId": "802",
    "nftId": 641,
    "creationDate": "1691129956302032",
    "id": "641",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-34",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #642",
    "zoneId": "2",
    "metaId": "855",
    "nftId": 642,
    "creationDate": "1691129956302032",
    "id": "642",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "-24",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #643",
    "zoneId": "2",
    "metaId": "761",
    "nftId": 643,
    "creationDate": "1691129956302032",
    "id": "643",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "10",
    "owner": "hx9213dfd0eba46fefff91b5ddc0ccb105a10c0f70",
    "name": "Emerald City Land #644",
    "zoneId": "7",
    "metaId": "3596",
    "nftId": 644,
    "creationDate": "1691130058598722",
    "id": "644",
    "mintedVia": "CROWN",
  },
  {
    "x": "54",
    "y": "8",
    "owner": "hx9213dfd0eba46fefff91b5ddc0ccb105a10c0f70",
    "name": "Emerald City Land #645",
    "zoneId": "7",
    "metaId": "2781",
    "nftId": 645,
    "creationDate": "1691130058598722",
    "id": "645",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "0",
    "owner": "hx9213dfd0eba46fefff91b5ddc0ccb105a10c0f70",
    "name": "Emerald City Land #646",
    "zoneId": "7",
    "metaId": "3286",
    "nftId": 646,
    "creationDate": "1691130058598722",
    "id": "646",
    "mintedVia": "CROWN",
  },
  {
    "x": "43",
    "y": "9",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #647",
    "zoneId": "6",
    "metaId": "1732",
    "nftId": 647,
    "creationDate": "1691130106354524",
    "id": "647",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "-18",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #648",
    "zoneId": "6",
    "metaId": "1702",
    "nftId": 648,
    "creationDate": "1691130106354524",
    "id": "648",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "26",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #649",
    "zoneId": "6",
    "metaId": "1800",
    "nftId": 649,
    "creationDate": "1691130106354524",
    "id": "649",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "-19",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #650",
    "zoneId": "6",
    "metaId": "1707",
    "nftId": 650,
    "creationDate": "1691130106354524",
    "id": "650",
    "mintedVia": "CROWN",
  },
  {
    "x": "40",
    "y": "-6",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #651",
    "zoneId": "6",
    "metaId": "1729",
    "nftId": 651,
    "creationDate": "1691130106354524",
    "id": "651",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "-13",
    "owner": "hx9213dfd0eba46fefff91b5ddc0ccb105a10c0f70",
    "name": "Emerald City Land #652",
    "zoneId": "1",
    "metaId": "74",
    "nftId": 652,
    "creationDate": "1691130134355826",
    "id": "652",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "3",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #653",
    "zoneId": "5",
    "metaId": "1648",
    "nftId": 653,
    "creationDate": "1691130234393442",
    "id": "653",
    "mintedVia": "CROWN",
  },
  {
    "x": "11",
    "y": "17",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #654",
    "zoneId": "5",
    "metaId": "1548",
    "nftId": 654,
    "creationDate": "1691130234393442",
    "id": "654",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "5",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #655",
    "zoneId": "5",
    "metaId": "1600",
    "nftId": 655,
    "creationDate": "1691130234393442",
    "id": "655",
    "mintedVia": "CROWN",
  },
  {
    "x": "-64",
    "y": "-27",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #656",
    "zoneId": "2",
    "metaId": "889",
    "nftId": 656,
    "creationDate": "1691130328426743",
    "id": "656",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "-25",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #657",
    "zoneId": "2",
    "metaId": "825",
    "nftId": 657,
    "creationDate": "1691130328426743",
    "id": "657",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "-35",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #658",
    "zoneId": "2",
    "metaId": "870",
    "nftId": 658,
    "creationDate": "1691130328426743",
    "id": "658",
    "mintedVia": "CROWN",
  },
  {
    "x": "-62",
    "y": "-28",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #659",
    "zoneId": "2",
    "metaId": "886",
    "nftId": 659,
    "creationDate": "1691130328426743",
    "id": "659",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-33",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #660",
    "zoneId": "2",
    "metaId": "854",
    "nftId": 660,
    "creationDate": "1691130328426743",
    "id": "660",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "-10",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #661",
    "zoneId": "4",
    "metaId": "1370",
    "nftId": 661,
    "creationDate": "1691130658786396",
    "id": "661",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "9",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #662",
    "zoneId": "4",
    "metaId": "1368",
    "nftId": 662,
    "creationDate": "1691130658786396",
    "id": "662",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "1",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #663",
    "zoneId": "4",
    "metaId": "1311",
    "nftId": 663,
    "creationDate": "1691130658786396",
    "id": "663",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "-6",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #664",
    "zoneId": "4",
    "metaId": "1530",
    "nftId": 664,
    "creationDate": "1691130726565018",
    "id": "664",
    "mintedVia": "CROWN",
  },
  {
    "x": "15",
    "y": "3",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #665",
    "zoneId": "4",
    "metaId": "1402",
    "nftId": 665,
    "creationDate": "1691130726565018",
    "id": "665",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "-6",
    "owner": "hx5f75e7ea5a055f6700716a2487b44261ed02dd72",
    "name": "Emerald City Land #666",
    "zoneId": "7",
    "metaId": "3028",
    "nftId": 666,
    "creationDate": "1691130776582251",
    "id": "666",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "14",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #667",
    "zoneId": "6",
    "metaId": "1788",
    "nftId": 667,
    "creationDate": "1691130800583750",
    "id": "667",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "15",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #668",
    "zoneId": "6",
    "metaId": "1804",
    "nftId": 668,
    "creationDate": "1691130800583750",
    "id": "668",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "28",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #669",
    "zoneId": "6",
    "metaId": "1692",
    "nftId": 669,
    "creationDate": "1691130800583750",
    "id": "669",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "19",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #670",
    "zoneId": "6",
    "metaId": "1783",
    "nftId": 670,
    "creationDate": "1691130800583750",
    "id": "670",
    "mintedVia": "CROWN",
  },
  {
    "x": "46",
    "y": "26",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #671",
    "zoneId": "6",
    "metaId": "1669",
    "nftId": 671,
    "creationDate": "1691130900623567",
    "id": "671",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "22",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #672",
    "zoneId": "6",
    "metaId": "1698",
    "nftId": 672,
    "creationDate": "1691130900623567",
    "id": "672",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "29",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #673",
    "zoneId": "6",
    "metaId": "1778",
    "nftId": 673,
    "creationDate": "1691130900623567",
    "id": "673",
    "mintedVia": "CROWN",
  },
  {
    "x": "-13",
    "y": "25",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #674",
    "zoneId": "6",
    "metaId": "1769",
    "nftId": 674,
    "creationDate": "1691130900623567",
    "id": "674",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "23",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #675",
    "zoneId": "6",
    "metaId": "1772",
    "nftId": 675,
    "creationDate": "1691130900623567",
    "id": "675",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "32",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #676",
    "zoneId": "3",
    "metaId": "1041",
    "nftId": 676,
    "creationDate": "1691130988818399",
    "id": "676",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "18",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #677",
    "zoneId": "3",
    "metaId": "1090",
    "nftId": 677,
    "creationDate": "1691130988818399",
    "id": "677",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "18",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #678",
    "zoneId": "3",
    "metaId": "1105",
    "nftId": 678,
    "creationDate": "1691130988818399",
    "id": "678",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "19",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #679",
    "zoneId": "3",
    "metaId": "1099",
    "nftId": 679,
    "creationDate": "1691130988818399",
    "id": "679",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "30",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #680",
    "zoneId": "3",
    "metaId": "951",
    "nftId": 680,
    "creationDate": "1691130988818399",
    "id": "680",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "19",
    "owner": "hx00f94ff34ab756985052354ba9c55eea33a9c10b",
    "name": "Emerald City Land #681",
    "zoneId": "8",
    "metaId": "5021",
    "nftId": 681,
    "creationDate": "1691131124749768",
    "id": "681",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "2",
    "owner": "hx00f94ff34ab756985052354ba9c55eea33a9c10b",
    "name": "Emerald City Land #682",
    "zoneId": "8",
    "metaId": "6591",
    "nftId": 682,
    "creationDate": "1691131124749768",
    "id": "682",
    "mintedVia": "CROWN",
  },
  {
    "x": "-89",
    "y": "9",
    "owner": "hx00f94ff34ab756985052354ba9c55eea33a9c10b",
    "name": "Emerald City Land #683",
    "zoneId": "8",
    "metaId": "6401",
    "nftId": 683,
    "creationDate": "1691131124749768",
    "id": "683",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "27",
    "owner": "hx00f94ff34ab756985052354ba9c55eea33a9c10b",
    "name": "Emerald City Land #684",
    "zoneId": "8",
    "metaId": "5183",
    "nftId": 684,
    "creationDate": "1691131164714848",
    "id": "684",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "30",
    "owner": "hx00f94ff34ab756985052354ba9c55eea33a9c10b",
    "name": "Emerald City Land #685",
    "zoneId": "8",
    "metaId": "5778",
    "nftId": 685,
    "creationDate": "1691131164714848",
    "id": "685",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "6",
    "owner": "hx00f94ff34ab756985052354ba9c55eea33a9c10b",
    "name": "Emerald City Land #686",
    "zoneId": "8",
    "metaId": "6680",
    "nftId": 686,
    "creationDate": "1691131164714848",
    "id": "686",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "34",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #687",
    "zoneId": "3",
    "metaId": "1023",
    "nftId": 687,
    "creationDate": "1691131210865720",
    "id": "687",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-19",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #688",
    "zoneId": "1",
    "metaId": "593",
    "nftId": 688,
    "creationDate": "1691131270752869",
    "id": "688",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "-13",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #689",
    "zoneId": "1",
    "metaId": "67",
    "nftId": 689,
    "creationDate": "1691131270752869",
    "id": "689",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "-24",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #690",
    "zoneId": "1",
    "metaId": "702",
    "nftId": 690,
    "creationDate": "1691131270752869",
    "id": "690",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-19",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #691",
    "zoneId": "1",
    "metaId": "596",
    "nftId": 691,
    "creationDate": "1691131270752869",
    "id": "691",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "12",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #692",
    "zoneId": "1",
    "metaId": "188",
    "nftId": 692,
    "creationDate": "1691131270752869",
    "id": "692",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "11",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #693",
    "zoneId": "7",
    "metaId": "3646",
    "nftId": 693,
    "creationDate": "1691131380781737",
    "id": "693",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "-1",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #694",
    "zoneId": "7",
    "metaId": "3953",
    "nftId": 694,
    "creationDate": "1691131380781737",
    "id": "694",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "-5",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #695",
    "zoneId": "7",
    "metaId": "2437",
    "nftId": 695,
    "creationDate": "1691131380781737",
    "id": "695",
    "mintedVia": "CROWN",
  },
  {
    "x": "0",
    "y": "24",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #696",
    "zoneId": "7",
    "metaId": "3864",
    "nftId": 696,
    "creationDate": "1691131380781737",
    "id": "696",
    "mintedVia": "CROWN",
  },
  {
    "x": "43",
    "y": "1",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #697",
    "zoneId": "7",
    "metaId": "2649",
    "nftId": 697,
    "creationDate": "1691131432799766",
    "id": "697",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "0",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #698",
    "zoneId": "7",
    "metaId": "2833",
    "nftId": 698,
    "creationDate": "1691131432799766",
    "id": "698",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "-4",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #700",
    "zoneId": "7",
    "metaId": "4594",
    "nftId": 700,
    "creationDate": "1691131432799766",
    "id": "700",
    "mintedVia": "CROWN",
  },
  {
    "x": "15",
    "y": "5",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #701",
    "zoneId": "7",
    "metaId": "3211",
    "nftId": 701,
    "creationDate": "1691131432799766",
    "id": "701",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "-33",
    "owner": "hxb7a130403368d28a2173d68b2e192bfd24a80954",
    "name": "Emerald City Land #702",
    "zoneId": "2",
    "metaId": "876",
    "nftId": 702,
    "creationDate": "1691131442906497",
    "id": "702",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-27",
    "owner": "hxb7a130403368d28a2173d68b2e192bfd24a80954",
    "name": "Emerald City Land #703",
    "zoneId": "2",
    "metaId": "839",
    "nftId": 703,
    "creationDate": "1691131541021419",
    "id": "703",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "-4",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #704",
    "zoneId": "1",
    "metaId": "353",
    "nftId": 704,
    "creationDate": "1691131548944038",
    "id": "704",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "-8",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #705",
    "zoneId": "1",
    "metaId": "521",
    "nftId": 705,
    "creationDate": "1691131548944038",
    "id": "705",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "-9",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #706",
    "zoneId": "1",
    "metaId": "342",
    "nftId": 706,
    "creationDate": "1691131548944038",
    "id": "706",
    "mintedVia": "CROWN",
  },
  {
    "x": "-99",
    "y": "-5",
    "owner": "hxb7a130403368d28a2173d68b2e192bfd24a80954",
    "name": "Emerald City Land #707",
    "zoneId": "7",
    "metaId": "2409",
    "nftId": 707,
    "creationDate": "1691131600852883",
    "id": "707",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "0",
    "owner": "hxb7a130403368d28a2173d68b2e192bfd24a80954",
    "name": "Emerald City Land #708",
    "zoneId": "7",
    "metaId": "3081",
    "nftId": 708,
    "creationDate": "1691131600852883",
    "id": "708",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "24",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #709",
    "zoneId": "8",
    "metaId": "4957",
    "nftId": 709,
    "creationDate": "1691131611015258",
    "id": "709",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "-18",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #710",
    "zoneId": "8",
    "metaId": "6877",
    "nftId": 710,
    "creationDate": "1691131611015258",
    "id": "710",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "22",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #711",
    "zoneId": "8",
    "metaId": "5728",
    "nftId": 711,
    "creationDate": "1691131611015258",
    "id": "711",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "28",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #712",
    "zoneId": "8",
    "metaId": "5425",
    "nftId": 712,
    "creationDate": "1691131611015258",
    "id": "712",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "23",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #713",
    "zoneId": "8",
    "metaId": "5027",
    "nftId": 713,
    "creationDate": "1691131611015258",
    "id": "713",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "-7",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #714",
    "zoneId": "8",
    "metaId": "5284",
    "nftId": 714,
    "creationDate": "1691131612859916",
    "id": "714",
    "mintedVia": "CROWN",
  },
  {
    "x": "17",
    "y": "22",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #715",
    "zoneId": "8",
    "metaId": "4782",
    "nftId": 715,
    "creationDate": "1691131612859916",
    "id": "715",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "-15",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #716",
    "zoneId": "8",
    "metaId": "5317",
    "nftId": 716,
    "creationDate": "1691131612859916",
    "id": "716",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "-9",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #717",
    "zoneId": "8",
    "metaId": "6926",
    "nftId": 717,
    "creationDate": "1691131612859916",
    "id": "717",
    "mintedVia": "CROWN",
  },
  {
    "x": "-10",
    "y": "11",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #718",
    "zoneId": "8",
    "metaId": "6598",
    "nftId": 718,
    "creationDate": "1691131612859916",
    "id": "718",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "-9",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #719",
    "zoneId": "7",
    "metaId": "2666",
    "nftId": 719,
    "creationDate": "1691131706892080",
    "id": "719",
    "mintedVia": "CROWN",
  },
  {
    "x": "56",
    "y": "27",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #720",
    "zoneId": "7",
    "metaId": "2099",
    "nftId": 720,
    "creationDate": "1691131706892080",
    "id": "720",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "23",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #721",
    "zoneId": "7",
    "metaId": "1952",
    "nftId": 721,
    "creationDate": "1691131706892080",
    "id": "721",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "24",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #722",
    "zoneId": "7",
    "metaId": "2058",
    "nftId": 722,
    "creationDate": "1691131706892080",
    "id": "722",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "11",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #723",
    "zoneId": "7",
    "metaId": "2016",
    "nftId": 723,
    "creationDate": "1691131706892080",
    "id": "723",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-18",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #724",
    "zoneId": "8",
    "metaId": "6850",
    "nftId": 724,
    "creationDate": "1691131709138937",
    "id": "724",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "22",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #725",
    "zoneId": "8",
    "metaId": "4975",
    "nftId": 725,
    "creationDate": "1691131709138937",
    "id": "725",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "-10",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #726",
    "zoneId": "8",
    "metaId": "6854",
    "nftId": 726,
    "creationDate": "1691131709138937",
    "id": "726",
    "mintedVia": "CROWN",
  },
  {
    "x": "23",
    "y": "19",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #727",
    "zoneId": "8",
    "metaId": "4706",
    "nftId": 727,
    "creationDate": "1691131794919617",
    "id": "727",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "11",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #728",
    "zoneId": "8",
    "metaId": "4891",
    "nftId": 728,
    "creationDate": "1691131794919617",
    "id": "728",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-4",
    "owner": "hx2ed8dc98004a534675b236746f49b87df5aae824",
    "name": "Emerald City Land #729",
    "zoneId": "8",
    "metaId": "6851",
    "nftId": 729,
    "creationDate": "1691131794919617",
    "id": "729",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "7",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #730",
    "zoneId": "7",
    "metaId": "4129",
    "nftId": 730,
    "creationDate": "1691131844931039",
    "id": "730",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "30",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #731",
    "zoneId": "7",
    "metaId": "4152",
    "nftId": 731,
    "creationDate": "1691131844931039",
    "id": "731",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-37",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #732",
    "zoneId": "7",
    "metaId": "4631",
    "nftId": 732,
    "creationDate": "1691131844931039",
    "id": "732",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "10",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #733",
    "zoneId": "7",
    "metaId": "4134",
    "nftId": 733,
    "creationDate": "1691131844931039",
    "id": "733",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "22",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #734",
    "zoneId": "7",
    "metaId": "2207",
    "nftId": 734,
    "creationDate": "1691131844931039",
    "id": "734",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "1",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #735",
    "zoneId": "7",
    "metaId": "3302",
    "nftId": 735,
    "creationDate": "1691131949067673",
    "id": "735",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "16",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #736",
    "zoneId": "7",
    "metaId": "4449",
    "nftId": 736,
    "creationDate": "1691131949067673",
    "id": "736",
    "mintedVia": "CROWN",
  },
  {
    "x": "23",
    "y": "12",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #737",
    "zoneId": "8",
    "metaId": "4792",
    "nftId": 737,
    "creationDate": "1691132175095655",
    "id": "737",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "26",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #738",
    "zoneId": "8",
    "metaId": "4765",
    "nftId": 738,
    "creationDate": "1691132175095655",
    "id": "738",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "22",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #739",
    "zoneId": "8",
    "metaId": "6670",
    "nftId": 739,
    "creationDate": "1691132175095655",
    "id": "739",
    "mintedVia": "CROWN",
  },
  {
    "x": "-105",
    "y": "-7",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #740",
    "zoneId": "8",
    "metaId": "6169",
    "nftId": 740,
    "creationDate": "1691132175095655",
    "id": "740",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "15",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #741",
    "zoneId": "8",
    "metaId": "5171",
    "nftId": 741,
    "creationDate": "1691132175095655",
    "id": "741",
    "mintedVia": "CROWN",
  },
  {
    "x": "-91",
    "y": "11",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #742",
    "zoneId": "8",
    "metaId": "6425",
    "nftId": 742,
    "creationDate": "1691132299178876",
    "id": "742",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "-16",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #743",
    "zoneId": "8",
    "metaId": "5709",
    "nftId": 743,
    "creationDate": "1691132299178876",
    "id": "743",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "20",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #744",
    "zoneId": "8",
    "metaId": "4933",
    "nftId": 744,
    "creationDate": "1691132299178876",
    "id": "744",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "1",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #745",
    "zoneId": "8",
    "metaId": "5645",
    "nftId": 745,
    "creationDate": "1691132299178876",
    "id": "745",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "28",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #746",
    "zoneId": "8",
    "metaId": "5658",
    "nftId": 746,
    "creationDate": "1691132299178876",
    "id": "746",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "-12",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #747",
    "zoneId": "8",
    "metaId": "5510",
    "nftId": 747,
    "creationDate": "1691132373337791",
    "id": "747",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "10",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #748",
    "zoneId": "8",
    "metaId": "4718",
    "nftId": 748,
    "creationDate": "1691132373337791",
    "id": "748",
    "mintedVia": "CROWN",
  },
  {
    "x": "-74",
    "y": "12",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #749",
    "zoneId": "8",
    "metaId": "6259",
    "nftId": 749,
    "creationDate": "1691132373337791",
    "id": "749",
    "mintedVia": "CROWN",
  },
  {
    "x": "-86",
    "y": "7",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #750",
    "zoneId": "8",
    "metaId": "6377",
    "nftId": 750,
    "creationDate": "1691132373337791",
    "id": "750",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "29",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #751",
    "zoneId": "8",
    "metaId": "5030",
    "nftId": 751,
    "creationDate": "1691133175422219",
    "id": "751",
    "mintedVia": "CROWN",
  },
  {
    "x": "-79",
    "y": "11",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #752",
    "zoneId": "8",
    "metaId": "6304",
    "nftId": 752,
    "creationDate": "1691133175422219",
    "id": "752",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "1",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #753",
    "zoneId": "8",
    "metaId": "5409",
    "nftId": 753,
    "creationDate": "1691133175422219",
    "id": "753",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "-11",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #754",
    "zoneId": "8",
    "metaId": "6585",
    "nftId": 754,
    "creationDate": "1691133175422219",
    "id": "754",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "17",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #755",
    "zoneId": "8",
    "metaId": "4675",
    "nftId": 755,
    "creationDate": "1691133175422219",
    "id": "755",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "-34",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #756",
    "zoneId": "2",
    "metaId": "869",
    "nftId": 756,
    "creationDate": "1691133287392939",
    "id": "756",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "-12",
    "owner": "hxf21efa4c66bbadfbb3e8d293a79309518b342a48",
    "name": "Emerald City Land #757",
    "zoneId": "7",
    "metaId": "4617",
    "nftId": 757,
    "creationDate": "1691133309670734",
    "id": "757",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "-5",
    "owner": "hxf21efa4c66bbadfbb3e8d293a79309518b342a48",
    "name": "Emerald City Land #758",
    "zoneId": "7",
    "metaId": "4430",
    "nftId": 758,
    "creationDate": "1691133309670734",
    "id": "758",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "14",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #759",
    "zoneId": "7",
    "metaId": "4187",
    "nftId": 759,
    "creationDate": "1691133473684541",
    "id": "759",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "25",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #760",
    "zoneId": "7",
    "metaId": "3925",
    "nftId": 760,
    "creationDate": "1691133473684541",
    "id": "760",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "-1",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #761",
    "zoneId": "7",
    "metaId": "2506",
    "nftId": 761,
    "creationDate": "1691133473684541",
    "id": "761",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "17",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #762",
    "zoneId": "7",
    "metaId": "4486",
    "nftId": 762,
    "creationDate": "1691133473684541",
    "id": "762",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "19",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #763",
    "zoneId": "7",
    "metaId": "1926",
    "nftId": 763,
    "creationDate": "1691133473684541",
    "id": "763",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-17",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #764",
    "zoneId": "7",
    "metaId": "4537",
    "nftId": 764,
    "creationDate": "1691133543550182",
    "id": "764",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "25",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #765",
    "zoneId": "7",
    "metaId": "4218",
    "nftId": 765,
    "creationDate": "1691133543550182",
    "id": "765",
    "mintedVia": "CROWN",
  },
  {
    "x": "-13",
    "y": "-1",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #766",
    "zoneId": "7",
    "metaId": "2891",
    "nftId": 766,
    "creationDate": "1691133543550182",
    "id": "766",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "-17",
    "owner": "hx5f75e7ea5a055f6700716a2487b44261ed02dd72",
    "name": "Emerald City Land #767",
    "zoneId": "8",
    "metaId": "5077",
    "nftId": 767,
    "creationDate": "1691133761700404",
    "id": "767",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "8",
    "owner": "hxd2d493154d2337c64adadf78f02691738370c1c5",
    "name": "Emerald City Land #768",
    "zoneId": "8",
    "metaId": "5468",
    "nftId": 768,
    "creationDate": "1691133763547015",
    "id": "768",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "20",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #769",
    "zoneId": "5",
    "metaId": "1628",
    "nftId": 769,
    "creationDate": "1691133795559429",
    "id": "769",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "-13",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #770",
    "zoneId": "4",
    "metaId": "1529",
    "nftId": 770,
    "creationDate": "1691133853588927",
    "id": "770",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "16",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #771",
    "zoneId": "4",
    "metaId": "1477",
    "nftId": 771,
    "creationDate": "1691133853588927",
    "id": "771",
    "mintedVia": "CROWN",
  },
  {
    "x": "-21",
    "y": "29",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #772",
    "zoneId": "4",
    "metaId": "1456",
    "nftId": 772,
    "creationDate": "1691133853588927",
    "id": "772",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "13",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #773",
    "zoneId": "4",
    "metaId": "1219",
    "nftId": 773,
    "creationDate": "1691133853588927",
    "id": "773",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "5",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #774",
    "zoneId": "6",
    "metaId": "1752",
    "nftId": 774,
    "creationDate": "1691133929604414",
    "id": "774",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "8",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #775",
    "zoneId": "6",
    "metaId": "1763",
    "nftId": 775,
    "creationDate": "1691133929604414",
    "id": "775",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "26",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #776",
    "zoneId": "8",
    "metaId": "4850",
    "nftId": 776,
    "creationDate": "1691134087653843",
    "id": "776",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "11",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #777",
    "zoneId": "8",
    "metaId": "6605",
    "nftId": 777,
    "creationDate": "1691134087653843",
    "id": "777",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "15",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #778",
    "zoneId": "8",
    "metaId": "5334",
    "nftId": 778,
    "creationDate": "1691134087653843",
    "id": "778",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "7",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #779",
    "zoneId": "8",
    "metaId": "5190",
    "nftId": 779,
    "creationDate": "1691134087653843",
    "id": "779",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "18",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #780",
    "zoneId": "8",
    "metaId": "5084",
    "nftId": 780,
    "creationDate": "1691134087653843",
    "id": "780",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "-19",
    "owner": "hx05d0dd049dff3baf33320efd3aefe0c21e573a66",
    "name": "Emerald City Land #781",
    "zoneId": "1",
    "metaId": "611",
    "nftId": 781,
    "creationDate": "1691134185695687",
    "id": "781",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "33",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #782",
    "zoneId": "3",
    "metaId": "1018",
    "nftId": 782,
    "creationDate": "1691134185695687",
    "id": "782",
    "mintedVia": "CROWN",
  },
  {
    "x": "-99",
    "y": "-20",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #783",
    "zoneId": "1",
    "metaId": "202",
    "nftId": 783,
    "creationDate": "1691134237745221",
    "id": "783",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "-7",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #784",
    "zoneId": "1",
    "metaId": "408",
    "nftId": 784,
    "creationDate": "1691134237745221",
    "id": "784",
    "mintedVia": "CROWN",
  },
  {
    "x": "20",
    "y": "-6",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #785",
    "zoneId": "1",
    "metaId": "390",
    "nftId": 785,
    "creationDate": "1691134237745221",
    "id": "785",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "-16",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #786",
    "zoneId": "1",
    "metaId": "686",
    "nftId": 786,
    "creationDate": "1691134237745221",
    "id": "786",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "5",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #787",
    "zoneId": "1",
    "metaId": "414",
    "nftId": 787,
    "creationDate": "1691134237745221",
    "id": "787",
    "mintedVia": "CROWN",
  },
  {
    "x": "-98",
    "y": "-24",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #788",
    "zoneId": "1",
    "metaId": "199",
    "nftId": 788,
    "creationDate": "1691134305933763",
    "id": "788",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "16",
    "owner": "hx05d0dd049dff3baf33320efd3aefe0c21e573a66",
    "name": "Emerald City Land #789",
    "zoneId": "8",
    "metaId": "5172",
    "nftId": 789,
    "creationDate": "1691134343860992",
    "id": "789",
    "mintedVia": "CROWN",
  },
  {
    "x": "14",
    "y": "24",
    "owner": "hx05d0dd049dff3baf33320efd3aefe0c21e573a66",
    "name": "Emerald City Land #790",
    "zoneId": "8",
    "metaId": "4860",
    "nftId": 790,
    "creationDate": "1691134343860992",
    "id": "790",
    "mintedVia": "CROWN",
  },
  {
    "x": "-109",
    "y": "-5",
    "owner": "hx05d0dd049dff3baf33320efd3aefe0c21e573a66",
    "name": "Emerald City Land #791",
    "zoneId": "8",
    "metaId": "6205",
    "nftId": 791,
    "creationDate": "1691134343860992",
    "id": "791",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "28",
    "owner": "hx05d0dd049dff3baf33320efd3aefe0c21e573a66",
    "name": "Emerald City Land #792",
    "zoneId": "8",
    "metaId": "5539",
    "nftId": 792,
    "creationDate": "1691134343860992",
    "id": "792",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "16",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #793",
    "zoneId": "8",
    "metaId": "4723",
    "nftId": 793,
    "creationDate": "1691134355950514",
    "id": "793",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "2",
    "owner": "hx52b793c039fd02dde34affff7654ed73467df61c",
    "name": "Emerald City Land #794",
    "zoneId": "8",
    "metaId": "6734",
    "nftId": 794,
    "creationDate": "1691134355950514",
    "id": "794",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "-6",
    "owner": "hxbe0f52adc85195dbaf041149095f9f8807827dff",
    "name": "Emerald City Land #795",
    "zoneId": "7",
    "metaId": "2812",
    "nftId": 795,
    "creationDate": "1691134415802115",
    "id": "795",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "32",
    "owner": "hxbe0f52adc85195dbaf041149095f9f8807827dff",
    "name": "Emerald City Land #796",
    "zoneId": "7",
    "metaId": "3853",
    "nftId": 796,
    "creationDate": "1691134415802115",
    "id": "796",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "14",
    "owner": "hx61d8a4f8fa06d7246798637253920026dc863966",
    "name": "Emerald City Land #797",
    "zoneId": "7",
    "metaId": "4391",
    "nftId": 797,
    "creationDate": "1691134713895683",
    "id": "797",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "3",
    "owner": "hx61d8a4f8fa06d7246798637253920026dc863966",
    "name": "Emerald City Land #798",
    "zoneId": "7",
    "metaId": "2888",
    "nftId": 798,
    "creationDate": "1691134713895683",
    "id": "798",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "23",
    "owner": "hx61d8a4f8fa06d7246798637253920026dc863966",
    "name": "Emerald City Land #799",
    "zoneId": "4",
    "metaId": "1159",
    "nftId": 799,
    "creationDate": "1691134850067889",
    "id": "799",
    "mintedVia": "CROWN",
  },
  {
    "x": "-65",
    "y": "-33",
    "owner": "hx61d8a4f8fa06d7246798637253920026dc863966",
    "name": "Emerald City Land #800",
    "zoneId": "1",
    "metaId": "725",
    "nftId": 800,
    "creationDate": "1691134883951260",
    "id": "800",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "5",
    "owner": "hx61d8a4f8fa06d7246798637253920026dc863966",
    "name": "Emerald City Land #801",
    "zoneId": "8",
    "metaId": "5851",
    "nftId": 801,
    "creationDate": "1691134920171904",
    "id": "801",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "-5",
    "owner": "hx4111d82941320117aef6c21ad8c82038c2243a7b",
    "name": "Emerald City Land #802",
    "zoneId": "7",
    "metaId": "2767",
    "nftId": 802,
    "creationDate": "1691135154098641",
    "id": "802",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-34",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #803",
    "zoneId": "2",
    "metaId": "799",
    "nftId": 803,
    "creationDate": "1691135276165368",
    "id": "803",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "9",
    "owner": "hx99c13a65edc4a54233737caad9afcd2f7051111a",
    "name": "Emerald City Land #804",
    "zoneId": "8",
    "metaId": "6725",
    "nftId": 804,
    "creationDate": "1691135328151567",
    "id": "804",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "24",
    "owner": "hx99c13a65edc4a54233737caad9afcd2f7051111a",
    "name": "Emerald City Land #805",
    "zoneId": "8",
    "metaId": "4939",
    "nftId": 805,
    "creationDate": "1691135328151567",
    "id": "805",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "-25",
    "owner": "hx99c13a65edc4a54233737caad9afcd2f7051111a",
    "name": "Emerald City Land #806",
    "zoneId": "2",
    "metaId": "791",
    "nftId": 806,
    "creationDate": "1691135420354579",
    "id": "806",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "4",
    "owner": "hx99c13a65edc4a54233737caad9afcd2f7051111a",
    "name": "Emerald City Land #807",
    "zoneId": "4",
    "metaId": "1511",
    "nftId": 807,
    "creationDate": "1691135472195138",
    "id": "807",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "4",
    "owner": "hx99c13a65edc4a54233737caad9afcd2f7051111a",
    "name": "Emerald City Land #808",
    "zoneId": "1",
    "metaId": "346",
    "nftId": 808,
    "creationDate": "1691135532219054",
    "id": "808",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "-20",
    "owner": "hx99c13a65edc4a54233737caad9afcd2f7051111a",
    "name": "Emerald City Land #809",
    "zoneId": "1",
    "metaId": "614",
    "nftId": 809,
    "creationDate": "1691135532219054",
    "id": "809",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "-4",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #810",
    "zoneId": "5",
    "metaId": "1654",
    "nftId": 810,
    "creationDate": "1691135590337474",
    "id": "810",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "14",
    "owner": "hx99c13a65edc4a54233737caad9afcd2f7051111a",
    "name": "Emerald City Land #811",
    "zoneId": "6",
    "metaId": "1812",
    "nftId": 811,
    "creationDate": "1691135590337474",
    "id": "811",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "11",
    "owner": "hx99c13a65edc4a54233737caad9afcd2f7051111a",
    "name": "Emerald City Land #812",
    "zoneId": "6",
    "metaId": "1670",
    "nftId": 812,
    "creationDate": "1691135590337474",
    "id": "812",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "11",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #813",
    "zoneId": "4",
    "metaId": "1394",
    "nftId": 813,
    "creationDate": "1691135734285090",
    "id": "813",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "13",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #814",
    "zoneId": "6",
    "metaId": "1809",
    "nftId": 814,
    "creationDate": "1691135854329175",
    "id": "814",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "36",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #815",
    "zoneId": "3",
    "metaId": "1045",
    "nftId": 815,
    "creationDate": "1691135920536957",
    "id": "815",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "-5",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #816",
    "zoneId": "1",
    "metaId": "518",
    "nftId": 816,
    "creationDate": "1691136030390197",
    "id": "816",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "3",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #817",
    "zoneId": "1",
    "metaId": "587",
    "nftId": 817,
    "creationDate": "1691136030390197",
    "id": "817",
    "mintedVia": "CROWN",
  },
  {
    "x": "27",
    "y": "-7",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #818",
    "zoneId": "1",
    "metaId": "502",
    "nftId": 818,
    "creationDate": "1691136030390197",
    "id": "818",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "-13",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #819",
    "zoneId": "1",
    "metaId": "55",
    "nftId": 819,
    "creationDate": "1691136030390197",
    "id": "819",
    "mintedVia": "CROWN",
  },
  {
    "x": "-96",
    "y": "11",
    "owner": "hx4111d82941320117aef6c21ad8c82038c2243a7b",
    "name": "Emerald City Land #820",
    "zoneId": "1",
    "metaId": "194",
    "nftId": 820,
    "creationDate": "1691136134423715",
    "id": "820",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "-25",
    "owner": "hx4111d82941320117aef6c21ad8c82038c2243a7b",
    "name": "Emerald City Land #821",
    "zoneId": "1",
    "metaId": "666",
    "nftId": 821,
    "creationDate": "1691136134423715",
    "id": "821",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-10",
    "owner": "hx4111d82941320117aef6c21ad8c82038c2243a7b",
    "name": "Emerald City Land #822",
    "zoneId": "1",
    "metaId": "317",
    "nftId": 822,
    "creationDate": "1691136134423715",
    "id": "822",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "4",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #823",
    "zoneId": "7",
    "metaId": "3141",
    "nftId": 823,
    "creationDate": "1691136164436591",
    "id": "823",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "3",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #824",
    "zoneId": "7",
    "metaId": "4241",
    "nftId": 824,
    "creationDate": "1691136164436591",
    "id": "824",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "29",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #825",
    "zoneId": "7",
    "metaId": "4049",
    "nftId": 825,
    "creationDate": "1691136164436591",
    "id": "825",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "21",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #826",
    "zoneId": "7",
    "metaId": "3678",
    "nftId": 826,
    "creationDate": "1691136164436591",
    "id": "826",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "8",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #827",
    "zoneId": "7",
    "metaId": "3313",
    "nftId": 827,
    "creationDate": "1691136164436591",
    "id": "827",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "19",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #828",
    "zoneId": "7",
    "metaId": "3842",
    "nftId": 828,
    "creationDate": "1691136266464690",
    "id": "828",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "10",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #829",
    "zoneId": "7",
    "metaId": "4031",
    "nftId": 829,
    "creationDate": "1691136266464690",
    "id": "829",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "-13",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #830",
    "zoneId": "7",
    "metaId": "2527",
    "nftId": 830,
    "creationDate": "1691136266464690",
    "id": "830",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "13",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #831",
    "zoneId": "7",
    "metaId": "3935",
    "nftId": 831,
    "creationDate": "1691136266464690",
    "id": "831",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "-15",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #832",
    "zoneId": "7",
    "metaId": "4553",
    "nftId": 832,
    "creationDate": "1691136266464690",
    "id": "832",
    "mintedVia": "CROWN",
  },
  {
    "x": "15",
    "y": "-1",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #833",
    "zoneId": "7",
    "metaId": "3205",
    "nftId": 833,
    "creationDate": "1691136368670896",
    "id": "833",
    "mintedVia": "CROWN",
  },
  {
    "x": "-4",
    "y": "18",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #834",
    "zoneId": "7",
    "metaId": "3768",
    "nftId": 834,
    "creationDate": "1691136368670896",
    "id": "834",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "5",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #835",
    "zoneId": "7",
    "metaId": "4243",
    "nftId": 835,
    "creationDate": "1691136368670896",
    "id": "835",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "8",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #836",
    "zoneId": "7",
    "metaId": "4224",
    "nftId": 836,
    "creationDate": "1691136368670896",
    "id": "836",
    "mintedVia": "CROWN",
  },
  {
    "x": "-19",
    "y": "-5",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #837",
    "zoneId": "7",
    "metaId": "2976",
    "nftId": 837,
    "creationDate": "1691136368670896",
    "id": "837",
    "mintedVia": "CROWN",
  },
  {
    "x": "-99",
    "y": "-17",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #838",
    "zoneId": "8",
    "metaId": "6574",
    "nftId": 838,
    "creationDate": "1691136492713799",
    "id": "838",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-2",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #839",
    "zoneId": "8",
    "metaId": "6742",
    "nftId": 839,
    "creationDate": "1691136492713799",
    "id": "839",
    "mintedVia": "CROWN",
  },
  {
    "x": "-98",
    "y": "-1",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #840",
    "zoneId": "8",
    "metaId": "6551",
    "nftId": 840,
    "creationDate": "1691136492713799",
    "id": "840",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "22",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #841",
    "zoneId": "8",
    "metaId": "5614",
    "nftId": 841,
    "creationDate": "1691136492713799",
    "id": "841",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "-10",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #842",
    "zoneId": "8",
    "metaId": "6838",
    "nftId": 842,
    "creationDate": "1691136492713799",
    "id": "842",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "-11",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #843",
    "zoneId": "8",
    "metaId": "5746",
    "nftId": 843,
    "creationDate": "1691136582560143",
    "id": "843",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "5",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #844",
    "zoneId": "8",
    "metaId": "5391",
    "nftId": 844,
    "creationDate": "1691136582560143",
    "id": "844",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "-9",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #845",
    "zoneId": "8",
    "metaId": "5603",
    "nftId": 845,
    "creationDate": "1691136582560143",
    "id": "845",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "8",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #846",
    "zoneId": "8",
    "metaId": "5703",
    "nftId": 846,
    "creationDate": "1691136582560143",
    "id": "846",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "15",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #847",
    "zoneId": "8",
    "metaId": "5294",
    "nftId": 847,
    "creationDate": "1691136582560143",
    "id": "847",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "17",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #848",
    "zoneId": "8",
    "metaId": "5413",
    "nftId": 848,
    "creationDate": "1691136644782320",
    "id": "848",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "1",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #849",
    "zoneId": "8",
    "metaId": "5206",
    "nftId": 849,
    "creationDate": "1691136644782320",
    "id": "849",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "-10",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #850",
    "zoneId": "8",
    "metaId": "5122",
    "nftId": 850,
    "creationDate": "1691136644782320",
    "id": "850",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "0",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #851",
    "zoneId": "8",
    "metaId": "5058",
    "nftId": 851,
    "creationDate": "1691136644782320",
    "id": "851",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "20",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #852",
    "zoneId": "8",
    "metaId": "5108",
    "nftId": 852,
    "creationDate": "1691136644782320",
    "id": "852",
    "mintedVia": "CROWN",
  },
  {
    "x": "-100",
    "y": "6",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #853",
    "zoneId": "8",
    "metaId": "6096",
    "nftId": 853,
    "creationDate": "1691136800847338",
    "id": "853",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "-6",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #854",
    "zoneId": "8",
    "metaId": "5283",
    "nftId": 854,
    "creationDate": "1691136800847338",
    "id": "854",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "24",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #855",
    "zoneId": "8",
    "metaId": "5808",
    "nftId": 855,
    "creationDate": "1691136800847338",
    "id": "855",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "23",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #856",
    "zoneId": "8",
    "metaId": "5690",
    "nftId": 856,
    "creationDate": "1691136800847338",
    "id": "856",
    "mintedVia": "CROWN",
  },
  {
    "x": "-98",
    "y": "10",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #857",
    "zoneId": "8",
    "metaId": "6562",
    "nftId": 857,
    "creationDate": "1691136800847338",
    "id": "857",
    "mintedVia": "CROWN",
  },
  {
    "x": "85",
    "y": "16",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #858",
    "zoneId": "8",
    "metaId": "5967",
    "nftId": 858,
    "creationDate": "1691136852652111",
    "id": "858",
    "mintedVia": "CROWN",
  },
  {
    "x": "-91",
    "y": "7",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #859",
    "zoneId": "8",
    "metaId": "6431",
    "nftId": 859,
    "creationDate": "1691136852652111",
    "id": "859",
    "mintedVia": "CROWN",
  },
  {
    "x": "-82",
    "y": "16",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #860",
    "zoneId": "8",
    "metaId": "6340",
    "nftId": 860,
    "creationDate": "1691136852652111",
    "id": "860",
    "mintedVia": "CROWN",
  },
  {
    "x": "-86",
    "y": "12",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #861",
    "zoneId": "8",
    "metaId": "6373",
    "nftId": 861,
    "creationDate": "1691136852652111",
    "id": "861",
    "mintedVia": "CROWN",
  },
  {
    "x": "-76",
    "y": "12",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #862",
    "zoneId": "8",
    "metaId": "6277",
    "nftId": 862,
    "creationDate": "1691136852652111",
    "id": "862",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "18",
    "owner": "hxcb6e7f02f40baef5ade7582db9df8c3b921bba35",
    "name": "Emerald City Land #863",
    "zoneId": "7",
    "metaId": "3416",
    "nftId": 863,
    "creationDate": "1691136856825473",
    "id": "863",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "1",
    "owner": "hxb38c61724a537235f9ce7b2fa1e498ce6b0bd0f9",
    "name": "Emerald City Land #864",
    "zoneId": "7",
    "metaId": "4133",
    "nftId": 864,
    "creationDate": "1691136888666127",
    "id": "864",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "27",
    "owner": "hxcb6e7f02f40baef5ade7582db9df8c3b921bba35",
    "name": "Emerald City Land #865",
    "zoneId": "4",
    "metaId": "1228",
    "nftId": 865,
    "creationDate": "1691136934675157",
    "id": "865",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "-14",
    "owner": "hxb38c61724a537235f9ce7b2fa1e498ce6b0bd0f9",
    "name": "Emerald City Land #866",
    "zoneId": "7",
    "metaId": "2489",
    "nftId": 866,
    "creationDate": "1691136934675157",
    "id": "866",
    "mintedVia": "CROWN",
  },
  {
    "x": "-92",
    "y": "6",
    "owner": "hxb38c61724a537235f9ce7b2fa1e498ce6b0bd0f9",
    "name": "Emerald City Land #867",
    "zoneId": "7",
    "metaId": "2346",
    "nftId": 867,
    "creationDate": "1691136934675157",
    "id": "867",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "5",
    "owner": "hxb38c61724a537235f9ce7b2fa1e498ce6b0bd0f9",
    "name": "Emerald City Land #868",
    "zoneId": "7",
    "metaId": "4222",
    "nftId": 868,
    "creationDate": "1691136934675157",
    "id": "868",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "-13",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #869",
    "zoneId": "8",
    "metaId": "6470",
    "nftId": 869,
    "creationDate": "1691137124934609",
    "id": "869",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "-3",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #870",
    "zoneId": "8",
    "metaId": "5240",
    "nftId": 870,
    "creationDate": "1691137124934609",
    "id": "870",
    "mintedVia": "CROWN",
  },
  {
    "x": "-70",
    "y": "20",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #871",
    "zoneId": "8",
    "metaId": "6227",
    "nftId": 871,
    "creationDate": "1691137124934609",
    "id": "871",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "9",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #872",
    "zoneId": "8",
    "metaId": "6468",
    "nftId": 872,
    "creationDate": "1691137124934609",
    "id": "872",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "-11",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #873",
    "zoneId": "8",
    "metaId": "6909",
    "nftId": 873,
    "creationDate": "1691137124934609",
    "id": "873",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "-1",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #874",
    "zoneId": "8",
    "metaId": "6654",
    "nftId": 874,
    "creationDate": "1691137196768048",
    "id": "874",
    "mintedVia": "CROWN",
  },
  {
    "x": "14",
    "y": "23",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #875",
    "zoneId": "8",
    "metaId": "4757",
    "nftId": 875,
    "creationDate": "1691137196768048",
    "id": "875",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "-6",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #876",
    "zoneId": "8",
    "metaId": "5443",
    "nftId": 876,
    "creationDate": "1691137196768048",
    "id": "876",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "6",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #877",
    "zoneId": "8",
    "metaId": "5585",
    "nftId": 877,
    "creationDate": "1691137196768048",
    "id": "877",
    "mintedVia": "CROWN",
  },
  {
    "x": "-79",
    "y": "14",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #878",
    "zoneId": "8",
    "metaId": "6307",
    "nftId": 878,
    "creationDate": "1691137196768048",
    "id": "878",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "-6",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #879",
    "zoneId": "8",
    "metaId": "6731",
    "nftId": 879,
    "creationDate": "1691137278787920",
    "id": "879",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "1",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #880",
    "zoneId": "8",
    "metaId": "6733",
    "nftId": 880,
    "creationDate": "1691137278787920",
    "id": "880",
    "mintedVia": "CROWN",
  },
  {
    "x": "-85",
    "y": "14",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #881",
    "zoneId": "8",
    "metaId": "6367",
    "nftId": 881,
    "creationDate": "1691137278787920",
    "id": "881",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "24",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #882",
    "zoneId": "8",
    "metaId": "4738",
    "nftId": 882,
    "creationDate": "1691137278787920",
    "id": "882",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "-16",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #883",
    "zoneId": "8",
    "metaId": "5398",
    "nftId": 883,
    "creationDate": "1691137278787920",
    "id": "883",
    "mintedVia": "CROWN",
  },
  {
    "x": "84",
    "y": "23",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #884",
    "zoneId": "8",
    "metaId": "5943",
    "nftId": 884,
    "creationDate": "1691137338805383",
    "id": "884",
    "mintedVia": "CROWN",
  },
  {
    "x": "-75",
    "y": "13",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #885",
    "zoneId": "8",
    "metaId": "6269",
    "nftId": 885,
    "creationDate": "1691137338805383",
    "id": "885",
    "mintedVia": "CROWN",
  },
  {
    "x": "90",
    "y": "11",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #886",
    "zoneId": "8",
    "metaId": "6072",
    "nftId": 886,
    "creationDate": "1691137338805383",
    "id": "886",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "19",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #887",
    "zoneId": "8",
    "metaId": "5141",
    "nftId": 887,
    "creationDate": "1691137338805383",
    "id": "887",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "11",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #888",
    "zoneId": "8",
    "metaId": "5993",
    "nftId": 888,
    "creationDate": "1691137338805383",
    "id": "888",
    "mintedVia": "CROWN",
  },
  {
    "x": "-101",
    "y": "2",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #889",
    "zoneId": "8",
    "metaId": "6109",
    "nftId": 889,
    "creationDate": "1691137384821421",
    "id": "889",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "11",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #890",
    "zoneId": "8",
    "metaId": "6461",
    "nftId": 890,
    "creationDate": "1691137384821421",
    "id": "890",
    "mintedVia": "CROWN",
  },
  {
    "x": "-79",
    "y": "16",
    "owner": "hx90e0ac2f7a44ff7d3eef5c25c960184080d217b5",
    "name": "Emerald City Land #891",
    "zoneId": "8",
    "metaId": "6308",
    "nftId": 891,
    "creationDate": "1691137384821421",
    "id": "891",
    "mintedVia": "CROWN",
  },
  {
    "x": "27",
    "y": "14",
    "owner": "hx7ce2a1e9588029f76b63ced075a101604d6bf7f5",
    "name": "Emerald City Land #892",
    "zoneId": "8",
    "metaId": "4881",
    "nftId": 892,
    "creationDate": "1691137396825877",
    "id": "892",
    "mintedVia": "CROWN",
  },
  {
    "x": "6",
    "y": "2",
    "owner": "hxcb6e7f02f40baef5ade7582db9df8c3b921bba35",
    "name": "Emerald City Land #893",
    "zoneId": "4",
    "metaId": "1419",
    "nftId": 893,
    "creationDate": "1691137564882349",
    "id": "893",
    "mintedVia": "CROWN",
  },
  {
    "x": "-73",
    "y": "11",
    "owner": "hx7ce2a1e9588029f76b63ced075a101604d6bf7f5",
    "name": "Emerald City Land #894",
    "zoneId": "8",
    "metaId": "6248",
    "nftId": 894,
    "creationDate": "1691137630902588",
    "id": "894",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "10",
    "owner": "hx7ce2a1e9588029f76b63ced075a101604d6bf7f5",
    "name": "Emerald City Land #895",
    "zoneId": "8",
    "metaId": "6720",
    "nftId": 895,
    "creationDate": "1691137630902588",
    "id": "895",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "20",
    "owner": "hx7ce2a1e9588029f76b63ced075a101604d6bf7f5",
    "name": "Emerald City Land #896",
    "zoneId": "8",
    "metaId": "5417",
    "nftId": 896,
    "creationDate": "1691137630902588",
    "id": "896",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "4",
    "owner": "hx7ce2a1e9588029f76b63ced075a101604d6bf7f5",
    "name": "Emerald City Land #897",
    "zoneId": "8",
    "metaId": "6011",
    "nftId": 897,
    "creationDate": "1691137630902588",
    "id": "897",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "-34",
    "owner": "hxcb6e7f02f40baef5ade7582db9df8c3b921bba35",
    "name": "Emerald City Land #898",
    "zoneId": "2",
    "metaId": "788",
    "nftId": 898,
    "creationDate": "1691138223134459",
    "id": "898",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "11",
    "owner": "hxcb6e7f02f40baef5ade7582db9df8c3b921bba35",
    "name": "Emerald City Land #899",
    "zoneId": "7",
    "metaId": "3476",
    "nftId": 899,
    "creationDate": "1691138269322852",
    "id": "899",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "20",
    "owner": "hxcb6e7f02f40baef5ade7582db9df8c3b921bba35",
    "name": "Emerald City Land #900",
    "zoneId": "4",
    "metaId": "1232",
    "nftId": 900,
    "creationDate": "1691138313233460",
    "id": "900",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "-7",
    "owner": "hxeab21f275efa1923dfd9c923eb7292d4f97df566",
    "name": "Emerald City Land #901",
    "zoneId": "5",
    "metaId": "1588",
    "nftId": 901,
    "creationDate": "1691138443402147",
    "id": "901",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "-7",
    "owner": "hxeab21f275efa1923dfd9c923eb7292d4f97df566",
    "name": "Emerald City Land #902",
    "zoneId": "1",
    "metaId": "270",
    "nftId": 902,
    "creationDate": "1691138597283904",
    "id": "902",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "1",
    "owner": "hx402c021c67b145f891199fdadb7a28eff0805600",
    "name": "Emerald City Land #903",
    "zoneId": "7",
    "metaId": "3260",
    "nftId": 903,
    "creationDate": "1691138719502064",
    "id": "903",
    "mintedVia": "CROWN",
  },
  {
    "x": "-46",
    "y": "1",
    "owner": "hx402c021c67b145f891199fdadb7a28eff0805600",
    "name": "Emerald City Land #904",
    "zoneId": "7",
    "metaId": "4293",
    "nftId": 904,
    "creationDate": "1691138719502064",
    "id": "904",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "9",
    "owner": "hx402c021c67b145f891199fdadb7a28eff0805600",
    "name": "Emerald City Land #905",
    "zoneId": "1",
    "metaId": "528",
    "nftId": 905,
    "creationDate": "1691138809355064",
    "id": "905",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "10",
    "owner": "hx402c021c67b145f891199fdadb7a28eff0805600",
    "name": "Emerald City Land #906",
    "zoneId": "1",
    "metaId": "570",
    "nftId": 906,
    "creationDate": "1691138809355064",
    "id": "906",
    "mintedVia": "CROWN",
  },
  {
    "x": "-21",
    "y": "2",
    "owner": "hx402c021c67b145f891199fdadb7a28eff0805600",
    "name": "Emerald City Land #907",
    "zoneId": "1",
    "metaId": "225",
    "nftId": 907,
    "creationDate": "1691138809355064",
    "id": "907",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "-2",
    "owner": "hxbc34295a392cef06efb39187ea9fe594af7da30b",
    "name": "Emerald City Land #908",
    "zoneId": "8",
    "metaId": "6534",
    "nftId": 908,
    "creationDate": "1691139591732899",
    "id": "908",
    "mintedVia": "CROWN",
  },
  {
    "x": "-68",
    "y": "18",
    "owner": "hx93d0c0a06b22569ac34ad7d2fb900a89b0a337bb",
    "name": "Emerald City Land #909",
    "zoneId": "7",
    "metaId": "2314",
    "nftId": 909,
    "creationDate": "1691139813724845",
    "id": "909",
    "mintedVia": "CROWN",
  },
  {
    "x": "56",
    "y": "28",
    "owner": "hx93d0c0a06b22569ac34ad7d2fb900a89b0a337bb",
    "name": "Emerald City Land #910",
    "zoneId": "7",
    "metaId": "2080",
    "nftId": 910,
    "creationDate": "1691139813724845",
    "id": "910",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "-12",
    "owner": "hxbc32fd9cb2cc3bd2d5c982b4f39a0168664e0420",
    "name": "Emerald City Land #911",
    "zoneId": "7",
    "metaId": "2470",
    "nftId": 911,
    "creationDate": "1691141072322594",
    "id": "911",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "10",
    "owner": "hxbc32fd9cb2cc3bd2d5c982b4f39a0168664e0420",
    "name": "Emerald City Land #912",
    "zoneId": "7",
    "metaId": "4364",
    "nftId": 912,
    "creationDate": "1691141072322594",
    "id": "912",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "27",
    "owner": "hxbc32fd9cb2cc3bd2d5c982b4f39a0168664e0420",
    "name": "Emerald City Land #913",
    "zoneId": "7",
    "metaId": "3560",
    "nftId": 913,
    "creationDate": "1691141072322594",
    "id": "913",
    "mintedVia": "CROWN",
  },
  {
    "x": "1",
    "y": "34",
    "owner": "hx23b4969798dcc2e922b16504426f5135639637a5",
    "name": "Emerald City Land #914",
    "zoneId": "7",
    "metaId": "3888",
    "nftId": 914,
    "creationDate": "1691141176364901",
    "id": "914",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "-9",
    "owner": "hx23b4969798dcc2e922b16504426f5135639637a5",
    "name": "Emerald City Land #915",
    "zoneId": "8",
    "metaId": "5163",
    "nftId": 915,
    "creationDate": "1691141232164110",
    "id": "915",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "-7",
    "owner": "hxbc32fd9cb2cc3bd2d5c982b4f39a0168664e0420",
    "name": "Emerald City Land #916",
    "zoneId": "1",
    "metaId": "488",
    "nftId": 916,
    "creationDate": "1691141242286588",
    "id": "916",
    "mintedVia": "CROWN",
  },
  {
    "x": "23",
    "y": "25",
    "owner": "hx23b4969798dcc2e922b16504426f5135639637a5",
    "name": "Emerald City Land #917",
    "zoneId": "1",
    "metaId": "3",
    "nftId": 917,
    "creationDate": "1691141286181420",
    "id": "917",
    "mintedVia": "CROWN",
  },
  {
    "x": "-98",
    "y": "-2",
    "owner": "hxbc32fd9cb2cc3bd2d5c982b4f39a0168664e0420",
    "name": "Emerald City Land #918",
    "zoneId": "8",
    "metaId": "6556",
    "nftId": 918,
    "creationDate": "1691141296317744",
    "id": "918",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "-24",
    "owner": "hx7502439878524ba4eb636119a283ebc9e315e52d",
    "name": "Emerald City Land #919",
    "zoneId": "2",
    "metaId": "768",
    "nftId": 919,
    "creationDate": "1691142878788004",
    "id": "919",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "26",
    "owner": "hx1dc395d8855eb38082640508d1c4fdcabd8c5059",
    "name": "Emerald City Land #920",
    "zoneId": "7",
    "metaId": "2190",
    "nftId": 920,
    "creationDate": "1691143156808416",
    "id": "920",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "23",
    "owner": "hx7502439878524ba4eb636119a283ebc9e315e52d",
    "name": "Emerald City Land #921",
    "zoneId": "7",
    "metaId": "2113",
    "nftId": 921,
    "creationDate": "1691143182816492",
    "id": "921",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "13",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #922",
    "zoneId": "7",
    "metaId": "3442",
    "nftId": 922,
    "creationDate": "1691143245056842",
    "id": "922",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "22",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #923",
    "zoneId": "7",
    "metaId": "4194",
    "nftId": 923,
    "creationDate": "1691143245056842",
    "id": "923",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "17",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #924",
    "zoneId": "7",
    "metaId": "3920",
    "nftId": 924,
    "creationDate": "1691143245056842",
    "id": "924",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "29",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #925",
    "zoneId": "7",
    "metaId": "3562",
    "nftId": 925,
    "creationDate": "1691143245056842",
    "id": "925",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "7",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #926",
    "zoneId": "7",
    "metaId": "2673",
    "nftId": 926,
    "creationDate": "1691143245056842",
    "id": "926",
    "mintedVia": "CROWN",
  },
  {
    "x": "-2",
    "y": "6",
    "owner": "hx7502439878524ba4eb636119a283ebc9e315e52d",
    "name": "Emerald City Land #927",
    "zoneId": "7",
    "metaId": "3020",
    "nftId": 927,
    "creationDate": "1691143282848381",
    "id": "927",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "15",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #928",
    "zoneId": "7",
    "metaId": "3807",
    "nftId": 928,
    "creationDate": "1691143364896728",
    "id": "928",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "7",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #929",
    "zoneId": "7",
    "metaId": "3144",
    "nftId": 929,
    "creationDate": "1691143364896728",
    "id": "929",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "3",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #930",
    "zoneId": "7",
    "metaId": "3338",
    "nftId": 930,
    "creationDate": "1691143364896728",
    "id": "930",
    "mintedVia": "CROWN",
  },
  {
    "x": "58",
    "y": "-9",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #931",
    "zoneId": "7",
    "metaId": "2822",
    "nftId": 931,
    "creationDate": "1691143364896728",
    "id": "931",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "-11",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #932",
    "zoneId": "7",
    "metaId": "2196",
    "nftId": 932,
    "creationDate": "1691143364896728",
    "id": "932",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "-23",
    "owner": "hx602d61e653c7bc72581e076ede13a18f5ab2f1b0",
    "name": "Emerald City Land #933",
    "zoneId": "1",
    "metaId": "186",
    "nftId": 933,
    "creationDate": "1691143463067339",
    "id": "933",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "-14",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #934",
    "zoneId": "7",
    "metaId": "2198",
    "nftId": 934,
    "creationDate": "1691143477141240",
    "id": "934",
    "mintedVia": "CROWN",
  },
  {
    "x": "-2",
    "y": "24",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #935",
    "zoneId": "7",
    "metaId": "3726",
    "nftId": 935,
    "creationDate": "1691143477141240",
    "id": "935",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "15",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #936",
    "zoneId": "7",
    "metaId": "3661",
    "nftId": 936,
    "creationDate": "1691143477141240",
    "id": "936",
    "mintedVia": "CROWN",
  },
  {
    "x": "9",
    "y": "8",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #937",
    "zoneId": "7",
    "metaId": "3372",
    "nftId": 937,
    "creationDate": "1691143477141240",
    "id": "937",
    "mintedVia": "CROWN",
  },
  {
    "x": "-21",
    "y": "20",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #938",
    "zoneId": "7",
    "metaId": "3591",
    "nftId": 938,
    "creationDate": "1691143477141240",
    "id": "938",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "7",
    "owner": "hx602d61e653c7bc72581e076ede13a18f5ab2f1b0",
    "name": "Emerald City Land #939",
    "zoneId": "7",
    "metaId": "4223",
    "nftId": 939,
    "creationDate": "1691143523140399",
    "id": "939",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "21",
    "owner": "hx602d61e653c7bc72581e076ede13a18f5ab2f1b0",
    "name": "Emerald City Land #940",
    "zoneId": "7",
    "metaId": "4171",
    "nftId": 940,
    "creationDate": "1691143523140399",
    "id": "940",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "14",
    "owner": "hx602d61e653c7bc72581e076ede13a18f5ab2f1b0",
    "name": "Emerald City Land #941",
    "zoneId": "7",
    "metaId": "3632",
    "nftId": 941,
    "creationDate": "1691143523140399",
    "id": "941",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "29",
    "owner": "hx7502439878524ba4eb636119a283ebc9e315e52d",
    "name": "Emerald City Land #942",
    "zoneId": "4",
    "metaId": "1469",
    "nftId": 942,
    "creationDate": "1691143529018264",
    "id": "942",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "-1",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #943",
    "zoneId": "1",
    "metaId": "73",
    "nftId": 943,
    "creationDate": "1691143556933358",
    "id": "943",
    "mintedVia": "CROWN",
  },
  {
    "x": "-70",
    "y": "13",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #944",
    "zoneId": "1",
    "metaId": "124",
    "nftId": 944,
    "creationDate": "1691143556933358",
    "id": "944",
    "mintedVia": "CROWN",
  },
  {
    "x": "-21",
    "y": "8",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #945",
    "zoneId": "1",
    "metaId": "230",
    "nftId": 945,
    "creationDate": "1691143556933358",
    "id": "945",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "-2",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #946",
    "zoneId": "1",
    "metaId": "467",
    "nftId": 946,
    "creationDate": "1691143556933358",
    "id": "946",
    "mintedVia": "CROWN",
  },
  {
    "x": "8",
    "y": "32",
    "owner": "hx7502439878524ba4eb636119a283ebc9e315e52d",
    "name": "Emerald City Land #947",
    "zoneId": "3",
    "metaId": "1119",
    "nftId": 947,
    "creationDate": "1691143624954176",
    "id": "947",
    "mintedVia": "CROWN",
  },
  {
    "x": "43",
    "y": "19",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #948",
    "zoneId": "8",
    "metaId": "4736",
    "nftId": 948,
    "creationDate": "1691143630956311",
    "id": "948",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "9",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #949",
    "zoneId": "8",
    "metaId": "5704",
    "nftId": 949,
    "creationDate": "1691143630956311",
    "id": "949",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "-5",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #950",
    "zoneId": "8",
    "metaId": "6540",
    "nftId": 950,
    "creationDate": "1691143630956311",
    "id": "950",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "-17",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #951",
    "zoneId": "8",
    "metaId": "5279",
    "nftId": 951,
    "creationDate": "1691143630956311",
    "id": "951",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "28",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #952",
    "zoneId": "8",
    "metaId": "5348",
    "nftId": 952,
    "creationDate": "1691143630956311",
    "id": "952",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "-17",
    "owner": "hx602d61e653c7bc72581e076ede13a18f5ab2f1b0",
    "name": "Emerald City Land #953",
    "zoneId": "8",
    "metaId": "5595",
    "nftId": 953,
    "creationDate": "1691143654968589",
    "id": "953",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "-7",
    "owner": "hx602d61e653c7bc72581e076ede13a18f5ab2f1b0",
    "name": "Emerald City Land #954",
    "zoneId": "8",
    "metaId": "6928",
    "nftId": 954,
    "creationDate": "1691143654968589",
    "id": "954",
    "mintedVia": "CROWN",
  },
  {
    "x": "8",
    "y": "33",
    "owner": "hx7502439878524ba4eb636119a283ebc9e315e52d",
    "name": "Emerald City Land #955",
    "zoneId": "3",
    "metaId": "1120",
    "nftId": 955,
    "creationDate": "1691143709191528",
    "id": "955",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "1",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #956",
    "zoneId": "7",
    "metaId": "3100",
    "nftId": 956,
    "creationDate": "1691143716989150",
    "id": "956",
    "mintedVia": "CROWN",
  },
  {
    "x": "14",
    "y": "18",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #957",
    "zoneId": "7",
    "metaId": "1898",
    "nftId": 957,
    "creationDate": "1691143716989150",
    "id": "957",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "28",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #958",
    "zoneId": "7",
    "metaId": "3789",
    "nftId": 958,
    "creationDate": "1691143716989150",
    "id": "958",
    "mintedVia": "CROWN",
  },
  {
    "x": "-72",
    "y": "10",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #959",
    "zoneId": "7",
    "metaId": "2319",
    "nftId": 959,
    "creationDate": "1691143716989150",
    "id": "959",
    "mintedVia": "CROWN",
  },
  {
    "x": "38",
    "y": "15",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #960",
    "zoneId": "8",
    "metaId": "4955",
    "nftId": 960,
    "creationDate": "1691143773232590",
    "id": "960",
    "mintedVia": "CROWN",
  },
  {
    "x": "-99",
    "y": "-3",
    "owner": "hx2154bd24afef425e9ba4c9fe212ab6f38f7c898e",
    "name": "Emerald City Land #961",
    "zoneId": "8",
    "metaId": "6576",
    "nftId": 961,
    "creationDate": "1691143773232590",
    "id": "961",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "25",
    "owner": "hx7502439878524ba4eb636119a283ebc9e315e52d",
    "name": "Emerald City Land #962",
    "zoneId": "1",
    "metaId": "562",
    "nftId": 962,
    "creationDate": "1691143889039423",
    "id": "962",
    "mintedVia": "CROWN",
  },
  {
    "x": "6",
    "y": "12",
    "owner": "hx9d175b0bab4f96c99094427f9ff4154e0bcfcba6",
    "name": "Emerald City Land #963",
    "zoneId": "7",
    "metaId": "3321",
    "nftId": 963,
    "creationDate": "1691144549239546",
    "id": "963",
    "mintedVia": "CROWN",
  },
  {
    "x": "23",
    "y": "20",
    "owner": "hx9d175b0bab4f96c99094427f9ff4154e0bcfcba6",
    "name": "Emerald City Land #964",
    "zoneId": "7",
    "metaId": "1833",
    "nftId": 964,
    "creationDate": "1691144549239546",
    "id": "964",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "19",
    "owner": "hx7502439878524ba4eb636119a283ebc9e315e52d",
    "name": "Emerald City Land #965",
    "zoneId": "8",
    "metaId": "5338",
    "nftId": 965,
    "creationDate": "1691145223689179",
    "id": "965",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "13",
    "owner": "hxa0503b1b453bfd1e8b582e6ee6f973f09152a6d0",
    "name": "Emerald City Land #966",
    "zoneId": "4",
    "metaId": "1390",
    "nftId": 966,
    "creationDate": "1691145369540872",
    "id": "966",
    "mintedVia": "CROWN",
  },
  {
    "x": "-21",
    "y": "-9",
    "owner": "hx6b0f294bded619aeaf81ea19edc76dc09663ab0c",
    "name": "Emerald City Land #967",
    "zoneId": "1",
    "metaId": "222",
    "nftId": 967,
    "creationDate": "1691145575581001",
    "id": "967",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-22",
    "owner": "hxdaf3b8bf5844ed3ebf104f2e8f13b4f5ceff2160",
    "name": "Emerald City Land #968",
    "zoneId": "2",
    "metaId": "736",
    "nftId": 968,
    "creationDate": "1691145791656143",
    "id": "968",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "4",
    "owner": "hxdaf3b8bf5844ed3ebf104f2e8f13b4f5ceff2160",
    "name": "Emerald City Land #969",
    "zoneId": "4",
    "metaId": "1359",
    "nftId": 969,
    "creationDate": "1691145857675771",
    "id": "969",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "3",
    "owner": "hxdaf3b8bf5844ed3ebf104f2e8f13b4f5ceff2160",
    "name": "Emerald City Land #970",
    "zoneId": "8",
    "metaId": "6735",
    "nftId": 970,
    "creationDate": "1691145891685365",
    "id": "970",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "11",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #971",
    "zoneId": "7",
    "metaId": "2330",
    "nftId": 971,
    "creationDate": "1691146769967788",
    "id": "971",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "-7",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #972",
    "zoneId": "8",
    "metaId": "6916",
    "nftId": 972,
    "creationDate": "1691146866116568",
    "id": "972",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "-15",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #973",
    "zoneId": "8",
    "metaId": "5397",
    "nftId": 973,
    "creationDate": "1691146866116568",
    "id": "973",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "10",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #974",
    "zoneId": "8",
    "metaId": "4823",
    "nftId": 974,
    "creationDate": "1691146928234076",
    "id": "974",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "9",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #975",
    "zoneId": "8",
    "metaId": "6047",
    "nftId": 975,
    "creationDate": "1691146928234076",
    "id": "975",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "-5",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #976",
    "zoneId": "8",
    "metaId": "5674",
    "nftId": 976,
    "creationDate": "1691147006046829",
    "id": "976",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "5",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #977",
    "zoneId": "8",
    "metaId": "6546",
    "nftId": 977,
    "creationDate": "1691147006046829",
    "id": "977",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "6",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #978",
    "zoneId": "8",
    "metaId": "6673",
    "nftId": 978,
    "creationDate": "1691147064283762",
    "id": "978",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "19",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #979",
    "zoneId": "8",
    "metaId": "5906",
    "nftId": 979,
    "creationDate": "1691147064283762",
    "id": "979",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "-11",
    "owner": "hxcd3f3e189cb2341402b5648073061bb8b90b19c2",
    "name": "Emerald City Land #980",
    "zoneId": "8",
    "metaId": "5631",
    "nftId": 980,
    "creationDate": "1691147096282075",
    "id": "980",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "23",
    "owner": "hxcd3f3e189cb2341402b5648073061bb8b90b19c2",
    "name": "Emerald City Land #981",
    "zoneId": "8",
    "metaId": "4902",
    "nftId": 981,
    "creationDate": "1691147096282075",
    "id": "981",
    "mintedVia": "CROWN",
  },
  {
    "x": "-75",
    "y": "19",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #982",
    "zoneId": "8",
    "metaId": "6274",
    "nftId": 982,
    "creationDate": "1691147106079442",
    "id": "982",
    "mintedVia": "CROWN",
  },
  {
    "x": "-72",
    "y": "11",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #983",
    "zoneId": "8",
    "metaId": "6238",
    "nftId": 983,
    "creationDate": "1691147106079442",
    "id": "983",
    "mintedVia": "CROWN",
  },
  {
    "x": "40",
    "y": "23",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #984",
    "zoneId": "8",
    "metaId": "5009",
    "nftId": 984,
    "creationDate": "1691147140091133",
    "id": "984",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "17",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #985",
    "zoneId": "8",
    "metaId": "4974",
    "nftId": 985,
    "creationDate": "1691147140091133",
    "id": "985",
    "mintedVia": "CROWN",
  },
  {
    "x": "-108",
    "y": "1",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #986",
    "zoneId": "8",
    "metaId": "6200",
    "nftId": 986,
    "creationDate": "1691147176102963",
    "id": "986",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "4",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #987",
    "zoneId": "8",
    "metaId": "6816",
    "nftId": 987,
    "creationDate": "1691147176102963",
    "id": "987",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "12",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #988",
    "zoneId": "8",
    "metaId": "6026",
    "nftId": 988,
    "creationDate": "1691147220117100",
    "id": "988",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "12",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #989",
    "zoneId": "8",
    "metaId": "5061",
    "nftId": 989,
    "creationDate": "1691147220117100",
    "id": "989",
    "mintedVia": "CROWN",
  },
  {
    "x": "88",
    "y": "11",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #990",
    "zoneId": "8",
    "metaId": "6049",
    "nftId": 990,
    "creationDate": "1691147252125318",
    "id": "990",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "29",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #991",
    "zoneId": "8",
    "metaId": "5149",
    "nftId": 991,
    "creationDate": "1691147252125318",
    "id": "991",
    "mintedVia": "CROWN",
  },
  {
    "x": "-105",
    "y": "-6",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #992",
    "zoneId": "8",
    "metaId": "6168",
    "nftId": 992,
    "creationDate": "1691147292143714",
    "id": "992",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "25",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #993",
    "zoneId": "8",
    "metaId": "4998",
    "nftId": 993,
    "creationDate": "1691147292143714",
    "id": "993",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "3",
    "owner": "hxf6b45a42bae0730f4e83d6be91f707b3a9328c66",
    "name": "Emerald City Land #994",
    "zoneId": "8",
    "metaId": "6010",
    "nftId": 994,
    "creationDate": "1691147334151125",
    "id": "994",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "-21",
    "owner": "hxdd68eb7e28e1c614c4cd819b8099191230c91d31",
    "name": "Emerald City Land #995",
    "zoneId": "2",
    "metaId": "809",
    "nftId": 995,
    "creationDate": "1691147800321944",
    "id": "995",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "5",
    "owner": "hxdd68eb7e28e1c614c4cd819b8099191230c91d31",
    "name": "Emerald City Land #996",
    "zoneId": "8",
    "metaId": "5625",
    "nftId": 996,
    "creationDate": "1691148028575587",
    "id": "996",
    "mintedVia": "CROWN",
  },
  {
    "x": "-96",
    "y": "-19",
    "owner": "hxdd68eb7e28e1c614c4cd819b8099191230c91d31",
    "name": "Emerald City Land #997",
    "zoneId": "8",
    "metaId": "6511",
    "nftId": 997,
    "creationDate": "1691148028575587",
    "id": "997",
    "mintedVia": "CROWN",
  },
  {
    "x": "39",
    "y": "-13",
    "owner": "hx528b8367fd6eb4f91c40b9ebb124ca0eea153117",
    "name": "Emerald City Land #998",
    "zoneId": "7",
    "metaId": "2585",
    "nftId": 998,
    "creationDate": "1691148126408853",
    "id": "998",
    "mintedVia": "CROWN",
  },
  {
    "x": "-20",
    "y": "18",
    "owner": "hx528b8367fd6eb4f91c40b9ebb124ca0eea153117",
    "name": "Emerald City Land #999",
    "zoneId": "7",
    "metaId": "3570",
    "nftId": 999,
    "creationDate": "1691148126408853",
    "id": "999",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "-2",
    "owner": "hx528b8367fd6eb4f91c40b9ebb124ca0eea153117",
    "name": "Emerald City Land #1000",
    "zoneId": "5",
    "metaId": "1646",
    "nftId": 1000,
    "creationDate": "1691148182425305",
    "id": "1000",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "-2",
    "owner": "hx528b8367fd6eb4f91c40b9ebb124ca0eea153117",
    "name": "Emerald City Land #1001",
    "zoneId": "5",
    "metaId": "1637",
    "nftId": 1001,
    "creationDate": "1691148182425305",
    "id": "1001",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "-16",
    "owner": "hx528b8367fd6eb4f91c40b9ebb124ca0eea153117",
    "name": "Emerald City Land #1002",
    "zoneId": "5",
    "metaId": "1663",
    "nftId": 1002,
    "creationDate": "1691148182425305",
    "id": "1002",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "-1",
    "owner": "hx528b8367fd6eb4f91c40b9ebb124ca0eea153117",
    "name": "Emerald City Land #1003",
    "zoneId": "4",
    "metaId": "1247",
    "nftId": 1003,
    "creationDate": "1691148234447133",
    "id": "1003",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "25",
    "owner": "hx528b8367fd6eb4f91c40b9ebb124ca0eea153117",
    "name": "Emerald City Land #1004",
    "zoneId": "6",
    "metaId": "1796",
    "nftId": 1004,
    "creationDate": "1691148270457330",
    "id": "1004",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "17",
    "owner": "hx528b8367fd6eb4f91c40b9ebb124ca0eea153117",
    "name": "Emerald City Land #1005",
    "zoneId": "6",
    "metaId": "1687",
    "nftId": 1005,
    "creationDate": "1691148270457330",
    "id": "1005",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "26",
    "owner": "hx528b8367fd6eb4f91c40b9ebb124ca0eea153117",
    "name": "Emerald City Land #1006",
    "zoneId": "6",
    "metaId": "1790",
    "nftId": 1006,
    "creationDate": "1691148270457330",
    "id": "1006",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "5",
    "owner": "hx528b8367fd6eb4f91c40b9ebb124ca0eea153117",
    "name": "Emerald City Land #1007",
    "zoneId": "1",
    "metaId": "430",
    "nftId": 1007,
    "creationDate": "1691148328678534",
    "id": "1007",
    "mintedVia": "CROWN",
  },
  {
    "x": "-46",
    "y": "-1",
    "owner": "hxbaa939936163c67795e081ec5282ee92a85c8f02",
    "name": "Emerald City Land #1008",
    "zoneId": "7",
    "metaId": "4290",
    "nftId": 1008,
    "creationDate": "1691150899071003",
    "id": "1008",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "27",
    "owner": "hxbaa939936163c67795e081ec5282ee92a85c8f02",
    "name": "Emerald City Land #1009",
    "zoneId": "7",
    "metaId": "2266",
    "nftId": 1009,
    "creationDate": "1691150899071003",
    "id": "1009",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "14",
    "owner": "hxbaa939936163c67795e081ec5282ee92a85c8f02",
    "name": "Emerald City Land #1010",
    "zoneId": "4",
    "metaId": "1514",
    "nftId": 1010,
    "creationDate": "1691150999095172",
    "id": "1010",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "-1",
    "owner": "hxbaa939936163c67795e081ec5282ee92a85c8f02",
    "name": "Emerald City Land #1011",
    "zoneId": "4",
    "metaId": "1495",
    "nftId": 1011,
    "creationDate": "1691150999095172",
    "id": "1011",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "5",
    "owner": "hxbaa939936163c67795e081ec5282ee92a85c8f02",
    "name": "Emerald City Land #1012",
    "zoneId": "1",
    "metaId": "568",
    "nftId": 1012,
    "creationDate": "1691151117224480",
    "id": "1012",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "14",
    "owner": "hxf015f787653f3d0c61d49e451e0b987bf487a86b",
    "name": "Emerald City Land #1013",
    "zoneId": "8",
    "metaId": "5293",
    "nftId": 1013,
    "creationDate": "1691151927385697",
    "id": "1013",
    "mintedVia": "CROWN",
  },
  {
    "x": "10",
    "y": "22",
    "owner": "hxf015f787653f3d0c61d49e451e0b987bf487a86b",
    "name": "Emerald City Land #1014",
    "zoneId": "8",
    "metaId": "4775",
    "nftId": 1014,
    "creationDate": "1691151927385697",
    "id": "1014",
    "mintedVia": "CROWN",
  },
  {
    "x": "-89",
    "y": "11",
    "owner": "hxf015f787653f3d0c61d49e451e0b987bf487a86b",
    "name": "Emerald City Land #1015",
    "zoneId": "8",
    "metaId": "6395",
    "nftId": 1015,
    "creationDate": "1691151927385697",
    "id": "1015",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "22",
    "owner": "hxf015f787653f3d0c61d49e451e0b987bf487a86b",
    "name": "Emerald City Land #1016",
    "zoneId": "8",
    "metaId": "4791",
    "nftId": 1016,
    "creationDate": "1691151927385697",
    "id": "1016",
    "mintedVia": "CROWN",
  },
  {
    "x": "-46",
    "y": "-21",
    "owner": "hx222e768cd4d1f6fce98d490e7818ae9fca55302e",
    "name": "Emerald City Land #1017",
    "zoneId": "2",
    "metaId": "811",
    "nftId": 1017,
    "creationDate": "1691151963383737",
    "id": "1017",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "16",
    "owner": "hx222e768cd4d1f6fce98d490e7818ae9fca55302e",
    "name": "Emerald City Land #1018",
    "zoneId": "7",
    "metaId": "3549",
    "nftId": 1018,
    "creationDate": "1691152041268159",
    "id": "1018",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "14",
    "owner": "hx222e768cd4d1f6fce98d490e7818ae9fca55302e",
    "name": "Emerald City Land #1019",
    "zoneId": "6",
    "metaId": "1792",
    "nftId": 1019,
    "creationDate": "1691152079275700",
    "id": "1019",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "10",
    "owner": "hx222e768cd4d1f6fce98d490e7818ae9fca55302e",
    "name": "Emerald City Land #1020",
    "zoneId": "6",
    "metaId": "1803",
    "nftId": 1020,
    "creationDate": "1691152127451908",
    "id": "1020",
    "mintedVia": "CROWN",
  },
  {
    "x": "-19",
    "y": "10",
    "owner": "hx222e768cd4d1f6fce98d490e7818ae9fca55302e",
    "name": "Emerald City Land #1021",
    "zoneId": "6",
    "metaId": "1780",
    "nftId": 1021,
    "creationDate": "1691152191304387",
    "id": "1021",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "-6",
    "owner": "hx222e768cd4d1f6fce98d490e7818ae9fca55302e",
    "name": "Emerald City Land #1022",
    "zoneId": "1",
    "metaId": "519",
    "nftId": 1022,
    "creationDate": "1691152239300826",
    "id": "1022",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "-12",
    "owner": "hx222e768cd4d1f6fce98d490e7818ae9fca55302e",
    "name": "Emerald City Land #1023",
    "zoneId": "8",
    "metaId": "5591",
    "nftId": 1023,
    "creationDate": "1691152275487028",
    "id": "1023",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "-24",
    "owner": "hx00d504b86586d81df048333e00a6010ff632806e",
    "name": "Emerald City Land #1024",
    "zoneId": "2",
    "metaId": "764",
    "nftId": 1024,
    "creationDate": "1691153173611733",
    "id": "1024",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "36",
    "owner": "hx00d504b86586d81df048333e00a6010ff632806e",
    "name": "Emerald City Land #1025",
    "zoneId": "4",
    "metaId": "1483",
    "nftId": 1025,
    "creationDate": "1691153243641300",
    "id": "1025",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "32",
    "owner": "hx00d504b86586d81df048333e00a6010ff632806e",
    "name": "Emerald City Land #1026",
    "zoneId": "3",
    "metaId": "1115",
    "nftId": 1026,
    "creationDate": "1691153285654080",
    "id": "1026",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "30",
    "owner": "hxaa090f833d9e24db83fcca1fb6f988910d81b2bb",
    "name": "Emerald City Land #1027",
    "zoneId": "7",
    "metaId": "1854",
    "nftId": 1027,
    "creationDate": "1691154250172279",
    "id": "1027",
    "mintedVia": "CROWN",
  },
  {
    "x": "40",
    "y": "20",
    "owner": "hxaa090f833d9e24db83fcca1fb6f988910d81b2bb",
    "name": "Emerald City Land #1028",
    "zoneId": "8",
    "metaId": "4971",
    "nftId": 1028,
    "creationDate": "1691154359990631",
    "id": "1028",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "-33",
    "owner": "hxee5c1d04f36e46fb4a9c5b0de1e0d10b03a8ed9c",
    "name": "Emerald City Land #1029",
    "zoneId": "2",
    "metaId": "775",
    "nftId": 1029,
    "creationDate": "1691155384302606",
    "id": "1029",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "6",
    "owner": "hxee5c1d04f36e46fb4a9c5b0de1e0d10b03a8ed9c",
    "name": "Emerald City Land #1030",
    "zoneId": "8",
    "metaId": "5272",
    "nftId": 1030,
    "creationDate": "1691155576563925",
    "id": "1030",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "-15",
    "owner": "hxee5c1d04f36e46fb4a9c5b0de1e0d10b03a8ed9c",
    "name": "Emerald City Land #1031",
    "zoneId": "8",
    "metaId": "5435",
    "nftId": 1031,
    "creationDate": "1691155576563925",
    "id": "1031",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "6",
    "owner": "hxee5c1d04f36e46fb4a9c5b0de1e0d10b03a8ed9c",
    "name": "Emerald City Land #1032",
    "zoneId": "8",
    "metaId": "6663",
    "nftId": 1032,
    "creationDate": "1691155576563925",
    "id": "1032",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "-19",
    "owner": "hxee5c1d04f36e46fb4a9c5b0de1e0d10b03a8ed9c",
    "name": "Emerald City Land #1033",
    "zoneId": "8",
    "metaId": "5094",
    "nftId": 1033,
    "creationDate": "1691155576563925",
    "id": "1033",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "-2",
    "owner": "hxee5c1d04f36e46fb4a9c5b0de1e0d10b03a8ed9c",
    "name": "Emerald City Land #1034",
    "zoneId": "1",
    "metaId": "160",
    "nftId": 1034,
    "creationDate": "1691155768719845",
    "id": "1034",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "1",
    "owner": "hxee5c1d04f36e46fb4a9c5b0de1e0d10b03a8ed9c",
    "name": "Emerald City Land #1035",
    "zoneId": "1",
    "metaId": "540",
    "nftId": 1035,
    "creationDate": "1691155768719845",
    "id": "1035",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "-8",
    "owner": "hxee5c1d04f36e46fb4a9c5b0de1e0d10b03a8ed9c",
    "name": "Emerald City Land #1036",
    "zoneId": "7",
    "metaId": "2912",
    "nftId": 1036,
    "creationDate": "1691156010484085",
    "id": "1036",
    "mintedVia": "CROWN",
  },
  {
    "x": "39",
    "y": "8",
    "owner": "hxee5c1d04f36e46fb4a9c5b0de1e0d10b03a8ed9c",
    "name": "Emerald City Land #1037",
    "zoneId": "7",
    "metaId": "2600",
    "nftId": 1037,
    "creationDate": "1691156010484085",
    "id": "1037",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-21",
    "owner": "hx70fe8cd0e21a0a0c75dd9d0b6bc806cd26f4e55a",
    "name": "Emerald City Land #1038",
    "zoneId": "2",
    "metaId": "836",
    "nftId": 1038,
    "creationDate": "1691156018723499",
    "id": "1038",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "4",
    "owner": "hx70fe8cd0e21a0a0c75dd9d0b6bc806cd26f4e55a",
    "name": "Emerald City Land #1039",
    "zoneId": "8",
    "metaId": "6042",
    "nftId": 1039,
    "creationDate": "1691156126725331",
    "id": "1039",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "26",
    "owner": "hx70fe8cd0e21a0a0c75dd9d0b6bc806cd26f4e55a",
    "name": "Emerald City Land #1040",
    "zoneId": "8",
    "metaId": "4930",
    "nftId": 1040,
    "creationDate": "1691156126725331",
    "id": "1040",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "7",
    "owner": "hx70fe8cd0e21a0a0c75dd9d0b6bc806cd26f4e55a",
    "name": "Emerald City Land #1041",
    "zoneId": "8",
    "metaId": "5088",
    "nftId": 1041,
    "creationDate": "1691156126725331",
    "id": "1041",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "-7",
    "owner": "hx70fe8cd0e21a0a0c75dd9d0b6bc806cd26f4e55a",
    "name": "Emerald City Land #1042",
    "zoneId": "8",
    "metaId": "5161",
    "nftId": 1042,
    "creationDate": "1691156126725331",
    "id": "1042",
    "mintedVia": "CROWN",
  },
  {
    "x": "-74",
    "y": "17",
    "owner": "hx70fe8cd0e21a0a0c75dd9d0b6bc806cd26f4e55a",
    "name": "Emerald City Land #1043",
    "zoneId": "8",
    "metaId": "6263",
    "nftId": 1043,
    "creationDate": "1691156126725331",
    "id": "1043",
    "mintedVia": "CROWN",
  },
  {
    "x": "18",
    "y": "13",
    "owner": "hx70fe8cd0e21a0a0c75dd9d0b6bc806cd26f4e55a",
    "name": "Emerald City Land #1044",
    "zoneId": "8",
    "metaId": "4761",
    "nftId": 1044,
    "creationDate": "1691156200757134",
    "id": "1044",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "7",
    "owner": "hx427b4c798c788756ac3b29bcd9ed884b0149fa6d",
    "name": "Emerald City Land #1045",
    "zoneId": "8",
    "metaId": "6014",
    "nftId": 1045,
    "creationDate": "1691157134820862",
    "id": "1045",
    "mintedVia": "CROWN",
  },
  {
    "x": "-67",
    "y": "-30",
    "owner": "hx9fd493c67714e6fcd0afade74237bca1f4110b31",
    "name": "Emerald City Land #1046",
    "zoneId": "2",
    "metaId": "901",
    "nftId": 1046,
    "creationDate": "1691157556988788",
    "id": "1046",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "0",
    "owner": "hx9fd493c67714e6fcd0afade74237bca1f4110b31",
    "name": "Emerald City Land #1047",
    "zoneId": "1",
    "metaId": "169",
    "nftId": 1047,
    "creationDate": "1691157694977036",
    "id": "1047",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "19",
    "owner": "hx5933fba534f5152ab435e02f7067e4729affa92e",
    "name": "Emerald City Land #1048",
    "zoneId": "3",
    "metaId": "1103",
    "nftId": 1048,
    "creationDate": "1691157871233960",
    "id": "1048",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "17",
    "owner": "hx9fd493c67714e6fcd0afade74237bca1f4110b31",
    "name": "Emerald City Land #1049",
    "zoneId": "7",
    "metaId": "2055",
    "nftId": 1049,
    "creationDate": "1691157965284483",
    "id": "1049",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "5",
    "owner": "hx5933fba534f5152ab435e02f7067e4729affa92e",
    "name": "Emerald City Land #1050",
    "zoneId": "6",
    "metaId": "1755",
    "nftId": 1050,
    "creationDate": "1691157981145002",
    "id": "1050",
    "mintedVia": "CROWN",
  },
  {
    "x": "38",
    "y": "13",
    "owner": "hx5933fba534f5152ab435e02f7067e4729affa92e",
    "name": "Emerald City Land #1051",
    "zoneId": "6",
    "metaId": "1666",
    "nftId": 1051,
    "creationDate": "1691158043088770",
    "id": "1051",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "-10",
    "owner": "hx5933fba534f5152ab435e02f7067e4729affa92e",
    "name": "Emerald City Land #1052",
    "zoneId": "1",
    "metaId": "514",
    "nftId": 1052,
    "creationDate": "1691158137121062",
    "id": "1052",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "8",
    "owner": "hx5933fba534f5152ab435e02f7067e4729affa92e",
    "name": "Emerald City Land #1053",
    "zoneId": "8",
    "metaId": "5885",
    "nftId": 1053,
    "creationDate": "1691158175125054",
    "id": "1053",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "6",
    "owner": "hx5933fba534f5152ab435e02f7067e4729affa92e",
    "name": "Emerald City Land #1054",
    "zoneId": "8",
    "metaId": "5816",
    "nftId": 1054,
    "creationDate": "1691158175125054",
    "id": "1054",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "-9",
    "owner": "hx5933fba534f5152ab435e02f7067e4729affa92e",
    "name": "Emerald City Land #1055",
    "zoneId": "8",
    "metaId": "6883",
    "nftId": 1055,
    "creationDate": "1691158175125054",
    "id": "1055",
    "mintedVia": "CROWN",
  },
  {
    "x": "46",
    "y": "27",
    "owner": "hx9fd493c67714e6fcd0afade74237bca1f4110b31",
    "name": "Emerald City Land #1056",
    "zoneId": "4",
    "metaId": "1225",
    "nftId": 1056,
    "creationDate": "1691158189156772",
    "id": "1056",
    "mintedVia": "CROWN",
  },
  {
    "x": "-21",
    "y": "1",
    "owner": "hx9028d6d15952b33c8c2c5b5fd35aeb877e366525",
    "name": "Emerald City Land #1057",
    "zoneId": "1",
    "metaId": "224",
    "nftId": 1057,
    "creationDate": "1691158535259410",
    "id": "1057",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "29",
    "owner": "hxac1a020ac9066406809c6ff0cff7ef7f42ff11bb",
    "name": "Emerald City Land #1058",
    "zoneId": "7",
    "metaId": "3644",
    "nftId": 1058,
    "creationDate": "1691158959411895",
    "id": "1058",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "19",
    "owner": "hx68978adeebf308551b9c7bd062ebd9d49f45e30b",
    "name": "Emerald City Land #1059",
    "zoneId": "8",
    "metaId": "5765",
    "nftId": 1059,
    "creationDate": "1691159829826661",
    "id": "1059",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "6",
    "owner": "hx68978adeebf308551b9c7bd062ebd9d49f45e30b",
    "name": "Emerald City Land #1060",
    "zoneId": "8",
    "metaId": "6738",
    "nftId": 1060,
    "creationDate": "1691159829826661",
    "id": "1060",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "15",
    "owner": "hxa7b4abb217b145e817dd120b113af88c2b938cd0",
    "name": "Emerald City Land #1061",
    "zoneId": "7",
    "metaId": "4349",
    "nftId": 1061,
    "creationDate": "1691160321982359",
    "id": "1061",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "26",
    "owner": "hxba5dc6c4bfe3da7ecf8eb443cda5977c77c2da6d",
    "name": "Emerald City Land #1062",
    "zoneId": "7",
    "metaId": "3642",
    "nftId": 1062,
    "creationDate": "1691160502045785",
    "id": "1062",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "-1",
    "owner": "hxba5dc6c4bfe3da7ecf8eb443cda5977c77c2da6d",
    "name": "Emerald City Land #1063",
    "zoneId": "1",
    "metaId": "263",
    "nftId": 1063,
    "creationDate": "1691160573881279",
    "id": "1063",
    "mintedVia": "CROWN",
  },
  {
    "x": "-73",
    "y": "16",
    "owner": "hxa7b4abb217b145e817dd120b113af88c2b938cd0",
    "name": "Emerald City Land #1064",
    "zoneId": "8",
    "metaId": "6253",
    "nftId": 1064,
    "creationDate": "1691160575878626",
    "id": "1064",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "-11",
    "owner": "hxa7b4abb217b145e817dd120b113af88c2b938cd0",
    "name": "Emerald City Land #1065",
    "zoneId": "8",
    "metaId": "6855",
    "nftId": 1065,
    "creationDate": "1691160575878626",
    "id": "1065",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "18",
    "owner": "hxa7b4abb217b145e817dd120b113af88c2b938cd0",
    "name": "Emerald City Land #1066",
    "zoneId": "8",
    "metaId": "5568",
    "nftId": 1066,
    "creationDate": "1691160575878626",
    "id": "1066",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "25",
    "owner": "hxa7b4abb217b145e817dd120b113af88c2b938cd0",
    "name": "Emerald City Land #1067",
    "zoneId": "8",
    "metaId": "6652",
    "nftId": 1067,
    "creationDate": "1691160575878626",
    "id": "1067",
    "mintedVia": "CROWN",
  },
  {
    "x": "-96",
    "y": "3",
    "owner": "hxba5dc6c4bfe3da7ecf8eb443cda5977c77c2da6d",
    "name": "Emerald City Land #1068",
    "zoneId": "8",
    "metaId": "6522",
    "nftId": 1068,
    "creationDate": "1691160653939031",
    "id": "1068",
    "mintedVia": "CROWN",
  },
  {
    "x": "-96",
    "y": "-12",
    "owner": "hxa7b4abb217b145e817dd120b113af88c2b938cd0",
    "name": "Emerald City Land #1069",
    "zoneId": "4",
    "metaId": "1260",
    "nftId": 1069,
    "creationDate": "1691160745935595",
    "id": "1069",
    "mintedVia": "CROWN",
  },
  {
    "x": "3",
    "y": "28",
    "owner": "hxa7b4abb217b145e817dd120b113af88c2b938cd0",
    "name": "Emerald City Land #1070",
    "zoneId": "4",
    "metaId": "1486",
    "nftId": 1070,
    "creationDate": "1691160745935595",
    "id": "1070",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "-9",
    "owner": "hxa7b4abb217b145e817dd120b113af88c2b938cd0",
    "name": "Emerald City Land #1071",
    "zoneId": "4",
    "metaId": "1237",
    "nftId": 1071,
    "creationDate": "1691160745935595",
    "id": "1071",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "18",
    "owner": "hxa7b4abb217b145e817dd120b113af88c2b938cd0",
    "name": "Emerald City Land #1072",
    "zoneId": "1",
    "metaId": "24",
    "nftId": 1072,
    "creationDate": "1691160916232451",
    "id": "1072",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "30",
    "owner": "hxa7b4abb217b145e817dd120b113af88c2b938cd0",
    "name": "Emerald City Land #1073",
    "zoneId": "3",
    "metaId": "913",
    "nftId": 1073,
    "creationDate": "1691160968014503",
    "id": "1073",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "11",
    "owner": "hxa7b4abb217b145e817dd120b113af88c2b938cd0",
    "name": "Emerald City Land #1074",
    "zoneId": "6",
    "metaId": "1811",
    "nftId": 1074,
    "creationDate": "1691161024055696",
    "id": "1074",
    "mintedVia": "CROWN",
  },
  {
    "x": "-83",
    "y": "6",
    "owner": "hx28949c39899e066146b5aad8db09b54fbff1611d",
    "name": "Emerald City Land #1075",
    "zoneId": "1",
    "metaId": "142",
    "nftId": 1075,
    "creationDate": "1691161456183360",
    "id": "1075",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "-20",
    "owner": "hx28949c39899e066146b5aad8db09b54fbff1611d",
    "name": "Emerald City Land #1076",
    "zoneId": "1",
    "metaId": "641",
    "nftId": 1076,
    "creationDate": "1691161456183360",
    "id": "1076",
    "mintedVia": "CROWN",
  },
  {
    "x": "24",
    "y": "2",
    "owner": "hx28949c39899e066146b5aad8db09b54fbff1611d",
    "name": "Emerald City Land #1077",
    "zoneId": "1",
    "metaId": "459",
    "nftId": 1077,
    "creationDate": "1691161456183360",
    "id": "1077",
    "mintedVia": "CROWN",
  },
  {
    "x": "56",
    "y": "16",
    "owner": "hx28949c39899e066146b5aad8db09b54fbff1611d",
    "name": "Emerald City Land #1078",
    "zoneId": "7",
    "metaId": "2020",
    "nftId": 1078,
    "creationDate": "1691161564225108",
    "id": "1078",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "3",
    "owner": "hx28949c39899e066146b5aad8db09b54fbff1611d",
    "name": "Emerald City Land #1079",
    "zoneId": "7",
    "metaId": "3140",
    "nftId": 1079,
    "creationDate": "1691161564225108",
    "id": "1079",
    "mintedVia": "CROWN",
  },
  {
    "x": "-70",
    "y": "-27",
    "owner": "hx541350746e2319b1c54fd929575cd280552b4f9b",
    "name": "Emerald City Land #1080",
    "zoneId": "2",
    "metaId": "911",
    "nftId": 1080,
    "creationDate": "1691161570385931",
    "id": "1080",
    "mintedVia": "CROWN",
  },
  {
    "x": "-2",
    "y": "36",
    "owner": "hx28949c39899e066146b5aad8db09b54fbff1611d",
    "name": "Emerald City Land #1081",
    "zoneId": "3",
    "metaId": "996",
    "nftId": 1081,
    "creationDate": "1691161618241813",
    "id": "1081",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "4",
    "owner": "hx01d3cab77693eb2a646ba77309e6efc3b630a882",
    "name": "Emerald City Land #1082",
    "zoneId": "7",
    "metaId": "4320",
    "nftId": 1082,
    "creationDate": "1691164761308415",
    "id": "1082",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "-3",
    "owner": "hx01d3cab77693eb2a646ba77309e6efc3b630a882",
    "name": "Emerald City Land #1083",
    "zoneId": "7",
    "metaId": "4614",
    "nftId": 1083,
    "creationDate": "1691164761308415",
    "id": "1083",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "26",
    "owner": "hx01d3cab77693eb2a646ba77309e6efc3b630a882",
    "name": "Emerald City Land #1084",
    "zoneId": "7",
    "metaId": "3607",
    "nftId": 1084,
    "creationDate": "1691164761308415",
    "id": "1084",
    "mintedVia": "CROWN",
  },
  {
    "x": "1",
    "y": "23",
    "owner": "hx01d3cab77693eb2a646ba77309e6efc3b630a882",
    "name": "Emerald City Land #1085",
    "zoneId": "7",
    "metaId": "3881",
    "nftId": 1085,
    "creationDate": "1691164761308415",
    "id": "1085",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "7",
    "owner": "hx01d3cab77693eb2a646ba77309e6efc3b630a882",
    "name": "Emerald City Land #1086",
    "zoneId": "4",
    "metaId": "1296",
    "nftId": 1086,
    "creationDate": "1691164871516593",
    "id": "1086",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "25",
    "owner": "hx01d3cab77693eb2a646ba77309e6efc3b630a882",
    "name": "Emerald City Land #1087",
    "zoneId": "4",
    "metaId": "1522",
    "nftId": 1087,
    "creationDate": "1691164871516593",
    "id": "1087",
    "mintedVia": "CROWN",
  },
  {
    "x": "10",
    "y": "36",
    "owner": "hx01d3cab77693eb2a646ba77309e6efc3b630a882",
    "name": "Emerald City Land #1088",
    "zoneId": "3",
    "metaId": "923",
    "nftId": 1088,
    "creationDate": "1691164947366611",
    "id": "1088",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "30",
    "owner": "hx01d3cab77693eb2a646ba77309e6efc3b630a882",
    "name": "Emerald City Land #1089",
    "zoneId": "3",
    "metaId": "1067",
    "nftId": 1089,
    "creationDate": "1691164947366611",
    "id": "1089",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "-9",
    "owner": "hx01d3cab77693eb2a646ba77309e6efc3b630a882",
    "name": "Emerald City Land #1090",
    "zoneId": "1",
    "metaId": "615",
    "nftId": 1090,
    "creationDate": "1691164997379330",
    "id": "1090",
    "mintedVia": "CROWN",
  },
  {
    "x": "-78",
    "y": "17",
    "owner": "hx01d3cab77693eb2a646ba77309e6efc3b630a882",
    "name": "Emerald City Land #1091",
    "zoneId": "8",
    "metaId": "6300",
    "nftId": 1091,
    "creationDate": "1691165035594362",
    "id": "1091",
    "mintedVia": "CROWN",
  },
  {
    "x": "43",
    "y": "29",
    "owner": "hx01d3cab77693eb2a646ba77309e6efc3b630a882",
    "name": "Emerald City Land #1092",
    "zoneId": "8",
    "metaId": "4959",
    "nftId": 1092,
    "creationDate": "1691165035594362",
    "id": "1092",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "26",
    "owner": "hxdce8596e665e1c065dd410b45d14f4a32841bea4",
    "name": "Emerald City Land #1093",
    "zoneId": "4",
    "metaId": "1205",
    "nftId": 1093,
    "creationDate": "1691169864777262",
    "id": "1093",
    "mintedVia": "CROWN",
  },
  {
    "x": "-4",
    "y": "6",
    "owner": "hx541350746e2319b1c54fd929575cd280552b4f9b",
    "name": "Emerald City Land #1094",
    "zoneId": "7",
    "metaId": "3049",
    "nftId": 1094,
    "creationDate": "1691171037372039",
    "id": "1094",
    "mintedVia": "CROWN",
  },
  {
    "x": "54",
    "y": "29",
    "owner": "hx3728cb69944624132c4b98c6558deee800558bb1",
    "name": "Emerald City Land #1095",
    "zoneId": "8",
    "metaId": "4749",
    "nftId": 1095,
    "creationDate": "1691172735030065",
    "id": "1095",
    "mintedVia": "CROWN",
  },
  {
    "x": "32",
    "y": "-8",
    "owner": "hx1f96a3befc7b47491a80591d3faa1101cc047f37",
    "name": "Emerald City Land #1096",
    "zoneId": "7",
    "metaId": "2458",
    "nftId": 1096,
    "creationDate": "1691172981085629",
    "id": "1096",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "-20",
    "owner": "hx1f96a3befc7b47491a80591d3faa1101cc047f37",
    "name": "Emerald City Land #1097",
    "zoneId": "1",
    "metaId": "161",
    "nftId": 1097,
    "creationDate": "1691173069210751",
    "id": "1097",
    "mintedVia": "CROWN",
  },
  {
    "x": "-23",
    "y": "-5",
    "owner": "hx1f96a3befc7b47491a80591d3faa1101cc047f37",
    "name": "Emerald City Land #1098",
    "zoneId": "1",
    "metaId": "251",
    "nftId": 1098,
    "creationDate": "1691173069210751",
    "id": "1098",
    "mintedVia": "CROWN",
  },
  {
    "x": "-106",
    "y": "-2",
    "owner": "hx1f96a3befc7b47491a80591d3faa1101cc047f37",
    "name": "Emerald City Land #1099",
    "zoneId": "8",
    "metaId": "6177",
    "nftId": 1099,
    "creationDate": "1691173163175298",
    "id": "1099",
    "mintedVia": "CROWN",
  },
  {
    "x": "-84",
    "y": "12",
    "owner": "hx1f96a3befc7b47491a80591d3faa1101cc047f37",
    "name": "Emerald City Land #1100",
    "zoneId": "8",
    "metaId": "6356",
    "nftId": 1100,
    "creationDate": "1691173163175298",
    "id": "1100",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "18",
    "owner": "hx3d17bdfc86e59755e9a3f103c690ae1cf0e43b3a",
    "name": "Emerald City Land #1101",
    "zoneId": "8",
    "metaId": "5414",
    "nftId": 1101,
    "creationDate": "1691173325419871",
    "id": "1101",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "-22",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #1102",
    "zoneId": "2",
    "metaId": "784",
    "nftId": 1102,
    "creationDate": "1691176406288226",
    "id": "1102",
    "mintedVia": "CROWN",
  },
  {
    "x": "-63",
    "y": "-27",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #1103",
    "zoneId": "7",
    "metaId": "4661",
    "nftId": 1103,
    "creationDate": "1691176512329665",
    "id": "1103",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "10",
    "owner": "hxcd245607b9d8525830e773b56b21e7aab133aec4",
    "name": "Emerald City Land #1104",
    "zoneId": "7",
    "metaId": "2073",
    "nftId": 1104,
    "creationDate": "1691177914781246",
    "id": "1104",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "9",
    "owner": "hxcd245607b9d8525830e773b56b21e7aab133aec4",
    "name": "Emerald City Land #1105",
    "zoneId": "7",
    "metaId": "2523",
    "nftId": 1105,
    "creationDate": "1691177914781246",
    "id": "1105",
    "mintedVia": "CROWN",
  },
  {
    "x": "17",
    "y": "29",
    "owner": "hx5f75e7ea5a055f6700716a2487b44261ed02dd72",
    "name": "Emerald City Land #1106",
    "zoneId": "8",
    "metaId": "4784",
    "nftId": 1106,
    "creationDate": "1691179677226581",
    "id": "1106",
    "mintedVia": "CROWN",
  },
  {
    "x": "11",
    "y": "-2",
    "owner": "hx9241689e4a9260c21bd59380964881ab8cbe2e5a",
    "name": "Emerald City Land #1107",
    "zoneId": "7",
    "metaId": "3170",
    "nftId": 1107,
    "creationDate": "1691180403487292",
    "id": "1107",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "-2",
    "owner": "hx9241689e4a9260c21bd59380964881ab8cbe2e5a",
    "name": "Emerald City Land #1108",
    "zoneId": "7",
    "metaId": "3055",
    "nftId": 1108,
    "creationDate": "1691180555532289",
    "id": "1108",
    "mintedVia": "CROWN",
  },
  {
    "x": "85",
    "y": "-9",
    "owner": "hx9241689e4a9260c21bd59380964881ab8cbe2e5a",
    "name": "Emerald City Land #1109",
    "zoneId": "7",
    "metaId": "2273",
    "nftId": 1109,
    "creationDate": "1691180555532289",
    "id": "1109",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "27",
    "owner": "hx9241689e4a9260c21bd59380964881ab8cbe2e5a",
    "name": "Emerald City Land #1110",
    "zoneId": "7",
    "metaId": "3748",
    "nftId": 1110,
    "creationDate": "1691180555532289",
    "id": "1110",
    "mintedVia": "CROWN",
  },
  {
    "x": "12",
    "y": "13",
    "owner": "hx9241689e4a9260c21bd59380964881ab8cbe2e5a",
    "name": "Emerald City Land #1111",
    "zoneId": "7",
    "metaId": "1828",
    "nftId": 1111,
    "creationDate": "1691180555532289",
    "id": "1111",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "24",
    "owner": "hx9241689e4a9260c21bd59380964881ab8cbe2e5a",
    "name": "Emerald City Land #1112",
    "zoneId": "8",
    "metaId": "5263",
    "nftId": 1112,
    "creationDate": "1691180675747529",
    "id": "1112",
    "mintedVia": "CROWN",
  },
  {
    "x": "-101",
    "y": "1",
    "owner": "hx9241689e4a9260c21bd59380964881ab8cbe2e5a",
    "name": "Emerald City Land #1113",
    "zoneId": "8",
    "metaId": "6108",
    "nftId": 1113,
    "creationDate": "1691180675747529",
    "id": "1113",
    "mintedVia": "CROWN",
  },
  {
    "x": "-77",
    "y": "19",
    "owner": "hx9241689e4a9260c21bd59380964881ab8cbe2e5a",
    "name": "Emerald City Land #1114",
    "zoneId": "8",
    "metaId": "6293",
    "nftId": 1114,
    "creationDate": "1691180675747529",
    "id": "1114",
    "mintedVia": "CROWN",
  },
  {
    "x": "90",
    "y": "9",
    "owner": "hx9241689e4a9260c21bd59380964881ab8cbe2e5a",
    "name": "Emerald City Land #1115",
    "zoneId": "8",
    "metaId": "6082",
    "nftId": 1115,
    "creationDate": "1691180675747529",
    "id": "1115",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "-22",
    "owner": "hx9241689e4a9260c21bd59380964881ab8cbe2e5a",
    "name": "Emerald City Land #1116",
    "zoneId": "8",
    "metaId": "6475",
    "nftId": 1116,
    "creationDate": "1691180675747529",
    "id": "1116",
    "mintedVia": "CROWN",
  },
  {
    "x": "89",
    "y": "9",
    "owner": "hx9241689e4a9260c21bd59380964881ab8cbe2e5a",
    "name": "Emerald City Land #1117",
    "zoneId": "8",
    "metaId": "6070",
    "nftId": 1117,
    "creationDate": "1691180751577050",
    "id": "1117",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "-5",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1118",
    "zoneId": "7",
    "metaId": "2653",
    "nftId": 1118,
    "creationDate": "1691180943790379",
    "id": "1118",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "29",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1119",
    "zoneId": "7",
    "metaId": "2070",
    "nftId": 1119,
    "creationDate": "1691180943790379",
    "id": "1119",
    "mintedVia": "CROWN",
  },
  {
    "x": "-86",
    "y": "11",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1120",
    "zoneId": "7",
    "metaId": "2328",
    "nftId": 1120,
    "creationDate": "1691180943790379",
    "id": "1120",
    "mintedVia": "CROWN",
  },
  {
    "x": "-18",
    "y": "2",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1121",
    "zoneId": "7",
    "metaId": "2969",
    "nftId": 1121,
    "creationDate": "1691180943790379",
    "id": "1121",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "5",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1122",
    "zoneId": "7",
    "metaId": "2636",
    "nftId": 1122,
    "creationDate": "1691180943790379",
    "id": "1122",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "32",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1123",
    "zoneId": "3",
    "metaId": "998",
    "nftId": 1123,
    "creationDate": "1691181063667666",
    "id": "1123",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "-12",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1124",
    "zoneId": "1",
    "metaId": "714",
    "nftId": 1124,
    "creationDate": "1691181375968224",
    "id": "1124",
    "mintedVia": "CROWN",
  },
  {
    "x": "20",
    "y": "1",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1125",
    "zoneId": "1",
    "metaId": "394",
    "nftId": 1125,
    "creationDate": "1691181375968224",
    "id": "1125",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "-10",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1126",
    "zoneId": "1",
    "metaId": "232",
    "nftId": 1126,
    "creationDate": "1691181375968224",
    "id": "1126",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "-4",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1127",
    "zoneId": "1",
    "metaId": "163",
    "nftId": 1127,
    "creationDate": "1691181375968224",
    "id": "1127",
    "mintedVia": "CROWN",
  },
  {
    "x": "12",
    "y": "28",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1128",
    "zoneId": "8",
    "metaId": "4755",
    "nftId": 1128,
    "creationDate": "1691181455828308",
    "id": "1128",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "-6",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1129",
    "zoneId": "8",
    "metaId": "6020",
    "nftId": 1129,
    "creationDate": "1691181455828308",
    "id": "1129",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "10",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1130",
    "zoneId": "8",
    "metaId": "5486",
    "nftId": 1130,
    "creationDate": "1691181455828308",
    "id": "1130",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "-4",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1131",
    "zoneId": "8",
    "metaId": "5858",
    "nftId": 1131,
    "creationDate": "1691181455828308",
    "id": "1131",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "-10",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1132",
    "zoneId": "8",
    "metaId": "6874",
    "nftId": 1132,
    "creationDate": "1691181455828308",
    "id": "1132",
    "mintedVia": "CROWN",
  },
  {
    "x": "39",
    "y": "27",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1133",
    "zoneId": "8",
    "metaId": "4956",
    "nftId": 1133,
    "creationDate": "1691181682021179",
    "id": "1133",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "21",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1134",
    "zoneId": "8",
    "metaId": "5041",
    "nftId": 1134,
    "creationDate": "1691181682021179",
    "id": "1134",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "23",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1135",
    "zoneId": "8",
    "metaId": "5653",
    "nftId": 1135,
    "creationDate": "1691181682021179",
    "id": "1135",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "-5",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1136",
    "zoneId": "8",
    "metaId": "5890",
    "nftId": 1136,
    "creationDate": "1691181682021179",
    "id": "1136",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "16",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1137",
    "zoneId": "8",
    "metaId": "5869",
    "nftId": 1137,
    "creationDate": "1691181682021179",
    "id": "1137",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "-12",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1138",
    "zoneId": "8",
    "metaId": "6862",
    "nftId": 1138,
    "creationDate": "1691181769898715",
    "id": "1138",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "-4",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1139",
    "zoneId": "8",
    "metaId": "5361",
    "nftId": 1139,
    "creationDate": "1691181769898715",
    "id": "1139",
    "mintedVia": "CROWN",
  },
  {
    "x": "-68",
    "y": "-27",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1140",
    "zoneId": "2",
    "metaId": "903",
    "nftId": 1140,
    "creationDate": "1691188378299239",
    "id": "1140",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "-22",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1141",
    "zoneId": "2",
    "metaId": "877",
    "nftId": 1141,
    "creationDate": "1691188378299239",
    "id": "1141",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "-33",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1142",
    "zoneId": "2",
    "metaId": "848",
    "nftId": 1142,
    "creationDate": "1691188378299239",
    "id": "1142",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-32",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1143",
    "zoneId": "2",
    "metaId": "840",
    "nftId": 1143,
    "creationDate": "1691188378299239",
    "id": "1143",
    "mintedVia": "CROWN",
  },
  {
    "x": "-62",
    "y": "-32",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1144",
    "zoneId": "2",
    "metaId": "887",
    "nftId": 1144,
    "creationDate": "1691188378299239",
    "id": "1144",
    "mintedVia": "CROWN",
  },
  {
    "x": "-69",
    "y": "-30",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1145",
    "zoneId": "2",
    "metaId": "910",
    "nftId": 1145,
    "creationDate": "1691188500115788",
    "id": "1145",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-33",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1146",
    "zoneId": "2",
    "metaId": "798",
    "nftId": 1146,
    "creationDate": "1691188500115788",
    "id": "1146",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "-36",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1147",
    "zoneId": "2",
    "metaId": "807",
    "nftId": 1147,
    "creationDate": "1691188500115788",
    "id": "1147",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "-24",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1148",
    "zoneId": "2",
    "metaId": "758",
    "nftId": 1148,
    "creationDate": "1691188500115788",
    "id": "1148",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "-36",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1149",
    "zoneId": "2",
    "metaId": "790",
    "nftId": 1149,
    "creationDate": "1691188500115788",
    "id": "1149",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-22",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1150",
    "zoneId": "2",
    "metaId": "744",
    "nftId": 1150,
    "creationDate": "1691188582298005",
    "id": "1150",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "-24",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1151",
    "zoneId": "2",
    "metaId": "785",
    "nftId": 1151,
    "creationDate": "1691188582298005",
    "id": "1151",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "-33",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1152",
    "zoneId": "2",
    "metaId": "868",
    "nftId": 1152,
    "creationDate": "1691188582298005",
    "id": "1152",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-26",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1153",
    "zoneId": "2",
    "metaId": "738",
    "nftId": 1153,
    "creationDate": "1691188582298005",
    "id": "1153",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "-30",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1154",
    "zoneId": "2",
    "metaId": "872",
    "nftId": 1154,
    "creationDate": "1691188582298005",
    "id": "1154",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "-1",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1155",
    "zoneId": "7",
    "metaId": "4268",
    "nftId": 1155,
    "creationDate": "1691188672174873",
    "id": "1155",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "4",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1156",
    "zoneId": "7",
    "metaId": "3309",
    "nftId": 1156,
    "creationDate": "1691188672174873",
    "id": "1156",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "11",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1157",
    "zoneId": "7",
    "metaId": "3262",
    "nftId": 1157,
    "creationDate": "1691188672174873",
    "id": "1157",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "-8",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1158",
    "zoneId": "7",
    "metaId": "3117",
    "nftId": 1158,
    "creationDate": "1691188672174873",
    "id": "1158",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "-14",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1159",
    "zoneId": "7",
    "metaId": "4567",
    "nftId": 1159,
    "creationDate": "1691188672174873",
    "id": "1159",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "29",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1160",
    "zoneId": "7",
    "metaId": "4104",
    "nftId": 1160,
    "creationDate": "1691188740388242",
    "id": "1160",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "-9",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1161",
    "zoneId": "7",
    "metaId": "2720",
    "nftId": 1161,
    "creationDate": "1691188740388242",
    "id": "1161",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "10",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1162",
    "zoneId": "7",
    "metaId": "4345",
    "nftId": 1162,
    "creationDate": "1691188740388242",
    "id": "1162",
    "mintedVia": "CROWN",
  },
  {
    "x": "-19",
    "y": "17",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1163",
    "zoneId": "7",
    "metaId": "3531",
    "nftId": 1163,
    "creationDate": "1691188740388242",
    "id": "1163",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "-10",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1164",
    "zoneId": "7",
    "metaId": "2876",
    "nftId": 1164,
    "creationDate": "1691188740388242",
    "id": "1164",
    "mintedVia": "CROWN",
  },
  {
    "x": "43",
    "y": "-3",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1165",
    "zoneId": "7",
    "metaId": "2641",
    "nftId": 1165,
    "creationDate": "1691188800216723",
    "id": "1165",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-31",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1166",
    "zoneId": "7",
    "metaId": "4539",
    "nftId": 1166,
    "creationDate": "1691188800216723",
    "id": "1166",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "-4",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1167",
    "zoneId": "7",
    "metaId": "2551",
    "nftId": 1167,
    "creationDate": "1691188800216723",
    "id": "1167",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "-10",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1168",
    "zoneId": "7",
    "metaId": "4596",
    "nftId": 1168,
    "creationDate": "1691188800216723",
    "id": "1168",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "26",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1169",
    "zoneId": "7",
    "metaId": "4074",
    "nftId": 1169,
    "creationDate": "1691188800216723",
    "id": "1169",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "-1",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1170",
    "zoneId": "7",
    "metaId": "4181",
    "nftId": 1170,
    "creationDate": "1691188874242084",
    "id": "1170",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "24",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1171",
    "zoneId": "7",
    "metaId": "1936",
    "nftId": 1171,
    "creationDate": "1691188874242084",
    "id": "1171",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "-6",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1172",
    "zoneId": "7",
    "metaId": "2949",
    "nftId": 1172,
    "creationDate": "1691188874242084",
    "id": "1172",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "28",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1173",
    "zoneId": "7",
    "metaId": "4018",
    "nftId": 1173,
    "creationDate": "1691188874242084",
    "id": "1173",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "10",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1174",
    "zoneId": "7",
    "metaId": "3032",
    "nftId": 1174,
    "creationDate": "1691188874242084",
    "id": "1174",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "21",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1175",
    "zoneId": "7",
    "metaId": "3468",
    "nftId": 1175,
    "creationDate": "1691188930264138",
    "id": "1175",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "-1",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1176",
    "zoneId": "7",
    "metaId": "2737",
    "nftId": 1176,
    "creationDate": "1691188930264138",
    "id": "1176",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "18",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1177",
    "zoneId": "5",
    "metaId": "1546",
    "nftId": 1177,
    "creationDate": "1691188978515821",
    "id": "1177",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "-17",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1178",
    "zoneId": "5",
    "metaId": "1586",
    "nftId": 1178,
    "creationDate": "1691188978515821",
    "id": "1178",
    "mintedVia": "CROWN",
  },
  {
    "x": "-23",
    "y": "20",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1179",
    "zoneId": "5",
    "metaId": "1626",
    "nftId": 1179,
    "creationDate": "1691188978515821",
    "id": "1179",
    "mintedVia": "CROWN",
  },
  {
    "x": "12",
    "y": "24",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1180",
    "zoneId": "5",
    "metaId": "1554",
    "nftId": 1180,
    "creationDate": "1691188978515821",
    "id": "1180",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "19",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1181",
    "zoneId": "5",
    "metaId": "1565",
    "nftId": 1181,
    "creationDate": "1691188978515821",
    "id": "1181",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "21",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1182",
    "zoneId": "4",
    "metaId": "1489",
    "nftId": 1182,
    "creationDate": "1691189042497907",
    "id": "1182",
    "mintedVia": "CROWN",
  },
  {
    "x": "54",
    "y": "21",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1183",
    "zoneId": "4",
    "metaId": "1170",
    "nftId": 1183,
    "creationDate": "1691189042497907",
    "id": "1183",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "18",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1184",
    "zoneId": "4",
    "metaId": "1521",
    "nftId": 1184,
    "creationDate": "1691189042497907",
    "id": "1184",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "-9",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1185",
    "zoneId": "4",
    "metaId": "1387",
    "nftId": 1185,
    "creationDate": "1691189042497907",
    "id": "1185",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "4",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1186",
    "zoneId": "4",
    "metaId": "1401",
    "nftId": 1186,
    "creationDate": "1691189042497907",
    "id": "1186",
    "mintedVia": "CROWN",
  },
  {
    "x": "54",
    "y": "-4",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1187",
    "zoneId": "4",
    "metaId": "1344",
    "nftId": 1187,
    "creationDate": "1691189104316948",
    "id": "1187",
    "mintedVia": "CROWN",
  },
  {
    "x": "27",
    "y": "22",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1188",
    "zoneId": "4",
    "metaId": "1142",
    "nftId": 1188,
    "creationDate": "1691189104316948",
    "id": "1188",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "9",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1189",
    "zoneId": "4",
    "metaId": "1397",
    "nftId": 1189,
    "creationDate": "1691189104316948",
    "id": "1189",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "25",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1190",
    "zoneId": "6",
    "metaId": "1797",
    "nftId": 1190,
    "creationDate": "1691189148348114",
    "id": "1190",
    "mintedVia": "CROWN",
  },
  {
    "x": "41",
    "y": "-10",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1191",
    "zoneId": "6",
    "metaId": "1730",
    "nftId": 1191,
    "creationDate": "1691189148348114",
    "id": "1191",
    "mintedVia": "CROWN",
  },
  {
    "x": "11",
    "y": "23",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1192",
    "zoneId": "6",
    "metaId": "1677",
    "nftId": 1192,
    "creationDate": "1691189148348114",
    "id": "1192",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "16",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1193",
    "zoneId": "6",
    "metaId": "1674",
    "nftId": 1193,
    "creationDate": "1691189148348114",
    "id": "1193",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "10",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1194",
    "zoneId": "6",
    "metaId": "1756",
    "nftId": 1194,
    "creationDate": "1691189148348114",
    "id": "1194",
    "mintedVia": "CROWN",
  },
  {
    "x": "30",
    "y": "-19",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1195",
    "zoneId": "6",
    "metaId": "1718",
    "nftId": 1195,
    "creationDate": "1691189200349922",
    "id": "1195",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "13",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1196",
    "zoneId": "6",
    "metaId": "1759",
    "nftId": 1196,
    "creationDate": "1691189200349922",
    "id": "1196",
    "mintedVia": "CROWN",
  },
  {
    "x": "3",
    "y": "35",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1197",
    "zoneId": "3",
    "metaId": "1051",
    "nftId": 1197,
    "creationDate": "1691189250364706",
    "id": "1197",
    "mintedVia": "CROWN",
  },
  {
    "x": "-92",
    "y": "-26",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1198",
    "zoneId": "3",
    "metaId": "972",
    "nftId": 1198,
    "creationDate": "1691189250364706",
    "id": "1198",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "30",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1199",
    "zoneId": "3",
    "metaId": "1053",
    "nftId": 1199,
    "creationDate": "1691189250364706",
    "id": "1199",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "22",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1200",
    "zoneId": "3",
    "metaId": "1071",
    "nftId": 1200,
    "creationDate": "1691189250364706",
    "id": "1200",
    "mintedVia": "CROWN",
  },
  {
    "x": "27",
    "y": "21",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1201",
    "zoneId": "3",
    "metaId": "942",
    "nftId": 1201,
    "creationDate": "1691189250364706",
    "id": "1201",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "-14",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1202",
    "zoneId": "3",
    "metaId": "1131",
    "nftId": 1202,
    "creationDate": "1691189304379319",
    "id": "1202",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "-2",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1203",
    "zoneId": "1",
    "metaId": "369",
    "nftId": 1203,
    "creationDate": "1691189356400369",
    "id": "1203",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "-13",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1204",
    "zoneId": "1",
    "metaId": "705",
    "nftId": 1204,
    "creationDate": "1691189356400369",
    "id": "1204",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "15",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1205",
    "zoneId": "1",
    "metaId": "90",
    "nftId": 1205,
    "creationDate": "1691189356400369",
    "id": "1205",
    "mintedVia": "CROWN",
  },
  {
    "x": "-75",
    "y": "10",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1206",
    "zoneId": "1",
    "metaId": "131",
    "nftId": 1206,
    "creationDate": "1691189356400369",
    "id": "1206",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "5",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1207",
    "zoneId": "1",
    "metaId": "565",
    "nftId": 1207,
    "creationDate": "1691189356400369",
    "id": "1207",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "14",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1208",
    "zoneId": "1",
    "metaId": "78",
    "nftId": 1208,
    "creationDate": "1691189426418733",
    "id": "1208",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "-3",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1209",
    "zoneId": "1",
    "metaId": "516",
    "nftId": 1209,
    "creationDate": "1691189426418733",
    "id": "1209",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "12",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1210",
    "zoneId": "8",
    "metaId": "5250",
    "nftId": 1210,
    "creationDate": "1691189470522672",
    "id": "1210",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "26",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1211",
    "zoneId": "8",
    "metaId": "5305",
    "nftId": 1211,
    "creationDate": "1691189470522672",
    "id": "1211",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "25",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1212",
    "zoneId": "8",
    "metaId": "5145",
    "nftId": 1212,
    "creationDate": "1691189470522672",
    "id": "1212",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "16",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1213",
    "zoneId": "8",
    "metaId": "5903",
    "nftId": 1213,
    "creationDate": "1691189470522672",
    "id": "1213",
    "mintedVia": "CROWN",
  },
  {
    "x": "-91",
    "y": "-16",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1214",
    "zoneId": "8",
    "metaId": "6413",
    "nftId": 1214,
    "creationDate": "1691189470522672",
    "id": "1214",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "28",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1215",
    "zoneId": "8",
    "metaId": "5734",
    "nftId": 1215,
    "creationDate": "1691189528690006",
    "id": "1215",
    "mintedVia": "CROWN",
  },
  {
    "x": "-100",
    "y": "4",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1216",
    "zoneId": "8",
    "metaId": "6094",
    "nftId": 1216,
    "creationDate": "1691189528690006",
    "id": "1216",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "-18",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1217",
    "zoneId": "8",
    "metaId": "5093",
    "nftId": 1217,
    "creationDate": "1691189528690006",
    "id": "1217",
    "mintedVia": "CROWN",
  },
  {
    "x": "-104",
    "y": "0",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1218",
    "zoneId": "8",
    "metaId": "6161",
    "nftId": 1218,
    "creationDate": "1691189528690006",
    "id": "1218",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "20",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1219",
    "zoneId": "8",
    "metaId": "5379",
    "nftId": 1219,
    "creationDate": "1691189528690006",
    "id": "1219",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "20",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1220",
    "zoneId": "8",
    "metaId": "5726",
    "nftId": 1220,
    "creationDate": "1691189592667731",
    "id": "1220",
    "mintedVia": "CROWN",
  },
  {
    "x": "-78",
    "y": "18",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1221",
    "zoneId": "8",
    "metaId": "6301",
    "nftId": 1221,
    "creationDate": "1691189592667731",
    "id": "1221",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "30",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1222",
    "zoneId": "8",
    "metaId": "5463",
    "nftId": 1222,
    "creationDate": "1691189592667731",
    "id": "1222",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "1",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1223",
    "zoneId": "8",
    "metaId": "6719",
    "nftId": 1223,
    "creationDate": "1691189592667731",
    "id": "1223",
    "mintedVia": "CROWN",
  },
  {
    "x": "-110",
    "y": "-3",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1224",
    "zoneId": "8",
    "metaId": "6210",
    "nftId": 1224,
    "creationDate": "1691189592667731",
    "id": "1224",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-5",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1225",
    "zoneId": "8",
    "metaId": "6716",
    "nftId": 1225,
    "creationDate": "1691189676499872",
    "id": "1225",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "3",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1226",
    "zoneId": "8",
    "metaId": "5736",
    "nftId": 1226,
    "creationDate": "1691189676499872",
    "id": "1226",
    "mintedVia": "CROWN",
  },
  {
    "x": "-106",
    "y": "-7",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1227",
    "zoneId": "8",
    "metaId": "6181",
    "nftId": 1227,
    "creationDate": "1691189676499872",
    "id": "1227",
    "mintedVia": "CROWN",
  },
  {
    "x": "-85",
    "y": "15",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1228",
    "zoneId": "8",
    "metaId": "6368",
    "nftId": 1228,
    "creationDate": "1691189676499872",
    "id": "1228",
    "mintedVia": "CROWN",
  },
  {
    "x": "17",
    "y": "23",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1229",
    "zoneId": "8",
    "metaId": "4820",
    "nftId": 1229,
    "creationDate": "1691189676499872",
    "id": "1229",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "18",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1230",
    "zoneId": "8",
    "metaId": "5376",
    "nftId": 1230,
    "creationDate": "1691189732692695",
    "id": "1230",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "-10",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1231",
    "zoneId": "8",
    "metaId": "6918",
    "nftId": 1231,
    "creationDate": "1691189732692695",
    "id": "1231",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "17",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1232",
    "zoneId": "8",
    "metaId": "5683",
    "nftId": 1232,
    "creationDate": "1691189732692695",
    "id": "1232",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "20",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1233",
    "zoneId": "8",
    "metaId": "6686",
    "nftId": 1233,
    "creationDate": "1691189732692695",
    "id": "1233",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "10",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1234",
    "zoneId": "8",
    "metaId": "5565",
    "nftId": 1234,
    "creationDate": "1691189732692695",
    "id": "1234",
    "mintedVia": "CROWN",
  },
  {
    "x": "-82",
    "y": "9",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1235",
    "zoneId": "8",
    "metaId": "6344",
    "nftId": 1235,
    "creationDate": "1691189796724423",
    "id": "1235",
    "mintedVia": "CROWN",
  },
  {
    "x": "11",
    "y": "27",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1236",
    "zoneId": "8",
    "metaId": "4898",
    "nftId": 1236,
    "creationDate": "1691189796724423",
    "id": "1236",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "-5",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1237",
    "zoneId": "8",
    "metaId": "5859",
    "nftId": 1237,
    "creationDate": "1691189796724423",
    "id": "1237",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "12",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1238",
    "zoneId": "8",
    "metaId": "4770",
    "nftId": 1238,
    "creationDate": "1691189796724423",
    "id": "1238",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "-10",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1239",
    "zoneId": "8",
    "metaId": "5745",
    "nftId": 1239,
    "creationDate": "1691189796724423",
    "id": "1239",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "17",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1240",
    "zoneId": "8",
    "metaId": "5567",
    "nftId": 1240,
    "creationDate": "1691189852553273",
    "id": "1240",
    "mintedVia": "CROWN",
  },
  {
    "x": "-88",
    "y": "8",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1241",
    "zoneId": "8",
    "metaId": "6392",
    "nftId": 1241,
    "creationDate": "1691189852553273",
    "id": "1241",
    "mintedVia": "CROWN",
  },
  {
    "x": "-75",
    "y": "12",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1242",
    "zoneId": "8",
    "metaId": "6268",
    "nftId": 1242,
    "creationDate": "1691189852553273",
    "id": "1242",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "-4",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1243",
    "zoneId": "8",
    "metaId": "6455",
    "nftId": 1243,
    "creationDate": "1691189852553273",
    "id": "1243",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "20",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1244",
    "zoneId": "8",
    "metaId": "5177",
    "nftId": 1244,
    "creationDate": "1691189852553273",
    "id": "1244",
    "mintedVia": "CROWN",
  },
  {
    "x": "40",
    "y": "19",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1245",
    "zoneId": "8",
    "metaId": "4729",
    "nftId": 1245,
    "creationDate": "1691189902569746",
    "id": "1245",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "9",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1246",
    "zoneId": "8",
    "metaId": "5588",
    "nftId": 1246,
    "creationDate": "1691189902569746",
    "id": "1246",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "13",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1247",
    "zoneId": "8",
    "metaId": "5062",
    "nftId": 1247,
    "creationDate": "1691189902569746",
    "id": "1247",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "5",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1248",
    "zoneId": "8",
    "metaId": "5117",
    "nftId": 1248,
    "creationDate": "1691189902569746",
    "id": "1248",
    "mintedVia": "CROWN",
  },
  {
    "x": "-107",
    "y": "-4",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1249",
    "zoneId": "8",
    "metaId": "6188",
    "nftId": 1249,
    "creationDate": "1691189902569746",
    "id": "1249",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "10",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1250",
    "zoneId": "8",
    "metaId": "5329",
    "nftId": 1250,
    "creationDate": "1691189956589217",
    "id": "1250",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "19",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1251",
    "zoneId": "8",
    "metaId": "5377",
    "nftId": 1251,
    "creationDate": "1691189956589217",
    "id": "1251",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "25",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1252",
    "zoneId": "8",
    "metaId": "5843",
    "nftId": 1252,
    "creationDate": "1691189956589217",
    "id": "1252",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "1",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1253",
    "zoneId": "8",
    "metaId": "5680",
    "nftId": 1253,
    "creationDate": "1691189956589217",
    "id": "1253",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "-8",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1254",
    "zoneId": "8",
    "metaId": "5203",
    "nftId": 1254,
    "creationDate": "1691189956589217",
    "id": "1254",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "29",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1255",
    "zoneId": "8",
    "metaId": "5735",
    "nftId": 1255,
    "creationDate": "1691190014792384",
    "id": "1255",
    "mintedVia": "CROWN",
  },
  {
    "x": "-85",
    "y": "8",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1256",
    "zoneId": "8",
    "metaId": "6370",
    "nftId": 1256,
    "creationDate": "1691190014792384",
    "id": "1256",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "8",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1257",
    "zoneId": "8",
    "metaId": "5587",
    "nftId": 1257,
    "creationDate": "1691190014792384",
    "id": "1257",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "17",
    "owner": "hxc505466cc0a61e6e7ec7fb0a0dc0316d606ff63f",
    "name": "Emerald City Land #1258",
    "zoneId": "8",
    "metaId": "5014",
    "nftId": 1258,
    "creationDate": "1691190014792384",
    "id": "1258",
    "mintedVia": "CROWN",
  },
  {
    "x": "-11",
    "y": "2",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #1259",
    "zoneId": "7",
    "metaId": "2873",
    "nftId": 1259,
    "creationDate": "1691192556556982",
    "id": "1259",
    "mintedVia": "CROWN",
  },
  {
    "x": "6",
    "y": "14",
    "owner": "hxbda9ea6ce93b75cc2f3234fe2d3c84fa8083a420",
    "name": "Emerald City Land #1260",
    "zoneId": "7",
    "metaId": "4466",
    "nftId": 1260,
    "creationDate": "1691195312415643",
    "id": "1260",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "-4",
    "owner": "hxbda9ea6ce93b75cc2f3234fe2d3c84fa8083a420",
    "name": "Emerald City Land #1261",
    "zoneId": "7",
    "metaId": "2922",
    "nftId": 1261,
    "creationDate": "1691195484346890",
    "id": "1261",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "-4",
    "owner": "hxbda9ea6ce93b75cc2f3234fe2d3c84fa8083a420",
    "name": "Emerald City Land #1262",
    "zoneId": "7",
    "metaId": "3056",
    "nftId": 1262,
    "creationDate": "1691195484346890",
    "id": "1262",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "4",
    "owner": "hxbda9ea6ce93b75cc2f3234fe2d3c84fa8083a420",
    "name": "Emerald City Land #1263",
    "zoneId": "7",
    "metaId": "4179",
    "nftId": 1263,
    "creationDate": "1691195484346890",
    "id": "1263",
    "mintedVia": "CROWN",
  },
  {
    "x": "-99",
    "y": "0",
    "owner": "hxbda9ea6ce93b75cc2f3234fe2d3c84fa8083a420",
    "name": "Emerald City Land #1264",
    "zoneId": "8",
    "metaId": "6579",
    "nftId": 1264,
    "creationDate": "1691195652367395",
    "id": "1264",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "15",
    "owner": "hxbda9ea6ce93b75cc2f3234fe2d3c84fa8083a420",
    "name": "Emerald City Land #1265",
    "zoneId": "8",
    "metaId": "4874",
    "nftId": 1265,
    "creationDate": "1691195652367395",
    "id": "1265",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "-5",
    "owner": "hxbda9ea6ce93b75cc2f3234fe2d3c84fa8083a420",
    "name": "Emerald City Land #1266",
    "zoneId": "8",
    "metaId": "6907",
    "nftId": 1266,
    "creationDate": "1691195652367395",
    "id": "1266",
    "mintedVia": "CROWN",
  },
  {
    "x": "-66",
    "y": "-28",
    "owner": "hx8cf4caad199391a29ce1f8e8388179582fb05cfa",
    "name": "Emerald City Land #1267",
    "zoneId": "2",
    "metaId": "896",
    "nftId": 1267,
    "creationDate": "1691195888460884",
    "id": "1267",
    "mintedVia": "CROWN",
  },
  {
    "x": "-13",
    "y": "15",
    "owner": "hx8cf4caad199391a29ce1f8e8388179582fb05cfa",
    "name": "Emerald City Land #1268",
    "zoneId": "7",
    "metaId": "3431",
    "nftId": 1268,
    "creationDate": "1691195980469565",
    "id": "1268",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "7",
    "owner": "hx8cf4caad199391a29ce1f8e8388179582fb05cfa",
    "name": "Emerald City Land #1269",
    "zoneId": "7",
    "metaId": "4081",
    "nftId": 1269,
    "creationDate": "1691195980469565",
    "id": "1269",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "16",
    "owner": "hx8cf4caad199391a29ce1f8e8388179582fb05cfa",
    "name": "Emerald City Land #1270",
    "zoneId": "7",
    "metaId": "4393",
    "nftId": 1270,
    "creationDate": "1691195980469565",
    "id": "1270",
    "mintedVia": "CROWN",
  },
  {
    "x": "55",
    "y": "24",
    "owner": "hx8cf4caad199391a29ce1f8e8388179582fb05cfa",
    "name": "Emerald City Land #1271",
    "zoneId": "7",
    "metaId": "1875",
    "nftId": 1271,
    "creationDate": "1691196066533679",
    "id": "1271",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "-14",
    "owner": "hx8cf4caad199391a29ce1f8e8388179582fb05cfa",
    "name": "Emerald City Land #1272",
    "zoneId": "7",
    "metaId": "2432",
    "nftId": 1272,
    "creationDate": "1691196066533679",
    "id": "1272",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "2",
    "owner": "hx8cf4caad199391a29ce1f8e8388179582fb05cfa",
    "name": "Emerald City Land #1273",
    "zoneId": "7",
    "metaId": "4237",
    "nftId": 1273,
    "creationDate": "1691196066533679",
    "id": "1273",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "36",
    "owner": "hx8cf4caad199391a29ce1f8e8388179582fb05cfa",
    "name": "Emerald City Land #1274",
    "zoneId": "3",
    "metaId": "1025",
    "nftId": 1274,
    "creationDate": "1691196148540348",
    "id": "1274",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "17",
    "owner": "hx8cf4caad199391a29ce1f8e8388179582fb05cfa",
    "name": "Emerald City Land #1275",
    "zoneId": "3",
    "metaId": "958",
    "nftId": 1275,
    "creationDate": "1691196148540348",
    "id": "1275",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "33",
    "owner": "hx8cf4caad199391a29ce1f8e8388179582fb05cfa",
    "name": "Emerald City Land #1276",
    "zoneId": "3",
    "metaId": "1042",
    "nftId": 1276,
    "creationDate": "1691196148540348",
    "id": "1276",
    "mintedVia": "CROWN",
  },
  {
    "x": "41",
    "y": "13",
    "owner": "hx541350746e2319b1c54fd929575cd280552b4f9b",
    "name": "Emerald City Land #1277",
    "zoneId": "6",
    "metaId": "1694",
    "nftId": 1277,
    "creationDate": "1691208531478733",
    "id": "1277",
    "mintedVia": "CROWN",
  },
  {
    "x": "-110",
    "y": "-2",
    "owner": "hx541350746e2319b1c54fd929575cd280552b4f9b",
    "name": "Emerald City Land #1278",
    "zoneId": "8",
    "metaId": "6209",
    "nftId": 1278,
    "creationDate": "1691208663496597",
    "id": "1278",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "30",
    "owner": "hx541350746e2319b1c54fd929575cd280552b4f9b",
    "name": "Emerald City Land #1279",
    "zoneId": "3",
    "metaId": "957",
    "nftId": 1279,
    "creationDate": "1691208787801603",
    "id": "1279",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-23",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1280",
    "zoneId": "2",
    "metaId": "838",
    "nftId": 1280,
    "creationDate": "1691211345591339",
    "id": "1280",
    "mintedVia": "CROWN",
  },
  {
    "x": "-10",
    "y": "24",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1281",
    "zoneId": "7",
    "metaId": "3383",
    "nftId": 1281,
    "creationDate": "1691211515635306",
    "id": "1281",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "12",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1282",
    "zoneId": "7",
    "metaId": "4111",
    "nftId": 1282,
    "creationDate": "1691211515635306",
    "id": "1282",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "-2",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1283",
    "zoneId": "7",
    "metaId": "4182",
    "nftId": 1283,
    "creationDate": "1691211515635306",
    "id": "1283",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "-4",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1284",
    "zoneId": "7",
    "metaId": "2157",
    "nftId": 1284,
    "creationDate": "1691211515635306",
    "id": "1284",
    "mintedVia": "CROWN",
  },
  {
    "x": "41",
    "y": "-9",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1285",
    "zoneId": "4",
    "metaId": "1308",
    "nftId": 1285,
    "creationDate": "1691212540107842",
    "id": "1285",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "13",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1286",
    "zoneId": "4",
    "metaId": "1151",
    "nftId": 1286,
    "creationDate": "1691212540107842",
    "id": "1286",
    "mintedVia": "CROWN",
  },
  {
    "x": "54",
    "y": "11",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1287",
    "zoneId": "4",
    "metaId": "1185",
    "nftId": 1287,
    "creationDate": "1691212540107842",
    "id": "1287",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "4",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1288",
    "zoneId": "4",
    "metaId": "1313",
    "nftId": 1288,
    "creationDate": "1691212540107842",
    "id": "1288",
    "mintedVia": "CROWN",
  },
  {
    "x": "46",
    "y": "3",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1289",
    "zoneId": "4",
    "metaId": "1325",
    "nftId": 1289,
    "creationDate": "1691212540107842",
    "id": "1289",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "11",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1290",
    "zoneId": "8",
    "metaId": "5330",
    "nftId": 1290,
    "creationDate": "1691212673957644",
    "id": "1290",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "19",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1291",
    "zoneId": "8",
    "metaId": "5257",
    "nftId": 1291,
    "creationDate": "1691212673957644",
    "id": "1291",
    "mintedVia": "CROWN",
  },
  {
    "x": "-95",
    "y": "9",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1292",
    "zoneId": "8",
    "metaId": "6508",
    "nftId": 1292,
    "creationDate": "1691212673957644",
    "id": "1292",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "-17",
    "owner": "hx47ee0ba594e0ea5032372925a459df0232b9ab7c",
    "name": "Emerald City Land #1293",
    "zoneId": "1",
    "metaId": "694",
    "nftId": 1293,
    "creationDate": "1691212729997373",
    "id": "1293",
    "mintedVia": "CROWN",
  },
  {
    "x": "-10",
    "y": "7",
    "owner": "hx1fef7b87b9182dc70793c53cbbea93625683a862",
    "name": "Emerald City Land #1294",
    "zoneId": "7",
    "metaId": "2858",
    "nftId": 1294,
    "creationDate": "1691218574293802",
    "id": "1294",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "5",
    "owner": "hx1fef7b87b9182dc70793c53cbbea93625683a862",
    "name": "Emerald City Land #1295",
    "zoneId": "7",
    "metaId": "4372",
    "nftId": 1295,
    "creationDate": "1691218574293802",
    "id": "1295",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "-4",
    "owner": "hx1fef7b87b9182dc70793c53cbbea93625683a862",
    "name": "Emerald City Land #1296",
    "zoneId": "1",
    "metaId": "486",
    "nftId": 1296,
    "creationDate": "1691218644397473",
    "id": "1296",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "3",
    "owner": "hx1fef7b87b9182dc70793c53cbbea93625683a862",
    "name": "Emerald City Land #1297",
    "zoneId": "1",
    "metaId": "328",
    "nftId": 1297,
    "creationDate": "1691218644397473",
    "id": "1297",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "-4",
    "owner": "hx1fef7b87b9182dc70793c53cbbea93625683a862",
    "name": "Emerald City Land #1298",
    "zoneId": "8",
    "metaId": "5321",
    "nftId": 1298,
    "creationDate": "1691218688189672",
    "id": "1298",
    "mintedVia": "CROWN",
  },
  {
    "x": "24",
    "y": "13",
    "owner": "hx1fef7b87b9182dc70793c53cbbea93625683a862",
    "name": "Emerald City Land #1299",
    "zoneId": "8",
    "metaId": "4877",
    "nftId": 1299,
    "creationDate": "1691218688189672",
    "id": "1299",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "10",
    "owner": "hx1fef7b87b9182dc70793c53cbbea93625683a862",
    "name": "Emerald City Land #1300",
    "zoneId": "8",
    "metaId": "5756",
    "nftId": 1300,
    "creationDate": "1691218688189672",
    "id": "1300",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "6",
    "owner": "hx1fef7b87b9182dc70793c53cbbea93625683a862",
    "name": "Emerald City Land #1301",
    "zoneId": "8",
    "metaId": "6784",
    "nftId": 1301,
    "creationDate": "1691218688189672",
    "id": "1301",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "-14",
    "owner": "hx9e539bc59882754e7db544b8ad2b91249d69ef53",
    "name": "Emerald City Land #1302",
    "zoneId": "1",
    "metaId": "155",
    "nftId": 1302,
    "creationDate": "1691221528296879",
    "id": "1302",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "-20",
    "owner": "hx9e539bc59882754e7db544b8ad2b91249d69ef53",
    "name": "Emerald City Land #1303",
    "zoneId": "1",
    "metaId": "631",
    "nftId": 1303,
    "creationDate": "1691221528296879",
    "id": "1303",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "-9",
    "owner": "hx9e539bc59882754e7db544b8ad2b91249d69ef53",
    "name": "Emerald City Land #1304",
    "zoneId": "8",
    "metaId": "5643",
    "nftId": 1304,
    "creationDate": "1691221670341243",
    "id": "1304",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "-21",
    "owner": "hx9e539bc59882754e7db544b8ad2b91249d69ef53",
    "name": "Emerald City Land #1305",
    "zoneId": "8",
    "metaId": "6474",
    "nftId": 1305,
    "creationDate": "1691221670341243",
    "id": "1305",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "8",
    "owner": "hx9e539bc59882754e7db544b8ad2b91249d69ef53",
    "name": "Emerald City Land #1306",
    "zoneId": "8",
    "metaId": "5274",
    "nftId": 1306,
    "creationDate": "1691221670341243",
    "id": "1306",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "15",
    "owner": "hx9e539bc59882754e7db544b8ad2b91249d69ef53",
    "name": "Emerald City Land #1307",
    "zoneId": "8",
    "metaId": "4695",
    "nftId": 1307,
    "creationDate": "1691221670341243",
    "id": "1307",
    "mintedVia": "CROWN",
  },
  {
    "x": "-18",
    "y": "13",
    "owner": "hx570ca097ecabb140736b003cb274425f41d43d91",
    "name": "Emerald City Land #1308",
    "zoneId": "7",
    "metaId": "3512",
    "nftId": 1308,
    "creationDate": "1691230725192458",
    "id": "1308",
    "mintedVia": "CROWN",
  },
  {
    "x": "-46",
    "y": "-3",
    "owner": "hx570ca097ecabb140736b003cb274425f41d43d91",
    "name": "Emerald City Land #1309",
    "zoneId": "8",
    "metaId": "6887",
    "nftId": 1309,
    "creationDate": "1691230765397706",
    "id": "1309",
    "mintedVia": "CROWN",
  },
  {
    "x": "14",
    "y": "15",
    "owner": "hx359cafab9908602241f59b9ced32e4916c0d744d",
    "name": "Emerald City Land #1310",
    "zoneId": "4",
    "metaId": "1154",
    "nftId": 1310,
    "creationDate": "1691230925259323",
    "id": "1310",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "15",
    "owner": "hxb69e92375ef41990daba1ff2f72409555f787aa8",
    "name": "Emerald City Land #1311",
    "zoneId": "7",
    "metaId": "4367",
    "nftId": 1311,
    "creationDate": "1691232981932441",
    "id": "1311",
    "mintedVia": "CROWN",
  },
  {
    "x": "38",
    "y": "16",
    "owner": "hxb69e92375ef41990daba1ff2f72409555f787aa8",
    "name": "Emerald City Land #1312",
    "zoneId": "7",
    "metaId": "2104",
    "nftId": 1312,
    "creationDate": "1691232981932441",
    "id": "1312",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "-2",
    "owner": "hxb69e92375ef41990daba1ff2f72409555f787aa8",
    "name": "Emerald City Land #1313",
    "zoneId": "7",
    "metaId": "2243",
    "nftId": 1313,
    "creationDate": "1691232981932441",
    "id": "1313",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "24",
    "owner": "hxb69e92375ef41990daba1ff2f72409555f787aa8",
    "name": "Emerald City Land #1314",
    "zoneId": "8",
    "metaId": "6651",
    "nftId": 1314,
    "creationDate": "1691233102180731",
    "id": "1314",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "14",
    "owner": "hxb69e92375ef41990daba1ff2f72409555f787aa8",
    "name": "Emerald City Land #1315",
    "zoneId": "7",
    "metaId": "4005",
    "nftId": 1315,
    "creationDate": "1691233144066678",
    "id": "1315",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "20",
    "owner": "hxb69e92375ef41990daba1ff2f72409555f787aa8",
    "name": "Emerald City Land #1316",
    "zoneId": "7",
    "metaId": "4097",
    "nftId": 1316,
    "creationDate": "1691233144066678",
    "id": "1316",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "28",
    "owner": "hxb7a130403368d28a2173d68b2e192bfd24a80954",
    "name": "Emerald City Land #1317",
    "zoneId": "4",
    "metaId": "1431",
    "nftId": 1317,
    "creationDate": "1691237451368906",
    "id": "1317",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "0",
    "owner": "hxb7a130403368d28a2173d68b2e192bfd24a80954",
    "name": "Emerald City Land #1318",
    "zoneId": "3",
    "metaId": "982",
    "nftId": 1318,
    "creationDate": "1691237549399516",
    "id": "1318",
    "mintedVia": "CROWN",
  },
  {
    "x": "-76",
    "y": "19",
    "owner": "hxb7a130403368d28a2173d68b2e192bfd24a80954",
    "name": "Emerald City Land #1319",
    "zoneId": "8",
    "metaId": "6283",
    "nftId": 1319,
    "creationDate": "1691237597412677",
    "id": "1319",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "14",
    "owner": "hx148782df8c375cc2f647308cf319705736e4b9e7",
    "name": "Emerald City Land #1320",
    "zoneId": "7",
    "metaId": "2017",
    "nftId": 1320,
    "creationDate": "1691242465027465",
    "id": "1320",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "-2",
    "owner": "hx148782df8c375cc2f647308cf319705736e4b9e7",
    "name": "Emerald City Land #1321",
    "zoneId": "7",
    "metaId": "2247",
    "nftId": 1321,
    "creationDate": "1691242465027465",
    "id": "1321",
    "mintedVia": "CROWN",
  },
  {
    "x": "-19",
    "y": "26",
    "owner": "hx148782df8c375cc2f647308cf319705736e4b9e7",
    "name": "Emerald City Land #1322",
    "zoneId": "7",
    "metaId": "3539",
    "nftId": 1322,
    "creationDate": "1691242465027465",
    "id": "1322",
    "mintedVia": "CROWN",
  },
  {
    "x": "8",
    "y": "29",
    "owner": "hx148782df8c375cc2f647308cf319705736e4b9e7",
    "name": "Emerald City Land #1323",
    "zoneId": "7",
    "metaId": "4513",
    "nftId": 1323,
    "creationDate": "1691242465027465",
    "id": "1323",
    "mintedVia": "CROWN",
  },
  {
    "x": "-23",
    "y": "24",
    "owner": "hx148782df8c375cc2f647308cf319705736e4b9e7",
    "name": "Emerald City Land #1324",
    "zoneId": "7",
    "metaId": "3623",
    "nftId": 1324,
    "creationDate": "1691242465027465",
    "id": "1324",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "29",
    "owner": "hx148782df8c375cc2f647308cf319705736e4b9e7",
    "name": "Emerald City Land #1325",
    "zoneId": "8",
    "metaId": "5308",
    "nftId": 1325,
    "creationDate": "1691242627074857",
    "id": "1325",
    "mintedVia": "CROWN",
  },
  {
    "x": "-95",
    "y": "-22",
    "owner": "hx148782df8c375cc2f647308cf319705736e4b9e7",
    "name": "Emerald City Land #1326",
    "zoneId": "8",
    "metaId": "6493",
    "nftId": 1326,
    "creationDate": "1691242627074857",
    "id": "1326",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "25",
    "owner": "hx596540c70cdbaf2ad097fcf28958f7db8f03d894",
    "name": "Emerald City Land #1327",
    "zoneId": "8",
    "metaId": "5422",
    "nftId": 1327,
    "creationDate": "1691248152501718",
    "id": "1327",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "20",
    "owner": "hx09f151f30b93b088d31dd466d3c50b5920b25823",
    "name": "Emerald City Land #1328",
    "zoneId": "8",
    "metaId": "5142",
    "nftId": 1328,
    "creationDate": "1691250521366473",
    "id": "1328",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "-16",
    "owner": "hx09f151f30b93b088d31dd466d3c50b5920b25823",
    "name": "Emerald City Land #1329",
    "zoneId": "8",
    "metaId": "5358",
    "nftId": 1329,
    "creationDate": "1691250567200916",
    "id": "1329",
    "mintedVia": "CROWN",
  },
  {
    "x": "-101",
    "y": "-8",
    "owner": "hxfbf033b1f1474ba9e7d316db9c60bbe2dd770533",
    "name": "Emerald City Land #1330",
    "zoneId": "8",
    "metaId": "6105",
    "nftId": 1330,
    "creationDate": "1691253426110890",
    "id": "1330",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "24",
    "owner": "hxfbf033b1f1474ba9e7d316db9c60bbe2dd770533",
    "name": "Emerald City Land #1331",
    "zoneId": "8",
    "metaId": "5144",
    "nftId": 1331,
    "creationDate": "1691253426110890",
    "id": "1331",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "3",
    "owner": "hxfbf033b1f1474ba9e7d316db9c60bbe2dd770533",
    "name": "Emerald City Land #1332",
    "zoneId": "8",
    "metaId": "6041",
    "nftId": 1332,
    "creationDate": "1691253426110890",
    "id": "1332",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "19",
    "owner": "hxfbf033b1f1474ba9e7d316db9c60bbe2dd770533",
    "name": "Emerald City Land #1333",
    "zoneId": "4",
    "metaId": "1204",
    "nftId": 1333,
    "creationDate": "1691253526138049",
    "id": "1333",
    "mintedVia": "CROWN",
  },
  {
    "x": "-88",
    "y": "10",
    "owner": "hxbad9e4701d71aa0329b2f722402ba8d2e6ba2d52",
    "name": "Emerald City Land #1334",
    "zoneId": "8",
    "metaId": "6386",
    "nftId": 1334,
    "creationDate": "1691258740935539",
    "id": "1334",
    "mintedVia": "CROWN",
  },
  {
    "x": "-92",
    "y": "-9",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #1335",
    "zoneId": "5",
    "metaId": "1573",
    "nftId": 1335,
    "creationDate": "1691261445785718",
    "id": "1335",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "19",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #1336",
    "zoneId": "3",
    "metaId": "1106",
    "nftId": 1336,
    "creationDate": "1691261679800874",
    "id": "1336",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "-16",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #1337",
    "zoneId": "3",
    "metaId": "1133",
    "nftId": 1337,
    "creationDate": "1691262634157121",
    "id": "1337",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-35",
    "owner": "hxed1009d0396a6e36653016843537a401fc79c07d",
    "name": "Emerald City Land #1338",
    "zoneId": "2",
    "metaId": "842",
    "nftId": 1338,
    "creationDate": "1691274072039039",
    "id": "1338",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "-33",
    "owner": "hxed1009d0396a6e36653016843537a401fc79c07d",
    "name": "Emerald City Land #1339",
    "zoneId": "2",
    "metaId": "804",
    "nftId": 1339,
    "creationDate": "1691274072039039",
    "id": "1339",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "29",
    "owner": "hxed1009d0396a6e36653016843537a401fc79c07d",
    "name": "Emerald City Land #1340",
    "zoneId": "7",
    "metaId": "2171",
    "nftId": 1340,
    "creationDate": "1691274153953927",
    "id": "1340",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "17",
    "owner": "hxed1009d0396a6e36653016843537a401fc79c07d",
    "name": "Emerald City Land #1341",
    "zoneId": "7",
    "metaId": "4384",
    "nftId": 1341,
    "creationDate": "1691274153953927",
    "id": "1341",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "-7",
    "owner": "hxed1009d0396a6e36653016843537a401fc79c07d",
    "name": "Emerald City Land #1342",
    "zoneId": "1",
    "metaId": "289",
    "nftId": 1342,
    "creationDate": "1691274269835900",
    "id": "1342",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "-19",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #1343",
    "zoneId": "1",
    "metaId": "664",
    "nftId": 1343,
    "creationDate": "1691281582024757",
    "id": "1343",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "-7",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #1344",
    "zoneId": "7",
    "metaId": "2990",
    "nftId": 1344,
    "creationDate": "1691281714030578",
    "id": "1344",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "5",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #1345",
    "zoneId": "4",
    "metaId": "1276",
    "nftId": 1345,
    "creationDate": "1691282699580763",
    "id": "1345",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "26",
    "owner": "hx72825e95698f25d6cc49b898d36ec51bcadea14a",
    "name": "Emerald City Land #1346",
    "zoneId": "7",
    "metaId": "3831",
    "nftId": 1346,
    "creationDate": "1691304504056106",
    "id": "1346",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "-32",
    "owner": "hx7e29e4deea8900003919e28b82f7ecf3d7715e5f",
    "name": "Emerald City Land #1347",
    "zoneId": "1",
    "metaId": "602",
    "nftId": 1347,
    "creationDate": "1691308970460527",
    "id": "1347",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "-11",
    "owner": "hx7e29e4deea8900003919e28b82f7ecf3d7715e5f",
    "name": "Emerald City Land #1348",
    "zoneId": "1",
    "metaId": "99",
    "nftId": 1348,
    "creationDate": "1691309316526930",
    "id": "1348",
    "mintedVia": "CROWN",
  },
  {
    "x": "-103",
    "y": "5",
    "owner": "hx7e29e4deea8900003919e28b82f7ecf3d7715e5f",
    "name": "Emerald City Land #1349",
    "zoneId": "8",
    "metaId": "6150",
    "nftId": 1349,
    "creationDate": "1691309404575652",
    "id": "1349",
    "mintedVia": "CROWN",
  },
  {
    "x": "32",
    "y": "14",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #1350",
    "zoneId": "7",
    "metaId": "2123",
    "nftId": 1350,
    "creationDate": "1691322072190249",
    "id": "1350",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "18",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #1351",
    "zoneId": "7",
    "metaId": "1948",
    "nftId": 1351,
    "creationDate": "1691322170296726",
    "id": "1351",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-32",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1352",
    "zoneId": "2",
    "metaId": "853",
    "nftId": 1352,
    "creationDate": "1691342401977832",
    "id": "1352",
    "mintedVia": "CROWN",
  },
  {
    "x": "-61",
    "y": "-30",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1353",
    "zoneId": "2",
    "metaId": "882",
    "nftId": 1353,
    "creationDate": "1691342401977832",
    "id": "1353",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "-24",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1354",
    "zoneId": "2",
    "metaId": "780",
    "nftId": 1354,
    "creationDate": "1691342401977832",
    "id": "1354",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "-21",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1355",
    "zoneId": "2",
    "metaId": "871",
    "nftId": 1355,
    "creationDate": "1691342401977832",
    "id": "1355",
    "mintedVia": "CROWN",
  },
  {
    "x": "-61",
    "y": "-32",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1356",
    "zoneId": "2",
    "metaId": "884",
    "nftId": 1356,
    "creationDate": "1691342401977832",
    "id": "1356",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-36",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1357",
    "zoneId": "2",
    "metaId": "801",
    "nftId": 1357,
    "creationDate": "1691342583213728",
    "id": "1357",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "11",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1358",
    "zoneId": "7",
    "metaId": "2093",
    "nftId": 1358,
    "creationDate": "1691342665264792",
    "id": "1358",
    "mintedVia": "CROWN",
  },
  {
    "x": "-107",
    "y": "3",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1359",
    "zoneId": "7",
    "metaId": "2307",
    "nftId": 1359,
    "creationDate": "1691342665264792",
    "id": "1359",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "-1",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1360",
    "zoneId": "7",
    "metaId": "2195",
    "nftId": 1360,
    "creationDate": "1691342665264792",
    "id": "1360",
    "mintedVia": "CROWN",
  },
  {
    "x": "1",
    "y": "6",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1361",
    "zoneId": "7",
    "metaId": "3251",
    "nftId": 1361,
    "creationDate": "1691342665264792",
    "id": "1361",
    "mintedVia": "CROWN",
  },
  {
    "x": "38",
    "y": "29",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1362",
    "zoneId": "7",
    "metaId": "2082",
    "nftId": 1362,
    "creationDate": "1691342665264792",
    "id": "1362",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "-2",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1363",
    "zoneId": "7",
    "metaId": "2200",
    "nftId": 1363,
    "creationDate": "1691342741180384",
    "id": "1363",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "13",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1364",
    "zoneId": "7",
    "metaId": "4091",
    "nftId": 1364,
    "creationDate": "1691342741180384",
    "id": "1364",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "16",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1365",
    "zoneId": "7",
    "metaId": "3601",
    "nftId": 1365,
    "creationDate": "1691342741180384",
    "id": "1365",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "23",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1366",
    "zoneId": "7",
    "metaId": "3655",
    "nftId": 1366,
    "creationDate": "1691342741180384",
    "id": "1366",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "12",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1367",
    "zoneId": "7",
    "metaId": "4329",
    "nftId": 1367,
    "creationDate": "1691342741180384",
    "id": "1367",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "23",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1368",
    "zoneId": "4",
    "metaId": "1430",
    "nftId": 1368,
    "creationDate": "1691342861312761",
    "id": "1368",
    "mintedVia": "CROWN",
  },
  {
    "x": "8",
    "y": "36",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1369",
    "zoneId": "3",
    "metaId": "1123",
    "nftId": 1369,
    "creationDate": "1691342915336137",
    "id": "1369",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "35",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1370",
    "zoneId": "3",
    "metaId": "1000",
    "nftId": 1370,
    "creationDate": "1691342915336137",
    "id": "1370",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "32",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1371",
    "zoneId": "3",
    "metaId": "1017",
    "nftId": 1371,
    "creationDate": "1691342915336137",
    "id": "1371",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "29",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1372",
    "zoneId": "3",
    "metaId": "956",
    "nftId": 1372,
    "creationDate": "1691342915336137",
    "id": "1372",
    "mintedVia": "CROWN",
  },
  {
    "x": "27",
    "y": "4",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1373",
    "zoneId": "1",
    "metaId": "507",
    "nftId": 1373,
    "creationDate": "1691343009411754",
    "id": "1373",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "6",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1374",
    "zoneId": "1",
    "metaId": "364",
    "nftId": 1374,
    "creationDate": "1691343009411754",
    "id": "1374",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "0",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1375",
    "zoneId": "1",
    "metaId": "375",
    "nftId": 1375,
    "creationDate": "1691343009411754",
    "id": "1375",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "0",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1376",
    "zoneId": "1",
    "metaId": "273",
    "nftId": 1376,
    "creationDate": "1691343009411754",
    "id": "1376",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "9",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1377",
    "zoneId": "1",
    "metaId": "548",
    "nftId": 1377,
    "creationDate": "1691343009411754",
    "id": "1377",
    "mintedVia": "CROWN",
  },
  {
    "x": "-108",
    "y": "-6",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1378",
    "zoneId": "8",
    "metaId": "6198",
    "nftId": 1378,
    "creationDate": "1691343093375057",
    "id": "1378",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "13",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1379",
    "zoneId": "8",
    "metaId": "5251",
    "nftId": 1379,
    "creationDate": "1691343093375057",
    "id": "1379",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "-6",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1380",
    "zoneId": "8",
    "metaId": "5891",
    "nftId": 1380,
    "creationDate": "1691343093375057",
    "id": "1380",
    "mintedVia": "CROWN",
  },
  {
    "x": "-87",
    "y": "10",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1381",
    "zoneId": "8",
    "metaId": "6380",
    "nftId": 1381,
    "creationDate": "1691343093375057",
    "id": "1381",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "29",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1382",
    "zoneId": "8",
    "metaId": "5540",
    "nftId": 1382,
    "creationDate": "1691343093375057",
    "id": "1382",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "-7",
    "owner": "hx640ce9a75a899aa5bc1061e0cedf44b041bebeb6",
    "name": "Emerald City Land #1383",
    "zoneId": "8",
    "metaId": "6846",
    "nftId": 1383,
    "creationDate": "1691343162613857",
    "id": "1383",
    "mintedVia": "CROWN",
  },
  {
    "x": "17",
    "y": "25",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1384",
    "zoneId": "7",
    "metaId": "1921",
    "nftId": 1384,
    "creationDate": "1691352976246969",
    "id": "1384",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "-2",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1385",
    "zoneId": "4",
    "metaId": "1377",
    "nftId": 1385,
    "creationDate": "1691353026255188",
    "id": "1385",
    "mintedVia": "CROWN",
  },
  {
    "x": "-2",
    "y": "34",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1386",
    "zoneId": "3",
    "metaId": "994",
    "nftId": 1386,
    "creationDate": "1691353064269459",
    "id": "1386",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "-11",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1387",
    "zoneId": "1",
    "metaId": "153",
    "nftId": 1387,
    "creationDate": "1691353108318943",
    "id": "1387",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "-20",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1388",
    "zoneId": "1",
    "metaId": "610",
    "nftId": 1388,
    "creationDate": "1691353108318943",
    "id": "1388",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "-7",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1389",
    "zoneId": "8",
    "metaId": "6937",
    "nftId": 1389,
    "creationDate": "1691353158329983",
    "id": "1389",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "24",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1390",
    "zoneId": "8",
    "metaId": "5457",
    "nftId": 1390,
    "creationDate": "1691353158329983",
    "id": "1390",
    "mintedVia": "CROWN",
  },
  {
    "x": "90",
    "y": "13",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1391",
    "zoneId": "8",
    "metaId": "6074",
    "nftId": 1391,
    "creationDate": "1691353158329983",
    "id": "1391",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "-17",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1392",
    "zoneId": "8",
    "metaId": "6822",
    "nftId": 1392,
    "creationDate": "1691353158329983",
    "id": "1392",
    "mintedVia": "CROWN",
  },
  {
    "x": "-79",
    "y": "19",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1393",
    "zoneId": "8",
    "metaId": "6311",
    "nftId": 1393,
    "creationDate": "1691353158329983",
    "id": "1393",
    "mintedVia": "CROWN",
  },
  {
    "x": "-96",
    "y": "-20",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1394",
    "zoneId": "8",
    "metaId": "6513",
    "nftId": 1394,
    "creationDate": "1691353250522338",
    "id": "1394",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "2",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1395",
    "zoneId": "8",
    "metaId": "6667",
    "nftId": 1395,
    "creationDate": "1691353250522338",
    "id": "1395",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "6",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1396",
    "zoneId": "8",
    "metaId": "5430",
    "nftId": 1396,
    "creationDate": "1691353250522338",
    "id": "1396",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "19",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1397",
    "zoneId": "8",
    "metaId": "5490",
    "nftId": 1397,
    "creationDate": "1691353250522338",
    "id": "1397",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "-20",
    "owner": "hx038403920b63fdea5849304a0df599dc35dd131a",
    "name": "Emerald City Land #1398",
    "zoneId": "8",
    "metaId": "6824",
    "nftId": 1398,
    "creationDate": "1691353250522338",
    "id": "1398",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "7",
    "owner": "hx2756b3fc8d9db58105df3610411d592865ebfa72",
    "name": "Emerald City Land #1399",
    "zoneId": "1",
    "metaId": "296",
    "nftId": 1399,
    "creationDate": "1691372326210400",
    "id": "1399",
    "mintedVia": "CROWN",
  },
  {
    "x": "85",
    "y": "-4",
    "owner": "hx2756b3fc8d9db58105df3610411d592865ebfa72",
    "name": "Emerald City Land #1400",
    "zoneId": "8",
    "metaId": "5955",
    "nftId": 1400,
    "creationDate": "1691372412223110",
    "id": "1400",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "10",
    "owner": "hx2756b3fc8d9db58105df3610411d592865ebfa72",
    "name": "Emerald City Land #1401",
    "zoneId": "8",
    "metaId": "6632",
    "nftId": 1401,
    "creationDate": "1691372412223110",
    "id": "1401",
    "mintedVia": "CROWN",
  },
  {
    "x": "-103",
    "y": "-5",
    "owner": "hx2756b3fc8d9db58105df3610411d592865ebfa72",
    "name": "Emerald City Land #1402",
    "zoneId": "8",
    "metaId": "6140",
    "nftId": 1402,
    "creationDate": "1691372412223110",
    "id": "1402",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "8",
    "owner": "hx2756b3fc8d9db58105df3610411d592865ebfa72",
    "name": "Emerald City Land #1403",
    "zoneId": "8",
    "metaId": "5546",
    "nftId": 1403,
    "creationDate": "1691372412223110",
    "id": "1403",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "23",
    "owner": "hx2756b3fc8d9db58105df3610411d592865ebfa72",
    "name": "Emerald City Land #1404",
    "zoneId": "8",
    "metaId": "6644",
    "nftId": 1404,
    "creationDate": "1691372412223110",
    "id": "1404",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "8",
    "owner": "hx5342c59c6f1d65a08315c114e1669e20958e2b52",
    "name": "Emerald City Land #1405",
    "zoneId": "8",
    "metaId": "5393",
    "nftId": 1405,
    "creationDate": "1691388630238973",
    "id": "1405",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "16",
    "owner": "hx5342c59c6f1d65a08315c114e1669e20958e2b52",
    "name": "Emerald City Land #1406",
    "zoneId": "8",
    "metaId": "5799",
    "nftId": 1406,
    "creationDate": "1691388630238973",
    "id": "1406",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "16",
    "owner": "hx5342c59c6f1d65a08315c114e1669e20958e2b52",
    "name": "Emerald City Land #1406",
    "zoneId": "8",
    "metaId": "5799",
    "nftId": 1406,
    "creationDate": "1691388630238973",
    "id": "1406",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "26",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1407",
    "zoneId": "8",
    "metaId": "4745",
    "nftId": 1407,
    "creationDate": "1691407663895323",
    "id": "1407",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "-4",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1408",
    "zoneId": "8",
    "metaId": "6590",
    "nftId": 1408,
    "creationDate": "1691407663895323",
    "id": "1408",
    "mintedVia": "CROWN",
  },
  {
    "x": "58",
    "y": "28",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1409",
    "zoneId": "8",
    "metaId": "5045",
    "nftId": 1409,
    "creationDate": "1691407663895323",
    "id": "1409",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "16",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1410",
    "zoneId": "8",
    "metaId": "5103",
    "nftId": 1410,
    "creationDate": "1691407663895323",
    "id": "1410",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "-5",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1411",
    "zoneId": "8",
    "metaId": "5988",
    "nftId": 1411,
    "creationDate": "1691407663895323",
    "id": "1411",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "14",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1412",
    "zoneId": "8",
    "metaId": "4831",
    "nftId": 1412,
    "creationDate": "1691407733935524",
    "id": "1412",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "6",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1413",
    "zoneId": "8",
    "metaId": "5626",
    "nftId": 1413,
    "creationDate": "1691407733935524",
    "id": "1413",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "8",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1414",
    "zoneId": "8",
    "metaId": "6659",
    "nftId": 1414,
    "creationDate": "1691407733935524",
    "id": "1414",
    "mintedVia": "CROWN",
  },
  {
    "x": "-96",
    "y": "-18",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1415",
    "zoneId": "8",
    "metaId": "6510",
    "nftId": 1415,
    "creationDate": "1691407733935524",
    "id": "1415",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "11",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1416",
    "zoneId": "8",
    "metaId": "5024",
    "nftId": 1416,
    "creationDate": "1691407733935524",
    "id": "1416",
    "mintedVia": "CROWN",
  },
  {
    "x": "10",
    "y": "17",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1417",
    "zoneId": "8",
    "metaId": "4897",
    "nftId": 1417,
    "creationDate": "1691407785904633",
    "id": "1417",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "22",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1418",
    "zoneId": "8",
    "metaId": "4766",
    "nftId": 1418,
    "creationDate": "1691407785904633",
    "id": "1418",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "10",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1419",
    "zoneId": "8",
    "metaId": "4849",
    "nftId": 1419,
    "creationDate": "1691407785904633",
    "id": "1419",
    "mintedVia": "CROWN",
  },
  {
    "x": "-107",
    "y": "-6",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1420",
    "zoneId": "8",
    "metaId": "6190",
    "nftId": 1420,
    "creationDate": "1691407785904633",
    "id": "1420",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "25",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1421",
    "zoneId": "8",
    "metaId": "5000",
    "nftId": 1421,
    "creationDate": "1691407785904633",
    "id": "1421",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "10",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1422",
    "zoneId": "8",
    "metaId": "6481",
    "nftId": 1422,
    "creationDate": "1691407826006925",
    "id": "1422",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "30",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1423",
    "zoneId": "8",
    "metaId": "5501",
    "nftId": 1423,
    "creationDate": "1691407826006925",
    "id": "1423",
    "mintedVia": "CROWN",
  },
  {
    "x": "46",
    "y": "17",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1424",
    "zoneId": "8",
    "metaId": "4828",
    "nftId": 1424,
    "creationDate": "1691407826006925",
    "id": "1424",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "-2",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1425",
    "zoneId": "8",
    "metaId": "6693",
    "nftId": 1425,
    "creationDate": "1691407826006925",
    "id": "1425",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "3",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1426",
    "zoneId": "8",
    "metaId": "5350",
    "nftId": 1426,
    "creationDate": "1691407826006925",
    "id": "1426",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "26",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1427",
    "zoneId": "8",
    "metaId": "4949",
    "nftId": 1427,
    "creationDate": "1691407874110916",
    "id": "1427",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "-7",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1428",
    "zoneId": "8",
    "metaId": "5789",
    "nftId": 1428,
    "creationDate": "1691407874110916",
    "id": "1428",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "6",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1429",
    "zoneId": "8",
    "metaId": "5883",
    "nftId": 1429,
    "creationDate": "1691407874110916",
    "id": "1429",
    "mintedVia": "CROWN",
  },
  {
    "x": "3",
    "y": "15",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1430",
    "zoneId": "7",
    "metaId": "3906",
    "nftId": 1430,
    "creationDate": "1691407915988853",
    "id": "1430",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "0",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1431",
    "zoneId": "7",
    "metaId": "4362",
    "nftId": 1431,
    "creationDate": "1691407915988853",
    "id": "1431",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "17",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1432",
    "zoneId": "7",
    "metaId": "1838",
    "nftId": 1432,
    "creationDate": "1691407915988853",
    "id": "1432",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "-2",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1433",
    "zoneId": "7",
    "metaId": "4085",
    "nftId": 1433,
    "creationDate": "1691407915988853",
    "id": "1433",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "27",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1434",
    "zoneId": "7",
    "metaId": "3714",
    "nftId": 1434,
    "creationDate": "1691407915988853",
    "id": "1434",
    "mintedVia": "CROWN",
  },
  {
    "x": "58",
    "y": "18",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1435",
    "zoneId": "7",
    "metaId": "2148",
    "nftId": 1435,
    "creationDate": "1691407972041575",
    "id": "1435",
    "mintedVia": "CROWN",
  },
  {
    "x": "-10",
    "y": "-10",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1436",
    "zoneId": "7",
    "metaId": "2842",
    "nftId": 1436,
    "creationDate": "1691407972041575",
    "id": "1436",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "8",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1437",
    "zoneId": "7",
    "metaId": "2762",
    "nftId": 1437,
    "creationDate": "1691407972041575",
    "id": "1437",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "13",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1438",
    "zoneId": "7",
    "metaId": "4330",
    "nftId": 1438,
    "creationDate": "1691407972041575",
    "id": "1438",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "1",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1439",
    "zoneId": "7",
    "metaId": "2206",
    "nftId": 1439,
    "creationDate": "1691407972041575",
    "id": "1439",
    "mintedVia": "CROWN",
  },
  {
    "x": "40",
    "y": "24",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1440",
    "zoneId": "1",
    "metaId": "9",
    "nftId": 1440,
    "creationDate": "1691408024162966",
    "id": "1440",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "-5",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1441",
    "zoneId": "4",
    "metaId": "1291",
    "nftId": 1441,
    "creationDate": "1691408051989155",
    "id": "1441",
    "mintedVia": "CROWN",
  },
  {
    "x": "-65",
    "y": "-28",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1442",
    "zoneId": "2",
    "metaId": "893",
    "nftId": 1442,
    "creationDate": "1691408080067906",
    "id": "1442",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "-23",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1443",
    "zoneId": "2",
    "metaId": "752",
    "nftId": 1443,
    "creationDate": "1691408080067906",
    "id": "1443",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "-23",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1444",
    "zoneId": "2",
    "metaId": "757",
    "nftId": 1444,
    "creationDate": "1691408080067906",
    "id": "1444",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "18",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1445",
    "zoneId": "3",
    "metaId": "1092",
    "nftId": 1445,
    "creationDate": "1691408116046956",
    "id": "1445",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "-1",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1446",
    "zoneId": "6",
    "metaId": "1802",
    "nftId": 1446,
    "creationDate": "1691408144151999",
    "id": "1446",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "-4",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1447",
    "zoneId": "6",
    "metaId": "1822",
    "nftId": 1447,
    "creationDate": "1691408144151999",
    "id": "1447",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "-5",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1448",
    "zoneId": "6",
    "metaId": "1751",
    "nftId": 1448,
    "creationDate": "1691408144151999",
    "id": "1448",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "10",
    "owner": "hx2797578bb0ff53ef5bbb99642bf41cf7bbda3603",
    "name": "Emerald City Land #1449",
    "zoneId": "7",
    "metaId": "4003",
    "nftId": 1449,
    "creationDate": "1691408462204031",
    "id": "1449",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "18",
    "owner": "hx2797578bb0ff53ef5bbb99642bf41cf7bbda3603",
    "name": "Emerald City Land #1450",
    "zoneId": "7",
    "metaId": "1966",
    "nftId": 1450,
    "creationDate": "1691408462204031",
    "id": "1450",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "20",
    "owner": "hx2797578bb0ff53ef5bbb99642bf41cf7bbda3603",
    "name": "Emerald City Land #1451",
    "zoneId": "7",
    "metaId": "1845",
    "nftId": 1451,
    "creationDate": "1691408462204031",
    "id": "1451",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "-17",
    "owner": "hx2797578bb0ff53ef5bbb99642bf41cf7bbda3603",
    "name": "Emerald City Land #1452",
    "zoneId": "7",
    "metaId": "4601",
    "nftId": 1452,
    "creationDate": "1691408462204031",
    "id": "1452",
    "mintedVia": "CROWN",
  },
  {
    "x": "15",
    "y": "7",
    "owner": "hx2797578bb0ff53ef5bbb99642bf41cf7bbda3603",
    "name": "Emerald City Land #1453",
    "zoneId": "7",
    "metaId": "3213",
    "nftId": 1453,
    "creationDate": "1691408462204031",
    "id": "1453",
    "mintedVia": "CROWN",
  },
  {
    "x": "58",
    "y": "14",
    "owner": "hx2797578bb0ff53ef5bbb99642bf41cf7bbda3603",
    "name": "Emerald City Land #1454",
    "zoneId": "8",
    "metaId": "5036",
    "nftId": 1454,
    "creationDate": "1691408618342315",
    "id": "1454",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "9",
    "owner": "hx2797578bb0ff53ef5bbb99642bf41cf7bbda3603",
    "name": "Emerald City Land #1455",
    "zoneId": "8",
    "metaId": "5089",
    "nftId": 1455,
    "creationDate": "1691408618342315",
    "id": "1455",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "29",
    "owner": "hx2797578bb0ff53ef5bbb99642bf41cf7bbda3603",
    "name": "Emerald City Land #1456",
    "zoneId": "8",
    "metaId": "4806",
    "nftId": 1456,
    "creationDate": "1691408618342315",
    "id": "1456",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "27",
    "owner": "hx2797578bb0ff53ef5bbb99642bf41cf7bbda3603",
    "name": "Emerald City Land #1457",
    "zoneId": "8",
    "metaId": "5224",
    "nftId": 1457,
    "creationDate": "1691408618342315",
    "id": "1457",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "9",
    "owner": "hx2797578bb0ff53ef5bbb99642bf41cf7bbda3603",
    "name": "Emerald City Land #1458",
    "zoneId": "8",
    "metaId": "5920",
    "nftId": 1458,
    "creationDate": "1691408618342315",
    "id": "1458",
    "mintedVia": "CROWN",
  },
  {
    "x": "-4",
    "y": "33",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1459",
    "zoneId": "3",
    "metaId": "1004",
    "nftId": 1459,
    "creationDate": "1691418097052683",
    "id": "1459",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "2",
    "owner": "hxaa7ff8c307514599fbee45351613448e7a906327",
    "name": "Emerald City Land #1460",
    "zoneId": "8",
    "metaId": "5107",
    "nftId": 1460,
    "creationDate": "1691424871114654",
    "id": "1460",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "7",
    "owner": "hxaa7ff8c307514599fbee45351613448e7a906327",
    "name": "Emerald City Land #1461",
    "zoneId": "8",
    "metaId": "5702",
    "nftId": 1461,
    "creationDate": "1691424871114654",
    "id": "1461",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "-17",
    "owner": "hxaa7ff8c307514599fbee45351613448e7a906327",
    "name": "Emerald City Land #1462",
    "zoneId": "8",
    "metaId": "5359",
    "nftId": 1462,
    "creationDate": "1691424871114654",
    "id": "1462",
    "mintedVia": "CROWN",
  },
  {
    "x": "24",
    "y": "28",
    "owner": "hxaa7ff8c307514599fbee45351613448e7a906327",
    "name": "Emerald City Land #1463",
    "zoneId": "8",
    "metaId": "4711",
    "nftId": 1463,
    "creationDate": "1691424871114654",
    "id": "1463",
    "mintedVia": "CROWN",
  },
  {
    "x": "-65",
    "y": "21",
    "owner": "hxaa7ff8c307514599fbee45351613448e7a906327",
    "name": "Emerald City Land #1464",
    "zoneId": "8",
    "metaId": "6215",
    "nftId": 1464,
    "creationDate": "1691424871114654",
    "id": "1464",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "10",
    "owner": "hxaa7ff8c307514599fbee45351613448e7a906327",
    "name": "Emerald City Land #1465",
    "zoneId": "7",
    "metaId": "2032",
    "nftId": 1465,
    "creationDate": "1691424969156176",
    "id": "1465",
    "mintedVia": "CROWN",
  },
  {
    "x": "-46",
    "y": "14",
    "owner": "hxaa7ff8c307514599fbee45351613448e7a906327",
    "name": "Emerald City Land #1466",
    "zoneId": "8",
    "metaId": "6694",
    "nftId": 1466,
    "creationDate": "1691425227299682",
    "id": "1466",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "-4",
    "owner": "hxaa7ff8c307514599fbee45351613448e7a906327",
    "name": "Emerald City Land #1467",
    "zoneId": "8",
    "metaId": "5889",
    "nftId": 1467,
    "creationDate": "1691425227299682",
    "id": "1467",
    "mintedVia": "CROWN",
  },
  {
    "x": "10",
    "y": "15",
    "owner": "hxaa7ff8c307514599fbee45351613448e7a906327",
    "name": "Emerald City Land #1468",
    "zoneId": "8",
    "metaId": "4668",
    "nftId": 1468,
    "creationDate": "1691425227299682",
    "id": "1468",
    "mintedVia": "CROWN",
  },
  {
    "x": "-66",
    "y": "-32",
    "owner": "hxdc166678ce876bef99affd1dd95b0425ed0267a1",
    "name": "Emerald City Land #1469",
    "zoneId": "2",
    "metaId": "898",
    "nftId": 1469,
    "creationDate": "1691500831871107",
    "id": "1469",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "7",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1470",
    "zoneId": "7",
    "metaId": "2661",
    "nftId": 1470,
    "creationDate": "1691506954805433",
    "id": "1470",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "24",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1471",
    "zoneId": "7",
    "metaId": "2209",
    "nftId": 1471,
    "creationDate": "1691506954805433",
    "id": "1471",
    "mintedVia": "CROWN",
  },
  {
    "x": "-11",
    "y": "14",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1472",
    "zoneId": "7",
    "metaId": "3394",
    "nftId": 1472,
    "creationDate": "1691506954805433",
    "id": "1472",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "26",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1473",
    "zoneId": "7",
    "metaId": "4150",
    "nftId": 1473,
    "creationDate": "1691506954805433",
    "id": "1473",
    "mintedVia": "CROWN",
  },
  {
    "x": "-11",
    "y": "17",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1474",
    "zoneId": "7",
    "metaId": "3396",
    "nftId": 1474,
    "creationDate": "1691506954805433",
    "id": "1474",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "-6",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1475",
    "zoneId": "8",
    "metaId": "6777",
    "nftId": 1475,
    "creationDate": "1691507066811517",
    "id": "1475",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-24",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1476",
    "zoneId": "2",
    "metaId": "797",
    "nftId": 1476,
    "creationDate": "1691507116828349",
    "id": "1476",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "12",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1477",
    "zoneId": "7",
    "metaId": "3498",
    "nftId": 1477,
    "creationDate": "1691507222893843",
    "id": "1477",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "21",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1478",
    "zoneId": "8",
    "metaId": "4886",
    "nftId": 1478,
    "creationDate": "1691507260944776",
    "id": "1478",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "6",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1479",
    "zoneId": "8",
    "metaId": "6800",
    "nftId": 1479,
    "creationDate": "1691507260944776",
    "id": "1479",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "24",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1480",
    "zoneId": "8",
    "metaId": "4836",
    "nftId": 1480,
    "creationDate": "1691507260944776",
    "id": "1480",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "12",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1481",
    "zoneId": "8",
    "metaId": "5795",
    "nftId": 1481,
    "creationDate": "1691507260944776",
    "id": "1481",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "-4",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1482",
    "zoneId": "8",
    "metaId": "6869",
    "nftId": 1482,
    "creationDate": "1691507260944776",
    "id": "1482",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "33",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1483",
    "zoneId": "3",
    "metaId": "1056",
    "nftId": 1483,
    "creationDate": "1691507329059373",
    "id": "1483",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "1",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1484",
    "zoneId": "1",
    "metaId": "170",
    "nftId": 1484,
    "creationDate": "1691507372937962",
    "id": "1484",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "-7",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1485",
    "zoneId": "1",
    "metaId": "356",
    "nftId": 1485,
    "creationDate": "1691507372937962",
    "id": "1485",
    "mintedVia": "CROWN",
  },
  {
    "x": "-107",
    "y": "-5",
    "owner": "hx4ef08ea410d3f89b75588fe08c1439e467d682a3",
    "name": "Emerald City Land #1486",
    "zoneId": "8",
    "metaId": "6189",
    "nftId": 1486,
    "creationDate": "1691507412999149",
    "id": "1486",
    "mintedVia": "CROWN",
  },
  {
    "x": "40",
    "y": "25",
    "owner": "hxeeaa8bda8d9a2c895ee5874af91c59be0c8ce22e",
    "name": "Emerald City Land #1487",
    "zoneId": "1",
    "metaId": "6",
    "nftId": 1487,
    "creationDate": "1691510067745151",
    "id": "1487",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "25",
    "owner": "hxc09bc9c068cb31adadb69c071ffce2e8d9930200",
    "name": "Emerald City Land #1488",
    "zoneId": "8",
    "metaId": "5264",
    "nftId": 1488,
    "creationDate": "1691515009478432",
    "id": "1488",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "3",
    "owner": "hxbe0f52adc85195dbaf041149095f9f8807827dff",
    "name": "Emerald City Land #1489",
    "zoneId": "8",
    "metaId": "5427",
    "nftId": 1489,
    "creationDate": "1691565132788273",
    "id": "1489",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "-25",
    "owner": "hx1be60841025db1b22126ba08ba519326603029c9",
    "name": "Emerald City Land #1490",
    "zoneId": "7",
    "metaId": "4534",
    "nftId": 1490,
    "creationDate": "1691576398438063",
    "id": "1490",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "-5",
    "owner": "hx1be60841025db1b22126ba08ba519326603029c9",
    "name": "Emerald City Land #1491",
    "zoneId": "7",
    "metaId": "2203",
    "nftId": 1491,
    "creationDate": "1691576398438063",
    "id": "1491",
    "mintedVia": "CROWN",
  },
  {
    "x": "39",
    "y": "-5",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #1492",
    "zoneId": "5",
    "metaId": "1594",
    "nftId": 1492,
    "creationDate": "1691599464427621",
    "id": "1492",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "21",
    "owner": "hx40c9ad7c54c30cd97c3f6c79d4ca92acc702446a",
    "name": "Emerald City Land #1493",
    "zoneId": "7",
    "metaId": "1910",
    "nftId": 1493,
    "creationDate": "1691652361919862",
    "id": "1493",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "25",
    "owner": "hxbe0f52adc85195dbaf041149095f9f8807827dff",
    "name": "Emerald City Land #1494",
    "zoneId": "8",
    "metaId": "5536",
    "nftId": 1494,
    "creationDate": "1691652784016744",
    "id": "1494",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "-6",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #1495",
    "zoneId": "6",
    "metaId": "1701",
    "nftId": 1495,
    "creationDate": "1691674510239373",
    "id": "1495",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "6",
    "owner": "hx1120530460a62f04f59c3378dc8ecc103304d4ba",
    "name": "Emerald City Land #1496",
    "zoneId": "6",
    "metaId": "1727",
    "nftId": 1496,
    "creationDate": "1691674510239373",
    "id": "1496",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "8",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1497",
    "zoneId": "8",
    "metaId": "6770",
    "nftId": 1497,
    "creationDate": "1691721961833237",
    "id": "1497",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "23",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1498",
    "zoneId": "8",
    "metaId": "5382",
    "nftId": 1498,
    "creationDate": "1691721961833237",
    "id": "1498",
    "mintedVia": "CROWN",
  },
  {
    "x": "-95",
    "y": "-4",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1499",
    "zoneId": "8",
    "metaId": "6495",
    "nftId": 1499,
    "creationDate": "1691721961833237",
    "id": "1499",
    "mintedVia": "CROWN",
  },
  {
    "x": "-68",
    "y": "-29",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1500",
    "zoneId": "2",
    "metaId": "905",
    "nftId": 1500,
    "creationDate": "1691722039849098",
    "id": "1500",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "9",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1501",
    "zoneId": "7",
    "metaId": "4056",
    "nftId": 1501,
    "creationDate": "1691722076023495",
    "id": "1501",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "25",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1502",
    "zoneId": "7",
    "metaId": "3668",
    "nftId": 1502,
    "creationDate": "1691722076023495",
    "id": "1502",
    "mintedVia": "CROWN",
  },
  {
    "x": "-109",
    "y": "-6",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1503",
    "zoneId": "7",
    "metaId": "2308",
    "nftId": 1503,
    "creationDate": "1691722076023495",
    "id": "1503",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "35",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1504",
    "zoneId": "7",
    "metaId": "4498",
    "nftId": 1504,
    "creationDate": "1691722076023495",
    "id": "1504",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "8",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1505",
    "zoneId": "7",
    "metaId": "4082",
    "nftId": 1505,
    "creationDate": "1691722076023495",
    "id": "1505",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "32",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1506",
    "zoneId": "3",
    "metaId": "988",
    "nftId": 1506,
    "creationDate": "1691722129938002",
    "id": "1506",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "22",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1507",
    "zoneId": "4",
    "metaId": "1436",
    "nftId": 1507,
    "creationDate": "1691740952277351",
    "id": "1507",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "5",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1508",
    "zoneId": "1",
    "metaId": "278",
    "nftId": 1508,
    "creationDate": "1691740998492099",
    "id": "1508",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "9",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1509",
    "zoneId": "1",
    "metaId": "315",
    "nftId": 1509,
    "creationDate": "1691740998492099",
    "id": "1509",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "6",
    "owner": "hx7ef870564de162a3e74fc58f79cb75b5a4e8bf1a",
    "name": "Emerald City Land #1510",
    "zoneId": "7",
    "metaId": "4286",
    "nftId": 1510,
    "creationDate": "1691741038331252",
    "id": "1510",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "2",
    "owner": "hx06efa9167ece33af37f7153dc53bce2775f812f2",
    "name": "Emerald City Land #1511",
    "zoneId": "7",
    "metaId": "3139",
    "nftId": 1511,
    "creationDate": "1691782869315593",
    "id": "1511",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "7",
    "owner": "hx06efa9167ece33af37f7153dc53bce2775f812f2",
    "name": "Emerald City Land #1512",
    "zoneId": "8",
    "metaId": "5313",
    "nftId": 1512,
    "creationDate": "1691782971365586",
    "id": "1512",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "11",
    "owner": "hxd13b42187fd9a9de63a6bd1c8f0b760cd465c5c5",
    "name": "Emerald City Land #1513",
    "zoneId": "7",
    "metaId": "3673",
    "nftId": 1513,
    "creationDate": "1691799917310630",
    "id": "1513",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "-19",
    "owner": "hxd13b42187fd9a9de63a6bd1c8f0b760cd465c5c5",
    "name": "Emerald City Land #1514",
    "zoneId": "1",
    "metaId": "601",
    "nftId": 1514,
    "creationDate": "1691800039301825",
    "id": "1514",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-17",
    "owner": "hxd13b42187fd9a9de63a6bd1c8f0b760cd465c5c5",
    "name": "Emerald City Land #1515",
    "zoneId": "1",
    "metaId": "654",
    "nftId": 1515,
    "creationDate": "1691800039301825",
    "id": "1515",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "0",
    "owner": "hxd13b42187fd9a9de63a6bd1c8f0b760cd465c5c5",
    "name": "Emerald City Land #1516",
    "zoneId": "8",
    "metaId": "5523",
    "nftId": 1516,
    "creationDate": "1691800129345917",
    "id": "1516",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "20",
    "owner": "hxac1fab2747feb0e7fe06e8185d9bfc5ac1f3cd3f",
    "name": "Emerald City Land #1517",
    "zoneId": "7",
    "metaId": "1944",
    "nftId": 1517,
    "creationDate": "1691987549604107",
    "id": "1517",
    "mintedVia": "CROWN",
  },
  {
    "x": "-18",
    "y": "27",
    "owner": "hxb2e1f1f3a78dbb6edf3b5f35378d7127bcaf4f37",
    "name": "Emerald City Land #1518",
    "zoneId": "7",
    "metaId": "3523",
    "nftId": 1518,
    "creationDate": "1692003998794468",
    "id": "1518",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "22",
    "owner": "hxb2e1f1f3a78dbb6edf3b5f35378d7127bcaf4f37",
    "name": "Emerald City Land #1519",
    "zoneId": "8",
    "metaId": "6037",
    "nftId": 1519,
    "creationDate": "1692004131066911",
    "id": "1519",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "-18",
    "owner": "hxb2e1f1f3a78dbb6edf3b5f35378d7127bcaf4f37",
    "name": "Emerald City Land #1520",
    "zoneId": "8",
    "metaId": "5400",
    "nftId": 1520,
    "creationDate": "1692004131066911",
    "id": "1520",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-35",
    "owner": "hxb2e1f1f3a78dbb6edf3b5f35378d7127bcaf4f37",
    "name": "Emerald City Land #1521",
    "zoneId": "2",
    "metaId": "800",
    "nftId": 1521,
    "creationDate": "1692004465166377",
    "id": "1521",
    "mintedVia": "CROWN",
  },
  {
    "x": "0",
    "y": "10",
    "owner": "hxb2e1f1f3a78dbb6edf3b5f35378d7127bcaf4f37",
    "name": "Emerald City Land #1522",
    "zoneId": "4",
    "metaId": "1398",
    "nftId": 1522,
    "creationDate": "1692004494956827",
    "id": "1522",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "7",
    "owner": "hxbd702c57ade86d078770ccda492634f0470cde0d",
    "name": "Emerald City Land #1523",
    "zoneId": "8",
    "metaId": "5505",
    "nftId": 1523,
    "creationDate": "1692112816642155",
    "id": "1523",
    "mintedVia": "CROWN",
  },
  {
    "x": "-83",
    "y": "15",
    "owner": "hxbed3dc5ea601b86ea6f7e3003fcdee41d9d9e032",
    "name": "Emerald City Land #1524",
    "zoneId": "8",
    "metaId": "6350",
    "nftId": 1524,
    "creationDate": "1692122767821498",
    "id": "1524",
    "mintedVia": "CROWN",
  },
  {
    "x": "23",
    "y": "4",
    "owner": "hxbed3dc5ea601b86ea6f7e3003fcdee41d9d9e032",
    "name": "Emerald City Land #1525",
    "zoneId": "1",
    "metaId": "442",
    "nftId": 1525,
    "creationDate": "1692122851902961",
    "id": "1525",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "24",
    "owner": "hxbed3dc5ea601b86ea6f7e3003fcdee41d9d9e032",
    "name": "Emerald City Land #1526",
    "zoneId": "7",
    "metaId": "3667",
    "nftId": 1526,
    "creationDate": "1692122911793183",
    "id": "1526",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "11",
    "owner": "hxe00c66fa8b5421f232d30c8f9ecb76ddb7b025cc",
    "name": "Emerald City Land #1527",
    "zoneId": "4",
    "metaId": "1197",
    "nftId": 1527,
    "creationDate": "1692127696452443",
    "id": "1527",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "6",
    "owner": "hxff7eb815ca57e12bf6c3b4a0c11776def9977abc",
    "name": "Emerald City Land #1528",
    "zoneId": "1",
    "metaId": "279",
    "nftId": 1528,
    "creationDate": "1692212759243077",
    "id": "1528",
    "mintedVia": "CROWN",
  },
  {
    "x": "-67",
    "y": "-29",
    "owner": "hxff7eb815ca57e12bf6c3b4a0c11776def9977abc",
    "name": "Emerald City Land #1529",
    "zoneId": "2",
    "metaId": "900",
    "nftId": 1529,
    "creationDate": "1692212989217252",
    "id": "1529",
    "mintedVia": "CROWN",
  },
  {
    "x": "-92",
    "y": "1",
    "owner": "hx7064eae70f3a12a4f358abbc0059cf87de61c2d8",
    "name": "Emerald City Land #1530",
    "zoneId": "8",
    "metaId": "6440",
    "nftId": 1530,
    "creationDate": "1692404429835300",
    "id": "1530",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-36",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1531",
    "zoneId": "2",
    "metaId": "843",
    "nftId": 1531,
    "creationDate": "1692435710711444",
    "id": "1531",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "-26",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1532",
    "zoneId": "2",
    "metaId": "753",
    "nftId": 1532,
    "creationDate": "1692435710711444",
    "id": "1532",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "-36",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1533",
    "zoneId": "2",
    "metaId": "781",
    "nftId": 1533,
    "creationDate": "1692435710711444",
    "id": "1533",
    "mintedVia": "CROWN",
  },
  {
    "x": "-62",
    "y": "-26",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1534",
    "zoneId": "2",
    "metaId": "885",
    "nftId": 1534,
    "creationDate": "1692435710711444",
    "id": "1534",
    "mintedVia": "CROWN",
  },
  {
    "x": "-69",
    "y": "-27",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1535",
    "zoneId": "2",
    "metaId": "907",
    "nftId": 1535,
    "creationDate": "1692435710711444",
    "id": "1535",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "-32",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1536",
    "zoneId": "2",
    "metaId": "862",
    "nftId": 1536,
    "creationDate": "1692435914979094",
    "id": "1536",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "-33",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1537",
    "zoneId": "2",
    "metaId": "863",
    "nftId": 1537,
    "creationDate": "1692435914979094",
    "id": "1537",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "-22",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1538",
    "zoneId": "2",
    "metaId": "756",
    "nftId": 1538,
    "creationDate": "1692435914979094",
    "id": "1538",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "-21",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1539",
    "zoneId": "2",
    "metaId": "774",
    "nftId": 1539,
    "creationDate": "1692435914979094",
    "id": "1539",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "-9",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1540",
    "zoneId": "2",
    "metaId": "734",
    "nftId": 1540,
    "creationDate": "1692435914979094",
    "id": "1540",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "25",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1541",
    "zoneId": "7",
    "metaId": "4015",
    "nftId": 1541,
    "creationDate": "1692436080837739",
    "id": "1541",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "24",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1542",
    "zoneId": "7",
    "metaId": "3421",
    "nftId": 1542,
    "creationDate": "1692436080837739",
    "id": "1542",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "11",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1543",
    "zoneId": "7",
    "metaId": "1867",
    "nftId": 1543,
    "creationDate": "1692436080837739",
    "id": "1543",
    "mintedVia": "CROWN",
  },
  {
    "x": "32",
    "y": "-10",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1544",
    "zoneId": "7",
    "metaId": "2448",
    "nftId": 1544,
    "creationDate": "1692436080837739",
    "id": "1544",
    "mintedVia": "CROWN",
  },
  {
    "x": "-100",
    "y": "7",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1545",
    "zoneId": "7",
    "metaId": "2285",
    "nftId": 1545,
    "creationDate": "1692436080837739",
    "id": "1545",
    "mintedVia": "CROWN",
  },
  {
    "x": "0",
    "y": "14",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1546",
    "zoneId": "7",
    "metaId": "3854",
    "nftId": 1546,
    "creationDate": "1692436151042929",
    "id": "1546",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "17",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1547",
    "zoneId": "7",
    "metaId": "4450",
    "nftId": 1547,
    "creationDate": "1692436151042929",
    "id": "1547",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "11",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1548",
    "zoneId": "7",
    "metaId": "4311",
    "nftId": 1548,
    "creationDate": "1692436151042929",
    "id": "1548",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "27",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1549",
    "zoneId": "7",
    "metaId": "4102",
    "nftId": 1549,
    "creationDate": "1692436151042929",
    "id": "1549",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "-32",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1550",
    "zoneId": "7",
    "metaId": "4634",
    "nftId": 1550,
    "creationDate": "1692436151042929",
    "id": "1550",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "0",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1551",
    "zoneId": "7",
    "metaId": "4058",
    "nftId": 1551,
    "creationDate": "1692436216890697",
    "id": "1551",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "15",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1552",
    "zoneId": "7",
    "metaId": "4422",
    "nftId": 1552,
    "creationDate": "1692436216890697",
    "id": "1552",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "12",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1553",
    "zoneId": "7",
    "metaId": "3263",
    "nftId": 1553,
    "creationDate": "1692436216890697",
    "id": "1553",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "5",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1554",
    "zoneId": "7",
    "metaId": "2709",
    "nftId": 1554,
    "creationDate": "1692436216890697",
    "id": "1554",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "-9",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1555",
    "zoneId": "7",
    "metaId": "2814",
    "nftId": 1555,
    "creationDate": "1692436216890697",
    "id": "1555",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "2",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1556",
    "zoneId": "7",
    "metaId": "2485",
    "nftId": 1556,
    "creationDate": "1692436289043900",
    "id": "1556",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "-10",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1557",
    "zoneId": "7",
    "metaId": "2389",
    "nftId": 1557,
    "creationDate": "1692436289043900",
    "id": "1557",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "5",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1558",
    "zoneId": "7",
    "metaId": "2671",
    "nftId": 1558,
    "creationDate": "1692436289043900",
    "id": "1558",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "14",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1559",
    "zoneId": "7",
    "metaId": "4434",
    "nftId": 1559,
    "creationDate": "1692436289043900",
    "id": "1559",
    "mintedVia": "CROWN",
  },
  {
    "x": "8",
    "y": "4",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1560",
    "zoneId": "7",
    "metaId": "3354",
    "nftId": 1560,
    "creationDate": "1692436289043900",
    "id": "1560",
    "mintedVia": "CROWN",
  },
  {
    "x": "-102",
    "y": "-15",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1561",
    "zoneId": "7",
    "metaId": "2293",
    "nftId": 1561,
    "creationDate": "1692436356932731",
    "id": "1561",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "17",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1562",
    "zoneId": "7",
    "metaId": "4401",
    "nftId": 1562,
    "creationDate": "1692436356932731",
    "id": "1562",
    "mintedVia": "CROWN",
  },
  {
    "x": "-18",
    "y": "6",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1563",
    "zoneId": "7",
    "metaId": "3524",
    "nftId": 1563,
    "creationDate": "1692436356932731",
    "id": "1563",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "29",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1564",
    "zoneId": "7",
    "metaId": "4461",
    "nftId": 1564,
    "creationDate": "1692436356932731",
    "id": "1564",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "15",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1565",
    "zoneId": "7",
    "metaId": "4006",
    "nftId": 1565,
    "creationDate": "1692436356932731",
    "id": "1565",
    "mintedVia": "CROWN",
  },
  {
    "x": "12",
    "y": "34",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1566",
    "zoneId": "7",
    "metaId": "1916",
    "nftId": 1566,
    "creationDate": "1692436414997517",
    "id": "1566",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "-12",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1567",
    "zoneId": "5",
    "metaId": "1661",
    "nftId": 1567,
    "creationDate": "1692436442989130",
    "id": "1567",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "20",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1568",
    "zoneId": "4",
    "metaId": "1473",
    "nftId": 1568,
    "creationDate": "1692436481002008",
    "id": "1568",
    "mintedVia": "CROWN",
  },
  {
    "x": "-13",
    "y": "21",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1569",
    "zoneId": "4",
    "metaId": "1423",
    "nftId": 1569,
    "creationDate": "1692436481002008",
    "id": "1569",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "17",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1570",
    "zoneId": "4",
    "metaId": "1482",
    "nftId": 1570,
    "creationDate": "1692436481002008",
    "id": "1570",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "12",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1571",
    "zoneId": "4",
    "metaId": "1480",
    "nftId": 1571,
    "creationDate": "1692436481002008",
    "id": "1571",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "12",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1572",
    "zoneId": "4",
    "metaId": "1510",
    "nftId": 1572,
    "creationDate": "1692436481002008",
    "id": "1572",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "18",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1573",
    "zoneId": "4",
    "metaId": "1221",
    "nftId": 1573,
    "creationDate": "1692436547041360",
    "id": "1573",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "14",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1574",
    "zoneId": "4",
    "metaId": "1492",
    "nftId": 1574,
    "creationDate": "1692436547041360",
    "id": "1574",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "26",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1575",
    "zoneId": "4",
    "metaId": "1437",
    "nftId": 1575,
    "creationDate": "1692436547041360",
    "id": "1575",
    "mintedVia": "CROWN",
  },
  {
    "x": "14",
    "y": "17",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1576",
    "zoneId": "4",
    "metaId": "1177",
    "nftId": 1576,
    "creationDate": "1692436547041360",
    "id": "1576",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "10",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1577",
    "zoneId": "4",
    "metaId": "1509",
    "nftId": 1577,
    "creationDate": "1692436547041360",
    "id": "1577",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "28",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1578",
    "zoneId": "3",
    "metaId": "1065",
    "nftId": 1578,
    "creationDate": "1692436615053937",
    "id": "1578",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "19",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1579",
    "zoneId": "3",
    "metaId": "1085",
    "nftId": 1579,
    "creationDate": "1692436615053937",
    "id": "1579",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "32",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1580",
    "zoneId": "3",
    "metaId": "1110",
    "nftId": 1580,
    "creationDate": "1692436615053937",
    "id": "1580",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "31",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1581",
    "zoneId": "3",
    "metaId": "1114",
    "nftId": 1581,
    "creationDate": "1692436615053937",
    "id": "1581",
    "mintedVia": "CROWN",
  },
  {
    "x": "11",
    "y": "30",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1582",
    "zoneId": "3",
    "metaId": "922",
    "nftId": 1582,
    "creationDate": "1692436615053937",
    "id": "1582",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "36",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1583",
    "zoneId": "3",
    "metaId": "1001",
    "nftId": 1583,
    "creationDate": "1692436663075370",
    "id": "1583",
    "mintedVia": "CROWN",
  },
  {
    "x": "3",
    "y": "31",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1584",
    "zoneId": "3",
    "metaId": "1047",
    "nftId": 1584,
    "creationDate": "1692436663075370",
    "id": "1584",
    "mintedVia": "CROWN",
  },
  {
    "x": "1",
    "y": "33",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1585",
    "zoneId": "3",
    "metaId": "1038",
    "nftId": 1585,
    "creationDate": "1692436663075370",
    "id": "1585",
    "mintedVia": "CROWN",
  },
  {
    "x": "3",
    "y": "32",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1586",
    "zoneId": "3",
    "metaId": "1048",
    "nftId": 1586,
    "creationDate": "1692436663075370",
    "id": "1586",
    "mintedVia": "CROWN",
  },
  {
    "x": "3",
    "y": "30",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1587",
    "zoneId": "3",
    "metaId": "1046",
    "nftId": 1587,
    "creationDate": "1692436663075370",
    "id": "1587",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "18",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1588",
    "zoneId": "3",
    "metaId": "1084",
    "nftId": 1588,
    "creationDate": "1692436725071759",
    "id": "1588",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "26",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1589",
    "zoneId": "3",
    "metaId": "1068",
    "nftId": 1589,
    "creationDate": "1692436725071759",
    "id": "1589",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-4",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1590",
    "zoneId": "1",
    "metaId": "319",
    "nftId": 1590,
    "creationDate": "1692436769081614",
    "id": "1590",
    "mintedVia": "CROWN",
  },
  {
    "x": "-105",
    "y": "3",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1591",
    "zoneId": "1",
    "metaId": "117",
    "nftId": 1591,
    "creationDate": "1692436769081614",
    "id": "1591",
    "mintedVia": "CROWN",
  },
  {
    "x": "-69",
    "y": "-31",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1592",
    "zoneId": "1",
    "metaId": "729",
    "nftId": 1592,
    "creationDate": "1692436769081614",
    "id": "1592",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "14",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1593",
    "zoneId": "1",
    "metaId": "2",
    "nftId": 1593,
    "creationDate": "1692436769081614",
    "id": "1593",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "14",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1594",
    "zoneId": "1",
    "metaId": "89",
    "nftId": 1594,
    "creationDate": "1692436769081614",
    "id": "1594",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "11",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1595",
    "zoneId": "1",
    "metaId": "49",
    "nftId": 1595,
    "creationDate": "1692436831091578",
    "id": "1595",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "-9",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1596",
    "zoneId": "1",
    "metaId": "272",
    "nftId": 1596,
    "creationDate": "1692436831091578",
    "id": "1596",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "5",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1597",
    "zoneId": "1",
    "metaId": "363",
    "nftId": 1597,
    "creationDate": "1692436831091578",
    "id": "1597",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "12",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1598",
    "zoneId": "1",
    "metaId": "572",
    "nftId": 1598,
    "creationDate": "1692436831091578",
    "id": "1598",
    "mintedVia": "CROWN",
  },
  {
    "x": "-101",
    "y": "-20",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1599",
    "zoneId": "1",
    "metaId": "113",
    "nftId": 1599,
    "creationDate": "1692436831091578",
    "id": "1599",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "-8",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1600",
    "zoneId": "1",
    "metaId": "271",
    "nftId": 1600,
    "creationDate": "1692436885157212",
    "id": "1600",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "4",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1601",
    "zoneId": "1",
    "metaId": "543",
    "nftId": 1601,
    "creationDate": "1692436885157212",
    "id": "1601",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "-13",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1602",
    "zoneId": "1",
    "metaId": "715",
    "nftId": 1602,
    "creationDate": "1692436885157212",
    "id": "1602",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "4",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1603",
    "zoneId": "1",
    "metaId": "494",
    "nftId": 1603,
    "creationDate": "1692436885157212",
    "id": "1603",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "-16",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1604",
    "zoneId": "1",
    "metaId": "157",
    "nftId": 1604,
    "creationDate": "1692436885157212",
    "id": "1604",
    "mintedVia": "CROWN",
  },
  {
    "x": "-86",
    "y": "6",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1605",
    "zoneId": "1",
    "metaId": "144",
    "nftId": 1605,
    "creationDate": "1692436939348635",
    "id": "1605",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "5",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1606",
    "zoneId": "1",
    "metaId": "544",
    "nftId": 1606,
    "creationDate": "1692436939348635",
    "id": "1606",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "16",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1607",
    "zoneId": "8",
    "metaId": "5762",
    "nftId": 1607,
    "creationDate": "1692436969134174",
    "id": "1607",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "21",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1608",
    "zoneId": "8",
    "metaId": "5688",
    "nftId": 1608,
    "creationDate": "1692436969134174",
    "id": "1608",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "18",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1609",
    "zoneId": "8",
    "metaId": "4873",
    "nftId": 1609,
    "creationDate": "1692436969134174",
    "id": "1609",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "3",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1610",
    "zoneId": "8",
    "metaId": "6671",
    "nftId": 1610,
    "creationDate": "1692436969134174",
    "id": "1610",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "29",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1611",
    "zoneId": "8",
    "metaId": "5114",
    "nftId": 1611,
    "creationDate": "1692436969134174",
    "id": "1611",
    "mintedVia": "CROWN",
  },
  {
    "x": "14",
    "y": "22",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1612",
    "zoneId": "8",
    "metaId": "4808",
    "nftId": 1612,
    "creationDate": "1692437029152119",
    "id": "1612",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "-17",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1613",
    "zoneId": "8",
    "metaId": "5238",
    "nftId": 1613,
    "creationDate": "1692437029152119",
    "id": "1613",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "-9",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1614",
    "zoneId": "8",
    "metaId": "6942",
    "nftId": 1614,
    "creationDate": "1692437029152119",
    "id": "1614",
    "mintedVia": "CROWN",
  },
  {
    "x": "88",
    "y": "10",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1615",
    "zoneId": "8",
    "metaId": "6048",
    "nftId": 1615,
    "creationDate": "1692437029152119",
    "id": "1615",
    "mintedVia": "CROWN",
  },
  {
    "x": "10",
    "y": "28",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1616",
    "zoneId": "8",
    "metaId": "4922",
    "nftId": 1616,
    "creationDate": "1692437029152119",
    "id": "1616",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "-7",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1617",
    "zoneId": "8",
    "metaId": "5202",
    "nftId": 1617,
    "creationDate": "1692437091210591",
    "id": "1617",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "-6",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1618",
    "zoneId": "8",
    "metaId": "5600",
    "nftId": 1618,
    "creationDate": "1692437091210591",
    "id": "1618",
    "mintedVia": "CROWN",
  },
  {
    "x": "-101",
    "y": "-2",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1619",
    "zoneId": "8",
    "metaId": "6101",
    "nftId": 1619,
    "creationDate": "1692437091210591",
    "id": "1619",
    "mintedVia": "CROWN",
  },
  {
    "x": "-66",
    "y": "-29",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1620",
    "zoneId": "2",
    "metaId": "897",
    "nftId": 1620,
    "creationDate": "1692439684292942",
    "id": "1620",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "-25",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1621",
    "zoneId": "2",
    "metaId": "786",
    "nftId": 1621,
    "creationDate": "1692439684292942",
    "id": "1621",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "-4",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1622",
    "zoneId": "1",
    "metaId": "286",
    "nftId": 1622,
    "creationDate": "1692439732308353",
    "id": "1622",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "-2",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1623",
    "zoneId": "4",
    "metaId": "1275",
    "nftId": 1623,
    "creationDate": "1692440392465757",
    "id": "1623",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "17",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1624",
    "zoneId": "8",
    "metaId": "5296",
    "nftId": 1624,
    "creationDate": "1692440428561703",
    "id": "1624",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "26",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #1625",
    "zoneId": "8",
    "metaId": "5182",
    "nftId": 1625,
    "creationDate": "1692440742569461",
    "id": "1625",
    "mintedVia": "CROWN",
  },
  {
    "x": "84",
    "y": "-8",
    "owner": "hx3269ff5e9d2e05f4aa192b70b702a98fb82126a0",
    "name": "Emerald City Land #1626",
    "zoneId": "8",
    "metaId": "5927",
    "nftId": 1626,
    "creationDate": "1692543729385959",
    "id": "1626",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "10",
    "owner": "hx9d0a862cf1f19e95942fa2114883a6889b5cb602",
    "name": "Emerald City Land #1627",
    "zoneId": "8",
    "metaId": "5606",
    "nftId": 1627,
    "creationDate": "1692566250505918",
    "id": "1627",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "7",
    "owner": "hx9d0a862cf1f19e95942fa2114883a6889b5cb602",
    "name": "Emerald City Land #1628",
    "zoneId": "8",
    "metaId": "5853",
    "nftId": 1628,
    "creationDate": "1692566250505918",
    "id": "1628",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "-1",
    "owner": "hx16533f91c766ce4222e6bd4eceb270e08c9a29c8",
    "name": "Emerald City Land #1629",
    "zoneId": "5",
    "metaId": "1568",
    "nftId": 1629,
    "creationDate": "1692582471563426",
    "id": "1629",
    "mintedVia": "CROWN",
  },
  {
    "x": "38",
    "y": "23",
    "owner": "hx16533f91c766ce4222e6bd4eceb270e08c9a29c8",
    "name": "Emerald City Land #1630",
    "zoneId": "8",
    "metaId": "4983",
    "nftId": 1630,
    "creationDate": "1692582525650766",
    "id": "1630",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-4",
    "owner": "hx16533f91c766ce4222e6bd4eceb270e08c9a29c8",
    "name": "Emerald City Land #1631",
    "zoneId": "8",
    "metaId": "6715",
    "nftId": 1631,
    "creationDate": "1692582525650766",
    "id": "1631",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "7",
    "owner": "hx16533f91c766ce4222e6bd4eceb270e08c9a29c8",
    "name": "Emerald City Land #1632",
    "zoneId": "8",
    "metaId": "6681",
    "nftId": 1632,
    "creationDate": "1692582525650766",
    "id": "1632",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "-16",
    "owner": "hx16533f91c766ce4222e6bd4eceb270e08c9a29c8",
    "name": "Emerald City Land #1633",
    "zoneId": "8",
    "metaId": "5157",
    "nftId": 1633,
    "creationDate": "1692582525650766",
    "id": "1633",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "20",
    "owner": "hxc2145dfe14e5d51b66f3f74241d82ac237edb318",
    "name": "Emerald City Land #1634",
    "zoneId": "8",
    "metaId": "5300",
    "nftId": 1634,
    "creationDate": "1692586756120895",
    "id": "1634",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "4",
    "owner": "hxc2145dfe14e5d51b66f3f74241d82ac237edb318",
    "name": "Emerald City Land #1635",
    "zoneId": "7",
    "metaId": "2445",
    "nftId": 1635,
    "creationDate": "1692586830291392",
    "id": "1635",
    "mintedVia": "CROWN",
  },
  {
    "x": "-13",
    "y": "-10",
    "owner": "hxc2145dfe14e5d51b66f3f74241d82ac237edb318",
    "name": "Emerald City Land #1636",
    "zoneId": "7",
    "metaId": "2892",
    "nftId": 1636,
    "creationDate": "1692586830291392",
    "id": "1636",
    "mintedVia": "CROWN",
  },
  {
    "x": "8",
    "y": "15",
    "owner": "hxc2145dfe14e5d51b66f3f74241d82ac237edb318",
    "name": "Emerald City Land #1637",
    "zoneId": "7",
    "metaId": "4501",
    "nftId": 1637,
    "creationDate": "1692586830291392",
    "id": "1637",
    "mintedVia": "CROWN",
  },
  {
    "x": "30",
    "y": "-8",
    "owner": "hxc2145dfe14e5d51b66f3f74241d82ac237edb318",
    "name": "Emerald City Land #1638",
    "zoneId": "7",
    "metaId": "2421",
    "nftId": 1638,
    "creationDate": "1692586830291392",
    "id": "1638",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "5",
    "owner": "hxc2145dfe14e5d51b66f3f74241d82ac237edb318",
    "name": "Emerald City Land #1639",
    "zoneId": "7",
    "metaId": "4263",
    "nftId": 1639,
    "creationDate": "1692586830291392",
    "id": "1639",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "-6",
    "owner": "hxc2145dfe14e5d51b66f3f74241d82ac237edb318",
    "name": "Emerald City Land #1640",
    "zoneId": "7",
    "metaId": "2936",
    "nftId": 1640,
    "creationDate": "1692586962221060",
    "id": "1640",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "14",
    "owner": "hxc2145dfe14e5d51b66f3f74241d82ac237edb318",
    "name": "Emerald City Land #1641",
    "zoneId": "7",
    "metaId": "3701",
    "nftId": 1641,
    "creationDate": "1692586962221060",
    "id": "1641",
    "mintedVia": "CROWN",
  },
  {
    "x": "41",
    "y": "26",
    "owner": "hxc2145dfe14e5d51b66f3f74241d82ac237edb318",
    "name": "Emerald City Land #1642",
    "zoneId": "7",
    "metaId": "1946",
    "nftId": 1642,
    "creationDate": "1692586962221060",
    "id": "1642",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "3",
    "owner": "hxcb59375882bf9f480c27dd67a6bce99bcddc8fec",
    "name": "Emerald City Land #1643",
    "zoneId": "1",
    "metaId": "476",
    "nftId": 1643,
    "creationDate": "1692628509240422",
    "id": "1643",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "0",
    "owner": "hx46fca9671f1811c860bb19d895704ce2ef4171e7",
    "name": "Emerald City Land #1644",
    "zoneId": "8",
    "metaId": "5679",
    "nftId": 1644,
    "creationDate": "1692682455716593",
    "id": "1644",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "2",
    "owner": "hx185e5394e56ce794bc4d1b70c0d61f93478a48eb",
    "name": "Emerald City Land #1645",
    "zoneId": "7",
    "metaId": "3307",
    "nftId": 1645,
    "creationDate": "1692683245776002",
    "id": "1645",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "2",
    "owner": "hx0ca55a2cddb30132e79ff4548f9f455bb9363448",
    "name": "Emerald City Land #1646",
    "zoneId": "7",
    "metaId": "2659",
    "nftId": 1646,
    "creationDate": "1692778811151271",
    "id": "1646",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "-3",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #1647",
    "zoneId": "3",
    "metaId": "980",
    "nftId": 1647,
    "creationDate": "1692940642980564",
    "id": "1647",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "-1",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #1648",
    "zoneId": "8",
    "metaId": "6726",
    "nftId": 1648,
    "creationDate": "1692940784974433",
    "id": "1648",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "4",
    "owner": "hxb0f50d9ed747617ee24981a056bb3803f701b524",
    "name": "Emerald City Land #1649",
    "zoneId": "7",
    "metaId": "2773",
    "nftId": 1649,
    "creationDate": "1692949749792453",
    "id": "1649",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "17",
    "owner": "hxb0f50d9ed747617ee24981a056bb3803f701b524",
    "name": "Emerald City Land #1650",
    "zoneId": "7",
    "metaId": "3503",
    "nftId": 1650,
    "creationDate": "1692949749792453",
    "id": "1650",
    "mintedVia": "CROWN",
  },
  {
    "x": "18",
    "y": "22",
    "owner": "hxb0f50d9ed747617ee24981a056bb3803f701b524",
    "name": "Emerald City Land #1651",
    "zoneId": "8",
    "metaId": "4872",
    "nftId": 1651,
    "creationDate": "1692949916357922",
    "id": "1651",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "-2",
    "owner": "hx157fa170289c22021e5a463e0f37ab9097859cc6",
    "name": "Emerald City Land #1652",
    "zoneId": "7",
    "metaId": "3241",
    "nftId": 1652,
    "creationDate": "1693026622852131",
    "id": "1652",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "0",
    "owner": "hxe3b2aeec9991fff3b62f9cd1d12e5401825c2061",
    "name": "Emerald City Land #1653",
    "zoneId": "7",
    "metaId": "3331",
    "nftId": 1653,
    "creationDate": "1693038708769322",
    "id": "1653",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "17",
    "owner": "hxe3b2aeec9991fff3b62f9cd1d12e5401825c2061",
    "name": "Emerald City Land #1654",
    "zoneId": "7",
    "metaId": "3650",
    "nftId": 1654,
    "creationDate": "1693039002664879",
    "id": "1654",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "4",
    "owner": "hxe3b2aeec9991fff3b62f9cd1d12e5401825c2061",
    "name": "Emerald City Land #1655",
    "zoneId": "7",
    "metaId": "2759",
    "nftId": 1655,
    "creationDate": "1693039002664879",
    "id": "1655",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "-19",
    "owner": "hxe3b2aeec9991fff3b62f9cd1d12e5401825c2061",
    "name": "Emerald City Land #1656",
    "zoneId": "7",
    "metaId": "2531",
    "nftId": 1656,
    "creationDate": "1693039002664879",
    "id": "1656",
    "mintedVia": "CROWN",
  },
  {
    "x": "-91",
    "y": "-2",
    "owner": "hxe3b2aeec9991fff3b62f9cd1d12e5401825c2061",
    "name": "Emerald City Land #1657",
    "zoneId": "8",
    "metaId": "6416",
    "nftId": 1657,
    "creationDate": "1693039146743437",
    "id": "1657",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "19",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #1658",
    "zoneId": "7",
    "metaId": "3895",
    "nftId": 1658,
    "creationDate": "1693153310817889",
    "id": "1658",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "2",
    "owner": "hx04c09b29c9db5f20cc7334367532c891caf30a65",
    "name": "Emerald City Land #1659",
    "zoneId": "7",
    "metaId": "2942",
    "nftId": 1659,
    "creationDate": "1693217119507874",
    "id": "1659",
    "mintedVia": "CROWN",
  },
  {
    "x": "-100",
    "y": "-2",
    "owner": "hxa5d8f55b368af815dd83a2eab42a4238f1d931a5",
    "name": "Emerald City Land #1660",
    "zoneId": "7",
    "metaId": "2282",
    "nftId": 1660,
    "creationDate": "1693221122818847",
    "id": "1660",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "4",
    "owner": "hxa5d8f55b368af815dd83a2eab42a4238f1d931a5",
    "name": "Emerald City Land #1661",
    "zoneId": "6",
    "metaId": "1801",
    "nftId": 1661,
    "creationDate": "1693221287056438",
    "id": "1661",
    "mintedVia": "CROWN",
  },
  {
    "x": "55",
    "y": "1",
    "owner": "hxa5d8f55b368af815dd83a2eab42a4238f1d931a5",
    "name": "Emerald City Land #1662",
    "zoneId": "4",
    "metaId": "1351",
    "nftId": 1662,
    "creationDate": "1693221370914760",
    "id": "1662",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "17",
    "owner": "hxa5d8f55b368af815dd83a2eab42a4238f1d931a5",
    "name": "Emerald City Land #1663",
    "zoneId": "8",
    "metaId": "6648",
    "nftId": 1663,
    "creationDate": "1693221400924630",
    "id": "1663",
    "mintedVia": "CROWN",
  },
  {
    "x": "9",
    "y": "-2",
    "owner": "hxadc6fd24315c17cebbd9cb458ed318d7d7c3a441",
    "name": "Emerald City Land #1664",
    "zoneId": "7",
    "metaId": "3359",
    "nftId": 1664,
    "creationDate": "1693243396912728",
    "id": "1664",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "-28",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1665",
    "zoneId": "2",
    "metaId": "777",
    "nftId": 1665,
    "creationDate": "1693244719410410",
    "id": "1665",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "30",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1666",
    "zoneId": "3",
    "metaId": "955",
    "nftId": 1666,
    "creationDate": "1693244749571586",
    "id": "1666",
    "mintedVia": "CROWN",
  },
  {
    "x": "9",
    "y": "31",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #1667",
    "zoneId": "3",
    "metaId": "1124",
    "nftId": 1667,
    "creationDate": "1693244749571586",
    "id": "1667",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "-10",
    "owner": "hx5cf1a8adfdcc51692d5f91ee8957790c592383c0",
    "name": "Emerald City Land #1668",
    "zoneId": "8",
    "metaId": "5315",
    "nftId": 1668,
    "creationDate": "1693246337886884",
    "id": "1668",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "-15",
    "owner": "hx35bef857f447768e009875dff1a8d386a488df9d",
    "name": "Emerald City Land #1669",
    "zoneId": "5",
    "metaId": "1582",
    "nftId": 1669,
    "creationDate": "1693247478159407",
    "id": "1669",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "6",
    "owner": "hx35bef857f447768e009875dff1a8d386a488df9d",
    "name": "Emerald City Land #1670",
    "zoneId": "5",
    "metaId": "1605",
    "nftId": 1670,
    "creationDate": "1693247478159407",
    "id": "1670",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "18",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1671",
    "zoneId": "7",
    "metaId": "2120",
    "nftId": 1671,
    "creationDate": "1693261708755471",
    "id": "1671",
    "mintedVia": "CROWN",
  },
  {
    "x": "32",
    "y": "5",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1672",
    "zoneId": "7",
    "metaId": "2463",
    "nftId": 1672,
    "creationDate": "1693261708755471",
    "id": "1672",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "-11",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1673",
    "zoneId": "7",
    "metaId": "2211",
    "nftId": 1673,
    "creationDate": "1693261708755471",
    "id": "1673",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "-7",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1674",
    "zoneId": "7",
    "metaId": "4623",
    "nftId": 1674,
    "creationDate": "1693261708755471",
    "id": "1674",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "4",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1675",
    "zoneId": "7",
    "metaId": "4387",
    "nftId": 1675,
    "creationDate": "1693261708755471",
    "id": "1675",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "-16",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1676",
    "zoneId": "8",
    "metaId": "5195",
    "nftId": 1676,
    "creationDate": "1693261820795272",
    "id": "1676",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "6",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1677",
    "zoneId": "8",
    "metaId": "6722",
    "nftId": 1677,
    "creationDate": "1693261820795272",
    "id": "1677",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "10",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1678",
    "zoneId": "8",
    "metaId": "4697",
    "nftId": 1678,
    "creationDate": "1693261820795272",
    "id": "1678",
    "mintedVia": "CROWN",
  },
  {
    "x": "12",
    "y": "27",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1679",
    "zoneId": "8",
    "metaId": "4754",
    "nftId": 1679,
    "creationDate": "1693261820795272",
    "id": "1679",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "30",
    "owner": "hxea86291e680e94a196c65768fb0ed60f8e4cddeb",
    "name": "Emerald City Land #1680",
    "zoneId": "8",
    "metaId": "6629",
    "nftId": 1680,
    "creationDate": "1693261820795272",
    "id": "1680",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "-13",
    "owner": "hxb22e5e711d47aa6c9feafa5aa5f1fbc3493b579a",
    "name": "Emerald City Land #1681",
    "zoneId": "1",
    "metaId": "81",
    "nftId": 1681,
    "creationDate": "1693262678954010",
    "id": "1681",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "29",
    "owner": "hxb22e5e711d47aa6c9feafa5aa5f1fbc3493b579a",
    "name": "Emerald City Land #1682",
    "zoneId": "8",
    "metaId": "5621",
    "nftId": 1682,
    "creationDate": "1693262742945795",
    "id": "1682",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "-17",
    "owner": "hxb22e5e711d47aa6c9feafa5aa5f1fbc3493b579a",
    "name": "Emerald City Land #1683",
    "zoneId": "8",
    "metaId": "6866",
    "nftId": 1683,
    "creationDate": "1693263603171082",
    "id": "1683",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "7",
    "owner": "hxcfc147627b87c10cbe48c02f8bbb94e34b7b6cb8",
    "name": "Emerald City Land #1684",
    "zoneId": "8",
    "metaId": "5627",
    "nftId": 1684,
    "creationDate": "1693270872534196",
    "id": "1684",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "-24",
    "owner": "hx6c1d9d6e0e596eecc437e3133462e947d1226fa9",
    "name": "Emerald City Land #1685",
    "zoneId": "8",
    "metaId": "6826",
    "nftId": 1685,
    "creationDate": "1693275160883866",
    "id": "1685",
    "mintedVia": "CROWN",
  },
  {
    "x": "9",
    "y": "4",
    "owner": "hx185e5394e56ce794bc4d1b70c0d61f93478a48eb",
    "name": "Emerald City Land #1686",
    "zoneId": "7",
    "metaId": "3368",
    "nftId": 1686,
    "creationDate": "1693328920502198",
    "id": "1686",
    "mintedVia": "CROWN",
  },
  {
    "x": "-99",
    "y": "-2",
    "owner": "hx185e5394e56ce794bc4d1b70c0d61f93478a48eb",
    "name": "Emerald City Land #1687",
    "zoneId": "8",
    "metaId": "6575",
    "nftId": 1687,
    "creationDate": "1693329110559313",
    "id": "1687",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "-8",
    "owner": "hx185e5394e56ce794bc4d1b70c0d61f93478a48eb",
    "name": "Emerald City Land #1688",
    "zoneId": "8",
    "metaId": "6836",
    "nftId": 1688,
    "creationDate": "1693329110559313",
    "id": "1688",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "2",
    "owner": "hx185e5394e56ce794bc4d1b70c0d61f93478a48eb",
    "name": "Emerald City Land #1689",
    "zoneId": "8",
    "metaId": "5686",
    "nftId": 1689,
    "creationDate": "1693329110559313",
    "id": "1689",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "23",
    "owner": "hxf819c5e1cc4b3e48aab63cacf87b2f723d01ec7d",
    "name": "Emerald City Land #1690",
    "zoneId": "8",
    "metaId": "5220",
    "nftId": 1690,
    "creationDate": "1693396875055568",
    "id": "1690",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "-33",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1691",
    "zoneId": "2",
    "metaId": "830",
    "nftId": 1691,
    "creationDate": "1693399239657106",
    "id": "1691",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "-31",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1692",
    "zoneId": "2",
    "metaId": "846",
    "nftId": 1692,
    "creationDate": "1693399239657106",
    "id": "1692",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "-8",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1693",
    "zoneId": "5",
    "metaId": "1603",
    "nftId": 1693,
    "creationDate": "1693399409917184",
    "id": "1693",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "22",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1694",
    "zoneId": "5",
    "metaId": "1561",
    "nftId": 1694,
    "creationDate": "1693399767847176",
    "id": "1694",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "10",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1695",
    "zoneId": "5",
    "metaId": "1650",
    "nftId": 1695,
    "creationDate": "1693399767847176",
    "id": "1695",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "3",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1696",
    "zoneId": "5",
    "metaId": "1657",
    "nftId": 1696,
    "creationDate": "1693399767847176",
    "id": "1696",
    "mintedVia": "CROWN",
  },
  {
    "x": "-21",
    "y": "26",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1697",
    "zoneId": "5",
    "metaId": "1624",
    "nftId": 1697,
    "creationDate": "1693399767847176",
    "id": "1697",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "0",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1698",
    "zoneId": "5",
    "metaId": "1640",
    "nftId": 1698,
    "creationDate": "1693399767847176",
    "id": "1698",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "23",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1699",
    "zoneId": "5",
    "metaId": "1630",
    "nftId": 1699,
    "creationDate": "1693399767847176",
    "id": "1699",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "-7",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1700",
    "zoneId": "5",
    "metaId": "1602",
    "nftId": 1700,
    "creationDate": "1693399767847176",
    "id": "1700",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "19",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1701",
    "zoneId": "5",
    "metaId": "1540",
    "nftId": 1701,
    "creationDate": "1693399767847176",
    "id": "1701",
    "mintedVia": "CROWN",
  },
  {
    "x": "12",
    "y": "2",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1702",
    "zoneId": "5",
    "metaId": "1615",
    "nftId": 1702,
    "creationDate": "1693399767847176",
    "id": "1702",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "0",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1703",
    "zoneId": "4",
    "metaId": "1331",
    "nftId": 1703,
    "creationDate": "1693399897872840",
    "id": "1703",
    "mintedVia": "CROWN",
  },
  {
    "x": "-21",
    "y": "30",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1704",
    "zoneId": "4",
    "metaId": "1457",
    "nftId": 1704,
    "creationDate": "1693399897872840",
    "id": "1704",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "-5",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1705",
    "zoneId": "4",
    "metaId": "1386",
    "nftId": 1705,
    "creationDate": "1693399897872840",
    "id": "1705",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "0",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1706",
    "zoneId": "4",
    "metaId": "1240",
    "nftId": 1706,
    "creationDate": "1693399897872840",
    "id": "1706",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "1",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1707",
    "zoneId": "4",
    "metaId": "1416",
    "nftId": 1707,
    "creationDate": "1693399897872840",
    "id": "1707",
    "mintedVia": "CROWN",
  },
  {
    "x": "43",
    "y": "24",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1708",
    "zoneId": "6",
    "metaId": "1681",
    "nftId": 1708,
    "creationDate": "1693400001910062",
    "id": "1708",
    "mintedVia": "CROWN",
  },
  {
    "x": "-4",
    "y": "36",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1709",
    "zoneId": "3",
    "metaId": "1006",
    "nftId": 1709,
    "creationDate": "1693400089932164",
    "id": "1709",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "-11",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1710",
    "zoneId": "1",
    "metaId": "713",
    "nftId": 1710,
    "creationDate": "1693400194150646",
    "id": "1710",
    "mintedVia": "CROWN",
  },
  {
    "x": "-87",
    "y": "6",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1711",
    "zoneId": "1",
    "metaId": "146",
    "nftId": 1711,
    "creationDate": "1693400194150646",
    "id": "1711",
    "mintedVia": "CROWN",
  },
  {
    "x": "20",
    "y": "6",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1712",
    "zoneId": "1",
    "metaId": "399",
    "nftId": 1712,
    "creationDate": "1693400194150646",
    "id": "1712",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "13",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1713",
    "zoneId": "1",
    "metaId": "77",
    "nftId": 1713,
    "creationDate": "1693400194150646",
    "id": "1713",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "-2",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1714",
    "zoneId": "1",
    "metaId": "85",
    "nftId": 1714,
    "creationDate": "1693400194150646",
    "id": "1714",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "12",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1715",
    "zoneId": "1",
    "metaId": "575",
    "nftId": 1715,
    "creationDate": "1693400194150646",
    "id": "1715",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "4",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1716",
    "zoneId": "1",
    "metaId": "277",
    "nftId": 1716,
    "creationDate": "1693400194150646",
    "id": "1716",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "-6",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1717",
    "zoneId": "1",
    "metaId": "165",
    "nftId": 1717,
    "creationDate": "1693400194150646",
    "id": "1717",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "-2",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1718",
    "zoneId": "1",
    "metaId": "92",
    "nftId": 1718,
    "creationDate": "1693400194150646",
    "id": "1718",
    "mintedVia": "CROWN",
  },
  {
    "x": "-21",
    "y": "-1",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1719",
    "zoneId": "1",
    "metaId": "213",
    "nftId": 1719,
    "creationDate": "1693400194150646",
    "id": "1719",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "3",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1720",
    "zoneId": "1",
    "metaId": "173",
    "nftId": 1720,
    "creationDate": "1693400194150646",
    "id": "1720",
    "mintedVia": "CROWN",
  },
  {
    "x": "24",
    "y": "-7",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1721",
    "zoneId": "1",
    "metaId": "455",
    "nftId": 1721,
    "creationDate": "1693400194150646",
    "id": "1721",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-1",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1722",
    "zoneId": "1",
    "metaId": "316",
    "nftId": 1722,
    "creationDate": "1693400194150646",
    "id": "1722",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "-20",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1723",
    "zoneId": "1",
    "metaId": "637",
    "nftId": 1723,
    "creationDate": "1693400194150646",
    "id": "1723",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "14",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1724",
    "zoneId": "1",
    "metaId": "59",
    "nftId": 1724,
    "creationDate": "1693400194150646",
    "id": "1724",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "-14",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1725",
    "zoneId": "1",
    "metaId": "607",
    "nftId": 1725,
    "creationDate": "1693400194150646",
    "id": "1725",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "15",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1726",
    "zoneId": "8",
    "metaId": "5137",
    "nftId": 1726,
    "creationDate": "1693400596120115",
    "id": "1726",
    "mintedVia": "CROWN",
  },
  {
    "x": "58",
    "y": "29",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1727",
    "zoneId": "8",
    "metaId": "5037",
    "nftId": 1727,
    "creationDate": "1693400596120115",
    "id": "1727",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "7",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1728",
    "zoneId": "8",
    "metaId": "6739",
    "nftId": 1728,
    "creationDate": "1693400596120115",
    "id": "1728",
    "mintedVia": "CROWN",
  },
  {
    "x": "-105",
    "y": "-1",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1729",
    "zoneId": "8",
    "metaId": "6163",
    "nftId": 1729,
    "creationDate": "1693400596120115",
    "id": "1729",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "13",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1730",
    "zoneId": "8",
    "metaId": "4980",
    "nftId": 1730,
    "creationDate": "1693400596120115",
    "id": "1730",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "17",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1731",
    "zoneId": "8",
    "metaId": "6636",
    "nftId": 1731,
    "creationDate": "1693400596120115",
    "id": "1731",
    "mintedVia": "CROWN",
  },
  {
    "x": "-11",
    "y": "18",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1732",
    "zoneId": "8",
    "metaId": "6615",
    "nftId": 1732,
    "creationDate": "1693400596120115",
    "id": "1732",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "25",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1733",
    "zoneId": "8",
    "metaId": "4894",
    "nftId": 1733,
    "creationDate": "1693400596120115",
    "id": "1733",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "-17",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1734",
    "zoneId": "8",
    "metaId": "6835",
    "nftId": 1734,
    "creationDate": "1693400596120115",
    "id": "1734",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "1",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1735",
    "zoneId": "8",
    "metaId": "6657",
    "nftId": 1735,
    "creationDate": "1693400596120115",
    "id": "1735",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "15",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1736",
    "zoneId": "8",
    "metaId": "4810",
    "nftId": 1736,
    "creationDate": "1693400596120115",
    "id": "1736",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "17",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1737",
    "zoneId": "8",
    "metaId": "5608",
    "nftId": 1737,
    "creationDate": "1693400596120115",
    "id": "1737",
    "mintedVia": "CROWN",
  },
  {
    "x": "-104",
    "y": "-3",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1738",
    "zoneId": "8",
    "metaId": "6154",
    "nftId": 1738,
    "creationDate": "1693400596120115",
    "id": "1738",
    "mintedVia": "CROWN",
  },
  {
    "x": "-81",
    "y": "17",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1739",
    "zoneId": "8",
    "metaId": "6330",
    "nftId": 1739,
    "creationDate": "1693400596120115",
    "id": "1739",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "19",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1740",
    "zoneId": "8",
    "metaId": "4917",
    "nftId": 1740,
    "creationDate": "1693400596120115",
    "id": "1740",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "7",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1741",
    "zoneId": "8",
    "metaId": "5467",
    "nftId": 1741,
    "creationDate": "1693400596120115",
    "id": "1741",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "-7",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1742",
    "zoneId": "8",
    "metaId": "5324",
    "nftId": 1742,
    "creationDate": "1693400596120115",
    "id": "1742",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "6",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1743",
    "zoneId": "8",
    "metaId": "6592",
    "nftId": 1743,
    "creationDate": "1693400596120115",
    "id": "1743",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "-6",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1744",
    "zoneId": "8",
    "metaId": "6705",
    "nftId": 1744,
    "creationDate": "1693400596120115",
    "id": "1744",
    "mintedVia": "CROWN",
  },
  {
    "x": "-91",
    "y": "-17",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1745",
    "zoneId": "8",
    "metaId": "6414",
    "nftId": 1745,
    "creationDate": "1693400596120115",
    "id": "1745",
    "mintedVia": "CROWN",
  },
  {
    "x": "-98",
    "y": "2",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1746",
    "zoneId": "8",
    "metaId": "6563",
    "nftId": 1746,
    "creationDate": "1693400596120115",
    "id": "1746",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "-6",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1747",
    "zoneId": "8",
    "metaId": "5480",
    "nftId": 1747,
    "creationDate": "1693400596120115",
    "id": "1747",
    "mintedVia": "CROWN",
  },
  {
    "x": "-104",
    "y": "-10",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1748",
    "zoneId": "8",
    "metaId": "6153",
    "nftId": 1748,
    "creationDate": "1693400596120115",
    "id": "1748",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "14",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1749",
    "zoneId": "8",
    "metaId": "5760",
    "nftId": 1749,
    "creationDate": "1693400596120115",
    "id": "1749",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "28",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1750",
    "zoneId": "8",
    "metaId": "4884",
    "nftId": 1750,
    "creationDate": "1693400596120115",
    "id": "1750",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "2",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1751",
    "zoneId": "7",
    "metaId": "2707",
    "nftId": 1751,
    "creationDate": "1693400946217826",
    "id": "1751",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "14",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1752",
    "zoneId": "7",
    "metaId": "4113",
    "nftId": 1752,
    "creationDate": "1693401014211914",
    "id": "1752",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "27",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1753",
    "zoneId": "7",
    "metaId": "3659",
    "nftId": 1753,
    "creationDate": "1693401014211914",
    "id": "1753",
    "mintedVia": "CROWN",
  },
  {
    "x": "-82",
    "y": "19",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1754",
    "zoneId": "7",
    "metaId": "2324",
    "nftId": 1754,
    "creationDate": "1693401014211914",
    "id": "1754",
    "mintedVia": "CROWN",
  },
  {
    "x": "-1",
    "y": "20",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1755",
    "zoneId": "7",
    "metaId": "3553",
    "nftId": 1755,
    "creationDate": "1693401014211914",
    "id": "1755",
    "mintedVia": "CROWN",
  },
  {
    "x": "1",
    "y": "21",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1756",
    "zoneId": "7",
    "metaId": "3879",
    "nftId": 1756,
    "creationDate": "1693401014211914",
    "id": "1756",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "0",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1757",
    "zoneId": "7",
    "metaId": "4205",
    "nftId": 1757,
    "creationDate": "1693401014211914",
    "id": "1757",
    "mintedVia": "CROWN",
  },
  {
    "x": "11",
    "y": "12",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1758",
    "zoneId": "7",
    "metaId": "1974",
    "nftId": 1758,
    "creationDate": "1693401014211914",
    "id": "1758",
    "mintedVia": "CROWN",
  },
  {
    "x": "-19",
    "y": "-3",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1759",
    "zoneId": "7",
    "metaId": "2974",
    "nftId": 1759,
    "creationDate": "1693401014211914",
    "id": "1759",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "-12",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1760",
    "zoneId": "7",
    "metaId": "2364",
    "nftId": 1760,
    "creationDate": "1693401014211914",
    "id": "1760",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "-11",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1761",
    "zoneId": "7",
    "metaId": "2229",
    "nftId": 1761,
    "creationDate": "1693401014211914",
    "id": "1761",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "-7",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1762",
    "zoneId": "7",
    "metaId": "2655",
    "nftId": 1762,
    "creationDate": "1693401074392469",
    "id": "1762",
    "mintedVia": "CROWN",
  },
  {
    "x": "6",
    "y": "8",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1763",
    "zoneId": "7",
    "metaId": "3327",
    "nftId": 1763,
    "creationDate": "1693401074392469",
    "id": "1763",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "-13",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1764",
    "zoneId": "7",
    "metaId": "2547",
    "nftId": 1764,
    "creationDate": "1693401074392469",
    "id": "1764",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "9",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1765",
    "zoneId": "7",
    "metaId": "3495",
    "nftId": 1765,
    "creationDate": "1693401074392469",
    "id": "1765",
    "mintedVia": "CROWN",
  },
  {
    "x": "-13",
    "y": "28",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1766",
    "zoneId": "7",
    "metaId": "3438",
    "nftId": 1766,
    "creationDate": "1693401074392469",
    "id": "1766",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "8",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1767",
    "zoneId": "7",
    "metaId": "2725",
    "nftId": 1767,
    "creationDate": "1693401074392469",
    "id": "1767",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "23",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1768",
    "zoneId": "7",
    "metaId": "2187",
    "nftId": 1768,
    "creationDate": "1693401074392469",
    "id": "1768",
    "mintedVia": "CROWN",
  },
  {
    "x": "18",
    "y": "18",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1769",
    "zoneId": "7",
    "metaId": "1901",
    "nftId": 1769,
    "creationDate": "1693401074392469",
    "id": "1769",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "26",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1770",
    "zoneId": "7",
    "metaId": "2095",
    "nftId": 1770,
    "creationDate": "1693401074392469",
    "id": "1770",
    "mintedVia": "CROWN",
  },
  {
    "x": "-46",
    "y": "-33",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1771",
    "zoneId": "7",
    "metaId": "4640",
    "nftId": 1771,
    "creationDate": "1693401074392469",
    "id": "1771",
    "mintedVia": "CROWN",
  },
  {
    "x": "43",
    "y": "-1",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1772",
    "zoneId": "7",
    "metaId": "2638",
    "nftId": 1772,
    "creationDate": "1693401074392469",
    "id": "1772",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "2",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1773",
    "zoneId": "7",
    "metaId": "4011",
    "nftId": 1773,
    "creationDate": "1693401074392469",
    "id": "1773",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "19",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1774",
    "zoneId": "7",
    "metaId": "3810",
    "nftId": 1774,
    "creationDate": "1693401074392469",
    "id": "1774",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "15",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1775",
    "zoneId": "7",
    "metaId": "3838",
    "nftId": 1775,
    "creationDate": "1693401074392469",
    "id": "1775",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "-10",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1776",
    "zoneId": "7",
    "metaId": "3111",
    "nftId": 1776,
    "creationDate": "1693401074392469",
    "id": "1776",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "29",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1777",
    "zoneId": "7",
    "metaId": "2089",
    "nftId": 1777,
    "creationDate": "1693401074392469",
    "id": "1777",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "15",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1778",
    "zoneId": "7",
    "metaId": "3501",
    "nftId": 1778,
    "creationDate": "1693401074392469",
    "id": "1778",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "-23",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1779",
    "zoneId": "7",
    "metaId": "4650",
    "nftId": 1779,
    "creationDate": "1693401074392469",
    "id": "1779",
    "mintedVia": "CROWN",
  },
  {
    "x": "-4",
    "y": "15",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1780",
    "zoneId": "7",
    "metaId": "3765",
    "nftId": 1780,
    "creationDate": "1693401074392469",
    "id": "1780",
    "mintedVia": "CROWN",
  },
  {
    "x": "8",
    "y": "19",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1781",
    "zoneId": "7",
    "metaId": "4504",
    "nftId": 1781,
    "creationDate": "1693401074392469",
    "id": "1781",
    "mintedVia": "CROWN",
  },
  {
    "x": "-19",
    "y": "-2",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1782",
    "zoneId": "7",
    "metaId": "2973",
    "nftId": 1782,
    "creationDate": "1693401254288236",
    "id": "1782",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "9",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1783",
    "zoneId": "7",
    "metaId": "2505",
    "nftId": 1783,
    "creationDate": "1693401254288236",
    "id": "1783",
    "mintedVia": "CROWN",
  },
  {
    "x": "46",
    "y": "-4",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1784",
    "zoneId": "7",
    "metaId": "2679",
    "nftId": 1784,
    "creationDate": "1693401254288236",
    "id": "1784",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "21",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1785",
    "zoneId": "7",
    "metaId": "3742",
    "nftId": 1785,
    "creationDate": "1693401254288236",
    "id": "1785",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "6",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1786",
    "zoneId": "7",
    "metaId": "4201",
    "nftId": 1786,
    "creationDate": "1693401254288236",
    "id": "1786",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "-8",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1787",
    "zoneId": "7",
    "metaId": "2394",
    "nftId": 1787,
    "creationDate": "1693401254288236",
    "id": "1787",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "20",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1788",
    "zoneId": "7",
    "metaId": "1994",
    "nftId": 1788,
    "creationDate": "1693401254288236",
    "id": "1788",
    "mintedVia": "CROWN",
  },
  {
    "x": "-69",
    "y": "18",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1789",
    "zoneId": "7",
    "metaId": "2315",
    "nftId": 1789,
    "creationDate": "1693401254288236",
    "id": "1789",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "30",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1790",
    "zoneId": "7",
    "metaId": "1986",
    "nftId": 1790,
    "creationDate": "1693401254288236",
    "id": "1790",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "-2",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1791",
    "zoneId": "7",
    "metaId": "2690",
    "nftId": 1791,
    "creationDate": "1693401254288236",
    "id": "1791",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "18",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1792",
    "zoneId": "7",
    "metaId": "3961",
    "nftId": 1792,
    "creationDate": "1693401254288236",
    "id": "1792",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-16",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1793",
    "zoneId": "7",
    "metaId": "4630",
    "nftId": 1793,
    "creationDate": "1693401254288236",
    "id": "1793",
    "mintedVia": "CROWN",
  },
  {
    "x": "15",
    "y": "2",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1794",
    "zoneId": "7",
    "metaId": "3209",
    "nftId": 1794,
    "creationDate": "1693401254288236",
    "id": "1794",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "-10",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1795",
    "zoneId": "7",
    "metaId": "2507",
    "nftId": 1795,
    "creationDate": "1693401254288236",
    "id": "1795",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "18",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1796",
    "zoneId": "7",
    "metaId": "4009",
    "nftId": 1796,
    "creationDate": "1693401254288236",
    "id": "1796",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "-1",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1797",
    "zoneId": "7",
    "metaId": "2232",
    "nftId": 1797,
    "creationDate": "1693401254288236",
    "id": "1797",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "14",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1798",
    "zoneId": "7",
    "metaId": "3599",
    "nftId": 1798,
    "creationDate": "1693401254288236",
    "id": "1798",
    "mintedVia": "CROWN",
  },
  {
    "x": "9",
    "y": "11",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1799",
    "zoneId": "7",
    "metaId": "3363",
    "nftId": 1799,
    "creationDate": "1693401254288236",
    "id": "1799",
    "mintedVia": "CROWN",
  },
  {
    "x": "11",
    "y": "18",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1800",
    "zoneId": "7",
    "metaId": "1827",
    "nftId": 1800,
    "creationDate": "1693401254288236",
    "id": "1800",
    "mintedVia": "CROWN",
  },
  {
    "x": "-10",
    "y": "17",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1801",
    "zoneId": "7",
    "metaId": "3377",
    "nftId": 1801,
    "creationDate": "1693401254288236",
    "id": "1801",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "15",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1802",
    "zoneId": "7",
    "metaId": "2112",
    "nftId": 1802,
    "creationDate": "1693401378497998",
    "id": "1802",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "1",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1803",
    "zoneId": "7",
    "metaId": "4363",
    "nftId": 1803,
    "creationDate": "1693401378497998",
    "id": "1803",
    "mintedVia": "CROWN",
  },
  {
    "x": "-70",
    "y": "11",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1804",
    "zoneId": "7",
    "metaId": "2317",
    "nftId": 1804,
    "creationDate": "1693401378497998",
    "id": "1804",
    "mintedVia": "CROWN",
  },
  {
    "x": "8",
    "y": "14",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1805",
    "zoneId": "7",
    "metaId": "4500",
    "nftId": 1805,
    "creationDate": "1693401378497998",
    "id": "1805",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "5",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1806",
    "zoneId": "7",
    "metaId": "3245",
    "nftId": 1806,
    "creationDate": "1693401378497998",
    "id": "1806",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "29",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1807",
    "zoneId": "7",
    "metaId": "3684",
    "nftId": 1807,
    "creationDate": "1693401378497998",
    "id": "1807",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "29",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1808",
    "zoneId": "7",
    "metaId": "3949",
    "nftId": 1808,
    "creationDate": "1693401378497998",
    "id": "1808",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "12",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1809",
    "zoneId": "7",
    "metaId": "3290",
    "nftId": 1809,
    "creationDate": "1693401378497998",
    "id": "1809",
    "mintedVia": "CROWN",
  },
  {
    "x": "-98",
    "y": "-12",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1810",
    "zoneId": "7",
    "metaId": "2399",
    "nftId": 1810,
    "creationDate": "1693401378497998",
    "id": "1810",
    "mintedVia": "CROWN",
  },
  {
    "x": "9",
    "y": "27",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1811",
    "zoneId": "7",
    "metaId": "4527",
    "nftId": 1811,
    "creationDate": "1693401378497998",
    "id": "1811",
    "mintedVia": "CROWN",
  },
  {
    "x": "55",
    "y": "-7",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1812",
    "zoneId": "7",
    "metaId": "2788",
    "nftId": 1812,
    "creationDate": "1693401378497998",
    "id": "1812",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "-8",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1813",
    "zoneId": "7",
    "metaId": "3097",
    "nftId": 1813,
    "creationDate": "1693401378497998",
    "id": "1813",
    "mintedVia": "CROWN",
  },
  {
    "x": "41",
    "y": "15",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1814",
    "zoneId": "7",
    "metaId": "2131",
    "nftId": 1814,
    "creationDate": "1693401378497998",
    "id": "1814",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "4",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1815",
    "zoneId": "7",
    "metaId": "4221",
    "nftId": 1815,
    "creationDate": "1693401378497998",
    "id": "1815",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "19",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1816",
    "zoneId": "7",
    "metaId": "2057",
    "nftId": 1816,
    "creationDate": "1693401378497998",
    "id": "1816",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "24",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1817",
    "zoneId": "7",
    "metaId": "3711",
    "nftId": 1817,
    "creationDate": "1693401378497998",
    "id": "1817",
    "mintedVia": "CROWN",
  },
  {
    "x": "0",
    "y": "-4",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1818",
    "zoneId": "7",
    "metaId": "3149",
    "nftId": 1818,
    "creationDate": "1693401378497998",
    "id": "1818",
    "mintedVia": "CROWN",
  },
  {
    "x": "-20",
    "y": "2",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1819",
    "zoneId": "7",
    "metaId": "3011",
    "nftId": 1819,
    "creationDate": "1693401378497998",
    "id": "1819",
    "mintedVia": "CROWN",
  },
  {
    "x": "56",
    "y": "15",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1820",
    "zoneId": "7",
    "metaId": "2119",
    "nftId": 1820,
    "creationDate": "1693401378497998",
    "id": "1820",
    "mintedVia": "CROWN",
  },
  {
    "x": "24",
    "y": "18",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1821",
    "zoneId": "7",
    "metaId": "2048",
    "nftId": 1821,
    "creationDate": "1693401378497998",
    "id": "1821",
    "mintedVia": "CROWN",
  },
  {
    "x": "-19",
    "y": "-6",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1822",
    "zoneId": "7",
    "metaId": "2977",
    "nftId": 1822,
    "creationDate": "1693401758463189",
    "id": "1822",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "14",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1823",
    "zoneId": "7",
    "metaId": "4251",
    "nftId": 1823,
    "creationDate": "1693401758463189",
    "id": "1823",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "14",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1824",
    "zoneId": "7",
    "metaId": "4163",
    "nftId": 1824,
    "creationDate": "1693401758463189",
    "id": "1824",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "13",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1825",
    "zoneId": "7",
    "metaId": "3103",
    "nftId": 1825,
    "creationDate": "1693401758463189",
    "id": "1825",
    "mintedVia": "CROWN",
  },
  {
    "x": "46",
    "y": "-7",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1826",
    "zoneId": "7",
    "metaId": "2681",
    "nftId": 1826,
    "creationDate": "1693401758463189",
    "id": "1826",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "10",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1827",
    "zoneId": "7",
    "metaId": "3672",
    "nftId": 1827,
    "creationDate": "1693401758463189",
    "id": "1827",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "20",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1828",
    "zoneId": "7",
    "metaId": "4215",
    "nftId": 1828,
    "creationDate": "1693401758463189",
    "id": "1828",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "-12",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1829",
    "zoneId": "7",
    "metaId": "2226",
    "nftId": 1829,
    "creationDate": "1693401758463189",
    "id": "1829",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "22",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1830",
    "zoneId": "7",
    "metaId": "3944",
    "nftId": 1830,
    "creationDate": "1693401758463189",
    "id": "1830",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "-10",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1831",
    "zoneId": "7",
    "metaId": "2750",
    "nftId": 1831,
    "creationDate": "1693401758463189",
    "id": "1831",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "12",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1832",
    "zoneId": "7",
    "metaId": "1840",
    "nftId": 1832,
    "creationDate": "1693401758463189",
    "id": "1832",
    "mintedVia": "CROWN",
  },
  {
    "x": "0",
    "y": "27",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1833",
    "zoneId": "7",
    "metaId": "3867",
    "nftId": 1833,
    "creationDate": "1693401758463189",
    "id": "1833",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "14",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1834",
    "zoneId": "7",
    "metaId": "1928",
    "nftId": 1834,
    "creationDate": "1693401758463189",
    "id": "1834",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "26",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1835",
    "zoneId": "7",
    "metaId": "1997",
    "nftId": 1835,
    "creationDate": "1693401758463189",
    "id": "1835",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "-3",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1836",
    "zoneId": "7",
    "metaId": "2933",
    "nftId": 1836,
    "creationDate": "1693401758463189",
    "id": "1836",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "18",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1837",
    "zoneId": "7",
    "metaId": "3841",
    "nftId": 1837,
    "creationDate": "1693401758463189",
    "id": "1837",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "4",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1838",
    "zoneId": "7",
    "metaId": "2560",
    "nftId": 1838,
    "creationDate": "1693401758463189",
    "id": "1838",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "23",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1839",
    "zoneId": "7",
    "metaId": "3486",
    "nftId": 1839,
    "creationDate": "1693401758463189",
    "id": "1839",
    "mintedVia": "CROWN",
  },
  {
    "x": "-10",
    "y": "5",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1840",
    "zoneId": "7",
    "metaId": "2857",
    "nftId": 1840,
    "creationDate": "1693401758463189",
    "id": "1840",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "17",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1841",
    "zoneId": "7",
    "metaId": "4436",
    "nftId": 1841,
    "creationDate": "1693401758463189",
    "id": "1841",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "20",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1842",
    "zoneId": "7",
    "metaId": "3690",
    "nftId": 1842,
    "creationDate": "1693402094550008",
    "id": "1842",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "5",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1843",
    "zoneId": "7",
    "metaId": "2957",
    "nftId": 1843,
    "creationDate": "1693402094550008",
    "id": "1843",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "25",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1844",
    "zoneId": "7",
    "metaId": "1919",
    "nftId": 1844,
    "creationDate": "1693402094550008",
    "id": "1844",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "27",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1845",
    "zoneId": "7",
    "metaId": "3832",
    "nftId": 1845,
    "creationDate": "1693402094550008",
    "id": "1845",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "15",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1846",
    "zoneId": "7",
    "metaId": "4416",
    "nftId": 1846,
    "creationDate": "1693402094550008",
    "id": "1846",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "34",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1847",
    "zoneId": "7",
    "metaId": "1940",
    "nftId": 1847,
    "creationDate": "1693402094550008",
    "id": "1847",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "5",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1848",
    "zoneId": "7",
    "metaId": "3974",
    "nftId": 1848,
    "creationDate": "1693402094550008",
    "id": "1848",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "-1",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1849",
    "zoneId": "7",
    "metaId": "2261",
    "nftId": 1849,
    "creationDate": "1693402094550008",
    "id": "1849",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "20",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1850",
    "zoneId": "7",
    "metaId": "1979",
    "nftId": 1850,
    "creationDate": "1693402094550008",
    "id": "1850",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "4",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1851",
    "zoneId": "7",
    "metaId": "4337",
    "nftId": 1851,
    "creationDate": "1693402094550008",
    "id": "1851",
    "mintedVia": "CROWN",
  },
  {
    "x": "15",
    "y": "6",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1852",
    "zoneId": "7",
    "metaId": "3212",
    "nftId": 1852,
    "creationDate": "1693402094550008",
    "id": "1852",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "14",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1853",
    "zoneId": "7",
    "metaId": "3478",
    "nftId": 1853,
    "creationDate": "1693402094550008",
    "id": "1853",
    "mintedVia": "CROWN",
  },
  {
    "x": "8",
    "y": "28",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1854",
    "zoneId": "7",
    "metaId": "4512",
    "nftId": 1854,
    "creationDate": "1693402094550008",
    "id": "1854",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "-10",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1855",
    "zoneId": "7",
    "metaId": "2808",
    "nftId": 1855,
    "creationDate": "1693402094550008",
    "id": "1855",
    "mintedVia": "CROWN",
  },
  {
    "x": "46",
    "y": "-9",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1856",
    "zoneId": "7",
    "metaId": "2683",
    "nftId": 1856,
    "creationDate": "1693402094550008",
    "id": "1856",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "8",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1857",
    "zoneId": "7",
    "metaId": "3473",
    "nftId": 1857,
    "creationDate": "1693402094550008",
    "id": "1857",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "19",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1858",
    "zoneId": "7",
    "metaId": "1897",
    "nftId": 1858,
    "creationDate": "1693402094550008",
    "id": "1858",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "2",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1859",
    "zoneId": "7",
    "metaId": "3190",
    "nftId": 1859,
    "creationDate": "1693402094550008",
    "id": "1859",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "16",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1860",
    "zoneId": "7",
    "metaId": "4007",
    "nftId": 1860,
    "creationDate": "1693402094550008",
    "id": "1860",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "20",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1861",
    "zoneId": "7",
    "metaId": "4257",
    "nftId": 1861,
    "creationDate": "1693402094550008",
    "id": "1861",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "-2",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1862",
    "zoneId": "7",
    "metaId": "3092",
    "nftId": 1862,
    "creationDate": "1693402206589776",
    "id": "1862",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "4",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1863",
    "zoneId": "7",
    "metaId": "3066",
    "nftId": 1863,
    "creationDate": "1693402206589776",
    "id": "1863",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "1",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1864",
    "zoneId": "7",
    "metaId": "3189",
    "nftId": 1864,
    "creationDate": "1693402206589776",
    "id": "1864",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "13",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1865",
    "zoneId": "7",
    "metaId": "1956",
    "nftId": 1865,
    "creationDate": "1693402206589776",
    "id": "1865",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "10",
    "owner": "hxd1026723a0e68cf404444bcf9a22cec2ca3ce79f",
    "name": "Emerald City Land #1866",
    "zoneId": "7",
    "metaId": "2012",
    "nftId": 1866,
    "creationDate": "1693402206589776",
    "id": "1866",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "-32",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1867",
    "zoneId": "2",
    "metaId": "847",
    "nftId": 1867,
    "creationDate": "1693404899453771",
    "id": "1867",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1868",
    "zoneId": "2",
    "metaId": "735",
    "nftId": 1868,
    "creationDate": "1693404899453771",
    "id": "1868",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "-34",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1869",
    "zoneId": "2",
    "metaId": "874",
    "nftId": 1869,
    "creationDate": "1693404899453771",
    "id": "1869",
    "mintedVia": "CROWN",
  },
  {
    "x": "-68",
    "y": "-30",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1870",
    "zoneId": "2",
    "metaId": "906",
    "nftId": 1870,
    "creationDate": "1693404899453771",
    "id": "1870",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "-32",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1871",
    "zoneId": "2",
    "metaId": "879",
    "nftId": 1871,
    "creationDate": "1693404899453771",
    "id": "1871",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "-33",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1872",
    "zoneId": "2",
    "metaId": "821",
    "nftId": 1872,
    "creationDate": "1693404899453771",
    "id": "1872",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "-29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1873",
    "zoneId": "2",
    "metaId": "754",
    "nftId": 1873,
    "creationDate": "1693404899453771",
    "id": "1873",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "-32",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1874",
    "zoneId": "2",
    "metaId": "867",
    "nftId": 1874,
    "creationDate": "1693404899453771",
    "id": "1874",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-36",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1875",
    "zoneId": "2",
    "metaId": "857",
    "nftId": 1875,
    "creationDate": "1693404899453771",
    "id": "1875",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "-25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1876",
    "zoneId": "2",
    "metaId": "783",
    "nftId": 1876,
    "creationDate": "1693404899453771",
    "id": "1876",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "-31",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1877",
    "zoneId": "2",
    "metaId": "759",
    "nftId": 1877,
    "creationDate": "1693404899453771",
    "id": "1877",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "-23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1878",
    "zoneId": "2",
    "metaId": "832",
    "nftId": 1878,
    "creationDate": "1693404899453771",
    "id": "1878",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1879",
    "zoneId": "2",
    "metaId": "796",
    "nftId": 1879,
    "creationDate": "1693404899453771",
    "id": "1879",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "-34",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1880",
    "zoneId": "2",
    "metaId": "849",
    "nftId": 1880,
    "creationDate": "1693404899453771",
    "id": "1880",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1881",
    "zoneId": "2",
    "metaId": "748",
    "nftId": 1881,
    "creationDate": "1693404899453771",
    "id": "1881",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-22",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1882",
    "zoneId": "2",
    "metaId": "795",
    "nftId": 1882,
    "creationDate": "1693404899453771",
    "id": "1882",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "-32",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1883",
    "zoneId": "2",
    "metaId": "803",
    "nftId": 1883,
    "creationDate": "1693404899453771",
    "id": "1883",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "-34",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1884",
    "zoneId": "2",
    "metaId": "769",
    "nftId": 1884,
    "creationDate": "1693404899453771",
    "id": "1884",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "-23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1885",
    "zoneId": "2",
    "metaId": "782",
    "nftId": 1885,
    "creationDate": "1693404899453771",
    "id": "1885",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "-34",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1886",
    "zoneId": "2",
    "metaId": "805",
    "nftId": 1886,
    "creationDate": "1693404899453771",
    "id": "1886",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "-21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1887",
    "zoneId": "2",
    "metaId": "817",
    "nftId": 1887,
    "creationDate": "1693404899453771",
    "id": "1887",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "-32",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1888",
    "zoneId": "2",
    "metaId": "820",
    "nftId": 1888,
    "creationDate": "1693404899453771",
    "id": "1888",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "-24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1889",
    "zoneId": "2",
    "metaId": "772",
    "nftId": 1889,
    "creationDate": "1693404899453771",
    "id": "1889",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-27",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1890",
    "zoneId": "2",
    "metaId": "747",
    "nftId": 1890,
    "creationDate": "1693404899453771",
    "id": "1890",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "0",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #1891",
    "zoneId": "7",
    "metaId": "2657",
    "nftId": 1891,
    "creationDate": "1693405497626578",
    "id": "1891",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1892",
    "zoneId": "4",
    "metaId": "1316",
    "nftId": 1892,
    "creationDate": "1693405555637315",
    "id": "1892",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1893",
    "zoneId": "4",
    "metaId": "1389",
    "nftId": 1893,
    "creationDate": "1693405555637315",
    "id": "1893",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1894",
    "zoneId": "4",
    "metaId": "1226",
    "nftId": 1894,
    "creationDate": "1693405555637315",
    "id": "1894",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1895",
    "zoneId": "4",
    "metaId": "1201",
    "nftId": 1895,
    "creationDate": "1693405555637315",
    "id": "1895",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1896",
    "zoneId": "4",
    "metaId": "1295",
    "nftId": 1896,
    "creationDate": "1693405555637315",
    "id": "1896",
    "mintedVia": "CROWN",
  },
  {
    "x": "-20",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1897",
    "zoneId": "4",
    "metaId": "1451",
    "nftId": 1897,
    "creationDate": "1693405555637315",
    "id": "1897",
    "mintedVia": "CROWN",
  },
  {
    "x": "-13",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1898",
    "zoneId": "4",
    "metaId": "1421",
    "nftId": 1898,
    "creationDate": "1693405555637315",
    "id": "1898",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "30",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1899",
    "zoneId": "4",
    "metaId": "1433",
    "nftId": 1899,
    "creationDate": "1693405555637315",
    "id": "1899",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1900",
    "zoneId": "4",
    "metaId": "1148",
    "nftId": 1900,
    "creationDate": "1693405555637315",
    "id": "1900",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1901",
    "zoneId": "4",
    "metaId": "1168",
    "nftId": 1901,
    "creationDate": "1693405555637315",
    "id": "1901",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1902",
    "zoneId": "4",
    "metaId": "1207",
    "nftId": 1902,
    "creationDate": "1693405555637315",
    "id": "1902",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "-5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1903",
    "zoneId": "4",
    "metaId": "1531",
    "nftId": 1903,
    "creationDate": "1693405555637315",
    "id": "1903",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1904",
    "zoneId": "4",
    "metaId": "1195",
    "nftId": 1904,
    "creationDate": "1693405555637315",
    "id": "1904",
    "mintedVia": "CROWN",
  },
  {
    "x": "56",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1905",
    "zoneId": "4",
    "metaId": "1153",
    "nftId": 1905,
    "creationDate": "1693405555637315",
    "id": "1905",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1906",
    "zoneId": "4",
    "metaId": "1339",
    "nftId": 1906,
    "creationDate": "1693405555637315",
    "id": "1906",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1907",
    "zoneId": "4",
    "metaId": "1329",
    "nftId": 1907,
    "creationDate": "1693405555637315",
    "id": "1907",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1908",
    "zoneId": "4",
    "metaId": "1293",
    "nftId": 1908,
    "creationDate": "1693405555637315",
    "id": "1908",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1909",
    "zoneId": "4",
    "metaId": "1465",
    "nftId": 1909,
    "creationDate": "1693405555637315",
    "id": "1909",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1910",
    "zoneId": "4",
    "metaId": "1471",
    "nftId": 1910,
    "creationDate": "1693405555637315",
    "id": "1910",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1911",
    "zoneId": "4",
    "metaId": "1507",
    "nftId": 1911,
    "creationDate": "1693405555637315",
    "id": "1911",
    "mintedVia": "CROWN",
  },
  {
    "x": "-13",
    "y": "27",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1912",
    "zoneId": "4",
    "metaId": "1426",
    "nftId": 1912,
    "creationDate": "1693405555637315",
    "id": "1912",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1913",
    "zoneId": "4",
    "metaId": "1318",
    "nftId": 1913,
    "creationDate": "1693405555637315",
    "id": "1913",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "-7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1914",
    "zoneId": "1",
    "metaId": "618",
    "nftId": 1914,
    "creationDate": "1693405915757933",
    "id": "1914",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "30",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1915",
    "zoneId": "1",
    "metaId": "554",
    "nftId": 1915,
    "creationDate": "1693405915757933",
    "id": "1915",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1916",
    "zoneId": "1",
    "metaId": "298",
    "nftId": 1916,
    "creationDate": "1693405915757933",
    "id": "1916",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1917",
    "zoneId": "1",
    "metaId": "594",
    "nftId": 1917,
    "creationDate": "1693405915757933",
    "id": "1917",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1918",
    "zoneId": "1",
    "metaId": "332",
    "nftId": 1918,
    "creationDate": "1693405915757933",
    "id": "1918",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1919",
    "zoneId": "1",
    "metaId": "468",
    "nftId": 1919,
    "creationDate": "1693405915757933",
    "id": "1919",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1920",
    "zoneId": "1",
    "metaId": "189",
    "nftId": 1920,
    "creationDate": "1693405915757933",
    "id": "1920",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1921",
    "zoneId": "1",
    "metaId": "558",
    "nftId": 1921,
    "creationDate": "1693405915757933",
    "id": "1921",
    "mintedVia": "CROWN",
  },
  {
    "x": "-92",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1922",
    "zoneId": "1",
    "metaId": "179",
    "nftId": 1922,
    "creationDate": "1693405915757933",
    "id": "1922",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "-23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1923",
    "zoneId": "1",
    "metaId": "180",
    "nftId": 1923,
    "creationDate": "1693405915757933",
    "id": "1923",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1924",
    "zoneId": "1",
    "metaId": "473",
    "nftId": 1924,
    "creationDate": "1693405915757933",
    "id": "1924",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1925",
    "zoneId": "1",
    "metaId": "669",
    "nftId": 1925,
    "creationDate": "1693405915757933",
    "id": "1925",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1926",
    "zoneId": "1",
    "metaId": "347",
    "nftId": 1926,
    "creationDate": "1693405915757933",
    "id": "1926",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "-19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1927",
    "zoneId": "1",
    "metaId": "619",
    "nftId": 1927,
    "creationDate": "1693405915757933",
    "id": "1927",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "-19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1928",
    "zoneId": "1",
    "metaId": "627",
    "nftId": 1928,
    "creationDate": "1693405915757933",
    "id": "1928",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1929",
    "zoneId": "1",
    "metaId": "274",
    "nftId": 1929,
    "creationDate": "1693405915757933",
    "id": "1929",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "-11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1930",
    "zoneId": "1",
    "metaId": "599",
    "nftId": 1930,
    "creationDate": "1693405915757933",
    "id": "1930",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1931",
    "zoneId": "1",
    "metaId": "10",
    "nftId": 1931,
    "creationDate": "1693405915757933",
    "id": "1931",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1932",
    "zoneId": "1",
    "metaId": "424",
    "nftId": 1932,
    "creationDate": "1693405915757933",
    "id": "1932",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "-7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1933",
    "zoneId": "1",
    "metaId": "536",
    "nftId": 1933,
    "creationDate": "1693405915757933",
    "id": "1933",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1934",
    "zoneId": "1",
    "metaId": "57",
    "nftId": 1934,
    "creationDate": "1693406085891777",
    "id": "1934",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1935",
    "zoneId": "1",
    "metaId": "429",
    "nftId": 1935,
    "creationDate": "1693406085891777",
    "id": "1935",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1936",
    "zoneId": "1",
    "metaId": "675",
    "nftId": 1936,
    "creationDate": "1693406085891777",
    "id": "1936",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1937",
    "zoneId": "1",
    "metaId": "523",
    "nftId": 1937,
    "creationDate": "1693406085891777",
    "id": "1937",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1938",
    "zoneId": "1",
    "metaId": "338",
    "nftId": 1938,
    "creationDate": "1693406085891777",
    "id": "1938",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "-18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1939",
    "zoneId": "1",
    "metaId": "38",
    "nftId": 1939,
    "creationDate": "1693406085891777",
    "id": "1939",
    "mintedVia": "CROWN",
  },
  {
    "x": "-80",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1940",
    "zoneId": "1",
    "metaId": "137",
    "nftId": 1940,
    "creationDate": "1693406085891777",
    "id": "1940",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "-13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1941",
    "zoneId": "1",
    "metaId": "62",
    "nftId": 1941,
    "creationDate": "1693406085891777",
    "id": "1941",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1942",
    "zoneId": "1",
    "metaId": "291",
    "nftId": 1942,
    "creationDate": "1693406085891777",
    "id": "1942",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "-5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1943",
    "zoneId": "1",
    "metaId": "423",
    "nftId": 1943,
    "creationDate": "1693406085891777",
    "id": "1943",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1944",
    "zoneId": "1",
    "metaId": "566",
    "nftId": 1944,
    "creationDate": "1693406085891777",
    "id": "1944",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "-8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1945",
    "zoneId": "1",
    "metaId": "472",
    "nftId": 1945,
    "creationDate": "1693406085891777",
    "id": "1945",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1946",
    "zoneId": "1",
    "metaId": "674",
    "nftId": 1946,
    "creationDate": "1693406085891777",
    "id": "1946",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1947",
    "zoneId": "1",
    "metaId": "372",
    "nftId": 1947,
    "creationDate": "1693406085891777",
    "id": "1947",
    "mintedVia": "CROWN",
  },
  {
    "x": "-78",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1948",
    "zoneId": "1",
    "metaId": "135",
    "nftId": 1948,
    "creationDate": "1693406085891777",
    "id": "1948",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1949",
    "zoneId": "1",
    "metaId": "349",
    "nftId": 1949,
    "creationDate": "1693406085891777",
    "id": "1949",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1950",
    "zoneId": "1",
    "metaId": "282",
    "nftId": 1950,
    "creationDate": "1693406085891777",
    "id": "1950",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "-17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1951",
    "zoneId": "1",
    "metaId": "647",
    "nftId": 1951,
    "creationDate": "1693406085891777",
    "id": "1951",
    "mintedVia": "CROWN",
  },
  {
    "x": "-70",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1952",
    "zoneId": "1",
    "metaId": "123",
    "nftId": 1952,
    "creationDate": "1693406085891777",
    "id": "1952",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1953",
    "zoneId": "1",
    "metaId": "538",
    "nftId": 1953,
    "creationDate": "1693406085891777",
    "id": "1953",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1954",
    "zoneId": "1",
    "metaId": "84",
    "nftId": 1954,
    "creationDate": "1693406233894120",
    "id": "1954",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1955",
    "zoneId": "1",
    "metaId": "553",
    "nftId": 1955,
    "creationDate": "1693406233894120",
    "id": "1955",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "-10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1956",
    "zoneId": "1",
    "metaId": "152",
    "nftId": 1956,
    "creationDate": "1693406233894120",
    "id": "1956",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "-20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1957",
    "zoneId": "1",
    "metaId": "640",
    "nftId": 1957,
    "creationDate": "1693406233894120",
    "id": "1957",
    "mintedVia": "CROWN",
  },
  {
    "x": "23",
    "y": "-8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1958",
    "zoneId": "1",
    "metaId": "438",
    "nftId": 1958,
    "creationDate": "1693406233894120",
    "id": "1958",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "-18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1959",
    "zoneId": "1",
    "metaId": "709",
    "nftId": 1959,
    "creationDate": "1693406233894120",
    "id": "1959",
    "mintedVia": "CROWN",
  },
  {
    "x": "-100",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1960",
    "zoneId": "1",
    "metaId": "109",
    "nftId": 1960,
    "creationDate": "1693406233894120",
    "id": "1960",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1961",
    "zoneId": "7",
    "metaId": "4333",
    "nftId": 1961,
    "creationDate": "1693406330083791",
    "id": "1961",
    "mintedVia": "CROWN",
  },
  {
    "x": "56",
    "y": "-5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1962",
    "zoneId": "7",
    "metaId": "2798",
    "nftId": 1962,
    "creationDate": "1693406330083791",
    "id": "1962",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1963",
    "zoneId": "7",
    "metaId": "2442",
    "nftId": 1963,
    "creationDate": "1693406330083791",
    "id": "1963",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1964",
    "zoneId": "7",
    "metaId": "4397",
    "nftId": 1964,
    "creationDate": "1693406330083791",
    "id": "1964",
    "mintedVia": "CROWN",
  },
  {
    "x": "-4",
    "y": "34",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1965",
    "zoneId": "7",
    "metaId": "3780",
    "nftId": 1965,
    "creationDate": "1693406330083791",
    "id": "1965",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1966",
    "zoneId": "7",
    "metaId": "3783",
    "nftId": 1966,
    "creationDate": "1693406330083791",
    "id": "1966",
    "mintedVia": "CROWN",
  },
  {
    "x": "-21",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1967",
    "zoneId": "7",
    "metaId": "3587",
    "nftId": 1967,
    "creationDate": "1693406330083791",
    "id": "1967",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1968",
    "zoneId": "7",
    "metaId": "4385",
    "nftId": 1968,
    "creationDate": "1693406330083791",
    "id": "1968",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1969",
    "zoneId": "7",
    "metaId": "3746",
    "nftId": 1969,
    "creationDate": "1693406330083791",
    "id": "1969",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "-14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1970",
    "zoneId": "7",
    "metaId": "4589",
    "nftId": 1970,
    "creationDate": "1693406330083791",
    "id": "1970",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "-17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1971",
    "zoneId": "7",
    "metaId": "4547",
    "nftId": 1971,
    "creationDate": "1693406330083791",
    "id": "1971",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1972",
    "zoneId": "7",
    "metaId": "3830",
    "nftId": 1972,
    "creationDate": "1693406330083791",
    "id": "1972",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1973",
    "zoneId": "7",
    "metaId": "2486",
    "nftId": 1973,
    "creationDate": "1693406330083791",
    "id": "1973",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1974",
    "zoneId": "7",
    "metaId": "3645",
    "nftId": 1974,
    "creationDate": "1693406330083791",
    "id": "1974",
    "mintedVia": "CROWN",
  },
  {
    "x": "46",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1975",
    "zoneId": "7",
    "metaId": "2689",
    "nftId": 1975,
    "creationDate": "1693406330083791",
    "id": "1975",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1976",
    "zoneId": "7",
    "metaId": "2396",
    "nftId": 1976,
    "creationDate": "1693406330083791",
    "id": "1976",
    "mintedVia": "CROWN",
  },
  {
    "x": "17",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1977",
    "zoneId": "7",
    "metaId": "2026",
    "nftId": 1977,
    "creationDate": "1693406330083791",
    "id": "1977",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1978",
    "zoneId": "7",
    "metaId": "3303",
    "nftId": 1978,
    "creationDate": "1693406330083791",
    "id": "1978",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1979",
    "zoneId": "7",
    "metaId": "2495",
    "nftId": 1979,
    "creationDate": "1693406330083791",
    "id": "1979",
    "mintedVia": "CROWN",
  },
  {
    "x": "38",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1980",
    "zoneId": "7",
    "metaId": "2576",
    "nftId": 1980,
    "creationDate": "1693406330083791",
    "id": "1980",
    "mintedVia": "CROWN",
  },
  {
    "x": "46",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1981",
    "zoneId": "7",
    "metaId": "2686",
    "nftId": 1981,
    "creationDate": "1693406330083791",
    "id": "1981",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1982",
    "zoneId": "7",
    "metaId": "4249",
    "nftId": 1982,
    "creationDate": "1693406330083791",
    "id": "1982",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1983",
    "zoneId": "7",
    "metaId": "3822",
    "nftId": 1983,
    "creationDate": "1693406330083791",
    "id": "1983",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1984",
    "zoneId": "7",
    "metaId": "4089",
    "nftId": 1984,
    "creationDate": "1693406330083791",
    "id": "1984",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1985",
    "zoneId": "7",
    "metaId": "3962",
    "nftId": 1985,
    "creationDate": "1693406330083791",
    "id": "1985",
    "mintedVia": "CROWN",
  },
  {
    "x": "38",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1986",
    "zoneId": "7",
    "metaId": "2581",
    "nftId": 1986,
    "creationDate": "1693406494067295",
    "id": "1986",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "-12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1987",
    "zoneId": "7",
    "metaId": "2329",
    "nftId": 1987,
    "creationDate": "1693406494067295",
    "id": "1987",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "-14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1988",
    "zoneId": "7",
    "metaId": "2351",
    "nftId": 1988,
    "creationDate": "1693406494067295",
    "id": "1988",
    "mintedVia": "CROWN",
  },
  {
    "x": "30",
    "y": "-18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1989",
    "zoneId": "7",
    "metaId": "2416",
    "nftId": 1989,
    "creationDate": "1693406494067295",
    "id": "1989",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "-2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1990",
    "zoneId": "7",
    "metaId": "3258",
    "nftId": 1990,
    "creationDate": "1693406494067295",
    "id": "1990",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1991",
    "zoneId": "7",
    "metaId": "3998",
    "nftId": 1991,
    "creationDate": "1693406494067295",
    "id": "1991",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1992",
    "zoneId": "7",
    "metaId": "2360",
    "nftId": 1992,
    "creationDate": "1693406494067295",
    "id": "1992",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "22",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1993",
    "zoneId": "7",
    "metaId": "3965",
    "nftId": 1993,
    "creationDate": "1693406494067295",
    "id": "1993",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1994",
    "zoneId": "7",
    "metaId": "3294",
    "nftId": 1994,
    "creationDate": "1693406494067295",
    "id": "1994",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "-8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1995",
    "zoneId": "7",
    "metaId": "2160",
    "nftId": 1995,
    "creationDate": "1693406494067295",
    "id": "1995",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "-4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1996",
    "zoneId": "7",
    "metaId": "2214",
    "nftId": 1996,
    "creationDate": "1693406494067295",
    "id": "1996",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "-12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1997",
    "zoneId": "7",
    "metaId": "2546",
    "nftId": 1997,
    "creationDate": "1693406494067295",
    "id": "1997",
    "mintedVia": "CROWN",
  },
  {
    "x": "1",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1998",
    "zoneId": "7",
    "metaId": "3876",
    "nftId": 1998,
    "creationDate": "1693406494067295",
    "id": "1998",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #1999",
    "zoneId": "7",
    "metaId": "1941",
    "nftId": 1999,
    "creationDate": "1693406494067295",
    "id": "1999",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "-8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2000",
    "zoneId": "7",
    "metaId": "3030",
    "nftId": 2000,
    "creationDate": "1693406494067295",
    "id": "2000",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2001",
    "zoneId": "7",
    "metaId": "4435",
    "nftId": 2001,
    "creationDate": "1693406494067295",
    "id": "2001",
    "mintedVia": "CROWN",
  },
  {
    "x": "43",
    "y": "-7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2002",
    "zoneId": "7",
    "metaId": "2645",
    "nftId": 2002,
    "creationDate": "1693406494067295",
    "id": "2002",
    "mintedVia": "CROWN",
  },
  {
    "x": "18",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2003",
    "zoneId": "7",
    "metaId": "1923",
    "nftId": 2003,
    "creationDate": "1693406494067295",
    "id": "2003",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2004",
    "zoneId": "7",
    "metaId": "3800",
    "nftId": 2004,
    "creationDate": "1693406494067295",
    "id": "2004",
    "mintedVia": "CROWN",
  },
  {
    "x": "12",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2005",
    "zoneId": "7",
    "metaId": "3176",
    "nftId": 2005,
    "creationDate": "1693406494067295",
    "id": "2005",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "-12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2006",
    "zoneId": "7",
    "metaId": "4588",
    "nftId": 2006,
    "creationDate": "1693406494067295",
    "id": "2006",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2007",
    "zoneId": "7",
    "metaId": "4186",
    "nftId": 2007,
    "creationDate": "1693406494067295",
    "id": "2007",
    "mintedVia": "CROWN",
  },
  {
    "x": "0",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2008",
    "zoneId": "7",
    "metaId": "3855",
    "nftId": 2008,
    "creationDate": "1693406494067295",
    "id": "2008",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2009",
    "zoneId": "7",
    "metaId": "2217",
    "nftId": 2009,
    "creationDate": "1693406494067295",
    "id": "2009",
    "mintedVia": "CROWN",
  },
  {
    "x": "54",
    "y": "-2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2010",
    "zoneId": "7",
    "metaId": "2775",
    "nftId": 2010,
    "creationDate": "1693406494067295",
    "id": "2010",
    "mintedVia": "CROWN",
  },
  {
    "x": "-23",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2011",
    "zoneId": "7",
    "metaId": "3625",
    "nftId": 2011,
    "creationDate": "1693406822284180",
    "id": "2011",
    "mintedVia": "CROWN",
  },
  {
    "x": "27",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2012",
    "zoneId": "7",
    "metaId": "1927",
    "nftId": 2012,
    "creationDate": "1693406822284180",
    "id": "2012",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "34",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2013",
    "zoneId": "7",
    "metaId": "3804",
    "nftId": 2013,
    "creationDate": "1693406822284180",
    "id": "2013",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "-7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2014",
    "zoneId": "7",
    "metaId": "2439",
    "nftId": 2014,
    "creationDate": "1693406822284180",
    "id": "2014",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2015",
    "zoneId": "7",
    "metaId": "2770",
    "nftId": 2015,
    "creationDate": "1693406822284180",
    "id": "2015",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2016",
    "zoneId": "7",
    "metaId": "2704",
    "nftId": 2016,
    "creationDate": "1693406822284180",
    "id": "2016",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "-15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2017",
    "zoneId": "7",
    "metaId": "2490",
    "nftId": 2017,
    "creationDate": "1693406822284180",
    "id": "2017",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2018",
    "zoneId": "7",
    "metaId": "4355",
    "nftId": 2018,
    "creationDate": "1693406822284180",
    "id": "2018",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2019",
    "zoneId": "7",
    "metaId": "4269",
    "nftId": 2019,
    "creationDate": "1693406822284180",
    "id": "2019",
    "mintedVia": "CROWN",
  },
  {
    "x": "12",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2020",
    "zoneId": "7",
    "metaId": "1847",
    "nftId": 2020,
    "creationDate": "1693406822284180",
    "id": "2020",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "-15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2021",
    "zoneId": "7",
    "metaId": "4580",
    "nftId": 2021,
    "creationDate": "1693406822284180",
    "id": "2021",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2022",
    "zoneId": "7",
    "metaId": "4454",
    "nftId": 2022,
    "creationDate": "1693406822284180",
    "id": "2022",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "-8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2023",
    "zoneId": "7",
    "metaId": "2515",
    "nftId": 2023,
    "creationDate": "1693406822284180",
    "id": "2023",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2024",
    "zoneId": "7",
    "metaId": "3063",
    "nftId": 2024,
    "creationDate": "1693406822284180",
    "id": "2024",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2025",
    "zoneId": "7",
    "metaId": "2748",
    "nftId": 2025,
    "creationDate": "1693406822284180",
    "id": "2025",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2026",
    "zoneId": "7",
    "metaId": "4258",
    "nftId": 2026,
    "creationDate": "1693406822284180",
    "id": "2026",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "-14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2027",
    "zoneId": "7",
    "metaId": "2260",
    "nftId": 2027,
    "creationDate": "1693406822284180",
    "id": "2027",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2028",
    "zoneId": "7",
    "metaId": "4380",
    "nftId": 2028,
    "creationDate": "1693406822284180",
    "id": "2028",
    "mintedVia": "CROWN",
  },
  {
    "x": "-11",
    "y": "-5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2029",
    "zoneId": "7",
    "metaId": "2866",
    "nftId": 2029,
    "creationDate": "1693406822284180",
    "id": "2029",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2030",
    "zoneId": "7",
    "metaId": "1872",
    "nftId": 2030,
    "creationDate": "1693406822284180",
    "id": "2030",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2031",
    "zoneId": "7",
    "metaId": "3065",
    "nftId": 2031,
    "creationDate": "1693406822284180",
    "id": "2031",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2032",
    "zoneId": "7",
    "metaId": "4174",
    "nftId": 2032,
    "creationDate": "1693406822284180",
    "id": "2032",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2033",
    "zoneId": "7",
    "metaId": "3946",
    "nftId": 2033,
    "creationDate": "1693406822284180",
    "id": "2033",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2034",
    "zoneId": "7",
    "metaId": "2189",
    "nftId": 2034,
    "creationDate": "1693406822284180",
    "id": "2034",
    "mintedVia": "CROWN",
  },
  {
    "x": "14",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2035",
    "zoneId": "7",
    "metaId": "1899",
    "nftId": 2035,
    "creationDate": "1693406822284180",
    "id": "2035",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2036",
    "zoneId": "7",
    "metaId": "3422",
    "nftId": 2036,
    "creationDate": "1693406970093049",
    "id": "2036",
    "mintedVia": "CROWN",
  },
  {
    "x": "-20",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2037",
    "zoneId": "7",
    "metaId": "3575",
    "nftId": 2037,
    "creationDate": "1693406970093049",
    "id": "2037",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "-12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2038",
    "zoneId": "7",
    "metaId": "2430",
    "nftId": 2038,
    "creationDate": "1693406970093049",
    "id": "2038",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2039",
    "zoneId": "7",
    "metaId": "3639",
    "nftId": 2039,
    "creationDate": "1693406970093049",
    "id": "2039",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2040",
    "zoneId": "7",
    "metaId": "3299",
    "nftId": 2040,
    "creationDate": "1693406970093049",
    "id": "2040",
    "mintedVia": "CROWN",
  },
  {
    "x": "50",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2041",
    "zoneId": "7",
    "metaId": "1953",
    "nftId": 2041,
    "creationDate": "1693406970093049",
    "id": "2041",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2042",
    "zoneId": "7",
    "metaId": "3242",
    "nftId": 2042,
    "creationDate": "1693406970093049",
    "id": "2042",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2043",
    "zoneId": "7",
    "metaId": "4021",
    "nftId": 2043,
    "creationDate": "1693406970093049",
    "id": "2043",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "-7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2044",
    "zoneId": "7",
    "metaId": "2911",
    "nftId": 2044,
    "creationDate": "1693406970093049",
    "id": "2044",
    "mintedVia": "CROWN",
  },
  {
    "x": "6",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2045",
    "zoneId": "7",
    "metaId": "3320",
    "nftId": 2045,
    "creationDate": "1693406970093049",
    "id": "2045",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2046",
    "zoneId": "7",
    "metaId": "4270",
    "nftId": 2046,
    "creationDate": "1693406970093049",
    "id": "2046",
    "mintedVia": "CROWN",
  },
  {
    "x": "-99",
    "y": "-11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2047",
    "zoneId": "7",
    "metaId": "2406",
    "nftId": 2047,
    "creationDate": "1693406970093049",
    "id": "2047",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2048",
    "zoneId": "7",
    "metaId": "4376",
    "nftId": 2048,
    "creationDate": "1693406970093049",
    "id": "2048",
    "mintedVia": "CROWN",
  },
  {
    "x": "31",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2049",
    "zoneId": "7",
    "metaId": "1912",
    "nftId": 2049,
    "creationDate": "1693406970093049",
    "id": "2049",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2050",
    "zoneId": "7",
    "metaId": "3418",
    "nftId": 2050,
    "creationDate": "1693406970093049",
    "id": "2050",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "-18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2051",
    "zoneId": "7",
    "metaId": "4569",
    "nftId": 2051,
    "creationDate": "1693406970093049",
    "id": "2051",
    "mintedVia": "CROWN",
  },
  {
    "x": "39",
    "y": "-12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2052",
    "zoneId": "7",
    "metaId": "2584",
    "nftId": 2052,
    "creationDate": "1693406970093049",
    "id": "2052",
    "mintedVia": "CROWN",
  },
  {
    "x": "-11",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2053",
    "zoneId": "7",
    "metaId": "2861",
    "nftId": 2053,
    "creationDate": "1693406970093049",
    "id": "2053",
    "mintedVia": "CROWN",
  },
  {
    "x": "0",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2054",
    "zoneId": "7",
    "metaId": "3148",
    "nftId": 2054,
    "creationDate": "1693406970093049",
    "id": "2054",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2055",
    "zoneId": "7",
    "metaId": "3715",
    "nftId": 2055,
    "creationDate": "1693406970093049",
    "id": "2055",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2056",
    "zoneId": "7",
    "metaId": "4536",
    "nftId": 2056,
    "creationDate": "1693406970093049",
    "id": "2056",
    "mintedVia": "CROWN",
  },
  {
    "x": "-2",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2057",
    "zoneId": "7",
    "metaId": "3730",
    "nftId": 2057,
    "creationDate": "1693406970093049",
    "id": "2057",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2058",
    "zoneId": "7",
    "metaId": "2370",
    "nftId": 2058,
    "creationDate": "1693406970093049",
    "id": "2058",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "27",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2059",
    "zoneId": "7",
    "metaId": "2133",
    "nftId": 2059,
    "creationDate": "1693406970093049",
    "id": "2059",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2060",
    "zoneId": "7",
    "metaId": "2668",
    "nftId": 2060,
    "creationDate": "1693406970093049",
    "id": "2060",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2061",
    "zoneId": "7",
    "metaId": "4067",
    "nftId": 2061,
    "creationDate": "1693407108133666",
    "id": "2061",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2062",
    "zoneId": "7",
    "metaId": "2887",
    "nftId": 2062,
    "creationDate": "1693407108133666",
    "id": "2062",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "-2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2063",
    "zoneId": "7",
    "metaId": "2212",
    "nftId": 2063,
    "creationDate": "1693407108133666",
    "id": "2063",
    "mintedVia": "CROWN",
  },
  {
    "x": "6",
    "y": "30",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2064",
    "zoneId": "7",
    "metaId": "4478",
    "nftId": 2064,
    "creationDate": "1693407108133666",
    "id": "2064",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2065",
    "zoneId": "7",
    "metaId": "4254",
    "nftId": 2065,
    "creationDate": "1693407108133666",
    "id": "2065",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2066",
    "zoneId": "7",
    "metaId": "3035",
    "nftId": 2066,
    "creationDate": "1693407108133666",
    "id": "2066",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "-34",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2067",
    "zoneId": "7",
    "metaId": "4551",
    "nftId": 2067,
    "creationDate": "1693407108133666",
    "id": "2067",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2068",
    "zoneId": "7",
    "metaId": "1961",
    "nftId": 2068,
    "creationDate": "1693407108133666",
    "id": "2068",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "-12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2069",
    "zoneId": "7",
    "metaId": "4577",
    "nftId": 2069,
    "creationDate": "1693407108133666",
    "id": "2069",
    "mintedVia": "CROWN",
  },
  {
    "x": "-4",
    "y": "-4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2070",
    "zoneId": "7",
    "metaId": "3042",
    "nftId": 2070,
    "creationDate": "1693407108133666",
    "id": "2070",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2071",
    "zoneId": "7",
    "metaId": "4285",
    "nftId": 2071,
    "creationDate": "1693407108133666",
    "id": "2071",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2072",
    "zoneId": "7",
    "metaId": "3116",
    "nftId": 2072,
    "creationDate": "1693407108133666",
    "id": "2072",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2073",
    "zoneId": "7",
    "metaId": "2921",
    "nftId": 2073,
    "creationDate": "1693407108133666",
    "id": "2073",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "-20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2074",
    "zoneId": "7",
    "metaId": "2354",
    "nftId": 2074,
    "creationDate": "1693407108133666",
    "id": "2074",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2075",
    "zoneId": "7",
    "metaId": "2164",
    "nftId": 2075,
    "creationDate": "1693407108133666",
    "id": "2075",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "-15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2076",
    "zoneId": "7",
    "metaId": "2472",
    "nftId": 2076,
    "creationDate": "1693407108133666",
    "id": "2076",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2077",
    "zoneId": "7",
    "metaId": "2535",
    "nftId": 2077,
    "creationDate": "1693407108133666",
    "id": "2077",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2078",
    "zoneId": "7",
    "metaId": "4092",
    "nftId": 2078,
    "creationDate": "1693407108133666",
    "id": "2078",
    "mintedVia": "CROWN",
  },
  {
    "x": "41",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2079",
    "zoneId": "7",
    "metaId": "2620",
    "nftId": 2079,
    "creationDate": "1693407108133666",
    "id": "2079",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "-8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2080",
    "zoneId": "7",
    "metaId": "3134",
    "nftId": 2080,
    "creationDate": "1693407108133666",
    "id": "2080",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2081",
    "zoneId": "7",
    "metaId": "4570",
    "nftId": 2081,
    "creationDate": "1693407108133666",
    "id": "2081",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2082",
    "zoneId": "7",
    "metaId": "3261",
    "nftId": 2082,
    "creationDate": "1693407108133666",
    "id": "2082",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2083",
    "zoneId": "7",
    "metaId": "4034",
    "nftId": 2083,
    "creationDate": "1693407108133666",
    "id": "2083",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "22",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2084",
    "zoneId": "7",
    "metaId": "3922",
    "nftId": 2084,
    "creationDate": "1693407108133666",
    "id": "2084",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2085",
    "zoneId": "7",
    "metaId": "1907",
    "nftId": 2085,
    "creationDate": "1693407108133666",
    "id": "2085",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "-2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2086",
    "zoneId": "7",
    "metaId": "4226",
    "nftId": 2086,
    "creationDate": "1693407262409434",
    "id": "2086",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2087",
    "zoneId": "7",
    "metaId": "2162",
    "nftId": 2087,
    "creationDate": "1693407262409434",
    "id": "2087",
    "mintedVia": "CROWN",
  },
  {
    "x": "-79",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2088",
    "zoneId": "7",
    "metaId": "2323",
    "nftId": 2088,
    "creationDate": "1693407262409434",
    "id": "2088",
    "mintedVia": "CROWN",
  },
  {
    "x": "9",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2089",
    "zoneId": "7",
    "metaId": "3360",
    "nftId": 2089,
    "creationDate": "1693407262409434",
    "id": "2089",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2090",
    "zoneId": "7",
    "metaId": "3955",
    "nftId": 2090,
    "creationDate": "1693407262409434",
    "id": "2090",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2091",
    "zoneId": "7",
    "metaId": "3087",
    "nftId": 2091,
    "creationDate": "1693407262409434",
    "id": "2091",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2092",
    "zoneId": "7",
    "metaId": "2172",
    "nftId": 2092,
    "creationDate": "1693407262409434",
    "id": "2092",
    "mintedVia": "CROWN",
  },
  {
    "x": "10",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2093",
    "zoneId": "7",
    "metaId": "3166",
    "nftId": 2093,
    "creationDate": "1693407262409434",
    "id": "2093",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2094",
    "zoneId": "7",
    "metaId": "2127",
    "nftId": 2094,
    "creationDate": "1693407262409434",
    "id": "2094",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2095",
    "zoneId": "7",
    "metaId": "3267",
    "nftId": 2095,
    "creationDate": "1693407262409434",
    "id": "2095",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2096",
    "zoneId": "7",
    "metaId": "2264",
    "nftId": 2096,
    "creationDate": "1693407262409434",
    "id": "2096",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "-5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2097",
    "zoneId": "7",
    "metaId": "2158",
    "nftId": 2097,
    "creationDate": "1693407262409434",
    "id": "2097",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2098",
    "zoneId": "7",
    "metaId": "1957",
    "nftId": 2098,
    "creationDate": "1693407262409434",
    "id": "2098",
    "mintedVia": "CROWN",
  },
  {
    "x": "6",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2099",
    "zoneId": "7",
    "metaId": "3318",
    "nftId": 2099,
    "creationDate": "1693407262409434",
    "id": "2099",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2100",
    "zoneId": "7",
    "metaId": "4488",
    "nftId": 2100,
    "creationDate": "1693407262409434",
    "id": "2100",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2101",
    "zoneId": "7",
    "metaId": "3782",
    "nftId": 2101,
    "creationDate": "1693407262409434",
    "id": "2101",
    "mintedVia": "CROWN",
  },
  {
    "x": "-21",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2102",
    "zoneId": "7",
    "metaId": "3592",
    "nftId": 2102,
    "creationDate": "1693407262409434",
    "id": "2102",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2103",
    "zoneId": "7",
    "metaId": "2718",
    "nftId": 2103,
    "creationDate": "1693407262409434",
    "id": "2103",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2104",
    "zoneId": "7",
    "metaId": "4060",
    "nftId": 2104,
    "creationDate": "1693407262409434",
    "id": "2104",
    "mintedVia": "CROWN",
  },
  {
    "x": "-4",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2105",
    "zoneId": "7",
    "metaId": "3044",
    "nftId": 2105,
    "creationDate": "1693407262409434",
    "id": "2105",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2106",
    "zoneId": "7",
    "metaId": "3291",
    "nftId": 2106,
    "creationDate": "1693407262409434",
    "id": "2106",
    "mintedVia": "CROWN",
  },
  {
    "x": "39",
    "y": "-18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2107",
    "zoneId": "7",
    "metaId": "2590",
    "nftId": 2107,
    "creationDate": "1693407262409434",
    "id": "2107",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "-15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2108",
    "zoneId": "7",
    "metaId": "2235",
    "nftId": 2108,
    "creationDate": "1693407262409434",
    "id": "2108",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2109",
    "zoneId": "7",
    "metaId": "1954",
    "nftId": 2109,
    "creationDate": "1693407262409434",
    "id": "2109",
    "mintedVia": "CROWN",
  },
  {
    "x": "-65",
    "y": "-26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2110",
    "zoneId": "7",
    "metaId": "4663",
    "nftId": 2110,
    "creationDate": "1693407262409434",
    "id": "2110",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2111",
    "zoneId": "7",
    "metaId": "2484",
    "nftId": 2111,
    "creationDate": "1693407464703257",
    "id": "2111",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2112",
    "zoneId": "7",
    "metaId": "3653",
    "nftId": 2112,
    "creationDate": "1693407464703257",
    "id": "2112",
    "mintedVia": "CROWN",
  },
  {
    "x": "10",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2113",
    "zoneId": "7",
    "metaId": "1973",
    "nftId": 2113,
    "creationDate": "1693407464703257",
    "id": "2113",
    "mintedVia": "CROWN",
  },
  {
    "x": "18",
    "y": "27",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2114",
    "zoneId": "7",
    "metaId": "2027",
    "nftId": 2114,
    "creationDate": "1693407464703257",
    "id": "2114",
    "mintedVia": "CROWN",
  },
  {
    "x": "55",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2115",
    "zoneId": "7",
    "metaId": "2118",
    "nftId": 2115,
    "creationDate": "1693407464703257",
    "id": "2115",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2116",
    "zoneId": "7",
    "metaId": "4398",
    "nftId": 2116,
    "creationDate": "1693407464703257",
    "id": "2116",
    "mintedVia": "CROWN",
  },
  {
    "x": "32",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2117",
    "zoneId": "7",
    "metaId": "2459",
    "nftId": 2117,
    "creationDate": "1693407464703257",
    "id": "2117",
    "mintedVia": "CROWN",
  },
  {
    "x": "54",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2118",
    "zoneId": "7",
    "metaId": "2780",
    "nftId": 2118,
    "creationDate": "1693407464703257",
    "id": "2118",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2119",
    "zoneId": "7",
    "metaId": "4033",
    "nftId": 2119,
    "creationDate": "1693407464703257",
    "id": "2119",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2120",
    "zoneId": "7",
    "metaId": "3123",
    "nftId": 2120,
    "creationDate": "1693407464703257",
    "id": "2120",
    "mintedVia": "CROWN",
  },
  {
    "x": "-2",
    "y": "27",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2121",
    "zoneId": "7",
    "metaId": "3729",
    "nftId": 2121,
    "creationDate": "1693407464703257",
    "id": "2121",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "22",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2122",
    "zoneId": "7",
    "metaId": "2066",
    "nftId": 2122,
    "creationDate": "1693407464703257",
    "id": "2122",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2123",
    "zoneId": "7",
    "metaId": "3634",
    "nftId": 2123,
    "creationDate": "1693407464703257",
    "id": "2123",
    "mintedVia": "CROWN",
  },
  {
    "x": "0",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2124",
    "zoneId": "7",
    "metaId": "3158",
    "nftId": 2124,
    "creationDate": "1693407464703257",
    "id": "2124",
    "mintedVia": "CROWN",
  },
  {
    "x": "18",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2125",
    "zoneId": "7",
    "metaId": "3235",
    "nftId": 2125,
    "creationDate": "1693407464703257",
    "id": "2125",
    "mintedVia": "CROWN",
  },
  {
    "x": "39",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2126",
    "zoneId": "7",
    "metaId": "2596",
    "nftId": 2126,
    "creationDate": "1693407464703257",
    "id": "2126",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2127",
    "zoneId": "7",
    "metaId": "3126",
    "nftId": 2127,
    "creationDate": "1693407464703257",
    "id": "2127",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2128",
    "zoneId": "7",
    "metaId": "3508",
    "nftId": 2128,
    "creationDate": "1693407464703257",
    "id": "2128",
    "mintedVia": "CROWN",
  },
  {
    "x": "-10",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2129",
    "zoneId": "7",
    "metaId": "3387",
    "nftId": 2129,
    "creationDate": "1693407464703257",
    "id": "2129",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2130",
    "zoneId": "7",
    "metaId": "2695",
    "nftId": 2130,
    "creationDate": "1693407464703257",
    "id": "2130",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2131",
    "zoneId": "7",
    "metaId": "4159",
    "nftId": 2131,
    "creationDate": "1693407464703257",
    "id": "2131",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2132",
    "zoneId": "7",
    "metaId": "2078",
    "nftId": 2132,
    "creationDate": "1693407464703257",
    "id": "2132",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2133",
    "zoneId": "7",
    "metaId": "4426",
    "nftId": 2133,
    "creationDate": "1693407464703257",
    "id": "2133",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2134",
    "zoneId": "7",
    "metaId": "2878",
    "nftId": 2134,
    "creationDate": "1693407464703257",
    "id": "2134",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2135",
    "zoneId": "7",
    "metaId": "4039",
    "nftId": 2135,
    "creationDate": "1693407464703257",
    "id": "2135",
    "mintedVia": "CROWN",
  },
  {
    "x": "11",
    "y": "31",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2136",
    "zoneId": "7",
    "metaId": "1977",
    "nftId": 2136,
    "creationDate": "1693407640436053",
    "id": "2136",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2137",
    "zoneId": "7",
    "metaId": "3940",
    "nftId": 2137,
    "creationDate": "1693407640436053",
    "id": "2137",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2138",
    "zoneId": "7",
    "metaId": "2744",
    "nftId": 2138,
    "creationDate": "1693407640436053",
    "id": "2138",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2139",
    "zoneId": "7",
    "metaId": "4418",
    "nftId": 2139,
    "creationDate": "1693407640436053",
    "id": "2139",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "-4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2140",
    "zoneId": "7",
    "metaId": "3114",
    "nftId": 2140,
    "creationDate": "1693407640436053",
    "id": "2140",
    "mintedVia": "CROWN",
  },
  {
    "x": "-4",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2141",
    "zoneId": "7",
    "metaId": "3778",
    "nftId": 2141,
    "creationDate": "1693407640436053",
    "id": "2141",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "27",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2142",
    "zoneId": "7",
    "metaId": "2170",
    "nftId": 2142,
    "creationDate": "1693407640436053",
    "id": "2142",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2143",
    "zoneId": "7",
    "metaId": "3312",
    "nftId": 2143,
    "creationDate": "1693407640436053",
    "id": "2143",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2144",
    "zoneId": "7",
    "metaId": "3108",
    "nftId": 2144,
    "creationDate": "1693407640436053",
    "id": "2144",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2145",
    "zoneId": "7",
    "metaId": "4659",
    "nftId": 2145,
    "creationDate": "1693407640436053",
    "id": "2145",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2146",
    "zoneId": "7",
    "metaId": "3342",
    "nftId": 2146,
    "creationDate": "1693407640436053",
    "id": "2146",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2147",
    "zoneId": "7",
    "metaId": "3970",
    "nftId": 2147,
    "creationDate": "1693407640436053",
    "id": "2147",
    "mintedVia": "CROWN",
  },
  {
    "x": "-2",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2148",
    "zoneId": "7",
    "metaId": "3727",
    "nftId": 2148,
    "creationDate": "1693407640436053",
    "id": "2148",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "-2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2149",
    "zoneId": "7",
    "metaId": "2176",
    "nftId": 2149,
    "creationDate": "1693407640436053",
    "id": "2149",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2150",
    "zoneId": "7",
    "metaId": "3443",
    "nftId": 2150,
    "creationDate": "1693407640436053",
    "id": "2150",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "27",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2151",
    "zoneId": "7",
    "metaId": "3801",
    "nftId": 2151,
    "creationDate": "1693407640436053",
    "id": "2151",
    "mintedVia": "CROWN",
  },
  {
    "x": "2",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2152",
    "zoneId": "7",
    "metaId": "3896",
    "nftId": 2152,
    "creationDate": "1693407640436053",
    "id": "2152",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2153",
    "zoneId": "7",
    "metaId": "4144",
    "nftId": 2153,
    "creationDate": "1693407640436053",
    "id": "2153",
    "mintedVia": "CROWN",
  },
  {
    "x": "-9",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2154",
    "zoneId": "7",
    "metaId": "3851",
    "nftId": 2154,
    "creationDate": "1693407640436053",
    "id": "2154",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "-13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2155",
    "zoneId": "7",
    "metaId": "2251",
    "nftId": 2155,
    "creationDate": "1693407640436053",
    "id": "2155",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2156",
    "zoneId": "7",
    "metaId": "2663",
    "nftId": 2156,
    "creationDate": "1693407640436053",
    "id": "2156",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2157",
    "zoneId": "7",
    "metaId": "3090",
    "nftId": 2157,
    "creationDate": "1693407640436053",
    "id": "2157",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2158",
    "zoneId": "7",
    "metaId": "4410",
    "nftId": 2158,
    "creationDate": "1693407640436053",
    "id": "2158",
    "mintedVia": "CROWN",
  },
  {
    "x": "10",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2159",
    "zoneId": "7",
    "metaId": "3159",
    "nftId": 2159,
    "creationDate": "1693407640436053",
    "id": "2159",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2160",
    "zoneId": "7",
    "metaId": "4538",
    "nftId": 2160,
    "creationDate": "1693407640436053",
    "id": "2160",
    "mintedVia": "CROWN",
  },
  {
    "x": "56",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2161",
    "zoneId": "7",
    "metaId": "2795",
    "nftId": 2161,
    "creationDate": "1693407773681219",
    "id": "2161",
    "mintedVia": "CROWN",
  },
  {
    "x": "-98",
    "y": "-13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2162",
    "zoneId": "7",
    "metaId": "2400",
    "nftId": 2162,
    "creationDate": "1693407773681219",
    "id": "2162",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2163",
    "zoneId": "7",
    "metaId": "3958",
    "nftId": 2163,
    "creationDate": "1693407773681219",
    "id": "2163",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2164",
    "zoneId": "7",
    "metaId": "4383",
    "nftId": 2164,
    "creationDate": "1693407773681219",
    "id": "2164",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "-16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2165",
    "zoneId": "7",
    "metaId": "4600",
    "nftId": 2165,
    "creationDate": "1693407773681219",
    "id": "2165",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2166",
    "zoneId": "7",
    "metaId": "2543",
    "nftId": 2166,
    "creationDate": "1693407773681219",
    "id": "2166",
    "mintedVia": "CROWN",
  },
  {
    "x": "20",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2167",
    "zoneId": "8",
    "metaId": "4677",
    "nftId": 2167,
    "creationDate": "1693407855672660",
    "id": "2167",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "-15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2168",
    "zoneId": "8",
    "metaId": "5472",
    "nftId": 2168,
    "creationDate": "1693407855672660",
    "id": "2168",
    "mintedVia": "CROWN",
  },
  {
    "x": "58",
    "y": "27",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2169",
    "zoneId": "8",
    "metaId": "5051",
    "nftId": 2169,
    "creationDate": "1693407855672660",
    "id": "2169",
    "mintedVia": "CROWN",
  },
  {
    "x": "-98",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2170",
    "zoneId": "8",
    "metaId": "6567",
    "nftId": 2170,
    "creationDate": "1693407855672660",
    "id": "2170",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2171",
    "zoneId": "8",
    "metaId": "6465",
    "nftId": 2171,
    "creationDate": "1693407855672660",
    "id": "2171",
    "mintedVia": "CROWN",
  },
  {
    "x": "-73",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2172",
    "zoneId": "8",
    "metaId": "6254",
    "nftId": 2172,
    "creationDate": "1693407855672660",
    "id": "2172",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "-7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2173",
    "zoneId": "8",
    "metaId": "5715",
    "nftId": 2173,
    "creationDate": "1693407855672660",
    "id": "2173",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2174",
    "zoneId": "8",
    "metaId": "5500",
    "nftId": 2174,
    "creationDate": "1693407855672660",
    "id": "2174",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2175",
    "zoneId": "8",
    "metaId": "6752",
    "nftId": 2175,
    "creationDate": "1693407855672660",
    "id": "2175",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2176",
    "zoneId": "8",
    "metaId": "6464",
    "nftId": 2176,
    "creationDate": "1693407855672660",
    "id": "2176",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2177",
    "zoneId": "8",
    "metaId": "5033",
    "nftId": 2177,
    "creationDate": "1693407855672660",
    "id": "2177",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2178",
    "zoneId": "8",
    "metaId": "5267",
    "nftId": 2178,
    "creationDate": "1693407855672660",
    "id": "2178",
    "mintedVia": "CROWN",
  },
  {
    "x": "-77",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2179",
    "zoneId": "8",
    "metaId": "6294",
    "nftId": 2179,
    "creationDate": "1693407855672660",
    "id": "2179",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2180",
    "zoneId": "8",
    "metaId": "4837",
    "nftId": 2180,
    "creationDate": "1693407855672660",
    "id": "2180",
    "mintedVia": "CROWN",
  },
  {
    "x": "-109",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2181",
    "zoneId": "8",
    "metaId": "6201",
    "nftId": 2181,
    "creationDate": "1693407855672660",
    "id": "2181",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2182",
    "zoneId": "8",
    "metaId": "5878",
    "nftId": 2182,
    "creationDate": "1693407855672660",
    "id": "2182",
    "mintedVia": "CROWN",
  },
  {
    "x": "-74",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2183",
    "zoneId": "8",
    "metaId": "6260",
    "nftId": 2183,
    "creationDate": "1693407855672660",
    "id": "2183",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2184",
    "zoneId": "8",
    "metaId": "6647",
    "nftId": 2184,
    "creationDate": "1693407855672660",
    "id": "2184",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2185",
    "zoneId": "8",
    "metaId": "5351",
    "nftId": 2185,
    "creationDate": "1693407855672660",
    "id": "2185",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2186",
    "zoneId": "8",
    "metaId": "5915",
    "nftId": 2186,
    "creationDate": "1693407855672660",
    "id": "2186",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2187",
    "zoneId": "8",
    "metaId": "4948",
    "nftId": 2187,
    "creationDate": "1693407855672660",
    "id": "2187",
    "mintedVia": "CROWN",
  },
  {
    "x": "-103",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2188",
    "zoneId": "8",
    "metaId": "6151",
    "nftId": 2188,
    "creationDate": "1693407855672660",
    "id": "2188",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2189",
    "zoneId": "8",
    "metaId": "6764",
    "nftId": 2189,
    "creationDate": "1693407855672660",
    "id": "2189",
    "mintedVia": "CROWN",
  },
  {
    "x": "20",
    "y": "23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2190",
    "zoneId": "8",
    "metaId": "4787",
    "nftId": 2190,
    "creationDate": "1693407855672660",
    "id": "2190",
    "mintedVia": "CROWN",
  },
  {
    "x": "84",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2191",
    "zoneId": "8",
    "metaId": "5945",
    "nftId": 2191,
    "creationDate": "1693407855672660",
    "id": "2191",
    "mintedVia": "CROWN",
  },
  {
    "x": "-72",
    "y": "19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2192",
    "zoneId": "8",
    "metaId": "6246",
    "nftId": 2192,
    "creationDate": "1693408003756121",
    "id": "2192",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2193",
    "zoneId": "8",
    "metaId": "6812",
    "nftId": 2193,
    "creationDate": "1693408003756121",
    "id": "2193",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "-8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2194",
    "zoneId": "8",
    "metaId": "5716",
    "nftId": 2194,
    "creationDate": "1693408003756121",
    "id": "2194",
    "mintedVia": "CROWN",
  },
  {
    "x": "27",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2195",
    "zoneId": "8",
    "metaId": "4946",
    "nftId": 2195,
    "creationDate": "1693408003756121",
    "id": "2195",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2196",
    "zoneId": "8",
    "metaId": "6038",
    "nftId": 2196,
    "creationDate": "1693408003756121",
    "id": "2196",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2197",
    "zoneId": "8",
    "metaId": "5797",
    "nftId": 2197,
    "creationDate": "1693408003756121",
    "id": "2197",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "-7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2198",
    "zoneId": "8",
    "metaId": "6881",
    "nftId": 2198,
    "creationDate": "1693408003756121",
    "id": "2198",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2199",
    "zoneId": "8",
    "metaId": "5580",
    "nftId": 2199,
    "creationDate": "1693408003756121",
    "id": "2199",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2200",
    "zoneId": "8",
    "metaId": "5525",
    "nftId": 2200,
    "creationDate": "1693408003756121",
    "id": "2200",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "-18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2201",
    "zoneId": "8",
    "metaId": "6844",
    "nftId": 2201,
    "creationDate": "1693408003756121",
    "id": "2201",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "-17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2202",
    "zoneId": "8",
    "metaId": "5399",
    "nftId": 2202,
    "creationDate": "1693408003756121",
    "id": "2202",
    "mintedVia": "CROWN",
  },
  {
    "x": "-95",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2203",
    "zoneId": "8",
    "metaId": "6503",
    "nftId": 2203,
    "creationDate": "1693408003756121",
    "id": "2203",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2204",
    "zoneId": "8",
    "metaId": "5742",
    "nftId": 2204,
    "creationDate": "1693408003756121",
    "id": "2204",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "-15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2205",
    "zoneId": "8",
    "metaId": "6530",
    "nftId": 2205,
    "creationDate": "1693408003756121",
    "id": "2205",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2206",
    "zoneId": "8",
    "metaId": "6486",
    "nftId": 2206,
    "creationDate": "1693408003756121",
    "id": "2206",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2207",
    "zoneId": "8",
    "metaId": "5809",
    "nftId": 2207,
    "creationDate": "1693408003756121",
    "id": "2207",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2208",
    "zoneId": "8",
    "metaId": "5223",
    "nftId": 2208,
    "creationDate": "1693408003756121",
    "id": "2208",
    "mintedVia": "CROWN",
  },
  {
    "x": "20",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2209",
    "zoneId": "8",
    "metaId": "4764",
    "nftId": 2209,
    "creationDate": "1693408003756121",
    "id": "2209",
    "mintedVia": "CROWN",
  },
  {
    "x": "-95",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2210",
    "zoneId": "8",
    "metaId": "6506",
    "nftId": 2210,
    "creationDate": "1693408003756121",
    "id": "2210",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "-4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2211",
    "zoneId": "8",
    "metaId": "6807",
    "nftId": 2211,
    "creationDate": "1693408003756121",
    "id": "2211",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2212",
    "zoneId": "8",
    "metaId": "4907",
    "nftId": 2212,
    "creationDate": "1693408003756121",
    "id": "2212",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "27",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2213",
    "zoneId": "8",
    "metaId": "5006",
    "nftId": 2213,
    "creationDate": "1693408003756121",
    "id": "2213",
    "mintedVia": "CROWN",
  },
  {
    "x": "18",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2214",
    "zoneId": "8",
    "metaId": "4927",
    "nftId": 2214,
    "creationDate": "1693408003756121",
    "id": "2214",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2215",
    "zoneId": "8",
    "metaId": "5826",
    "nftId": 2215,
    "creationDate": "1693408003756121",
    "id": "2215",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2216",
    "zoneId": "8",
    "metaId": "6046",
    "nftId": 2216,
    "creationDate": "1693408003756121",
    "id": "2216",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2217",
    "zoneId": "8",
    "metaId": "5999",
    "nftId": 2217,
    "creationDate": "1693408109731800",
    "id": "2217",
    "mintedVia": "CROWN",
  },
  {
    "x": "17",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2218",
    "zoneId": "8",
    "metaId": "4865",
    "nftId": 2218,
    "creationDate": "1693408109731800",
    "id": "2218",
    "mintedVia": "CROWN",
  },
  {
    "x": "90",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2219",
    "zoneId": "8",
    "metaId": "6078",
    "nftId": 2219,
    "creationDate": "1693408109731800",
    "id": "2219",
    "mintedVia": "CROWN",
  },
  {
    "x": "-98",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2220",
    "zoneId": "8",
    "metaId": "6561",
    "nftId": 2220,
    "creationDate": "1693408109731800",
    "id": "2220",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "-19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2221",
    "zoneId": "8",
    "metaId": "6402",
    "nftId": 2221,
    "creationDate": "1693408109731800",
    "id": "2221",
    "mintedVia": "CROWN",
  },
  {
    "x": "-74",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2222",
    "zoneId": "8",
    "metaId": "6262",
    "nftId": 2222,
    "creationDate": "1693408109731800",
    "id": "2222",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2223",
    "zoneId": "8",
    "metaId": "5416",
    "nftId": 2223,
    "creationDate": "1693408109731800",
    "id": "2223",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2224",
    "zoneId": "8",
    "metaId": "5696",
    "nftId": 2224,
    "creationDate": "1693408109731800",
    "id": "2224",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2225",
    "zoneId": "8",
    "metaId": "5186",
    "nftId": 2225,
    "creationDate": "1693408109731800",
    "id": "2225",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2226",
    "zoneId": "8",
    "metaId": "5522",
    "nftId": 2226,
    "creationDate": "1693408109731800",
    "id": "2226",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2227",
    "zoneId": "8",
    "metaId": "5788",
    "nftId": 2227,
    "creationDate": "1693408109731800",
    "id": "2227",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2228",
    "zoneId": "8",
    "metaId": "5151",
    "nftId": 2228,
    "creationDate": "1693408109731800",
    "id": "2228",
    "mintedVia": "CROWN",
  },
  {
    "x": "-100",
    "y": "-15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2229",
    "zoneId": "8",
    "metaId": "6085",
    "nftId": 2229,
    "creationDate": "1693408109731800",
    "id": "2229",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2230",
    "zoneId": "8",
    "metaId": "5211",
    "nftId": 2230,
    "creationDate": "1693408109731800",
    "id": "2230",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2231",
    "zoneId": "8",
    "metaId": "5431",
    "nftId": 2231,
    "creationDate": "1693408109731800",
    "id": "2231",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "-4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2232",
    "zoneId": "8",
    "metaId": "6898",
    "nftId": 2232,
    "creationDate": "1693408109731800",
    "id": "2232",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "-14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2233",
    "zoneId": "8",
    "metaId": "5056",
    "nftId": 2233,
    "creationDate": "1693408109731800",
    "id": "2233",
    "mintedVia": "CROWN",
  },
  {
    "x": "-49",
    "y": "-12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2234",
    "zoneId": "8",
    "metaId": "6910",
    "nftId": 2234,
    "creationDate": "1693408109731800",
    "id": "2234",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "-2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2235",
    "zoneId": "8",
    "metaId": "6701",
    "nftId": 2235,
    "creationDate": "1693408109731800",
    "id": "2235",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "-2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2236",
    "zoneId": "8",
    "metaId": "5158",
    "nftId": 2236,
    "creationDate": "1693408109731800",
    "id": "2236",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2237",
    "zoneId": "8",
    "metaId": "5111",
    "nftId": 2237,
    "creationDate": "1693408109731800",
    "id": "2237",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2238",
    "zoneId": "8",
    "metaId": "6809",
    "nftId": 2238,
    "creationDate": "1693408109731800",
    "id": "2238",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2239",
    "zoneId": "8",
    "metaId": "6674",
    "nftId": 2239,
    "creationDate": "1693408109731800",
    "id": "2239",
    "mintedVia": "CROWN",
  },
  {
    "x": "85",
    "y": "23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2240",
    "zoneId": "8",
    "metaId": "5975",
    "nftId": 2240,
    "creationDate": "1693408109731800",
    "id": "2240",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2241",
    "zoneId": "8",
    "metaId": "4912",
    "nftId": 2241,
    "creationDate": "1693408109731800",
    "id": "2241",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2242",
    "zoneId": "8",
    "metaId": "5366",
    "nftId": 2242,
    "creationDate": "1693408269835349",
    "id": "2242",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2243",
    "zoneId": "8",
    "metaId": "6801",
    "nftId": 2243,
    "creationDate": "1693408269835349",
    "id": "2243",
    "mintedVia": "CROWN",
  },
  {
    "x": "10",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2244",
    "zoneId": "8",
    "metaId": "6612",
    "nftId": 2244,
    "creationDate": "1693408269835349",
    "id": "2244",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2245",
    "zoneId": "8",
    "metaId": "6617",
    "nftId": 2245,
    "creationDate": "1693408269835349",
    "id": "2245",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2246",
    "zoneId": "8",
    "metaId": "4936",
    "nftId": 2246,
    "creationDate": "1693408269835349",
    "id": "2246",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2247",
    "zoneId": "8",
    "metaId": "6678",
    "nftId": 2247,
    "creationDate": "1693408269835349",
    "id": "2247",
    "mintedVia": "CROWN",
  },
  {
    "x": "-92",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2248",
    "zoneId": "8",
    "metaId": "6441",
    "nftId": 2248,
    "creationDate": "1693408269835349",
    "id": "2248",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2249",
    "zoneId": "8",
    "metaId": "5327",
    "nftId": 2249,
    "creationDate": "1693408269835349",
    "id": "2249",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2250",
    "zoneId": "8",
    "metaId": "5544",
    "nftId": 2250,
    "creationDate": "1693408269835349",
    "id": "2250",
    "mintedVia": "CROWN",
  },
  {
    "x": "-109",
    "y": "-4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2251",
    "zoneId": "8",
    "metaId": "6204",
    "nftId": 2251,
    "creationDate": "1693408269835349",
    "id": "2251",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "22",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2252",
    "zoneId": "8",
    "metaId": "5573",
    "nftId": 2252,
    "creationDate": "1693408269835349",
    "id": "2252",
    "mintedVia": "CROWN",
  },
  {
    "x": "-95",
    "y": "-21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2253",
    "zoneId": "8",
    "metaId": "6492",
    "nftId": 2253,
    "creationDate": "1693408269835349",
    "id": "2253",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2254",
    "zoneId": "8",
    "metaId": "5516",
    "nftId": 2254,
    "creationDate": "1693408269835349",
    "id": "2254",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "-14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2255",
    "zoneId": "8",
    "metaId": "6902",
    "nftId": 2255,
    "creationDate": "1693408269835349",
    "id": "2255",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2256",
    "zoneId": "8",
    "metaId": "5328",
    "nftId": 2256,
    "creationDate": "1693408269835349",
    "id": "2256",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2257",
    "zoneId": "8",
    "metaId": "5846",
    "nftId": 2257,
    "creationDate": "1693408269835349",
    "id": "2257",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2258",
    "zoneId": "8",
    "metaId": "5563",
    "nftId": 2258,
    "creationDate": "1693408269835349",
    "id": "2258",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "-14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2259",
    "zoneId": "8",
    "metaId": "5707",
    "nftId": 2259,
    "creationDate": "1693408269835349",
    "id": "2259",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2260",
    "zoneId": "8",
    "metaId": "5506",
    "nftId": 2260,
    "creationDate": "1693408269835349",
    "id": "2260",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2261",
    "zoneId": "8",
    "metaId": "5854",
    "nftId": 2261,
    "creationDate": "1693408269835349",
    "id": "2261",
    "mintedVia": "CROWN",
  },
  {
    "x": "-83",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2262",
    "zoneId": "8",
    "metaId": "6351",
    "nftId": 2262,
    "creationDate": "1693408269835349",
    "id": "2262",
    "mintedVia": "CROWN",
  },
  {
    "x": "84",
    "y": "-4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2263",
    "zoneId": "8",
    "metaId": "5923",
    "nftId": 2263,
    "creationDate": "1693408269835349",
    "id": "2263",
    "mintedVia": "CROWN",
  },
  {
    "x": "-89",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2264",
    "zoneId": "8",
    "metaId": "6398",
    "nftId": 2264,
    "creationDate": "1693408269835349",
    "id": "2264",
    "mintedVia": "CROWN",
  },
  {
    "x": "38",
    "y": "19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2265",
    "zoneId": "8",
    "metaId": "4811",
    "nftId": 2265,
    "creationDate": "1693408269835349",
    "id": "2265",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2266",
    "zoneId": "8",
    "metaId": "5307",
    "nftId": 2266,
    "creationDate": "1693408269835349",
    "id": "2266",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "-20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2267",
    "zoneId": "8",
    "metaId": "6535",
    "nftId": 2267,
    "creationDate": "1693408363898511",
    "id": "2267",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "22",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2268",
    "zoneId": "8",
    "metaId": "5381",
    "nftId": 2268,
    "creationDate": "1693408363898511",
    "id": "2268",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "-2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2269",
    "zoneId": "8",
    "metaId": "6655",
    "nftId": 2269,
    "creationDate": "1693408363898511",
    "id": "2269",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "-7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2270",
    "zoneId": "8",
    "metaId": "5752",
    "nftId": 2270,
    "creationDate": "1693408363898511",
    "id": "2270",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "-4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2271",
    "zoneId": "8",
    "metaId": "5517",
    "nftId": 2271,
    "creationDate": "1693408363898511",
    "id": "2271",
    "mintedVia": "CROWN",
  },
  {
    "x": "-95",
    "y": "-2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2272",
    "zoneId": "8",
    "metaId": "6490",
    "nftId": 2272,
    "creationDate": "1693408363898511",
    "id": "2272",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2273",
    "zoneId": "8",
    "metaId": "5271",
    "nftId": 2273,
    "creationDate": "1693408363898511",
    "id": "2273",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2274",
    "zoneId": "8",
    "metaId": "5796",
    "nftId": 2274,
    "creationDate": "1693408363898511",
    "id": "2274",
    "mintedVia": "CROWN",
  },
  {
    "x": "84",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2275",
    "zoneId": "8",
    "metaId": "5922",
    "nftId": 2275,
    "creationDate": "1693408363898511",
    "id": "2275",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2276",
    "zoneId": "8",
    "metaId": "6619",
    "nftId": 2276,
    "creationDate": "1693408363898511",
    "id": "2276",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2277",
    "zoneId": "8",
    "metaId": "6661",
    "nftId": 2277,
    "creationDate": "1693408363898511",
    "id": "2277",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2278",
    "zoneId": "8",
    "metaId": "5785",
    "nftId": 2278,
    "creationDate": "1693408363898511",
    "id": "2278",
    "mintedVia": "CROWN",
  },
  {
    "x": "-87",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2279",
    "zoneId": "8",
    "metaId": "6384",
    "nftId": 2279,
    "creationDate": "1693408363898511",
    "id": "2279",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "-16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2280",
    "zoneId": "8",
    "metaId": "6451",
    "nftId": 2280,
    "creationDate": "1693408363898511",
    "id": "2280",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2281",
    "zoneId": "8",
    "metaId": "5781",
    "nftId": 2281,
    "creationDate": "1693408363898511",
    "id": "2281",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2282",
    "zoneId": "8",
    "metaId": "5191",
    "nftId": 2282,
    "creationDate": "1693408363898511",
    "id": "2282",
    "mintedVia": "CROWN",
  },
  {
    "x": "-75",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2283",
    "zoneId": "8",
    "metaId": "6273",
    "nftId": 2283,
    "creationDate": "1693408363898511",
    "id": "2283",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2284",
    "zoneId": "8",
    "metaId": "5335",
    "nftId": 2284,
    "creationDate": "1693408363898511",
    "id": "2284",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2285",
    "zoneId": "8",
    "metaId": "6746",
    "nftId": 2285,
    "creationDate": "1693408363898511",
    "id": "2285",
    "mintedVia": "CROWN",
  },
  {
    "x": "41",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2286",
    "zoneId": "8",
    "metaId": "4815",
    "nftId": 2286,
    "creationDate": "1693408363898511",
    "id": "2286",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2287",
    "zoneId": "8",
    "metaId": "6488",
    "nftId": 2287,
    "creationDate": "1693408363898511",
    "id": "2287",
    "mintedVia": "CROWN",
  },
  {
    "x": "-106",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2288",
    "zoneId": "8",
    "metaId": "6183",
    "nftId": 2288,
    "creationDate": "1693408363898511",
    "id": "2288",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2289",
    "zoneId": "8",
    "metaId": "6606",
    "nftId": 2289,
    "creationDate": "1693408363898511",
    "id": "2289",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2290",
    "zoneId": "8",
    "metaId": "5270",
    "nftId": 2290,
    "creationDate": "1693408363898511",
    "id": "2290",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2291",
    "zoneId": "8",
    "metaId": "5295",
    "nftId": 2291,
    "creationDate": "1693408363898511",
    "id": "2291",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2292",
    "zoneId": "8",
    "metaId": "5301",
    "nftId": 2292,
    "creationDate": "1693408455831811",
    "id": "2292",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "-5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2293",
    "zoneId": "8",
    "metaId": "6704",
    "nftId": 2293,
    "creationDate": "1693408455831811",
    "id": "2293",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2294",
    "zoneId": "8",
    "metaId": "5691",
    "nftId": 2294,
    "creationDate": "1693408455831811",
    "id": "2294",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2295",
    "zoneId": "8",
    "metaId": "5830",
    "nftId": 2295,
    "creationDate": "1693408455831811",
    "id": "2295",
    "mintedVia": "CROWN",
  },
  {
    "x": "84",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2296",
    "zoneId": "8",
    "metaId": "5944",
    "nftId": 2296,
    "creationDate": "1693408455831811",
    "id": "2296",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2297",
    "zoneId": "8",
    "metaId": "5469",
    "nftId": 2297,
    "creationDate": "1693408455831811",
    "id": "2297",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2298",
    "zoneId": "8",
    "metaId": "5802",
    "nftId": 2298,
    "creationDate": "1693408455831811",
    "id": "2298",
    "mintedVia": "CROWN",
  },
  {
    "x": "-95",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2299",
    "zoneId": "8",
    "metaId": "6504",
    "nftId": 2299,
    "creationDate": "1693408455831811",
    "id": "2299",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "-5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2300",
    "zoneId": "8",
    "metaId": "6808",
    "nftId": 2300,
    "creationDate": "1693408455831811",
    "id": "2300",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2301",
    "zoneId": "8",
    "metaId": "5731",
    "nftId": 2301,
    "creationDate": "1693408455831811",
    "id": "2301",
    "mintedVia": "CROWN",
  },
  {
    "x": "-86",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2302",
    "zoneId": "8",
    "metaId": "6378",
    "nftId": 2302,
    "creationDate": "1693408455831811",
    "id": "2302",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2303",
    "zoneId": "8",
    "metaId": "5160",
    "nftId": 2303,
    "creationDate": "1693408455831811",
    "id": "2303",
    "mintedVia": "CROWN",
  },
  {
    "x": "24",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2304",
    "zoneId": "8",
    "metaId": "4829",
    "nftId": 2304,
    "creationDate": "1693408455831811",
    "id": "2304",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2305",
    "zoneId": "8",
    "metaId": "6634",
    "nftId": 2305,
    "creationDate": "1693408455831811",
    "id": "2305",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2306",
    "zoneId": "8",
    "metaId": "4746",
    "nftId": 2306,
    "creationDate": "1693408455831811",
    "id": "2306",
    "mintedVia": "CROWN",
  },
  {
    "x": "34",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2307",
    "zoneId": "8",
    "metaId": "4999",
    "nftId": 2307,
    "creationDate": "1693408455831811",
    "id": "2307",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2308",
    "zoneId": "8",
    "metaId": "5354",
    "nftId": 2308,
    "creationDate": "1693408455831811",
    "id": "2308",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2309",
    "zoneId": "8",
    "metaId": "5412",
    "nftId": 2309,
    "creationDate": "1693408455831811",
    "id": "2309",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2310",
    "zoneId": "8",
    "metaId": "5153",
    "nftId": 2310,
    "creationDate": "1693408455831811",
    "id": "2310",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "-10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2311",
    "zoneId": "8",
    "metaId": "5433",
    "nftId": 2311,
    "creationDate": "1693408455831811",
    "id": "2311",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2312",
    "zoneId": "8",
    "metaId": "5002",
    "nftId": 2312,
    "creationDate": "1693408455831811",
    "id": "2312",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2313",
    "zoneId": "8",
    "metaId": "4950",
    "nftId": 2313,
    "creationDate": "1693408455831811",
    "id": "2313",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2314",
    "zoneId": "8",
    "metaId": "5692",
    "nftId": 2314,
    "creationDate": "1693408455831811",
    "id": "2314",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2315",
    "zoneId": "8",
    "metaId": "6006",
    "nftId": 2315,
    "creationDate": "1693408455831811",
    "id": "2315",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "-5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2316",
    "zoneId": "8",
    "metaId": "5822",
    "nftId": 2316,
    "creationDate": "1693408455831811",
    "id": "2316",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2317",
    "zoneId": "8",
    "metaId": "5815",
    "nftId": 2317,
    "creationDate": "1693408543892197",
    "id": "2317",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2318",
    "zoneId": "8",
    "metaId": "6782",
    "nftId": 2318,
    "creationDate": "1693408543892197",
    "id": "2318",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2319",
    "zoneId": "8",
    "metaId": "5650",
    "nftId": 2319,
    "creationDate": "1693408543892197",
    "id": "2319",
    "mintedVia": "CROWN",
  },
  {
    "x": "-90",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2320",
    "zoneId": "8",
    "metaId": "6409",
    "nftId": 2320,
    "creationDate": "1693408543892197",
    "id": "2320",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2321",
    "zoneId": "8",
    "metaId": "6635",
    "nftId": 2321,
    "creationDate": "1693408543892197",
    "id": "2321",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2322",
    "zoneId": "8",
    "metaId": "5914",
    "nftId": 2322,
    "creationDate": "1693408543892197",
    "id": "2322",
    "mintedVia": "CROWN",
  },
  {
    "x": "85",
    "y": "19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2323",
    "zoneId": "8",
    "metaId": "5970",
    "nftId": 2323,
    "creationDate": "1693408543892197",
    "id": "2323",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2324",
    "zoneId": "8",
    "metaId": "5743",
    "nftId": 2324,
    "creationDate": "1693408543892197",
    "id": "2324",
    "mintedVia": "CROWN",
  },
  {
    "x": "41",
    "y": "22",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2325",
    "zoneId": "8",
    "metaId": "4958",
    "nftId": 2325,
    "creationDate": "1693408543892197",
    "id": "2325",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2326",
    "zoneId": "8",
    "metaId": "5222",
    "nftId": 2326,
    "creationDate": "1693408543892197",
    "id": "2326",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2327",
    "zoneId": "8",
    "metaId": "5344",
    "nftId": 2327,
    "creationDate": "1693408543892197",
    "id": "2327",
    "mintedVia": "CROWN",
  },
  {
    "x": "-13",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2328",
    "zoneId": "8",
    "metaId": "6623",
    "nftId": 2328,
    "creationDate": "1693408543892197",
    "id": "2328",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2329",
    "zoneId": "8",
    "metaId": "5829",
    "nftId": 2329,
    "creationDate": "1693408543892197",
    "id": "2329",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2330",
    "zoneId": "8",
    "metaId": "6480",
    "nftId": 2330,
    "creationDate": "1693408543892197",
    "id": "2330",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2331",
    "zoneId": "8",
    "metaId": "5841",
    "nftId": 2331,
    "creationDate": "1693408543892197",
    "id": "2331",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2332",
    "zoneId": "8",
    "metaId": "5415",
    "nftId": 2332,
    "creationDate": "1693408543892197",
    "id": "2332",
    "mintedVia": "CROWN",
  },
  {
    "x": "58",
    "y": "-10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2333",
    "zoneId": "8",
    "metaId": "6596",
    "nftId": 2333,
    "creationDate": "1693408543892197",
    "id": "2333",
    "mintedVia": "CROWN",
  },
  {
    "x": "85",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2334",
    "zoneId": "8",
    "metaId": "5976",
    "nftId": 2334,
    "creationDate": "1693408543892197",
    "id": "2334",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2335",
    "zoneId": "8",
    "metaId": "5640",
    "nftId": 2335,
    "creationDate": "1693408543892197",
    "id": "2335",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2336",
    "zoneId": "8",
    "metaId": "5423",
    "nftId": 2336,
    "creationDate": "1693408543892197",
    "id": "2336",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2337",
    "zoneId": "8",
    "metaId": "5135",
    "nftId": 2337,
    "creationDate": "1693408543892197",
    "id": "2337",
    "mintedVia": "CROWN",
  },
  {
    "x": "58",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2338",
    "zoneId": "8",
    "metaId": "5053",
    "nftId": 2338,
    "creationDate": "1693408543892197",
    "id": "2338",
    "mintedVia": "CROWN",
  },
  {
    "x": "89",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2339",
    "zoneId": "8",
    "metaId": "6062",
    "nftId": 2339,
    "creationDate": "1693408543892197",
    "id": "2339",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2340",
    "zoneId": "8",
    "metaId": "4771",
    "nftId": 2340,
    "creationDate": "1693408543892197",
    "id": "2340",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2341",
    "zoneId": "8",
    "metaId": "5812",
    "nftId": 2341,
    "creationDate": "1693408543892197",
    "id": "2341",
    "mintedVia": "CROWN",
  },
  {
    "x": "-80",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2342",
    "zoneId": "8",
    "metaId": "6318",
    "nftId": 2342,
    "creationDate": "1693408641144143",
    "id": "2342",
    "mintedVia": "CROWN",
  },
  {
    "x": "-88",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2343",
    "zoneId": "8",
    "metaId": "6387",
    "nftId": 2343,
    "creationDate": "1693408641144143",
    "id": "2343",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "-14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2344",
    "zoneId": "8",
    "metaId": "5236",
    "nftId": 2344,
    "creationDate": "1693408641144143",
    "id": "2344",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2345",
    "zoneId": "8",
    "metaId": "5082",
    "nftId": 2345,
    "creationDate": "1693408641144143",
    "id": "2345",
    "mintedVia": "CROWN",
  },
  {
    "x": "30",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2346",
    "zoneId": "8",
    "metaId": "4840",
    "nftId": 2346,
    "creationDate": "1693408641144143",
    "id": "2346",
    "mintedVia": "CROWN",
  },
  {
    "x": "-103",
    "y": "-7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2347",
    "zoneId": "8",
    "metaId": "6142",
    "nftId": 2347,
    "creationDate": "1693408641144143",
    "id": "2347",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2348",
    "zoneId": "8",
    "metaId": "5176",
    "nftId": 2348,
    "creationDate": "1693408641144143",
    "id": "2348",
    "mintedVia": "CROWN",
  },
  {
    "x": "-96",
    "y": "-22",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2349",
    "zoneId": "8",
    "metaId": "6515",
    "nftId": 2349,
    "creationDate": "1693408641144143",
    "id": "2349",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2350",
    "zoneId": "8",
    "metaId": "5066",
    "nftId": 2350,
    "creationDate": "1693408641144143",
    "id": "2350",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "-11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2351",
    "zoneId": "8",
    "metaId": "5074",
    "nftId": 2351,
    "creationDate": "1693408641144143",
    "id": "2351",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2352",
    "zoneId": "8",
    "metaId": "5916",
    "nftId": 2352,
    "creationDate": "1693408641144143",
    "id": "2352",
    "mintedVia": "CROWN",
  },
  {
    "x": "30",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2353",
    "zoneId": "8",
    "metaId": "4714",
    "nftId": 2353,
    "creationDate": "1693408641144143",
    "id": "2353",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2354",
    "zoneId": "8",
    "metaId": "5566",
    "nftId": 2354,
    "creationDate": "1693408641144143",
    "id": "2354",
    "mintedVia": "CROWN",
  },
  {
    "x": "36",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2355",
    "zoneId": "8",
    "metaId": "4982",
    "nftId": 2355,
    "creationDate": "1693408641144143",
    "id": "2355",
    "mintedVia": "CROWN",
  },
  {
    "x": "85",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2356",
    "zoneId": "8",
    "metaId": "5973",
    "nftId": 2356,
    "creationDate": "1693408641144143",
    "id": "2356",
    "mintedVia": "CROWN",
  },
  {
    "x": "58",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2357",
    "zoneId": "8",
    "metaId": "5054",
    "nftId": 2357,
    "creationDate": "1693408641144143",
    "id": "2357",
    "mintedVia": "CROWN",
  },
  {
    "x": "84",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2358",
    "zoneId": "8",
    "metaId": "5948",
    "nftId": 2358,
    "creationDate": "1693408641144143",
    "id": "2358",
    "mintedVia": "CROWN",
  },
  {
    "x": "-96",
    "y": "-5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2359",
    "zoneId": "8",
    "metaId": "6517",
    "nftId": 2359,
    "creationDate": "1693408641144143",
    "id": "2359",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "-14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2360",
    "zoneId": "8",
    "metaId": "6864",
    "nftId": 2360,
    "creationDate": "1693408641144143",
    "id": "2360",
    "mintedVia": "CROWN",
  },
  {
    "x": "-46",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2361",
    "zoneId": "8",
    "metaId": "6697",
    "nftId": 2361,
    "creationDate": "1693408641144143",
    "id": "2361",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2362",
    "zoneId": "8",
    "metaId": "5575",
    "nftId": 2362,
    "creationDate": "1693408641144143",
    "id": "2362",
    "mintedVia": "CROWN",
  },
  {
    "x": "90",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2363",
    "zoneId": "8",
    "metaId": "6073",
    "nftId": 2363,
    "creationDate": "1693408641144143",
    "id": "2363",
    "mintedVia": "CROWN",
  },
  {
    "x": "-104",
    "y": "-7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2364",
    "zoneId": "8",
    "metaId": "6158",
    "nftId": 2364,
    "creationDate": "1693408641144143",
    "id": "2364",
    "mintedVia": "CROWN",
  },
  {
    "x": "-96",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2365",
    "zoneId": "8",
    "metaId": "6520",
    "nftId": 2365,
    "creationDate": "1693408641144143",
    "id": "2365",
    "mintedVia": "CROWN",
  },
  {
    "x": "10",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2366",
    "zoneId": "8",
    "metaId": "4732",
    "nftId": 2366,
    "creationDate": "1693408641144143",
    "id": "2366",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2367",
    "zoneId": "8",
    "metaId": "6750",
    "nftId": 2367,
    "creationDate": "1693408727211488",
    "id": "2367",
    "mintedVia": "CROWN",
  },
  {
    "x": "-100",
    "y": "-16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2368",
    "zoneId": "8",
    "metaId": "6086",
    "nftId": 2368,
    "creationDate": "1693408727211488",
    "id": "2368",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "-18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2369",
    "zoneId": "8",
    "metaId": "6923",
    "nftId": 2369,
    "creationDate": "1693408727211488",
    "id": "2369",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2370",
    "zoneId": "8",
    "metaId": "5744",
    "nftId": 2370,
    "creationDate": "1693408727211488",
    "id": "2370",
    "mintedVia": "CROWN",
  },
  {
    "x": "-101",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2371",
    "zoneId": "8",
    "metaId": "6107",
    "nftId": 2371,
    "creationDate": "1693408727211488",
    "id": "2371",
    "mintedVia": "CROWN",
  },
  {
    "x": "-87",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2372",
    "zoneId": "8",
    "metaId": "6385",
    "nftId": 2372,
    "creationDate": "1693408727211488",
    "id": "2372",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2373",
    "zoneId": "8",
    "metaId": "6007",
    "nftId": 2373,
    "creationDate": "1693408727211488",
    "id": "2373",
    "mintedVia": "CROWN",
  },
  {
    "x": "-48",
    "y": "-17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2374",
    "zoneId": "8",
    "metaId": "6904",
    "nftId": 2374,
    "creationDate": "1693408727211488",
    "id": "2374",
    "mintedVia": "CROWN",
  },
  {
    "x": "-91",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2375",
    "zoneId": "8",
    "metaId": "6433",
    "nftId": 2375,
    "creationDate": "1693408727211488",
    "id": "2375",
    "mintedVia": "CROWN",
  },
  {
    "x": "85",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2376",
    "zoneId": "8",
    "metaId": "5968",
    "nftId": 2376,
    "creationDate": "1693408727211488",
    "id": "2376",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2377",
    "zoneId": "8",
    "metaId": "5881",
    "nftId": 2377,
    "creationDate": "1693408727211488",
    "id": "2377",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2378",
    "zoneId": "8",
    "metaId": "4799",
    "nftId": 2378,
    "creationDate": "1693408727211488",
    "id": "2378",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2379",
    "zoneId": "8",
    "metaId": "5446",
    "nftId": 2379,
    "creationDate": "1693408727211488",
    "id": "2379",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2380",
    "zoneId": "8",
    "metaId": "5205",
    "nftId": 2380,
    "creationDate": "1693408727211488",
    "id": "2380",
    "mintedVia": "CROWN",
  },
  {
    "x": "-106",
    "y": "-10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2381",
    "zoneId": "8",
    "metaId": "6175",
    "nftId": 2381,
    "creationDate": "1693408727211488",
    "id": "2381",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2382",
    "zoneId": "8",
    "metaId": "5532",
    "nftId": 2382,
    "creationDate": "1693408727211488",
    "id": "2382",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2383",
    "zoneId": "8",
    "metaId": "5050",
    "nftId": 2383,
    "creationDate": "1693408727211488",
    "id": "2383",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2384",
    "zoneId": "8",
    "metaId": "5902",
    "nftId": 2384,
    "creationDate": "1693408727211488",
    "id": "2384",
    "mintedVia": "CROWN",
  },
  {
    "x": "-103",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2385",
    "zoneId": "8",
    "metaId": "6146",
    "nftId": 2385,
    "creationDate": "1693408727211488",
    "id": "2385",
    "mintedVia": "CROWN",
  },
  {
    "x": "23",
    "y": "27",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2386",
    "zoneId": "8",
    "metaId": "4685",
    "nftId": 2386,
    "creationDate": "1693408727211488",
    "id": "2386",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2387",
    "zoneId": "8",
    "metaId": "6618",
    "nftId": 2387,
    "creationDate": "1693408727211488",
    "id": "2387",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2388",
    "zoneId": "8",
    "metaId": "5855",
    "nftId": 2388,
    "creationDate": "1693408727211488",
    "id": "2388",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2389",
    "zoneId": "8",
    "metaId": "6689",
    "nftId": 2389,
    "creationDate": "1693408727211488",
    "id": "2389",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2390",
    "zoneId": "8",
    "metaId": "5541",
    "nftId": 2390,
    "creationDate": "1693408727211488",
    "id": "2390",
    "mintedVia": "CROWN",
  },
  {
    "x": "-91",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2391",
    "zoneId": "8",
    "metaId": "6429",
    "nftId": 2391,
    "creationDate": "1693408727211488",
    "id": "2391",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2392",
    "zoneId": "8",
    "metaId": "5139",
    "nftId": 2392,
    "creationDate": "1693408809196020",
    "id": "2392",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2393",
    "zoneId": "8",
    "metaId": "6709",
    "nftId": 2393,
    "creationDate": "1693408809196020",
    "id": "2393",
    "mintedVia": "CROWN",
  },
  {
    "x": "32",
    "y": "27",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2394",
    "zoneId": "8",
    "metaId": "4696",
    "nftId": 2394,
    "creationDate": "1693408809196020",
    "id": "2394",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "-4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2395",
    "zoneId": "8",
    "metaId": "5638",
    "nftId": 2395,
    "creationDate": "1693408809196020",
    "id": "2395",
    "mintedVia": "CROWN",
  },
  {
    "x": "-103",
    "y": "-4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2396",
    "zoneId": "8",
    "metaId": "6139",
    "nftId": 2396,
    "creationDate": "1693408809196020",
    "id": "2396",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "-17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2397",
    "zoneId": "8",
    "metaId": "5554",
    "nftId": 2397,
    "creationDate": "1693408809196020",
    "id": "2397",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "-5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2398",
    "zoneId": "8",
    "metaId": "5713",
    "nftId": 2398,
    "creationDate": "1693408809196020",
    "id": "2398",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2399",
    "zoneId": "8",
    "metaId": "4704",
    "nftId": 2399,
    "creationDate": "1693408809196020",
    "id": "2399",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2400",
    "zoneId": "8",
    "metaId": "6665",
    "nftId": 2400,
    "creationDate": "1693408809196020",
    "id": "2400",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2401",
    "zoneId": "8",
    "metaId": "4816",
    "nftId": 2401,
    "creationDate": "1693408809196020",
    "id": "2401",
    "mintedVia": "CROWN",
  },
  {
    "x": "-85",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2402",
    "zoneId": "8",
    "metaId": "6369",
    "nftId": 2402,
    "creationDate": "1693408809196020",
    "id": "2402",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "-7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2403",
    "zoneId": "8",
    "metaId": "6597",
    "nftId": 2403,
    "creationDate": "1693408809196020",
    "id": "2403",
    "mintedVia": "CROWN",
  },
  {
    "x": "88",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2404",
    "zoneId": "8",
    "metaId": "6054",
    "nftId": 2404,
    "creationDate": "1693408809196020",
    "id": "2404",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2405",
    "zoneId": "8",
    "metaId": "5870",
    "nftId": 2405,
    "creationDate": "1693408809196020",
    "id": "2405",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2406",
    "zoneId": "8",
    "metaId": "6013",
    "nftId": 2406,
    "creationDate": "1693408809196020",
    "id": "2406",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2407",
    "zoneId": "8",
    "metaId": "5269",
    "nftId": 2407,
    "creationDate": "1693408809196020",
    "id": "2407",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2408",
    "zoneId": "8",
    "metaId": "4741",
    "nftId": 2408,
    "creationDate": "1693408809196020",
    "id": "2408",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2409",
    "zoneId": "8",
    "metaId": "4698",
    "nftId": 2409,
    "creationDate": "1693408809196020",
    "id": "2409",
    "mintedVia": "CROWN",
  },
  {
    "x": "-72",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2410",
    "zoneId": "8",
    "metaId": "6241",
    "nftId": 2410,
    "creationDate": "1693408809196020",
    "id": "2410",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2411",
    "zoneId": "8",
    "metaId": "4952",
    "nftId": 2411,
    "creationDate": "1693408809196020",
    "id": "2411",
    "mintedVia": "CROWN",
  },
  {
    "x": "32",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2412",
    "zoneId": "8",
    "metaId": "4845",
    "nftId": 2412,
    "creationDate": "1693408809196020",
    "id": "2412",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2413",
    "zoneId": "8",
    "metaId": "4996",
    "nftId": 2413,
    "creationDate": "1693408809196020",
    "id": "2413",
    "mintedVia": "CROWN",
  },
  {
    "x": "88",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2414",
    "zoneId": "8",
    "metaId": "6050",
    "nftId": 2414,
    "creationDate": "1693408809196020",
    "id": "2414",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2415",
    "zoneId": "8",
    "metaId": "5868",
    "nftId": 2415,
    "creationDate": "1693408809196020",
    "id": "2415",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "-11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2416",
    "zoneId": "8",
    "metaId": "5549",
    "nftId": 2416,
    "creationDate": "1693408809196020",
    "id": "2416",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2417",
    "zoneId": "8",
    "metaId": "5880",
    "nftId": 2417,
    "creationDate": "1693409015306058",
    "id": "2417",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2418",
    "zoneId": "8",
    "metaId": "4945",
    "nftId": 2418,
    "creationDate": "1693409015306058",
    "id": "2418",
    "mintedVia": "CROWN",
  },
  {
    "x": "11",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2419",
    "zoneId": "8",
    "metaId": "4777",
    "nftId": 2419,
    "creationDate": "1693409015306058",
    "id": "2419",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2420",
    "zoneId": "8",
    "metaId": "5498",
    "nftId": 2420,
    "creationDate": "1693409015306058",
    "id": "2420",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2421",
    "zoneId": "8",
    "metaId": "5143",
    "nftId": 2421,
    "creationDate": "1693409015306058",
    "id": "2421",
    "mintedVia": "CROWN",
  },
  {
    "x": "-82",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2422",
    "zoneId": "8",
    "metaId": "6343",
    "nftId": 2422,
    "creationDate": "1693409015306058",
    "id": "2422",
    "mintedVia": "CROWN",
  },
  {
    "x": "-102",
    "y": "-2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2423",
    "zoneId": "8",
    "metaId": "6118",
    "nftId": 2423,
    "creationDate": "1693409015306058",
    "id": "2423",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2424",
    "zoneId": "8",
    "metaId": "5254",
    "nftId": 2424,
    "creationDate": "1693409015306058",
    "id": "2424",
    "mintedVia": "CROWN",
  },
  {
    "x": "40",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2425",
    "zoneId": "8",
    "metaId": "4972",
    "nftId": 2425,
    "creationDate": "1693409015306058",
    "id": "2425",
    "mintedVia": "CROWN",
  },
  {
    "x": "46",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2426",
    "zoneId": "8",
    "metaId": "4990",
    "nftId": 2426,
    "creationDate": "1693409015306058",
    "id": "2426",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2427",
    "zoneId": "8",
    "metaId": "5312",
    "nftId": 2427,
    "creationDate": "1693409015306058",
    "id": "2427",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2428",
    "zoneId": "8",
    "metaId": "5207",
    "nftId": 2428,
    "creationDate": "1693409015306058",
    "id": "2428",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2429",
    "zoneId": "8",
    "metaId": "6541",
    "nftId": 2429,
    "creationDate": "1693409015306058",
    "id": "2429",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2430",
    "zoneId": "8",
    "metaId": "5394",
    "nftId": 2430,
    "creationDate": "1693409015306058",
    "id": "2430",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "-5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2431",
    "zoneId": "8",
    "metaId": "6019",
    "nftId": 2431,
    "creationDate": "1693409015306058",
    "id": "2431",
    "mintedVia": "CROWN",
  },
  {
    "x": "74",
    "y": "30",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2432",
    "zoneId": "8",
    "metaId": "5582",
    "nftId": 2432,
    "creationDate": "1693409015306058",
    "id": "2432",
    "mintedVia": "CROWN",
  },
  {
    "x": "-97",
    "y": "-21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2433",
    "zoneId": "8",
    "metaId": "6536",
    "nftId": 2433,
    "creationDate": "1693409015306058",
    "id": "2433",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "-11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2434",
    "zoneId": "8",
    "metaId": "5705",
    "nftId": 2434,
    "creationDate": "1693409015306058",
    "id": "2434",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2435",
    "zoneId": "8",
    "metaId": "6691",
    "nftId": 2435,
    "creationDate": "1693409015306058",
    "id": "2435",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2436",
    "zoneId": "8",
    "metaId": "5488",
    "nftId": 2436,
    "creationDate": "1693409015306058",
    "id": "2436",
    "mintedVia": "CROWN",
  },
  {
    "x": "-86",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2437",
    "zoneId": "8",
    "metaId": "6375",
    "nftId": 2437,
    "creationDate": "1693409015306058",
    "id": "2437",
    "mintedVia": "CROWN",
  },
  {
    "x": "-85",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2438",
    "zoneId": "8",
    "metaId": "6371",
    "nftId": 2438,
    "creationDate": "1693409015306058",
    "id": "2438",
    "mintedVia": "CROWN",
  },
  {
    "x": "-106",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2439",
    "zoneId": "8",
    "metaId": "6180",
    "nftId": 2439,
    "creationDate": "1693409015306058",
    "id": "2439",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2440",
    "zoneId": "8",
    "metaId": "5794",
    "nftId": 2440,
    "creationDate": "1693409015306058",
    "id": "2440",
    "mintedVia": "CROWN",
  },
  {
    "x": "-76",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2441",
    "zoneId": "8",
    "metaId": "6281",
    "nftId": 2441,
    "creationDate": "1693409015306058",
    "id": "2441",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2442",
    "zoneId": "8",
    "metaId": "6873",
    "nftId": 2442,
    "creationDate": "1693409103256409",
    "id": "2442",
    "mintedVia": "CROWN",
  },
  {
    "x": "-80",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2443",
    "zoneId": "8",
    "metaId": "6322",
    "nftId": 2443,
    "creationDate": "1693409103256409",
    "id": "2443",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "-8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2444",
    "zoneId": "8",
    "metaId": "5407",
    "nftId": 2444,
    "creationDate": "1693409103256409",
    "id": "2444",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2445",
    "zoneId": "8",
    "metaId": "4788",
    "nftId": 2445,
    "creationDate": "1693409103256409",
    "id": "2445",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "22",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2446",
    "zoneId": "8",
    "metaId": "5261",
    "nftId": 2446,
    "creationDate": "1693409103256409",
    "id": "2446",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "-10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2447",
    "zoneId": "8",
    "metaId": "5235",
    "nftId": 2447,
    "creationDate": "1693409103256409",
    "id": "2447",
    "mintedVia": "CROWN",
  },
  {
    "x": "-102",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2448",
    "zoneId": "8",
    "metaId": "6114",
    "nftId": 2448,
    "creationDate": "1693409103256409",
    "id": "2448",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "27",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2449",
    "zoneId": "8",
    "metaId": "5538",
    "nftId": 2449,
    "creationDate": "1693409103256409",
    "id": "2449",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2450",
    "zoneId": "8",
    "metaId": "4893",
    "nftId": 2450,
    "creationDate": "1693409103256409",
    "id": "2450",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2451",
    "zoneId": "8",
    "metaId": "4727",
    "nftId": 2451,
    "creationDate": "1693409103256409",
    "id": "2451",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2452",
    "zoneId": "8",
    "metaId": "5081",
    "nftId": 2452,
    "creationDate": "1693409103256409",
    "id": "2452",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "-15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2453",
    "zoneId": "8",
    "metaId": "6834",
    "nftId": 2453,
    "creationDate": "1693409103256409",
    "id": "2453",
    "mintedVia": "CROWN",
  },
  {
    "x": "-84",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2454",
    "zoneId": "8",
    "metaId": "6357",
    "nftId": 2454,
    "creationDate": "1693409103256409",
    "id": "2454",
    "mintedVia": "CROWN",
  },
  {
    "x": "-103",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2455",
    "zoneId": "8",
    "metaId": "6141",
    "nftId": 2455,
    "creationDate": "1693409103256409",
    "id": "2455",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2456",
    "zoneId": "8",
    "metaId": "4842",
    "nftId": 2456,
    "creationDate": "1693409103256409",
    "id": "2456",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2457",
    "zoneId": "8",
    "metaId": "6767",
    "nftId": 2457,
    "creationDate": "1693409103256409",
    "id": "2457",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2458",
    "zoneId": "8",
    "metaId": "6810",
    "nftId": 2458,
    "creationDate": "1693409103256409",
    "id": "2458",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "-8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2459",
    "zoneId": "8",
    "metaId": "5482",
    "nftId": 2459,
    "creationDate": "1693409103256409",
    "id": "2459",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2460",
    "zoneId": "8",
    "metaId": "5991",
    "nftId": 2460,
    "creationDate": "1693409103256409",
    "id": "2460",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2461",
    "zoneId": "8",
    "metaId": "6712",
    "nftId": 2461,
    "creationDate": "1693409103256409",
    "id": "2461",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "-5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2462",
    "zoneId": "8",
    "metaId": "6730",
    "nftId": 2462,
    "creationDate": "1693409103256409",
    "id": "2462",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2463",
    "zoneId": "8",
    "metaId": "6043",
    "nftId": 2463,
    "creationDate": "1693409103256409",
    "id": "2463",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "-10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2464",
    "zoneId": "8",
    "metaId": "5356",
    "nftId": 2464,
    "creationDate": "1693409103256409",
    "id": "2464",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2465",
    "zoneId": "8",
    "metaId": "6814",
    "nftId": 2465,
    "creationDate": "1693409103256409",
    "id": "2465",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2466",
    "zoneId": "8",
    "metaId": "6601",
    "nftId": 2466,
    "creationDate": "1693409103256409",
    "id": "2466",
    "mintedVia": "CROWN",
  },
  {
    "x": "-105",
    "y": "-10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2467",
    "zoneId": "8",
    "metaId": "6164",
    "nftId": 2467,
    "creationDate": "1693409205317989",
    "id": "2467",
    "mintedVia": "CROWN",
  },
  {
    "x": "-81",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2468",
    "zoneId": "8",
    "metaId": "6329",
    "nftId": 2468,
    "creationDate": "1693409205317989",
    "id": "2468",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2469",
    "zoneId": "8",
    "metaId": "4977",
    "nftId": 2469,
    "creationDate": "1693409205317989",
    "id": "2469",
    "mintedVia": "CROWN",
  },
  {
    "x": "78",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2470",
    "zoneId": "8",
    "metaId": "5725",
    "nftId": 2470,
    "creationDate": "1693409205317989",
    "id": "2470",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2471",
    "zoneId": "8",
    "metaId": "4989",
    "nftId": 2471,
    "creationDate": "1693409205317989",
    "id": "2471",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2472",
    "zoneId": "8",
    "metaId": "6036",
    "nftId": 2472,
    "creationDate": "1693409205317989",
    "id": "2472",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2473",
    "zoneId": "8",
    "metaId": "5367",
    "nftId": 2473,
    "creationDate": "1693409205317989",
    "id": "2473",
    "mintedVia": "CROWN",
  },
  {
    "x": "-103",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2474",
    "zoneId": "8",
    "metaId": "6149",
    "nftId": 2474,
    "creationDate": "1693409205317989",
    "id": "2474",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "-15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2475",
    "zoneId": "8",
    "metaId": "5512",
    "nftId": 2475,
    "creationDate": "1693409205317989",
    "id": "2475",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "-7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2476",
    "zoneId": "8",
    "metaId": "6936",
    "nftId": 2476,
    "creationDate": "1693409205317989",
    "id": "2476",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "-10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2477",
    "zoneId": "8",
    "metaId": "6932",
    "nftId": 2477,
    "creationDate": "1693409205317989",
    "id": "2477",
    "mintedVia": "CROWN",
  },
  {
    "x": "46",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2478",
    "zoneId": "8",
    "metaId": "5016",
    "nftId": 2478,
    "creationDate": "1693409205317989",
    "id": "2478",
    "mintedVia": "CROWN",
  },
  {
    "x": "-80",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2479",
    "zoneId": "8",
    "metaId": "6313",
    "nftId": 2479,
    "creationDate": "1693409205317989",
    "id": "2479",
    "mintedVia": "CROWN",
  },
  {
    "x": "-110",
    "y": "-1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2480",
    "zoneId": "8",
    "metaId": "6208",
    "nftId": 2480,
    "creationDate": "1693409205317989",
    "id": "2480",
    "mintedVia": "CROWN",
  },
  {
    "x": "38",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2481",
    "zoneId": "8",
    "metaId": "4809",
    "nftId": 2481,
    "creationDate": "1693409205317989",
    "id": "2481",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2482",
    "zoneId": "8",
    "metaId": "6761",
    "nftId": 2482,
    "creationDate": "1693409205317989",
    "id": "2482",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2483",
    "zoneId": "8",
    "metaId": "4963",
    "nftId": 2483,
    "creationDate": "1693409205317989",
    "id": "2483",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "30",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2484",
    "zoneId": "8",
    "metaId": "5623",
    "nftId": 2484,
    "creationDate": "1693409205317989",
    "id": "2484",
    "mintedVia": "CROWN",
  },
  {
    "x": "-103",
    "y": "-2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2485",
    "zoneId": "8",
    "metaId": "6137",
    "nftId": 2485,
    "creationDate": "1693409205317989",
    "id": "2485",
    "mintedVia": "CROWN",
  },
  {
    "x": "58",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2486",
    "zoneId": "8",
    "metaId": "5048",
    "nftId": 2486,
    "creationDate": "1693409205317989",
    "id": "2486",
    "mintedVia": "CROWN",
  },
  {
    "x": "15",
    "y": "23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2487",
    "zoneId": "8",
    "metaId": "4861",
    "nftId": 2487,
    "creationDate": "1693409205317989",
    "id": "2487",
    "mintedVia": "CROWN",
  },
  {
    "x": "-82",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2488",
    "zoneId": "8",
    "metaId": "6339",
    "nftId": 2488,
    "creationDate": "1693409205317989",
    "id": "2488",
    "mintedVia": "CROWN",
  },
  {
    "x": "-72",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2489",
    "zoneId": "8",
    "metaId": "6242",
    "nftId": 2489,
    "creationDate": "1693409205317989",
    "id": "2489",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "-12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2490",
    "zoneId": "8",
    "metaId": "6848",
    "nftId": 2490,
    "creationDate": "1693409205317989",
    "id": "2490",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2491",
    "zoneId": "8",
    "metaId": "5168",
    "nftId": 2491,
    "creationDate": "1693409205317989",
    "id": "2491",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2492",
    "zoneId": "8",
    "metaId": "4883",
    "nftId": 2492,
    "creationDate": "1693409287343180",
    "id": "2492",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2493",
    "zoneId": "8",
    "metaId": "5909",
    "nftId": 2493,
    "creationDate": "1693409287343180",
    "id": "2493",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "-6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2494",
    "zoneId": "8",
    "metaId": "5323",
    "nftId": 2494,
    "creationDate": "1693409287343180",
    "id": "2494",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "-5",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2495",
    "zoneId": "8",
    "metaId": "6745",
    "nftId": 2495,
    "creationDate": "1693409287343180",
    "id": "2495",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "22",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2496",
    "zoneId": "8",
    "metaId": "5044",
    "nftId": 2496,
    "creationDate": "1693409287343180",
    "id": "2496",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "-18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2497",
    "zoneId": "8",
    "metaId": "6897",
    "nftId": 2497,
    "creationDate": "1693409287343180",
    "id": "2497",
    "mintedVia": "CROWN",
  },
  {
    "x": "17",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2498",
    "zoneId": "8",
    "metaId": "4863",
    "nftId": 2498,
    "creationDate": "1693409287343180",
    "id": "2498",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "-4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2499",
    "zoneId": "8",
    "metaId": "5821",
    "nftId": 2499,
    "creationDate": "1693409287343180",
    "id": "2499",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2500",
    "zoneId": "8",
    "metaId": "5170",
    "nftId": 2500,
    "creationDate": "1693409287343180",
    "id": "2500",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "2",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2501",
    "zoneId": "8",
    "metaId": "5907",
    "nftId": 2501,
    "creationDate": "1693409287343180",
    "id": "2501",
    "mintedVia": "CROWN",
  },
  {
    "x": "14",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2502",
    "zoneId": "8",
    "metaId": "4673",
    "nftId": 2502,
    "creationDate": "1693409287343180",
    "id": "2502",
    "mintedVia": "CROWN",
  },
  {
    "x": "39",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2503",
    "zoneId": "8",
    "metaId": "4984",
    "nftId": 2503,
    "creationDate": "1693409287343180",
    "id": "2503",
    "mintedVia": "CROWN",
  },
  {
    "x": "38",
    "y": "25",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2504",
    "zoneId": "8",
    "metaId": "4986",
    "nftId": 2504,
    "creationDate": "1693409287343180",
    "id": "2504",
    "mintedVia": "CROWN",
  },
  {
    "x": "-82",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2505",
    "zoneId": "8",
    "metaId": "6342",
    "nftId": 2505,
    "creationDate": "1693409287343180",
    "id": "2505",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2506",
    "zoneId": "8",
    "metaId": "6813",
    "nftId": 2506,
    "creationDate": "1693409287343180",
    "id": "2506",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2507",
    "zoneId": "8",
    "metaId": "4712",
    "nftId": 2507,
    "creationDate": "1693409287343180",
    "id": "2507",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2508",
    "zoneId": "8",
    "metaId": "5867",
    "nftId": 2508,
    "creationDate": "1693409287343180",
    "id": "2508",
    "mintedVia": "CROWN",
  },
  {
    "x": "-71",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2509",
    "zoneId": "8",
    "metaId": "6229",
    "nftId": 2509,
    "creationDate": "1693409287343180",
    "id": "2509",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2510",
    "zoneId": "8",
    "metaId": "6467",
    "nftId": 2510,
    "creationDate": "1693409287343180",
    "id": "2510",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2511",
    "zoneId": "8",
    "metaId": "5919",
    "nftId": 2511,
    "creationDate": "1693409287343180",
    "id": "2511",
    "mintedVia": "CROWN",
  },
  {
    "x": "70",
    "y": "-14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2512",
    "zoneId": "8",
    "metaId": "5396",
    "nftId": 2512,
    "creationDate": "1693409287343180",
    "id": "2512",
    "mintedVia": "CROWN",
  },
  {
    "x": "79",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2513",
    "zoneId": "8",
    "metaId": "5748",
    "nftId": 2513,
    "creationDate": "1693409287343180",
    "id": "2513",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2514",
    "zoneId": "8",
    "metaId": "6650",
    "nftId": 2514,
    "creationDate": "1693409287343180",
    "id": "2514",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2515",
    "zoneId": "8",
    "metaId": "5049",
    "nftId": 2515,
    "creationDate": "1693409287343180",
    "id": "2515",
    "mintedVia": "CROWN",
  },
  {
    "x": "-10",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2516",
    "zoneId": "8",
    "metaId": "6599",
    "nftId": 2516,
    "creationDate": "1693409287343180",
    "id": "2516",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2517",
    "zoneId": "8",
    "metaId": "4987",
    "nftId": 2517,
    "creationDate": "1693409377475704",
    "id": "2517",
    "mintedVia": "CROWN",
  },
  {
    "x": "-79",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2518",
    "zoneId": "8",
    "metaId": "6310",
    "nftId": 2518,
    "creationDate": "1693409377475704",
    "id": "2518",
    "mintedVia": "CROWN",
  },
  {
    "x": "85",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2519",
    "zoneId": "8",
    "metaId": "5963",
    "nftId": 2519,
    "creationDate": "1693409377475704",
    "id": "2519",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2520",
    "zoneId": "8",
    "metaId": "5477",
    "nftId": 2520,
    "creationDate": "1693409377475704",
    "id": "2520",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "-8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2521",
    "zoneId": "8",
    "metaId": "5602",
    "nftId": 2521,
    "creationDate": "1693409377475704",
    "id": "2521",
    "mintedVia": "CROWN",
  },
  {
    "x": "47",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2522",
    "zoneId": "8",
    "metaId": "5018",
    "nftId": 2522,
    "creationDate": "1693409377475704",
    "id": "2522",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "30",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2523",
    "zoneId": "8",
    "metaId": "5849",
    "nftId": 2523,
    "creationDate": "1693409377475704",
    "id": "2523",
    "mintedVia": "CROWN",
  },
  {
    "x": "24",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2524",
    "zoneId": "8",
    "metaId": "4915",
    "nftId": 2524,
    "creationDate": "1693409377475704",
    "id": "2524",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2525",
    "zoneId": "8",
    "metaId": "5848",
    "nftId": 2525,
    "creationDate": "1693409377475704",
    "id": "2525",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2526",
    "zoneId": "8",
    "metaId": "5372",
    "nftId": 2526,
    "creationDate": "1693409377475704",
    "id": "2526",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "-4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2527",
    "zoneId": "8",
    "metaId": "5598",
    "nftId": 2527,
    "creationDate": "1693409377475704",
    "id": "2527",
    "mintedVia": "CROWN",
  },
  {
    "x": "-74",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2528",
    "zoneId": "8",
    "metaId": "6258",
    "nftId": 2528,
    "creationDate": "1693409377475704",
    "id": "2528",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2529",
    "zoneId": "8",
    "metaId": "4681",
    "nftId": 2529,
    "creationDate": "1693409377475704",
    "id": "2529",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2530",
    "zoneId": "8",
    "metaId": "6710",
    "nftId": 2530,
    "creationDate": "1693409377475704",
    "id": "2530",
    "mintedVia": "CROWN",
  },
  {
    "x": "-77",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2531",
    "zoneId": "8",
    "metaId": "6287",
    "nftId": 2531,
    "creationDate": "1693409377475704",
    "id": "2531",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2532",
    "zoneId": "8",
    "metaId": "6785",
    "nftId": 2532,
    "creationDate": "1693409377475704",
    "id": "2532",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2533",
    "zoneId": "8",
    "metaId": "5166",
    "nftId": 2533,
    "creationDate": "1693409377475704",
    "id": "2533",
    "mintedVia": "CROWN",
  },
  {
    "x": "-13",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2534",
    "zoneId": "8",
    "metaId": "6621",
    "nftId": 2534,
    "creationDate": "1693409377475704",
    "id": "2534",
    "mintedVia": "CROWN",
  },
  {
    "x": "88",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2535",
    "zoneId": "8",
    "metaId": "6052",
    "nftId": 2535,
    "creationDate": "1693409377475704",
    "id": "2535",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2536",
    "zoneId": "8",
    "metaId": "5790",
    "nftId": 2536,
    "creationDate": "1693409377475704",
    "id": "2536",
    "mintedVia": "CROWN",
  },
  {
    "x": "-100",
    "y": "-10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2537",
    "zoneId": "8",
    "metaId": "6083",
    "nftId": 2537,
    "creationDate": "1693409377475704",
    "id": "2537",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2538",
    "zoneId": "8",
    "metaId": "5371",
    "nftId": 2538,
    "creationDate": "1693409377475704",
    "id": "2538",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2539",
    "zoneId": "8",
    "metaId": "4932",
    "nftId": 2539,
    "creationDate": "1693409377475704",
    "id": "2539",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2540",
    "zoneId": "8",
    "metaId": "5502",
    "nftId": 2540,
    "creationDate": "1693409377475704",
    "id": "2540",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2541",
    "zoneId": "8",
    "metaId": "5291",
    "nftId": 2541,
    "creationDate": "1693409377475704",
    "id": "2541",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2542",
    "zoneId": "8",
    "metaId": "5213",
    "nftId": 2542,
    "creationDate": "1693409461365887",
    "id": "2542",
    "mintedVia": "CROWN",
  },
  {
    "x": "-66",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2543",
    "zoneId": "8",
    "metaId": "6218",
    "nftId": 2543,
    "creationDate": "1693409461365887",
    "id": "2543",
    "mintedVia": "CROWN",
  },
  {
    "x": "-86",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2544",
    "zoneId": "8",
    "metaId": "6374",
    "nftId": 2544,
    "creationDate": "1693409461365887",
    "id": "2544",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2545",
    "zoneId": "8",
    "metaId": "5118",
    "nftId": 2545,
    "creationDate": "1693409461365887",
    "id": "2545",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2546",
    "zoneId": "8",
    "metaId": "5839",
    "nftId": 2546,
    "creationDate": "1693409461365887",
    "id": "2546",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2547",
    "zoneId": "8",
    "metaId": "5165",
    "nftId": 2547,
    "creationDate": "1693409461365887",
    "id": "2547",
    "mintedVia": "CROWN",
  },
  {
    "x": "84",
    "y": "-10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2548",
    "zoneId": "8",
    "metaId": "5921",
    "nftId": 2548,
    "creationDate": "1693409461365887",
    "id": "2548",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2549",
    "zoneId": "8",
    "metaId": "5448",
    "nftId": 2549,
    "creationDate": "1693409461365887",
    "id": "2549",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2550",
    "zoneId": "8",
    "metaId": "5388",
    "nftId": 2550,
    "creationDate": "1693409461365887",
    "id": "2550",
    "mintedVia": "CROWN",
  },
  {
    "x": "42",
    "y": "23",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2551",
    "zoneId": "8",
    "metaId": "4735",
    "nftId": 2551,
    "creationDate": "1693409461365887",
    "id": "2551",
    "mintedVia": "CROWN",
  },
  {
    "x": "-98",
    "y": "-16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2552",
    "zoneId": "8",
    "metaId": "6553",
    "nftId": 2552,
    "creationDate": "1693409461365887",
    "id": "2552",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "27",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2553",
    "zoneId": "8",
    "metaId": "4705",
    "nftId": 2553,
    "creationDate": "1693409461365887",
    "id": "2553",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2554",
    "zoneId": "8",
    "metaId": "5827",
    "nftId": 2554,
    "creationDate": "1693409461365887",
    "id": "2554",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2555",
    "zoneId": "8",
    "metaId": "5507",
    "nftId": 2555,
    "creationDate": "1693409461365887",
    "id": "2555",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "0",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2556",
    "zoneId": "8",
    "metaId": "5164",
    "nftId": 2556,
    "creationDate": "1693409461365887",
    "id": "2556",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2557",
    "zoneId": "8",
    "metaId": "6763",
    "nftId": 2557,
    "creationDate": "1693409461365887",
    "id": "2557",
    "mintedVia": "CROWN",
  },
  {
    "x": "-73",
    "y": "19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2558",
    "zoneId": "8",
    "metaId": "6256",
    "nftId": 2558,
    "creationDate": "1693409461365887",
    "id": "2558",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "-18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2559",
    "zoneId": "8",
    "metaId": "5596",
    "nftId": 2559,
    "creationDate": "1693409461365887",
    "id": "2559",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2560",
    "zoneId": "8",
    "metaId": "5804",
    "nftId": 2560,
    "creationDate": "1693409461365887",
    "id": "2560",
    "mintedVia": "CROWN",
  },
  {
    "x": "-99",
    "y": "-16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2561",
    "zoneId": "8",
    "metaId": "6573",
    "nftId": 2561,
    "creationDate": "1693409461365887",
    "id": "2561",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2562",
    "zoneId": "8",
    "metaId": "5249",
    "nftId": 2562,
    "creationDate": "1693409461365887",
    "id": "2562",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2563",
    "zoneId": "8",
    "metaId": "5256",
    "nftId": 2563,
    "creationDate": "1693409461365887",
    "id": "2563",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2564",
    "zoneId": "8",
    "metaId": "6679",
    "nftId": 2564,
    "creationDate": "1693409461365887",
    "id": "2564",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "1",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2565",
    "zoneId": "8",
    "metaId": "6795",
    "nftId": 2565,
    "creationDate": "1693409461365887",
    "id": "2565",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2566",
    "zoneId": "8",
    "metaId": "4813",
    "nftId": 2566,
    "creationDate": "1693409461365887",
    "id": "2566",
    "mintedVia": "CROWN",
  },
  {
    "x": "-65",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2567",
    "zoneId": "8",
    "metaId": "6214",
    "nftId": 2567,
    "creationDate": "1693409563461372",
    "id": "2567",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "30",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2568",
    "zoneId": "8",
    "metaId": "6638",
    "nftId": 2568,
    "creationDate": "1693409563461372",
    "id": "2568",
    "mintedVia": "CROWN",
  },
  {
    "x": "20",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2569",
    "zoneId": "8",
    "metaId": "4866",
    "nftId": 2569,
    "creationDate": "1693409563461372",
    "id": "2569",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "-13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2570",
    "zoneId": "8",
    "metaId": "6863",
    "nftId": 2570,
    "creationDate": "1693409563461372",
    "id": "2570",
    "mintedVia": "CROWN",
  },
  {
    "x": "23",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2571",
    "zoneId": "8",
    "metaId": "4940",
    "nftId": 2571,
    "creationDate": "1693409563461372",
    "id": "2571",
    "mintedVia": "CROWN",
  },
  {
    "x": "10",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2572",
    "zoneId": "8",
    "metaId": "4802",
    "nftId": 2572,
    "creationDate": "1693409563461372",
    "id": "2572",
    "mintedVia": "CROWN",
  },
  {
    "x": "39",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2573",
    "zoneId": "8",
    "metaId": "4954",
    "nftId": 2573,
    "creationDate": "1693409563461372",
    "id": "2573",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2574",
    "zoneId": "8",
    "metaId": "5259",
    "nftId": 2574,
    "creationDate": "1693409563461372",
    "id": "2574",
    "mintedVia": "CROWN",
  },
  {
    "x": "57",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2575",
    "zoneId": "8",
    "metaId": "5052",
    "nftId": 2575,
    "creationDate": "1693409563461372",
    "id": "2575",
    "mintedVia": "CROWN",
  },
  {
    "x": "32",
    "y": "24",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2576",
    "zoneId": "8",
    "metaId": "4715",
    "nftId": 2576,
    "creationDate": "1693409563461372",
    "id": "2576",
    "mintedVia": "CROWN",
  },
  {
    "x": "-46",
    "y": "-18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2577",
    "zoneId": "8",
    "metaId": "6886",
    "nftId": 2577,
    "creationDate": "1693409563461372",
    "id": "2577",
    "mintedVia": "CROWN",
  },
  {
    "x": "-87",
    "y": "11",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2578",
    "zoneId": "8",
    "metaId": "6381",
    "nftId": 2578,
    "creationDate": "1693409563461372",
    "id": "2578",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "-19",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2579",
    "zoneId": "8",
    "metaId": "5476",
    "nftId": 2579,
    "creationDate": "1693409563461372",
    "id": "2579",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2580",
    "zoneId": "8",
    "metaId": "5545",
    "nftId": 2580,
    "creationDate": "1693409563461372",
    "id": "2580",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2581",
    "zoneId": "8",
    "metaId": "5451",
    "nftId": 2581,
    "creationDate": "1693409563461372",
    "id": "2581",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2582",
    "zoneId": "8",
    "metaId": "6016",
    "nftId": 2582,
    "creationDate": "1693409563461372",
    "id": "2582",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "-10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2583",
    "zoneId": "8",
    "metaId": "5887",
    "nftId": 2583,
    "creationDate": "1693409563461372",
    "id": "2583",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "-8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2584",
    "zoneId": "8",
    "metaId": "5677",
    "nftId": 2584,
    "creationDate": "1693409563461372",
    "id": "2584",
    "mintedVia": "CROWN",
  },
  {
    "x": "24",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2585",
    "zoneId": "8",
    "metaId": "4832",
    "nftId": 2585,
    "creationDate": "1693409563461372",
    "id": "2585",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2586",
    "zoneId": "8",
    "metaId": "5462",
    "nftId": 2586,
    "creationDate": "1693409563461372",
    "id": "2586",
    "mintedVia": "CROWN",
  },
  {
    "x": "-68",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2587",
    "zoneId": "8",
    "metaId": "6222",
    "nftId": 2587,
    "creationDate": "1693409563461372",
    "id": "2587",
    "mintedVia": "CROWN",
  },
  {
    "x": "24",
    "y": "26",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2588",
    "zoneId": "8",
    "metaId": "4688",
    "nftId": 2588,
    "creationDate": "1693409563461372",
    "id": "2588",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2589",
    "zoneId": "8",
    "metaId": "5375",
    "nftId": 2589,
    "creationDate": "1693409563461372",
    "id": "2589",
    "mintedVia": "CROWN",
  },
  {
    "x": "-57",
    "y": "-8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2590",
    "zoneId": "8",
    "metaId": "6940",
    "nftId": 2590,
    "creationDate": "1693409563461372",
    "id": "2590",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "4",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2591",
    "zoneId": "8",
    "metaId": "6672",
    "nftId": 2591,
    "creationDate": "1693409563461372",
    "id": "2591",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "13",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2592",
    "zoneId": "8",
    "metaId": "6721",
    "nftId": 2592,
    "creationDate": "1693409695440199",
    "id": "2592",
    "mintedVia": "CROWN",
  },
  {
    "x": "-92",
    "y": "-17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2593",
    "zoneId": "8",
    "metaId": "6437",
    "nftId": 2593,
    "creationDate": "1693409695440199",
    "id": "2593",
    "mintedVia": "CROWN",
  },
  {
    "x": "-83",
    "y": "14",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2594",
    "zoneId": "8",
    "metaId": "6349",
    "nftId": 2594,
    "creationDate": "1693409695440199",
    "id": "2594",
    "mintedVia": "CROWN",
  },
  {
    "x": "-89",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2595",
    "zoneId": "8",
    "metaId": "6396",
    "nftId": 2595,
    "creationDate": "1693409695440199",
    "id": "2595",
    "mintedVia": "CROWN",
  },
  {
    "x": "-98",
    "y": "8",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2596",
    "zoneId": "8",
    "metaId": "6568",
    "nftId": 2596,
    "creationDate": "1693409695440199",
    "id": "2596",
    "mintedVia": "CROWN",
  },
  {
    "x": "-77",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2597",
    "zoneId": "8",
    "metaId": "6286",
    "nftId": 2597,
    "creationDate": "1693409695440199",
    "id": "2597",
    "mintedVia": "CROWN",
  },
  {
    "x": "43",
    "y": "20",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2598",
    "zoneId": "8",
    "metaId": "4737",
    "nftId": 2598,
    "creationDate": "1693409695440199",
    "id": "2598",
    "mintedVia": "CROWN",
  },
  {
    "x": "68",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2599",
    "zoneId": "8",
    "metaId": "5326",
    "nftId": 2599,
    "creationDate": "1693409695440199",
    "id": "2599",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "17",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2600",
    "zoneId": "8",
    "metaId": "4937",
    "nftId": 2600,
    "creationDate": "1693409695440199",
    "id": "2600",
    "mintedVia": "CROWN",
  },
  {
    "x": "-95",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2601",
    "zoneId": "8",
    "metaId": "6505",
    "nftId": 2601,
    "creationDate": "1693409695440199",
    "id": "2601",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2602",
    "zoneId": "8",
    "metaId": "6607",
    "nftId": 2602,
    "creationDate": "1693409695440199",
    "id": "2602",
    "mintedVia": "CROWN",
  },
  {
    "x": "66",
    "y": "15",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2603",
    "zoneId": "8",
    "metaId": "5253",
    "nftId": 2603,
    "creationDate": "1693409695440199",
    "id": "2603",
    "mintedVia": "CROWN",
  },
  {
    "x": "-73",
    "y": "12",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2604",
    "zoneId": "8",
    "metaId": "6249",
    "nftId": 2604,
    "creationDate": "1693409695440199",
    "id": "2604",
    "mintedVia": "CROWN",
  },
  {
    "x": "71",
    "y": "16",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2605",
    "zoneId": "8",
    "metaId": "5449",
    "nftId": 2605,
    "creationDate": "1693409695440199",
    "id": "2605",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "18",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2606",
    "zoneId": "8",
    "metaId": "5489",
    "nftId": 2606,
    "creationDate": "1693409695440199",
    "id": "2606",
    "mintedVia": "CROWN",
  },
  {
    "x": "-101",
    "y": "-3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2607",
    "zoneId": "8",
    "metaId": "6102",
    "nftId": 2607,
    "creationDate": "1693409695440199",
    "id": "2607",
    "mintedVia": "CROWN",
  },
  {
    "x": "81",
    "y": "29",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2608",
    "zoneId": "8",
    "metaId": "5847",
    "nftId": 2608,
    "creationDate": "1693409695440199",
    "id": "2608",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "-10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2609",
    "zoneId": "8",
    "metaId": "5856",
    "nftId": 2609,
    "creationDate": "1693409695440199",
    "id": "2609",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "3",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2610",
    "zoneId": "8",
    "metaId": "5389",
    "nftId": 2610,
    "creationDate": "1693409695440199",
    "id": "2610",
    "mintedVia": "CROWN",
  },
  {
    "x": "83",
    "y": "10",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2611",
    "zoneId": "8",
    "metaId": "5897",
    "nftId": 2611,
    "creationDate": "1693409695440199",
    "id": "2611",
    "mintedVia": "CROWN",
  },
  {
    "x": "63",
    "y": "28",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2612",
    "zoneId": "8",
    "metaId": "5148",
    "nftId": 2612,
    "creationDate": "1693409695440199",
    "id": "2612",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "-9",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2613",
    "zoneId": "8",
    "metaId": "5286",
    "nftId": 2613,
    "creationDate": "1693409695440199",
    "id": "2613",
    "mintedVia": "CROWN",
  },
  {
    "x": "87",
    "y": "6",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2614",
    "zoneId": "8",
    "metaId": "6044",
    "nftId": 2614,
    "creationDate": "1693409695440199",
    "id": "2614",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "-7",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2615",
    "zoneId": "8",
    "metaId": "5601",
    "nftId": 2615,
    "creationDate": "1693409695440199",
    "id": "2615",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "21",
    "owner": "hx52a5f71f8f6323adf618362e647f645e786b9b9d",
    "name": "Emerald City Land #2616",
    "zoneId": "8",
    "metaId": "5805",
    "nftId": 2616,
    "creationDate": "1693409695440199",
    "id": "2616",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "-10",
    "owner": "hxeab89c26bc2bd872b07faedd752ac66411c85c77",
    "name": "Emerald City Land #2617",
    "zoneId": "8",
    "metaId": "6892",
    "nftId": 2617,
    "creationDate": "1693475434428692",
    "id": "2617",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "-3",
    "owner": "hx1be60841025db1b22126ba08ba519326603029c9",
    "name": "Emerald City Land #2618",
    "zoneId": "8",
    "metaId": "6758",
    "nftId": 2618,
    "creationDate": "1693582650698902",
    "id": "2618",
    "mintedVia": "CROWN",
  },
  {
    "x": "22",
    "y": "11",
    "owner": "hx1be60841025db1b22126ba08ba519326603029c9",
    "name": "Emerald City Land #2619",
    "zoneId": "8",
    "metaId": "4824",
    "nftId": 2619,
    "creationDate": "1693582650698902",
    "id": "2619",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "12",
    "owner": "hx6c5a105740cd09ae9a7ff4d3d521249d79fadd4d",
    "name": "Emerald City Land #2620",
    "zoneId": "1",
    "metaId": "76",
    "nftId": 2620,
    "creationDate": "1693627757503782",
    "id": "2620",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "-10",
    "owner": "hxd34b919aa4e22a09da8e76cb0115308fabf75ffc",
    "name": "Emerald City Land #2621",
    "zoneId": "7",
    "metaId": "3024",
    "nftId": 2621,
    "creationDate": "1693628127532852",
    "id": "2621",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "14",
    "owner": "hxd34b919aa4e22a09da8e76cb0115308fabf75ffc",
    "name": "Emerald City Land #2622",
    "zoneId": "7",
    "metaId": "4209",
    "nftId": 2622,
    "creationDate": "1693628127532852",
    "id": "2622",
    "mintedVia": "CROWN",
  },
  {
    "x": "21",
    "y": "28",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2623",
    "zoneId": "8",
    "metaId": "4934",
    "nftId": 2623,
    "creationDate": "1693646340826303",
    "id": "2623",
    "mintedVia": "CROWN",
  },
  {
    "x": "-92",
    "y": "-2",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2624",
    "zoneId": "8",
    "metaId": "6438",
    "nftId": 2624,
    "creationDate": "1693646340826303",
    "id": "2624",
    "mintedVia": "CROWN",
  },
  {
    "x": "-78",
    "y": "15",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2625",
    "zoneId": "8",
    "metaId": "6298",
    "nftId": 2625,
    "creationDate": "1693646340826303",
    "id": "2625",
    "mintedVia": "CROWN",
  },
  {
    "x": "54",
    "y": "23",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2626",
    "zoneId": "8",
    "metaId": "4844",
    "nftId": 2626,
    "creationDate": "1693646340826303",
    "id": "2626",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "-11",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2627",
    "zoneId": "8",
    "metaId": "6861",
    "nftId": 2627,
    "creationDate": "1693646340826303",
    "id": "2627",
    "mintedVia": "CROWN",
  },
  {
    "x": "75",
    "y": "16",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2628",
    "zoneId": "8",
    "metaId": "5607",
    "nftId": 2628,
    "creationDate": "1693646340826303",
    "id": "2628",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "18",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2629",
    "zoneId": "7",
    "metaId": "3688",
    "nftId": 2629,
    "creationDate": "1693646382926272",
    "id": "2629",
    "mintedVia": "CROWN",
  },
  {
    "x": "-2",
    "y": "15",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2630",
    "zoneId": "7",
    "metaId": "3717",
    "nftId": 2630,
    "creationDate": "1693646382926272",
    "id": "2630",
    "mintedVia": "CROWN",
  },
  {
    "x": "7",
    "y": "16",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2631",
    "zoneId": "7",
    "metaId": "4485",
    "nftId": 2631,
    "creationDate": "1693646382926272",
    "id": "2631",
    "mintedVia": "CROWN",
  },
  {
    "x": "-15",
    "y": "4",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2632",
    "zoneId": "7",
    "metaId": "2929",
    "nftId": 2632,
    "creationDate": "1693646382926272",
    "id": "2632",
    "mintedVia": "CROWN",
  },
  {
    "x": "53",
    "y": "-7",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2633",
    "zoneId": "7",
    "metaId": "2769",
    "nftId": 2633,
    "creationDate": "1693646382926272",
    "id": "2633",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "21",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2634",
    "zoneId": "7",
    "metaId": "3943",
    "nftId": 2634,
    "creationDate": "1693646382926272",
    "id": "2634",
    "mintedVia": "CROWN",
  },
  {
    "x": "-34",
    "y": "11",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2635",
    "zoneId": "7",
    "metaId": "4004",
    "nftId": 2635,
    "creationDate": "1693646382926272",
    "id": "2635",
    "mintedVia": "CROWN",
  },
  {
    "x": "-37",
    "y": "23",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2636",
    "zoneId": "7",
    "metaId": "4099",
    "nftId": 2636,
    "creationDate": "1693646382926272",
    "id": "2636",
    "mintedVia": "CROWN",
  },
  {
    "x": "-13",
    "y": "-9",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2637",
    "zoneId": "7",
    "metaId": "2899",
    "nftId": 2637,
    "creationDate": "1693646382926272",
    "id": "2637",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "2",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2638",
    "zoneId": "7",
    "metaId": "4118",
    "nftId": 2638,
    "creationDate": "1693646382926272",
    "id": "2638",
    "mintedVia": "CROWN",
  },
  {
    "x": "61",
    "y": "14",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2639",
    "zoneId": "7",
    "metaId": "2184",
    "nftId": 2639,
    "creationDate": "1693646382926272",
    "id": "2639",
    "mintedVia": "CROWN",
  },
  {
    "x": "-22",
    "y": "25",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2640",
    "zoneId": "7",
    "metaId": "3606",
    "nftId": 2640,
    "creationDate": "1693646382926272",
    "id": "2640",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "2",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2641",
    "zoneId": "7",
    "metaId": "2558",
    "nftId": 2641,
    "creationDate": "1693646382926272",
    "id": "2641",
    "mintedVia": "CROWN",
  },
  {
    "x": "-94",
    "y": "-14",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2642",
    "zoneId": "7",
    "metaId": "2365",
    "nftId": 2642,
    "creationDate": "1693646382926272",
    "id": "2642",
    "mintedVia": "CROWN",
  },
  {
    "x": "5",
    "y": "26",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2643",
    "zoneId": "7",
    "metaId": "4458",
    "nftId": 2643,
    "creationDate": "1693646382926272",
    "id": "2643",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "12",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2644",
    "zoneId": "7",
    "metaId": "3934",
    "nftId": 2644,
    "creationDate": "1693646382926272",
    "id": "2644",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "-1",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2645",
    "zoneId": "7",
    "metaId": "2904",
    "nftId": 2645,
    "creationDate": "1693646382926272",
    "id": "2645",
    "mintedVia": "CROWN",
  },
  {
    "x": "27",
    "y": "2",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2646",
    "zoneId": "1",
    "metaId": "505",
    "nftId": 2646,
    "creationDate": "1693646434964857",
    "id": "2646",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "2",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2647",
    "zoneId": "1",
    "metaId": "524",
    "nftId": 2647,
    "creationDate": "1693646434964857",
    "id": "2647",
    "mintedVia": "CROWN",
  },
  {
    "x": "27",
    "y": "-10",
    "owner": "hx0e711f326375ac6b6e1a509b9e410ea915a75414",
    "name": "Emerald City Land #2648",
    "zoneId": "1",
    "metaId": "499",
    "nftId": 2648,
    "creationDate": "1693646434964857",
    "id": "2648",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "10",
    "owner": "hxe88dc41a0e74aa7e4be02e34e89e2e39d439887c",
    "name": "Emerald City Land #2649",
    "zoneId": "8",
    "metaId": "4941",
    "nftId": 2649,
    "creationDate": "1693646494843388",
    "id": "2649",
    "mintedVia": "CROWN",
  },
  {
    "x": "43",
    "y": "-6",
    "owner": "hxe88dc41a0e74aa7e4be02e34e89e2e39d439887c",
    "name": "Emerald City Land #2650",
    "zoneId": "7",
    "metaId": "2644",
    "nftId": 2650,
    "creationDate": "1693646517035843",
    "id": "2650",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "9",
    "owner": "hxe88dc41a0e74aa7e4be02e34e89e2e39d439887c",
    "name": "Emerald City Land #2651",
    "zoneId": "7",
    "metaId": "2726",
    "nftId": 2651,
    "creationDate": "1693646517035843",
    "id": "2651",
    "mintedVia": "CROWN",
  },
  {
    "x": "-3",
    "y": "19",
    "owner": "hxe88dc41a0e74aa7e4be02e34e89e2e39d439887c",
    "name": "Emerald City Land #2652",
    "zoneId": "7",
    "metaId": "3754",
    "nftId": 2652,
    "creationDate": "1693646517035843",
    "id": "2652",
    "mintedVia": "CROWN",
  },
  {
    "x": "45",
    "y": "23",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #2653",
    "zoneId": "5",
    "metaId": "1551",
    "nftId": 2653,
    "creationDate": "1693662893914499",
    "id": "2653",
    "mintedVia": "CROWN",
  },
  {
    "x": "-46",
    "y": "-30",
    "owner": "hx609ff3d94e702f5a0e23862c9ab1028988969037",
    "name": "Emerald City Land #2654",
    "zoneId": "2",
    "metaId": "813",
    "nftId": 2654,
    "creationDate": "1693709236237809",
    "id": "2654",
    "mintedVia": "CROWN",
  },
  {
    "x": "18",
    "y": "6",
    "owner": "hx609ff3d94e702f5a0e23862c9ab1028988969037",
    "name": "Emerald City Land #2655",
    "zoneId": "7",
    "metaId": "3239",
    "nftId": 2655,
    "creationDate": "1693709622559420",
    "id": "2655",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "19",
    "owner": "hx609ff3d94e702f5a0e23862c9ab1028988969037",
    "name": "Emerald City Land #2656",
    "zoneId": "7",
    "metaId": "4038",
    "nftId": 2656,
    "creationDate": "1693709622559420",
    "id": "2656",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "4",
    "owner": "hx609ff3d94e702f5a0e23862c9ab1028988969037",
    "name": "Emerald City Land #2657",
    "zoneId": "7",
    "metaId": "2944",
    "nftId": 2657,
    "creationDate": "1693709622559420",
    "id": "2657",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "6",
    "owner": "hx609ff3d94e702f5a0e23862c9ab1028988969037",
    "name": "Emerald City Land #2658",
    "zoneId": "6",
    "metaId": "1777",
    "nftId": 2658,
    "creationDate": "1693709756409762",
    "id": "2658",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "7",
    "owner": "hx609ff3d94e702f5a0e23862c9ab1028988969037",
    "name": "Emerald City Land #2659",
    "zoneId": "3",
    "metaId": "981",
    "nftId": 2659,
    "creationDate": "1693709846635164",
    "id": "2659",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "-2",
    "owner": "hx609ff3d94e702f5a0e23862c9ab1028988969037",
    "name": "Emerald City Land #2660",
    "zoneId": "3",
    "metaId": "1059",
    "nftId": 2660,
    "creationDate": "1693709970474225",
    "id": "2660",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "-13",
    "owner": "hx609ff3d94e702f5a0e23862c9ab1028988969037",
    "name": "Emerald City Land #2661",
    "zoneId": "1",
    "metaId": "100",
    "nftId": 2661,
    "creationDate": "1693710036493564",
    "id": "2661",
    "mintedVia": "CROWN",
  },
  {
    "x": "26",
    "y": "2",
    "owner": "hx609ff3d94e702f5a0e23862c9ab1028988969037",
    "name": "Emerald City Land #2662",
    "zoneId": "1",
    "metaId": "492",
    "nftId": 2662,
    "creationDate": "1693710036493564",
    "id": "2662",
    "mintedVia": "CROWN",
  },
  {
    "x": "-60",
    "y": "-14",
    "owner": "hx609ff3d94e702f5a0e23862c9ab1028988969037",
    "name": "Emerald City Land #2663",
    "zoneId": "1",
    "metaId": "716",
    "nftId": 2663,
    "creationDate": "1693710036493564",
    "id": "2663",
    "mintedVia": "CROWN",
  },
  {
    "x": "-27",
    "y": "-23",
    "owner": "hx57edc0f934535e82f2d7f9a615297952690ffbfd",
    "name": "Emerald City Land #2664",
    "zoneId": "2",
    "metaId": "745",
    "nftId": 2664,
    "creationDate": "1693710104722086",
    "id": "2664",
    "mintedVia": "CROWN",
  },
  {
    "x": "-50",
    "y": "-21",
    "owner": "hx57edc0f934535e82f2d7f9a615297952690ffbfd",
    "name": "Emerald City Land #2665",
    "zoneId": "2",
    "metaId": "827",
    "nftId": 2665,
    "creationDate": "1693710146747710",
    "id": "2665",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "5",
    "owner": "hx609ff3d94e702f5a0e23862c9ab1028988969037",
    "name": "Emerald City Land #2666",
    "zoneId": "8",
    "metaId": "5882",
    "nftId": 2666,
    "creationDate": "1693710168756546",
    "id": "2666",
    "mintedVia": "CROWN",
  },
  {
    "x": "3",
    "y": "36",
    "owner": "hx57edc0f934535e82f2d7f9a615297952690ffbfd",
    "name": "Emerald City Land #2667",
    "zoneId": "3",
    "metaId": "1052",
    "nftId": 2667,
    "creationDate": "1693710194775064",
    "id": "2667",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "17",
    "owner": "hx57edc0f934535e82f2d7f9a615297952690ffbfd",
    "name": "Emerald City Land #2668",
    "zoneId": "3",
    "metaId": "1061",
    "nftId": 2668,
    "creationDate": "1693710194775064",
    "id": "2668",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "14",
    "owner": "hx57edc0f934535e82f2d7f9a615297952690ffbfd",
    "name": "Emerald City Land #2669",
    "zoneId": "4",
    "metaId": "1211",
    "nftId": 2669,
    "creationDate": "1693711689158565",
    "id": "2669",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "-29",
    "owner": "hx4b0deedd552bab1c9123e8523e056adca5658fd5",
    "name": "Emerald City Land #2670",
    "zoneId": "2",
    "metaId": "845",
    "nftId": 2670,
    "creationDate": "1693729478670433",
    "id": "2670",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "13",
    "owner": "hx6ed97bda00fa9d719c7e26086b9cdf822954922e",
    "name": "Emerald City Land #2671",
    "zoneId": "8",
    "metaId": "4692",
    "nftId": 2671,
    "creationDate": "1693818034760976",
    "id": "2671",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "25",
    "owner": "hx84eb0a4f19a0f0d5bf372cb1c7f578828d61ca7c",
    "name": "Emerald City Land #2672",
    "zoneId": "8",
    "metaId": "6646",
    "nftId": 2672,
    "creationDate": "1693826601487029",
    "id": "2672",
    "mintedVia": "CROWN",
  },
  {
    "x": "-12",
    "y": "-9",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #2673",
    "zoneId": "7",
    "metaId": "2884",
    "nftId": 2673,
    "creationDate": "1693828217882923",
    "id": "2673",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "20",
    "owner": "hx2142a5b85d3682b71a01eb80c7aee27980481e70",
    "name": "Emerald City Land #2674",
    "zoneId": "4",
    "metaId": "1199",
    "nftId": 2674,
    "creationDate": "1693829092502551",
    "id": "2674",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "28",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2675",
    "zoneId": "4",
    "metaId": "1466",
    "nftId": 2675,
    "creationDate": "1693847233639851",
    "id": "2675",
    "mintedVia": "CROWN",
  },
  {
    "x": "28",
    "y": "16",
    "owner": "hx9a949bf4256c07eb70d45c134a76e162197e6f37",
    "name": "Emerald City Land #2676",
    "zoneId": "7",
    "metaId": "1958",
    "nftId": 2676,
    "creationDate": "1693900931527109",
    "id": "2676",
    "mintedVia": "CROWN",
  },
  {
    "x": "40",
    "y": "29",
    "owner": "hx9a949bf4256c07eb70d45c134a76e162197e6f37",
    "name": "Emerald City Land #2677",
    "zoneId": "7",
    "metaId": "2005",
    "nftId": 2677,
    "creationDate": "1693901081569794",
    "id": "2677",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "28",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2678",
    "zoneId": "7",
    "metaId": "3927",
    "nftId": 2678,
    "creationDate": "1693938434831361",
    "id": "2678",
    "mintedVia": "CROWN",
  },
  {
    "x": "12",
    "y": "19",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2679",
    "zoneId": "7",
    "metaId": "1915",
    "nftId": 2679,
    "creationDate": "1693938434831361",
    "id": "2679",
    "mintedVia": "CROWN",
  },
  {
    "x": "-36",
    "y": "14",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2680",
    "zoneId": "7",
    "metaId": "4063",
    "nftId": 2680,
    "creationDate": "1693938434831361",
    "id": "2680",
    "mintedVia": "CROWN",
  },
  {
    "x": "39",
    "y": "2",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2681",
    "zoneId": "7",
    "metaId": "2595",
    "nftId": 2681,
    "creationDate": "1693938434831361",
    "id": "2681",
    "mintedVia": "CROWN",
  },
  {
    "x": "-2",
    "y": "-8",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2682",
    "zoneId": "7",
    "metaId": "3018",
    "nftId": 2682,
    "creationDate": "1693938434831361",
    "id": "2682",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "-17",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2683",
    "zoneId": "7",
    "metaId": "2513",
    "nftId": 2683,
    "creationDate": "1693938434831361",
    "id": "2683",
    "mintedVia": "CROWN",
  },
  {
    "x": "-41",
    "y": "21",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2684",
    "zoneId": "7",
    "metaId": "4193",
    "nftId": 2684,
    "creationDate": "1693938434831361",
    "id": "2684",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "-18",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2685",
    "zoneId": "1",
    "metaId": "681",
    "nftId": 2685,
    "creationDate": "1693938506707640",
    "id": "2685",
    "mintedVia": "CROWN",
  },
  {
    "x": "20",
    "y": "-7",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2686",
    "zoneId": "1",
    "metaId": "391",
    "nftId": 2686,
    "creationDate": "1693938506707640",
    "id": "2686",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "-6",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2687",
    "zoneId": "8",
    "metaId": "6830",
    "nftId": 2687,
    "creationDate": "1693938544689391",
    "id": "2687",
    "mintedVia": "CROWN",
  },
  {
    "x": "16",
    "y": "27",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2688",
    "zoneId": "8",
    "metaId": "4924",
    "nftId": 2688,
    "creationDate": "1693938544689391",
    "id": "2688",
    "mintedVia": "CROWN",
  },
  {
    "x": "-17",
    "y": "21",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2689",
    "zoneId": "8",
    "metaId": "6628",
    "nftId": 2689,
    "creationDate": "1693938544689391",
    "id": "2689",
    "mintedVia": "CROWN",
  },
  {
    "x": "64",
    "y": "6",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2690",
    "zoneId": "8",
    "metaId": "5189",
    "nftId": 2690,
    "creationDate": "1693938544689391",
    "id": "2690",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "10",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2691",
    "zoneId": "8",
    "metaId": "5646",
    "nftId": 2691,
    "creationDate": "1693938544689391",
    "id": "2691",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "23",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2692",
    "zoneId": "8",
    "metaId": "4909",
    "nftId": 2692,
    "creationDate": "1693938544689391",
    "id": "2692",
    "mintedVia": "CROWN",
  },
  {
    "x": "85",
    "y": "26",
    "owner": "hxb611b26f8ef4c8d515de4d23f62316eb1ad842b9",
    "name": "Emerald City Land #2693",
    "zoneId": "8",
    "metaId": "5978",
    "nftId": 2693,
    "creationDate": "1693938544689391",
    "id": "2693",
    "mintedVia": "CROWN",
  },
  {
    "x": "6",
    "y": "19",
    "owner": "hxa05ae08318ab23825b503fee416fa56e3235cbb8",
    "name": "Emerald City Land #2694",
    "zoneId": "7",
    "metaId": "4471",
    "nftId": 2694,
    "creationDate": "1694016792490693",
    "id": "2694",
    "mintedVia": "CROWN",
  },
  {
    "x": "-13",
    "y": "29",
    "owner": "hxfba4b90e499fcdffdf705c2a329b8e5336169f08",
    "name": "Emerald City Land #2695",
    "zoneId": "7",
    "metaId": "3439",
    "nftId": 2695,
    "creationDate": "1694415903915414",
    "id": "2695",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "8",
    "owner": "hx73b6995e5ee75f1c9a6e201dc7a9fc4814eb5a53",
    "name": "Emerald City Land #2696",
    "zoneId": "8",
    "metaId": "6724",
    "nftId": 2696,
    "creationDate": "1694456892752080",
    "id": "2696",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "19",
    "owner": "hxc6ff5827aa5ecb183479709f426793ffb06f78b0",
    "name": "Emerald City Land #2697",
    "zoneId": "3",
    "metaId": "1097",
    "nftId": 2697,
    "creationDate": "1694564590996222",
    "id": "2697",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "19",
    "owner": "hxa0c0ea753dc4cc9fc3d7f4d32a12a8b9334a3f14",
    "name": "Emerald City Land #2698",
    "zoneId": "3",
    "metaId": "1080",
    "nftId": 2698,
    "creationDate": "1694565695351253",
    "id": "2698",
    "mintedVia": "CROWN",
  },
  {
    "x": "-92",
    "y": "-23",
    "owner": "hx15fa4c2f697335d5c068909ad50212db32d70004",
    "name": "Emerald City Land #2699",
    "zoneId": "3",
    "metaId": "969",
    "nftId": 2699,
    "creationDate": "1694607645137594",
    "id": "2699",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "15",
    "owner": "hx9fd28a12867853323f905abb4f1f27ee19c9b833",
    "name": "Emerald City Land #2700",
    "zoneId": "8",
    "metaId": "6780",
    "nftId": 2700,
    "creationDate": "1694675351554288",
    "id": "2700",
    "mintedVia": "CROWN",
  },
  {
    "x": "-53",
    "y": "5",
    "owner": "hx9fd28a12867853323f905abb4f1f27ee19c9b833",
    "name": "Emerald City Land #2701",
    "zoneId": "8",
    "metaId": "6737",
    "nftId": 2701,
    "creationDate": "1694675351554288",
    "id": "2701",
    "mintedVia": "CROWN",
  },
  {
    "x": "15",
    "y": "24",
    "owner": "hx9fd28a12867853323f905abb4f1f27ee19c9b833",
    "name": "Emerald City Land #2702",
    "zoneId": "8",
    "metaId": "4674",
    "nftId": 2702,
    "creationDate": "1694675351554288",
    "id": "2702",
    "mintedVia": "CROWN",
  },
  {
    "x": "-16",
    "y": "16",
    "owner": "hx408e89e7f6319c1361c0ccc09e337396ac43b47f",
    "name": "Emerald City Land #2703",
    "zoneId": "7",
    "metaId": "3480",
    "nftId": 2703,
    "creationDate": "1694795430595972",
    "id": "2703",
    "mintedVia": "CROWN",
  },
  {
    "x": "-43",
    "y": "-35",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2704",
    "zoneId": "2",
    "metaId": "806",
    "nftId": 2704,
    "creationDate": "1694799003822208",
    "id": "2704",
    "mintedVia": "CROWN",
  },
  {
    "x": "-30",
    "y": "-21",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2705",
    "zoneId": "2",
    "metaId": "760",
    "nftId": 2705,
    "creationDate": "1694799868323505",
    "id": "2705",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "-35",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2706",
    "zoneId": "2",
    "metaId": "835",
    "nftId": 2706,
    "creationDate": "1694799868323505",
    "id": "2706",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-30",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2707",
    "zoneId": "2",
    "metaId": "741",
    "nftId": 2707,
    "creationDate": "1694799868323505",
    "id": "2707",
    "mintedVia": "CROWN",
  },
  {
    "x": "-32",
    "y": "-21",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2708",
    "zoneId": "2",
    "metaId": "765",
    "nftId": 2708,
    "creationDate": "1694799868323505",
    "id": "2708",
    "mintedVia": "CROWN",
  },
  {
    "x": "-68",
    "y": "-28",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2709",
    "zoneId": "2",
    "metaId": "904",
    "nftId": 2709,
    "creationDate": "1694799868323505",
    "id": "2709",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "-22",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2710",
    "zoneId": "2",
    "metaId": "808",
    "nftId": 2710,
    "creationDate": "1694799868323505",
    "id": "2710",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "-32",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2711",
    "zoneId": "2",
    "metaId": "816",
    "nftId": 2711,
    "creationDate": "1694799868323505",
    "id": "2711",
    "mintedVia": "CROWN",
  },
  {
    "x": "-55",
    "y": "-30",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2712",
    "zoneId": "2",
    "metaId": "860",
    "nftId": 2712,
    "creationDate": "1694799868323505",
    "id": "2712",
    "mintedVia": "CROWN",
  },
  {
    "x": "-51",
    "y": "-34",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2713",
    "zoneId": "2",
    "metaId": "834",
    "nftId": 2713,
    "creationDate": "1694799868323505",
    "id": "2713",
    "mintedVia": "CROWN",
  },
  {
    "x": "8",
    "y": "-1",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2714",
    "zoneId": "7",
    "metaId": "3345",
    "nftId": 2714,
    "creationDate": "1694799998580851",
    "id": "2714",
    "mintedVia": "CROWN",
  },
  {
    "x": "18",
    "y": "5",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2715",
    "zoneId": "7",
    "metaId": "3238",
    "nftId": 2715,
    "creationDate": "1694799998580851",
    "id": "2715",
    "mintedVia": "CROWN",
  },
  {
    "x": "-38",
    "y": "27",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2716",
    "zoneId": "7",
    "metaId": "4125",
    "nftId": 2716,
    "creationDate": "1694799998580851",
    "id": "2716",
    "mintedVia": "CROWN",
  },
  {
    "x": "-93",
    "y": "-10",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2717",
    "zoneId": "7",
    "metaId": "2347",
    "nftId": 2717,
    "creationDate": "1694799998580851",
    "id": "2717",
    "mintedVia": "CROWN",
  },
  {
    "x": "33",
    "y": "-1",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2718",
    "zoneId": "7",
    "metaId": "2467",
    "nftId": 2718,
    "creationDate": "1694799998580851",
    "id": "2718",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "23",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2719",
    "zoneId": "7",
    "metaId": "1870",
    "nftId": 2719,
    "creationDate": "1694799998580851",
    "id": "2719",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "-32",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2720",
    "zoneId": "7",
    "metaId": "4546",
    "nftId": 2720,
    "creationDate": "1694799998580851",
    "id": "2720",
    "mintedVia": "CROWN",
  },
  {
    "x": "49",
    "y": "20",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2721",
    "zoneId": "7",
    "metaId": "1887",
    "nftId": 2721,
    "creationDate": "1694799998580851",
    "id": "2721",
    "mintedVia": "CROWN",
  },
  {
    "x": "-25",
    "y": "22",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2722",
    "zoneId": "7",
    "metaId": "3654",
    "nftId": 2722,
    "creationDate": "1694799998580851",
    "id": "2722",
    "mintedVia": "CROWN",
  },
  {
    "x": "14",
    "y": "11",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2723",
    "zoneId": "7",
    "metaId": "1849",
    "nftId": 2723,
    "creationDate": "1694799998580851",
    "id": "2723",
    "mintedVia": "CROWN",
  },
  {
    "x": "25",
    "y": "27",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2724",
    "zoneId": "7",
    "metaId": "1852",
    "nftId": 2724,
    "creationDate": "1694799998580851",
    "id": "2724",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "20",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2725",
    "zoneId": "7",
    "metaId": "4281",
    "nftId": 2725,
    "creationDate": "1694799998580851",
    "id": "2725",
    "mintedVia": "CROWN",
  },
  {
    "x": "-40",
    "y": "27",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2726",
    "zoneId": "7",
    "metaId": "4176",
    "nftId": 2726,
    "creationDate": "1694799998580851",
    "id": "2726",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "2",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2727",
    "zoneId": "7",
    "metaId": "4214",
    "nftId": 2727,
    "creationDate": "1694799998580851",
    "id": "2727",
    "mintedVia": "CROWN",
  },
  {
    "x": "43",
    "y": "12",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2728",
    "zoneId": "7",
    "metaId": "1884",
    "nftId": 2728,
    "creationDate": "1694799998580851",
    "id": "2728",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "8",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2729",
    "zoneId": "7",
    "metaId": "3997",
    "nftId": 2729,
    "creationDate": "1694799998580851",
    "id": "2729",
    "mintedVia": "CROWN",
  },
  {
    "x": "44",
    "y": "16",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2730",
    "zoneId": "7",
    "metaId": "2010",
    "nftId": 2730,
    "creationDate": "1694799998580851",
    "id": "2730",
    "mintedVia": "CROWN",
  },
  {
    "x": "-6",
    "y": "20",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2731",
    "zoneId": "7",
    "metaId": "3798",
    "nftId": 2731,
    "creationDate": "1694799998580851",
    "id": "2731",
    "mintedVia": "CROWN",
  },
  {
    "x": "56",
    "y": "21",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2732",
    "zoneId": "7",
    "metaId": "1969",
    "nftId": 2732,
    "creationDate": "1694799998580851",
    "id": "2732",
    "mintedVia": "CROWN",
  },
  {
    "x": "-7",
    "y": "16",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2733",
    "zoneId": "7",
    "metaId": "3808",
    "nftId": 2733,
    "creationDate": "1694799998580851",
    "id": "2733",
    "mintedVia": "CROWN",
  },
  {
    "x": "-24",
    "y": "24",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2734",
    "zoneId": "7",
    "metaId": "3640",
    "nftId": 2734,
    "creationDate": "1694799998580851",
    "id": "2734",
    "mintedVia": "CROWN",
  },
  {
    "x": "51",
    "y": "-5",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2735",
    "zoneId": "7",
    "metaId": "2739",
    "nftId": 2735,
    "creationDate": "1694799998580851",
    "id": "2735",
    "mintedVia": "CROWN",
  },
  {
    "x": "-14",
    "y": "-10",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2736",
    "zoneId": "7",
    "metaId": "2905",
    "nftId": 2736,
    "creationDate": "1694799998580851",
    "id": "2736",
    "mintedVia": "CROWN",
  },
  {
    "x": "-56",
    "y": "9",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #2737",
    "zoneId": "8",
    "metaId": "6787",
    "nftId": 2737,
    "creationDate": "1694882465299694",
    "id": "2737",
    "mintedVia": "CROWN",
  },
  {
    "x": "-74",
    "y": "18",
    "owner": "hxf9abb02725118d18046e66054d856d74968d92be",
    "name": "Emerald City Land #2738",
    "zoneId": "8",
    "metaId": "6264",
    "nftId": 2738,
    "creationDate": "1694882465299694",
    "id": "2738",
    "mintedVia": "CROWN",
  },
  {
    "x": "-59",
    "y": "5",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2739",
    "zoneId": "5",
    "metaId": "1653",
    "nftId": 2739,
    "creationDate": "1694889171560192",
    "id": "2739",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "11",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2740",
    "zoneId": "4",
    "metaId": "1176",
    "nftId": 2740,
    "creationDate": "1694889243582324",
    "id": "2740",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "13",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2741",
    "zoneId": "4",
    "metaId": "1144",
    "nftId": 2741,
    "creationDate": "1694889243582324",
    "id": "2741",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "18",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2742",
    "zoneId": "4",
    "metaId": "1487",
    "nftId": 2742,
    "creationDate": "1694889243582324",
    "id": "2742",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "12",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2743",
    "zoneId": "4",
    "metaId": "1395",
    "nftId": 2743,
    "creationDate": "1694889243582324",
    "id": "2743",
    "mintedVia": "CROWN",
  },
  {
    "x": "6",
    "y": "24",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2744",
    "zoneId": "4",
    "metaId": "1518",
    "nftId": 2744,
    "creationDate": "1694889243582324",
    "id": "2744",
    "mintedVia": "CROWN",
  },
  {
    "x": "30",
    "y": "0",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2745",
    "zoneId": "4",
    "metaId": "1270",
    "nftId": 2745,
    "creationDate": "1694889243582324",
    "id": "2745",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "19",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2746",
    "zoneId": "4",
    "metaId": "1200",
    "nftId": 2746,
    "creationDate": "1694889243582324",
    "id": "2746",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "-19",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2747",
    "zoneId": "4",
    "metaId": "1288",
    "nftId": 2747,
    "creationDate": "1694889243582324",
    "id": "2747",
    "mintedVia": "CROWN",
  },
  {
    "x": "12",
    "y": "9",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2748",
    "zoneId": "4",
    "metaId": "1400",
    "nftId": 2748,
    "creationDate": "1694889243582324",
    "id": "2748",
    "mintedVia": "CROWN",
  },
  {
    "x": "-26",
    "y": "-2",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2749",
    "zoneId": "4",
    "metaId": "1381",
    "nftId": 2749,
    "creationDate": "1694889243582324",
    "id": "2749",
    "mintedVia": "CROWN",
  },
  {
    "x": "-5",
    "y": "5",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2750",
    "zoneId": "4",
    "metaId": "1391",
    "nftId": 2750,
    "creationDate": "1694889243582324",
    "id": "2750",
    "mintedVia": "CROWN",
  },
  {
    "x": "19",
    "y": "6",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2751",
    "zoneId": "4",
    "metaId": "1409",
    "nftId": 2751,
    "creationDate": "1694889243582324",
    "id": "2751",
    "mintedVia": "CROWN",
  },
  {
    "x": "48",
    "y": "28",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2752",
    "zoneId": "4",
    "metaId": "1217",
    "nftId": 2752,
    "creationDate": "1694889243582324",
    "id": "2752",
    "mintedVia": "CROWN",
  },
  {
    "x": "4",
    "y": "36",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2753",
    "zoneId": "3",
    "metaId": "1058",
    "nftId": 2753,
    "creationDate": "1694889477662806",
    "id": "2753",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "26",
    "owner": "hx35bef857f447768e009875dff1a8d386a488df9d",
    "name": "Emerald City Land #2754",
    "zoneId": "8",
    "metaId": "5032",
    "nftId": 2754,
    "creationDate": "1694926131234257",
    "id": "2754",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "-16",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2755",
    "zoneId": "1",
    "metaId": "699",
    "nftId": 2755,
    "creationDate": "1694942714921973",
    "id": "2755",
    "mintedVia": "CROWN",
  },
  {
    "x": "-47",
    "y": "-20",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2756",
    "zoneId": "1",
    "metaId": "638",
    "nftId": 2756,
    "creationDate": "1694942714921973",
    "id": "2756",
    "mintedVia": "CROWN",
  },
  {
    "x": "-23",
    "y": "3",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2757",
    "zoneId": "1",
    "metaId": "257",
    "nftId": 2757,
    "creationDate": "1694942714921973",
    "id": "2757",
    "mintedVia": "CROWN",
  },
  {
    "x": "-29",
    "y": "9",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2758",
    "zoneId": "1",
    "metaId": "367",
    "nftId": 2758,
    "creationDate": "1694942714921973",
    "id": "2758",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-25",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2759",
    "zoneId": "1",
    "metaId": "658",
    "nftId": 2759,
    "creationDate": "1694942714921973",
    "id": "2759",
    "mintedVia": "CROWN",
  },
  {
    "x": "15",
    "y": "16",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2760",
    "zoneId": "1",
    "metaId": "1",
    "nftId": 2760,
    "creationDate": "1694942714921973",
    "id": "2760",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "-13",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2761",
    "zoneId": "1",
    "metaId": "103",
    "nftId": 2761,
    "creationDate": "1694942714921973",
    "id": "2761",
    "mintedVia": "CROWN",
  },
  {
    "x": "29",
    "y": "-5",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2762",
    "zoneId": "1",
    "metaId": "534",
    "nftId": 2762,
    "creationDate": "1694942714921973",
    "id": "2762",
    "mintedVia": "CROWN",
  },
  {
    "x": "-54",
    "y": "13",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2763",
    "zoneId": "1",
    "metaId": "576",
    "nftId": 2763,
    "creationDate": "1694942714921973",
    "id": "2763",
    "mintedVia": "CROWN",
  },
  {
    "x": "-87",
    "y": "13",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2764",
    "zoneId": "8",
    "metaId": "6382",
    "nftId": 2764,
    "creationDate": "1694942838853509",
    "id": "2764",
    "mintedVia": "CROWN",
  },
  {
    "x": "43",
    "y": "28",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2765",
    "zoneId": "8",
    "metaId": "5013",
    "nftId": 2765,
    "creationDate": "1694942838853509",
    "id": "2765",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "15",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2766",
    "zoneId": "8",
    "metaId": "5997",
    "nftId": 2766,
    "creationDate": "1694942838853509",
    "id": "2766",
    "mintedVia": "CROWN",
  },
  {
    "x": "86",
    "y": "19",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2767",
    "zoneId": "8",
    "metaId": "6001",
    "nftId": 2767,
    "creationDate": "1694942838853509",
    "id": "2767",
    "mintedVia": "CROWN",
  },
  {
    "x": "82",
    "y": "19",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2768",
    "zoneId": "8",
    "metaId": "5872",
    "nftId": 2768,
    "creationDate": "1694942838853509",
    "id": "2768",
    "mintedVia": "CROWN",
  },
  {
    "x": "85",
    "y": "25",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2769",
    "zoneId": "8",
    "metaId": "5977",
    "nftId": 2769,
    "creationDate": "1694942838853509",
    "id": "2769",
    "mintedVia": "CROWN",
  },
  {
    "x": "72",
    "y": "-7",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2770",
    "zoneId": "8",
    "metaId": "5481",
    "nftId": 2770,
    "creationDate": "1694942838853509",
    "id": "2770",
    "mintedVia": "CROWN",
  },
  {
    "x": "67",
    "y": "-3",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2771",
    "zoneId": "8",
    "metaId": "5280",
    "nftId": 2771,
    "creationDate": "1694942838853509",
    "id": "2771",
    "mintedVia": "CROWN",
  },
  {
    "x": "10",
    "y": "26",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2772",
    "zoneId": "8",
    "metaId": "4752",
    "nftId": 2772,
    "creationDate": "1694942838853509",
    "id": "2772",
    "mintedVia": "CROWN",
  },
  {
    "x": "59",
    "y": "16",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2773",
    "zoneId": "8",
    "metaId": "5047",
    "nftId": 2773,
    "creationDate": "1694942838853509",
    "id": "2773",
    "mintedVia": "CROWN",
  },
  {
    "x": "-58",
    "y": "8",
    "owner": "hx8b6461c87b6990bb60785320aba340cd49f264ab",
    "name": "Emerald City Land #2774",
    "zoneId": "8",
    "metaId": "6820",
    "nftId": 2774,
    "creationDate": "1694942838853509",
    "id": "2774",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "13",
    "owner": "hx06518d1041ecffcce3a0536b2c55ccbbfba33f36",
    "name": "Emerald City Land #2775",
    "zoneId": "7",
    "metaId": "2060",
    "nftId": 2775,
    "creationDate": "1694964503705347",
    "id": "2775",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "-1",
    "owner": "hx06518d1041ecffcce3a0536b2c55ccbbfba33f36",
    "name": "Emerald City Land #2776",
    "zoneId": "7",
    "metaId": "2254",
    "nftId": 2776,
    "creationDate": "1694964503705347",
    "id": "2776",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "-2",
    "owner": "hx06518d1041ecffcce3a0536b2c55ccbbfba33f36",
    "name": "Emerald City Land #2777",
    "zoneId": "7",
    "metaId": "2155",
    "nftId": 2777,
    "creationDate": "1694964503705347",
    "id": "2777",
    "mintedVia": "CROWN",
  },
  {
    "x": "-70",
    "y": "21",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #2778",
    "zoneId": "8",
    "metaId": "6228",
    "nftId": 2778,
    "creationDate": "1694968414119693",
    "id": "2778",
    "mintedVia": "CROWN",
  },
  {
    "x": "69",
    "y": "-5",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #2779",
    "zoneId": "8",
    "metaId": "5362",
    "nftId": 2779,
    "creationDate": "1694968414119693",
    "id": "2779",
    "mintedVia": "CROWN",
  },
  {
    "x": "-106",
    "y": "0",
    "owner": "hxbf9340b9672bcf6f18c29b0938635c93b39c3533",
    "name": "Emerald City Land #2780",
    "zoneId": "8",
    "metaId": "6184",
    "nftId": 2780,
    "creationDate": "1694968414119693",
    "id": "2780",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "-16",
    "owner": "hxf38a1a9f460a4dfd84636ce6ac321e95c6ea1b1f",
    "name": "Emerald City Land #2781",
    "zoneId": "8",
    "metaId": "6876",
    "nftId": 2781,
    "creationDate": "1694968534142977",
    "id": "2781",
    "mintedVia": "CROWN",
  },
  {
    "x": "-33",
    "y": "-21",
    "owner": "hx05d0dd049dff3baf33320efd3aefe0c21e573a66",
    "name": "Emerald City Land #2782",
    "zoneId": "2",
    "metaId": "770",
    "nftId": 2782,
    "creationDate": "1695133569776543",
    "id": "2782",
    "mintedVia": "CROWN",
  },
  {
    "x": "-35",
    "y": "17",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #2783",
    "zoneId": "7",
    "metaId": "4036",
    "nftId": 2783,
    "creationDate": "1695187231141169",
    "id": "2783",
    "mintedVia": "CROWN",
  },
  {
    "x": "60",
    "y": "3",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #2784",
    "zoneId": "8",
    "metaId": "5069",
    "nftId": 2784,
    "creationDate": "1695309266910380",
    "id": "2784",
    "mintedVia": "CROWN",
  },
  {
    "x": "62",
    "y": "9",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #2785",
    "zoneId": "8",
    "metaId": "5121",
    "nftId": 2785,
    "creationDate": "1695321295317532",
    "id": "2785",
    "mintedVia": "CROWN",
  },
  {
    "x": "-61",
    "y": "-27",
    "owner": "hxdc166678ce876bef99affd1dd95b0425ed0267a1",
    "name": "Emerald City Land #2786",
    "zoneId": "2",
    "metaId": "881",
    "nftId": 2786,
    "creationDate": "1695430756733568",
    "id": "2786",
    "mintedVia": "CROWN",
  },
  {
    "x": "-65",
    "y": "-27",
    "owner": "hxdc166678ce876bef99affd1dd95b0425ed0267a1",
    "name": "Emerald City Land #2787",
    "zoneId": "2",
    "metaId": "892",
    "nftId": 2787,
    "creationDate": "1695430756733568",
    "id": "2787",
    "mintedVia": "CROWN",
  },
  {
    "x": "35",
    "y": "7",
    "owner": "hxdc166678ce876bef99affd1dd95b0425ed0267a1",
    "name": "Emerald City Land #2788",
    "zoneId": "7",
    "metaId": "2522",
    "nftId": 2788,
    "creationDate": "1695430868601040",
    "id": "2788",
    "mintedVia": "CROWN",
  },
  {
    "x": "37",
    "y": "-3",
    "owner": "hxdc166678ce876bef99affd1dd95b0425ed0267a1",
    "name": "Emerald City Land #2789",
    "zoneId": "6",
    "metaId": "1726",
    "nftId": 2789,
    "creationDate": "1695430900620849",
    "id": "2789",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "13",
    "owner": "hxdc166678ce876bef99affd1dd95b0425ed0267a1",
    "name": "Emerald City Land #2790",
    "zoneId": "1",
    "metaId": "65",
    "nftId": 2790,
    "creationDate": "1695430932621703",
    "id": "2790",
    "mintedVia": "CROWN",
  },
  {
    "x": "73",
    "y": "-17",
    "owner": "hxdc166678ce876bef99affd1dd95b0425ed0267a1",
    "name": "Emerald City Land #2791",
    "zoneId": "8",
    "metaId": "5514",
    "nftId": 2791,
    "creationDate": "1695430960657317",
    "id": "2791",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "15",
    "owner": "hxdc166678ce876bef99affd1dd95b0425ed0267a1",
    "name": "Emerald City Land #2792",
    "zoneId": "8",
    "metaId": "5212",
    "nftId": 2792,
    "creationDate": "1695430960657317",
    "id": "2792",
    "mintedVia": "CROWN",
  },
  {
    "x": "65",
    "y": "12",
    "owner": "hxdc166678ce876bef99affd1dd95b0425ed0267a1",
    "name": "Emerald City Land #2793",
    "zoneId": "8",
    "metaId": "5209",
    "nftId": 2793,
    "creationDate": "1695430960657317",
    "id": "2793",
    "mintedVia": "CROWN",
  },
  {
    "x": "77",
    "y": "26",
    "owner": "hxdc166678ce876bef99affd1dd95b0425ed0267a1",
    "name": "Emerald City Land #2794",
    "zoneId": "8",
    "metaId": "5693",
    "nftId": 2794,
    "creationDate": "1695430960657317",
    "id": "2794",
    "mintedVia": "CROWN",
  },
  {
    "x": "-28",
    "y": "-5",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #2795",
    "zoneId": "1",
    "metaId": "340",
    "nftId": 2795,
    "creationDate": "1695573983502869",
    "id": "2795",
    "mintedVia": "CROWN",
  },
  {
    "x": "20",
    "y": "20",
    "owner": "hx876ffb898b88b64a1dea48bfef028b22103b6110",
    "name": "Emerald City Land #2796",
    "zoneId": "3",
    "metaId": "941",
    "nftId": 2796,
    "creationDate": "1695744311995946",
    "id": "2796",
    "mintedVia": "CROWN",
  },
  {
    "x": "3",
    "y": "0",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #2797",
    "zoneId": "7",
    "metaId": "3270",
    "nftId": 2797,
    "creationDate": "1695998923241090",
    "id": "2797",
    "mintedVia": "CROWN",
  },
  {
    "x": "80",
    "y": "23",
    "owner": "hx1da7bb2c7d2613d59f3d75c5003b42bde0711c52",
    "name": "Emerald City Land #2798",
    "zoneId": "8",
    "metaId": "5807",
    "nftId": 2798,
    "creationDate": "1695999391417107",
    "id": "2798",
    "mintedVia": "CROWN",
  },
  {
    "x": "-52",
    "y": "-3",
    "owner": "hxbc34295a392cef06efb39187ea9fe594af7da30b",
    "name": "Emerald City Land #2799",
    "zoneId": "8",
    "metaId": "6714",
    "nftId": 2799,
    "creationDate": "1696140176557175",
    "id": "2799",
    "mintedVia": "CROWN",
  },
  {
    "x": "-45",
    "y": "18",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #2800",
    "zoneId": "7",
    "metaId": "4278",
    "nftId": 2800,
    "creationDate": "1696223573838613",
    "id": "2800",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "-37",
    "owner": "hxa71249cc33d414e1474951bf6f7f4f81b6681bd5",
    "name": "Emerald City Land #2801",
    "zoneId": "7",
    "metaId": "4622",
    "nftId": 2801,
    "creationDate": "1696376653591470",
    "id": "2801",
    "mintedVia": "CROWN",
  },
  {
    "x": "13",
    "y": "13",
    "owner": "hxebdc725cc4c9c12e09f6b323448ce56334f602f1",
    "name": "Emerald City Land #2802",
    "zoneId": "4",
    "metaId": "1187",
    "nftId": 2802,
    "creationDate": "1696731192050519",
    "id": "2802",
    "mintedVia": "CROWN",
  },
  {
    "x": "-44",
    "y": "-18",
    "owner": "hx5ac358694a7cf831f783274bf9464a8aa389825c",
    "name": "Emerald City Land #2803",
    "zoneId": "8",
    "metaId": "6867",
    "nftId": 2803,
    "creationDate": "1696767537683855",
    "id": "2803",
    "mintedVia": "CROWN",
  },
  {
    "x": "52",
    "y": "-9",
    "owner": "hx91f0734080d801df6fd6f3c880aa6ec3fb4f9e06",
    "name": "Emerald City Land #2804",
    "zoneId": "7",
    "metaId": "2755",
    "nftId": 2804,
    "creationDate": "1696824029927824",
    "id": "2804",
    "mintedVia": "CROWN",
  },
  {
    "x": "-8",
    "y": "15",
    "owner": "hx91f0734080d801df6fd6f3c880aa6ec3fb4f9e06",
    "name": "Emerald City Land #2805",
    "zoneId": "7",
    "metaId": "3821",
    "nftId": 2805,
    "creationDate": "1696824339550507",
    "id": "2805",
    "mintedVia": "CROWN",
  },
  {
    "x": "-31",
    "y": "-7",
    "owner": "hx91f0734080d801df6fd6f3c880aa6ec3fb4f9e06",
    "name": "Emerald City Land #2806",
    "zoneId": "7",
    "metaId": "4561",
    "nftId": 2806,
    "creationDate": "1696824339550507",
    "id": "2806",
    "mintedVia": "CROWN",
  },
  {
    "x": "-13",
    "y": "26",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #2807",
    "zoneId": "4",
    "metaId": "1425",
    "nftId": 2807,
    "creationDate": "1697043300939874",
    "id": "2807",
    "mintedVia": "CROWN",
  },
  {
    "x": "1",
    "y": "32",
    "owner": "hxc8081576f9774fe7c72af76dcce686026003e450",
    "name": "Emerald City Land #2808",
    "zoneId": "3",
    "metaId": "1037",
    "nftId": 2808,
    "creationDate": "1697043722918034",
    "id": "2808",
    "mintedVia": "CROWN",
  },
  {
    "x": "-42",
    "y": "17",
    "owner": "hxada87743b7f70f28bf61d3c3f9f39da8d87cc5ec",
    "name": "Emerald City Land #2809",
    "zoneId": "7",
    "metaId": "4211",
    "nftId": 2809,
    "creationDate": "1697405602665140",
    "id": "2809",
    "mintedVia": "CROWN",
  },
  {
    "x": "30",
    "y": "-5",
    "owner": "hxada87743b7f70f28bf61d3c3f9f39da8d87cc5ec",
    "name": "Emerald City Land #2810",
    "zoneId": "7",
    "metaId": "2419",
    "nftId": 2810,
    "creationDate": "1697405666690509",
    "id": "2810",
    "mintedVia": "CROWN",
  },
  {
    "x": "18",
    "y": "28",
    "owner": "hx98ef5ad26f961cb0c960a97d53cfd900bd9fa293",
    "name": "Emerald City Land #2811",
    "zoneId": "4",
    "metaId": "1178",
    "nftId": 2811,
    "creationDate": "1697433438413283",
    "id": "2811",
    "mintedVia": "CROWN",
  },
  {
    "x": "-39",
    "y": "-20",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #2812",
    "zoneId": "1",
    "metaId": "628",
    "nftId": 2812,
    "creationDate": "1697440054064234",
    "id": "2812",
    "mintedVia": "CROWN",
  },
  {
    "x": "76",
    "y": "25",
    "owner": "hxbd818852511748dfd5f459861c5fc6cac7ebebc5",
    "name": "Emerald City Land #2813",
    "zoneId": "8",
    "metaId": "5655",
    "nftId": 2813,
    "creationDate": "1697440282448888",
    "id": "2813",
    "mintedVia": "CROWN",
  },
];
